// IMPORT PACKAGE REFERENCES
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ContextPage } from "../../containers/layout/ContextPage";
import { ApprovalMatrixTable } from "./ApprovalMatrixTable";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Card } from "../../containers/card/Card";
import { CardActions } from "../../containers/card/CardActions";
import { uploadSegmentData, getSegmentDiscountMetaData } from "../../state/actions/ConfigurationActions";
import { Loading } from "../../containers/loading/Loading";
import moment from "moment";
import { CardRow } from "../../containers/card/CardRow";
import { API_ROOT } from "../../../config/config";
import Progress from "../../containers/loading/Progress";

// COMPONENT
class SegmentDiscountPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fileToUpload: undefined,
            segmentDiscountLastUpdated: undefined,
            uploading: false,
            progress: 0,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
    }

    componentDidMount() {
        this.props.getSegmentDiscountMetaData();
    }

    componentDidUpdate() {
        if (this.state.segmentDiscountLastUpdated !== this.props.segmentDiscountLastUpdated) {
            this.setState({ segmentDiscountLastUpdated: this.props.segmentDiscountLastUpdated });
        }
    }

    handleChange(event) {
        let files = event.target.files;
        if (files.length >= 1) {
            let file = files[0];
            this.setState({
                fileToUpload: file,
            });
        }
    }

    handleUpload() {
        this.setState({ uploading: true });
        this.props.uploadSegmentData(
            this.state.fileToUpload,
            (progress) => {
                this.setState({ progress: progress.loaded });
            },
            (success) => {
                if (success) {
                    this.setState({ fileToUpload: undefined });
                }

                this.setState({ uploading: false });
            }
        );
    }

    render() {
        return (
            <ContextPage fetching={this.props.fetching} fetched={this.props.fetched} title={"Segment Average Discount %"}>
                <Progress showing={this.state.uploading} progressText={`${this.state.progress}% uploaded`} />
                <Card
                    title="Import Segment Average Discount Data"
                    action={
                        <div onClick={(e) => this.handleUpload(e)} className={"text-link" + (this.state.fileToUpload === null ? " disabled" : "")}>
                            Upload
                        </div>
                    }
                >
                    <FormGroup>
                        <Label for="exampleFile">File</Label>
                        <Input accept=".csv" onChange={this.handleChange} type="file" name="file" id="exampleFile" />
                        <FormText color="muted">Please ensure that the file is formatted correctly (accepts .csv).</FormText>
                    </FormGroup>
                </Card>

                <Card title="Segment Avg Discount">
                    <CardRow label={"Last Updated"} top>
                        {this.state.segmentDiscountLastUpdated === null ? "No Segment Average Data" : moment(this.state.segmentDiscountLastUpdated).format("DD/MM/YYYY")}
                    </CardRow>
                    <CardRow label={"Download Latest Sales Data"} bottom>
                        {this.state.segmentDiscountLastUpdated === null ? "No Segment Average Data to download" : <a href={API_ROOT + "/segmentDiscount/data"}>Download</a>}
                    </CardRow>
                </Card>
            </ContextPage>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { fetching, fetched, failed, segmentDiscountLastUpdated } = state.configurationReducer;
    return { fetching, fetched, failed, segmentDiscountLastUpdated };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getSegmentDiscountMetaData, uploadSegmentData }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(SegmentDiscountPage));

// EXPORT COMPONENT

export { hoc as SegmentDiscountPage };
