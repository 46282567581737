// IMPORT PACKAGE REFERENCES
import React from "react";

// COMPONENT
export class Loading extends React.Component {
    render() {
        return (
            <div className={"loading-container" + (this.props.right ? " right" : "") + (this.props.hidden ? " hidden" : "") + (this.props.small ? " sm" : "") + (this.props.medium ? " md" : "") + (this.props.large ? " lg" : "") + (this.props.noPadding ? " no-padding" : "") + (this.props.fullHeight ? " full-height" : "")}>
                <span className={"spinner" + (this.props.light ? " light" : " dark") + (this.props.small ? " small" : "") + (this.props.medium ? " medium" : "") + (this.props.large ? " large" : "")}></span>
            </div>
        );
    }
}
