import axios from "axios";
import { API_ROOT } from "../../../config/config";

/* GET MATERIALS */
export const GET_MATERIALS_PENDING = "GET_MATERIALS_PENDING";
export const GET_MATERIALS_FULFILLED = "GET_MATERIALS_FULFILLED";
export const GET_MATERIALS_REJECTED = "GET_MATERIALS_REJECTED";

/* ADD MATERIAL */
export const ADD_MATERIAL_PENDING = "ADD_MATERIAL_PENDING";
export const ADD_MATERIAL_FULFILLED = "ADD_MATERIAL_FULFILLED";
export const ADD_MATERIAL_REJECTED = "ADD_MATERIAL_REJECTED";

/* GET CONTRACT MATERIALS */
export const GET_CONTRACT_MATERIAL_PENDING = "GET_CONTRACT_MATERIAL_PENDING";
export const GET_CONTRACT_MATERIAL_FULFILLED = "GET_CONTRACT_MATERIAL_FULFILLED";
export const GET_CONTRACT_MATERIAL_REJECTED = "GET_CONTRACT_MATERIAL_REJECTED";

/* UPDATE INDIVIDUAL CONTRACT MATERIALS */
export const PUT_CONTRACT_MATERIAL_UPDATING = "PUT_CONTRACT_MATERIAL_UPDATING";
export const PUT_CONTRACT_MATERIAL_UPDATED = "PUT_CONTRACT_MATERIAL_UPDATED";
export const PUT_CONTRACT_MATERIAL_FAILED = "PUT_CONTRACT_MATERIAL_FAILED";

// UPDATE ALL CONTRACT MATERIALS
export const PUT_CONTRACT_MATERIALS_UPDATING = "PUT_CONTRACT_MATERIALS_UPDATING";
export const PUT_CONTRACT_MATERIALS_UPDATED = "PUT_CONTRACT_MATERIALS_UPDATED";
export const PUT_CONTRACT_MATERIALS_FAILED = "PUT_CONTRACT_MATERIALS_FAILED";

/* SEARCH MATERIALS */
export const SEARCH_MATERIALS_PENDING = "SEARCH_MATERIALS_PENDING";
export const SEARCH_MATERIALS_FULFILLED = "SEARCH_MATERIALS_FULFILLED";
export const SEARCH_MATERIALS_FAILED = "SEARCH_MATERIALS_FAILED";

/* GET VOLUME/DATE SEGMENTATION */
export const GET_SEGMENTATION_FULFILLED = "GET_SEGMENTATION_FULFILLED";
export const GET_SEGMENTATION_PENDING = "GET_SEGMENTATION_PENDING";
export const GET_SEGMENTATION_REJECTED = "GET_SEGMENTATION_REJECTED";

/* CREATE PRODUCT GROUP */
export const CREATE_PRODUCT_GROUP_MATERIALS_PENDING = "CREATE_PRODUCT_GROUP_MATERIALS_PENDING";
export const CREATE_PRODUCT_GROUP_MATERIALS_FULFILLED = "CREATE_PRODUCT_GROUP_MATERIALS_FULFILLED";
export const CREATE_PRODUCT_GROUP_MATERIALS_REJECTED = "CREATE_PRODUCT_GROUP_MATERIALS_REJECTED";

/* GET PRODUCT GROUPS */
export const GET_PRODUCT_GROUPS_MATERIALS_PENDING = "GET_PRODUCT_GROUPS_MATERIALS_PENDING";
export const GET_PRODUCT_GROUPS_MATERIALS_FULFILLED = "GET_PRODUCT_GROUPS_MATERIALS_FULFILLED";
export const GET_PRODUCT_GROUPS_MATERIALS_REJECTED = "GET_PRODUCT_GROUPS_MATERIALS_REJECTED";

/* SET HOVERED EXPRESSION INDEX */
export const SET_HOVERED_EXPRESSION_INDEX = "SET_HOVERED_EXPRESSION_INDEX";

// ACTION GENERATORS

/*
    Retrieves all the materials.
*/
const getMaterials = (contractId, scenarioId, callback) => (dispatch) => {
    dispatch({
        type: GET_MATERIALS_PENDING,
    });

    /*
        We want to get all the materials, plus all the materials that are currently assigned to the contract, and the suggested materials for this customer.
    */
    axios
        .get(`${API_ROOT}/contracts/${contractId}/${scenarioId}/materials`)
        .then((res) => {
            const materialData = res.data;

            /* Fulfil the request for materials, since the API succceded, pass the materials data to the reducer. */
            dispatch({
                type: GET_MATERIALS_FULFILLED,
                payload: materialData,
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting materials.", error);

            /* Reject the request for materials, since the API failed. */
            dispatch({
                type: GET_MATERIALS_REJECTED,
            });

            callback(false);
        });
};

/*
    Create a product group.
*/
const createProductGroup = (contractId, scenarioId, data, callback) => (dispatch) => {
    dispatch({
        type: CREATE_PRODUCT_GROUP_MATERIALS_PENDING,
    });

    /*
        We want to get all the materials, plus all the materials that are currently assigned to the contract, and the suggested materials for this customer.
    */
    axios
        .post(`${API_ROOT}/contracts/${contractId}/${scenarioId}/productGroups`, data)
        .then(() => {
            /* Fulfil the request for materials, since the API succceded, pass the materials data to the reducer. */
            dispatch({
                type: CREATE_PRODUCT_GROUP_MATERIALS_FULFILLED,
                payload: materials,
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting materials.", error);

            /* Reject the request for materials, since the API failed. */
            dispatch({
                type: CREATE_PRODUCT_GROUP_MATERIALS_REJECTED,
            });

            callback(false);
        });
};

/*
    Get product groups.
*/
const getProductGroups = (contractId, data, callback) => (dispatch) => {
    dispatch({
        type: GET_PRODUCT_GROUPS_MATERIALS_PENDING,
    });

    /*
        We want to get all the materials, plus all the materials that are currently assigned to the contract, and the suggested materials for this customer.
    */
    axios
        .get(`${API_ROOT}/contracts/${contractId}/${scenarioId}/productGroups`)
        .then((res) => {
            const productGroups = res.data;

            /* Fulfil the request for materials, since the API succceded, pass the materials data to the reducer. */
            dispatch({
                type: GET_PRODUCT_GROUPS_MATERIALS_FULFILLED,
                payload: productGroups,
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting materials.", error);

            /* Reject the request for materials, since the API failed. */
            dispatch({
                type: GET_PRODUCT_GROUPS_MATERIALS_REJECTED,
            });

            callback(false);
        });
};

const changeMaterialStatusOnContract = (contractId, materialId, status, callback) => (dispatch) => {
    dispatch({
        type: ADD_MATERIAL_PENDING,
    });

    axios
        .put(`${API_ROOT}/contracts/${contractId}/${scenarioId}/materials/${materialId}`, { status: status })
        .then(() => {
            /* Fulfil the request */
            dispatch({
                type: ADD_MATERIAL_FULFILLED,
                payload: { materialId: materialId, status },
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting materials.", error);

            /* Reject the request for materials, since the API failed. */

            dispatch({
                type: ADD_MATERIAL_REJECTED,
            });

            callback(false);
        });
};

const getContractMaterial = (contractId, materialId, update = false, callback = false) => (dispatch) => {
    if (!update) {
        // If we're updating don't set the state to fetching.
        dispatch({
            type: GET_CONTRACT_MATERIAL_PENDING,
        });
    }

    axios
        .get(`${API_ROOT}/contracts/${contractId}/${scenarioId}/materials/${materialId}`)
        .then((res) => {
            const contractMaterial = res.data;

            /* Fulfil the request for contracts, since the API succceded, pass the contracts data to the reducer. */

            dispatch({
                type: GET_CONTRACT_MATERIAL_FULFILLED,
                payload: contractMaterial,
            });

            let conditions = {};
            if (contractMaterial.hasOwnProperty("conditions")) {
                contractMaterial.conditions.forEach((condition) => {
                    conditions[condition.id] = condition.value;
                });
            }

            let variables = Object.assign(conditions, { annualCases: contractMaterial.annualCases, volumeGrowth: contractMaterial.volumeGrowth, productInflationRate: contractMaterial.productInflationRate, unitPrice: contractMaterial.UnitPriceEX, customerRetailPrice: contractMaterial.customerRetailPrice, ccaCostPerUnit: contractMaterial.ccaCostPerUnit });
            getContractMaterialVolumeSegmentation(contractId, materialId, variables, update, dispatch, callback);
        })
        .catch((error) => {
            console.log("An error occurred requesting product.", error);

            /* Reject the request for the product, since the API failed. */

            dispatch({
                type: GET_CONTRACT_MATERIAL_REJECTED,
            });
        });
};

function getContractMaterialVolumeSegmentation(contractId, materialId, variables, update, dispatch, callback) {
    if (!update) {
        dispatch({
            type: GET_SEGMENTATION_PENDING,
        });
    }

    let baseReq = `${API_ROOT}/expressions/${contractId}/${scenarioId}/${materialId}`;

    let query = "";
    Object.keys(variables).map((variableKey, index) => {
        if (index === 0) {
            query += "?";
        } else {
            query += "&";
        }

        let variable = variables[variableKey];
        query += variableKey + "=" + variable;
    });

    axios
        .get(baseReq + query)
        .then((res) => {
            const segmentation = res.data;

            dispatch({
                type: GET_SEGMENTATION_FULFILLED,
                payload: segmentation,
            });

            if (callback) {
                callback(true);
            }
        })
        .catch((error) => {
            console.log("Error occured getting segmentation data", error);

            dispatch({
                type: GET_SEGMENTATION_REJECTED,
            });

            if (callback) {
                callback(false);
            }
        });
}

const updateContractMaterial = (contractId, materialId, values, callback) => (dispatch) => {
    axios
        .put(`${API_ROOT}/contracts/${contractId}/${scenarioId}/materials/${materialId}`)
        .then((res) => {
            dispatch({
                type: PUT_CONTRACT_MATERIAL_UPDATED,
            });

            callback(true);
        })
        .catch((error) => {
            console.log("Error occurred saving product data", error);

            dispatch({
                type: PUT_CONTRACT_MATERIAL_FAILED,
            });

            callback(false);
        });
};

const updateContractMaterials = (contractId, values) => (dispatch) => {
    axios
        .put(`${API_ROOT}/contracts/${contractId}/${scenarioId}/materials/`, values)
        .then((res) => {
            dispatch({
                type: PUT_CONTRACT_MATERIALS_UPDATED,
            });
        })
        .catch((error) => {
            console.log("Error occurred saving product data", error);

            dispatch({
                type: PUT_CONTRACT_MATERIALS_FAILED,
            });
        });
};

const setHoveredExpression = (expressionIndex) => (dispatch) => {
    dispatch({
        type: SET_HOVERED_EXPRESSION_INDEX,
        payload: expressionIndex,
    });
};

var call;

const getMaterialSearchResults = (contractId, scenarioId, query) => (dispatch) => {
    dispatch({
        type: SEARCH_MATERIALS_PENDING,
    });

    if (call) {
        call.cancel();
    }

    call = axios.CancelToken.source();

    axios
        .get(`${API_ROOT}/contracts/${contractId}/${scenarioId}/materialsearch?query=${query}`, { cancelToken: call.token })
        .then((response) => {
            dispatch({
                type: SEARCH_MATERIALS_FULFILLED,
                payload: response.data,
            });
        })
        .catch((thrown) => {
            if (axios.isCancel(thrown)) {
                console.log("Request cancelled: ", query);
            } else {
                console.log("Error searching for materials", thrown);

                dispatch({
                    type: SEARCH_MATERIALS_FAILED,
                });
            }
        });
};

// EXPORT ACTIONS
export { getMaterialSearchResults, getMaterials, changeMaterialStatusOnContract, getContractMaterial, updateContractMaterial, getContractMaterialVolumeSegmentation, setHoveredExpression, updateContractMaterials, createProductGroup, getProductGroups };
