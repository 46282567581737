// *** PACKAGE REFERENCES *** //
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// *** ACTIONS *** //
import { getContractDetails, saveContractDetails } from "../../../state/actions/ContractDetailsActions";
import { showError, hideError } from "../../../state/actions/GeneralActions";

// *** UI *** //
import { ContextPage } from "../../../containers/layout/ContextPage";
import { Card } from "../../../containers/card/Card";
import { CardRow } from "../../../containers/card/CardRow";
import { Formik } from "formik";
import { CCAInputTypes } from "../../../enums/CCAInputTypes";
import { CCAInput } from "../../../containers/inputs/CCAInput";
import { CCAError } from "../../../enums/CCAError";

class GoalsAndNeedsPage extends React.Component {
    constructor(props) {
        super(props);

        this.valuesAtLastSave = {};
        this.getDetails = this.getDetails.bind(this);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false,
        };
    }

    componentDidMount() {
        this.getDetails();
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        });
    }

    generateGoalsAndNeedsToolTip() {
        return (
            <div>
                Understanding The Total $ Investment <br />
                ▪ Volume, RRPs, % Margin and $ Margin expectations <br />
                ▪ Cash Payments (annual or upfront) <br />
                ▪ Rebates (Frequency) <br />
                ▪ Promotional Support ($ Specific and understand what they are being used for) <br />▪ Rank Grocery Product Group in order of importance to the customer
            </div>
        );
    }

    generateCCAToolTip() {
        return (
            <div>
                PSS - Customer Needs <br />
                ▪ Customers KPIs <br />
                ▪ Key Elements of importance of their existing agreement and supplier <br />
                ▪ Role of existing equipment plays in their business <br />
                ▪ What are their businesses “must haves” <br />
                ▪ TERM (including a pressure test – what would you need to see for 10years???) <br />
            </div>
        );
    }

    generateCurrentOperationsToolTip() {
        return (
            <div>
                Understanding current Operations <br />
                ▪ Pros / Cons of their existing suppliers service? <br />
                ▪ Existing ordering processes? <br />
                ▪ Accounts benefits? <br />
                ▪ Current Delivery commitments <br />▪ What’s working / What’s not working (any pinch points)
            </div>
        );
    }

    generateGrowthOpportunitiesToolTip() {
        return (
            <div>
                Growth Opportunities <br />
                ▪ Discuss wider portfolio (coffee, vending, Alcohol) <br />
                ▪ What role does emerging categories play? (Flavored MILK) <br />
                ▪ Innovation – Frozen? <br />
                ▪ 6 -18month business plan? <br />
                <emsp />▪ External influences on their business <br />
                ▪ Seasonality? <br />
                ▪ Expansion plans? <br />
                ▪ How are they currently being recognized for growth? <br />▪ Is the above important to them?
            </div>
        );
    }

    getDetails() {
        this.props.getContractDetails(this.props.match.params.contractId, (success) => {
            if (success) {
                this.valuesAtLastSave = this.props.contractDetails.goalsAndNeeds;
                this.props.hideError(CCAError.GET_CONTRACT_DETAILS_ERROR);
            } else {
                this.props.showError(CCAError.GET_CONTRACT_DETAILS_ERROR);
            }
        });
    }

    saveContractDetails(values) {
        this.props.saveContractDetails(this.props.match.params.contractId, values, "goalsAndNeeds", (success) => {
            if (success) {
                this.props.hideError(CCAError.SAVE_CONTRACT_DETAILS_ERROR);
                this.valuesAtLastSave = values;
            } else {
                this.props.showError(CCAError.SAVE_CONTRACT_DETAILS_ERROR, () => {
                    this.saveContractDetails(values);
                });
            }
        });
    }

    render() {
        let goalsAndNeedsToolTip = this.generateGoalsAndNeedsToolTip();
        let ccaToolTip = this.generateCCAToolTip();
        let generateCurrentOperationsToolTip = this.generateCurrentOperationsToolTip();
        let generateGrowthToolTip = this.generateGrowthOpportunitiesToolTip();

        return (
            <ContextPage pin={true} pinned={this.props.pinned} handlePin={() => this.props.handlePin("goalsAndNeeds")} fetching={this.props.fetching} fetched={this.props.fetched} title={this.props.title}>
                <Formik
                    enableReinitialize={true}
                    initialValues={this.props.contractDetails.goalsAndNeeds}
                    onSubmit={(values) => {
                        if (JSON.stringify(values) !== JSON.stringify(this.valuesAtLastSave)) {
                            this.saveContractDetails(values);
                        }
                    }}
                    render={({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldTouched, setFieldValue, handleReset }) => (
                        <Card>
                            <CardRow longform label={"Goals and Needs"} top={true} toolTipText={goalsAndNeedsToolTip}>
                                <CCAInput placeholder={"What are the goals that the customer would like to achieve through their relationship with CCA?"} type={CCAInputTypes.DESCRIPTION} name="goalsAndNeeds" value={values.goalsAndNeeds} onChange={handleChange} onSave={handleSubmit} />
                            </CardRow>

                            <CardRow longform label={"How CCEP can help"} toolTipText={ccaToolTip}>
                                <CCAInput placeholder={"How can CCEP help the customer achieve these goals?"} type={CCAInputTypes.DESCRIPTION} name="howCCAHelp" value={values.howCCAHelp} onChange={handleChange} onSave={handleSubmit} />
                            </CardRow>

                            <CardRow longform label={"Operational Considerations"} toolTipText={generateCurrentOperationsToolTip}>
                                <CCAInput placeholder={"Operational considerations"} type={CCAInputTypes.DESCRIPTION} name="operationalConsiderations" value={values.operationalConsiderations} onChange={handleChange} onSave={handleSubmit} />
                            </CardRow>

                            <CardRow longform label={"Growth Opportunities"} bottom={true} toolTipText={generateGrowthToolTip}>
                                <CCAInput placeholder={"Growth opportunities"} type={CCAInputTypes.DESCRIPTION} name="growthOpportunities" value={values.growthOpportunities} onChange={handleChange} onSave={handleSubmit} />
                            </CardRow>
                        </Card>
                    )}
                />
            </ContextPage>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { fetching, fetched, failed, contractDetails } = state.contractDetailsReducer;
    return { fetching, fetched, failed, contractDetails };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getContractDetails,
            saveContractDetails,
            showError,
            hideError,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(GoalsAndNeedsPage));

// EXPORT COMPONENT
export { hoc as GoalsAndNeedsPage };
