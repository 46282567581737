import {
    GET_NON_FINANCIALS_PENDING,
    GET_NON_FINANCIALS_FULFILLED,
    GET_NON_FINANCIALS_REJECTED,
    ADD_NON_FINANCIAL_FULFILLED,
    ADD_NON_FINANCIAL_PENDING,
    ADD_NON_FINANCIAL_REJECTED,
    GET_NON_FINANCIAL_FULFILLED,
    GET_NON_FINANCIAL_PENDING,
    GET_NON_FINANCIAL_REJECTED,
    PUT_NON_FINANCIAL_FULFILLED,
    PUT_NON_FINANCIAL_PENDING,
    PUT_NON_FINANCIAL_REJECTED,
    DELETE_NON_FINANCIAL_FULFILLED,
    DELETE_NON_FINANCIAL_PENDING,
    DELETE_NON_FINANCIAL_REJECTED,
    GET_NON_FINANCIAL_SUMMARY_PENDING,
    GET_NON_FINANCIAL_SUMMARY_FULFILLED,
    GET_NON_FINANCIAL_SUMMARY_REJECTED,
    SAVE_NON_FINANCIAL_CONDITIONS_PENDING,
    SAVE_NON_FINANCIAL_CONDITIONS_FULFILLED,
    SAVE_NON_FINANCIAL_CONDITIONS_REJECTED,
} from "../actions/NonFinancialActions";

import { CLEAR_REDUCERS, PREPARE_FOR_CONTRACT_SWITCH } from "../actions/GeneralActions";

// INITIALIZE STATE
const initialState = {
    nonFinancial: {},
    nonFinancials: [],
    fetching: false,
    fetched: false,
    failed: false,
    adding: false,
    added: false,
    addFailed: false,
    updating: false,
    updated: false,
    updateFailed: false,
    deleting: false,
    deleted: false,
    deleteFailed: false,
    nonFinancialSummary: {
        totalCapitalValue: {
            value: 0,
            guidance: "normal",
            advice: "",
        },
        totalQuantity: {
            value: 0,
            guidance: "normal",
            advice: "",
        },
        equipmentOtherDistribution: {
            ratio: 0,
            guidance: "normal",
            advice: "",
        },
    },
};

// REDUCER
export const NonFinancialReducer = (state = initialState, action) => {
    let nonFinancials = {};

    switch (action.type) {
        case CLEAR_REDUCERS:
            return initialState;
        case PREPARE_FOR_CONTRACT_SWITCH:
            return initialState;
        /* GET NON_FINANCIAL */
        case GET_NON_FINANCIALS_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_NON_FINANCIALS_FULFILLED:
            return {
                ...state,
                nonFinancials: action.payload,
                fetching: false,
                fetched: true,
                failed: false,
            };
        case GET_NON_FINANCIALS_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        case ADD_NON_FINANCIAL_PENDING:
            return {
                ...state,
                adding: true,
                added: false,
                addFailed: false,
            };
        case ADD_NON_FINANCIAL_FULFILLED:
            return {
                ...state,
                adding: false,
                added: true,
                addFailed: false,
            };
        case ADD_NON_FINANCIAL_REJECTED:
            return {
                ...state,
                adding: false,
                added: false,
                addFailed: true,
            };
        case GET_NON_FINANCIAL_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_NON_FINANCIAL_FULFILLED:
            return {
                ...state,
                nonFinancial: action.payload,
                fetching: false,
                fetched: true,
                failed: false,
            };
        case GET_NON_FINANCIAL_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        case PUT_NON_FINANCIAL_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
                updatedFailed: false,
            };
        case PUT_NON_FINANCIAL_FULFILLED:
            let newNonFinancial = action.payload;
            nonFinancials = Object.assign({}, state.nonFinancials);
            nonFinancials[newNonFinancial.id] = newNonFinancial;

            return {
                ...state,
                nonFinancial: newNonFinancial,
                nonFinancials: nonFinancials,
                updating: false,
                updated: true,
                updatedFailed: false,
            };
        case PUT_NON_FINANCIAL_REJECTED:
            return {
                ...state,
                updating: false,
                updated: false,
                updatedFailed: true,
            };
        case DELETE_NON_FINANCIAL_PENDING:
            return {
                ...state,
                deleting: true,
                delete: false,
                deleteFailed: false,
            };
        case DELETE_NON_FINANCIAL_FULFILLED:
            let deletedIds = action.payload;
            let nonFinancial = Object.assign({}, state.nonFinancial);
            nonFinancials = Object.assign({}, state.nonFinancials);

            deletedIds.forEach((id) => {
                delete nonFinancials[id];
            });

            if (deletedIds.includes(state.nonFinancial.id)) {
                nonFinancial = {};
            }

            return {
                ...state,
                nonFinancial: nonFinancial,
                nonFinancials: nonFinancials,
                deleting: false,
                delete: true,
                deleteFailed: false,
            };
        case DELETE_NON_FINANCIAL_REJECTED:
            return {
                ...state,
                deleting: false,
                delete: false,
                deleteFailed: true,
            };
        case GET_NON_FINANCIAL_SUMMARY_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
                updateFailed: false,
            };
        case GET_NON_FINANCIAL_SUMMARY_FULFILLED:
            return {
                ...state,
                updating: false,
                updated: true,
                updateFailed: false,
                nonFinancialSummary: action.payload,
            };
        case GET_NON_FINANCIAL_SUMMARY_REJECTED:
            return {
                ...state,
                updating: false,
                updated: false,
                updateFailed: true,
            };
        case SAVE_NON_FINANCIAL_CONDITIONS_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
                updateFailed: false,
            };
        case SAVE_NON_FINANCIAL_CONDITIONS_FULFILLED:
            let updatedNonFinancials = Object.assign({}, state.nonFinancials);
            updatedNonFinancials[action.payload.id].conditions = action.payload.data;

            return {
                ...state,
                nonFinancials: updatedNonFinancials,
                updating: false,
                updated: true,
                updateFailed: false,
            };
        case SAVE_NON_FINANCIAL_CONDITIONS_REJECTED:
            return {
                ...state,
                updating: false,
                updated: false,
                updateFailed: true,
            };
        default:
            return state;
    }
};
