import {
    GET_CONTRACTS_PENDING,
    GET_CONTRACTS_FULFILLED,
    GET_CONTRACTS_REJECTED,
    GET_CONTRACT_PENDING,
    GET_CONTRACT_FULFILLED,
    GET_CONTRACT_REJECTED,
    POST_CONTRACT_PENDING,
    POST_CONTRACT_FULFILLED,
    POST_CONTRACT_REJECTED,
    PUT_CONTRACT_PENDING,
    PUT_CONTRACT_FULFILLED,
    PUT_CONTRACT_REJECTED,
    PUT_CONTRACT_UPDATING,
    PUT_CONTRACT_UPDATED,
    GET_CONTRACT_PROFIT_SUMMARY_PENDING,
    GET_CONTRACT_PROFIT_SUMMARY_FULFILLED,
    GET_CONTRACT_PROFIT_SUMMARY_REJECTED,
    GET_CONTRACT_COMPLIANCE_PENDING,
    GET_CONTRACT_COMPLIANCE_FULFILLED,
    GET_CONTRACT_COMPLIANCE_REJECTED,
    GET_CONTRACT_SUMMARY_PENDING,
    GET_CONTRACT_SUMMARY_FULFILLED,
    GET_CONTRACT_SUMMARY_REJECTED,
    UPDATE_CONTRACT_SUMMARY_PENDING,
    UPDATE_CONTRACT_SUMMARY_FULFILLED,
    UPDATE_CONTRACT_SUMMARY_REJECTED,
    GET_CONTRACT_DISCOUNT_VOLUME_SPREAD_PENDING,
    GET_CONTRACT_DISCOUNT_VOLUME_SPREAD_FULFILLED,
    GET_CONTRACT_DISCOUNT_VOLUME_SPREAD_REJECTED,
    DELETE_CONTRACTS_PENDING,
    DELETE_CONTRACTS_FULFILLED,
    DELETE_CONTRACTS_REJECTED,
    SAVE_CONTRACT_CUSTOM_CONDITIONS_PENDING,
    SAVE_CONTRACT_CUSTOM_CONDITIONS_FULFILLED,
    SAVE_CONTRACT_CUSTOM_CONDITIONS_REJECTED,
    SAVE_CONTRACT_COMPLIANCE_PENDING,
    SAVE_CONTRACT_COMPLIANCE_FULFILLED,
    SAVE_CONTRACT_COMPLIANCE_REJECTED,
    POST_SCENARIO_PENDING,
    POST_SCENARIO_FULFILLED,
    POST_SCENARIO_REJECTED,
    DELETE_SCENARIO_PENDING,
    DELETE_SCENARIO_FULFILLED,
    DELETE_SCENARIO_REJECTED,
    GET_CONTRACT_APPROVERS_PENDING,
    GET_CONTRACT_APPROVERS_FULFILLED,
    GET_CONTRACT_APPROVERS_REJECTED,
    GET_CONTRACT_APPROVAL_PENDING,
    GET_CONTRACT_APPROVAL_FULFILLED,
    GET_CONTRACT_APPROVAL_REJECTED,
    deleteContracts,
    GET_WORKFLOW_USERS_REJECTED,
    GET_WORKFLOW_USERS_PENDING,
    GET_WORKFLOW_USERS_FULFILLED,
} from "../actions/ContractActions";

import { CLEAR_REDUCERS } from "../actions/GeneralActions";

// INITIALIZE STATE
const initialState = {
    contract: {
        contractLength: 0,
    },
    contracts: {},
    fetching: false,
    fetched: false,
    failed: false,
    updating: false,
    updated: false,
    updateFailed: false,
    fetchingProfitSummary: false,
    fetchedProfitSummary: false,
    failedProfitSummary: false,
    chartFetching: false,
    chartFetched: false,
    chartFailed: false,
    chartData: [],
    summary: {},
    profitSummary: {
        totalCCAProfit: 0,
        totalOutletProfit: 0,
    },
    approvalReason: {
        approver: "",
        reason: ""
    },
    approvalTree: [],
    approvalItem: {},
    usersByRole: []
};

// REDUCER
export const ContractReducer = (state = initialState, action) => {
    let contract = Object.assign({}, state.contract);
    let contracts = Object.assign({}, state.contracts);

    switch (action.type) {
        case CLEAR_REDUCERS:
            return initialState;
        /* GET CONTRACTS */
        case GET_CONTRACTS_PENDING:
            return {
                ...state,
                contract: {},
                fetching: true,
                fetched: false,
                failed: false,
                updating: false,
                updated: false,
            };
        case GET_CONTRACTS_FULFILLED:
            return {
                ...state,
                contract: {},
                contracts: action.payload,
                fetching: false,
                fetched: true,
                failed: false,
                updating: false,
                updated: false,
            };
        case GET_CONTRACTS_REJECTED:
            return {
                ...state,
                contract: {},
                fetching: false,
                fetched: false,
                failed: true,
                updating: false,
                updated: false,
            };
        /* Get customCompliance */
        case GET_CONTRACT_COMPLIANCE_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
                updating: false,
                updated: false,
            };
        case GET_CONTRACT_COMPLIANCE_FULFILLED:
            return {
                contractCompliance: action.payload.customConditions,
                ...state,
                fetching: false,
                fetched: true,
                failed: false,
                updating: false,
                updated: false,
            };
        case GET_CONTRACT_COMPLIANCE_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
                updating: false,
                updated: false,
            };
        /* GET CONTRACT */

        case GET_CONTRACT_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
                updating: false,
                updated: false,
            };
        case GET_CONTRACT_FULFILLED:
            return {
                ...state,
                contract: action.payload,
                fetching: false,
                fetched: true,
                failed: false,
                updating: false,
                updated: false,
            };
        case GET_CONTRACT_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
                updating: false,
                updated: false,
            };
        /* POST CONTRACT */

        case POST_CONTRACT_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
                updating: false,
                updated: false,
            };
        case POST_CONTRACT_FULFILLED:
            // Add the new contract to the contract object,
            let contractId = action.payload.contractId;
            contracts[contractId] = action.payload;

            return {
                ...state,
                fetching: false,
                fetched: true,
                failed: false,
                updating: false,
                updated: false,
                contracts,
            };
        case POST_CONTRACT_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
                updating: false,
                updated: false,
            };
        /* PUT CONTRACT */

        case PUT_CONTRACT_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
                updating: false,
                updated: false,
            };
        case PUT_CONTRACT_FULFILLED:
            return {
                ...state,
                contract: action.payload,
                fetching: false,
                fetched: true,
                failed: false,
                updating: false,
                updated: false,
            };
        case PUT_CONTRACT_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
                updating: false,
                updated: false,
            };
        case PUT_CONTRACT_UPDATING:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: false,
                updating: true,
                updated: false,
            };
        case PUT_CONTRACT_UPDATED:
            return {
                ...state,
                contract: action.payload,
                fetching: false,
                fetched: true,
                failed: false,
                updating: false,
                updated: true,
            };
        case GET_CONTRACT_PROFIT_SUMMARY_PENDING:
            return {
                ...state,
                fetchingProfitSummary: true,
                fetchedProfitSummary: false,
                failedProfitSummary: false,
            };
        case GET_CONTRACT_PROFIT_SUMMARY_FULFILLED:
            return {
                ...state,
                fetchingProfitSummary: false,
                fetchedProfitSummary: true,
                failedProfitSummary: false,
                profitSummary: action.payload,
            };
        case GET_CONTRACT_PROFIT_SUMMARY_REJECTED:
            return {
                ...state,
                fetchingProfitSummary: false,
                fetchedProfitSummary: false,
                failedProfitSummary: true,
            };
        case GET_CONTRACT_SUMMARY_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_CONTRACT_SUMMARY_FULFILLED:
            return {
                ...state,
                fetching: false,
                fetched: true,
                failed: false,
                summary: action.payload,
            };
        case GET_CONTRACT_SUMMARY_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        case UPDATE_CONTRACT_SUMMARY_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
            };
        case UPDATE_CONTRACT_SUMMARY_FULFILLED:
            return {
                ...state,
                updating: false,
                updated: true,
            };
        case UPDATE_CONTRACT_SUMMARY_REJECTED:
            return {
                ...state,
                updating: false,
                updated: false,
                failed: true,
            };
        case GET_CONTRACT_DISCOUNT_VOLUME_SPREAD_PENDING:
            return {
                ...state,
                chartFetching: true,
                chartFetched: false,
                chartFailed: false,
            };
        case GET_CONTRACT_DISCOUNT_VOLUME_SPREAD_FULFILLED:
            return {
                ...state,
                chartFetching: false,
                chartFetched: true,
                chartFailed: false,
                chartData: action.payload,
            };
        case GET_CONTRACT_DISCOUNT_VOLUME_SPREAD_REJECTED:
            return {
                ...state,
                chartFetching: false,
                chartFetched: false,
                chartFailed: true,
            };
        case DELETE_CONTRACTS_PENDING:
            return {
                ...state,
                deleting: true,
                deleted: false,
                deleteFailed: false,
            };
        case DELETE_CONTRACTS_FULFILLED:
            let deletedContracts = action.payload;

            deletedContracts.forEach((contractId) => {
                delete contracts[contractId];
            });

            return {
                ...state,
                contracts,
                deleting: false,
                deleted: true,
                deleteFailed: false,
            };
        case DELETE_CONTRACTS_REJECTED:
            return {
                ...state,
                deleting: false,
                deleted: false,
                deleteFailed: true,
            };
        case SAVE_CONTRACT_CUSTOM_CONDITIONS_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
                updateFailed: false,
            };
        case SAVE_CONTRACT_CUSTOM_CONDITIONS_FULFILLED:
            contract.customConditions = action.payload.conditions;

            return {
                ...state,
                contract,
                updating: false,
                updated: true,
                updateFailed: false,
            };
        case SAVE_CONTRACT_CUSTOM_CONDITIONS_REJECTED:
            return {
                ...state,
                updating: false,
                updated: false,
                updateFailed: true,
            };
        case SAVE_CONTRACT_COMPLIANCE_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
                updateFailed: false,
            };
        case SAVE_CONTRACT_COMPLIANCE_FULFILLED:
            contract.compliance = action.payload;

            return {
                ...state,
                contract,
                updating: false,
                updated: true,
                updateFailed: false,
            };
        case SAVE_CONTRACT_COMPLIANCE_REJECTED:
            return {
                ...state,
                updating: false,
                updated: false,
                updateFailed: true,
            };
        case POST_SCENARIO_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
                updateFailed: false,
            };
        case POST_SCENARIO_FULFILLED:
            if (!contract.scenarios) {
                contract.scenarios = [];
            }

            contract.scenarios.push(action.payload);

            return {
                ...state,
                contract,
                updating: false,
                updated: true,
                updateFailed: false,
            };
        case POST_SCENARIO_REJECTED:
            return {
                ...state,
                updating: false,
                updated: false,
                updateFailed: true,
            };
        case DELETE_SCENARIO_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
                updateFailed: false,
            };
        case DELETE_SCENARIO_FULFILLED:
            let indexToDelete = contract.scenarios.findIndex((element) => {
                return element.scenarioId === action.payload;
            });

            if (indexToDelete > -1) {
                contract.scenarios.splice(indexToDelete, 1);
            }

            return {
                ...state,
                contract,
                updating: false,
                updated: true,
                updateFailed: false,
            };
        case DELETE_SCENARIO_REJECTED:
            return {
                ...state,
                updating: false,
                updated: false,
                updateFailed: true,
            };

        case GET_CONTRACT_APPROVERS_PENDING:
            return {
                ...state,
                approvalTree: [],
                approvalReason: {
                    approver: "",
                    reason: ""
                },
            }
        case GET_CONTRACT_APPROVERS_REJECTED:
            return {
                ...state,
                approvalTree: [],
                approvalReason: {
                    approver: "",
                    reason: ""
                },
            }
        case GET_CONTRACT_APPROVERS_FULFILLED:
            return {
                ...state,
                approvalTree: action.payload.ApprovalTree,
                approvalReason: action.payload.CurrentApprovalLevel,
                approvalItem: action.payload
            }
        case GET_WORKFLOW_USERS_PENDING:
            return {
                ...state,
                usersByRole: [],
            }
        case GET_WORKFLOW_USERS_FULFILLED:
            return {
                ...state,
                usersByRole: action.payload,
            }
        case GET_WORKFLOW_USERS_REJECTED:
            return {
                ...state,
            }
        default:
            return state;
    }
};
