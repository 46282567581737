import React from "react";
import NumberFormat from "react-number-format";

export default function format(value, formatSettings) {
    let decimalPlaces = formatSettings ? formatSettings.decimalPlaces : undefined;
    let forceNoDecimals = formatSettings ? formatSettings.forceNoDecimals : false;
    let displayAsNegative = formatSettings ? formatSettings.displayAsNegative : false;

    if (formatSettings) {
        if (formatSettings.type !== "string" && (typeof value === "object" || value === "" || value === null || value === undefined)) {
            return <NumberFormat fixedDecimalScale decimalScale={decimalPlaces ? decimalPlaces : 0} value={0} displayType={"text"} thousandSeparator={true} />;
        }

        if (displayAsNegative) {
            value = Math.abs(value) * -1;
        }

        switch (formatSettings.type) {
            case "percentage": {
                return <NumberFormat fixedDecimalScale decimalScale={decimalPlaces ? decimalPlaces : 1} value={value} displayType={"text"} thousandSeparator={true} suffix={"%"} />;
            }
            case "dollar": {
                return <NumberFormat fixedDecimalScale={!forceNoDecimals} decimalScale={decimalPlaces ? decimalPlaces : forceNoDecimals ? 0 : 2} value={value} displayType={"text"} thousandSeparator={true} prefix={"$"} />;
            }
            case "number": {
                return <NumberFormat fixedDecimalScale decimalScale={decimalPlaces ? decimalPlaces : 0} value={value} displayType={"text"} thousandSeparator={true} />;
            }
            case "dropdown": {
                return (
                    <Fragment>
                        <div style={{ paddingRight: "1em" }}>{value} </div>
                        <FeatherIcon style={{ width: "150%" }} icon="chevron-down" />
                    </Fragment>
                );
            }
            case "string": {
                return value;
            }
        }
    }

    return value;
}
