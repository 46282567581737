// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getRebates } from "../../../state/actions/RebateActions";

// UI
import { IndicatorTile } from "./IndicatorTile";

import { EditableInputTypes } from "../../../enums/EditableInputTypes";

// COMPONENT
class RebateIndicatorRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            indicators: [],
            loadingSpinner: false,
        };

        this.generateIndicators = this.generateIndicators.bind(this);
        this.parseNumericValue = this.parseNumericValue.bind(this);
    }

    parseNumericValue(key) {
        let parsed = parseFloat(this.props.rebates[key].numericValue);
        return !isNaN(parsed) && typeof parsed === "number" ? parsed : 0;
    }

    generateIndicators() {
        let rebates = this.props.rebates;

        let packagedConditional = false;
        let postmixConditional = false;

        let packagedTotal = 0;
        let postmixTotal = 0;

        if (rebates !== undefined) {
            if (rebates.perCase !== undefined) {
                packagedConditional = rebates.perCase.active || rebates.paPerCase.active ? true : false;
                // postmixConditional = ((rebates.perSellingLitre.active) || (rebates.paPerSellingLitre.active)) ? true : false;

                if (rebates.perCase.numericValue !== undefined) {
                    packagedTotal += this.parseNumericValue("perCase");
                }
                if (rebates.paPerCase.numericValue !== undefined) {
                    packagedTotal += this.parseNumericValue("paPerCase");
                }

                // if (rebates.perSellingLitre.numericValue !== undefined) { postmixTotal += this.parseNumericValue("perSellingLitre"); }
                // if (rebates.paPerSellingLitre.numericValue !== undefined) { postmixTotal += this.parseNumericValue("paPerSellingLitre"); }
            }
        }

        let indicators = [];
        indicators.push({ title: "Total Packaged", value: packagedConditional ? `Up to $${packagedTotal}` : `$${packagedTotal}` });
        // indicators.push({ 'title' : 'Total PostMix', 'value' : postmixConditional ? `Up to $${postmixTotal}` : `$${postmixTotal}` });
        this.setState({ indicators: indicators });
    }

    componentDidMount() {
        this.setState({ loadingSpinner: true });
        this.generateIndicators();
        this.props.getRebates(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => {
            if (success) {
                this.generateIndicators();
            }
            this.setState({ loadingSpinner: false });
        });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.rebates) !== JSON.stringify(prevProps.rebates)) {
            this.generateIndicators();
        }
    }

    render() {
        return (
            <div className="indicator-row">
                {this.state.indicators.map((data, index) => {
                    return <IndicatorTile loadingSpinner={this.state.loadingSpinner} key={index} title={data.title} value={data.value} type={data.type} />;
                })}
            </div>
        );
    }
}

RebateIndicatorRow.propTypes = {
    rebates: PropTypes.object.isRequired,
};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { rebates, fetched, fetching } = state.rebateReducer;
    return { rebates, fetched, fetching };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getRebates }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(RebateIndicatorRow));

// EXPORT COMPONENT

export { hoc as RebateIndicatorRow };
