import {
  START_WORKING,
  STOP_WORKING,
  SHOW_ERROR,
  HIDE_ERROR,
  SET_BUILD_NUMBER,
  CLEAR_REDUCERS,
  SET_CALCULATION_PENDING,
} from "../actions/GeneralActions";
import { CCAError } from "../../enums/CCAError";

// INITIALIZE STATE
const initialState = {
  working: false,
  errorShowing: false,
  error: undefined /* the CCAError object referencing the active error */,
  errorMessage: "",
  retryHandler: {},
  buildNumber: undefined,
  isCalculationPending: true, // Assume a calculation is pending intitially. We'll set this to false when we get the first calculation result.
};

// REDUCER
export const GeneralReducer = (state = initialState, action) => {

  switch (action.type) {
    case CLEAR_REDUCERS:
      return initialState;
    case START_WORKING: {
      return {
        ...state,
        working: true,
      };
    }
    case STOP_WORKING: {
      return {
        ...state,
        working: false,
      };
    }
    case SHOW_ERROR: {
      const { error, retryHandler } = action.payload;
      return {
        ...state,
        error: error,
        errorShowing: true,
        errorMessage: error.message,
        retryHandler,
      };
    }
    case HIDE_ERROR: {
      let error = action.payload;
      if (error === undefined || state.error === undefined) {
        return state;
      }

      /* If the current error code matches the incoming error code, it is to be hidden */
      if (
        error.code === state.error.code ||
        error.code === CCAError.DISMISS_ERROR.code
      ) {
        return {
          ...state,
          error: undefined,
          errorShowing: false,
          errorMessage: "",
        };
      } else {
        return state;
      }
    }
    case SET_BUILD_NUMBER: {
      return {
        ...state,
        buildNumber: action.payload,
      };
    }
    case SET_CALCULATION_PENDING: {
      return {
        ...state,
        isCalculationPending: action.payload,
      };
    }

    default:
      return state;
  }
};
