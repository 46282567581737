// IMPORT PACKAGE REFERENCES
import React from "react";

// COMPONENT
export class NormalCell extends React.Component {
    render() {
        let clickable = this.props.clickable !== undefined ? this.props.clickable : true;

        return (
            <div className={"normal-row" + (this.props.isLastCell ? " last" : "") + (clickable ? "" : " unclickable")}>
                <div className="normal-label">{this.props.data[this.props.identifiers.label]}</div>
                <div className="normal-subtitle">{this.props.data[this.props.identifiers.subtitle]}</div>
            </div>
        );
    }
}
