import axios from "axios";
import { API_ROOT } from "../../../config/config";

// GET CONTRACT DETAILS
export const GET_CONTRACT_DETAILS_PENDING = "GET_CONTRACT_DETAILS_PENDING";
export const GET_CONTRACT_DETAILS_FULFILLED = "GET_CONTRACT_DETAILS_FULFILLED";
export const GET_CONTRACT_DETAILS_REJECTED = "GET_CONTRACT_DETAILS_REJECTED";

export const SAVE_CONTRACT_DETAILS_PENDING = "SAVE_CONTRACT_DETAILS_PENDING";
export const SAVE_CONTRACT_DETAILS_FULFILLED = "SAVE_CONTRACT_DETAILS_FULFILLED";
export const SAVE_CONTRACT_DETAILS_REJECTED = "SAVE_CONTRACT_DETAILS_REJECTED";

// GET SCENARIO DETAILS
export const GET_SCENARIO_DETAILS_PENDING = "GET_SCENARIO_DETAILS_PENDING";
export const GET_SCENARIO_DETAILS_FULFILLED = "GET_SCENARIO_DETAILS_FULFILLED";
export const GET_SCENARIO_DETAILS_REJECTED = "GET_SCENARIO_DETAILS_REJECTED";

// SAVE SCENARIO DETAILS
export const SAVE_SCENARIO_DETAILS_PENDING = "SAVE_SCENARIO_DETAILS_PENDING";
export const SAVE_SCENARIO_DETAILS_FULFILLED = "SAVE_SCENARIO_DETAILS_FULFILLED";
export const SAVE_SCENARIO_DETAILS_REJECTED = "SAVE_SCENARIO_DETAILS_REJECTED";

// SET CURRENT COMPARISON SCENARIO
export const SET_COMPARISON_SCENARIO = "SET_COMPARISAON_SCENARIO";
export const SET_COMPARISON_SCENARIOS = "SET_COMPARISAON_SCENARIOS";

export const POPULATE_CONTRACT_DETAILS = "POPULATE_CONTRACT_DETAILS";

/*
    Retrieve all of the contract details
*/
const getContractDetails = (contractId, callback) => (dispatch) => {
    dispatch({
        type: GET_CONTRACT_DETAILS_PENDING,
    });

    axios
        .get(`${API_ROOT}/contracts/${contractId}/details`)
        .then((response) => {
            dispatch({
                type: GET_CONTRACT_DETAILS_FULFILLED,
                payload: response.data,
            });
            callback(true);
        })
        .catch((error) => {
            dispatch({
                type: GET_CONTRACT_DETAILS_REJECTED,
            });
            console.log("An error occurred retrieving contract details.", error);
            callback(false);
        });
};

/*
    Retrieve all of the details of a contract's scenario.
*/
const getScenarioDetails = (contractId, scenarioId, callback) => (dispatch) => {
    dispatch({
        type: GET_SCENARIO_DETAILS_PENDING,
    });

    axios
        .get(`${API_ROOT}/contracts/${contractId}/${scenarioId}/details`)
        .then((response) => {
            dispatch({
                type: GET_SCENARIO_DETAILS_FULFILLED,
                payload: response.data,
            });
            callback(true);
        })
        .catch((error) => {
            dispatch({
                type: GET_SCENARIO_DETAILS_REJECTED,
            });
            console.log("An error occurred getting contract details.", error);
            callback(false);
        });
};

/*
    Save the details of a contract's scenario.
*/
const saveScenarioDetails = (contractId, scenarioId, data, callback) => (dispatch) => {
    dispatch({
        type: SAVE_SCENARIO_DETAILS_PENDING,
    });

    axios
        .put(`${API_ROOT}/contracts/${contractId}/${scenarioId}/details`, { data: data })
        .then((response) => {
            dispatch({
                type: SAVE_SCENARIO_DETAILS_FULFILLED,
                payload: response.data,
            });
            callback(true);
        })
        .catch((error) => {
            dispatch({
                type: SAVE_SCENARIO_DETAILS_REJECTED,
            });
            console.log("An error occurred saving scenario details.", error);
            callback(false);
        });
};

/*
    Saves the details of a contract.
    Upon a successful response, updated data is dispatched to the reducer.
*/
const saveContractDetails = (contractId, data, option, callback) => (dispatch) => {
    dispatch({ type: SAVE_CONTRACT_DETAILS_PENDING });

    axios
        .put(`${API_ROOT}/contracts/${contractId}/details`, { data: data, option: option })
        .then((response) => {
            dispatch({ type: SAVE_CONTRACT_DETAILS_FULFILLED, payload: response.data });
            callback(true);
        })
        .catch((error) => {
            dispatch({ type: SAVE_CONTRACT_DETAILS_REJECTED });
            callback(false);
            console.log("An error occurred saving contract details\n", error);
        });
};

/*
    Set the current comparison scenario
*/
const setCurrentComparisonScenario = (id, callback) => (dispatch) => {
    dispatch({ type: SET_COMPARISON_SCENARIO, payload: id });
    callback();
};

/*
    Pass the contract details along to the ContractDetailsReducer
*/
const populateContractDetails = (details) => (dispatch) => {
    dispatch({ type: POPULATE_CONTRACT_DETAILS, payload: details });
};

export { getContractDetails, saveContractDetails, populateContractDetails, getScenarioDetails, saveScenarioDetails, setCurrentComparisonScenario };
