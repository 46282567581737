import axios from "axios";
import { API_ROOT } from "../../../config/config";
import { CCARequestTypes } from "../../enums/CCARequestTypes";

export const GET_DISCOUNTS_PENDING = "GET_DISCOUNTS_PENDING";
export const GET_DISCOUNTS_FULFILLED = "GET_DISCOUNTS_FULFILLED";
export const GET_DISCOUNTS_REJECTED = "GET_DISCOUNTS_REJECTED";

export const SAVE_DISCOUNTS_PENDING = "SAVE_DISCOUNTS_PENDING";
export const SAVE_DISCOUNTS_FULFILLED = "SAVE_DISCOUNTS_FULFILLED";
export const SAVE_DISCOUNTS_REJECTED = "SAVE_DISCOUNTS_REJECTED";

const saveDiscountConditions = (contractId, scenarioId, data, callback) => (dispatch) => {
    let requestId = CCARequestTypes.CONTRACT_DISCOUNT_SAVE;

    dispatch({ type: SAVE_DISCOUNTS_PENDING });

    axios
        .put(`${API_ROOT}/contracts/${contractId}/${scenarioId}/financialIncentives/discounts`, {
            data,
            requestId,
        })
        .then((response) => {
            dispatch({ type: SAVE_DISCOUNTS_FULFILLED, payload: response.data });
            callback(true, response.data);
        })
        .catch((error) => {
            dispatch({ type: SAVE_DISCOUNTS_REJECTED });
            callback(false);
            console.log("An error occurred creating the product group.", error);
        });
};

const getDiscounts = (contractId, scenarioId, callback) => (dispatch) => {
    dispatch({ type: GET_DISCOUNTS_PENDING });

    axios
        .get(`${API_ROOT}/contracts/${contractId}/${scenarioId}/financialIncentives/discounts`)
        .then((response) => {
            dispatch({ type: GET_DISCOUNTS_FULFILLED, payload: response.data });
            callback(true);
        })
        .catch((error) => {
            dispatch({ type: GET_DISCOUNTS_REJECTED });
            callback(false);
            console.log("Error retrieving discounts.", error);
        });
};

export { saveDiscountConditions, getDiscounts };
