// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// IMPORT PROJECT REFERENCES
import { getTemplates } from "../../state/actions/TemplateActions";

// UI
import { CELL_TYPES } from "./CellTypes";
import { ContextualSidebar } from "../../containers/layout/ContextualSidebar";
import { MasterDetailRouter } from "../../containers/layout/MasterDetailRouter";
import { PageTitle } from "../../containers/layout/PageTitle";
import { SimpleList } from "./lists/SimpleList";
import { TemplateDetailsPage } from "./TemplateDetailsPage";

// COMPONENT
class TemplatesPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleTypeClick = this.handleTypeClick.bind(this);
    }

    handleTypeClick(sectionIdentifier) {
        this.props.history.push(`?section=${sectionIdentifier}`);
    }

    render() {
        let sections = [
            {
                page: <TemplateDetailsPage />,
                sectionIdentifier: "my-templates",
                sectionHeader: "",
                cell: {
                    type: CELL_TYPES.NORMAL,
                    identifiers: { label: "My Templates" },
                },
            },
        ];

        return (
            <Fragment>
                <ContextualSidebar pageTitle={<PageTitle title={"Templates"} />} left={<SimpleList handleClick={this.handleTypeClick} sections={sections} />} right={<MasterDetailRouter sections={sections} />} />
            </Fragment>
        );
    }
}

TemplatesPage.propTypes = {
    contracts: PropTypes.object.isRequired,
    fetching: PropTypes.bool.isRequired,
    fetched: PropTypes.bool.isRequired,
    failed: PropTypes.bool.isRequired,
    getContracts: PropTypes.func.isRequired,
    history: PropTypes.object,
};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { templates, fetchingTemplates, fetchedTemplates, failedTemplates } = state.templateReducer;
    return { templates, fetchingTemplates, fetchedTemplates, failedTemplates };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getTemplates }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(TemplatesPage));

// EXPORT COMPONENT

export { hoc as TemplatesPage };
