import axios from "axios";
import { API_ROOT } from "../../../config/config";
import { CCARequestTypes } from "../../enums/CCARequestTypes";

export const GET_REBATES_PENDING = "GET_REBATES_PENDING";
export const GET_REBATES_FULFILLED = "GET_REBATES_FULFILLED";
export const GET_REBATES_REJECTED = "GET_REBATES_REJECTED";

export const SAVE_REBATES_PENDING = "SAVE_REBATES_PENDING";
export const SAVE_REBATES_FULFILLED = "SAVE_REBATES_FULFILLED";
export const SAVE_REBATES_REJECTED = "SAVE_REBATES_REJECTED";

const saveRebateConditions = (ccontractId, scenarioId, data, callback) => (dispatch) => {
    let requestId = CCARequestTypes.CONTRACT_REBATE_SAVE;

    dispatch({ type: SAVE_REBATES_PENDING });

    axios
        .put(`${API_ROOT}/contracts/${contractId}/${scenarioId}/financialIncentives/rebates`, {
            data,
            requestId,
        })
        .then((response) => {
            dispatch({ type: SAVE_REBATES_FULFILLED, payload: response.data });
            callback(true, response.data);
        })
        .catch((error) => {
            dispatch({ type: SAVE_REBATES_REJECTED });
            callback(false);
            console.log("An error occurred creating the product group.", error);
        });
};

const getRebates = (contractId, scenarioId, callback) => (dispatch) => {
    dispatch({ type: GET_REBATES_PENDING });

    axios
        .get(`${API_ROOT}/contracts/${contractId}/${scenarioId}/financialIncentives/rebates`)
        .then((response) => {
            dispatch({ type: GET_REBATES_FULFILLED, payload: response.data });
            callback(true);
        })
        .catch((error) => {
            dispatch({ type: GET_REBATES_REJECTED });
            callback(false);
            console.log("Error retrieving rebates.", error);
        });
};

export { saveRebateConditions, getRebates };
