import { GET_DISCOUNTS_PENDING, GET_DISCOUNTS_FULFILLED, GET_DISCOUNTS_REJECTED, SAVE_DISCOUNTS_PENDING, SAVE_DISCOUNTS_FULFILLED, SAVE_DISCOUNTS_REJECTED } from "../actions/DiscountActions";

// INITIALIZE STATE
const initialState = {
    discounts: {},
    fetching: false,
    fetched: false,
    failed: false,
    updating: false,
    updated: false,
    updateFailed: false,
};

import { CLEAR_REDUCERS, PREPARE_FOR_CONTRACT_SWITCH } from "../actions/GeneralActions";

// REDUCER
export const DiscountReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_REDUCERS:
            return initialState;
        case PREPARE_FOR_CONTRACT_SWITCH:
            return initialState;
        /* GET EQUIPMENTS */
        case GET_DISCOUNTS_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_DISCOUNTS_FULFILLED:
            return {
                ...state,
                discounts: action.payload,
                fetching: false,
                fetched: true,
                failed: false,
            };
        case GET_DISCOUNTS_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        case SAVE_DISCOUNTS_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
                updateFailed: false,
            };
        case SAVE_DISCOUNTS_FULFILLED:
            return {
                ...state,
                discounts: action.payload,
                updating: false,
                updated: false,
                updateFailed: true,
            };
        case SAVE_DISCOUNTS_REJECTED:
            return {
                ...state,
                updating: false,
                updated: false,
                updateFailed: true,
            };
        default:
            return state;
    }
};
