import axios from "axios";
import { API_ROOT } from "../../../config/config";
import { getContractApprovers } from "./ContractActions";

export const CLEAR_REDUCERS = "CLEAR_REDCUERS";
export const PREPARE_FOR_CONTRACT_SWITCH = "PREPARE_FOR_CONTRACT_SWITCH";

export const START_WORKING = "START_WORKING";
export const STOP_WORKING = "STOP_WORKING";

export const SHOW_ERROR = "SHOW_ERROR";
export const HIDE_ERROR = "HIDE_ERROR";

export const SET_CALCULATION_PENDING = "SET_CALCULATION_PENDING";


/* 
    Clear state in all reducers. If new reducers are written they need to handle
    the CLEAR_REDUCERS type to ensure all reducers are resetting their state.
*/
const clearReducers = () => (dispatch) => {
    dispatch({
        type: CLEAR_REDUCERS,
    });
};

/* 
    Clear state in necessary reducers such that the user is not briefly presented
    with details from the contract they were previously viewing when they view details
    of another contract.
*/
const prepareForContractSwitch = () => (dispatch) => {
    dispatch({
        type: PREPARE_FOR_CONTRACT_SWITCH,
    });
};

const startWorking = () => (dispatch) => {
    dispatch({
        type: START_WORKING,
    });
};

const stopWorking = () => (dispatch) => {
    dispatch({
        type: STOP_WORKING,
    });
};

/**
 * Dispatches the active error to the GeneralReducer.
 * After a set timeout, the HIDE_ERROR type is then dispatched to deactivate the error.
 *
 * @param {CCAError} error Error to display
 */
const showError = (error, retryHandler) => (dispatch) => {
    dispatch({
        type: SHOW_ERROR,
        payload: { error, retryHandler },
    });
};

/**
 * Dispatches the now inactive error to the GeneralReducer.
 *
 * @param {CCAError} error Error to hide
 */
const hideError = (error) => (dispatch) => {
    dispatch({
        type: HIDE_ERROR,
        payload: error,
    });
};


/**
 * Dispatch a change in calcuation state to the GeneralReducer.
 * Determines if there's a calculation in progress on the server and if so, used to configure the UI to indicate this.
 */
 const getIsCalculationPending = (contractId, scenarioId, callback) => (dispatch) => {
    if(!contractId || !scenarioId) return;

    axios
        .get(API_ROOT + `/contracts/${contractId}/${scenarioId}/computeState`)
        .then((res) => {
            if(res.data.state === 'complete'){
                dispatch({
                    type: SET_CALCULATION_PENDING,
                    payload: false,
                });
                dispatch(getContractApprovers(contractId, scenarioId));
                
                if(callback) callback(true);
                return;
            }

            // Assume calculation is pending until we get a response otherwise.
            dispatch({
                type: SET_CALCULATION_PENDING,
                payload: true,
            });
        })
        .catch((error) => {
            console.log("An error occurred requesting compute state.", error);

            /* Assume computing is in progress */
            dispatch({
                type: SET_CALCULATION_PENDING,
                payload: true
            });
        });
};

// EXPORT ACTIONS
export { clearReducers, prepareForContractSwitch, startWorking, stopWorking, showError, hideError, getIsCalculationPending };
