// IMPORT PACKAGE REFERENCES
import React from "react";
import ReactDOM from "react-dom";
import { Loading } from "../loading/Loading";

// COMPONENT
export default class ErrorPopup extends React.Component {
    render() {
        return ReactDOM.createPortal(
            <div className={"error-popup-page-container"}>
                <div className={"error-popup-container" + (this.props.showing ? "" : " hidden")}>{JSON.stringify(this.props.errorText)}</div>
            </div>,
            document.body
        );
    }
}
