// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FeatherIcon from "feather-icons-react";
import moment from "moment";

// ACTIONS
import { newTemplate } from "../state/actions/TemplateActions";
import { getAlerts } from "../state/actions/AlertActions";
import { NewTemplateModal } from "../pages/contract/additionals/NewTemplateModal";
import { getContract, deleteScenario, deleteContracts, getContractApprovers, requestContractApproval } from "../state/actions/ContractActions";
import { getScenarioDetails } from "../state/actions/ContractDetailsActions";
import { logout, unpinFromSidebar } from "../state/actions/UserActions";
import { resetMaterialOrdering } from "../state/actions/ProductGroupActions";

// UI
import Modalised from "../containers/layout/Modalised.js";
import { Loading } from "../containers/loading/Loading.js";
import { NewProgrammeModal } from "../pages/contract/additionals/NewProgrammeModal.js";
import { NewScenarioModal } from "../pages/contract/additionals/NewScenarioModal.js";
import InfoLoader from "../containers/layout/InfoLoader.js";
import { SummaryPin } from "./pins/SummaryPin.js";
import { GoalsAndNeedsPin } from "./pins/GoalsAndNeedsPin.js";
import { ExclusivityPin } from "./pins/ExclusivityPin.js";
import { KeyBusinessDriversPin } from "./pins/KeyBusinessDriversPin.js";
import { CustomerTargetPin } from "./pins/CustomerTargetPin";
import { Tooltip } from "reactstrap";
import { ScenarioPicker } from "./ScenarioPicker";
import { ApprovalPage } from "../pages/contract/Approvals/ApprovalPage";

const pages = {
    contract: {
        path: "contract",
        sectionName: "contract",
        previousSections: ["root"],
        dataSections: [
            {
                type: "summary",
            },
            {
                type: "pins",
                icon: "zap",
            },
        ],
        actions: [
            // {
            //     type: "template",
            //     name: "Create Template",
            //     icon: "copy",
            //     hidden: ["template", "programme"]
            // },
            // {
            //     type: "email",
            //     name: "Contract Approval",
            //     icon: "mail",
            //     hidden: ["template", "programme"]
            // },
            {
                type: "delete",
                name: "Delete Agreement",
                icon: "trash",
                hidden: ["template", "programme"],
                role: ["admin"],
            },
            // {
            //     type: "programme",
            //     name: "Create Programme",
            //     icon: "file-plus",
            //     admin: true,
            //     hidden: ['template', 'programme']
            // }
        ],

        sections: [
            {
                name: "",
                showSectionHeader: false,
                pages: [
                    {
                        custom: true,
                        typeId: 1,
                    },
                ],
            },
            {
                showSectionHeader: false,
                name: "Scenario Switcher",
                pages: [
                    {
                        typeId: 2,
                    },
                ],
            },
            {
                name: "Agreement",
                showSectionHeader: true,
                pages: [
                    {
                        name: "Details",
                        path: "details",
                        icon: "book-open",
                    },
                    {
                        name: "Products",
                        path: "products/",
                        icon: "box",
                    },
                    {
                        name: "Financial Incentives",
                        path: "financial-incentives/",
                        icon: "dollar-sign",
                    },
                    {
                        name: "Additional Incentives",
                        path: "equipment/",
                        icon: "plus-circle",
                    },
                    {
                        name: "Summary",
                        path: "summary",
                        icon: "pie-chart",
                        hidden: ["programme"],
                    },
                ],
            },
        ],
    },
    root: {
        path: "",
        sectionName: "Contracts",
        previousSections: [],
        actionSections: [
            {
                type: "search",
            },
        ],
        dataSections: [],
        sections: [
            {
                name: "Contracts",
                pages: [
                    {
                        name: "Contracts",
                        path: "/",
                        icon: "briefcase",
                    },
                    // {
                    //     name: "Templates",
                    //     path: "/templates",
                    //     icon: "clipboard"
                    // }
                ],
            },
            // {
            //     name: "Specifications",
            //     pages: [
            //         {
            //             name: "Programmes",
            //             path: "/programmes",
            //             icon: "file-text",
            //             disabled: false,
            //         }
            //     ]
            // },
            // {
            //     name: "Programmes",
            //     showSectionHeader: true,
            //     pages: [
            //         {
            //             name: "Programmes",
            //             path: "/programmes",
            //             icon: "file-text"
            //         },
            //     ]
            // },
            {
                name: "Data",
                role: ["admin"],
                pages: [
                    {
                        name: "Assumptions",
                        path: "/assumptions",
                        icon: "crosshair",
                    },
                    {
                        name: 'Rolling Sales',
                        path: '/rolling-sales',
                        icon: 'bar-chart'
                    },
                    {
                        name: "Floor Price Matrix",
                        path: "/floor-price-matrix",
                        icon: "grid",
                    },
                    {
                        name: "Segment Discount",
                        path: "/segment-discount",
                        icon: "folder-plus",
                    },
                    {
                        name: "Materials",
                        path: "/materials",
                        icon: "circle",
                        disabled: false,
                    },
                    {
                        name: "Equipment",
                        path: "/equipment",
                        icon: "box",
                        disabled: false,
                    },
                ],
            },
            ,
            {
                name: 'Settings',
                role: ['admin'],
                pages: [
                    {
                        name: 'Users',
                        path: '/users',
                        icon: 'user',
                        disabled: false
                    }
                ]
            }
        ],
    },
};

// COMPONENT
class Sidebar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeLinks: this.activeLinkSet(props.location),
            profitSummary: props.profitSummary,
            entering: false,
            templateModalShowing: false,
            programmeModalShowing: false,
            scenarioModalShowing: false,
            approvalModalShowing: false,
            details: {},
            contract: {},
            alerts: props.alerts,
            showingPopover: false,
            scenariosOpen: false,
            tooltipOpen: false,
            scenarioId: undefined,
        };

        this.toggle = this.toggle.bind(this);
        this.popSection = this.popSection.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.performAction = this.performAction.bind(this);
        this.requestReview = this.requestReview.bind(this);
    }

    showModal(type) {
        if (type === "template") {
            this.setState({ templateModalShowing: true });
        } else if (type === "programme") {
            this.setState({ programmeModalShowing: true });
        } else {
            this.setState({ scenarioModalShowing: true });
        }
    }

    deleteContract() {
        if (confirm("Are you sure you want to delete?")) {
            let contractsToDelete = [this.getContractIdFromUrl()];

            this.props.deleteContracts(contractsToDelete, (success) => {
                if (success) {
                    this.props.history.push("/");
                } else {
                    alert("Error deleting contract");
                }
            });
        }
    }

    requestReview() {
        this.setState({ approvalModalShowing: true });
    }

    performAction(type) {
        if (type === "email") {
            this.requestReview();
        } else if (type === "delete") {
            this.deleteContract();
        } else {
            this.showModal(type);
        }
    }

    hideModal(type) {
        if (type === "template") {
            this.setState({ templateModalShowing: false });
        } else if (type === "programme") {
            this.setState({ programmeModalShowing: false });
        } else if (type === "approval") {
            this.setState({ approvalModalShowing: false });
        } else {
            this.setState({ scenarioModalShowing: false });
        }
    }

    getContractIdFromUrl(providedProps) {
        let props = this.props;
        if (providedProps) {
            props = providedProps;
        }

        let components = props.location.pathname.split("/");
        return components[2];
    }

    getScenarioIdFromUrl(providedProps, newScenarioId = null) {
        if (newScenarioId) {
            return newScenarioId;
        }

        let props = this.props;

        if (providedProps) {
            props = providedProps;
        }

        let components = props.location.pathname.split("/");
        return components[3];
    }

    roundToTwo(num) {
        return parseFloat(Math.round(num * 100) / 100).toFixed(2);
    }

    numberOfUrlComponents(pathname) {
        let components = pathname.split("/");
        return components.length;
    }

    handleMenuItemClick(path) {
        this.props.history.push(path);
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        });
    }

    isPageActive(pagePath) {
        if (this.isRoot()) {
            return this.props.location.pathname === pagePath;
        } else {
            return this.props.location.pathname.includes(pagePath);
        }
    }

    activeLinkSet(location) {
        let isRootPage = this.numberOfUrlComponents(location.pathname) <= 3;
        if (isRootPage) {
            return pages.root;
        } else {
            return pages.contract;
        }
    }

    popSection(newSection) {
        this.props.history.push("/");
    }

    componentDidMount() {
        if (!this.isRoot()) {
            this.props.getContract(this.getContractIdFromUrl(), (contractSuccess) => {
                // Get alerts related to the contract...

                if (contractSuccess) {
                    this.getAlerts();
                } else {
                    console.log("Failed to retrieve contract:", this.getContractIdFromUrl());
                }
            });

            this.props.getScenarioDetails(this.getContractIdFromUrl(), this.getScenarioIdFromUrl(), () => { });
        }
    }

    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            let newActiveLinks = this.activeLinkSet(location);
            if (newActiveLinks.path !== this.state.activeLinks.path) {
                this.setState({ activeLinks: newActiveLinks });
            }
        });
    }

    getAlerts() {
        // Update alerts for the contract when the contract changes...
        this.props.getAlerts(this.getContractIdFromUrl(), this.getScenarioIdFromUrl(), (alertSuccess) => {
            if (!alertSuccess) console.log("Failed to retrieve contract alerts");
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.hasOwnProperty("contract")) {
            if (JSON.stringify(this.props.contract) !== JSON.stringify(this.state.contract)) {
                this.setState({ contract: this.props.contract });
                this.getAlerts();
            }

            if (JSON.stringify(this.props.contractDetails) !== JSON.stringify(this.state.details)) {
                this.setState({ details: this.props.contractDetails });
                this.getAlerts();
            }
        }

        let currentScenarioId = this.getScenarioIdFromUrl(this.props);
        if (currentScenarioId !== this.getScenarioIdFromUrl(prevProps)) {
            this.setState({ scenarioId: currentScenarioId });
            this.props.getScenarioDetails(this.getContractIdFromUrl(), this.getScenarioIdFromUrl(), () => { });
            this.props.resetMaterialOrdering();
        }

        
        if (JSON.stringify(this.props.scenarioDetails) !== JSON.stringify(this.state.scenarioDetails)) {
            this.setState({ scenarioDetails: this.props.scenarioDetails });
            this.getAlerts();
        }
        
        if (JSON.stringify(this.props.alerts) !== JSON.stringify(this.state.alerts)) {
            this.setState({ alerts: this.props.alerts });
        }
    }

    componentWillUnmount() {
        this.unlisten();
    }

    isRoot() {
        return this.activeLinkSet(this.props.location).previousSections.length === 0;
    }

    render() {
        /* Get the customer id from the URL */
        let isContractsPage = this.props.location.pathname.includes("/contracts") && this.props.location.pathname !== "/contracts/new";
        let customerId = this.getContractIdFromUrl();
        let scenarioId = this.getScenarioIdFromUrl();
        let contractsPrefix = "/contracts/" + customerId + "/" + scenarioId + "/";
        let contractTerm = "";
        let contractName = "";

        if (this.state.details) {
            if (this.state.details.general) {
                contractName = this.state.details.general.contractName;

                if (this.state.scenarioDetails) {
                    let startDate = this.state.details.general.contractStartDate;
                    let duration = this.state.scenarioDetails.contractDuration;

                    if (startDate && this.state.scenarioDetails.contractDuration) {
                        let endDate = moment(startDate, "DD/MM/YYYY").add(duration, "years").format("DD/MM/YYYY");
                        contractTerm = `${startDate} to ${endDate}`;
                    }
                }
            }
        }

        return (
            <Fragment>
                <Modalised
                    pageModal
                    title={"Approvals"}
                    action={
                        <div
                            onClick={() => {
                                this.hideModal("approval");
                            }}
                            className="text-link"
                        >
                            Close
                        </div>
                    }
                    handleClose={() => {
                        this.hideModal("approval");
                    }}
                    showing={this.state.approvalModalShowing}
                >
                    <ApprovalPage hideModal={() => this.hideModal("approval")} />
                </Modalised>

                <Modalised
                    pageModal
                    title={"Create a template"}
                    action={
                        <div
                            onClick={() => {
                                this.hideModal("template");
                            }}
                            className="text-link"
                        >
                            Close
                        </div>
                    }
                    handleClose={() => {
                        this.hideModal("template");
                    }}
                    showing={this.state.templateModalShowing}
                >
                    <NewTemplateModal hideModal={() => this.hideModal("template")} />
                </Modalised>

                <Modalised
                    pageModal
                    title={"Create a programme"}
                    action={
                        <div
                            onClick={() => {
                                this.hideModal("programme");
                            }}
                            className="text-link"
                        >
                            Close
                        </div>
                    }
                    handleClose={() => {
                        this.hideModal("programme");
                    }}
                    showing={this.state.programmeModalShowing}
                >
                    <NewProgrammeModal hideModal={() => this.hideModal("programme")} />
                </Modalised>

                <Modalised
                    pageModal
                    title={"New Scenario"}
                    action={
                        <div
                            onClick={() => {
                                this.hideModal("scenario");
                            }}
                            className="text-link"
                        >
                            <FeatherIcon icon="x" />
                        </div>
                    }
                    handleClose={() => {
                        this.hideModal("scenario");
                    }}
                    showing={this.state.scenarioModalShowing}
                >
                    <NewScenarioModal hideModal={() => this.hideModal("scenario")} />
                </Modalised>

                {!this.isRoot() && (
                    <div onClick={this.popSection} className={"sidebar-crumbs"}>
                        <div className="sidebar-crumb" style={{ marginRight: "2em" }}>
                            <FeatherIcon className="crumb-icon" size={18} icon={"arrow-left"} />
                            Back to Home
                        </div>
                    </div>
                )}

                {this.state.activeLinks.sections.map((section, index) => {
                    // Protect the route from users with invalid credentials...
                    if (section.hasOwnProperty("role")) {
                        if (this.props.user === undefined) {
                            return;
                        }

                        if (this.props.user.role === undefined) {
                            return;
                        }

                        if (!section.role.includes(this.props.user.role)) {
                            return;
                        }
                    }

                    return (
                        <div className="sidebar-links" key={index}>
                            {(section.showSectionHeader === undefined ? true : section.showSectionHeader) && <div className="section-name">{section.name}</div>}
                            {section.pages.map((page, index) => {
                                // Scenario Custom Action
                                if (page.typeId === 2) {
                                    return (
                                        <div key={index}>
                                            <div className="section-name">Currently Viewing</div>
                                            <ScenarioPicker
                                                toggleSwitching={() => {
                                                    this.setState({ switchingScenarios: !this.state.switchingScenarios });
                                                }}
                                                getScenarioDetails={this.props.getScenarioDetails}
                                                contractId={this.getContractIdFromUrl()}
                                                baseScenario={this.state.contract.baseScenario}
                                                scenarioId={this.getScenarioIdFromUrl(null, this.state.scenarioId)}
                                                location={this.props.location}
                                                history={this.props.history}
                                                deleteScenario={this.props.deleteScenario}
                                                showModal={this.showModal}
                                                scenarios={this.state.contract.scenarios}
                                            />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div key={index}>
                                            {page.custom ? (
                                                <div className="contract-button">
                                                    <Loading hidden={Object.keys(this.props.contract).length !== 0 ? true : !this.props.fetching} light medium />
                                                    <div className={"sidebar-item large-title"}>{contractName}</div>
                                                    <div className={"sidebar-term" + (this.state.contract.isTemplate || this.state.contract.isProgramme ? " no-padding" : "")}>
                                                        <FeatherIcon className="time-icon" size={16} icon="clock" />
                                                        {contractTerm}
                                                    </div>

                                                    {(this.state.contract.isTemplate || this.state.contract.isProgramme) && (
                                                        <div className="template-tag">
                                                            <div className={"status-tag"}>{this.state.contract.isProgramme ? "PROGRAMME" : "TEMPLATE"}</div>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <div
                                                    onClick={() => {
                                                        !this.isPageActive(page.path) && this.handleMenuItemClick(!this.isRoot() ? contractsPrefix + page.path : page.path);
                                                    }}
                                                    className={"sidebar-item" /* + ((Object.keys(this.props.contract).length > 0 ? false : this.props.fetching) ? ' hidden' : '') */ + (this.isPageActive(page.path) ? " active" : "") + (page.disabled ? " disabled" : "")}
                                                >
                                                    <FeatherIcon className={"sidebar-icon"} size={"18"} icon={page.icon} />
                                                    <div className={"sidebar-item-label"}>{page.name}</div>
                                                    {this.state.alerts[page.name] && (
                                                        <div className="alert-icon">
                                                            <span href="#" id="TooltipExample">
                                                                <FeatherIcon icon="alert-circle" />
                                                            </span>
                                                            <Tooltip placement="left" isOpen={this.state.tooltipOpen} target="TooltipExample" toggle={this.toggle}>
                                                                <div>
                                                                    {Object.keys(this.state.alerts[page.name]).map((alertKey) => {
                                                                        let alert = this.state.alerts[page.name][alertKey];
                                                                        return alert.message;
                                                                    })}
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    );
                })}

                {!this.isRoot() && (
                    <Fragment>
                        <div className="section-name">Actions</div>
                        {pages.contract.actions.map((action, index) => {
                            if (action.role == null || action.role.includes(this.props.user.role)) {
                                return (
                                    <div key={index} className={"sidebar-item"} onClick={() => this.performAction(action.type)}>
                                        <FeatherIcon className={"sidebar-icon"} size={"18"} icon={action.icon} />
                                        <div className={"sidebar-item-label"}>{action.name}</div>
                                    </div>
                                );
                            }

                        })}
                    </Fragment>
                )}

                {this.state.activeLinks.dataSections.map((page, index) => {
                    if (page.type === "pins") {
                        return (
                            !this.isRoot() &&
                            !this.state.contract.isProgramme && (
                                <div key={index}>
                                    {Object.keys(this.props.pinned).length > 0 && <div className="section-name">Pins</div>}

                                    <div className={"pin-container"}>
                                        {Object.keys(this.props.pinned).map((pinnedSection, index) => {
                                            let unpinElement = (
                                                <div className={"unpin"}>
                                                    <div
                                                        onClick={() => {
                                                            this.props.unpinFromSidebar(pinnedSection);
                                                        }}
                                                        className="unpin-button"
                                                    >
                                                        Unpin
                                                    </div>
                                                </div>
                                            );

                                            if (this.state.details) {
                                                if (pinnedSection === "keyBusinessDrivers" && this.state.details.hasOwnProperty("keyBusinessDrivers")) {
                                                    return <KeyBusinessDriversPin key={index} keyBusinessDrivers={this.state.details.keyBusinessDrivers} />;
                                                } else if (pinnedSection === "goalsAndNeeds" && this.state.details.hasOwnProperty("goalsAndNeeds")) {
                                                    return <GoalsAndNeedsPin key={index} goalsAndNeeds={this.state.details.goalsAndNeeds} />;
                                                } else if (pinnedSection === "exclusivity" && this.state.details.hasOwnProperty("exclusivity")) {
                                                    return <ExclusivityPin key={index} exclusivity={this.state.details.exclusivity} />;
                                                } else if (pinnedSection === "customerTargets" && this.state.details.customer) {
                                                    return <CustomerTargetPin key={index} contractId={this.getContractIdFromUrl()} customerDetails={this.state.details.customer} />;
                                                }
                                            }

                                            if (pinnedSection === "summary") {
                                                return <SummaryPin key={index} scenarioId={this.getScenarioIdFromUrl()} contractId={this.getContractIdFromUrl()} />;
                                            }
                                        })}
                                    </div>
                                </div>
                            )
                        );
                    }
                })}

                <InfoLoader showing={this.state.switchingScenarios} text={"Switching Scenarios"} />
            </Fragment>
        );
    }
}

Sidebar.propTypes = {
    location: PropTypes.object.isRequired,
    contract: PropTypes.object.isRequired,
    fetching: PropTypes.bool.isRequired,
    fetched: PropTypes.bool.isRequired,
    failed: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    profitSummary: PropTypes.object,
    contractDetails: PropTypes.object.isRequired,
};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { contract, fetching, fetched, failed, profitSummary } = state.contractReducer;
    const { alerts } = state.alertReducer;
    const { productGroups, productGroupsSummary } = state.productGroupReducer;
    const { pinned, user } = state.userReducer;
    const { contractDetails, scenarioDetails } = state.contractDetailsReducer;
    return { contract, profitSummary, fetching, fetched, failed, pinned, user, contractDetails, alerts, scenarioDetails, productGroups, productGroupsSummary };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getContract,
            getScenarioDetails,
            deleteScenario,
            deleteContracts,
            getContractApprovers,
            requestContractApproval,
            logout,
            newTemplate,
            getAlerts,
            resetMaterialOrdering,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));

// EXPORT COMPONENT

export { hoc as Sidebar };
