import { GET_SUBTRADE_CHANNEL_FULFILLED, GET_SUBTRADE_CHANNEL_PENDING, GET_SUBTRADE_CHANNEL_REJECTED } from "../actions/GeneralDetailsActions";

import { CLEAR_REDUCERS } from "../actions/GeneralActions";

// INITIALIZE STATE
const initialState = {
    generalDetails: {
        equipment: {},
    },
    fetchingGeneralDetails: false,
    fetchedGeneralDetails: false,
    failedGeneralDetails: false,
};

// REDUCER
export const GeneralDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_REDUCERS:
            return initialState;
        case GET_SUBTRADE_CHANNEL_PENDING:
            return {
                ...state,
                fetchingGeneralDetails: true,
                fetchedGeneralDetails: false,
                failedGeneralDetails: false,
            };
        case GET_SUBTRADE_CHANNEL_FULFILLED:
            return {
                ...state,
                generalDetails: action.payload,
                fetchingGeneralDetails: false,
                fetchedGeneralDetails: true,
                failedGeneralDetails: false,
            };
        case GET_SUBTRADE_CHANNEL_REJECTED:
            return {
                ...state,
                fetchingGeneralDetails: false,
                fetchedGeneralDetails: false,
                failedGeneralDetails: true,
            };
        default:
            return state;
    }
};
