// IMPORT PACKAGE REFERENCES
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// IMPORT PROJECT REFERENCES
import { getProductGroups, saveScenarioMaterialData } from "../../../state/actions/ProductGroupActions";
import { getCustomCompliance } from "../../../state/actions/ContractActions";
import { getContractSummarySnapshot } from "../../../state/actions/SummaryActions";
import { getCustomerTargets } from "../../../state/actions/CustomerTargetActions";

// UI
import { DataTable } from "./productGroup/DataTable";
import { Loading } from "../../../containers/loading/Loading";
import Placeholder from "../../../containers/layout/Placeholder";

export const FormContext = React.createContext();

// COMPONENT
class ProductDetailsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            addMaterialGroupModalShowing: false,
            splitMaterialGroupModalShowing: false,
            showingId: undefined,
            activeMaterialId: "",
            messageActive: false,
            messageLoading: false,
            message: "",
            loading: true,
            productGroups: {},
            tableDirection: "horizontal",
            showingPopover: false,
            columnsPopoverShowing: false,
            position: {},
            tableSize: {},
            columnsPopoverPosition: {},
            groups: props.groups,
        };

        this.handleProductGroupClicked = this.handleProductGroupClicked.bind(this);
        this.toggleNewProductGroupModal = this.toggleNewProductGroupModal.bind(this);
        this.handleNewProductGroupModalClose = this.handleNewProductGroupModalClose.bind(this);
        this.handleDataTableSave = this.handleDataTableSave.bind(this);
        this.recalculate = this.recalculate.bind(this);
    }

    handleNewProductGroupModalClose() {
        this.hideModal();

        // Refetch the new product groups.
        this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
    }

    toggleNewProductGroupModal() {
        this.setState({ modalShowing: !this.state.modalShowing });
    }

    hideModal() {
        this.setState({ modalShowing: false });
    }

    getContractIdFromUrl(providedProps) {
        let props = this.props;
        if (providedProps) {
            props = providedProps;
        }

        let components = props.location.pathname.split("/");
        return components[2];
    }

    getScenarioIdFromUrl(providedProps, newScenarioId = null) {
        if (newScenarioId) {
            return newScenarioId;
        }

        let props = this.props;

        if (providedProps) {
            props = providedProps;
        }

        let components = props.location.pathname.split("/");
        return components[3];
    }

    toggleAllMaterialGroups() {
        this.setState({ allMaterialGroupsVisible: !this.state.allMaterialGroupsVisible });
    }

    handleProductGroupClicked(id, section, sectionType) {
        this.props.history.push(id + `?section=${section}`);
    }

    componentDidMount() {
        // Fetch the product groups to show on the page.
        this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
        this.props.getCustomCompliance(this.props.match.params.contractId, (success => { }));

        setTimeout(() => {
            this.setState({ loading: false });
        }, 100);
        this.props.setFloatingButtons([{ type: "recalculate" }]);
        this.props.bindRefresh(this.recalculate);
    }

    handleDelete() {
        this.materialDataTable.handleDelete();
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(this.props.groups) !== JSON.stringify(this.state.groups)) {
            this.setState({ groups: this.props.groups });
        }

        if (JSON.stringify(this.props.productGroups) !== JSON.stringify(this.state.productGroups)) {
            this.setState({ productGroups: this.props.productGroups });
        }

        if (prevProps.match.params.scenarioId !== this.props.match.params.scenarioId) {
            this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
        }
    }

    recalculate() {
        this.setState({ loading: true });
        this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
        this.props.getCustomCompliance(this.props.match.params.contractId, (success => { }));

        setTimeout(() => {
            this.setState({ loading: false });
        }, 100);

        this.props.getContractSummarySnapshot(this.props.match.params.contractId, this.props.match.params.scenarioId);
    }

    handleDataTableSave(rows, completion) {
        let promises = [];

        let promise = new Promise((resolve, reject) => {
            this.props.saveScenarioMaterialData(this.props.match.params.contractId, this.props.match.params.scenarioId, rows, resolve, reject);
        });
        promises.push(promise);

        Promise.all(promises)
            .then((data) => {
                // When all the materials have been updated, recall the customer targets and topline summary.
                // this.props.getCustomerTargets(this.props.match.params.contractId, this.props.match.params.scenarioId)

                this.props.getContractSummarySnapshot(this.props.match.params.contractId, this.props.match.params.scenarioId);
                this.props.getAlerts();
                completion(true);
            })
            .catch((error) => {
                completion(false);
                console.log("Error...", error);
            });
    }

    render() {
        let activeScenarioId = this.props.match.params.scenarioId;

        let materials = [];
        if (this.state.productGroups) {
            let productGroups = this.state.productGroups[activeScenarioId];

            if (productGroups) {
                materials = Object.keys(productGroups).map((productGroupKey) => {
                    let rowItem = {};
                    rowItem["discounts"] = productGroups[productGroupKey].discounts === undefined ? {} : productGroups[productGroupKey].discounts;
                    rowItem["dataPoints"] = Object.values(productGroups[productGroupKey].materials)[0];
                    rowItem["productGroupId"] = productGroupKey;
                    return rowItem;
                });
            }
        }

        if (this.state.loading) {
            return <Loading />;
        }

        return (
            <div className="h-scroll-container -pad-lower">
                {materials.length === 0 ? (
                    <Placeholder type="no_materials" />
                ) : (
                <div className="material-data-table-container">
                        {this.props.fetching ? (
                            <Loading />
                        ) : (
                            <DataTable
                                isResizable={true}
                                enableRemove={this.props.enableRemove}
                                disableRemove={this.props.disableRemove}
                                sizeListener={this.tableSizeCallback}
                                direction={this.state.tableDirection}
                                customConditions={this.props.contract.customConditions}
                                onRef={this.props.onRef}
                                hideTitleBorder
                                handleSplit={(row) => {
                                    this.handleMaterialSplit(row);
                                }}
                                handleSave={this.handleDataTableSave}
                                groups={this.state.groups.filter((group) => {
                                    return group.active;
                                })}
                                rows={materials}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { productGroups, fetching } = state.productGroupReducer;
    const { contract } = state.contractReducer;
    return { productGroups, fetching, contract };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {   
            getProductGroups,
            saveScenarioMaterialData,
            getCustomerTargets,
            getContractSummarySnapshot,
            getCustomCompliance,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductDetailsPage));

// EXPORT COMPONENT
export { hoc as ProductDetailsPage };
