// IMPORT PACKAGE REFERENCES
import React from "react";
import FeatherIcon from "feather-icons-react";

// COMPONENT
// Use this component over Checkbox when the checkbox is being used in conjunction with a Formik form.
// Must pass in as props an onChange callback and name to set the desired value in the form.
export class FormCheckbox extends React.Component {
    render() {
        return (
            <div
                onClick={() => {
                    this.props.onChange(this.props.name, !this.props.checked);
                }}
                className={"float-right checkbox" + (this.props.checked ? " active" : "")}
            >
                <FeatherIcon icon="check"></FeatherIcon>
            </div>
        );
    }
}
