// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { getUser, loginWithToken, checkForOpenId } from "../state/actions/UserActions";
import meta from "../../config/meta.json";
import Cookies from "universal-cookie";
import { API_ROOT } from "../../config/config";
// UI
import { Button, Input } from "reactstrap";
import { Loading } from "../containers/loading/Loading";

const cookies = new Cookies();

// COMPONENT
class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password: "",
            username: "",
            hasTriedLogin: false,
            isRedirecting: false
        };

        this.emailChange = this.emailChange.bind(this);
        this.passwordChange = this.passwordChange.bind(this);
        // this.handleSubmitSmudge = this.handleSubmitSmudge.bind(this);
        // this.handleSubmitCCA = this.handleSubmitCCA.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.tokenReceived = this.tokenReceived.bind(this);
        this.pushToSSOCCA = this.pushToSSOCCA.bind(this);
        this.pushToSSOSmudge = this.pushToSSOSmudge.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.loggedIn) {
            this.props.history.push("/");
        } else if (nextProps.failed) {
            // If the login failed.
            console.log("Next props failed...");
            alert(nextProps.message);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.shouldUseSSO != prevProps.shouldUseSSO) {
            if (this.state.hasTriedLogin) {
                if (this.props.shouldUseSSO) {
                    this.pushToSSO();
                } else {
                    this.setState({ hasTriedLogin: false });
                }
            }
        }

        if (this.props.checkingSSOFulfilled != prevProps.checkingSSOFulfilled) {
            if (this.state.hasTriedLogin && this.props.shouldUseSSO) {
                this.pushToSSO();
            }
        }

        if (this.props.checkingSSOFailed != prevProps.checkingSSOFailed) {
            if (this.state.hasTriedLogin && this.props.shouldUseSSO) {
                this.pushToSSO();
            }
        }
    }

    componentDidMount() {
        if (this.props.loggedIn) {
            this.props.history.push("/");

            this.setState({
                isRedirecting: false,
                hasTriedLogin: false,
            });
        } else {
            const queryString = require("query-string");
            var parsed = queryString.parse(this.props.location.search);

            if (parsed.token != null && parsed.token != undefined) {
                cookies.set("authToken", parsed.token);
                this.tokenReceived();
            }
        }
    }

    pushToSSOSmudge() {
        this.setState({ isRedirecting: true });
        window.location = API_ROOT + "/login?email=test@smudge.com" + this.state.username;
    }

    pushToSSOCCA() {
        this.setState({ isRedirecting: true });
        window.location = API_ROOT + "/login?email=test@ccamatil.com" + this.state.username;
    }

    passwordChange(event) {
        this.setState({ password: event.target.value });
    }

    emailChange(event) {
        this.setState({ username: event.target.value });
    }

    handleSubmitCCA() {
        this.setState({ hasTriedLogin: true });
        this.props.checkForOpenId("user.name@ccamatil.com");
    }
    handleSubmitSmudge() {
        this.setState({ hasTriedLogin: true });
        this.props.checkForOpenId("user@smudge.com");
    }

    handleKeyPress(event) {
        if (event.key === "Enter") {
            this.handleSubmit(event);
        }
    }

    tokenReceived() {
        let cookieToken = cookies.get("authToken");
        if (cookieToken != undefined && cookieToken != null) {
            this.setState({ isRedirecting: true });
            this.props.loginWithToken(cookieToken);
        }
    }



    render() {
        return (
            <div className="container py-5">
                <div className="pattern" />
                <div className="login-container">
                    <Fragment>
                        <div className={"login-content" + (this.state.loggingIn ? " hidden" : "")}>
                            <img alt={"login logo"} className="ccep-logo" src={require("../../images/ccepWide.png")} />
                            <img alt={"login logo"} className="sama-logo" src={require("../../images/logo2.png")} />
                            <div className="welcome">Welcome</div>
                            <div className="welcome-message">Sign in with your credentials to manage Coca-Cola Europacific Partners customer contracts.</div>
                            <div className="login-form row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12 mx-auto">
                                            {!this.props.loggingIn && !this.state.isRedirecting && !this.props.checkingSSOPending && (
                                                <div>
                                                    <div className="form-group">
                                                        <Button className="login-input login-button button" onClick={this.pushToSSOCCA} type="primary">
                                                            Login
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                            {(this.props.loggingIn || this.state.isRedirecting || this.props.checkingSSOPending) && <Loading />}
                                            <div className="login-version">
                                                v{meta.versionNumber} - Build {meta.buildNumber}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"login-loading" + (!this.state.loggingIn ? " hidden" : "")}>
                            <Loading large noPadding fullHeight />
                        </div>
                    </Fragment>
                </div>
            </div>
        );
    }
}

LoginPage.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    loggingIn: PropTypes.bool.isRequired,
    failed: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    shouldUseSSO: PropTypes.bool.isRequired,
    loginWithToken: PropTypes.func.isRequired,
    checkForOpenId: PropTypes.func.isRequired,
    checkingSSOPending: PropTypes.bool.isRequired,
    checkingSSOFulfilled: PropTypes.bool.isRequired,
    checkingSSOFailed: PropTypes.bool.isRequired,
};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { loggedIn, loggingIn, failed, message, shouldUseSSO, checkingSSOPending, checkingSSOFulfilled, checkingSSOFailed } = state.userReducer;
    return { loggedIn, loggingIn, failed, message, shouldUseSSO, checkingSSOPending, checkingSSOFulfilled, checkingSSOFailed };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getUser, loginWithToken, checkForOpenId }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage));

// EXPORT COMPONENT

export { hoc as LoginPage };
