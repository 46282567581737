// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { ContextPage } from "../../../containers/layout/ContextPage";
import { Card } from "../../../containers/card/Card";
import { CardRow } from "../../../containers/card/CardRow";
import { CardActions } from "../../../containers/card/CardActions";
import { EditableInput } from "../../../containers/inputs/EditableInput";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

// IMPORT UI
import { Message } from "../../../containers/layout/Message";
import { EditableInputTypes } from "../../../enums/EditableInputTypes";
import { Conditionaliser } from "../../../../modules/conditionaliser/Conditionaliser";
import { CCAError } from "../../../enums/CCAError";
import { CCAInput } from "../../../containers/inputs/CCAInput";
import { CCAInputTypes } from "../../../enums/CCAInputTypes";

import { saveContractCustomConditions } from "../../../state/actions/ContractActions";
import { getNonFinancial, saveNonFinancial, getNonFinancialSummary, saveNonFinancialConditions } from "../../../state/actions/NonFinancialActions";
import { showError, hideError } from "../../../state/actions/GeneralActions";
import { getContractSummarySnapshot } from "../../../state/actions/SummaryActions";

// COMPONENT
class NonFinancialDetailsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: {
                1: false,
                2: false,
            },
            showingId: undefined,
            nonFinancialSaveLoading: false,
            conditionSaveLoading: false,
        };

        this.valuesAtLastSave = {};

        this.toggleEditing = this.toggleEditing.bind(this);
        this.handleSaveCustomConditions = this.handleSaveCustomConditions.bind(this);

        this.conditionaliser = null;
    }

    componentDidMount() {
        this.getNonFinancial();
    }

    componentDidUpdate() {
        this.getNonFinancial();
    }

    getNonFinancial() {
        if (this.state.showingId !== this.props.match.params.id) {
            this.props.getNonFinancial(this.props.match.params.contractId, this.props.match.params.scenarioId, this.props.match.params.id, () => {
                this.valuesAtLastSave = this.props.nonFinancial;
            });
            this.setState({ showingId: this.props.match.params.id });
        }
    }

    handleSubmit() {
        if (this.conditionaliser !== null) {
            let expressions = JSON.parse(this.conditionaliser.getExpressions());

            this.setState({ conditionSaveLoading: true });
            this.props.saveNonFinancialConditions(this.props.match.params.contractId, this.props.match.params.scenarioId, this.props.match.params.id, expressions, (success) => {
                if (success) {
                    this.setState({ conditionSaveLoading: false });
                    this.props.getContractSummarySnapshot(this.props.params.match.contractId, this.props.params.match.scenarioId);
                } else {
                    this.setState({ messageActive: true, messageError: true, message: "Error saving other non-financial conditions" });
                    setTimeout(() => {
                        this.setState({ messageActive: false, messageError: false, conditionSaveLoading: false });
                    }, 2000);
                }
            });
        }
    }

    toggleEditing(sectionNumber) {
        this.setState({ editing: { ...this.state.editing, [sectionNumber]: !this.state.editing[sectionNumber] } });
    }

    handleSaveCustomConditions(conditions) {
        this.setState({ messageActive: true, messageLoading: true, message: "Saving Custom Conditions" });

        this.props.saveContractCustomConditions(this.props.match.params.contractId, conditions, (success) => {
            this.setState({ messageActive: false, messageLoading: false });
            this.props.getContractSummarySnapshot(this.props.params.match.contractId, this.props.params.match.scenarioId);
        });
    }

    getNonFinancialSummary() {
        this.props.getNonFinancialSummary(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => {
            if (success) {
                this.props.hideError(CCAError.GET_NON_FINANCIAL_SUMMARY_ERROR);
            } else {
                this.props.showError(CCAError.GET_NON_FINANCIAL_SUMMARY_ERROR, () => {
                    this.getNonFinancialSummary();
                });
            }
        });
    }

    saveNonFinancial() {
        this.setState({ nonFinancialSaveLoading: true });
        this.props.saveNonFinancial(this.props.match.params.contractId, this.props.match.params.scenarioId, this.props.match.params.id, values, (success) => {
            if (success) {
                this.props.hideError(CCAError.SAVE_NON_FINANCIAL_ERROR);
                this.setState({ nonFinancialSaveLoading: false });
                this.valuesAtLastSave = values;
                this.props.getNonFinancialSummary();
                this.props.getContractSummarySnapshot(this.props.params.match.contractId, this.props.params.match.scenarioId);
            } else {
                this.props.showError(CCAError.SAVE_NON_FINANCIAL_ERROR, () => {
                    this.saveNonFinancial();
                });
            }
        });
    }

    render() {
        return (
            <ContextPage fetching={this.props.fetching} fetched={this.props.fetched} title={this.props.nonFinancial.type}>
                {Object.keys(this.props.nonFinancial).length === 0 ? (
                    <div className="empty-view">
                        <img alt={"empty icon"} className="empty-icon" src={require("../../../../images/empty-placeholder.png")} />
                        <h4>No item selected </h4>
                    </div>
                ) : (
                        <Formik
                            enableReinitialize={true}
                            initialValues={this.props.nonFinancial}
                            onSubmit={(values) => {
                                if (JSON.stringify(values) !== JSON.stringify(this.valuesAtLastSave)) {
                                    this.saveNonFinancial();
                                }
                            }}
                            render={({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldTouched, setFieldValue }) => (
                                <Fragment>
                                    <Card title={"Details"}>
                                        <CardRow label={"Name"} top>
                                            <CCAInput
                                                type={CCAInputTypes.FREE_TEXT}
                                                name="type"
                                                value={values.type}
                                                onChange={handleChange}
                                                onSave={(e) => {
                                                    handleSubmit(e);
                                                }}
                                            />
                                        </CardRow>

                                        <CardRow label={"Type"}>
                                            <CCAInput
                                                firstItemNone
                                                type={CCAInputTypes.SELECT}
                                                name="select"
                                                value={values.nonFinancialType}
                                                onChange={(e) => {
                                                    setFieldValue("nonFinancialType", e.target.value);
                                                }}
                                                onSave={(e) => {
                                                    handleSubmit(e);
                                                }}
                                                options={[
                                                    { saveChoice: "Rent", displayChoice: "Rent" },
                                                    { saveChoice: "Other", displayChoice: "Other" },
                                                ]}
                                                optionsAreObjects
                                                className={"borderless right"}
                                            />
                                        </CardRow>

                                        <CardRow label={"Estimated Value"}>
                                            <CCAInput
                                                type={CCAInputTypes.DOLLAR}
                                                name="value"
                                                value={values.value}
                                                onChange={(value) => {
                                                    setFieldValue("value", value);
                                                }}
                                                onSave={handleSubmit}
                                            />
                                        </CardRow>

                                        <CardRow label={"Quantity"}>
                                            <CCAInput
                                                type={CCAInputTypes.NUMBER}
                                                name="quantity"
                                                value={values.quantity}
                                                onChange={(value) => {
                                                    setFieldValue("quantity", value);
                                                }}
                                                onSave={handleSubmit}
                                            />
                                        </CardRow>

                                        <CardRow longform label={"Description"} bottom>
                                            <CCAInput
                                                type={CCAInputTypes.DESCRIPTION}
                                                name="description"
                                                value={values.description}
                                                onChange={handleChange}
                                                onSave={(e) => {
                                                    handleSubmit(e);
                                                }}
                                            />
                                        </CardRow>
                                    </Card>

                                    <Card
                                        title={"Conditions"}
                                        action={
                                            <CardActions
                                                handleClick={(e) => {
                                                    if (this.state.editing[2]) {
                                                        this.handleSubmit();
                                                    }

                                                    this.toggleEditing(2);
                                                }}
                                                editing={this.state.editing[2]}
                                                loading={this.state.conditionSaveLoading}
                                            />
                                        }
                                    >
                                        <Conditionaliser customConditions={this.props.contract.customConditions} saveCustomConditions={this.handleSaveCustomConditions} value={this.props.nonFinancial.conditions} allowResults={false} ref={(ref) => (this.conditionaliser = ref)} setSaveButtonState={() => { }} onChange={this.handleChange} editing={this.state.editing[2]} />
                                    </Card>
                                </Fragment>
                            )}
                        />
                    )}
            </ContextPage>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { nonFinancial, fetching, fetched, failed } = state.nonFinancialReducer;
    const { contract } = state.contractReducer;
    return { nonFinancial, fetching, fetched, failed, contract };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getNonFinancial,
            saveNonFinancial,
            getNonFinancialSummary,
            saveNonFinancialConditions,
            saveContractCustomConditions,
            showError,
            hideError,
            getContractSummarySnapshot,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(NonFinancialDetailsPage));

// EXPORT COMPONENT

export { hoc as NonFinancialDetailsPage };
