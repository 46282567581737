// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { Transition } from "react-transition-group";
import { Loading } from "../loading/Loading";

// COMPONENT
export class Popover extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayChildren: false,
            connectorPosition: { y: 0, x: 0 },
        };

        // Detect click outside the popover...
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    componentDidUpdate() {
        if (this.props.showing !== this.state.showing) {
            if (this.props.showing) {
                setTimeout(() => {
                    this.setState({ displayChildren: true });
                }, 100);
            }
            this.setState({ showing: this.props.showing, displayChildren: false });
        }

        if (JSON.stringify(this.props.connectorPosition) !== JSON.stringify(this.state.connectorPosition)) {
            this.setState({ connectorPosition: this.props.connectorPosition });
        }
    }

    /*
        Set the wrapper ref
    */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /*
        Alert if clicked on outside of element
    */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.showing) {
            this.props.hide();
        }
    }

    render() {
        let popoverSize = { top: 0, left: 0, width: 0, height: 0 };
        let leftPosition = 0;
        let top = 0;

        // Wait
        if (this.wrapperRef) {
            popoverSize = this.wrapperRef.getBoundingClientRect();

            if (this.state.connectorPosition) {
                top = this.state.connectorPosition.y + 30;

                if (this.props.right) {
                    leftPosition = this.state.connectorPosition.x - popoverSize.width;
                } else {
                    leftPosition = this.state.connectorPosition.x - popoverSize.width / 2;
                }
            }
        }

        return ReactDOM.createPortal(
            <div ref={this.setWrapperRef} className={"popover" + ` popover-${this.state.showing ? "active" : "inactive"}`} style={{ minHeight: this.props.minWidth ? this.props.minWidth : 160, minWidth: this.props.minWidth ? this.props.minWidth : 200, top: top, left: leftPosition }}>
                <div className={"popover-triangle-container" + (this.props.right ? " right" : " center")}>
                    <div className="popover-triangle-border"></div>
                    <div className={"popover-triangle" + (this.props.right ? " right" : "")}></div>
                </div>
                <div className={"popover-content" + (this.props.fixed ? " fixed" : "")}>{this.state.showing && this.state.displayChildren ? this.props.children : <Loading />}</div>
            </div>,
            document.body
        );
    }
}
