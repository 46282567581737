// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getContractSummarySnapshot, clearCurrentContractSummarySnapshot } from "../../state/actions/SummaryActions";
import { increaseSummaryYear, decreaseSummaryYear } from "../../state/actions/UserActions";
import { Button } from "reactstrap";
import format from "../../../helpers/formatter";
import FeatherIcon from "feather-icons-react";
import { Loading } from "../../containers/loading/Loading";
import { ConsolidatedCashFlowPage } from "../../pages/contract/summary/ConsolidatedCashFlowPage";
import NewWindow from "react-new-window";

// COMPONENT
class SummaryPin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentContractSummarySnapshot: props.currentContractSummarySnapshot,
            previousContractSummarySnapshot: props.previousContractSummarySnapshot,
            fetchingContractSummarySnapshot: props.fetchingContractSummarySnapshot,
            fetchedContractSummarySnapshot: props.fetchedContractSummarySnapshot,
            failedContractSummarySnapshotFetch: props.failedContractSummarySnapshotFetch,
            productGroups: {},
            summaryYear: props.summaryYear,
            dataPointsToGraph: props.dataPointsToGraph,
            decreasing: false,
            increasing: true,
            fetchingNewDataPointSet: true,
            popout: false,
        };
    }

    componentDidMount() {
        this.fetchedContractSummarySnapshot(this.state.summaryYear);
    }

    fetchedContractSummarySnapshot() {
        this.props.getContractSummarySnapshot(this.props.contractId, this.props.scenarioId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.scenarioId !== prevProps.scenarioId || this.props.contractId !== prevProps.contractId) {
            this.fetchedContractSummarySnapshot();
        }

        if (JSON.stringify(this.props.dataPointsToGraph) !== JSON.stringify(this.state.dataPointsToGraph)) {
            this.setState({ dataPointsToGraph: this.props.dataPointsToGraph });

            // Fetch new data when the pin set changes...
            this.fetchedContractSummarySnapshot();
        }

        if (JSON.stringify(this.props.currentContractSummarySnapshot) !== JSON.stringify(this.state.currentContractSummarySnapshot)) {
            this.setState({ currentContractSummarySnapshot: this.props.currentContractSummarySnapshot });
        }

        if (this.props.fetchedContractSummarySnapshot && this.state.fetchingNewDataPointSet) {
            this.setState({ fetchingNewDataPointSet: false });
        }

        if (JSON.stringify(this.props.previousContractSummarySnapshot) !== JSON.stringify(this.state.previousContractSummarySnapshot) && this.props.fetchedContractSummarySnapshot) {
            this.setState({ previousContractSummarySnapshot: this.props.previousContractSummarySnapshot });
        }

        if (this.props.failedContractSummarySnapshotFetch !== this.state.failedContractSummarySnapshotFetch) {
            this.setState({ failedContractSummarySnapshotFetch: this.props.failedContractSummarySnapshotFetch });
        }

        if (this.props.summaryYear !== this.state.summaryYear) {
            this.setState({ summaryYear: this.props.summaryYear });
        }
    }

    increaseSummaryYear() {
        let contractDuration = this.props.scenarioDetails.contractDuration;
        let summaryYear = this.state.summaryYear;

        if (summaryYear < contractDuration - 1) {
            this.setState({ fetchingNewDataPointSet: true });
            this.props.increaseSummaryYear();
            this.props.clearCurrentContractSummarySnapshot();
            this.fetchedContractSummarySnapshot(summaryYear + 1);
        }
    }

    decreaseSummaryYear() {
        let summaryYear = this.state.summaryYear;

        if (summaryYear > -1) {
            this.setState({ fetchingNewDataPointSet: true });
            this.props.decreaseSummaryYear();
            this.props.clearCurrentContractSummarySnapshot();
            this.fetchedContractSummarySnapshot(summaryYear - 1);

            // this.setState({ decreasing: true })
            // setTimeout(() => {
            //     this.setState({ decreasing: false })
            // }, 1000)
        }
    }

    render() {
        let dataPoints = Object.keys(this.state.dataPointsToGraph).filter((dataPointKey) => {
            let formatSpec = this.state.dataPointsToGraph[dataPointKey].format;
            let isTotal = formatSpec.total === undefined ? false : formatSpec.total;

            if (this.state.summaryYear === -1) {
                return isTotal;
            }

            return !isTotal;
        });

        return (
            <Fragment>
                <div className="menu-subtitle">
                    <div
                        onClick={() => {
                            this.setState({ popout: !this.state.popout });
                        }}
                    >
                        Summary
                    </div>
                    <div className="stepper">
                        <div className="stepper-year">{this.state.summaryYear === -1 ? "All" : `Year ${this.state.summaryYear + 1}`} </div>
                        <Button className="stepper-button" onClick={() => this.decreaseSummaryYear()} color="primary">
                            <FeatherIcon icon="chevron-left" />
                        </Button>
                        <Button className="stepper-button" onClick={() => this.increaseSummaryYear()} color="primary">
                            <FeatherIcon icon="chevron-right" />
                        </Button>
                    </div>
                </div>
                <div className={"sidebar-summary-section" + (this.state.decreasing ? " decreasing" : "") + (this.state.increasing ? " increasing" : "")}>
                    {this.state.fetchingNewDataPointSet && (
                        <div className="sidebar-summary-loading-container">
                            <Loading medium noPadding />
                        </div>
                    )}

                    {dataPoints.length === 0 && <div className={"sidebar-tile empty"}>No data points.</div>}
                    {dataPoints.map((summaryValue, index) => {
                        let formatSpec = this.state.dataPointsToGraph[summaryValue].format;

                        if (this.state.summaryYear !== -1 && formatSpec.total) {
                            return;
                        }

                        let currentValue = this.state.currentContractSummarySnapshot[summaryValue];
                        let previousValue = this.state.previousContractSummarySnapshot[summaryValue];

                        let changeOccurred = false;
                        let increased = false;
                        let changePercentage = 0;

                        if (currentValue !== previousValue) {
                            changeOccurred = true;

                            if (currentValue > previousValue) {
                                increased = true;
                            }

                            changePercentage = Math.abs(((currentValue - previousValue) / currentValue) * 100);

                            if (isNaN(changePercentage) || !isFinite(changePercentage) || changePercentage === 0) {
                                changeOccurred = false;
                            }
                        }

                        return (
                            <div key={index} className={`sidebar-tile ${index === this.state.dataPointsToGraph.length - 1}` /* + (!neutral ? (improved ? ' up' : ' down') : ' neutral') */}>
                                <div className="sidebar-tile-title">{summaryValue}</div>
                                <div className="sidebar-value-container">
                                    <div className={"data-value"}>
                                        {format(this.state.currentContractSummarySnapshot[summaryValue], formatSpec)}
                                        <span className={"data-change" + (changeOccurred ? " visible" : " hidden") + (increased ? " increase" : " decrease")}> ({(increased ? "+" : "-") + Math.round(changePercentage) + "%"})</span>
                                    </div>
                                </div>
                                {/* { this.state.popout && <NewWindow><ConsolidatedCashFlowPage contractId={this.props.match.params.contractId} scenarioId={this.props.match.params.scenarioId}/></NewWindow>} */}
                            </div>
                        );
                    })}
                </div>
            </Fragment>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { currentContractSummarySnapshot, previousContractSummarySnapshot, fetchedContractSummarySnapshot, failedContractSummarySnapshotFetch, dataPoints } = state.summaryReducer;

    const { productGroups, productGroupsSummary } = state.productGroupReducer;

    const { dataPointsToGraph, summaryYear } = state.userReducer;

    const { scenarioDetails } = state.contractDetailsReducer;

    return {
        dataPoints,
        productGroups,
        productGroupsSummary,
        currentContractSummarySnapshot,
        previousContractSummarySnapshot,
        fetchedContractSummarySnapshot,
        failedContractSummarySnapshotFetch,
        dataPointsToGraph,
        summaryYear,
        scenarioDetails,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getContractSummarySnapshot,
            increaseSummaryYear,
            decreaseSummaryYear,
            clearCurrentContractSummarySnapshot,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(SummaryPin));

// EXPORT COMPONENT

export { hoc as SummaryPin };
