// IMPORT PACKAGE REFERENCES
import { combineReducers } from "redux";

// IMPORT REDUCERS
import { UserReducer } from "./UserReducer";
import { CustomerReducer } from "./CustomerReducer";
import { NavigationReducer } from "./NavigationReducer";
import { ContractReducer } from "./ContractReducer";
import { MaterialReducer } from "./MaterialReducer";
import { EquipmentReducer } from "./EquipmentReducer";
import { NonFinancialReducer } from "./NonFinancialReducer";
import { ProductGroupReducer } from "./ProductGroupReducer";
import { GeneralDetailsReducer } from "./GeneralDetailsReducer";
import { PaymentReducer } from "./PaymentReducer";
import { RebateReducer } from "./RebateReducer";
import { ContractDetailsReducer } from "./ContractDetailsReducer";
import { SummaryReducer } from "./SummaryReducer";
import { DiscountReducer } from "./DiscountReducer";
import { TemplateReducer } from "./TemplateReducer";
import { AlertReducer } from "./AlertReducer";
import { ConfigurationReducer } from "./ConfigurationReducer";
import { GeneralReducer } from "./GeneralReducer";
import { CustomerTargetReducer } from "./CustomerTargetReducer";
import { DocumentReducer } from "./DocumentReducer";

// EXPORT APP REDUCER
export const AppReducer = combineReducers({
    alertReducer: AlertReducer,
    userReducer: UserReducer,
    customerReducer: CustomerReducer,
    navigationReducer: NavigationReducer,
    contractReducer: ContractReducer,
    generalDetailsReducer: GeneralDetailsReducer,
    materialReducer: MaterialReducer,
    equipmentReducer: EquipmentReducer,
    paymentReducer: PaymentReducer,
    nonFinancialReducer: NonFinancialReducer,
    rebateReducer: RebateReducer,
    productGroupReducer: ProductGroupReducer,
    contractDetailsReducer: ContractDetailsReducer,
    summaryReducer: SummaryReducer,
    discountReducer: DiscountReducer,
    templateReducer: TemplateReducer,
    configurationReducer: ConfigurationReducer,
    generalReducer: GeneralReducer,
    customerTargetReducer: CustomerTargetReducer,
    documentReducer: DocumentReducer,
});
