// IMPORT PACKAGE REFERENCES
import React from "react";
import moment from "moment";

// COMPONENT
export class TemplateCell extends React.Component {
    render() {
        return (
            <div className="normal-row">
                <div className="normal-label">{this.props.data[this.props.identifiers.label]}</div>
                <div className="normal-subtitle">Created {moment(this.props.data[this.props.identifiers.createdAt]).fromNow()}</div>
                {this.props.data[this.props.identifiers.associatedContractName] !== "" && this.props.data[this.props.identifiers.associatedContractName] && <div className={"status-tag"}>{this.props.data[this.props.identifiers.associatedContractName]}</div>}
            </div>
        );
    }
}
