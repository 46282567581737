/** IMPORT PACKAGE REFERENCES */
import React, { Fragment } from "react";
import { Row, Col } from "reactstrap";
import { translate } from "../../../modules/conditionaliser/ConditionaliserHelper";
var numeral = require("numeral");

export class FlexibleDataTable extends React.Component {
    constructor(props) {
        super(props);

        this.renderTextCell = this.renderTextCell.bind(this);
        this.renderNumberCell = this.renderNumberCell.bind(this);
        this.renderDollarCell = this.renderDollarCell.bind(this);
        this.renderPercentCell = this.renderPercentCell.bind(this);
        this.renderSwitch = this.renderSwitch.bind(this);
    }

    renderTextCell(column, index) {
        if (!column.data) {
            column.data = "";
        }
        return <Col key={index}> {column.data} </Col>;
    }

    renderNumberCell(column, index) {
        if (!column.data) {
            column.data = 0;
        }
        let cellValue = column.conditionalised ? `Up to ${numeral(column.data).format("0,0")}` : `${numeral(column.data).format("0,0")}`;
        return <Col key={index}> {cellValue} </Col>;
    }

    renderDollarCell(column, index) {
        if (!column.data) {
            column.data = 0;
        }
        let cellValue = column.conditionalised ? `Up to $${numeral(column.data).format("0,00.00")}` : `$${numeral(column.data).format("0,00.00")}`;
        return <Col key={index}> {cellValue} </Col>;
    }

    renderPercentCell(column, index) {
        if (!column.data) {
            column.data = 0;
        }
        let cellValue = column.conditionalised ? `Up to ${numeral(column.data).format("0,0.0")}%` : `${numeral(column.data).format("0,0.0")}%`;
        return <Col key={index}> {cellValue} </Col>;
    }

    renderSwitch(row, index) {
        switch (row.format.type) {
            case "text":
                return this.renderTextCell({ conditionalised: false, data: row.value }, index);
            case "number":
                return this.renderNumberCell({ conditionalised: false, data: row.value }, index);
            case "dollar":
                return this.renderDollarCell({ conditionalised: false, data: row.value }, index);
            case "percent":
                return this.renderPercentCell({ conditionalised: false, data: row.value }, index);
        }
    }

    /**
     * Simple Table consists of only 2 columns: label, value
     */
    renderSimpleTable() {
        return this.props.rows.map((row, index) => {
            return (
                <div key={index} className="flexible-row">
                    <div className="flexible-element left"> {row.label} </div>
                    {row.format === undefined ? (
                        <div className="flexible-element right">
                            {" "}
                            {row.hasOwnProperty("secondaryValue")
                                ? row.hasOwnProperty("tertiaryValue") && row.value > 1 ?
                                    <Col key={index}> Amount: {row.value}<br></br>Value: ${row.secondaryValue}<br></br>Total: ${row.tertiaryValue} </Col>
                                    : <Col key={index}> Amount: {row.value}<br></br>Value: ${row.secondaryValue} </Col>
                                : <Col key={index}> {row.value} </Col>} {" "}
                        </div>
                    ) : (
                        <div className="flexible-element right"> {this.renderSwitch(row, index)} </div>
                    )}
                </div>
            );
        });
    }

    /**
     * Column table consists of any given number of rows and columns and optionally headers for each column
     */
    renderColumnTable() {
        return (
            <Fragment>
                <Row className="flexible-row header">
                    {this.props.columnHeaders.map((header, index) => {
                        return <Col key={index}> {header} </Col>;
                    })}
                </Row>

                {this.props.rows.map((row, index) => {
                    if (row.rowType === "conditions") {
                        return (
                            <Row key={index} className="flexible-row">
                                <ul className="condition-translations">
                                    {row.rental != null && <li>{row.rental}</li>}
                                    {row.data.length >= 1 &&
                                        row.data.map((condition) => {
                                            if (condition != null) return <li>{translate(condition)}</li>;
                                        })
                                    }
                                </ul>
                            </Row>
                        );
                    }

                    return (
                        <Row key={index} className="flexible-row">
                            {row.columns.map((column, colIndex) => {
                                if (this.props.valuesLoading && column.calculated) {
                                    return <Col key={colIndex}> ... </Col>;
                                }

                                switch (column.cellType) {
                                    case "text":
                                        return this.renderTextCell(column, colIndex);
                                    case "number":
                                        return this.renderNumberCell(column, colIndex);
                                    case "dollar":
                                        return this.renderDollarCell(column, colIndex);
                                    case "percent":
                                        return this.renderPercentCell(column, colIndex);
                                }
                            })}
                        </Row>
                    );
                })}
            </Fragment>
        );
    }

    render() {
        return (
            <div className="flexible-item">
                {/* Table Header */}
                <div className={`flexible-header ${this.props.headerColour}`}>
                    {this.props.header}

                    {this.props.headerAction && (
                        <div onClick={this.props.headerAction} className="flexible-header-action">
                            {this.props.headerActionText}
                        </div>
                    )}
                    {this.props.headerElement && (<div style={{ width: "50%" }}> {this.props.headerElement}</div>)}
                </div>

                {/* Simple Table rows */}
                {this.props.simpleTable && this.renderSimpleTable()}

                {/* Multiple Column Table */}
                {this.props.columnTable && this.renderColumnTable()}
            </div>
        );
    }
}
