// IMPORT PACKAGE REFERENCES
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ContextPage } from "../../../containers/layout/ContextPage";
import moment from "moment";
import { Button } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import PrintProvider, { Print } from 'react-easy-print';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
// API IMPORT
import { API_ROOT } from "../../../../config/config";
import { PricingCompliance } from "../products/PricingCompliance";

// IMPORT ACTIONS
import { getScenarioDetails, getContractDetails, setCurrentComparisonScenario } from "../../../state/actions/ContractDetailsActions";
import { getEquipments } from "../../../state/actions/EquipmentActions";
import { getProductGroups } from "../../../state/actions/ProductGroupActions";
import { getPayments, getNovelties } from "../../../state/actions/PaymentActions";
import { getNonFinancials } from "../../../state/actions/NonFinancialActions";
import { getMaterialGroupDataPoint, getAnnualInvestmentSummary, getMaterialSummary, getApproval, getAdditionalComments, postAdditionalComments } from "../../../state/actions/SummaryActions";
import { getContract } from "../../../state/actions/ContractActions";

// IMPORT UI
import { FlexibleDataTable } from "../../../containers/table/FlexibleDataTable";
import { ScenarioChooser } from "../products/ScenarioChooser";
import { eq, isNumber } from "lodash";

export const FormContext = React.createContext();

// COMPONENT
class CustomerReviewPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            equipments: {},
            annualInvestmentSummary: props.annualInvestmentSummary,
            scenarioChooserShowing: false,
            scenarios: [],
            fetching: false,
            fetched: false,
            approval: {},
        };

        this.scenarioId = props.match.params.scenarioId;
        this.contractId = props.match.params.contractId;

        this.getAnnualInvestmentSummary = this.getAnnualInvestmentSummary.bind(this);
        this.calculateAnnualInvestmentSummaries = this.calculateAnnualInvestmentSummaries.bind(this);
        this.calculateMaterialSummary = this.calculateMaterialSummary.bind(this);
        this.launchScenarioChooser = this.launchScenarioChooser.bind(this);
        this.closeScenarioChooser = this.closeScenarioChooser.bind(this);
        this.handleScenarioIdSelected = this.handleScenarioIdSelected.bind(this);
        this.setFloatingButtons = this.setFloatingButtons.bind(this);
    }

    componentDidMount() {
        this.getDetails();
        this.calculateAnnualInvestmentSummaries();
        this.calculateMaterialSummary();
        this.props.getAdditionalComments(this.contractId, this.props.match.params.scenarioId);
        this.props.getApproval(this.contractId, this.props.match.params.scenarioId);

        this.props.getContract(this.contractId, (success) => {
            if (success) {
                let scenarios = Object.values(this.props.contract.scenarios).map((value) => {
                    return value.scenarioId;
                });

                this.setState({ scenarios: scenarios });
            }
        });
    }

    calculateMaterialSummary() {
        this.props.getMaterialSummary(this.contractId, this.scenarioId, this.props.annualInvestmentSummaryTimestamp, () => { });
    }

    calculateAnnualInvestmentSummaries() {
        this.getAnnualInvestmentSummary(this.contractId, this.scenarioId, this.props.annualInvestmentSummaryTimestamp, false);
        this.props.comparisonScenario ? this.getAnnualInvestmentSummary(this.contractId, this.props.comparisonScenario, this.props.annualInvestmentSummaryTimestamp, true) : null;
    }

    setFloatingButtons(buttons) {
        this.setState({ floatingButtons: [] });
    }

    getDetails() {
        let scenarioDetailsPromise = new Promise((resolve, reject) =>
            this.props.getScenarioDetails(this.contractId, this.scenarioId, (success) => {
                resolve();
            })
        );
        let equipmentsPromise = new Promise((resolve, reject) =>
            this.props.getEquipments(this.contractId, this.scenarioId, (success) => {
                resolve();
            })
        );
        let productGroupsPromise = new Promise((resolve, reject) =>
            this.props.getProductGroups(this.contractId, this.scenarioId, (success) => {
                resolve();
            })
        );
        let paymentsPromise = new Promise((resolve, reject) =>
            this.props.getPayments(this.contractId, this.scenarioId, (success) => {
                resolve();
            })
        );
        let noveltiesPromise = new Promise((resolve, reject) =>
            this.props.getNovelties(this.contractId, this.scenarioId, (success) => {
                resolve();
            })
        );
        let nonFinancialsPromise = new Promise((resolve, reject) =>
            this.props.getNonFinancials(this.contractId, this.scenarioId, (success) => {
                resolve();
            })
        );
        let contractDetailsPromise = new Promise((resolve, reject) =>
            this.props.getContractDetails(this.contractId, (success) => {
                resolve();
            })
        );

        this.setState({
            fetching: true,
            fetched: false,
        });

        Promise.all([scenarioDetailsPromise, equipmentsPromise, productGroupsPromise, paymentsPromise, noveltiesPromise, nonFinancialsPromise, contractDetailsPromise]).then(() => {
            this.setState({
                fetching: false,
                fetched: true,
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.scenarioId !== this.props.match.params.scenarioId) {
            this.scenarioId = this.props.match.params.scenarioId;
            this.getDetails();
            this.calculateAnnualInvestmentSummaries();
            this.calculateMaterialSummary();
            this.props.getApproval(this.contractId, this.props.match.params.scenarioId);
        }

        if (JSON.stringify(this.props.equipments) !== JSON.stringify(this.state.equipments)) {
            this.setState({ equipments: this.props.equipments });
        }

        if (JSON.stringify(prevState.annualInvestmentSummary) !== JSON.stringify(this.props.annualInvestmentSummary)) {
            this.setState({ annualInvestmentSummary: this.props.annualInvestmentSummary });
        }

        /* Check if the currently selected scenario for comparison has updated and re-calculate summary if so */
        if (prevProps.comparisonScenario !== this.props.comparisonScenario) {
            this.getAnnualInvestmentSummary(this.contractId, this.props.comparisonScenario, this.props.annualInvestmentSummaryTimestamp, true, true);
        }

        if (JSON.stringify(prevState.annualInvestmentSummaryComparison) !== JSON.stringify(this.props.annualInvestmentSummaryComparison)) {
            this.setState({ annualInvestmentSummaryComparison: this.props.annualInvestmentSummaryComparison });
        }

        if (JSON.stringify(this.props.approval) !== JSON.stringify(this.state.approval)) {
            this.setState({ approval: this.props.approval });
        }
    }

    getAnnualInvestmentSummary(contractId, scenarioId, lastCalculated, isComparison = false, forceRecalculate = false) {
        this.props.getAnnualInvestmentSummary(contractId, scenarioId, isComparison, lastCalculated, forceRecalculate, (success) => {
            return success;
        });
    }

    toSentenceCase(text) {
        var result = text.replace(/([A-Z]+)/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    calculateTotalPayments() {
        let annualPayments = 0;
        let upfrontPayments = 0;

        Object.values(this.props.payments).map((payment) => {
            if (payment.annuallyRecurring) {
                annualPayments += parseFloat(payment.amount);
            } else {
                upfrontPayments += parseFloat(payment.amount);
            }
        });

        return { annualPayments, upfrontPayments };
    }

    calculateTotalNovelties() {
        let annualNovelties = 0;
        let upfrontNovelties = 0;

        Object.values(this.props.novelties).map((novelty) => {
            if (novelty.annuallyRecurring) {
                annualNovelties += parseFloat(novelty.amount);
            } else {
                upfrontNovelties += parseFloat(novelty.amount);
            }
        });

        return { annualNovelties, upfrontNovelties };
    }

    calculateTotalRent() {
        let rent = 0;

        Object.values(this.props.nonFinancials).map((nonFinancial) => {
            if (nonFinancial.type === "Rent") {
                rent += parseFloat(nonFinancial.value) * nonFinancial.quantity;
            }
        });

        return rent;
    }

    calculateMaterialFinancials() {
        let totalDollarRebates = 0;
        let totalPercRebates = 0;
        let totalNumberOfRebates = 0;

        let totalDollarPa = 0;
        let totalPercPa = 0;
        let totalNumberOfPa = 0;

        Object.values(this.props.productGroups[this.scenarioId]).map((productGroup) => {
            if (productGroup.discounts) {
                if (productGroup.discounts.paPerCase) {
                    productGroup.discounts.paPerCase.type === "dollar" ?
                        totalDollarPa += productGroup.discounts.paPerCase.numericValue :
                        totalPercPa += productGroup.discounts.paPerCase.numericValue
                    totalNumberOfPa += 1;
                }

                if (productGroup.discounts.rebates) {
                    productGroup.discounts.rebates.type === "dollar" ?
                        totalDollarRebates += productGroup.discounts.rebates.numericValue :
                        totalPercRebates += productGroup.discounts.rebates.numericValue

                    totalNumberOfRebates += 1;
                }
            }
        });
        if (totalNumberOfRebates === 0) {
            totalNumberOfRebates = 1;
        }

        if (totalNumberOfPa === 0) {
            totalNumberOfPa = 1;
        }

        return {
            annualAvgDollarRebates: totalDollarRebates / this.props.scenarioDetails.contractDuration,
            annualAvgPercRebates: totalPercRebates / this.props.scenarioDetails.contractDuration,
            dollarRebates: totalDollarRebates / totalNumberOfRebates,
            percentRebates: totalPercRebates / totalNumberOfRebates,

            annualAvgDollarPa: totalDollarPa / this.props.scenarioDetails.contractDuration,
            annualAvgPercPa: totalPercPa / this.props.scenarioDetails.contractDuration,
            dollarPaPerCase: totalDollarPa / totalNumberOfPa,
            percentPaPerCase: totalPercPa / totalNumberOfPa
        };
    }

    generateAnnualInvestmentSummaryRows() {
        let currentScenarioSummmary = this.state.annualInvestmentSummary;
        let comparisonScenarioSummary = this.state.annualInvestmentSummaryComparison;

        let dataPoints = [
            { label: "Unit Cases", identifier: "Unit Cases", dataType: "number" },
            { label: "Physical Cases", identifier: "Physical Cases", dataType: "number" },
            // { label: "Selling Litres", identifier: "Selling Litres", dataType: "number" },
            { label: "Discounts", identifier: "Discounts and Allowances", dataType: "dollar" },
            { label: "Net Sales Revenue", identifier: "Net Sales Revenue", dataType: "dollar" },
            { label: "NSR/UC", identifier: "NSR per UC", dataType: "dollar" },
            { label: "Net Contribution", identifier: "Net Contribution", dataType: "dollar" },
            { label: "NC/UC", identifier: "NC per UC", dataType: "dollar" },
            { label: "EBIT", identifier: "EBIT", dataType: "dollar" },
            { label: "Capital Investment", identifier: "Capital Expenditure", dataType: "dollar", negated: true },
            { label: "IRR", identifier: "Internal Rate of Return", dataType: "percent" },
            { label: "EBIT Margin", identifier: "EBIT Margin", dataType: "percent" },
            { label: "Discount % GSR", identifier: "Discount percent GSR", dataType: "percent" },
        ];

        let rows = dataPoints.map((dataPoint) => {
            let currentScenarioValue = currentScenarioSummmary ? currentScenarioSummmary[dataPoint.identifier] : "-";
            let comparisonScenarioValue = comparisonScenarioSummary && this.props.comparisonScenario ? comparisonScenarioSummary[dataPoint.identifier] : "-";
            let variance = "-";

            if (dataPoint.negated) {
                currentScenarioValue *= -1;
                comparisonScenarioValue = typeof comparisonScenarioValue === "number" ? comparisonScenarioValue * -1 : comparisonScenarioValue;
            }

            if (typeof currentScenarioValue === "number" && typeof comparisonScenarioValue === "number") {
                variance = comparisonScenarioValue - currentScenarioValue;
            }

            if (this.props.comparisonScenario) {
                return {
                    columns: [
                        { cellType: "text", data: dataPoint.label },
                        { calculated: true, cellType: dataPoint.dataType, data: currentScenarioValue },
                        { calculated: true, cellType: dataPoint.dataType, data: comparisonScenarioValue },
                        { calculated: true, cellType: dataPoint.dataType, data: variance },
                    ],
                };
            } else {
                return {
                    columns: [
                        { cellType: "text", data: dataPoint.label },
                        { calculated: true, cellType: dataPoint.dataType, data: currentScenarioValue },
                    ],
                };
            }
        });

        return rows;
    }

    generateMaterialSummaryRows() {
        let rows = [];

        Object.values(this.props.productGroups[this.scenarioId]).map((productGroup) => {
            let conditions = [];
            let discounts = productGroup.discounts;
            let flatDiscountConditionalised = false;
            let rebatePaPaymentPerCaseConditionalised = false;

            // Assuming there is only one material per product group.
            if (discounts) {
                if (discounts.hasOwnProperty("flatDiscount")) {
                    flatDiscountConditionalised = discounts.flatDiscount.active ? true : false;
                    if (flatDiscountConditionalised) {
                        conditions.push(discounts.flatDiscount.conditions);
                    }
                }
                if (discounts.rebates.hasOwnProperty("fixedValue") || discounts.rebates.hasOwnProperty("numericValue")) {
                    if (discounts.rebates.active) {
                        rebatePaPaymentPerCaseConditionalised = true;
                        conditions.push(discounts.rebates.conditions);
                    }
                }
                if (discounts.hasOwnProperty("paPerCase")) {
                    if (discounts.paPerCase.active) {
                        rebatePaPaymentPerCaseConditionalised = true;
                        conditions.push(discounts.paPerCase.conditions);
                    }
                }
            }


            if (productGroup.materials) {
                Object.values(productGroup.materials).map((material) => {
                    rows.push({
                        // columnHeaders={["Product Description", "Physical Cases", "RRP", "Max RRP (Inc)", "Ranging Required", "Minimum SKUs", "Promotions"]}
                        columns: [
                            { cellType: 'text', data: material.MG1FriendlyDesc == null ? material.MG1Desc : material.MG1FriendlyDesc },
                            { cellType: 'number', data: material.physicalCases },
                            { cellType: 'dollar', data: material.RRP_EXC },
                            { cellType: 'dollar', data: material.MaxRRP },
                            { cellType: 'text', data: material.RequiredRange ? "Yes" : "-" },
                            { cellType: 'number', data: material.MinimumSKUs },
                            { cellType: 'text', data: material.Promotion == null ? "-" : `${material.Promotion[0]} for $${material.Promotion[1]}` }
                        ]
                    });

                    if (flatDiscountConditionalised || rebatePaPaymentPerCaseConditionalised) {
                        rows.push({
                            rowType: "conditions",
                            data: conditions,
                        });
                    }
                });
            }
        });
        return rows;
    }

    generateEquipmentRows() {
        let rows = [];
        Object.values(this.state.equipments).map((equipment) => {
            let conditions = [];
            let rental = null;
            if (equipment.rentalDetails) {
                rental = equipment.rentalDetails.stringValue
            }

            rows.push({
                columns: [
                    { cellType: 'text', data: equipment.equipmentSelection },
                    { cellType: 'text', data: "Equipment" },
                    { cellType: 'text', data: equipment.equipmentType },
                    { cellType: 'number', data: equipment.quantity },
                    { cellType: 'dollar', data: equipment.capitalValue },
                    { cellType: 'dollar', data: equipment.capitalValue * equipment.quantity },
                ]
            });

            if (equipment.conditions || equipment.rentalDetails) {
                conditions.push(equipment.conditions);
                rows.push({
                    rowType: "conditions",
                    data: conditions,
                    rental: rental
                });
            };

        });

        Object.values(this.props.novelties).map((novelty) => {
            let conditions = [];
            rows.push({
                columns: [
                    { cellType: 'text', data: novelty.name },
                    { cellType: 'text', data: "Novelty" },
                    { cellType: 'text', data: novelty.type },
                    { cellType: 'number', data: novelty.quantity },
                    { cellType: 'dollar', data: novelty.amount },
                    { cellType: 'dollar', data: novelty.quantity * novelty.amount },
                ]
            });

            if (novelty.conditions) {
                conditions.push(novelty.conditions);
                rows.push({
                    rowType: "conditions",
                    data: conditions,
                });
            };
        })

        Object.values(this.props.nonFinancials).map((financial) => {
            let conditions = [];
            rows.push({
                columns: [
                    { cellType: 'text', data: financial.type },
                    { cellType: 'text', data: "Non Financial" },
                    { cellType: 'text', data: financial.nonFinancialType },
                    { cellType: 'number', data: financial.quantity },
                    { cellType: 'dollar', data: financial.value },
                    { cellType: 'dollar', data: financial.quantity * financial.value },
                ]
            });

            if (financial.conditions) {
                conditions.push(financial.conditions);
                rows.push({
                    rowType: "conditions",
                    data: conditions,
                });
            };
        })

        Object.values(this.props.payments).map((payment) => {
            let conditions = [];
            rows.push({
                columns: [
                    { cellType: 'text', data: payment.type },
                    { cellType: 'text', data: "Payment" },
                    { cellType: 'text', data: payment.description },
                    { cellType: 'number', data: 1 },
                    { cellType: 'dollar', data: payment.amount },
                    { cellType: 'dollar', data: 1 * payment.amount },
                ]
            });

            if (payment.conditions) {
                conditions.push(payment.conditions);
                rows.push({
                    rowType: "conditions",
                    data: conditions,
                });
            };
        })
        return rows;
    }

    getSignOffLevel(approval) {
        return approval ? approval.approver : "...";
    }

    launchScenarioChooser() {
        this.setState({ scenarioChooserShowing: true });
    }

    closeScenarioChooser() {
        this.setState({ scenarioChooserShowing: false });
    }

    handleScenarioIdSelected(id) {
        this.props.setCurrentComparisonScenario(id, () => {
            this.closeScenarioChooser();
        });
    }

    getScenarioName(scenarioId) {
        let scenarioName = "";

        this.props.contract.scenarios.forEach((scenario) => {
            if (scenarioId === scenario.scenarioId) {
                scenarioName = scenario.scenarioName;
            }
        });

        return scenarioName;
    }

    render() {
        let exclusivity = ["NARTD", "CSD", "water", "energy", "sports", "lifestyle", "juice", "coffee", "exceptions"];
        let keyBusinessDrivers = ["agreedRetails", "combos", "coreRange", "displays", "extras", "fridgeRelay", "hotspot", "impulseCooler", "shareOfSpace"];
        let alcKeyBusinessDrivers = ["SoftDrinks", "Mixers", "PremiumMixers", "GingerBeer", "Energy", "Water", "Juice", "Cordial", "Coffee", "NumberOfTaps", "Exclusive First Pour Spirit Categories", "Number of Packaged ARTD SKU’s Ranged", "MenuActivation", "PerfectServe", "MinimumActivation"]

        let payments;
        let novelties;
        let totalRent;
        let materialFinancials;
        let annualAvgDollarRebates;
        let annualAvgPercRebates;
        let annualAvgPercPa;
        let annualAvgDollarPa;
        let avgDollarRebates;
        let avgPercRebates;
        let avgDollarPa;
        let avgPercPa;

        if (this.props.productGroups[this.scenarioId]) {
            payments = this.calculateTotalPayments();
            novelties = this.calculateTotalNovelties();
            totalRent = this.calculateTotalRent();
            materialFinancials = this.calculateMaterialFinancials();

            annualAvgDollarRebates = materialFinancials.annualAvgDollarRebates;
            annualAvgPercRebates = materialFinancials.annualAvgPercRebates;
            annualAvgPercPa = materialFinancials.annualAvgPercPa;
            annualAvgDollarPa = materialFinancials.annualAvgDollarPa;

            avgDollarRebates = materialFinancials.dollarRebates;
            avgPercRebates = materialFinancials.percentRebates;
            avgDollarPa = materialFinancials.dollarPaPerCase;
            avgPercPa = materialFinancials.percentPaPerCase;
        }

        let outletNumber = "-";
        let contractName = "-";
        let subtradeChannel = "-";
        let fullAddress = "-";
        let salesOffice = "-";
        let accountStatus = "-";
        let contractStartDate = "-";
        let priceRise = "-";
        let preparedBy = "-";
        let custDetails = this.props.contractDetails.customer;

        // customer details
        if (this.props.contractDetails.customer) {
            outletNumber = this.props.contractDetails.customer["outletNumber"];
            contractName = this.props.contractDetails.customer["tradeName"];
            fullAddress = this.props.contractDetails.customer["fullAddress"];
            preparedBy = this.props.contractDetails.customer["preparedBy"];
        }

        if (this.props.contractDetails.general) {
            subtradeChannel = this.props.contractDetails.general["subtradeChannel"];
            salesOffice = this.props.contractDetails.general["salesOffice"];
            accountStatus = this.props.contractDetails.general["accountStatus"];
            contractStartDate = this.props.scenarioDetails["contractStartDate"];
            priceRise = this.props.contractDetails.general["priceRise"];
        }

        let annualInvestmentSummaryColumnHeaders = [];

        if (this.props.comparisonScenario) {
            annualInvestmentSummaryColumnHeaders = ["", this.props.contract.scenarios ? this.getScenarioName(this.scenarioId) : "...", this.getScenarioName(this.props.comparisonScenario), "Variance"];
        } else {
            annualInvestmentSummaryColumnHeaders = ["", this.props.contract.scenarios ? this.getScenarioName(this.scenarioId) : "..."];
        }

        return (
            <PrintProvider>

                <ContextPage

                    firstElement={
                        <Button
                            className="menu-action-button"
                            color="link"
                            onClick={() => {
                                alert("Please use the system dialog to print: CTRL+P for Windows, CMD+P for MacOS");
                            }}
                        >
                            <FeatherIcon className="button-icon" icon="printer" /> Print
                        </Button>
                    }
                    fetching={this.state.fetching}
                    fetched={this.state.fetched}
                    title={"Customer Review"}
                >
                    {
                        this.state.scenarios && (
                            <ScenarioChooser
                                firstOptionNone
                                handleSelection={this.handleScenarioIdSelected}
                                showing={this.state.scenarioChooserShowing}
                                handleClose={this.closeScenarioChooser}
                                // Passing in the current comparison scenario id will make the scenario chooser show the current scenario as selected
                                scenarios={[this.props.comparisonScenario]}
                            />
                        )
                    }
                    <Print name="Details">

                        < div className="flexible-container" >
                            <FlexibleDataTable
                                simpleTable
                                header="Outlet Details"
                                headerColour="blue"
                                rows={[
                                    { label: "Customer/BO/TN Name", value: custDetails.companyName },
                                    { label: "Customer/BO/TN No.", value: outletNumber },
                                    { label: "Trading Name", value: custDetails.tradingName },
                                    { label: "Customer Business Number", value: custDetails.customerNumber },
                                    { label: "Customer Contact Email", value: custDetails.contactEmail },
                                    { label: "Customer Contact Number", value: custDetails.contactPhone },
                                    { label: "Customer Full Address", value: fullAddress },
                                    { label: "Contract Start Date", value: contractStartDate },
                                    { label: "Contract End Date", value: moment(contractStartDate, "DD/MM/YYYY").add(this.props.scenarioDetails.contractDuration, "year").format("DD/MM/YYYY") },
                                    { label: "Prepared By", value: preparedBy },
                                ]}
                            />
                        </ div>
                    </Print>
                    <Print name="Incentives">
                        <div className="flexible-container">
                            <FlexibleDataTable
                                columnTable
                                header="Incentives"
                                headerColour="pink"
                                columnHeaders={["Incentive Name", "Incentive Type", "Sub Type", "Quantity", "Value", "Total Value"]}
                                rows={this.generateEquipmentRows()}
                            />
                        </div>
                    </Print>
                    <div className="page-break" />
                    <Print name="Materials">
                        <div className="flexible-container">
                            <FlexibleDataTable
                                columnTable
                                header="Material Summary"
                                headerColour="green"
                                valuesLoading={this.props.fetchingMaterialSummary}
                                columnHeaders={["Product Description", "Physical Cases", "RRP", "Max RRP (Inc)", "Ranging Required", "Minimum SKUs", "Promotions"]}
                                rows={this.props.productGroups[this.scenarioId] ? this.generateMaterialSummaryRows() : []}
                            />
                        </div>
                    </Print>
                    <div className="page-break" />

                    <Print name="Exclusivity">
                        <div className="flexible-container">
                            <FlexibleDataTable
                                simpleTable
                                header="Exclusivity"
                                headerColour="red"
                                rows={exclusivity.map((value) => {
                                    let isExclusive = false;

                                    if (this.props.scenarioDetails) {
                                        if (this.props.scenarioDetails.exclusivity) {
                                            if (this.props.scenarioDetails.exclusivity.hasOwnProperty(value)) {
                                                isExclusive = this.props.scenarioDetails.exclusivity[value];
                                            }
                                        }
                                    }
                                    return { label: value, value: isExclusive ? "Yes" : "-" };
                                })}
                            />
                            <FlexibleDataTable
                                simpleTable
                                header="Key Business Drivers"
                                headerColour="red"
                                rows={keyBusinessDrivers.map((driver) => {
                                    let value = "...";
                                    value = this.props.contractDetails.keyBusinessDrivers ? this.props.contractDetails.keyBusinessDrivers[driver] : "...";
                                    if (value === true) value = "Yes";
                                    else if (isNumber(value)) value = `${value}%`;
                                    return { label: this.toSentenceCase(driver), value: value ? value : "-" };
                                })}
                            />
                            <FlexibleDataTable
                                simpleTable
                                header="Key Business Drivers (ALC)"
                                headerColour="red"
                                rows={alcKeyBusinessDrivers.map((driver) => {
                                    let value = "...";
                                    value = this.props.contractDetails.keyBusinessDrivers ? this.props.contractDetails.keyBusinessDrivers[driver] : "...";
                                    if (Array.isArray(value)) value = value.map(item => item.value).join(", ");
                                    if (value === true) { value = "Yes"; }
                                    return { label: this.toSentenceCase(driver), value: value ? value : "-" };
                                })}
                            />
                        </div>
                    </Print>
                    <Print name="MoreInfo">
                        <div className="flexible-container">
                            <div className="flexible-item">
                                <div className="flexible-header purple">Further Information</div>
                                <textarea
                                    placeholder="Any additional information..."
                                    className="further-information-field"
                                    rows="4"
                                    cols="50"
                                    defaultValue={this.props.additionalComments}
                                    onBlur={(event) => { this.props.postAdditionalComments(this.contractId, this.props.match.params.scenarioId, event.target.value) }}
                                />
                            </div>
                        </div>
                    </Print>
                </ContextPage >
            </PrintProvider>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { contract, fetched } = state.contractReducer;
    const { scenarioDetails, contractDetails, comparisonScenario } = state.contractDetailsReducer;
    const { productGroups } = state.productGroupReducer;
    const { equipments } = state.equipmentReducer;
    const { nonFinancials } = state.nonFinancialReducer;
    const { user } = state.userReducer;
    const { payments, novelties } = state.paymentReducer;
    const { additionalComments, annualInvestmentSummary, annualInvestmentSummaryTimestamp, annualInvestmentSummaryComparison, fetchingAnnualInvestmentSummary, materialSummary, fetchingMaterialSummary, fetchingAnnualInvestmentSummaryComparison, approval, fetchingApproval } = state.summaryReducer;

    return {
        user,
        contract,
        fetched,
        contractDetails,
        scenarioDetails,
        comparisonScenario,
        equipments,
        productGroups,
        payments,
        novelties,
        nonFinancials,
        annualInvestmentSummaryTimestamp,
        annualInvestmentSummary,
        annualInvestmentSummaryComparison,
        fetchingAnnualInvestmentSummary,
        fetchingAnnualInvestmentSummaryComparison,
        materialSummary,
        fetchingMaterialSummary,
        approval,
        fetchingApproval,
        additionalComments
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getScenarioDetails,
            getEquipments,
            getProductGroups,
            getPayments,
            getNovelties,
            getNonFinancials,
            getMaterialGroupDataPoint,
            getAnnualInvestmentSummary,
            getContract,
            getContractDetails,
            setCurrentComparisonScenario,
            getMaterialSummary,
            getApproval,
            getAdditionalComments,
            postAdditionalComments
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerReviewPage));

// EXPORT COMPONENT
export { hoc as CustomerReviewPage };
