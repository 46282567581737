// IMPORT PACKAGE REFERENCES //
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
//------------------------- //

// IMPORT UI
import { Row, Col } from "reactstrap";
import { Checkbox } from "../../containers/inputs/Checkbox";
import Select from 'react-select'

//------- //

// IMPORT ACTIONS //
//--------------- //

// COMPONENT
class SimpleContractCopyList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            checked: undefined,
        };

        this.handleClick = this.handleClick.bind(this);
        this.getRows = this.getRows.bind(this);
    }

    handleClick(id) {
        this.props.handleItemClicked(id);
        this.props.slide();
    }

    getRows() {
        if (this.props.contracts) {
            let mappedContracts = Object.values(this.props.contracts).map((contract, contractIndex) => {
                return { ...contract, label: contract.contractName, value: contract.contractId };
            })
            return mappedContracts;
        }
        return [];
    }

    render() {
        return (
            <div className="contract-list">
                <Row form>
                    <Col md={12}>
                        <Select
                            menuIsOpen={true}
                            styles={{ height: "600px" }}
                            options={this.getRows()}
                            onChange={(event) => {
                                this.handleClick(event.value)
                            }}>
                        </Select>
                    </Col>
                </Row>
            </div>
        );
    }
}

SimpleContractCopyList.propTypes = {
    contracts: PropTypes.object.isRequired,
};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { contracts } = state.contractReducer;
    return { contracts };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            // getcontracts,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(SimpleContractCopyList));

// EXPORT COMPONENT

export { hoc as SimpleContractCopyList };
