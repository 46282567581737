// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

// Actions
import { getMaterials } from "../../../state/actions/MaterialActions";
import { createProductGroup, addMaterialGroups } from "../../../state/actions/ProductGroupActions";
import { getMaterialSearchResults } from "../../../state/actions/MaterialActions";

// UI
import { Button, FormGroup, Row, Col } from "reactstrap";
import { Checkbox } from "../../../containers/inputs/Checkbox";
import { Loading } from "../../../containers/loading/Loading";
import { MaterialGroupCell } from "./MaterialGroupCell";
import { ExpandableRow } from "../../../containers/table/ExpandableRow";
import { first } from "lodash";

const groupBy = (key) => (array) =>
    array.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
    }, {});

const groupByProductGroup = groupBy("ProductGroup");
const groupByBeverageCategory = groupBy("BeverageCategory");

// COMPONENT
class AddMaterialGroupsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalShowing: false,
            loading: false,
            materials: {},
            searchedMaterials: {},
            title: "",
            query: "",
            searching: props.searching,
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showing !== this.props.showing) {
            this.setState({ query: "" });

            if (this.props.showing) {
                this.setState({ loading: true });
                this.props.getMaterials(this.props.match.params.contractId, this.props.match.params.scenarioId, () => {
                    this.setState({ loading: false });
                });
            } else {
                this.setState({ title: "", loading: true, materials: {} });
            }
        }

        if (JSON.stringify(this.state.searchedMaterials) !== JSON.stringify(this.props.searchedMaterials)) {
            this.setState({ searchedMaterials: this.props.searchedMaterials });
        }

        if (this.props.searching !== this.state.searching) {
            this.setState({ searching: this.props.searching });
        }
    }

    componentDidMount() {
        this.setState({ loading: true });

        this.props.getMaterials(this.props.match.params.contractId, this.props.match.params.scenarioId, () => {
            this.setState({ loading: false });
        });
    }

    onSubmit() {
        this.setState({ loading: true });

        this.props.addMaterialGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, { materials: this.state.materials }, (success) => {
            this.setState({ loading: false });
            this.props.complete();
        });
    }

    handleInputChange = () => {
        this.setState(
            {
                query: this.search.value,
            },
            () => {
                if (this.state.query !== "") {
                    this.props.getMaterialSearchResults(this.props.match.params.contractId, this.props.match.params.scenarioId, this.state.query);
                }
            }
        );
    };

    handleClick(checked, material) {
        let materials = Object.assign({}, this.state.materials);
        if (!checked) {
            delete materials[material.MG1];
        } else {
            materials[material.MG1] = material;
        }

        this.setState({ materials });
    }

    render() {
        let numberOfSelectedMaterials = Object.keys(this.state.materials).length;
        let buttonLabel = `Add ${numberOfSelectedMaterials} material${numberOfSelectedMaterials > 1 ? "s" : ""}`;

        if (this.state.loading) {
            return <Loading />;
        }

        let group = {};

        if (this.state.query === "") {
            let allMaterials = Object.values(this.props.materials.allMaterials ? this.props.materials.allMaterials : {}).flatMap((collection) => {
                return collection.materials;
            });
            group = groupByBeverageCategory(allMaterials);
        } else {
            group = { All: Object.values(this.state.searchedMaterials) };
        }

        return (
            <Fragment>
                <div className="fixed-search">
                    <input className="searchInput" size="lg" name="searchValue" type="text" placeholder="Search for a material group..." onChange={this.handleInputChange} ref={(input) => (this.search = input)} />
                </div>
                {this.state.searching ? (
                    <Loading />
                ) : (
                    <FormGroup>
                        <div className="material-list-container">
                            {Object.keys(group).length === 0 ? (
                                <div className="no-results-placeholder">No results</div>
                            ) : (
                                Object.keys(group).map((groupKey, groupIndex) => {
                                    let materials = group[groupKey];
                                    // TODO -> Comment the regex so the next person doesn't die reading it
                                    let regexp = /(\d+)[l-mL-M]{2}|(\d+)[lL]|(\d+\.\d)[lL]|(\d+\.\d+)|(\d+)[kKgG]{2}|(\d+)[gG]/
                                    materials.sort((firstEl, secondEl) => {
                                        let desc = firstEl.MG1FriendlyDesc;
                                        let desc2 = secondEl.MG1FriendlyDesc;
                                        let descRegex = regexp.exec(desc);
                                        let desc2Regex = regexp.exec(desc2);

                                        let firstElValue = 0;
                                        let secondElValue = 0;
                                        if (descRegex == null || desc2Regex == null) { return 0 }
                                        if (descRegex[0] == undefined || desc2Regex[0] == undefined) { return 0 };

                                        if (descRegex[1] != undefined) {
                                            firstElValue = parseFloat(descRegex[1]);
                                        } else if (descRegex[2] != undefined) {
                                            firstElValue = parseFloat(descRegex[2]) * 1000;
                                        } else if (descRegex[3] != undefined) {
                                            firstElValue = parseFloat(descRegex[3]) * 1000;
                                        } else if (descRegex[4] != undefined) {
                                            firstElValue = parseFloat(descRegex[4]) * 1000;
                                        }else if (descRegex[5] != undefined) {
                                            firstElValue = parseFloat(descRegex[5]) * 1000;
                                        }else if (descRegex[6] != undefined) {
                                            firstElValue = parseFloat(descRegex[6]);
                                        }

                                        if (desc2Regex[1] != undefined) {
                                            secondElValue = parseFloat(desc2Regex[1]);
                                        } else if (desc2Regex[2] != undefined) {
                                            secondElValue = parseFloat(desc2Regex[2]) * 1000;
                                        } else if (desc2Regex[3] != undefined) {
                                            secondElValue = parseFloat(desc2Regex[3]) * 1000;
                                        } else if (desc2Regex[4] != undefined) {
                                            secondElValue = parseFloat(desc2Regex[4]) * 1000;
                                        }else if (desc2Regex[5] != undefined) {
                                            secondElValue = parseFloat(desc2Regex[5]) * 1000;
                                        }else if (desc2Regex[6] != undefined) {
                                            secondElValue = parseFloat(desc2Regex[6]);
                                        }

                                        if (firstElValue > secondElValue) {
                                            return 1;
                                        } else if (secondElValue > firstElValue) {
                                            return -1;
                                        } else {
                                            return 0;
                                        }
                                    });

                                    let results;

                                    let materialCells = materials.map((material, materialIndex) => {
                                        let isSelected = this.state.materials.hasOwnProperty(material.MG1);
                                        return (
                                            <MaterialGroupCell
                                                selected={isSelected}
                                                handleClick={(checked, material) => {
                                                    this.handleClick(checked, material);
                                                }}
                                                materialIndex={materialIndex * (groupIndex + 1)}
                                                material={material}
                                            />
                                        );
                                    });

                                    if (this.state.query === "") {
                                        results = (
                                            <ExpandableRow expanded={false} label={groupKey}>
                                                {materialCells}
                                            </ExpandableRow>
                                        );
                                    } else {
                                        results = materialCells;
                                    }

                                    return <div>{results}</div>;
                                })
                            )}
                        </div>
                    </FormGroup>
                )}
                <div className={"rg-button search-button" + (Object.keys(this.state.materials).length > 0 ? " showing" : " hidden")} onClick={this.onSubmit} color="secondary">
                    {buttonLabel}
                </div>{" "}
            </Fragment>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { materials, searchedMaterials, fetching, fetched, failed, searching } = state.materialReducer;
    return { materials, searchedMaterials, fetching, fetched, failed, searching };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getMaterials, createProductGroup, addMaterialGroups, getMaterialSearchResults }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(AddMaterialGroupsPage));

// EXPORT COMPONENT

export { hoc as AddMaterialGroupsPage };
