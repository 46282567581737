// IMPORT PACKAGE REFERENCES
import React from "react";
import PropTypes from "prop-types";
import FeatherIcon from "feather-icons-react";

// COMPONENT
export class PageTitle extends React.Component {
    render() {
        return (
            <div className={"page-title-container" + (this.props.fullWidth ? " full-width" : "")}>
                <div className="page-title">{this.props.minimised ? "" : this.props.title}</div>
                {this.props.minimised ? "" : <div className="right-element">{this.props.firstElement}</div>}
            </div>
        );
    }
}

PageTitle.propTypes = {
    title: PropTypes.string,
    firstElement: PropTypes.object,
};
