// IMPORT PACKAGE REFERENCES
import React from "react";
var NumberFormat = require("react-number-format");

// COMPONENT
export class ConditionalInput extends React.Component {
    constructor(props) {
        super(props);

        let active = false;
        let editing = false;
        let value = props.value;

        if (props.active !== undefined) {
            active = props.active;

            if (active) {
                value = "";
            }
        }

        if (props.editing) {
            editing = props.editing;
        }

        this.state = {
            active,
            value,
            editing,
        };

        this.initialValue = value;
        this.toggleActive = this.toggleActive.bind(this);
    }

    componentDidUpdate() {
        if (this.state.active !== this.props.active) {
            this.setState({ active: this.props.active });
        }

        if (this.state.editing !== this.props.editing) {
            this.setState({ editing: this.props.editing });
        }

        if (this.state.formattedValue !== this.props.value) {
            this.setState({ formattedValue: this.props.value });
        }
    }

    toggleActive() {
        let confirmationMessage = "";
        let showMessage = false;

        if (this.state.active) {
            confirmationMessage = "Are you sure you want to delete attached conditions?";
            showMessage = true;
        } else if (!this.state.active && typeof this.state.value === "number") {
            confirmationMessage = "Are you sure you want to remove attached values?";
            showMessage = true;
        }

        if (showMessage) {
            if (!confirm(confirmationMessage)) {
                return;
            }
        }

        this.setState({ active: !this.state.active, value: "" });
        this.props.handleToggle(!this.state.active);
    }

    render() {
        return (
            <div>
                <div className={"conditionalisable-input-container " + (this.state.editing ? "editing" : "")}>
                    {this.state.active ? (
                        this.state.formattedValue
                    ) : (
                            <NumberFormat
                                decimalScale={2}
                                style={{ width: "100%", paddding: "0.3em 0.2em" }}
                                className="no-border conditionalisable-input-item"
                                disabled={this.state.active || !this.state.editing}
                                value={this.state.formattedValue}
                                thousandSeparator={true}
                                prefix={this.props.units.type === "$" ? "$" : ""}
                                suffix={this.props.units.type === "%" ? "%" : ""}
                                onValueChange={(values) => {
                                    const { formattedValue, value } = values;
                                    this.setState({ formattedValue: formattedValue, value: value });
                                    this.props.handleChange(value);
                                }}
                                onBlur={() => {
                                    this.props.onSave();
                                }}
                            />
                        )}

                    <div onClick={this.state.editing ? this.toggleActive : undefined} className={"conditionalisable-input-item conditionalisable-input-item-button" + (this.state.editing ? " enabled" : " disabled")}>
                        {this.state.active ? <img alt="conditional active" className={`conditional-icon ${this.state.editing ? "editing" : ""}`} src={require("../../../images/icons/conditional-input-active.svg")} /> : <img alt="conditional inactive" className={`conditional-icon ${this.state.editing ? "editing" : ""}`} src={require("../../../images/icons/conditional-input-inactive.svg")} />}
                    </div>
                </div>
            </div>
        );
    }
}
