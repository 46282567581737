// *** PACKAGE REFERENCES *** //
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Yup from "yup";

// *** ACTIONS *** //
import { getScenarioDetails, saveScenarioDetails } from "../../../state/actions/ContractDetailsActions";
import { getContract } from "../../../state/actions/ContractActions";

// *** UI *** //
import { ContextPage } from "../../../containers/layout/ContextPage";
import { Card } from "../../../containers/card/Card";
import { CardRow } from "../../../containers/card/CardRow";
import { Formik } from "formik";
import "moment/locale/en-nz";
import "react-datepicker/dist/react-datepicker.css";
import { CCAInputTypes } from "../../../enums/CCAInputTypes";
import { CCAInput } from "../../../containers/inputs/CCAInput";
import NumberFormat from "react-number-format";
import { CCAError } from "../../../enums/CCAError";

const validationSchema = Yup.object().shape({
    scenarioName: Yup.string().required("A scenario name is required."),
});

class GeneralScenarioDetailsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            messageActive: false,
            messageError: false,
            message: "",
        };

        this.durationInputElement;
        this.valuesAtLastSave = {};
        this.getDetails = this.getDetails.bind(this);
    }

    componentDidMount() {
        this.getDetails();
    }

    getDetails() {
        this.props.getScenarioDetails(this.props.match.params.contractId, this.props.match.params.scenarioId, () => {
            this.valuesAtLastSave = this.props.scenarioDetails;
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.scenarioId !== this.props.match.params.scenarioId) {
            this.getDetails();
        }
    }

    saveScenarioDetails(values) {
        this.props.saveScenarioDetails(this.props.match.params.contractId, this.props.match.params.scenarioId, values, (success) => {
            if (success) {
                this.valuesAtLastSave = values;
                this.props.hideError(CCAError.SAVE_SCENARIO_DETAILS_ERROR);
                // Go and re-fetch the contract so that the term, name, etc will be updated on the sidebar...
                this.props.getContract(this.props.match.params.contractId, (success) => { });
            } else {
                this.props.showError(CCAError.SAVE_SCENARIO_DETAILS_ERROR, () => {
                    this.saveScenarioDetails(values);
                });
            }
        });
    }

    render() {
        return (
            <ContextPage fetching={this.props.fetching} fetched={this.props.fetched} title={this.props.title}>
                {this.props.fetched && (
                    <Fragment>
                        <Formik
                            validationSchema={validationSchema}
                            enableReinitialize={true}
                            initialValues={this.props.scenarioDetails}
                            onSubmit={(values) => {
                                // Don't bother sending a request if no change to inputs
                                if (JSON.stringify(values) !== JSON.stringify(this.valuesAtLastSave)) {
                                    // Save details here.
                                    // On success, reset the message to inactive.
                                    // On Failiure, set the message to its error state, then reset it to inactive after a timeout to give user a chance to read it
                                    this.props.saveScenarioDetails(this.props.match.params.contractId, this.props.match.params.scenarioId, values, (success) => {
                                        if (success) {
                                            this.valuesAtLastSave = values;
                                            // Go and re-fetch the contract so that the term, name, etc will be updated on the sidebar...
                                            this.props.getContract(this.props.match.params.contractId, (success) => { });
                                        } else {
                                            this.setState({ messageActive: true, messageError: true, message: "Error saving general details" });
                                            setTimeout(() => {
                                                this.setState({ messageActive: false, messageError: false });
                                            }, 2000);
                                        }
                                    });
                                }
                            }}
                            render={({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => {
                                return (
                                    <Fragment>
                                        <Card title={"General"}>
                                            <CardRow top label={"Scenario Name"}>
                                                <CCAInput
                                                    onBlur={(e) => {
                                                        handleSubmit(e);
                                                    }}
                                                    placeholder={"Scenario Name"}
                                                    type={CCAInputTypes.FREE_TEXT}
                                                    name="scenarioName"
                                                    value={values.scenarioName}
                                                    onChange={handleChange}
                                                    onSave={handleSubmit}
                                                />
                                            </CardRow>
                                            <CardRow label={"Duration"} bottom={true}>
                                                <NumberFormat
                                                    getInputRef={(el) => {
                                                        if (el) {
                                                            el.className = "form-control borderless right";
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        handleSubmit(e);
                                                    }}
                                                    placeholder="Contract Length"
                                                    suffix={" year" + (values.contractDuration > 1 ? "s" : "")}
                                                    decimalScale={0}
                                                    allowNegative={false}
                                                    defaultValue={0}
                                                    onValueChange={(value) => {
                                                        setFieldValue("contractDuration", value.floatValue);
                                                    }}
                                                    value={values.contractDuration}
                                                />
                                            </CardRow>
                                        </Card>
                                    </Fragment>
                                );
                            }}
                        />
                    </Fragment>
                )}
            </ContextPage>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { fetching, fetched, failed, scenarioDetails } = state.contractDetailsReducer;
    return { fetching, fetched, failed, scenarioDetails };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getScenarioDetails,
            saveScenarioDetails,
            getContract,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(GeneralScenarioDetailsPage));

// EXPORT COMPONENT
export { hoc as GeneralScenarioDetailsPage };
