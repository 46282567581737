import { GET_CUSTOMER_TARGETS_PENDING, GET_CUSTOMER_TARGETS_FULFILLED, GET_CUSTOMER_TARGETS_FAILED } from "../actions/CustomerTargetActions";

import { CLEAR_REDUCERS } from "../actions/GeneralActions";

// INITIALIZE STATE
const initialState = {
    currentCustomerProfit: 0,
    currentCustomerMargin: 0,
    currentCustomerDollarMargin: 0,
};

// REDUCER
export const CustomerTargetReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_REDUCERS:
            return state;
        case GET_CUSTOMER_TARGETS_FULFILLED:
            return {
                ...state,
                currentCustomerProfit: action.payload.customerProfit,
                currentCustomerMargin: action.payload.customerMargin,
                currentCustomerDollarMargin: action.payload.customerDollarMargin,
            };
        default:
            return state;
    }
};
