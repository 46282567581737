import axios from "axios";
import { API_ROOT } from "../../../config/config";

export const GET_NON_FINANCIALS_PENDING = "GET_NON_FINANCIALS_PENDING";
export const GET_NON_FINANCIALS_FULFILLED = "GET_NON_FINANCIALS_FULFILLED";
export const GET_NON_FINANCIALS_REJECTED = "GET_NON_FINANCIALS_REJECTED";

export const ADD_NON_FINANCIAL_PENDING = "ADD_NON_FINANCIAL_PENDING";
export const ADD_NON_FINANCIAL_FULFILLED = "ADD_NON_FINANCIAL_FULFILLED";
export const ADD_NON_FINANCIAL_REJECTED = "ADD_NON_FINANCIAL_REJECTED";

export const GET_NON_FINANCIAL_PENDING = "GET_NON_FINANCIAL_PENDING";
export const GET_NON_FINANCIAL_FULFILLED = "GET_NON_FINANCIAL_FULFILLED";
export const GET_NON_FINANCIAL_REJECTED = "GET_NON_FINANCIAL_REJECTED";

export const PUT_NON_FINANCIAL_PENDING = "PUT_NON_FINANCIAL_PENDING";
export const PUT_NON_FINANCIAL_FULFILLED = "PUT_NON_FINANCIAL_FULFILLED";
export const PUT_NON_FINANCIAL_REJECTED = "PUT_NON_FINANCIAL_REJECTED";

export const DELETE_NON_FINANCIAL_PENDING = "DELETE_NON_FINANCIAL_PENDING";
export const DELETE_NON_FINANCIAL_FULFILLED = "DELETE_NON_FINANCIAL_FULFILLED";
export const DELETE_NON_FINANCIAL_REJECTED = "DELETE_NON_FINANCIAL_REJECTED";

export const GET_NON_FINANCIAL_SUMMARY_PENDING = "GET_NON_FINANCIAL_SUMMARY_PENDING";
export const GET_NON_FINANCIAL_SUMMARY_FULFILLED = "GET_NON_FINANCIAL_SUMMARY_FULFILLED";
export const GET_NON_FINANCIAL_SUMMARY_REJECTED = "GET_NON_FINANCIAL_SUMMARY_REJECTED";

export const SAVE_NON_FINANCIAL_CONDITIONS_FULFILLED = "SAVE_NON_FINANCIAL_CONDITIONS_FULFILLED";
export const SAVE_NON_FINANCIAL_CONDITIONS_PENDING = "SAVE_NON_FINANCIAL_CONDITIONS_PENDING";
export const SAVE_NON_FINANCIAL_CONDITIONS_REJECTED = "SAVE_NON_FINANCIAL_CONDITIONS_REJECTED";

// ACTION GENERATORS

/*
    Retrieves all the non financial.
*/

const getNonFinancials = (contractId, scenarioId, callback) => (dispatch) => {
    dispatch({
        type: GET_NON_FINANCIALS_PENDING,
    });

    /*
        We want to get all the non financial.
    */
    axios
        .get(`${API_ROOT}/contracts/${contractId}/${scenarioId}/non-financials`)
        .then((res) => {
            const nonFinancials = res.data;

            /* Fulfil the request for nonFinancial, since the API succceded, pass the nonFinancial data to the reducer. */
            dispatch({
                type: GET_NON_FINANCIALS_FULFILLED,
                payload: nonFinancials,
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting non financial.", error);

            /* Reject the request for nonFinancial, since the API failed. */
            dispatch({
                type: GET_NON_FINANCIALS_REJECTED,
            });

            callback(false);
        });
};

/*
    Retrieves a singular piece of non financial.
*/

const getNonFinancial = (contractId, scenarioId, nonFinancialId, callback) => (dispatch) => {
    dispatch({
        type: GET_NON_FINANCIAL_PENDING,
    });

    /*
        We want to get the non financial.
    */
    axios
        .get(`${API_ROOT}/contracts/${contractId}/${scenarioId}/non-financials/${nonFinancialId}`)
        .then((res) => {
            const nonFinancial = { id: nonFinancialId, ...res.data };

            /* Fulfil the request for nonFinancial, since the API succceded, pass the nonFinancial data to the reducer. */
            dispatch({
                type: GET_NON_FINANCIAL_FULFILLED,
                payload: nonFinancial,
            });

            callback();
        })
        .catch((error) => {
            console.log("An error occurred requesting non financial.", error);

            /* Reject the request for nonFinancial, since the API failed. */
            dispatch({
                type: GET_NON_FINANCIAL_REJECTED,
            });
        });
};

/*
    Saves a singular piece of non financial.
*/

const saveNonFinancial = (contractId, scenarioId, nonFinancialId, values, callback) => (dispatch) => {
    dispatch({
        type: PUT_NON_FINANCIAL_PENDING,
    });

    /*
        We want to save the non financial.
    */
    axios
        .put(`${API_ROOT}/contracts/${contractId}/${scenarioId}/non-financials/${nonFinancialId}`, values)
        .then((res) => {
            /* Fulfil the request for nonFinancial, since the API succceded */
            dispatch({
                type: PUT_NON_FINANCIAL_FULFILLED,
                payload: { id: nonFinancialId, ...values },
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting non financial.", error);

            /* Reject the request for saving the nonFinancial, since the API failed. */
            dispatch({
                type: PUT_NON_FINANCIAL_REJECTED,
            });

            callback(false);
        });
};

/*
    Saves a singular piece of non financial.
*/

const deleteNonFinancials = (contractId, scenarioId, values, callback) => (dispatch) => {
    dispatch({
        type: DELETE_NON_FINANCIAL_PENDING,
    });

    /*
        We want to save the non financial.
    */
    axios
        .delete(`${API_ROOT}/contracts/${contractId}/${scenarioId}/non-financials`, { data: values })
        .then((res) => {
            /* Fulfil the request for nonFinancial, since the API succceded */
            dispatch({
                type: DELETE_NON_FINANCIAL_FULFILLED,
                payload: values,
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting non financial.", error);

            /* Reject the request for saving the nonFinancial, since the API failed. */
            dispatch({
                type: DELETE_NON_FINANCIAL_REJECTED,
            });

            callback(false);
        });
};

/*
    Add nonFinancial to the contract.
*/

const addNonFinancial = (contractId, scenarioId, data, callback) => (dispatch) => {
    dispatch({
        type: ADD_NON_FINANCIAL_PENDING,
    });

    /*
        We want to add non financial.
    */
    axios
        .post(`${API_ROOT}/contracts/${contractId}/${scenarioId}/non-financials`, data)
        .then((response) => {
            dispatch({
                type: ADD_NON_FINANCIAL_FULFILLED,
            });

            callback(true, response.data);
        })
        .catch((error) => {
            console.log("An error occurred requesting non financial.", error);

            /* Reject the request for nonFinancial, since the API failed. */
            dispatch({
                type: ADD_NON_FINANCIAL_REJECTED,
            });

            callback(false);
        });
};

/*
    Get nonFinancial summary.
*/

const getNonFinancialSummary = (contractId, scenarioId, callback) => (dispatch) => {
    dispatch({
        type: GET_NON_FINANCIAL_SUMMARY_PENDING,
    });

    /*
        We want to get non financial summary.
    */
    axios
        .get(`${API_ROOT}/contracts/${contractId}/${scenarioId}/non-financials/summary`)
        .then((res) => {
            dispatch({
                type: GET_NON_FINANCIAL_SUMMARY_FULFILLED,
                payload: res.data,
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting non financial.", error);

            /* Reject the request for nonFinancial summary, since the API failed. */
            dispatch({
                type: GET_NON_FINANCIAL_SUMMARY_REJECTED,
            });

            callback(false);
        });
};

/*
    Save non-financial conditions.
*/
const saveNonFinancialConditions = (contractId, scenarioId, nonFinancialId, data, callback) => (dispatch) => {
    dispatch({
        type: SAVE_NON_FINANCIAL_CONDITIONS_PENDING,
    });

    /*
        We want to add equipment.
    */
    axios
        .post(`${API_ROOT}/contracts/${contractId}/${scenarioId}/non-financials/${nonFinancialId}/conditions`, { data: data })
        .then((response) => {
            console.log(response.data);
            dispatch({
                type: SAVE_NON_FINANCIAL_CONDITIONS_FULFILLED,
                payload: { data: response.data, id: nonFinancialId },
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting equipment.", error);

            /* Reject the request for equipment, since the API failed. */
            dispatch({
                type: SAVE_NON_FINANCIAL_CONDITIONS_REJECTED,
            });

            callback(false);
        });
};

// EXPORT ACTIONS
export { getNonFinancials, addNonFinancial, getNonFinancial, saveNonFinancial, deleteNonFinancials, getNonFinancialSummary, saveNonFinancialConditions };
