// IMPORT PACKAGE REFERENCES
import React from "react";
import { CELL_SUB_TYPES } from "../../../pages/contract/CellSubTypes";

// INDICATOR ROWS
import { PaymentIndicatorRow } from "./PaymentIndicatorRow";
import { EquipmentIndicatorRow } from "./EquipmentIndicatorRow";
import { NonFinancialIndicatorRow } from "./NonFinancialIndicatorRow";
import { RebateIndicatorRow } from "./RebateIndicatorRow";
import { ProductIndicatorRow } from "./ProductIndicatorRow";
import { DiscountIndicatorRow } from "./DiscountIndicatorRow";

// COMPONENT
export class IndicatorRow extends React.Component {
    render() {
        switch (this.props.cellSubType) {
            case CELL_SUB_TYPES.PAYMENT:
                return <PaymentIndicatorRow data={this.props.data} />;
            case CELL_SUB_TYPES.REBATE:
                return <RebateIndicatorRow data={this.props.data} />;
            case CELL_SUB_TYPES.EQUIPMENT:
                return <EquipmentIndicatorRow data={this.props.data} />;
            case CELL_SUB_TYPES.NON_FINANCIAL:
                return <NonFinancialIndicatorRow data={this.props.data} />;
            case CELL_SUB_TYPES.PRODUCT:
                return <ProductIndicatorRow data={this.props.data} />;
            case CELL_SUB_TYPES.DISCOUNT:
                return <DiscountIndicatorRow data={this.props.data} />;
            default:
                return null;
        }
    }
}
