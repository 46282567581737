import React from "react";

export const conditions = {
    colors: {
        SQ123: "#F34972",
    },
    translation: {
        CON1: "or",
        CON2: "and",
        SQ123: {
            clause: "the date must be",
            condition: "when the date is",
        },
        SQ124: {
            clause: "the volume must be",
            condition: "when the volume is",
        },
        SQ125: {
            clause: "",
            condition: "If",
        },
        SQ127: {
            clause: "the spend must be",
            condition: "when the spend is",
        },
        SQ126: "then the customer will receive a ",
        OP1: "between",
        OP2: "before",
        OP3: "after",
        OP4: "greater than",
        OP5: "greater than or equal to",
        OP6: "less than",
        OP7: "less than or equal to",
        OP8: "equal to",
        OP9: {
            clause: "must be true",
            condition: "is true",
        },
        OP10: {
            clause: "must be false",
            condition: "is false",
        },
        OP11: "a rebate of",
        OP12: "discount of",
        OP13: "fixed price of",
        OP14: "dollar discount off of",
        OP18: "monthly rental fee of",
        UN1: "physical cases",
        UN2: "nips (unused)",
        UN3: "physical cases (unused)",
        UN4: "dollars",
        UN5: "percent",
        UN6: "KGs",
        UN7: "Hecto Litres",
        UN8: "Syrup Litres",
        TIM1: "per day",
        TIM2: "per month",
        TIM3: "per year",
        TIM4: "for the length of the contract",
    },
    conjunctions: {
        CON1: {
            color: "#6B5FAA",
            name: "Or",
            next: "types",
        },
        CON2: {
            color: "#6B5FAA",
            name: "And",
            next: "types",
        },
        CON3: {
            color: "#6B5FAA",
            name: "None",
            next: "types",
            display: false,
        },
    },
    types: {
        SQ123: {
            color: "#00B19D",
            name: "Date",
            next: "types/SQ123/options",
            options: {
                OP1: {
                    color: "#0395CE",
                    name: "Is Between",
                    valueFormat: "VF1",
                },
                OP2: {
                    color: "#0395CE",
                    name: "Is Before",
                    valueFormat: "VF2",
                },
                OP3: {
                    color: "#0395CE",
                    name: "Is After",
                    valueFormat: "VF10",
                },
            },
        },
        SQ124: {
            color: "#00B19D",
            name: "Volume",
            next: "types/SQ124/options",
            options: {
                OP4: {
                    color: "#F34972",
                    name: "is greater than",
                    valueFormat: "VF3",
                    valueId: "volume",
                },
                OP5: {
                    color: "#F34972",
                    name: "is greater than or equal to",
                    valueFormat: "VF3",
                    valueId: "volume",
                },
                OP6: {
                    color: "#F34972",
                    name: "less than",
                    valueFormat: "VF3",
                    valueId: "volume",
                },
                OP7: {
                    color: "#F34972",
                    name: "is less than or equal to",
                    valueFormat: "VF3",
                    valueId: "volume",
                },
                OP8: {
                    color: "#F34972",
                    name: "is equal to",
                    valueFormat: "VF3",
                    valueId: "volume",
                },
            },
        },
        // Commented out 'custom' conditions as CCA cannot use for their import tool
        // Leaving commented in case they ever want it back
        SQ125: {
            color: "#00B19D",
            name: "Condition",
            next: "types/SQ125/options",
            options: {
                OP10: {
                    color: "#0395CE",
                    name: "Is False",
                },
                OP9: {
                    color: "#0395CE",
                    name: "Is True",
                },
            },
            valueFormat: "VF5",
        },
        SQ126: {
            color: "#00B19D",
            name: "Result",
            next: "types/SQ126/options",
            options: {
                OP11: {
                    color: "#0395CE",
                    name: "A Rebate Of",
                    valueFormat: "VF6",
                },
                OP12: {
                    color: "#0395CE",
                    name: "A Flat Percent Discount of",
                    valueFormat: "VF7",
                },
                OP13: {
                    color: "#0395CE",
                    name: "A Fixed Price Discount Of",
                    valueFormat: "VF8",
                },
                OP14: {
                    color: "#0395CE",
                    name: "A Dollar Discount Off Discount Of",
                    valueFormat: "VF8",
                },
                OP15: {
                    color: "#0395CE",
                    name: "A Dollar PA Rebate Of",
                    valueFormat: "VF8",
                },
                OP16: {
                    color: "#0395CE",
                    name: "A Percent PA Rebate Of",
                    valueFormat: "VF7",
                },
                OP17: {
                    color: "#0395CE",
                    name: "A Percent Rebate Of",
                    valueFormat: "VF7",
                },
                OP18: {
                    color: "#FF0000",
                    name: "Monthly Rental Fee Of",
                    valueFormat: "VF8"
                }
            },
        },
        SQ127: {
            color: "#00B19D",
            name: "Spend",
            next: "types/SQ127/options",
            options: {
                OP4: {
                    color: "#F34972",
                    name: "is greater than",
                    valueFormat: "VF9",
                    valueId: "spend",
                },
                OP5: {
                    color: "#F34972",
                    name: "is greater than or equal to",
                    valueFormat: "VF9",
                    valueId: "spend",
                },
                OP6: {
                    color: "#F34972",
                    name: "less than",
                    valueFormat: "VF9",
                    valueId: "spend",
                },
                OP7: {
                    color: "#F34972",
                    name: "is less than or equal to",
                    valueFormat: "VF9",
                    valueId: "spend",
                },
                OP8: {
                    color: "#F34972",
                    name: "is equal to",
                    valueFormat: "VF9",
                    valueId: "spend",
                },
            },
        },
        next: "types/:typeId/options",
    },
    units: {
        UN1: {
            color: "#0395CE",
            name: "physical cases",
        },
        UN2: {
            color: "#0395CE",
            name: "nips (unused)",
        },
        UN3: {
            color: "#0395CE",
            name: "physical cases (unused)",
            next: "time",
        },
        UN4: {
            color: "#0395CE",
            name: "dollars",
        },
        UN5: {
            color: "#0395CE",
            name: "percent",
        },
        UN6: {
            color: "#0395CE",
            name: "KGs",
        },
        UN7: {
            color: "#0395CE",
            name: "Hecto Litres",
        },
        UN8: {
            color: "#0395CE",
            name: "Syrup Litres",
        },
    },
    time: {
        TIM1: {
            name: "per day",
            color: "#28C4BD",
        },
        TIM2: {
            name: "per month",
            color: "#28C4BD",
        },
        TIM3: {
            name: "per year",
            color: "#28C4BD",
        },
        TIM4: {
            name: "over contract",
            color: "#28C4BD",
        },
    },
    valueFormats: {
        VF1: {
            description: "Enter two dates.",
        },
        VF2: {
            description: "Enter one date.",
        },
        VF3: {
            description: "Volume input",
            next: "units",
            availableUnits: ["UN1", "UN6", "UN7", "UN8"],
        },
        VF4: {
            description: "Percentage input.",
        },
        VF5: {
            description: "Condition Input.",
            next: "types/SQ125/options",
            availableUnits: ["OP10", "OP9"],
        },
        VF6: {
            description: "Rebate input",
            next: "units",
            availableUnits: ["UN4"],
        },
        VF7: {
            description: "Percent discount input",
            next: "units",
            availableUnits: ["UN5"],
        },
        VF8: {
            description: "Fixed price discount",
            next: "units",
            availableUnits: ["UN4"],
        },
        VF9: {
            description: "Spend input",
            next: "units",
            availableUnits: ["UN4"],
        },
        VF10: {
            description: "Enter one date.",
        },
    },
};

function capitalizeFirstLetter(string) {
    let capitalised = string.charAt(0).toUpperCase() + string.slice(1);
    return capitalised;
}

export const translate = (expressions, allowResults, customConditions) => {
    let sentences = [];
    if (expressions) expressions.forEach((expression) => {
        let sentence = "";
        expression.forEach((statement) => {
            if (statement.hasOwnProperty("components")) {
                statement.components.forEach((component) => {
                    if (conditions.translation.hasOwnProperty(component.id)) {
                        let translated = "";

                        if (!allowResults && conditions.translation[component.id].hasOwnProperty("clause")) {
                            translated = conditions.translation[component.id].clause;
                        } else if (conditions.translation[component.id].hasOwnProperty("condition")) {
                            translated = conditions.translation[component.id].condition;
                        } else {
                            translated = conditions.translation[component.id];
                        }

                        sentence += ` ${translated}`;
                    } else if (component.hasOwnProperty("isValueFormat")) {
                        let value = component.value;
                        if (component.hasOwnProperty("friendlyName")) {
                            value = component.friendlyName;
                        }
                        if (component.valueFormat === "VF1") {
                            if (value === undefined) {
                                value = [];
                                value.push(moment().format("DD/MM/YYYY"));
                                value.push(moment().format("DD/MM/YYYY"));
                            }

                            value = `${value[0]} and ${value[1]}`;
                        } else if (component.valueFormat === "VF5") {
                            if (customConditions) {
                                let customCondition = customConditions.filter((condition) => condition.id === value);
                                value = customCondition.length !== 0 ? customCondition[0].name : "[deleted condition - please update]";
                            }
                        }
                        sentence += ` ${value}`;
                    }
                });
            }
        });
        sentences.push(sentence.trim() + ".");
    });

    return sentences.map((sentence, index) => {
        return <li key={index}>{sentence}</li>;
    });
};
