// IMPORT PACKAGE REFERENCES
import React from "react";
import { Table, Input } from "reactstrap";

// COMPONENT
export class ApprovalMatrixTable extends React.Component {
    render() {
        return (
            <Table>
                <thead>
                    <tr>
                        <th></th>
                        <th>DSM</th>
                        <th>RSM/NBM</th>
                        <th>GM</th>
                        <th>PLT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">ROI</th>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">EBIT Margin</th>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Proposed Discount % vs. Segment</th>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Any product below floor price/segment ave</th>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Total Annual Cash Investment Greater than</th>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Cash/Discount Risk</th>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                        <td>
                            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    }
}
