// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import NumberFormat from "react-number-format";

// UI
import { Input } from "reactstrap";

export class MultiPurchasePromotionCell extends React.Component {
    constructor(props) {
        super(props);

        if (typeof props.values === "object") {
            this.quantityValue = props.values[0];
            this.priceValue = props.values[1];
        }

        this.state = {
            quantityValue: this.quantityValue,
            priceValue: this.priceValue,
            active: props.active,
        };

        this.handlePriceChange = this.handlePriceChange.bind(this);
        this.handlePriceChange = this.handlePriceChange.bind(this);
        this.passUpValues = this.passUpValues.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.values) !== JSON.stringify(this.props.values)) {
            this.handleValuesChange(this.props.values);
        }

        if (this.props.active !== this.state.active) {
            // If the cell is now active, focus in on the quantity input
            this.setState({ active: this.props.active }, () => {
                if (this.state.active) {
                    document.getElementById(this.props.uid).focus();
                }
            });
        }
    }

    handleValuesChange(values) {
        let quantity = "";
        let price = "";

        if (typeof values === "object") {
            quantity = typeof values[0] === "number" ? values[0] : "";
            price = typeof values[1] === "number" ? values[1] : "";
        }

        this.setState(
            {
                quantityValue: quantity,
                priceValue: price,
            },
            () => {
                this.passUpValues();
            }
        );
    }

    handleQuantityChange(value) {
        value = value ? value : "";
        if (parseFloat(value) < 0) {
            value = "0";
        }
        this.setState(
            {
                quantityValue: parseFloat(value),
            },
            () => {
                this.passUpValues();
            }
        );
    }

    handlePriceChange(value) {
        value = value ? value : "";
        if (parseFloat(value) < 0) {
            value = "0";
        }
        this.setState(
            {
                priceValue: value,
            },
            () => {
                this.passUpValues();
            }
        );
    }

    passUpValues() {
        this.props.onValueChange([this.state.quantityValue, this.state.priceValue]);
    }

    render() {
        return (
            <div className="multiPurchaseCell">
                <Input id={this.props.uid} type="number" className="multiPurchaseCell input" value={this.state.quantityValue} onChange={(e) => this.handleQuantityChange(e.target.value)} disabled={!this.props.active} />

                <div className="multiPurchaseCell label">FOR</div>

                <NumberFormat
                    className={"multiPurchaseCell input bordered-input"}
                    onValueChange={(e) => {
                        this.handlePriceChange(e.floatValue);
                    }}
                    value={this.state.priceValue}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    prefix={"$"}
                    disabled={!this.props.active}
                />
            </div>
        );
    }
}
