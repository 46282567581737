// *** PACKAGE REFERENCES *** //
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik } from "formik";
import "moment/locale/en-nz";
import "react-datepicker/dist/react-datepicker.css";

// *** ACTIONS *** //
import { getContractDetails, saveContractDetails } from "../../../state/actions/ContractDetailsActions";
import { getGeneralDetails } from "../../../state/actions/GeneralDetailsActions";
import { getContract } from "../../../state/actions/ContractActions";
import { showError, hideError } from "../../../state/actions/GeneralActions";

// *** UI *** //
import { ContextPage } from "../../../containers/layout/ContextPage";
import { Card } from "../../../containers/card/Card";
import { CardRow } from "../../../containers/card/CardRow";
import { CCAInputTypes } from "../../../enums/CCAInputTypes";
import { CCAInput } from "../../../containers/inputs/CCAInput";
import { CCAError } from "../../../enums/CCAError";
import NumberFormat from "react-number-format";

class GeneralDetailsPage extends React.Component {
    constructor(props) {
        super(props);

        this.valuesAtLastSave = {};
        this.getDetails = this.getDetails.bind(this);
        this.getContractDetails = this.getContractDetails.bind(this);
        this.getGeneralDetails = this.getGeneralDetails.bind(this);
    }

    componentDidMount() {
        this.getDetails();
    }

    getGeneralDetails() {
        this.props.getGeneralDetails((success) => {
            if (success) {
                this.props.hideError(CCAError.GET_GENERAL_DETAILS_ERROR);
            } else {
                this.props.showError(CCAError.GET_GENERAL_DETAILS_ERROR, () => {
                    this.getGeneralDetails();
                });
            }
        });
    }

    getContractDetails() {
        this.props.getContractDetails(this.props.match.params.contractId, (success) => {
            if (success) {
                this.valuesAtLastSave = this.props.contractDetails.general;
                this.props.hideError(CCAError.GET_CONTRACT_DETAILS_ERROR);
            } else {
                this.props.showError(CCAError.GET_CONTRACT_DETAILS_ERROR, () => {
                    this.getContractDetails();
                });
            }
        });
    }

    getDetails() {
        this.getGeneralDetails();
        this.getContractDetails();
    }

    saveContractDetails(values) {
        this.props.saveContractDetails(this.props.match.params.contractId, values, "general", (success) => {
            if (success) {
                this.valuesAtLastSave = values;
                this.props.hideError(CCAError.SAVE_CONTRACT_DETAILS_ERROR);
            } else {
                this.props.showError(CCAError.SAVE_CONTRACT_DETAILS_ERROR, () => {
                    this.saveContractDetails(values);
                });
            }
        });
    }

    render() {
        return (
            <ContextPage fetching={this.props.fetching && this.props.fetchingGeneralDetails} fetched={this.props.fetched && this.props.fetchedGeneralDetails} title={this.props.title}>
                {this.props.fetched && this.props.fetchedGeneralDetails && (
                    <Fragment>
                        <Fragment>
                            <Formik
                                enableReinitialize={true}
                                initialValues={this.props.contractDetails.general}
                                onSubmit={(values) => {
                                    if (JSON.stringify(values) !== JSON.stringify(this.valuesAtLastSave)) {
                                        this.saveContractDetails(values);
                                    }
                                }}
                                render={({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => {
                                    return (
                                        <Fragment>
                                            <Card title={"General"}>
                                                <CardRow top label={"Contract Name"}>
                                                    <CCAInput
                                                        type={CCAInputTypes.FREE_TEXT}
                                                        name="contractName"
                                                        value={values.contractName}
                                                        onChange={handleChange}
                                                        onSave={(e) => {
                                                            handleSubmit(e);
                                                        }}
                                                    />
                                                </CardRow>

                                                <CardRow label={"Sub-Trade Channel"}>
                                                    <CCAInput
                                                        firstItemNone
                                                        type={CCAInputTypes.SELECT}
                                                        name="select"
                                                        value={values.subtradeChannel}
                                                        onChange={(e) => {
                                                            setFieldValue("subtradeChannel", e.target.value);
                                                        }}
                                                        onSave={(e) => {
                                                            handleSubmit(e);
                                                        }}
                                                        options={Object.keys(this.props.generalDetails.subTrade).map((key) => {
                                                            return { saveChoice: key, displayChoice: this.props.generalDetails.subTrade[key].name };
                                                        })}
                                                        optionsAreObjects
                                                        className={"borderless right"}
                                                    />
                                                </CardRow>

                                                <CardRow label={"Sales Office"}>
                                                    <CCAInput
                                                        firstItemNone
                                                        type={CCAInputTypes.SELECT}
                                                        name="select"
                                                        value={values.salesOffice}
                                                        onChange={(e) => {
                                                            setFieldValue("salesOffice", e.target.value);
                                                        }}
                                                        onSave={(e) => {
                                                            handleSubmit(e);
                                                        }}
                                                        options={Object.values(this.props.generalDetails.salesOffice).map((value) => {
                                                            return value;
                                                        })}
                                                    />
                                                </CardRow>

                                                <CardRow label={"Status"}>
                                                    <CCAInput
                                                        firstItemNone
                                                        type={CCAInputTypes.SELECT}
                                                        name="select"
                                                        value={values.accountStatus}
                                                        onChange={(e) => {
                                                            setFieldValue("accountStatus", e.target.value);
                                                        }}
                                                        onSave={(e) => {
                                                            handleSubmit(e);
                                                        }}
                                                        options={Object.values(this.props.generalDetails.accountStatus).map((value) => {
                                                            return value;
                                                        })}
                                                    />
                                                </CardRow>

                                                <CardRow label={"Price Rise"}>
                                                    <CCAInput
                                                        type={CCAInputTypes.SELECT}
                                                        name="select"
                                                        value={values.priceRise}
                                                        onChange={(e) => {
                                                            setFieldValue("priceRise", e.target.value);
                                                        }}
                                                        onSave={(e) => {
                                                            handleSubmit(e);
                                                        }}
                                                        options={Object.values(this.props.generalDetails.priceRise).map((value) => {
                                                            return value;
                                                        })}
                                                    />
                                                </CardRow>

                                                <CardRow bottom={values.priceRiseNotificationBy === "Email" ? false : true} label={"Preferred Means of Price Rise Notification"}>
                                                    <CCAInput
                                                        type={CCAInputTypes.SELECT}
                                                        name="select"
                                                        value={values.priceRiseNotificationBy}
                                                        onChange={(e) => {
                                                            setFieldValue("priceRiseNotificationBy", e.target.value);
                                                        }}
                                                        onSave={(e) => {
                                                            handleSubmit(e);
                                                        }}
                                                        options={["Email", "Post", "In Person"]}
                                                    />
                                                </CardRow>

                                                {values.priceRiseNotificationBy === "Email" && (
                                                    <CardRow bottom label={"Email Address"}>
                                                        <CCAInput
                                                            type={CCAInputTypes.FREE_TEXT}
                                                            name="email"
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            onSave={(e) => {
                                                                handleSubmit(e);
                                                            }}
                                                        />
                                                    </CardRow>
                                                )}
                                            </Card>
                                            <Card title={"Contract Length"}>
                                                <CardRow top label={"Start Date"}>
                                                    <NumberFormat
                                                        getInputRef={(el) => {
                                                            if (el) {
                                                                el.className = "form-control borderless right";
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            handleSubmit(e);
                                                        }}
                                                        className="right"
                                                        format="##/##/####"
                                                        placeholder="DD/MM/YYYY"
                                                        mask={["D", "D", "M", "M", "Y", "Y", "Y", "Y"]}
                                                        onValueChange={(value) => {
                                                            setFieldValue("contractStartDate", value.formattedValue);
                                                        }}
                                                        value={values.contractStartDate}
                                                    />
                                                </CardRow>

                                                <CardRow bottom label={"Signed Date"}>
                                                    <NumberFormat
                                                        getInputRef={(el) => {
                                                            if (el) {
                                                                el.className = "form-control borderless right";
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            handleSubmit(e);
                                                        }}
                                                        className="right"
                                                        format="##/##/####"
                                                        placeholder="DD/MM/YYYY"
                                                        mask={["D", "D", "M", "M", "Y", "Y", "Y", "Y"]}
                                                        onValueChange={(value) => {
                                                            setFieldValue("contractSignDate", value.formattedValue);
                                                        }}
                                                        value={values.contractSignDate}
                                                    />
                                                </CardRow>
                                            </Card>
                                        </Fragment>
                                    );
                                }}
                            />
                        </Fragment>
                    </Fragment>
                )}
            </ContextPage>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { fetching, fetched, failed, contractDetails, subtradeChannel } = state.contractDetailsReducer;
    const { fetchingGeneralDetails, fetchedGeneralDetails, failedGeneralDetails, generalDetails } = state.generalDetailsReducer;

    return { fetching, fetched, failed, contractDetails, subtradeChannel, fetchingGeneralDetails, fetchedGeneralDetails, failedGeneralDetails, generalDetails };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getContractDetails,
            saveContractDetails,
            getGeneralDetails,
            getContract,
            showError,
            hideError,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(GeneralDetailsPage));

// EXPORT COMPONENT
export { hoc as GeneralDetailsPage };
