// IMPORT PACKAGE REFERENCES
import React from "react";
import { Button } from "reactstrap";
import FeatherIcon from "feather-icons-react";

// COMPONENT
export class ContextHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            padding: 2,
        };
    }

    listenScrollEvent = (e) => {
        if (window.scrollY < 400) {
            this.setState({ padding: 1 });
        } else {
            this.setState({ padding: 2 });
        }
    };

    render() {
        let classes = "contextual-header" + (this.props.large ? " large" : "") + (this.props.hasTabs ? " hasTabs" : "");

        if (this.props.wide) {
            classes += " wide-content-guide";
        } else if (this.props.fullWidth) {
            classes += " full-width";
        } else {
            classes += " content-guide";
        }

        return (
            <div className={classes}>
                <div className={"page-title" + (this.props.large ? " large" : "")}>{this.props.title}</div>
                <div className="menu-right-action">
                    {this.props.firstElement}
                    {this.props.secondElement}
                    {this.props.thirdElement}
                    {this.props.fourthElement}
                    {this.props.fifthElement}
                    {this.props.pin && (
                        <Button className="menu-action-button" color="link" onClick={this.props.handlePin}>
                            <FeatherIcon className="button-icon" icon="star" />
                            {this.props.pinned ? "Unpin" : "Pin"}
                        </Button>
                    )}
                </div>

                {this.props.tabs}
            </div>
        );
    }
}
