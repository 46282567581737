import {
    GET_MATERIALS_PENDING,
    GET_MATERIALS_FULFILLED,
    GET_MATERIALS_REJECTED,
    ADD_MATERIAL_PENDING,
    ADD_MATERIAL_FULFILLED,
    ADD_MATERIAL_REJECTED,
    GET_CONTRACT_MATERIAL_PENDING,
    GET_CONTRACT_MATERIAL_FULFILLED,
    GET_CONTRACT_MATERIAL_REJECTED,
    PUT_CONTRACT_MATERIAL_UPDATING,
    PUT_CONTRACT_MATERIAL_UPDATED,
    PUT_CONTRACT_MATERIAL_FAILED,
    SEARCH_MATERIALS_FULFILLED,
    SEARCH_MATERIALS_PENDING,
    SEARCH_MATERIALS_FAILED,
    GET_SEGMENTATION_FULFILLED,
    GET_SEGMENTATION_PENDING,
    GET_SEGMENTATION_REJECTED,
    SET_HOVERED_EXPRESSION_INDEX,
    PUT_CONTRACT_MATERIALS_UPDATED,
    PUT_CONTRACT_MATERIALS_UPDATING,
    PUT_CONTRACT_MATERIALS_FAILED,
} from "../actions/MaterialActions";

import { CLEAR_REDUCERS } from "../actions/GeneralActions";

// INITIALIZE STATE
const initialState = {
    material: {
        UnitPriceEX: 0,
        Volume: 0,
    },
    materials: {
        allMaterials: {},
        suggestedMaterials: {},
        activeMaterials: {},
    },
    filteredMaterials: {},
    fetching: false,
    fetched: false,
    failed: false,
    updating: false,
    updated: false,
    segmentation: {},
    hoveredExpressionIndex: null,
    searchedMaterials: {},
};

// REDUCER
export const MaterialReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_REDUCERS:
            return initialState;
        /* GET MATERIALS */
        case GET_MATERIALS_PENDING:
            return {
                ...state,
                materials: {},
                filteredMaterials: {},
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_MATERIALS_FULFILLED:
            return {
                ...state,
                materials: action.payload.materials,
                filteredMaterials: action.payload.materials.allMaterials,
                materialsLastUpdated: action.payload.lastUpdated,
                fetching: false,
                fetched: true,
                failed: false,
            };
        case GET_MATERIALS_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        case ADD_MATERIAL_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
                failed: false,
            };
        case ADD_MATERIAL_FULFILLED:
            var newState = Object.assign({}, state);

            if (action.payload.status === "active") {
                // If the new material group has an active status add this to the activeMaterials object.
                newState.materials.activeMaterials[action.payload.materialId] = newState.materials.allMaterials[action.payload.materialId];
            } else {
                // If the new material group has an inactive status remove this to the activeMaterials object.
                delete newState.materials.activeMaterials[action.payload.materialId];
            }

            return {
                ...state,
                updating: false,
                updated: true,
                failed: false,
                materials: newState.materials,
            };
        case ADD_MATERIAL_REJECTED:
            return {
                ...state,
                updating: false,
                updated: false,
                failed: true,
            };
        // GET MATERIAL FOR SPECIFIC CONTRACT
        case GET_CONTRACT_MATERIAL_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_CONTRACT_MATERIAL_FULFILLED:
            return {
                ...state,
                material: action.payload,
                fetching: false,
                fetched: true,
                failed: false,
            };
        case GET_CONTRACT_MATERIAL_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        // UPDATE MATERIAL FOR SPECIFIC CONTRACT
        case PUT_CONTRACT_MATERIAL_UPDATING:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: false,
                updating: true,
                updated: false,
            };
        case PUT_CONTRACT_MATERIAL_UPDATED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: false,
                updating: false,
                updated: true,
            };
        case PUT_CONTRACT_MATERIAL_FAILED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
                updating: false,
                updated: false,
            };
        case SEARCH_MATERIALS_PENDING:
            return {
                ...state,
                searching: true,
            };
        case SEARCH_MATERIALS_FULFILLED:
            return {
                ...state,
                searching: false,
                searchedMaterials: action.payload,
            };
        case SEARCH_MATERIALS_FAILED:
            return {
                ...state,
                searching: false,
            };
        case GET_SEGMENTATION_PENDING:
            return {
                ...state,
                segmentation: {},
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_SEGMENTATION_FULFILLED:
            return {
                ...state,
                fetching: false,
                fetched: true,
                failed: false,
                segmentation: action.payload,
            };
        case GET_SEGMENTATION_REJECTED:
            return {
                ...state,
                segmentation: {},
                fetching: false,
                fetched: false,
                failed: true,
            };
        case SET_HOVERED_EXPRESSION_INDEX:
            return {
                ...state,
                hoveredExpressionIndex: action.payload,
            };
        case PUT_CONTRACT_MATERIALS_UPDATING:
            return {
                ...state,
                updating: true,
                updated: false,
                failed: false,
            };
        case PUT_CONTRACT_MATERIALS_UPDATED:
            return {
                ...state,
                updating: false,
                updated: true,
                failed: false,
            };
        case PUT_CONTRACT_MATERIALS_FAILED:
            return {
                ...state,
                updating: false,
                updated: false,
                failed: true,
            };
        default:
            return state;
    }
};
