// IMPORT PACKAGE REFERENCES
import React from "react";
import ReactDOM from "react-dom";
import { Loading } from "./Loading";

// COMPONENT
export default class Progress extends React.Component {
    render() {
        return ReactDOM.createPortal(
            <div className={"progress-page-container" + (this.props.showing ? "" : " hidden")}>
                <div className="progress-container">
                    <Loading medium noPadding />
                    <div className="progress-text">{this.props.progressText}</div>
                </div>
            </div>,
            document.body
        );
    }
}
