// IMPORT PACKAGE REFERENCES
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FeatherIcon from "feather-icons-react";

// UI
import { ContextHeader } from "../../../containers/layout/ContextHeader";
import { Loading } from "../../../containers/loading/Loading";
import { ProductDetailsPage } from "./ProductDetailsPage";
import { AddMaterialGroupsPage } from "./AddMaterialGroupsPage";
import { CustomerProfitStoryPage } from "./CustomerProfitStoryPage";

// Actions
import { getAlerts } from "../../../state/actions/AlertActions";
import { getProductGroups, saveProductGroupMaterials } from "../../../state/actions/ProductGroupActions";
import { getContractSummarySnapshot } from "../../../state/actions/SummaryActions";
import { RevenueAndCostsPage } from "./RevenueAndCostsPage";
import Modalised from "../../../containers/layout/Modalised";
import { FloatingButton } from "../../../containers/inputs/FloatingButton";
import { PricingCompliance } from "./PricingCompliance";
import { FloatingBar } from "../../../containers/layout/FloatingBar";
import Placeholder from "../../../containers/layout/Placeholder";


export const FormContext = React.createContext();

// COMPONENT
class ProductsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            alerts: props.alerts,
            loading: true,
            showingPopover: false,
            popoverPosition: { x: 0, y: 0 },
            isRemoveEnabled: false,
            tab: 0,
            floatingButtons: [{ type: "remove_materials" }, { type: "add_materials" }, { type: "recalculate" }],
            productGroups: {},
            salesStoryGroups: [
                {
                    groupName: "Product",
                    active: true,
                    color: "#475364",
                    sticky: true,
                    dataPoints: [
                        { editable: false, orderable: true, name: "Delete", bold: true, type: "delete", align: "left", dataIdentifier: "delete", width: 50, maxWidth: 50, disabled: false },
                        { editable: false, orderable: true, name: "Material Group", bold: true, align: "left", dataIdentifier: "MG1FriendlyDesc", width: 250, disabled: true, stuck: true },
                        { editable: false, orderable: true, name: "Product Group", bold: true, align: "center", dataIdentifier: "ProductGroup", width: 100, disabled: true, stuck: true },
                    ],
                },
                {
                    groupName: "Discounts Per Physical Case",
                    active: true,
                    color: "#FF9044",
                    dataPoints: [
                        { editable: true, orderable: true, name: "Discount on Invoice", align: "right", dataIdentifier: "flatDiscount", width: 100, format: { prefix: "$", type: "dollar", decimalPlaces: 2 } },
                        { editable: true, orderable: true, name: "Rebates (per Rebate UoM)", align: "right", dataIdentifier: "rebates", width: 100, format: { prefix: "$", type: "dollar", decimalPlaces: 2 } },
                        { editable: true, orderable: true, name: "Rebate Payment Frequency", align: "right", dataIdentifier: "paymentFrequency", width: 120, format: { type: "dropdown" } },
                        { editable: true, orderable: true, name: "Rebate Unit Of Measure", align: "right", dataIdentifier: "unitOfMeasurement", width: 120, format: { type: "dropdown" } },
                        { editable: true, orderable: true, name: "PAs (per Rebate UoM)", align: "right", dataIdentifier: "paPerCase", width: 100, format: { prefix: "$", type: "dollar", decimalPlaces: 2 } },
                        { editable: true, orderable: true, name: "Payment Allocation", selectable: true, align: "right", dataIdentifier: "paymentAllocation", width: 120, format: { suffix: "%", type: "percentage", decimalPlaces: 1 }, hasDropdownAction: true },
                        { editable: false, orderable: true, name: "Total Discount", align: "right", dataIdentifier: "TotalDiscount", calculated: true, width: 120, disabled: true, highlight: { dependent: "FloorMatrix/AverageDiscount", comparator: "greater" }, format: { suffix: "%", type: "percentage", decimalPlaces: 1 } },
                        { editable: false, orderable: true, name: "Unit Price (Inc GST)", align: "right", dataIdentifier: "InvoiceGSTPerUnitCase", disabled: true, calculated: true, width: 100, format: { prefix: "$", type: "dollar", decimalPlaces: 2 } },
                    ],
                },
                {
                    groupName: "Physical Case Price (excl GST)",
                    active: true,
                    color: "#008000",
                    dataPoints: [
                        { editable: false, orderable: true, name: "Wholesale", align: "right", dataIdentifier: "Amount", calculated: false, disabled: true, width: 100, format: { prefix: "$", type: "dollar", decimalPlaces: 2 } },
                        { editable: false, orderable: true, name: "Invoice Per Case", align: "right", dataIdentifier: "Invoice", disabled: true, calculated: true, width: 100, format: { prefix: "$", type: "dollar", decimalPlaces: 2 } },
                        { editable: false, orderable: true, name: "Net Sales Revenue", align: "right", dataIdentifier: "NSR", width: 100, disabled: true, calculated: true, format: { prefix: "$", type: "dollar", decimalPlaces: 2 } },
                        { editable: false, orderable: true, name: "Net Sales Revenue per UC", align: "right", dataIdentifier: "Rate", width: 100, calculated: true, disabled: true, format: { prefix: "$", type: "dollar", decimalPlaces: 2 } },
                        { editable: false, orderable: true, name: "Net Contribution", align: "right", dataIdentifier: "NetContributionPerPhysicalCase", disabled: true, calculated: true, width: 100, highlight: { fixedValue: 0, comparator: "less" }, format: { prefix: "$", type: "dollar", decimalPlaces: 2 } },
                        { editable: false, orderable: true, name: "Average COGs + Sales Office", align: "right", dataIdentifier: "AverageCOGSDC", calculated: true, width: 120, disabled: true, format: { prefix: "$", type: "dollar", decimalPlaces: 2, displayAsNegative: false } },
                    ],
                },
                {
                    groupName: "Unit Price (excl GST)",
                    active: true,
                    color: "#3F6EA2",
                    dataPoints: [
                        { editable: false, orderable: true, name: "Units/Serves Per Case", align: "right", dataIdentifier: "UnitsPerCase", calculated: false, disabled: true, width: 150, format: { type: "number", decimalPlaces: 0 } },
                        { editable: false, orderable: true, name: "Total Units/Serves", align: "right", dataIdentifier: "TotalUnits", calculated: true, disabled: true, width: 150, format: { type: "number", decimalPlaces: 0 } },
                        { editable: false, orderable: true, name: "Invoice", align: "right", dataIdentifier: "InvoicePerUnitCase", calculated: true, width: 120, disabled: true, format: { type: "dollar", decimalPlaces: 2 } },
                        { editable: false, orderable: true, name: "NSR", align: "right", dataIdentifier: "NSRPerUnitCase", calculated: true, width: 120, disabled: true, format: { type: "dollar", decimalPlaces: 2 } },
                        { editable: false, orderable: true, name: "Net Cont/Unit", align: "right", dataIdentifier: "NetContPerUnit", calculated: true, width: 120, disabled: true, format: { type: "dollar", decimalPlaces: 2 } },
                    ],
                },
                {
                    groupName: "Averages",
                    color: "#50538B",
                    active: true,
                    dataPoints: [
                        { editable: false, orderable: true, name: "Average Discount - Total Discount", align: "right", dataIdentifier: "AveDiscountMinusTotalDiscount", calculated: true, width: 120, disabled: true, format: { type: "percentage", decimalPlaces: 1 } },
                        /* This column is showing incorrect values. Rather than attempt to fix it we've agreed to hide it for now. */
                        // { editable: false, orderable: true, name: "Floor Matrix / Average Discount", align: "right", dataIdentifier: "FloorMatrix/AverageDiscount", calculated: true, width: 120, disabled: true, format: { type: "percentage", decimalPlaces: 1 } },
                        { editable: false, orderable: true, name: "Floor Matrix / Average - Total Discount", align: "right", dataIdentifier: "FloorMatrix/AverageMinusTotalDiscount", calculated: true, width: 120, disabled: true, format: { type: "percentage", decimalPlaces: 1 } },
                    ],
                },
            ],
        };

        this.togglePopover = this.togglePopover.bind(this);
        this.toggleColumnPopover = this.toggleColumnPopover.bind(this);
        this.toggleColumnActive = this.toggleColumnActive.bind(this);

        // Enable/Disable remove...
        this.enableRemove = this.enableRemove.bind(this);
        this.disableRemove = this.disableRemove.bind(this);

        this.renderFloatingButtons = this.renderFloatingButtons.bind(this);
        this.setFloatingButtons = this.setFloatingButtons.bind(this);

        // Material Data Table Ref...
        this.materialDataTable = null;

        this.recalculate = () => {
            // This doesn't seem to get called
            this.getAlerts();
            console.log("Recalculate")
        };
        this.export = () => { console.log("Export") };
    }

    componentDidMount() {
        // Fetch the product groups to show on the page.
        this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => {
            if(success) {
                this.getAlerts();
                this.setState({ alerts: this.props.alerts }, () => {
                    console.log(this.state.alerts)
                });
                console.log('Mounted, product groups fetched')
            } else {
                console.error("Failed to get product groups")
            }
        });

        setTimeout(() => {
            this.setState({ loading: false });
        }, 100);
    }

    getContractIdFromUrl(providedProps) {
        let props = this.props;
        if (providedProps) {
            props = providedProps;
        }

        let components = props.location.pathname.split("/");
        return components[2];
    }

    getScenarioIdFromUrl(providedProps, newScenarioId = null) {
        if (newScenarioId) {
            return newScenarioId;
        }

        let props = this.props;

        if (providedProps) {
            props = providedProps;
        }

        let components = props.location.pathname.split("/");
        return components[3];
    }

    getAlerts() {
        const contractId = this.getContractIdFromUrl();
        const scenarioId = this.getScenarioIdFromUrl();
        
        this.props.getAlerts(contractId, scenarioId, (alertSuccess) => {
            if (!alertSuccess){
                console.log("Failed to retrieve contract alerts");
                return
            } 
            console.log("Successfully retrieved contract alerts");
        });
    }

    renderAlerts() {
        if(!this.state.alerts || !('Products' in this.state.alerts)) return;
        // Make sure we're only showing product alerts in this view
        const alerts = Object.values(this.state.alerts.Products);
        if(!alerts.length) return;

        return alerts.map(alert => {
            return (
                <div className="payment-allocation-warning alerts" key={alert.key}>
                    <FeatherIcon icon="alert-circle" />
                    <span>{alert.message}</span>
                </div>
            )
        });
    }

    toggleColumnActive(columnIndex) {
        let salesStoryGroups = Object.assign([], this.state.salesStoryGroups);
        salesStoryGroups[columnIndex].active = !salesStoryGroups[columnIndex].active;
        this.setState({ salesStoryGroups: salesStoryGroups });
    }

    togglePopover(e) {
        e.preventDefault();
        this.setState({ showingPopover: !this.state.showingPopover, popoverPosition: { x: e.clientX, y: e.clientY } });
    }

    toggleColumnPopover(e) {
        e.preventDefault();
        this.setState({ showingColumnPopover: !this.state.showingColumnPopover, popoverPosition: { x: e.clientX, y: e.clientY } });
    }

    showFloatingButtons(buttons) {
        this.setState({ floatingButtons: buttons });
    }

    enableRemove() {
        this.setState({ isRemoveEnabled: true });
    }

    disableRemove() {
        this.setState({ isRemoveEnabled: false });
        this.props.getContractSummarySnapshot(this.props.match.params.contractId, this.props.match.params.scenarioId);
    }

    changeTab(index) {
        this.setState({ tab: index });
    }

    calculateTotalPaymentAllocation() {
        if(!this.state || !this.state.productGroups) return;

        const totalPaymentAllocation = Object.values(this.state.productGroups).reduce((accGroupTotal, currentGroup) => {
            return accGroupTotal += Object.values(currentGroup).reduce((accMaterialTotal, currentMaterial) => {
                return accMaterialTotal += Object.values(currentMaterial.materials).reduce((accPaymentAllocation, currentMaterial) => {
                    return accPaymentAllocation += currentMaterial.paymentAllocation;
                }, 0);
            }, 0);
        }, 0);

        const roundedTotal = Math.round((totalPaymentAllocation + 0.00001) * 10) / 10;

        this.setState({ totalPaymentAllocation: roundedTotal });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.scenarioId !== this.props.match.params.scenarioId) {
            this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
        }

        if (JSON.stringify(this.state.productGroups) !== JSON.stringify(this.props.productGroups)) {
            this.setState({ productGroups: this.props.productGroups });
            this.getAlerts();
        }

        if (JSON.stringify(this.props.alerts) !== JSON.stringify(this.state.alerts)) {
            this.setState({ alerts: this.props.alerts });
        }
    }

    renderFloatingButtons(buttons) {
        return buttons.map((button, buttonIndex) => {
            if (button.type === "add_materials") {
                return (
                    <FloatingButton
                        key={`add_materials-${buttonIndex}`}
                        label={
                            <div>
                                {" "}
                                <FeatherIcon className="fab-button-icon" icon="plus-circle" /> Add Material Group{" "}
                            </div>
                        }
                        action={(e) => this.togglePopover(e)}
                    />
                );
            } else if (button.type === "remove_materials") {
                return (
                    <FloatingButton
                        key={`remove_materials-${buttonIndex}`}
                        classes={`${this.state.isRemoveEnabled ? " enabled" : " disabled"}`}
                        label={
                            <div>
                                <FeatherIcon className="fab-button-icon" icon="trash-2" /> Delete Material Group{" "}
                            </div>
                        }
                        action={(e) => {
                            if (confirm("Are you sure you want to delete selected materials?")) {
                                this.materialDataTable.handleDelete();
                            }
                        }}
                    />
                );
            } else if (button.type === "recalculate") {
                return (
                    <FloatingButton
                        key={`recalculate-${buttonIndex}`}
                        classes={"delete-button"}
                        label={
                            <div>
                                <FeatherIcon className="fab-button-icon" icon="refresh-cw" /> Recalculate{" "}
                            </div>
                        }
                        action={(e) => {
                            this.recalculate();
                        }}
                    />
                )
            } else if (button.type === "xlsx_export") {
                return (
                    <FloatingButton
                        key={`xlsx_export-${buttonIndex}`}
                        label={
                            <div>
                                {" "}
                                <FeatherIcon className="fab-button-icon" icon="download" /> Excel Export{" "}
                            </div>
                        }
                        action={(e) => this.export()}
                    />
                )
            }
            else {
                return button.value;
            }
        });
    }

    setFloatingButtons(buttons) {
        this.setState({
            floatingButtons: buttons
        });
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }
        return (
            <div>
                <ContextHeader
                    tabs={
                        <div className="tabs products-tabs">
                            <div
                                onClick={() => {
                                    this.changeTab(0);
                                }}
                                className={"tab left" + (this.state.tab === 0 ? " active" : "")}
                            >
                                Customer Profit Story
                            </div>
                            <div
                                onClick={() => {
                                    this.changeTab(1);
                                }}
                                className={"tab" + (this.state.tab === 1 ? " active" : "")}
                            >
                                Discounts
                            </div>
                            <div
                                onClick={() => {
                                    this.changeTab(2);
                                }}
                                className={"tab" + (this.state.tab === 2 ? " active" : "")}
                            >
                                Revenue & Costs
                            </div>
                            <div
                                onClick={() => {
                                    this.changeTab(3);
                                }}
                                className={"tab right" + (this.state.tab === 3 ? " active" : "")}
                            >
                                Pricing Compliance & Ranging
                            </div>
                        </div>
                    }
                    wide
                    title={"Products"}
                />

                <div>
                    { this.renderAlerts() }
                    {Object.values(this.state.productGroups).length > 0 ? (
                        (this.state.tab === 0 && <CustomerProfitStoryPage setFloatingButtons={this.setFloatingButtons} getAlerts={this.getAlerts} disableRemove={this.disableRemove} enableRemove={this.enableRemove} onRef={(ref) => (this.materialDataTable = ref)} groups={this.state.customerProfitStoryGroups} bindRefresh={(recalculate) => (this.recalculate = recalculate)} bindExport={(exportFunc) => (this.export = exportFunc)} />) ||
                        (this.state.tab === 1 && <ProductDetailsPage setFloatingButtons={this.setFloatingButtons} getAlerts={this.getAlerts} groups={this.state.salesStoryGroups} disableRemove={this.disableRemove} enableRemove={this.enableRemove} onRef={(ref) => (this.materialDataTable = ref)} bindRefresh={(recalculate) => (this.recalculate = recalculate)} />) ||
                        (this.state.tab === 2 && <RevenueAndCostsPage setFloatingButtons={this.setFloatingButtons} getAlerts={this.getAlerts} disableRemove={this.disableRemove} enableRemove={this.enableRemove} onRef={(ref) => (this.materialDataTable = ref)} bindRefresh={(recalculate) => (this.recalculate = recalculate)} />) ||
                        (this.state.tab === 3 && <PricingCompliance setFloatingButtons={this.setFloatingButtons} getAlerts={this.getAlerts} disableRemove={this.disableRemove} enableRemove={this.enableRemove} onRef={(ref) => (this.materialDataTable = ref)} bindRefresh={(recalculate) => (this.recalculate = recalculate)} />)
                    ) : (
                        <Placeholder type="no_materials" />
                    )}
                </div>
                

                <Modalised
                    title={"Add Materials"}
                    handleClose={() => this.setState({ showingPopover: false })}
                    showing={this.state.showingPopover}
                    action={
                        <div
                            onClick={() => {
                                this.setState({ showingPopover: false });
                            }}
                            className="text-link"
                        >
                            <FeatherIcon icon="x" />
                        </div>
                    }
                >
                    <div className="add-material-modal-content">
                        <AddMaterialGroupsPage
                            complete={() => {
                                this.setState({ showingPopover: false });
                                this.props.getContractSummarySnapshot(this.props.match.params.contractId, this.props.match.params.scenarioId);
                            }}
                        />
                    </div>
                </Modalised>

                <FloatingBar>{this.renderFloatingButtons(this.state.floatingButtons)}</FloatingBar>
            </div>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { productGroups, fetching } = state.productGroupReducer;
    const { alerts } = state.alertReducer;
    return { productGroups, fetching, alerts };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getAlerts,
    getProductGroups,
    getContractSummarySnapshot
}, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductsPage));

// EXPORT COMPONENT
export { hoc as ProductsPage };
