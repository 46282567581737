// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { PageTitle } from "./PageTitle";
import { Button } from "reactstrap";
import { toggleLeftDetail } from "../../state/actions/UserActions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// COMPONENT
class ContextualSidebar extends React.Component {
    constructor() {
        super();

        this.state = {
            collapsed: false,
        };
    }

    render() {
        return (
            <div className="sidebar-container">
                <div
                    onClick={() => {
                        /* this.setState({ collapsed: !this.state.collapsed }) */
                    }}
                    className={"contextual-sidebar-left" + (this.state.collapsed ? " collapsed" : "") + (this.props.leftDetailOpen ? " open" : " closed")}
                >
                    <div className={"contextual-page-title" + (this.state.collapsed ? " collapsed" : "")}>{this.props.pageTitle}</div>
                    <div className={"contextual-page-children" + (this.state.collapsed ? " collapsed" : "")}>{this.props.left}</div>
                </div>
                <div className="contextual-sidebar-right">{this.props.right}</div>
            </div>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { leftDetailOpen } = state.userReducer;
    return { leftDetailOpen };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ toggleLeftDetail }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(ContextualSidebar));

// EXPORT COMPONENT

export { hoc as ContextualSidebar };
