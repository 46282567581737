import {
    GET_CONTRACT_DETAILS_PENDING,
    GET_CONTRACT_DETAILS_FULFILLED,
    GET_CONTRACT_DETAILS_REJECTED,
    SAVE_CONTRACT_DETAILS_PENDING,
    SAVE_CONTRACT_DETAILS_FULFILLED,
    SAVE_CONTRACT_DETAILS_REJECTED,
    POPULATE_CONTRACT_DETAILS,
    SAVE_SCENARIO_DETAILS_PENDING,
    SAVE_SCENARIO_DETAILS_FULFILLED,
    SAVE_SCENARIO_DETAILS_REJECTED,
    GET_SCENARIO_DETAILS_PENDING,
    GET_SCENARIO_DETAILS_FULFILLED,
    GET_SCENARIO_DETAILS_REJECTED,
    SET_COMPARISON_SCENARIO,
    SET_COMPARISON_SCENARIOS,
} from "../actions/ContractDetailsActions";

// INITIALIZE STATE
const initialState = {
    contractDetails: {},
    fetching: false,
    fetched: false,
    failed: false,
    updating: false,
    updated: false,
    updateFailed: false,
    scenarioDetails: {},
    comparisonScenario: null,
    comparisonScenarios: [],
};

import { CLEAR_REDUCERS, PREPARE_FOR_CONTRACT_SWITCH } from "../actions/GeneralActions";

// REDUCER
export const ContractDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_REDUCERS:
            return initialState;
        case PREPARE_FOR_CONTRACT_SWITCH:
            return initialState;
        /* GET CONTRACT DETAILS */
        case GET_CONTRACT_DETAILS_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_CONTRACT_DETAILS_FULFILLED:
            return {
                ...state,
                contractDetails: action.payload,
                fetching: false,
                fetched: true,
                failed: false,
            };
        case GET_CONTRACT_DETAILS_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        case SAVE_CONTRACT_DETAILS_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
                updateFailed: false,
            };
        case SAVE_CONTRACT_DETAILS_FULFILLED:
            return {
                ...state,
                contractDetails: action.payload,
                updating: false,
                updated: true,
                updateFailed: false,
            };
        case SAVE_CONTRACT_DETAILS_REJECTED:
            return {
                ...state,
                updating: false,
                updated: false,
                updateFailed: true,
            };
        case GET_SCENARIO_DETAILS_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_SCENARIO_DETAILS_FULFILLED:
            return {
                ...state,
                scenarioDetails: action.payload,
                fetching: false,
                fetched: true,
                failed: false,
            };
        case GET_SCENARIO_DETAILS_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        case SAVE_SCENARIO_DETAILS_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
                updateFailed: false,
            };
        case SAVE_SCENARIO_DETAILS_FULFILLED:
            return {
                ...state,
                scenarioDetails: action.payload,
                updating: false,
                updated: true,
                updateFailed: false,
            };
        case SAVE_SCENARIO_DETAILS_REJECTED:
            return {
                ...state,
                updating: false,
                updated: false,
                updateFailed: true,
            };
        case POPULATE_CONTRACT_DETAILS:
            return {
                ...state,
                contractDetails: action.payload,
            };
        case SET_COMPARISON_SCENARIO:
            return {
                ...state,
                comparisonScenario: action.payload,
            };
        case SET_COMPARISON_SCENARIOS:
            return {
                ...state,
                comparisonScenarios: action.payload,
            };
        default:
            return state;
    }
};
