// IMPORT PACKAGES
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

// IMPORT STORE
import { createAppStore } from "../components/state/stores/AppStore";

// IMPORT COMPONENTS
import { AppRouter } from "./routers/AppRouter";

export const redux = createAppStore();

// COMPONENT
export const App = () => {
    return (
        <Provider store={redux.store}>
            <PersistGate loading={null} persistor={redux.persistor}>
                <AppRouter />
            </PersistGate>
        </Provider>
    )
};
