import axios from "axios";
import { API_ROOT } from "../../../config/config";
import { CCARequestTypes } from "../../enums/CCARequestTypes";

export const GET_PAYMENT_PENDING = "GET_PAYMENT_PENDING";
export const GET_PAYMENT_FULFILLED = "GET_PAYMENT_FULFILLED";
export const GET_PAYMENT_REJECTED = "GET_PAYMENT_REJECTED";

export const GET_PAYMENTS_PENDING = "GET_PAYMENTS_PENDING";
export const GET_PAYMENTS_FULFILLED = "GET_PAYMENTS_FULFILLED";
export const GET_PAYMENTS_REJECTED = "GET_PAYMENTS_REJECTED";

export const ADD_PAYMENT_PENDING = "ADD_PAYMENT_PENDING";
export const ADD_PAYMENT_FULFILLED = "ADD_PAYMENT_FULFILLED";
export const ADD_PAYMENT_REJECTED = "ADD_PAYMENT_REJECTED";

export const UPDATE_PAYMENT_PENDING = "UPDATE_PAYMENT_PENDING";
export const UPDATE_PAYMENT_FULFILLED = "UPDATE_PAYMENT_FULFILLED";
export const UPDATE_PAYMENT_REJECTED = "UPDATE_PAYMENT_REJECTED";

export const DELETE_PAYMENTS_PENDING = "DELETE_PAYMENTS_PENDING";
export const DELETE_PAYMENTS_FULFILLED = "DELETE_PAYMENTS_FULFILLED";
export const DELETE_PAYMENTS_REJECTED = "DELETE_PAYMENTS_REJECTED";

export const SAVE_PAYMENT_CONDITIONS_PENDING = "SAVE_PAYMENT_CONDITIONS_PENDING";
export const SAVE_PAYMENT_CONDITIONS_FULFILLED = "SAVE_PAYMENT_CONDITIONS_FULFILLED";
export const SAVE_PAYMENT_CONDITIONS_REJECTED = "SAVE_PAYMENT_CONDITIONS_REJECTED";

export const GET_NOVELTIES_PENDING = "GET_NOVELTIES_PENDING";
export const GET_NOVELTIES_FULFILLED = "GET_NOVELTIES_FULFILLED";
export const GET_NOVELTIES_REJECTED = "GET_NOVELTIES_REJECTED";

export const GET_BASE_NOVELTIES_PENDING = "GET_BASE_NOVELTIES_PENDING";
export const GET_BASE_NOVELTIES_FULFILLED = "GET_BASE_NOVELTIES_FULFILLED";
export const GET_BASE_NOVELTIES_REJECTED = "GET_BASE_NOVELTIES_REJECTED";

// ACTION GENERATORS

/*
    Retrieve a payment by its ID
*/
const getPayment = (contractId, scenarioId, paymentId, callback) => (dispatch) => {
    dispatch({
        type: GET_PAYMENT_PENDING,
    });

    axios
        .get(`${API_ROOT}/contracts/${contractId}/${scenarioId}/financialIncentives/payments/${paymentId}`)
        .then((response) => {
            const payment = { id: paymentId, ...response.data };

            dispatch({
                type: GET_PAYMENT_FULFILLED,
                payload: payment,
            });

            callback();
        })
        .catch((error) => {
            dispatch({
                type: GET_PAYMENT_REJECTED,
            });

            console.log("An error ocurred requesting payment");
        });
};

/*
    Retrieve all the payments
*/
const getPayments = (contractId, scenarioId, callback) => (dispatch) => {
    dispatch({
        type: GET_PAYMENTS_PENDING,
    });

    axios
        .get(`${API_ROOT}/contracts/${contractId}/${scenarioId}/financialIncentives/payments`)
        .then((response) => {
            dispatch({
                type: GET_PAYMENTS_FULFILLED,
                payload: response.data,
            });

            callback(true);
        })
        .catch((error) => {
            dispatch({
                type: GET_PAYMENTS_REJECTED,
            });

            console.log("An error ocurred requesting payments", error);
            callback(false);
        });
};

/*
Get all contract Novelties
*/
const getNovelties = (contractId, scenarioId, callback) => (dispatch) => {
    dispatch({
        type: GET_NOVELTIES_PENDING,
    });

    axios
        .get(`${API_ROOT}/contracts/${contractId}/${scenarioId}/financialIncentives/novelties`)
        .then((response) => {
            dispatch({
                type: GET_NOVELTIES_FULFILLED,
                payload: response.data,
            });

            callback(true);
        })
        .catch((error) => {
            dispatch({
                type: GET_NOVELTIES_REJECTED,
            });

            console.log("An error ocurred requesting novelties", error);
            callback(false);
        });
};

const getAllNovelties = (callback) => (dispatch) => {
    dispatch({
        type: GET_BASE_NOVELTIES_PENDING,
    });

    axios
        .get(`${API_ROOT}/novelties`)
        .then((response) => {
            dispatch({
                type: GET_BASE_NOVELTIES_FULFILLED,
                payload: response.data,
            });

            callback(true);
        })
        .catch((error) => {
            dispatch({
                type: GET_BASE_NOVELTIES_REJECTED,
            });

            console.log("An error ocurred requesting novelties", error);
            callback(false);
        });
}

/*
    Add a payment to the contract.
*/
const addPayment = (contractId, scenarioId, data, callback) => (dispatch) => {
    let requestId = CCARequestTypes.PAYMENT_ADD;

    dispatch({
        type: ADD_PAYMENT_PENDING,
    });

    axios
        .post(`${API_ROOT}/contracts/${contractId}/${scenarioId}/financialIncentives/payments`, {
            data,
            requestId,
        })
        .then((response) => {
            dispatch({
                type: ADD_PAYMENT_FULFILLED,
            });

            callback(true, response.data);
        })
        .catch((error) => {
            dispatch({
                type: ADD_PAYMENT_REJECTED,
            });

            console.log("An error ocurred adding new payment", error);
            callback(false);
        });
};

const savePayment = (contractId, scenarioId, paymentId, data, callback) => (dispatch) => {
    let requestId = CCARequestTypes.PAYMENT_SAVE;

    dispatch({
        type: UPDATE_PAYMENT_PENDING,
    });

    axios
        .put(`${API_ROOT}/contracts/${contractId}/${scenarioId}/financialIncentives/payments/${paymentId}`, {
            data,
            requestId,
        })
        .then((response) => {
            dispatch({
                type: UPDATE_PAYMENT_FULFILLED,
                payload: { id: paymentId, ...data },
            });
            callback(true);
        })
        .catch((error) => {
            dispatch({
                type: UPDATE_PAYMENT_REJECTED,
            });
            callback(false);
        });
};

/*
    Delete a set payments
*/
const deletePayments = (contractId, scenarioId, values, callback) => (dispatch) => {
    dispatch({
        type: DELETE_PAYMENTS_PENDING,
    });

    axios
        .delete(`${API_ROOT}/contracts/${contractId}/${scenarioId}/financialIncentives/payments`, { data: values })
        .then((res) => {
            /* Fulfil the request for equipment, since the API succceded */
            dispatch({
                type: DELETE_PAYMENTS_FULFILLED,
                payload: values,
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred removing payments", error);

            /* Reject the request for saving the equipment, since the API failed. */
            dispatch({
                type: DELETE_PAYMENTS_REJECTED,
            });

            callback(false);
        });
};

const addRebate = (contractId, values, callback) => {
    console.log("Add rebate here!");
};

/*
    Save payment conditions.
*/
const savePaymentConditions = (contractId, scenarioId, paymentId, data, callback) => (dispatch) => {
    dispatch({
        type: SAVE_PAYMENT_CONDITIONS_PENDING,
    });

    /*
        We want to add equipment.
    */
    axios
        .post(`${API_ROOT}/contracts/${contractId}/${scenarioId}/financialIncentives/payments/${paymentId}/conditions`, { data: data })
        .then(() => {
            dispatch({
                type: SAVE_PAYMENT_CONDITIONS_FULFILLED,
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting equipment.", error);

            /* Reject the request for equipment, since the API failed. */
            dispatch({
                type: SAVE_PAYMENT_CONDITIONS_REJECTED,
            });

            callback(false);
        });
};

// EXPORT ACTIONS
export { getPayment, getPayments, getNovelties, addPayment, savePayment, deletePayments, addRebate, savePaymentConditions, getAllNovelties };
