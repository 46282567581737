// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { ContextPage } from "../../../containers/layout/ContextPage";
import { Card } from "../../../containers/card/Card";
import { CardRow } from "../../../containers/card/CardRow";
import { CardActions } from "../../../containers/card/CardActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";

// IMPORT ACTIONS
import { getEquipment, saveEquipment, saveEquipmentConditions } from "../../../state/actions/EquipmentActions";
import { getNonFinancialSummary } from "../../../state/actions/NonFinancialActions";
import { showError, hideError } from "../../../state/actions/GeneralActions";
import { getContractDetails, saveContractDetails, getScenarioDetails, saveScenarioDetails } from "../../../state/actions/ContractDetailsActions";

//IMPORT UI
import { Message } from "../../../containers/layout/Message";
import { Conditionaliser } from "../../../../modules/conditionaliser/Conditionaliser";
import { saveContractCustomConditions } from "../../../state/actions/ContractActions";
import { CCAInput } from "../../../containers/inputs/CCAInput";
import { CCAInputTypes } from "../../../enums/CCAInputTypes";
import { CCAError } from "../../../enums/CCAError";
import Select from 'react-select';

// COMPONENT
class EquipmentDetailsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: {
                1: false,
                2: false,
            },
            showingId: undefined,
            conditionSaveLoading: false,
            b2b: false
        };

        this.rentalOptions = [
            { label: "Free On Loan", value: "free" },
            { label: "Lease to Own", value: "lease" },
            { label: "Outright Purchase", value: "purchase" }
        ];

        this.valuesAtLastSave = {};
        this.scenarioDetails = {};

        this.toggleEditing = this.toggleEditing.bind(this);
        this.handleSaveCustomConditions = this.handleSaveCustomConditions.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getStringValueForRentalType = this.getStringValueForRentalType.bind(this);
        this.conditionaliser = null;
        this.getRentalOption = this.getRentalOption.bind(this);
    }

    componentDidMount() {
        this.getEquipment(this.props);
        this.getDetails();
    }

    componentDidUpdate() {
        this.getEquipment();
    }

    getDetails() {
        this.props.getScenarioDetails(this.props.match.params.contractId, this.props.match.params.scenarioId, () => {
            this.scenarioDetails = this.props.scenarioDetails;
        });
    }

    /*
        Fetches the equipment only if the previous payment is different to the current or did not exist before.
    */
    getEquipment() {
        if (this.state.showingId !== this.props.match.params.id) {
            this.props.getEquipment(this.props.match.params.contractId, this.props.match.params.scenarioId, this.props.match.params.id, () => {
                this.valuesAtLastSave = this.props.equipment;
            });
            this.setState({ showingId: this.props.match.params.id });
        }
        if (this.props.equipment != null && (this.props.equipment.equipmentType == "B2B" || this.props.equipment.rental)) {
            this.state.b2b = true;
        }
    }

    handleSaveCustomConditions(conditions) {
        this.setState({ messageActive: true, messageLoading: true, message: "Saving Custom Conditions" });
        this.props.saveContractCustomConditions(this.props.match.params.contractId, conditions, (success) => {
            this.setState({ messageActive: false, messageLoading: false });
        });
    }

    handleChange() { }

    getRentalOption(value) {
        let selectedOption = this.rentalOptions.filter((item) => item.value == value);
        return selectedOption.length >= 1 ? selectedOption[0] : null;
    }

    handleSubmit() {
        if (this.conditionaliser !== null) {
            let expressions = JSON.parse(this.conditionaliser.getExpressions());

            this.setState({ conditionSaveLoading: true });
            this.props.saveEquipmentConditions(this.props.match.params.contractId, this.props.match.params.scenarioId, this.props.match.params.id, expressions, (success) => {
                if (success) {
                    this.setState({ conditionSaveLoading: false });
                } else {
                    this.setState({ messageActive: true, messageError: true, message: "Error saving equipment conditions" });
                    setTimeout(() => {
                        this.setState({ messageActive: false, messageError: false, conditionSaveLoading: false });
                    }, 2000);
                }
            });
        }
    }

    toggleEditing(sectionNumber) {
        this.setState({ editing: { ...this.state.editing, [sectionNumber]: !this.state.editing[sectionNumber] } });
    }

    getNonFinancialSummary() {
        this.props.getNonFinancialSummary(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => {
            if (success) {
                this.props.hideError(CCAError.GET_NON_FINANCIAL_SUMMARY_ERROR);
            } else {
                this.props.showError(CCAError.GET_NON_FINANCIAL_SUMMARY_ERROR, () => {
                    this.getNonFinancialSummary();
                });
            }
        });
    }

    saveEquipment(values) {
        this.props.saveEquipment(this.props.match.params.contractId, this.props.match.params.scenarioId, this.props.match.params.id, values, (success) => {
            if (success) {
                this.props.hideError(CCAError.SAVE_EQUIPMENT_ERROR);
                this.valuesAtLastSave = values;
                this.getNonFinancialSummary();
            } else {
                this.props.showError(CCAError.SAVE_EQUIPMENT_ERROR, () => {
                    this.saveEquipment(values);
                });
            }
        });
    }

    getStringValueForRentalType(rentalValue, values, setValue, submitForm) {
        // Null check
        if (values == null || this.scenarioDetails == null) return (<div></div>)
        // Normal code
        if (rentalValue == "purchase") {
            var str = `$${values.capitalValue * values.quantity} one off charge.`;
            if (values.rentalDetails && values.rentalDetails.stringValue) {
                if (values.rentalDetails.stringValue !== str) {
                    setValue("rentalDetails.stringValue", str);
                    submitForm();
                }
            } else {
                setValue("rentalDetails.stringValue", str);
                submitForm();
            }
            return (<div>{str}</div>);

        } else if (rentalValue == "lease") {
            var str = `$${((values.capitalValue * values.quantity) / (this.scenarioDetails.contractDuration * 12)).toFixed(2)} Monthly Fee`;
            if (values.rentalDetails && values.rentalDetails.stringValue) {
                if (values.rentalDetails.stringValue !== str) {
                    setValue("rentalDetails.stringValue", str);
                    submitForm();
                }
            } else {
                setValue("rentalDetails.stringValue", str);
                submitForm();
            }
            return (<div>{str}</div>)
        }
    }


    render() {
        return (
            <ContextPage fetching={this.props.fetching} fetched={this.props.fetched} title={this.props.equipment.equipmentSelection}>
                {Object.keys(this.props.equipment).length === 0 ? (
                    <div className="empty-view">
                        <img alt={"empty icon"} className="empty-icon" src={require("../../../../images/empty-placeholder.png")} />
                        <h4>No item selected </h4>
                    </div>
                ) : (
                    <Formik
                        enableReinitialize={true}
                        initialValues={this.props.equipment}
                        onSubmit={(values) => {
                            if (JSON.stringify(values) !== JSON.stringify(this.valuesAtLastSave)) {
                                this.saveEquipment(values);
                            }
                        }}
                        render={({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldTouched, setFieldValue }) => (
                            <Fragment>
                                <Card title={"Equipment Details"}>
                                    <CardRow label={"Equipment Type"} top={true}>
                                        <CCAInput
                                            disabled={true}
                                            type={CCAInputTypes.FREE_TEXT}
                                            name="equipmentType"
                                            value={values.equipmentType}
                                            onChange={handleChange}
                                            onSave={(e) => {
                                                handleSubmit(e);
                                            }}
                                        />
                                    </CardRow>

                                    <CardRow label={"Capital Value"}>
                                        <CCAInput
                                            type={CCAInputTypes.DOLLAR}
                                            name="capitalValue"
                                            value={values.capitalValue}
                                            onChange={(value) => {
                                                setFieldValue("capitalValue", value);
                                            }}
                                            onSave={handleSubmit}
                                        />
                                    </CardRow>

                                    <CardRow label={"Quantity"}>
                                        <CCAInput
                                            type={CCAInputTypes.NUMBER}
                                            name="quantity"
                                            value={values.quantity}
                                            onChange={(value) => {
                                                setFieldValue("quantity", value);
                                            }}
                                            onSave={handleSubmit}
                                        />
                                    </CardRow>
                                    {values.equipmentType === "Coffee Equipment" &&
                                        <CardRow label={"Is Rental?"}>
                                            <CCAInput
                                                type={CCAInputTypes.CHECKBOX}
                                                name="Rental"
                                                value={values.rental}
                                                onChange={(value) => {
                                                    setFieldValue("rental", !values.rental);
                                                }}
                                                onSave={handleSubmit}
                                            />
                                        </CardRow>
                                    }
                                    {values.rental &&
                                        <CardRow label={"Rental Type"}>
                                            <Select
                                                defaultValue={this.getRentalOption(values.rentalType)}
                                                options={this.rentalOptions}
                                                name="rentalType"
                                                onChange={(event) => {
                                                    setFieldValue("rentalType", event.value);
                                                    handleSubmit();
                                                }}>
                                            </Select>
                                        </CardRow>
                                    }
                                    {
                                        values.rentalType &&
                                        <CardRow label={"Rental Details"}>
                                            {values.rentalType == "free" &&
                                                <div>$0.00 monthly, if customer purchases less than
                                                    <CCAInput
                                                        type={CCAInputTypes.NUMBER}
                                                        name="rentalDetails.quantity"
                                                        value={values.rentalDetails ? values.rentalDetails.quantity : null}
                                                        onChange={(value) => {
                                                            if (!values.rentalDetails) values.rentalDetails = {};
                                                            setFieldValue("rentalDetails.quantity", value);
                                                            setFieldValue("rentalDetails.stringValue", `$0.00 monthly, if customer purchases less than ${value}kg per week, a monthly rental fee of $${values.rentalDetails.fee} will be applied`);
                                                        }}
                                                        onSave={handleSubmit}
                                                    />
                                                    kg per week, a monthly rental fee of $
                                                    <CCAInput
                                                        type={CCAInputTypes.DOLLAR}
                                                        name="rentalDetails.fee"
                                                        value={values.rentalDetails ? values.rentalDetails.fee : null}
                                                        onChange={(value) => {
                                                            if (!values.rentalDetails) values.rentalDetails = {};
                                                            setFieldValue("rentalDetails.fee", value);
                                                            setFieldValue("rentalDetails.stringValue", `$0.00 monthly, if customer purchases less than ${values.rentalDetails.quantity}kg per week, a monthly rental fee of $${value} will be applied`);
                                                        }}
                                                        onSave={handleSubmit}
                                                    /> will be applied
                                                </div>
                                            }
                                            {this.getStringValueForRentalType(values.rentalType, values, setFieldValue, handleSubmit)}
                                        </CardRow>
                                    }
                                    <CardRow bottom longform label={"Description"}>
                                        <CCAInput
                                            type={CCAInputTypes.DESCRIPTION}
                                            name="description"
                                            value={values.description}
                                            onChange={handleChange}
                                            onSave={(e) => {
                                                handleSubmit(e);
                                            }}
                                        />
                                    </CardRow>
                                </Card>

                                <Card
                                    title={"Compliance"}
                                    action={
                                        <CardActions
                                            handleClick={(e) => {
                                                if (this.state.editing[2]) {
                                                    this.handleSubmit();
                                                }

                                                this.toggleEditing(2);
                                            }}
                                            editing={this.state.editing[2]}
                                            loading={this.state.conditionSaveLoading}
                                        />
                                    }
                                >
                                    <Conditionaliser customConditions={this.props.contract.customConditions} b2b={this.state.b2b} allowResults={false} saveCustomConditions={this.handleSaveCustomConditions} value={this.props.equipment.conditions} ref={(ref) => (this.conditionaliser = ref)} setSaveButtonState={() => { }} onChange={this.handleChange} editing={this.state.editing[2]} />
                                </Card>
                            </Fragment>
                        )}
                    />
                )}
            </ContextPage>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { equipment, fetching, fetched, failed } = state.equipmentReducer;
    const { scenarioDetails } = state.contractDetailsReducer;
    const { contract } = state.contractReducer;
    return { equipment, fetching, fetched, failed, contract, scenarioDetails };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getEquipment,
            saveEquipment,
            getNonFinancialSummary,
            saveContractCustomConditions,
            saveEquipmentConditions,
            getScenarioDetails,
            showError,
            hideError,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(EquipmentDetailsPage));

// EXPORT COMPONENT

export { hoc as EquipmentDetailsPage };
