// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
var numeral = require("numeral");

// IMPORT ACTIONS
import { getCustomerTargets } from "../../state/actions/CustomerTargetActions";

// IMPORT UI
import { Loading } from "../../containers/loading/Loading";

// COMPONENT
class SummaryPin extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getCustomerTargets(this.props.contractId, this.props.scenarioId);
    }

    render() {
        return (
            <Fragment>
                <div className="menu-subtitle"> Customer Targets </div>
                {false ? (
                    <Loading light />
                ) : (
                        <div className="sidebar-summary-section">
                            <div className="sidebar-tile neutral">
                                <div>
                                    Target Profit
                                <div className="sidebar-tile-subtitle"> {"$" + numeral(this.props.contractDetails.customer.targetProfit).format("0,0.00")} </div>
                                </div>

                                <div>
                                    Achieved Profit
                                <div className="sidebar-tile-subtitle"> {"$" + numeral(this.props.currentCustomerProfit).format("0,0.00")} </div>
                                </div>
                            </div>

                            <div className="sidebar-tile neutral">
                                <div>
                                    Target Margin
                                <div className="sidebar-tile-subtitle"> {numeral(this.props.contractDetails.customer.targetMargin).format("0,0.00") + "%"} </div>
                                </div>

                                <div>
                                    Achieved Margin
                                <div className="sidebar-tile-subtitle"> {numeral(this.props.currentCustomerMargin).format("0,0.00") + "%"} </div>
                                </div>
                            </div>
                        </div>
                    )}
            </Fragment>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { contractDetails } = state.contractDetailsReducer;
    const { currentCustomerProfit, currentCustomerMargin } = state.customerTargetReducer;

    return { contractDetails, currentCustomerProfit, currentCustomerMargin };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getCustomerTargets,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(SummaryPin));

// EXPORT COMPONENT
export { hoc as CustomerTargetPin };
