// IMPORT PACKAGE REFERENCES
import React, { PureComponent } from "react";
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, ReferenceLine, ReferenceDot, Tooltip, CartesianGrid, Legend, Brush, ErrorBar, AreaChart, Area, Label, LabelList } from "recharts";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ContextPage } from "../../../containers/layout/ContextPage";
import { Card } from "../../../containers/card/Card";
import { SummaryDataTable } from "../products/productGroup/SummaryDataTable";
import moment from "moment";
import { pinToSidebar, unpinFromSidebar, addDataPointToGraph, removeDataPointFromGraph } from "../../../state/actions/UserActions";
import { CardContainer } from "../../../containers/card/CardContainer";
import { ApprovalDataTable } from "../products/productGroup/ApprovalDataTable";

export const FormContext = React.createContext();

// COMPONENT
class ConsolidatedCashFlowPage extends React.Component {
    constructor(props) {
        super(props);

        this.annualInvestmentTable = null;

        this.state = {
            collapsed: true,
            graphData: [],
            dataPointsToGraph: props.dataPointsToGraph,
            scenarioDetails: props.scenarioDetails,
        };

        this.toggleDataPoint = this.toggleDataPoint.bind(this);
    }

    componentDidUpdate() {
        if (JSON.stringify(this.props.scenarioDetails) !== JSON.stringify(this.state.scenarioDetails)) {
            this.setState({ scenarioDetails: this.props.scenarioDetails });
        }
    }

    generateColumns() {
        let contractDuration = 1;

        if (this.state.scenarioDetails.contractDuration) {
            contractDuration = this.state.scenarioDetails.contractDuration;
        }

        let columns = [{ name: "Measure", scenarioId: this.props.match.params.scenarioId, align: "left", dataIdentifier: "Measure", width: 1, disabled: true }];

        let count = -1;
        while (count <= contractDuration - 1) {
            let columnName = "Escalators";
            let identifier = `Year ${count}`;

            if (count !== -1) {
                columnName = `Year ${count + 1}`;
            }

            columns.push({ identifier: identifier, scenarioId: this.props.match.params.scenarioId, name: columnName, index: count, align: "right", dataIdentifier: "Measure", width: 1, disabled: true, calculated: true, incremental: true });
            count += 1;
        }

        return columns;
    }

    toggleDataPoint(dataPoint, column) {
        console.log("Data point is...", dataPoint, column);

        if (this.state.dataPointsToGraph.hasOwnProperty(dataPoint)) {
            this.props.removeDataPointFromGraph(dataPoint);
            return;
        }

        this.props.addDataPointToGraph(dataPoint, column.format);
    }

    /**
     * Handle the pin of the page to the sidebar.
     * @param {string} pageIdentifier - the identifier for the pin.
     */
    handlePin(pageIdentifier) {
        if (this.props.pinned.hasOwnProperty(pageIdentifier)) {
            this.props.unpinFromSidebar(pageIdentifier);
        } else {
            this.props.pinToSidebar(pageIdentifier);
        }
    }

    componentDidUpdate() {
        if (JSON.stringify(this.props.dataPointsToGraph) !== JSON.stringify(this.state.dataPointsToGraph)) {
            this.setState({ dataPointsToGraph: this.props.dataPointsToGraph });
        }
    }

    render() {
        let columns = this.generateColumns();

        return (
            <ContextPage fetching={false} fetched={true} pin={true} pinned={this.props.pinned.hasOwnProperty("summary")} handlePin={() => this.handlePin("summary")} title={"Cash Flow"}>
                <Card noTitle>
                    <SummaryDataTable
                        onRef={(ref) => (this.annualInvestmentTable = ref)}
                        hideTitleBorder
                        handleSave={this.handleDataTableSave}
                        columns={columns}
                        graphedDataPoints={this.state.dataPointsToGraph}
                        toggleDataPoint={this.toggleDataPoint}
                        toggleCollapse={() => {
                            this.setState({ collapsed: !this.state.collapsed });
                        }}
                        sections={[
                            {
                                name: "Profit & Loss",
                                collapsed: this.state.collapsed,
                                graphable: true,
                                rows: [
                                    { Measure: "Physical Cases", format: { type: "number", decimalPlaces: 0 } },
                                    { Measure: "Unit Cases", format: { type: "number", decimalPlaces: 0 } },
                                    { Measure: "Selling Litres", format: { type: "number", decimalPlaces: 0 } },
                                    { Measure: "Sale Price per PC", format: { type: "dollar", decimalPlaces: 2 } },
                                    { Measure: "Gross Sales Revenue", format: { type: "dollar", decimalPlaces: 0 } },
                                    { Measure: "Total Discount on Invoice", format: { type: "dollar", decimalPlaces: 0 } },
                                    { Measure: "Rebates", format: { type: "dollar", decimalPlaces: 0 } },
                                    { Measure: "PA", format: { type: "dollar", decimalPlaces: 0 } },
                                    { Measure: "Discounts and Allowances", format: { type: "dollar", decimalPlaces: 0 } },
                                    { Measure: "Annual Payments", format: { type: "dollar", decimalPlaces: 0 } },
                                    { Measure: "Upfront Payments", format: { type: "dollar", decimalPlaces: 0 } },
                                    { Measure: "Net Sales Revenue", rowType: "total", format: { type: "dollar", decimalPlaces: 0 } },

                                    { Measure: "NSR per PC", hidden: true, format: { type: "dollar", decimalPlaces: 2 } },
                                    { Measure: "NSR per UC", hidden: true, rowType: "space", format: { type: "dollar", decimalPlaces: 2 } },

                                    { Measure: "Cost of Sales per PC", format: { type: "dollar", decimalPlaces: 2 } },
                                    { Measure: "Cost of Sales", format: { type: "dollar", decimalPlaces: 2 } },
                                    { Measure: "Delivery Cost", format: { type: "dollar", decimalPlaces: 2 } },
                                    { Measure: "Net Contribution", rowType: "total", format: { type: "dollar", decimalPlaces: 0 } },

                                    { Measure: "NC per PC", format: { type: "dollar", decimalPlaces: 2 } },
                                    { Measure: "NC per UC", format: { type: "dollar", decimalPlaces: 2 } },
                                    { Measure: "Depreciation", format: { type: "dollar", decimalPlaces: 2 } },
                                    { Measure: "Indirect Costs", format: { type: "dollar", decimalPlaces: 2 } },
                                    { Measure: "Total Indirect Costs", rowType: "total", format: { type: "dollar", decimalPlaces: 2 } },

                                    { Measure: "Rent", rowType: "total", format: { type: "dollar", decimalPlaces: 2 } },

                                    { Measure: "EBIT", format: { type: "dollar", decimalPlaces: 0 } },
                                    { Measure: "EBIT Margin", format: { type: "percentage", decimalPlaces: 0 } },
                                    { Measure: "Tax Expense", format: { type: "dollar", decimalPlaces: 0 } },
                                    { Measure: "Net Profit After Tax", rowType: "total", format: { type: "dollar", decimalPlaces: 0 } },
                                ],
                            },
                        ]}
                    />
                </Card>

                <Card noTitle>
                    <SummaryDataTable
                        onRef={(ref) => (this.annualInvestmentTable = ref)}
                        hideTitleBorder
                        handleSave={this.handleDataTableSave}
                        columns={columns}
                        graphedDataPoints={this.state.dataPointsToGraph}
                        toggleDataPoint={this.toggleDataPoint}
                        sections={[
                            {
                                name: "Cash Flow",
                                graphable: true,
                                rows: [
                                    { Measure: "EBIT", format: { type: "dollar", decimalPlaces: 0 } },
                                    { Measure: "Depreciation", format: { type: "dollar", decimalPlaces: 2 } },
                                    { Measure: "Amortisation", format: { type: "dollar", decimalPlaces: 0 } },
                                    { Measure: "Net Cashflow from Operating Activities", format: { type: "dollar", decimalPlaces: 0 } },
                                    { Measure: "Capital Expenditure", format: { type: "dollar", decimalPlaces: 0 } },
                                    { Measure: "Upfront Payments CF", format: { type: "dollar", decimalPlaces: 0 } },
                                    { Measure: "Upfront Novelties CF", format: { type: "dollar", decimalPlaces: 0 } },
                                    { Measure: "Net Cashflow from Investing Activities", format: { type: "dollar", decimalPlaces: 0 } },
                                    { Measure: "Net Cashflow", rowType: "total", format: { type: "dollar", decimalPlaces: 0 } },
                                    // { Measure: "Cumulative Cashflow", format: {type: 'dollar', decimalPlaces: 0} },
                                ],
                            },
                        ]}
                    />
                </Card>

                <Card noTitle>
                    <SummaryDataTable
                        collatedContractDataPoints
                        onRef={(ref) => (this.annualInvestmentTable = ref)}
                        hideTitleBorder
                        graphedDataPoints={this.state.dataPointsToGraph}
                        toggleDataPoint={this.toggleDataPoint}
                        handleSave={this.handleDataTableSave}
                        columns={[
                            { name: "Measure", scenarioId: this.props.match.params.scenarioId, align: "left", dataIdentifier: "Measure", width: 1, disabled: true },
                            { identifier: "Year -1", scenarioId: this.props.match.params.scenarioId, name: "Contract", index: 0, align: "right", dataIdentifier: "Measure", width: 1, disabled: true, calculated: true },
                        ]}
                        sections={[
                            {
                                name: "Return on Investment",
                                graphable: true,
                                rows: [
                                    { Measure: "Residual EQ Value", format: { type: "dollar", decimalPlaces: 2, total: true } },
                                    { Measure: "Hurdle Rate", format: { type: "percentage", decimalPlaces: 0, total: true } },
                                    { Measure: "Net Present Value", format: { type: "dollar", decimalPlaces: 2, total: true } },
                                    { Measure: "Internal Rate of Return", format: { type: "percentage", decimalPlaces: 0, total: true } },
                                    { Measure: "Discount percent GSR", format: { type: "percentage", decimalPlaces: 0, total: true } },
                                ],
                            },
                        ]}
                    />

                    <ApprovalDataTable />
                </Card>
            </ContextPage>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { contract } = state.contractReducer;
    const { scenarioDetails } = state.contractDetailsReducer;
    const { contractDataPoints } = state.summaryReducer;
    const { pinned, dataPointsToGraph } = state.userReducer;
    return { contract, pinned, contractDataPoints, scenarioDetails, dataPointsToGraph };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            pinToSidebar,
            unpinFromSidebar,
            addDataPointToGraph,
            removeDataPointFromGraph,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConsolidatedCashFlowPage));

// EXPORT COMPONENT
export { hoc as ConsolidatedCashFlowPage };
