// IMPORT PACKAGE REFERENCES
import React from "react";

// COMPONENT
export class WorkingIndicator extends React.Component {
    render() {
        return (
            <div>
                <div className="working-indicator dot1"></div>
                <div className="working-indicator dot2" style={{ margin: "0px 2px" }}></div>
                <div className="working-indicator dot3"></div>
            </div>
        );
    }
}
