import {
    GET_ROLLING_SALES_META_DATA_PENDING,
    GET_ROLLING_SALES_META_DATA_FULFILLED,
    GET_ROLLING_SALES_META_DATA_REJECTED,
    GET_FLOOR_PRICE_MATRIX_META_DATA_PENDING,
    GET_FLOOR_PRICE_MATRIX_META_DATA_FULFILLED,
    GET_FLOOR_PRICE_MATRIX_META_DATA_REJECTED,
    SAVE_GLOBAL_DETAILS_PENDING,
    SAVE_GLOBAL_DETAILS_REJECTED,
    SAVE_GLOBAL_DETAILS_FULFILLED,
    GET_GLOBAL_DETAILS_PENDING,
    GET_GLOBAL_DETAILS_FULFILLED,
    GET_GLOBAL_DETAILS_REJECTED,
    GET_SEGMENT_PENDING,
    GET_SEGMENT_FULFILLED,
    GET_SEGMENT_FAILED
} from "../actions/ConfigurationActions";

import { CLEAR_REDUCERS } from "../actions/GeneralActions";

// INITIALIZE STATE
const initialState = {
    fetching: false,
    fetched: false,
    failed: false,
    globalDetails: {},
    rollingSalesLastUpdated: undefined,
    floorPriceMatrixLastUpdated: undefined,
    segmentDiscountLastUpdated: undefined,
};

// REDUCER
export const ConfigurationReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_REDUCERS:
            return initialState;
        case GET_ROLLING_SALES_META_DATA_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_ROLLING_SALES_META_DATA_FULFILLED:
            return {
                ...state,
                rollingSalesLastUpdated: action.payload,
                fetching: false,
                fetched: true,
                failed: false,
            };
        case GET_ROLLING_SALES_META_DATA_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        case GET_SEGMENT_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_SEGMENT_FULFILLED:
            return {
                ...state,
                segmentDiscountLastUpdated: action.payload,
                fetching: false,
                fetched: true,
                failed: false,
            };
        case GET_SEGMENT_FAILED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        case GET_FLOOR_PRICE_MATRIX_META_DATA_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_FLOOR_PRICE_MATRIX_META_DATA_FULFILLED:
            return {
                ...state,
                floorPriceMatrixLastUpdated: action.payload,
                fetching: false,
                fetched: true,
                failed: false,
            };
        case GET_FLOOR_PRICE_MATRIX_META_DATA_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        case GET_GLOBAL_DETAILS_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_GLOBAL_DETAILS_FULFILLED:
            return {
                ...state,
                globalDetails: action.payload,
                fetching: false,
                fetched: true,
                failed: false,
            };
        case GET_GLOBAL_DETAILS_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        case SAVE_GLOBAL_DETAILS_PENDING:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        case SAVE_GLOBAL_DETAILS_FULFILLED:
            return {
                ...state,
                globalDetails: { ...state.globalDetails, ...action.payload },
                fetching: false,
                fetched: false,
                failed: true,
            };
        case SAVE_GLOBAL_DETAILS_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        default:
            return state;
    }
};
