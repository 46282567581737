import axios from "axios";
import { API_ROOT } from "../../../config/config";

export const GET_TEMPLATES_PENDING = "GET_TEMPLATES_PENDING";
export const GET_TEMPLATES_FULFILLED = "GET_TEMPLATES_FULFILLED";
export const GET_TEMPLATES_REJECTED = "GET_TEMPLATES_REJECTED";

export const PUT_TEMPLATE_PENDING = "PUT_TEMPLATE_PENDING";
export const PUT_TEMPLATE_FULFILLED = "PUT_TEMPLATE_FULFILLED";
export const PUT_TEMPLATE_FAILED = "PUT_TEMPLATE_FAILED";

export const DELETE_TEMPLATE_PENDING = "DELETE_TEMPLATE_PENDING";
export const DELETE_TEMPLATE_FULFILLED = "DELETE_TEMPLATE_FULFILLED";
export const DELETE_TEMPLATE_FAILED = "DELETE_TEMPLATE_FAILED";

const getTemplates = () => (dispatch) => {
    dispatch({ type: GET_TEMPLATES_PENDING });

    axios
        .get(`${API_ROOT}/templates`)
        .then((response) => {
            dispatch({ type: GET_TEMPLATES_FULFILLED, payload: response.data });
        })
        .catch((error) => {
            console.log(error);
            dispatch({ type: GET_TEMPLATES_REJECTED });
        });
};

const newTemplate = (contractId, values, callback) => (dispatch) => {
    let name = values.name;
    dispatch({ type: PUT_TEMPLATE_PENDING });

    axios
        .put(`${API_ROOT}/templates?contractId=${contractId}&name=${name}`)
        .then((response) => {
            dispatch({ type: PUT_TEMPLATE_FULFILLED });
            callback(true);
        })
        .catch((error) => {
            console.log(error);
            dispatch({ type: PUT_TEMPLATE_FAILED });
            callback(false);
        });
};

const deleteTemplates = (templateIdsToDelete, callback) => (dispatch) => {
    dispatch({ type: DELETE_TEMPLATE_PENDING });

    axios
        .delete(API_ROOT + "/templates", { data: templateIdsToDelete })
        .then((res) => {
            dispatch({
                type: DELETE_TEMPLATE_FULFILLED,
                payload: templateIdsToDelete,
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred deleting templates", error);
            dispatch({
                type: DELETE_TEMPLATE_FAILED,
            });

            callback(false);
        });
};

export { getTemplates, newTemplate, deleteTemplates };
