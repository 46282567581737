// IMPORT PACKAGE REFERENCES
import React from "react";
import moment from "moment";

// COMPONENT
export class ContractCell extends React.Component {
    render() {
        let status = this.props.data[this.props.identifiers.accountStatus];
        let statusColor = "default";

        if (status === "Current - New contract") {
            statusColor = "current-new";
        } else if (status === "Current - contract Resign") {
            statusColor = "current-resign";
        } else if (status === "New Business - New") {
            statusColor = "new-business";
        } else if (status === "New Business - Competitor") {
            statusColor = "competitor-business";
        }

        return (
            <div className={"normal-row" + (this.props.isLastCell ? " last" : "")}>
                <div className="normal-label">{this.props.data[this.props.identifiers.label]}</div>
                <div className="normal-subtitle">
                    Created by {this.props.data[this.props.identifiers.subtitle]} &middot; Created {moment(this.props.data[this.props.identifiers.createdAt]).fromNow()}
                </div>
                {this.props.data[this.props.identifiers.accountStatus] !== "" && this.props.data[this.props.identifiers.accountStatus] && <div className={"status-tag " + statusColor}>{this.props.data[this.props.identifiers.accountStatus]}</div>}
            </div>
        );
    }
}
