// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { getApproval } from "../../../../state/actions/SummaryActions";
import { Loading } from "../../../../containers/loading/Loading";

// COMPONENT
class ApprovalDataTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        this.props.getApproval(this.props.match.params.contractId, this.props.match.params.scenarioId);
    }

    render() {
        return (
            <Fragment>
                {this.props.fetchingApproval && (
                    <div className="data-table-loader">
                        <Loading small /> <div className="data-table-loader-text"> Loading Approval </div>{" "}
                    </div>
                )}
                {this.props.fetchedApproval && (
                    <Fragment>
                        <div className={"data-table-row-container text"}>
                            <div className="table-row even">
                                <div className="table-row-item left"> Approver </div>
                                <div className="table-row-item right"> {this.props.approval.approver} </div>
                            </div>
                        </div>

                        <div className={"data-table-row-container text"}>
                            <div className="table-row odd">
                                <div className="table-row-item left"> Approval Reason </div>
                                <div className="table-row-item right"> {this.props.approval.reason} </div>
                            </div>
                        </div>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { approval, fetchingApproval, fetchedApproval, fetchApprovalFailed } = state.summaryReducer;
    return { approval, fetchingApproval, fetchedApproval, fetchApprovalFailed };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getApproval,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(ApprovalDataTable));

// EXPORT COMPONENT
export { hoc as ApprovalDataTable };
