// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { GeneralDetailsPage } from "./GeneralDetailsPage";
import { CustomerDetailsPage } from "./CustomerDetailsPage";
import { KeyBusinessDriversPage } from "./KeyBusinessDriversPage";
import { ExclusivityPage } from "./ExclusivityPage";
import { GoalsAndNeedsPage } from "./GoalsAndNeedsPage";
import { EscalatorAssumptionsPage } from "./EscalatorAssumptionsPage";
import { OtherAssumptionsPage } from "./OtherAssumptionsPage";
import { pinToSidebar, unpinFromSidebar } from "../../../state/actions/UserActions";

import { PageTitle } from "../../../containers/layout/PageTitle";
import { SimpleList } from "../lists/SimpleList";
import { ContextualSidebar } from "../../../containers/layout/ContextualSidebar";
import { CELL_TYPES } from "../CellTypes";
import { MasterDetailRouter } from "../../../containers/layout/MasterDetailRouter";
import { getContract } from "../../../state/actions/ContractActions";
import { ContractCompliancePage } from "./ContractCompliancePage";
import { GeneralScenarioDetailsPage } from "./GeneralScenarioDetailsPage";

// Component to add Financial Incentives to a contract.
// Financial Incentives are either upfront or annual payments, or rebates.
class ContractDetailsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            messageActive: false,
            message: "",
        };

        this.handleClick = this.handleClick.bind(this);
        this.handlePin = this.handlePin.bind(this);
        this.handleCollapse = this.handleCollapse.bind(this);
    }

    componentDidMount() {
        this.props.getContract(this.props.match.params.contractId, (success) => { });
    }

    handleClick(section) {
        this.props.history.push(`?section=${section}`);
    }

    handlePin(pageIdentifier) {
        if (this.props.pinned.hasOwnProperty(pageIdentifier)) {
            this.props.unpinFromSidebar(pageIdentifier);
        } else {
            this.props.pinToSidebar(pageIdentifier);
        }
    }

    handleCollapse(section) {
        let sections = Object.assign(this.state.collapsedSections);
        sections[section] = !sections[section];

        this.setState({
            collapsedSections: sections,
        });
    }

    render() {
        let sections = [
            {
                sectionHeader: "Agreement Details",
                page: <GeneralDetailsPage title="General Details" />,
                sectionIdentifier: "generalDetails",
                cell: {
                    type: CELL_TYPES.NORMAL,
                    identifiers: { label: "General Details" },
                },
            },
            {
                page: <CustomerDetailsPage title="Customer Details" />,
                sectionIdentifier: "customerDetails",
                cell: {
                    type: CELL_TYPES.NORMAL,
                    identifiers: { label: "Customer Details" },
                },
            },
            {
                page: <GoalsAndNeedsPage pinned={this.props.pinned.hasOwnProperty("goalsAndNeeds")} handlePin={this.handlePin} title="Goals and Needs" />,
                sectionIdentifier: "goalsAndNeeds",
                cell: {
                    type: CELL_TYPES.NORMAL,
                    identifiers: { label: "Goals and Needs" },
                },
            },
            {
                sectionHeader: "Scenario Details",
                page: <ExclusivityPage pinned={this.props.pinned.hasOwnProperty("exclusivity")} handlePin={this.handlePin} title="Exclusivity" />,
                sectionIdentifier: "exclusivity",
                cell: {
                    type: CELL_TYPES.NORMAL,
                    identifiers: { label: "Exclusivity" },
                },
            },
            {
                page: <KeyBusinessDriversPage pinned={this.props.pinned.hasOwnProperty("keyBusinessDrivers")} handlePin={this.handlePin} title="Key Business Drivers" />,
                sectionIdentifier: "keyBusinessDrivers",
                cell: {
                    type: CELL_TYPES.NORMAL,
                    identifiers: { label: "Key Business Drivers" },
                },
            },
            {
                page: <GeneralScenarioDetailsPage title="General Scenario Details" />,
                sectionIdentifier: "generalScenarioDetails",
                cell: {
                    type: CELL_TYPES.NORMAL,
                    identifiers: { label: "General Scenario Details" },
                },
            },
            {
                sectionHeader: "Assumptions",
                restricted: "admin",
                page: <EscalatorAssumptionsPage locked={this.props.user.role !== "admin"} title="Escalators" />,
                sectionIdentifier: "escalatorAssumptions",
                cell: {
                    type: CELL_TYPES.NORMAL,
                    identifiers: { label: "Escalators" },
                },
            },
            {
                page: <OtherAssumptionsPage locked={this.props.user.role !== "admin"} title="Other" />,
                sectionIdentifier: "otherAssumptions",
                cell: {
                    type: CELL_TYPES.NORMAL,
                    identifiers: { label: "Other" },
                },
            },
        ];

        return (
            <Fragment>
                <Fragment>
                    <ContextualSidebar pageTitle={<PageTitle title={"Details"} />} left={<SimpleList handleClick={this.handleClick} sections={sections} />} right={<MasterDetailRouter sections={sections} />} />
                </Fragment>
            </Fragment>
        );
    }
}

ContractDetailsPage.propTypes = {};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { pinned, user } = state.userReducer;
    return { pinned, user };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getContract, pinToSidebar, unpinFromSidebar }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractDetailsPage));

// EXPORT COMPONENT

export { hoc as ContractDetailsPage };
