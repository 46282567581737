import {
    GET_PRODUCT_RANGE_SUMMARY_PENDING,
    GET_PRODUCT_RANGE_SUMMARY_FULFILLED,
    GET_PRODUCT_RANGE_SUMMARY_REJECTED,
    GET_MATERIAL_GROUP_DATA_POINT_SUMMARY_PENDING,
    GET_MATERIAL_GROUP_DATA_POINT_SUMMARY_REJECTED,
    GET_MATERIAL_GROUP_DATA_POINT_SUMMARY_FULFILLED,
    GET_CONTRACT_DATA_POINT_SUMMARY_PENDING,
    GET_CONTRACT_DATA_POINT_SUMMARY_FULFILLED,
    GET_CONTRACT_DATA_POINT_SUMMARY_REJECTED,
    GET_CONTRACT_SUMMARY_SNAPSHOT_PENDING,
    GET_CONTRACT_SUMMARY_SNAPSHOT_FULFILLED,
    GET_CONTRACT_SUMMARY_SNAPSHOT_REJECTED,
    GET_APPROVAL_PENDING,
    GET_APPROVAL_FULFILLED,
    GET_APPROVAL_FAILED,
    GET_SCENARIO_PROFIT_STORY_SUMMARY_PENDING,
    GET_SCENARIO_PROFIT_STORY_SUMMARY_FULFILLED,
    GET_SCENARIO_PROFIT_STORY_SUMMARY_FAILED,
    GET_ANNUAL_INVESTMENT_SUMMARY_PENDING,
    GET_ANNUAL_INVESTMENT_SUMMARY_FULFILLED,
    GET_ANNUAL_INVESTMENT_SUMMARY_FAILED,
    GET_ANNUAL_INVESTMENT_SUMMARY_COMPARISON_PENDING,
    GET_ANNUAL_INVESTMENT_SUMMARY_COMPARISON_FULFILLED,
    GET_ANNUAL_INVESTMENT_SUMMARY_COMPARISON_FAILED,
    GET_SCENARIO_MATERIAL_DATA_POINTS_PENDING,
    GET_SCENARIO_MATERIAL_DATA_POINTS_FULFILLED,
    GET_SCENARIO_MATERIAL_DATA_POINTS_FAILED,
    GET_MATERIAL_SUMMARY_PENDING,
    GET_MATERIAL_SUMMARY_FULFILLED,
    GET_MATERIAL_SUMMARY_FAILED,
    CLEAR_CONTRACT_SUMMARY_SNAPSHOT,
    ADDITIONAL_COMMENTS_PENDING,
    ADDITIONAL_COMMENTS_FULFILLED,
    ADDITIONAL_COMMENTS_REJECTED,
    GET_ANNUAL_INVESTMENT_SUMMARIES_COMPARISON_FAILED,
    GET_ANNUAL_INVESTMENT_SUMMARIES_COMPARISON_PENDING,
    GET_ANNUAL_INVESTMENT_SUMMARIES_COMPARISON_FULFILLED,
} from "../actions/SummaryActions";

import { CLEAR_REDUCERS } from "../actions/GeneralActions";

import moment from "moment";
import "moment/locale/en-nz";

// INITIALIZE STATE
const initialState = {
    summary: {},
    fetchingSummary: false,
    fetchedSummary: false,
    fetchSummaryFailed: false,
    dataPoints: {},
    contractDataPoints: {},
    currentContractSummarySnapshot: {},
    previousContractSummarySnapshot: {},
    fetchingContractSummarySnapshot: false,
    fetchedContractSummarySnapshot: false,
    failedContractSummarySnapshotFetch: false,
    additionalComments: "",

    fetchingDataPoints: false,
    fetchedDataPoints: false,
    failedDataPoints: false,

    approval: {},
    fetchingApproval: false,
    fetchedApproval: false,
    fetchApprovalFailed: false,

    scenarioProfitStorySummary: {},

    annualInvestmentSummaryTimestamp: undefined,

    annualInvestmentSummary: {},
    fetchingAnnualInvestmentSummary: false,
    fetchedAnnualInvestmentSummary: false,
    failedAnnualInvestmentSummary: false,

    annualInvestmentSummaryComparison: {},
    annualInvestmentSummariesComparison: [],
    fetchingAnnualInvestmentSummaryComparison: false,
    fetchedAnnualInvestmentSummaryComparison: false,
    failedAnnualInvestmentSummaryComparison: false,

    materialSummary: {},
    fetchingMaterialSummary: false,
    fetchedMaterialSummary: false,
    failedMaterialSummary: false,
};

// REDUCER
export const SummaryReducer = (state = initialState, action) => {
    let dataPoints = Object.assign({}, state.dataPoints);
    let contractDataPoints = Object.assign({}, state.contractDataPoints);
    let scenarioProfitStorySummary = Object.assign({}, state.scenarioProfitStorySummary);

    switch (action.type) {
        case CLEAR_REDUCERS:
            return initialState;
        case GET_PRODUCT_RANGE_SUMMARY_PENDING:
            return {
                ...state,
                fetchingSummary: true,
                fetchedSummary: false,
                fetchSummaryFailed: false,
            };
        case CLEAR_CONTRACT_SUMMARY_SNAPSHOT:
            return {
                ...state,
                currentContractSummarySnapshot: {},
                previousContractSummarySnapshot: {},
            };
        case GET_PRODUCT_RANGE_SUMMARY_FULFILLED:
            return {
                ...state,
                summary: action.payload,
                fetchingSummary: false,
                fetchedSummary: true,
                fetchSummaryFailed: false,
            };
        case GET_PRODUCT_RANGE_SUMMARY_REJECTED:
            return {
                ...state,
                fetchingSummary: false,
                fetchedSummary: false,
                fetchSummaryFailed: true,
            };
        case GET_MATERIAL_GROUP_DATA_POINT_SUMMARY_PENDING:
            if (!dataPoints.hasOwnProperty(action.payload.materialGroupId)) {
                dataPoints[action.payload.materialGroupId] = {};
            }

            action.payload.dataPoints.forEach((newDataPoint) => {
                dataPoints[action.payload.materialGroupId][newDataPoint] = { data: {}, fetching: true, fetched: false, failed: false };
            });

            return {
                ...state,
                dataPoints,
            };
        case GET_MATERIAL_GROUP_DATA_POINT_SUMMARY_FULFILLED:
            if (!dataPoints.hasOwnProperty(action.payload.materialGroupId)) {
                dataPoints[action.payload.materialGroupId] = {};
            }
            action.payload.dataPoints.forEach((newDataPoint) => {
                dataPoints[action.payload.materialGroupId][newDataPoint] = { fetching: false, fetched: true, failed: false, data: action.payload.data[newDataPoint] };
            });

            return {
                ...state,
                dataPoints,
            };
        case GET_MATERIAL_GROUP_DATA_POINT_SUMMARY_REJECTED:
            if (!dataPoints.hasOwnProperty(action.payload.materialGroupId)) {
                dataPoints[action.payload.materialGroupId] = {};
            }

            action.payload.dataPoints.forEach((newDataPoint) => {
                dataPoints[action.payload.materialGroupId][newDataPoint] = { fetching: false, fetched: false, failed: true, data: {} };
            });

            return {
                ...state,
                dataPoints,
            };
        case GET_CONTRACT_DATA_POINT_SUMMARY_PENDING:
            action.payload.dataPoints.forEach((newDataPoint) => {
                if (!contractDataPoints.hasOwnProperty(newDataPoint)) {
                    contractDataPoints[newDataPoint] = {};
                }

                if (!contractDataPoints[newDataPoint].hasOwnProperty(action.payload.year)) {
                    contractDataPoints[newDataPoint][action.payload.year] = {};
                }

                contractDataPoints[newDataPoint][action.payload.year][action.payload.scenarioId] = { data: {}, fetching: true, fetched: false, failed: false };
            });

            return {
                ...state,
                contractDataPoints,
            };
        case GET_CONTRACT_DATA_POINT_SUMMARY_FULFILLED:
            action.payload.dataPoints.forEach((newDataPoint) => {
                if (!contractDataPoints.hasOwnProperty(newDataPoint)) {
                    contractDataPoints[newDataPoint] = {};
                }

                if (!contractDataPoints[newDataPoint].hasOwnProperty(action.payload.year)) {
                    contractDataPoints[newDataPoint][action.payload.year] = {};
                }

                contractDataPoints[newDataPoint][action.payload.year][action.payload.scenarioId] = { fetching: false, fetched: true, failed: false, data: action.payload.data[newDataPoint] };
            });

            console.log("Fetched data points", contractDataPoints);

            return {
                ...state,
                contractDataPoints,
            };
        // case GET_CONTRACT_DATA_POINT_SUMMARY_FULFILLED:
        //     action.payload.data.forEach((yearData, yearIndex) => {
        //         Object.keys(yearData).forEach(newDataPoint => {
        //             let yearIdentifier = `Year ${yearIndex - 1}`
        //             if (!contractDataPoints.hasOwnProperty(newDataPoint)) {
        //                 contractDataPoints[newDataPoint] = {};
        //             }

        //             if (!contractDataPoints[newDataPoint].hasOwnProperty(yearIdentifier)) {
        //                 contractDataPoints[newDataPoint][yearIdentifier] = {};
        //             }

        //             contractDataPoints[newDataPoint][yearIdentifier][action.payload.scenarioId] = { fetching: false, fetched: true, failed: false, data: yearData[newDataPoint] };
        //         })
        //     });

        //     console.log("Fetched data points:", contractDataPoints)

        //     return {
        //         ...state,
        //         contractDataPoints,
        //     };
        case GET_CONTRACT_DATA_POINT_SUMMARY_REJECTED:
            action.payload.dataPoints.forEach((newDataPoint) => {
                if (!contractDataPoints.hasOwnProperty(newDataPoint)) {
                    contractDataPoints[newDataPoint] = {};
                }

                if (!contractDataPoints[newDataPoint].hasOwnProperty(action.payload.year)) {
                    contractDataPoints[newDataPoint][action.payload.year] = {};
                }

                contractDataPoints[newDataPoint][action.payload.year][action.payload.scenarioId] = { fetching: false, fetched: false, failed: true };
            });

            return {
                ...state,
                contractDataPoints,
            };
        case GET_CONTRACT_SUMMARY_SNAPSHOT_PENDING:
            return {
                ...state,
                previousContractSummarySnapshot: state.currentContractSummarySnapshot,
                fetchingContractSummarySnapshot: true,
                fetchedContractSummarySnapshot: false,
                failedContractSummarySnapshotFetch: false,
            };
        case GET_CONTRACT_SUMMARY_SNAPSHOT_FULFILLED:
            return {
                ...state,
                currentContractSummarySnapshot: action.payload,
                fetchingContractSummarySnapshot: false,
                fetchedContractSummarySnapshot: true,
                failedContractSummarySnapshotFetch: false,
            };
        case GET_CONTRACT_SUMMARY_SNAPSHOT_REJECTED:
            return {
                ...state,
                fetchingContractSummarySnapshot: false,
                fetchedContractSummarySnapshot: false,
                failedContractSummarySnapshotFetch: true,
            };
        case GET_APPROVAL_PENDING:
            return {
                ...state,
                fetchingApproval: true,
                fetchedApproval: false,
                fetchApprovalFailed: false,
            };
        case GET_APPROVAL_FULFILLED:
            return {
                ...state,
                approval: action.payload,
                fetchingApproval: false,
                fetchedApproval: true,
                fetchApprovalFailed: false,
            };
        case GET_APPROVAL_FAILED:
            return {
                ...state,
                fetchingApproval: false,
                fetchedApproval: false,
                fetchApprovalFailed: true,
            };
        case GET_SCENARIO_PROFIT_STORY_SUMMARY_PENDING: {
            return {
                ...state,
                fetchingSummary: true,
                fetchedSummary: false,
                fetchSummaryFailed: false,
            };
        }
        case GET_SCENARIO_PROFIT_STORY_SUMMARY_FULFILLED: {
            scenarioProfitStorySummary[action.payload.scenarioId] = action.payload.data;

            return {
                ...state,
                scenarioProfitStorySummary,
                fetchingSummary: false,
                fetchedSummary: true,
                fetchSummaryFailed: false,
            };
        }
        case GET_SCENARIO_PROFIT_STORY_SUMMARY_FAILED: {
            return {
                ...state,
                fetchingSummary: false,
                fetchedSummary: false,
                fetchSummaryFailed: true,
            };
        }
        case GET_ANNUAL_INVESTMENT_SUMMARY_PENDING: {
            return {
                ...state,
                fetchingAnnualInvestmentSummary: true,
                fetchedAnnualInvestmentSummary: false,
                failedAnnualInvestmentSummary: false,
            };
        }
        case GET_ANNUAL_INVESTMENT_SUMMARY_FULFILLED: {
            return {
                ...state,
                annualInvestmentSummary: action.payload.data,
                annualInvestmentSummaryTimestamp: moment().format(),
                fetchingAnnualInvestmentSummary: false,
                fetchedAnnualInvestmentSummary: true,
                failedAnnualInvestmentSummary: false,
            };
        }
        case GET_ANNUAL_INVESTMENT_SUMMARY_FAILED: {
            return {
                ...state,
                fetchingAnnualInvestmentSummary: false,
                fetchedAnnualInvestmentSummary: false,
                failedAnnualInvestmentSummary: true,
            };
        }
        case GET_ANNUAL_INVESTMENT_SUMMARY_COMPARISON_PENDING: {
            return {
                ...state,
                fetchingAnnualInvestmentSummaryComparison: true,
                fetchedAnnualInvestmentSummaryComparison: false,
                failedAnnualInvestmentSummaryComparison: false,
            };
        }
        case GET_ANNUAL_INVESTMENT_SUMMARY_COMPARISON_FULFILLED: {
            return {
                ...state,
                annualInvestmentSummaryComparison: action.payload.data,
                annualInvestmentSummaryTimestamp: moment().format(),
                fetchingAnnualInvestmentSummaryComparison: false,
                fetchedAnnualInvestmentSummaryComparison: true,
                failedAnnualInvestmentSummaryComparison: false,
            };
        }
        case GET_ANNUAL_INVESTMENT_SUMMARY_COMPARISON_FAILED: {
            return {
                ...state,
                fetchingAnnualInvestmentSummaryComparison: false,
                fetchedAnnualInvestmentSummaryComparison: false,
                failedAnnualInvestmentSummaryComparison: true,
            };
        }

        case GET_ANNUAL_INVESTMENT_SUMMARIES_COMPARISON_PENDING: {
            return {
                ...state,
                fetchingAnnualInvestmentSummaryComparison: true,
                fetchedAnnualInvestmentSummaryComparison: false,
                failedAnnualInvestmentSummaryComparison: false,
            };
        }
        case GET_ANNUAL_INVESTMENT_SUMMARIES_COMPARISON_FULFILLED: {
            return {
                ...state,
                annualInvestmentSummariesComparison: action.payload,
                annualInvestmentSummaryTimestamp: moment().format(),
                fetchingAnnualInvestmentSummaryComparison: false,
                fetchedAnnualInvestmentSummaryComparison: true,
                failedAnnualInvestmentSummaryComparison: false,
            };
        }
        case GET_ANNUAL_INVESTMENT_SUMMARIES_COMPARISON_FAILED: {
            return {
                ...state,
                fetchingAnnualInvestmentSummaryComparison: false,
                fetchedAnnualInvestmentSummaryComparison: false,
                failedAnnualInvestmentSummaryComparison: true,
            };
        }

        case GET_SCENARIO_MATERIAL_DATA_POINTS_PENDING: {
            return {
                ...state,
                fetchingDataPoints: true,
                fetchedDataPoints: false,
                failedDataPoints: false,
            };
        }
        case GET_SCENARIO_MATERIAL_DATA_POINTS_FULFILLED: {
            let updatedDataPoints = action.payload.data;
            return {
                ...state,
                dataPoints: { ...state.dataPoints, ...updatedDataPoints },
                fetchingDataPoints: false,
                fetchedDataPoints: true,
                failedDataPoints: false,
            };
        }
        case GET_SCENARIO_MATERIAL_DATA_POINTS_FAILED: {
            return {
                ...state,
                fetchingDataPoints: false,
                fetchedDataPoints: false,
                failedDataPoints: true,
            };
        }
        case GET_MATERIAL_SUMMARY_PENDING: {
            return {
                ...state,
                fetchingMaterialSummary: true,
                fetchedMaterialSummary: false,
                failedMaterialSummary: false,
            };
        }
        case GET_MATERIAL_SUMMARY_FULFILLED: {
            return {
                ...state,
                materialSummary: action.payload,
                fetchingMaterialSummary: false,
                fetchedMaterialSummary: true,
                failedMaterialSummary: false,
            };
        }
        case GET_MATERIAL_SUMMARY_FAILED: {
            return {
                ...state,
                fetchingMaterialSummary: false,
                fetchedMaterialSummary: false,
                failedMaterialSummary: true,
            };
        }
        case ADDITIONAL_COMMENTS_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
                updateFailed: false,
            };
        case ADDITIONAL_COMMENTS_FULFILLED:
            let additionalComments = action.payload;

            return {
                ...state,
                additionalComments,
                updating: false,
                updated: true,
                updateFailed: false,
            };
        case ADDITIONAL_COMMENTS_REJECTED:
            return {
                ...state,
                updating: false,
                updated: false,
                updateFailed: true,
            };
        default:
            return state;
    }
};
