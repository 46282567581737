import axios from "axios";
import { API_ROOT } from "../../config/config";

const pages = {
    root: [
        {
            name: "Contracts",
            route: "/",
        },
        // {
        //     name: 'Templates',
        //     route: '/templates',
        // },
        // {
        //     name: 'Programmes',
        //     route: '/programmes',
        // },
        {
            name: "Users",
            route: "/users",
        },
        {
            name: "Settings",
            route: "/settings",
        },
        {
            name: "Rolling Sales",
            route: "/rolling-sales",
        },
        {
            name: "Floor Price Matrix",
            route: "/floor-price-matrix",
        },
        {
            name: "Segment Discount",
            route: "/segment-discount",
        },
        {
            name: "Materials",
            route: "/materials",
        },
        {
            name: "Equipment",
            route: "/equipment",
        },
    ],
    contract: [
        {
            name: "Products",
            route: "/contracts/:contractId/:scenarioId/products",
        },
        {
            name: "Contract Details",
            route: "/contracts/:contractId/:scenarioId/details",
        },
        {
            name: "General Details",
            route: "/contracts/:contractId/:scenarioId/details?section=generalDetails",
        },
        {
            name: "Goals and Needs",
            route: "/contracts/:contractId/:scenarioId/details?section=goalsAndNeeds",
        },
        {
            name: "Key Business Drivers",
            route: "/contracts/:contractId/:scenarioId/details?section=keyBusinessDrivers",
        },
        {
            name: "Customer Details",
            route: "/contracts/:contractId/:scenarioId/details?section=customerDetails",
        },
        {
            name: "General Scenario Details",
            route: "/contracts/:contractId/:scenarioId/scenario?section=generalDetails",
        },
        {
            name: "Exclusivity",
            route: "/contracts/:contractId/:scenarioId/scenario?section=exclusivity",
        },
        {
            name: "Scenario Details",
            route: "/contracts/:contractId/:scenarioId/scenario",
        },
        {
            name: "Financial Incentives",
            route: "/contracts/:contractId/:scenarioId/financial-incentives/",
        },
        {
            name: "Additional Incentives",
            route: "/contracts/:contractId/:scenarioId/equipment/",
        },
        {
            name: "Summary",
            route: "/contracts/:contractId/:scenarioId/summary",
        },
        {
            name: "Cash Flow",
            route: "/contracts/:contractId/:scenarioId/summary?section=cash-flow",
        },
        {
            name: "Review",
            route: "/contracts/:contractId/:scenarioId/summary?section=review",
        },
    ],
};

export default function getSearchSuggestions(searchText, contract) {
    console.log("Getting suggestions...");
    let generalPageItems = pages.root.filter((page) => page.name.toLowerCase().includes(searchText.toLowerCase()));
    let contractPageItems = [];

    let sections = [];

    if (contract.contractId !== undefined) {
        contractPageItems = pages.contract
            .filter((page) => page.name.toLowerCase().includes(searchText.toLowerCase()))
            .map((page) => {
                let pageRoute = page.route;
                pageRoute = pageRoute.replace(":contractId", contract.contractId);
                pageRoute = pageRoute.replace(":scenarioId", contract.scenarioId);
                console.log("Page route is...", pageRoute);
                return { ...page, route: pageRoute };
            });
    }

    sections.push({
        name: "Contracts",
        items: axios.get(API_ROOT + `/search/contracts?search=${searchText}`).then((data) => {
            return data.data;
        }),
    });

    sections.push({
        name: "General Pages",
        items: new Promise((resolve) => {
            if (generalPageItems.length > 0) {
                resolve(generalPageItems);
            }
            resolve([]);
        }),
    });

    sections.push({
        name: "contract Pages",
        items: new Promise((resolve) => {
            if (contractPageItems.length > 0) {
                resolve(contractPageItems);
            }
            resolve([]);
        }),
    });

    return sections;
}
