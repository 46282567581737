// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import FeatherIcon from "feather-icons-react";

// UI
import { Button } from "reactstrap";
import { Loading } from "../loading/Loading";

// COMPONENT
export class Slideable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 0,
            slide: false,
        };

        this.pageDown = this.pageDown.bind(this);
        this.pageUp = this.pageUp.bind(this);
        this.backToStart = this.backToStart.bind(this);
    }

    pageDown() {
        if (this.state.currentPage > 0) {
            this.setState({ currentPage: this.state.currentPage - 1 });
        }
    }

    backToStart() {
        this.setState({ currentPage: 0 });
    }

    pageUp() {
        if (
            this.state.currentPage <
            this.props.pages.filter((page) => {
                return page !== undefined;
            }).length -
            1
        ) {
            this.setState({ currentPage: this.state.currentPage + 1 });
        }
    }

    componentDidUpdate() {
        if (this.props.slide !== this.state.slide) {
            this.setState({ slide: this.props.slide }, () => {
                this.pageUp();
            });
        }

        this.props.backToStart(this.backToStart);
    }

    render() {
        let pages = this.props.pages.filter((page) => {
            return page !== undefined;
        });

        return (
            <div className="slideable-container">
                {pages[this.state.currentPage]}
                <div className="page-indicator-circles-container">
                    <div style={{ bottom: "-0.55em" }} onClick={() => this.pageDown()} className={"slideable-button left" + (this.state.currentPage === 0 ? " hidden" : "")}>
                        <FeatherIcon size={25} icon="chevron-left" />
                    </div>
                    {pages.map((page, index) => {
                        return <div key={index} className={"page-indicator-circle" + (this.state.currentPage === index ? " active" : "")} />;
                    })}

                    {this.state.currentPage === pages.length - 1 && (
                        <Button className="slideable-button right slide-in-right" onClick={this.props.handleSubmit} color="secondary">
                            {!this.props.submitLoading && this.props.submitText}
                            {this.props.submitLoading && <Loading light small />}
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}
