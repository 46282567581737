// IMPORT PACKAGE REFERENCES
import React from "react";
import FeatherIcon from "feather-icons-react";
import { Tooltip } from "reactstrap";
import nextId from "react-id-generator";

// COMPONENT
export class CardRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tooltipOpen: false,
            tooltipId: nextId(),
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        });
    }

    render() {
        let toolTip = <div></div>;
        if (this.props.toolTipText) {
            toolTip = (
                <div className="alert-icon float-right">
                    <span href="#" id={this.state.tooltipId}>
                        <FeatherIcon icon="alert-circle" />
                    </span>
                    <Tooltip style={{ maxWidth: "400px", textAlign: "left" }} placement="left" isOpen={this.state.tooltipOpen} target={this.state.tooltipId} toggle={this.toggle}>
                        {this.props.toolTipText}
                    </Tooltip>
                </div>
            );
        }

        return (
            <div className={"cca-card-row " + (this.props.top ? " top" : "") + (this.props.bottom ? " bottom" : "") + (this.props.noDivider ? " noDivider" : "") + (this.props.longform ? " longform" : "") + (this.props.stacked ? " stacked" : "")}>
                <div className="row-label float-left">{this.props.label}</div>
                {toolTip}
                <div className="row-value" style={{ minWidth: this.props.minWidth, maxWidth: this.props.longform ? "100%" : "50%" }}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
