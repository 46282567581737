// IMPORT PACKAGE REFERENCES
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { getContractDataPoint } from "../../../../state/actions/SummaryActions";
import { AsyncDataTable } from "../../../../containers/table/AsyncDataTable";

// COMPONENT
class SummaryDataTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sections: props.sections,
            columns: props.columns,
            editing: false,
            columnGroups: props.columnGroups,
            contractDataPoints: props.contractDataPoints === undefined ? {} : props.contractDataPoints,
        };
    }

    componentDidUpdate() {
        if (JSON.stringify(this.props.sections) !== JSON.stringify(this.state.sections)) {
            this.setState({ sections: this.props.sections });
        }

        if (JSON.stringify(this.props.columns) !== JSON.stringify(this.state.columns)) {
            this.setState({ columns: this.props.columns });
        }

        if (JSON.stringify(this.props.columnGroups) !== JSON.stringify(this.state.columnGroups)) {
            this.setState({ columnGroups: this.props.columnGroups });
        }

        if (JSON.stringify(this.props.contractDataPoints) !== JSON.stringify(this.state.contractDataPoints)) {
            this.setState({ contractDataPoints: this.props.contractDataPoints });
        }
    }

    render() {
        return (
            <AsyncDataTable
                scenarioId={this.props.match.params.scenarioId}
                graphedDataPoints={this.props.graphedDataPoints}
                toggleDataPoint={this.props.toggleDataPoint}
                toggleCollapse={this.props.toggleCollapse}
                handleSave={() => { }}
                onRef={this.props.onRef}
                columns={this.state.columns}
                sections={this.state.sections}
                dataPoints={this.state.contractDataPoints}
                rowIdentifier={"MG1"}
                getDataPoints={(dataPoints, rowIndex, columnIndex, incremental = true, scenarioId, resolve, reject) => {
                    if (this.props.collatedContractDataPoints) {
                        return this.props.getContractDataPoint(this.props.match.params.contractId, scenarioId, dataPoints, -1, `Year ${columnIndex - 1}`, incremental, 'null', resolve, reject);
                    } else {
                        return this.props.getContractDataPoint(this.props.match.params.contractId, scenarioId, dataPoints, columnIndex, `Year ${columnIndex}`, incremental,'null', resolve, reject);
                    }
                }}
            />
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { contractDataPoints } = state.summaryReducer;
    return { contractDataPoints };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getContractDataPoint,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(SummaryDataTable));

// EXPORT COMPONENT
export { hoc as SummaryDataTable };
