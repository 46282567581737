import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "moment/locale/en-nz";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Button } from "reactstrap";

class SingleDatePicker extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.handleDateChange(this.props.dateValue, this.props.expressionIndex, this.props.statementIndex, this.props.componentIndex, this.props.vfKey);
    }

    render() {
        return (
            <DatePicker
                key={this.props.vfKey}
                showYearDropdown
                className={"date-input " + (this.props.conditionalInput ? "conditional" : "")}
                locale="en-NZ"
                startDate={this.props.dateValue}
                endDate={this.props.dateValue}
                selected={this.props.dateValue}
                selectsStart
                onChange={(date) => this.props.handleDateChange(date, this.props.expressionIndex, this.props.statementIndex, this.props.componentIndex, this.props.vfKey)}
            >
                {(this.props.contractStartButton || this.props.contractEndButton) && (
                    <div>
                        <Button
                            onClick={() => {
                                this.props.handleDateChange(moment(this.props.contract.details.general.contractTerm[this.props.dateIndex], "DD/MM/YYYY"), this.props.expressionIndex, this.props.statementIndex, this.props.componentIndex, this.props.vfKey);
                            }}
                            style={{ width: "100%" }}
                        >
                            {this.props.contractEndButton && <div> Contract End </div>}
                            {this.props.contractStartButton && <div> Contract Start </div>}
                        </Button>
                    </div>
                )}
            </DatePicker>
        );
    }
}

SingleDatePicker.propTypes = {
    contract: PropTypes.object.isRequired,
};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { contract } = state.contractReducer;
    return { contract };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleDatePicker));

// EXPORT COMPONENT

export { hoc as SingleDatePicker };
