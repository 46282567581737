import { GET_PAYMENT_PENDING, GET_PAYMENT_FULFILLED, GET_PAYMENT_REJECTED, GET_PAYMENTS_PENDING, GET_PAYMENTS_FULFILLED, GET_PAYMENTS_REJECTED, ADD_PAYMENT_FULFILLED, ADD_PAYMENT_PENDING, ADD_PAYMENT_REJECTED, DELETE_PAYMENTS_FULFILLED, DELETE_PAYMENTS_PENDING, DELETE_PAYMENTS_REJECTED, UPDATE_PAYMENT_PENDING, UPDATE_PAYMENT_FULFILLED, UPDATE_PAYMENT_FAILED, GET_NOVELTIES_FULFILLED, GET_NOVELTIES_REJECTED, GET_NOVELTIES_PENDING, GET_BASE_NOVELTIES_PENDING, GET_BASE_NOVELTIES_FULFILLED, GET_BASE_NOVELTIES_REJECTED } from "../actions/PaymentActions";

import { CLEAR_REDUCERS, PREPARE_FOR_CONTRACT_SWITCH } from "../actions/GeneralActions";

// INITIALIZE STATE
const initialState = {
    payments: {},
    payment: {},
    novelties: {},
    noveltyList: [],
    fetching: false,
    fetched: false,
    failed: false,
    fetching_payment: false,
    fetched_payment: false,
    failed_payment: false,
    adding: false,
    added: false,
    addFailed: false,
    deleting: false,
    deleted: false,
    deleteFailed: false,
    updating: false,
    updated: false,
    updateFailed: false,
};

// REDUCER
export const PaymentReducer = (state = initialState, action) => {
    let payments = {};
    let novelties = {};

    switch (action.type) {
        case CLEAR_REDUCERS:
            return initialState;
        case PREPARE_FOR_CONTRACT_SWITCH:
            return initialState;
        /* GET PAYMENTS */
        case GET_PAYMENT_PENDING:
            return {
                ...state,
                fetching_payment: true,
                fetched_payment: false,
                failed_payment: false,
            };
        case GET_PAYMENT_FULFILLED:
            return {
                ...state,
                payment: action.payload,
                fetching_payment: false,
                fetched_payment: true,
                failed_payment: false,
            };
        case GET_PAYMENT_REJECTED:
            return {
                ...state,
                fetching_payment: false,
                fetched_payment: false,
                failed_payment: true,
            };
        case GET_PAYMENTS_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_PAYMENTS_FULFILLED:
            return {
                ...state,
                payments: action.payload,
                fetching: false,
                fetched: true,
                failed: false,
            };
        case GET_PAYMENTS_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        case GET_NOVELTIES_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_NOVELTIES_FULFILLED:
            return {
                ...state,
                novelties: action.payload,
                fetching: false,
                fetched: true,
                failed: false,
            };
        case GET_NOVELTIES_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        case GET_BASE_NOVELTIES_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_BASE_NOVELTIES_FULFILLED:
            return {
                ...state,
                noveltyList: action.payload,
                fetching: false,
                fetched: true,
                failed: false,
            };
        case GET_BASE_NOVELTIES_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        case ADD_PAYMENT_PENDING:
            return {
                ...state,
                adding: true,
                added: false,
                addFailed: false,
            };
        case ADD_PAYMENT_FULFILLED:
            return {
                ...state,
                adding: false,
                added: true,
                addFailed: false,
            };
        case ADD_PAYMENT_REJECTED:
            return {
                ...state,
                adding: false,
                added: false,
                addFailed: true,
            };
        case DELETE_PAYMENTS_PENDING:
            return {
                ...state,
                deleting: true,
                deleted: false,
                deleteFailed: false,
            };
        case DELETE_PAYMENTS_FULFILLED:
            let deletedIds = action.payload;
            let payment = Object.assign({}, state.payment);
            payments = Object.assign({}, state.payments);
            novelties = Object.assign({}, state.novelties);

            deletedIds.forEach((id) => {
                delete payments[id];
                delete novelties[id];
            });

            if (deletedIds.includes(state.payment.id)) {
                payment = {};
            }

            return {
                ...state,
                payment: payment,
                payments: payments,
                deleting: false,
                deleted: true,
                deleteFailed: false,
            };
        case DELETE_PAYMENTS_REJECTED:
            return {
                ...state,
                deleting: false,
                deleted: false,
                deleteFailed: true,
            };
        case UPDATE_PAYMENT_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
                updateFailed: false,
            };
        case UPDATE_PAYMENT_FULFILLED:
            let newPayment = action.payload;
            payments = Object.assign({}, state.payments);
            novelties = Object.assign({}, state.novelties);
            if (newPayment.isNovelty != null && newPayment.isNovelty) {
                novelties[newPayment.id] = newPayment;
            } else {
                payments[newPayment.id] = newPayment;
            }

            return {
                ...state,
                payment: newPayment,
                payments: payments,
                novelties: novelties,
                updating: false,
                updated: true,
                updateFailed: false,
            };
        case UPDATE_PAYMENT_FAILED:
            return {
                ...state,
                updating: false,
                updated: false,
                updateFailed: true,
            };
        default:
            return state;
    }
};
