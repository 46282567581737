// IMPORT PACKAGE REFERENCES
import React from "react";
import PropTypes from "prop-types";

// COMPONENT
const AccessDenied = (props) => (
    <div className="access-denied">
        <div>
            <div>
                <h3>Access Denied</h3>
            </div>
            <div>
                <i>Insufficient privileges to access this resource.</i>
            </div>
        </div>
    </div>
);

// EXPORT COMPONENT
export { AccessDenied };
