// IMPORT PACKAGE REFERENCES
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// UI
import { IndicatorTile } from "./IndicatorTile";

import { EditableInputTypes } from "../../../enums/EditableInputTypes";

// COMPONENT
class EquipmentIndicatorRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            indicators: [],
            equipmentId: null,
        };

        this.generateIndicators = this.generateIndicators.bind(this);
    }

    generateIndicators() {
        let indicators = [];
        let equipmentId = this.props.data.equipmentId;
        indicators.push({ title: "Capital Value", value: this.props.equipments[equipmentId]["capitalValue"], type: EditableInputTypes.DOLLAR });
        indicators.push({ title: "Quantity", value: this.props.equipments[equipmentId]["quantity"] });

        this.setState({ indicators: indicators });
    }

    componentDidMount() {
        this.generateIndicators();
    }

    componentDidUpdate(prevProps) {
        let equipmentId = this.props.data.equipmentId;
        if (JSON.stringify(this.props.equipments[equipmentId]) !== JSON.stringify(prevProps.equipments[equipmentId])) {
            this.generateIndicators();
        }
    }

    render() {
        return (
            <div className="indicator-row">
                {this.state.indicators.map((data, index) => {
                    return <IndicatorTile key={index} title={data.title} value={data.value} type={data.type} />;
                })}
            </div>
        );
    }
}

EquipmentIndicatorRow.propTypes = {
    equipments: PropTypes.object.isRequired,
};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { equipments } = state.equipmentReducer;
    return { equipments };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(EquipmentIndicatorRow));

// EXPORT COMPONENT

export { hoc as EquipmentIndicatorRow };
