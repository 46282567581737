import React from "react";
import FeatherIcon from "feather-icons-react";
import { Button } from "reactstrap";

export class ScenarioPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scenariosOpen: false,
            selectedScenarioId: undefined,
            baseScenarioId: undefined,
            scenarios: [],
            editing: false,
            scenarioDetails: {},
            switchingScenarios: false,
        };

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.scenariosOpen) {
                this.setState({ scenariosOpen: false });
            }
        }
    }

    toggleScenarioPickerExpansion() {
        this.setState({ scenariosOpen: !this.state.scenariosOpen });
    }

    toggleEditScenarios() {
        if (this.state.editing) {
            this.setState({ editing: false });
            return;
        }
        this.setState({ editing: true });
    }

    componentDidUpdate(prevProps) {
        if (this.props.scenarios) {
            if (JSON.stringify(this.props.scenarios) !== JSON.stringify(this.state.scenarios)) {
                this.setState({ scenarios: this.props.scenarios });
            }
            if (!this.state.selectedScenarioId) {
                if (this.props.scenarioId) {
                    this.setState({ selectedScenarioId: this.props.scenarioId });
                } else {
                    this.setState({ selectedScenarioId: this.props.scenarios[0].scenarioId });
                }
            } else if (prevProps.scenarioId !== this.props.scenarioId) {
                this.setState({ selectedScenarioId: this.props.scenarioId });
            }
        }
    }

    deleteScenario(scenario) {
        if (confirm("Are you sure you want to delete this scenario?")) {
            this.props.deleteScenario(scenario.scenarioId, this.props.contractId);
        }
    }

    navigateToScenario(scenario) {
        this.props.toggleSwitching();
        // Have to build up the URL with the new scenario ID...
        let components = this.props.location.pathname.split("/");
        components[3] = scenario.scenarioId;
        let newPath = components.join("/");
        let queryString = this.props.location.search;
        this.props.history.push(newPath + queryString);
        // And close the scenario picker...
        this.setState({ selectedScenarioId: scenario.scenarioId, scenariosOpen: false }, () => {
            setTimeout(() => {
                this.props.toggleSwitching();
            }, 750);
        });
        // Fetch the new scenario information...
        this.props.getScenarioDetails(this.props.contractId, scenario.scenarioId, () => { });
    }

    render() {
        let selectedScenario;
        if (this.state.scenarios) {
            let selectedScenarioIndex = this.state.scenarios.findIndex((scenario) => {
                return scenario.scenarioId === this.state.selectedScenarioId;
            });
            selectedScenario = this.state.scenarios[selectedScenarioIndex];
        }

        return (
            <div ref={this.setWrapperRef} className={"sidebar-scenario-picker"}>
                <div className={"scenario-options-container" + (this.state.scenariosOpen ? " open" : "")}>
                    {selectedScenario && (
                        <div
                            onClick={() => {
                                this.toggleScenarioPickerExpansion();
                            }}
                            className={"scenario-option" + (this.state.scenariosOpen ? " open" : " collapsed")}
                        >
                            {this.props.baseScenario === selectedScenario.scenarioId && <span className="base-scenario-indicator">Base</span>}
                            {selectedScenario.scenarioName === "" ? "Unnamed Scenario" : selectedScenario.scenarioName}
                            <FeatherIcon className={`scenario-arrow ${this.state.scenariosOpen ? "up" : "down"}`} icon="chevron-left" />
                        </div>
                    )}

                    {selectedScenario &&
                        this.state.scenarios
                            .filter((scenario) => {
                                return scenario.scenarioId !== selectedScenario.scenarioId;
                            })
                            .map((scenario, index) => {
                                return (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            !this.state.editing && this.navigateToScenario(scenario);
                                        }}
                                        className={"scenario-option"}
                                    >
                                        {this.state.editing && scenario.scenarioId !== this.props.baseScenario && (
                                            <FeatherIcon
                                                onClick={() => {
                                                    scenario.scenarioId !== this.props.baseScenario && this.deleteScenario(scenario);
                                                }}
                                                size="20"
                                                icon="trash-2"
                                            />
                                        )}
                                        {this.props.baseScenario === scenario.scenarioId && <span className="base-scenario-indicator">Base</span>}
                                        {scenario.scenarioName === "" ? "Unnamed Scenario" : scenario.scenarioName}
                                    </div>
                                );
                            })}
                    <div className="scenario-option last">
                        {this.state.scenarios.length > 1 && (
                            <Button
                                onClick={() => {
                                    this.toggleEditScenarios();
                                }}
                                color="link"
                                className="create-scenario-button"
                            >
                                {this.state.editing ? "Done" : "Edit"}
                            </Button>
                        )}
                        <Button
                            onClick={() => {
                                this.props.showModal("scenario");
                            }}
                            color="link"
                            className="create-scenario-button"
                        >
                            Create Scenario
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
