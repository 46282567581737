// IMPORT PACKAGE REFERENCES
import React from "react";

// COMPONENT
const SettingsPage = () => (
    <main>
        <div>
            <a>Settings.</a>
        </div>
    </main>
);

export { SettingsPage };
