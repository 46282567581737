export var CCAInputTypes = Object.freeze({
    FREE_TEXT: 0,
    SELECT: 1,
    DATE: 2,
    DATE_RANGE: 3,
    DESCRIPTION: 4,
    CHECKBOX: 5,
    PERCENT: 6,
    NUMBER: 7,
    DOLLAR: 8,
    CONDITIONALISED: 9,
});
