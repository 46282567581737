// IMPORT PACKAGE REFERENCES
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ContextPage } from "../../containers/layout/ContextPage";
import { getMaterials } from "../../state/actions/MaterialActions";
import { Card } from "../../containers/card/Card";
import { CardRow } from "../../containers/card/CardRow";
import { Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import { API_ROOT } from "../../../config/config";
import moment from "moment";

import { uploadMaterials } from "../../state/actions/ConfigurationActions";

// COMPONENT
class MaterialsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            materials: {},
            fileToUpload: undefined,
            materialsLastUpdated: undefined,
            uploading: false,
            progress: 0,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
    }

    componentDidMount() {
        this.props.getMaterials(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
    }

    componentDidUpdate() {
        if (JSON.stringify(this.props.materials) !== JSON.stringify(this.state.materials)) {
            this.setState({ materials: this.props.materials });
        }

        if (this.state.materialsLastUpdated !== this.props.materialsLastUpdated) {
            this.setState({ materialsLastUpdated: this.props.materialsLastUpdated });
        }
    }

    handleChange(event) {
        let files = event.target.files;

        if (files.length >= 1) {
            let file = files[0];

            this.setState({
                fileToUpload: file,
            });
        }
    }

    handleUpload() {
        this.setState({ uploading: true });
        this.props.uploadMaterials(
            this.state.fileToUpload,
            (progress) => {
                this.setState({ progress: progress.loaded });
            },
            (success, reason) => {
                if (success) {
                    this.setState({ fileToUpload: undefined });
                    this.props.getMaterials(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
                }else{
                    alert(reason ? reason : "Upload failed, please check that your data is correct");
                }

                this.setState({ uploading: false });
            }
        );
    }

    round2dp(num) {
        return Math.round(num * 100) / 100;
    }

    render() {
        let materials = {};

        if (this.state.materials.hasOwnProperty("allMaterials")) {
            materials = this.state.materials.allMaterials;
        }

        return (
            <ContextPage fetching={this.props.fetching} fetched={this.props.fetched} title={"Materials"}>
                <Card
                    title="Import Material Data"
                    action={
                        <div onClick={(e) => this.handleUpload(e)} className={"text-link" + (this.state.fileToUpload === undefined ? " disabled" : "")}>
                            Upload
                        </div>
                    }
                >
                    <FormGroup>
                        <Label for="exampleFile">File</Label>
                        <Input accept=".csv" onChange={this.handleChange} type="file" name="file" id="exampleFile" />
                        <FormText color="muted">{"Please ensure that the file is formatted correctly (accepts .csv)."}</FormText>
                        <FormText color="muted">{"Column format (spelling must match): MG1Desc, MG1FriendlyDesc, MG1, Unit, UoM, Amount, MarginPercent, ProductGroup, RRP_INC, UnitPriceEX, UnitPriceINC, UnitVolume, UnitsPerCase, MATVol, BeverageCategory"}</FormText>
                    </FormGroup>
                </Card>

                <Card title="Materials Data">
                    <CardRow label={"Last Updated"} top>
                        {this.state.materialsLastUpdated === undefined ? "No materials data uploaded." : moment(this.state.materialsLastUpdated).format("DD/MM/YYYY")}
                    </CardRow>

                    <CardRow label={"Download Latest Sales Data"} bottom>
                        {this.state.materialsLastUpdated === undefined ? "No materials data to download." : <a href={API_ROOT + "/materials/Download"}>Download</a>}
                    </CardRow>
                </Card>

                <Card noTitle>
                    {Object.keys(materials).map((materialKey) => {
                        let category = materials[materialKey];
                        let categoryMaterials = category.materials;

                        return categoryMaterials.map((material) => {
                            return (
                                <CardRow label={material.MG1FriendlyDesc == null ? material.MG1Desc : material.MG1FriendlyDesc} minWidth={"70%"}>
                                    <Row>
                                        <Col xs="1">{material.MarginPercent === undefined ? "-" : `${this.round2dp(parseFloat(material.MarginPercent))}%`}</Col>
                                        <Col xs="1">{material.ProductGroup === undefined ? "-" : `${material.ProductGroup}`}</Col>
                                        <Col xs="2">{material.BeverageCategory === undefined ? "-" : `${material.BeverageCategory}`}</Col>
                                        <Col xs="1">{material.RRP_INC === undefined ? "-" : `$${this.round2dp(parseFloat(material.RRP_INC))}`}</Col>
                                        <Col xs="1">{material.UnitPriceEX === undefined ? "-" : `$${this.round2dp(parseFloat(material.UnitPriceEX))}`}</Col>
                                        <Col xs="1">{material.UnitPriceINC === undefined ? "-" : `$${this.round2dp(parseFloat(material.UnitPriceINC))}`}</Col>
                                        <Col xs="1">{material.UnitVolume === undefined ? "-" : `${this.round2dp(parseFloat(material.UnitVolume))}`}</Col>
                                        <Col xs="1">{material.UnitsPerCase === undefined ? "-" : `${this.round2dp(parseFloat(material.UnitsPerCase))}`}</Col>
                                    </Row>
                                </CardRow>
                            );
                        });
                    })}
                </Card>
            </ContextPage>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { fetching, fetched, failed, materials, materialsLastUpdated } = state.materialReducer;
    return { fetching, fetched, failed, materials, materialsLastUpdated };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getMaterials,
            uploadMaterials,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(MaterialsPage));

// EXPORT COMPONENT

export { hoc as MaterialsPage };
