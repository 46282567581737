// *** PACKAGE REFERENCES *** //
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// *** ACTIONS *** //
import { getPayment, savePayment, savePaymentConditions } from "../../../state/actions/PaymentActions";
import { saveContractCustomConditions } from "../../../state/actions/ContractActions";
import { startWorking, stopWorking } from "../../../state/actions/GeneralActions";
import { getContractSummarySnapshot } from "../../../state/actions/SummaryActions";

// *** UI *** //
import { ContextPage } from "../../../containers/layout/ContextPage";
import { Card } from "../../../containers/card/Card";
import { CardRow } from "../../../containers/card/CardRow";
import { CardActions } from "../../../containers/card/CardActions";
import { Formik } from "formik";
import { Message } from "../../../containers/layout/Message";
import { Conditionaliser } from "../../../../modules/conditionaliser/Conditionaliser";
import { CCAInput } from "../../../containers/inputs/CCAInput";
import { CCAInputTypes } from "../../../enums/CCAInputTypes";

class PaymentDetailsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: {
                1: false,
                2: false,
            },
            currentPaymentId: undefined,
            message: "",
            messageActive: false,
            messageError: false,
            working: false,
            conditionSaveLoading: false,
        };

        this.valuesAtLastSave = {};

        this.getPayment = this.getPayment.bind(this);
        this.toggleEditing = this.toggleEditing.bind(this);
        this.handleSaveCustomConditions = this.handleSaveCustomConditions.bind(this);
        this.conditionaliser = null;
    }

    componentDidMount() {
        this.getPayment();
    }

    componentDidUpdate(_, prevState) {
        this.getPayment();

        if (this.state.working !== prevState.working) {
            if (prevState.working === false) {
                this.props.startWorking();
            } else {
                this.props.stopWorking();
            }
        }
    }

    /*
        Fetches the payment only if the previous payment ID is different to the current or did not exist before.
    */
    getPayment() {
        if (this.state.currentPaymentId !== this.props.match.params.id) {
            this.props.getPayment(this.props.match.params.contractId, this.props.match.params.scenarioId, this.props.match.params.id, () => {
                this.valuesAtLastSave = this.props.payment;
            });
            this.setState({ currentPaymentId: this.props.match.params.id });
        }
    }

    toggleEditing(sectionNumber) {
        this.setState({ editing: { ...this.state.editing, [sectionNumber]: !this.state.editing[sectionNumber] } });
    }

    handleSaveCustomConditions(conditions) {
        this.setState({ messageActive: true, messageLoading: true, message: "Saving Custom Conditions" });
        this.props.saveContractCustomConditions(this.props.match.params.contractId, conditions, (success) => {
            this.setState({ messageActive: false, messageLoading: false });
            this.props.getContractSummarySnapshot(this.props.match.params.contractId, this.props.match.params.scenarioId);
        });
    }

    handleChange() { }

    handleSubmit() {
        if (this.conditionaliser !== null) {
            let expressions = JSON.parse(this.conditionaliser.getExpressions());

            this.setState({ conditionSaveLoading: true });
            this.props.savePaymentConditions(this.props.match.params.contractId, this.props.match.params.scenarioId, this.props.match.params.id, expressions, (success) => {
                if (success) {
                    this.setState({ conditionSaveLoading: false });
                    this.props.getContractSummarySnapshot(this.props.match.params.contractId, this.props.match.params.scenarioId);
                } else {
                    this.setState({ messageActive: true, messageError: true, message: "Error saving payment conditions" });
                    setTimeout(() => {
                        this.setState({ messageActive: false, messageError: false, conditionSaveLoading: false });
                    }, 2000);
                }
            });
        }
    }

    render() {
        return (
            <ContextPage fetching={this.props.fetching_payment} fetched={this.props.fetched_payment} title={this.props.payment.type}>
                <Message active={this.state.messageActive} message={this.state.message} error={this.state.messageError} />
                {Object.keys(this.props.payment).length === 0 ? (
                    <div className="empty-view">
                        <img alt={"empty"} className="empty-icon" src={require("../../../../images/empty-placeholder.png")} />
                        <h4>No item selected </h4>
                    </div>
                ) : (
                        <Formik
                            enableReinitialize={true}
                            initialValues={this.props.payment}
                            onSubmit={(values) => {
                                if (JSON.stringify(values) !== JSON.stringify(this.valuesAtLastSave)) {
                                    this.setState({ working: true });
                                    // Save details here.
                                    // On success, reset the message to inactive.
                                    // On Failiure, set the message to its error state, then reset it to inactive after a timeout to give user a chance to read it
                                    this.props.savePayment(this.props.match.params.contractId, this.props.match.params.scenarioId, this.props.match.params.id, values, (success) => {
                                        console.log("Saved payment...", success);
                                        if (success) {
                                            this.setState({ working: false });
                                            this.valuesAtLastSave = values;
                                            console.log("Getting contract summary snapshot", this.props.getContractSummarySnapshot);
                                            this.props.getContractSummarySnapshot(this.props.match.params.contractId, this.props.match.params.scenarioId);
                                        } else {
                                            this.setState({ messageActive: true, messageError: true, message: "Error saving payments" });
                                            setTimeout(() => {
                                                this.setState({ messageActive: false, messageError: false, working: false });
                                            }, 2000);
                                        }
                                    });
                                }
                            }}
                            render={({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldTouched, setFieldValue }) => (
                                <Fragment>
                                    <Card>
                                        <CardRow label={"Payment Type"} top={true}>
                                            <CCAInput
                                                type={CCAInputTypes.FREE_TEXT}
                                                name="type"
                                                value={values.type}
                                                onChange={handleChange}
                                                onSave={(e) => {
                                                    handleSubmit(e);
                                                }}
                                            />
                                        </CardRow>

                                        <CardRow label={"Amount"}>
                                            <CCAInput
                                                type={CCAInputTypes.DOLLAR}
                                                name="amount"
                                                value={values.amount}
                                                onChange={(value) => {
                                                    setFieldValue("amount", value);
                                                }}
                                                onSave={handleSubmit}
                                            />
                                        </CardRow>

                                        <CardRow label={"Annually Recurring"}>
                                            <CCAInput
                                                type={CCAInputTypes.CHECKBOX}
                                                value={values.annuallyRecurring}
                                                name="annuallyRecurring"
                                                onChange={(name, event) => {
                                                    setFieldValue(name, event);
                                                }}
                                                onSave={(e) => {
                                                    handleSubmit(e);
                                                }}
                                            />
                                        </CardRow>

                                        <CardRow longform label={"Description"} bottom={true}>
                                            <CCAInput
                                                type={CCAInputTypes.DESCRIPTION}
                                                name="description"
                                                value={values.description}
                                                onChange={handleChange}
                                                onSave={(e) => {
                                                    handleSubmit(e);
                                                }}
                                            />
                                        </CardRow>
                                    </Card>

                                    <Card
                                        title={"Compliance"}
                                        action={
                                            <CardActions
                                                handleClick={(e) => {
                                                    if (this.state.editing[2]) {
                                                        this.handleSubmit(e);
                                                    }

                                                    this.toggleEditing(2);
                                                }}
                                                editing={this.state.editing[2]}
                                                loading={this.state.conditionSaveLoading}
                                            />
                                        }
                                    >
                                        <Conditionaliser customConditions={this.props.contract.customConditions} saveCustomConditions={this.handleSaveCustomConditions} value={this.props.payment.conditions} allowResults={false} ref={(ref) => (this.conditionaliser = ref)} setSaveButtonState={() => { }} onChange={this.handleChange} editing={this.state.editing[2]} />
                                    </Card>
                                </Fragment>
                            )}
                        />
                    )}
            </ContextPage>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { payment, fetched, fetching, failed, fetching_payment, fetched_payment, failed_payment } = state.paymentReducer;
    const { contract } = state.contractReducer;
    const { working } = state.generalReducer;
    return { payment, fetched, fetching, failed, fetching_payment, fetched_payment, failed_payment, contract, working };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getPayment, savePayment, saveContractCustomConditions, savePaymentConditions, startWorking, stopWorking, getContractSummarySnapshot }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentDetailsPage));

// EXPORT COMPONENT
export { hoc as PaymentDetailsPage };
