// Import package references
import React, { Fragment } from "react";
import moment from "moment";
import "moment/locale/en-nz";
import NumberFormat from "react-number-format";

// UI
import { Input } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormCheckbox } from "./FormCheckbox";
import { ConditionalisationSection } from "../conditionaliser/ConditionalisationSection";

// Enums
import { CCAInputTypes } from "../../enums/CCAInputTypes";

export class CCAInput extends React.Component {
    constructor(props) {
        super(props);

        this.conditionaliser = null;
    }

    renderFreeTextInput() {
        return (
            <Input
                disabled={this.props.disabled}
                placeholder={this.props.placeholder}
                onChange={this.props.onChange}
                onBlur={(e) => {
                    this.props.onSave(e);
                }}
                value={this.props.value}
                name={this.props.name}
                className={"borderless right"}
            />
        );
    }

    renderDescriptionInput() {
        return <textarea placeholder={this.props.placeholder} className="text-area" onChange={this.props.onChange} onBlur={this.props.onSave} value={this.props.value} type="text" name={this.props.name} />;
    }

    renderSelectInput() {
        return (
            <Input
                placeholder={this.props.placeholder}
                className={this.props.css !== undefined ? this.props.css : "borderless right"}
                onBlur={(e) => {
                    this.props.onSave(e);
                }}
                onChange={this.props.onChange}
                value={this.props.value ? this.props.value : this.props.firstItemNone}
                type="select"
                name="select"
                disabled={this.props.disabled}
            >
                {this.props.firstItemNone && (
                    <option selected="selected" hidden value="None">
                        -- None --
                    </option>
                )}
                {this.props.options.map((option, index) => {
                    if (this.props.optionsAreObjects) {
                        // saveChoice = the value that will be sent in the payload when saved
                        // displayChoice = the value that will be displayed as the option in the selection
                        return (
                            <option key={index} value={option.saveChoice}>
                                {" "}
                                {option.displayChoice}{" "}
                            </option>
                        );
                    } else {
                        return (
                            <option key={index} value={option}>
                                {" "}
                                {option}{" "}
                            </option>
                        );
                    }
                })}
            </Input>
        );
    }

    renderDateInput() {
        return (
            <div className="date-input-container">
                <DatePicker
                    placeholder={this.props.placeholder}
                    onChange={this.props.onChange}
                    onBlur={(e) => {
                        this.props.onSave(e);
                    }}
                    popperPlacement="left-start"
                    showYearDropdown
                    locale="en-NZ"
                    className="date-input"
                    selected={moment(this.props.value, "DD/MM/YYYY")}
                    selectsStart
                />
            </div>
        );
    }

    renderDateRangeInput() {
        return (
            <div className="date-input-container">
                <DatePicker
                    placeholder={this.props.placeholder}
                    onChange={this.props.onChange}
                    onBlur={(e) => {
                        this.props.onSave(e);
                    }}
                    popperPlacement="left-start"
                    showYearDropdown
                    locale="en-NZ"
                    className="date-input"
                    selected={moment(this.props.value[0], "DD/MM/YYYY")}
                    selectsStart
                />
            </div>
        );
    }

    renderCheckBoxInput() {
        return (
            <FormCheckbox
                placeholder={this.props.placeholder}
                checked={this.props.value}
                value={this.props.value}
                name={this.props.name}
                onChange={async (name, event) => {
                    await this.props.onChange(name, event);
                    this.props.onSave(event);
                }}
            />
        );
    }

    renderNumberInput() {
        return (
            <Fragment>
                <NumberFormat
                    placeholder={this.props.placeholder}
                    className="basic-input borderless right"
                    value={this.props.value}
                    onValueChange={(values) => {
                        const { value } = values;
                        this.props.onChange(value);
                    }}
                    onBlur={(e) => {
                        this.props.onSave(e);
                    }}
                />
            </Fragment>
        );
    }

    renderPercentInput() {
        return (
            <Fragment>
                <NumberFormat
                    allowNegative={false}
                    isAllowed={(values) => {
                        return values.floatValue >= 0 && values.floatValue <= 100;
                    }}
                    placeholder={this.props.placeholder}
                    className="basic-input borderless right"
                    value={this.props.value}
                    suffix="%"
                    fixedDecimalScale={true}
                    decimalScale={1}
                    onValueChange={(values) => {
                        const { value } = values;
                        this.props.onChange(value);
                    }}
                    onBlur={(e) => {
                        this.props.onSave(e);
                    }}
                />
            </Fragment>
        );
    }

    renderDollarInput() {
        return (
            <Fragment>
                <NumberFormat
                    placeholder={this.props.placeholder}
                    className="basic-input borderless right"
                    value={this.props.value}
                    prefix="$"
                    thousandSeparator={true}
                    fixedDecimalScale
                    decimalScale={2}
                    onValueChange={(values) => {
                        const { value } = values;
                        this.props.onChange(value);
                    }}
                    onBlur={(e) => {
                        this.props.onSave(e);
                    }}
                />
            </Fragment>
        );
    }

    renderConditionalisedInput() {
        return (
            <Fragment>
                <ConditionalisationSection
                    onRef={this.props.onRef}
                    stacked={this.props.stacked}
                    top={this.props.top}
                    id={1}
                    units={this.props.units}
                    resultOptions={this.props.resultOptions}
                    type={this.props.conditionalisationSectionType}
                    label={this.props.label}
                    value={this.props.value}
                    editing={this.props.editing}
                    onSave={() => {
                        this.props.onSave();
                    }}
                />
            </Fragment>
        );
    }

    render() {
        switch (this.props.type) {
            case CCAInputTypes.FREE_TEXT: {
                return this.renderFreeTextInput();
            }
            case CCAInputTypes.DESCRIPTION: {
                return this.renderDescriptionInput();
            }
            case CCAInputTypes.SELECT: {
                return this.renderSelectInput();
            }
            case CCAInputTypes.DATE: {
                return this.renderDateInput();
            }
            case CCAInputTypes.DATE_RANGE: {
                return this.renderDateRangeInput();
            }
            case CCAInputTypes.CHECKBOX: {
                return this.renderCheckBoxInput();
            }
            case CCAInputTypes.PERCENT: {
                return this.renderPercentInput();
            }
            case CCAInputTypes.NUMBER: {
                return this.renderNumberInput();
            }
            case CCAInputTypes.DOLLAR: {
                return this.renderDollarInput();
            }
            case CCAInputTypes.CONDITIONALISED: {
                return this.renderConditionalisedInput();
            }
        }
    }
}
