// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import FeatherIcon from "feather-icons-react";

const exclusivityLabelMap = {
    CSD: "CSD",
    NARTD: "NARTD",
    coffee: "Coffee",
    energy: "Energy",
    exceptions: "Exceptions",
    juice: "Juice",
    lifestyle: "Lifestyle",
    sports: "Sports",
    water: "Water",
};

// COMPONENT
export class ExclusivityPin extends React.Component {
    render() {
        let exclusivity = this.props.exclusivity !== undefined ? this.props.exclusivity : {};

        return (
            <Fragment>
                <div className="menu-subtitle">Exclusivity</div>
                <div className="sidebar-summary-section">
                    {Object.keys(exclusivity).map((exclusivityKey, exclusivityIndex) => {
                        let isExclusive = this.props.exclusivity[exclusivityKey];

                        return (
                            <div className={"summary-row" + (exclusivityIndex % 2 === 0 ? " even" : " odd")}>
                                <div className="summary-row-item left">{exclusivityLabelMap[exclusivityKey]}</div>
                                <div className="summary-row-item right">{isExclusive ? <FeatherIcon size="12" icon="check" /> : <FeatherIcon size="12" icon="x" />}</div>
                            </div>
                        );
                    })}
                </div>
            </Fragment>
        );
    }
}
