// IMPORT PACKAGE REFERENCES
import React from "react";
import ReactDOM from "react-dom";
import { Loading } from "../loading/Loading";

// COMPONENT
export default class Placeholder extends React.Component {
    render() {
        if (this.props.type === "no_materials") {
            return (
                <div className="placeholder-content">
                    <img alt={"placeholder"} className="placeholder-icon" src={require("../../../images/materials-placeholder.png")} />
                    <h5 className="empty-view materials">No material groups</h5>
                </div>
            );
        } else {
            return <div></div>;
        }
    }
}
