import {
    LOGIN_USER_PENDING,
    LOGIN_USER_FULFILLED,
    LOGIN_USER_REJECTED,
    LOGOUT_USER_REJECTED,
    LOGOUT_USER_FULFILLED,
    LOGOUT_USER_PENDING,
    TOGGLE_SIDEBAR,
    PIN_TO_SIDEBAR,
    UNPIN_FROM_SIDEBAR,
    TOGGLE_LEFT_DETAIL,
    ADD_DATA_POINT_TO_GRAPH,
    REMOVE_DATA_POINT_FROM_GRAPH,
    GET_USERS_PENDING,
    GET_USERS_FULFILLED,
    GET_USERS_REJECTED,
    GET_USER_PENDING,
    GET_USER_REJECTED,
    GET_USER_FULFILLED,
    SET_VERSION,
    INCREASE_SUMMARY_YEAR,
    DECREASE_SUMMARY_YEAR,
    CHECK_SSO_PENDING,
    CHECK_SSO_FULFILLED,
    CHECK_SSO_REJECTED,
    UPDATE_USER_PENDING,
    UPDATE_USER_FULFILLED,
    UPDATE_USER_REJECTED,
} from "../actions/UserActions";

import { CLEAR_REDUCERS } from "../actions/GeneralActions";

// INITIALIZE STATE
const initialState = {
    user: {},
    companyID: "",
    companyName: "",
    userEmail: "",
    loggedIn: false,
    loggingIn: false,
    loggingOut: false,
    shouldUseSSO: true,
    checkingSSOPending: false,
    checkingSSOFulfilled: false,
    checkingSSOFailed: false,
    message: "",
    failed: false,
    sidebarOpen: true,
    pinned: {},
    leftDetailOpen: true,
    dataPointsToGraph: {},
    fetching: false,
    fetched: false,
    users: [],
    version: 0,
    summaryYear: -1,
    summaryValues: [
        { type: "Net Present Value", format: "dollar" },
        { type: "Internal Rate of Return", format: "percentage" },
    ],
};

// REDUCER
export const UserReducer = (state = initialState, action) => {
    let pinned = Object.assign({}, state.pinned);
    let dataPointsToGraph = Object.assign({}, state.dataPointsToGraph);

    switch (action.type) {
        case CLEAR_REDUCERS:
            return {
                ...initialState,
                version: state.version,
            };
        case INCREASE_SUMMARY_YEAR:
            return {
                ...state,
                summaryYear: state.summaryYear + 1,
            };
        case DECREASE_SUMMARY_YEAR:
            return {
                ...state,
                summaryYear: state.summaryYear - 1,
            };
        case SET_VERSION:
            return {
                ...state,
                version: action.payload,
            };
        case LOGIN_USER_PENDING:
            return {
                ...state,
                loggingIn: true,
                loggedIn: false,
                failed: false,
                message: "",
            };
        case LOGIN_USER_FULFILLED:
            return {
                ...state,
                loggingIn: false,
                loggedIn: true,
                failed: false,
                message: "",
            };
        case LOGIN_USER_REJECTED:
            return {
                ...state,
                loggedIn: false,
                loggingIn: false,
                failed: true,
                message: action.payload.message,
            };
        case LOGOUT_USER_PENDING:
            return {
                ...state,
                loggingOut: true,
                failed: false,
                message: "",
            };
        case LOGOUT_USER_FULFILLED:
            return {
                ...state,
                user: {},
                companyID: "",
                loggingOut: false,
                loggedIn: false,
                failed: false,
                message: "",
            };
        case LOGOUT_USER_REJECTED:
            return {
                ...state,
                loggingOut: false,
                message: action.payload.message,
                failed: true,
            };
        case TOGGLE_SIDEBAR:
            return {
                ...state,
                sidebarOpen: !state.sidebarOpen,
            };
        case ADD_DATA_POINT_TO_GRAPH:
            dataPointsToGraph[action.payload.dataPoint] = { isPinned: true, format: action.payload.format };

            return {
                ...state,
                dataPointsToGraph,
            };
        case REMOVE_DATA_POINT_FROM_GRAPH:
            delete dataPointsToGraph[action.payload];

            return {
                ...state,
                dataPointsToGraph,
            };
        case PIN_TO_SIDEBAR:
            pinned[action.payload] = true;

            return {
                ...state,
                pinned,
            };
        case UNPIN_FROM_SIDEBAR:
            delete pinned[action.payload];

            return {
                ...state,
                pinned,
            };
        case TOGGLE_LEFT_DETAIL:
            return {
                ...state,
                leftDetailOpen: !state.leftDetailOpen,
            };
        case GET_USERS_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_USERS_FULFILLED:
            let users = [];
            if (action.payload !== undefined) {
                users = Object.keys(action.payload).map((key) => {
                    let object = action.payload[key];
                    object["id"] = key;
                    return object;
                });
            }

            return {
                ...state,
                users,
                fetching: false,
                fetched: true,
                failed: false,
            };
        case GET_USERS_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        case UPDATE_USER_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false
            };
        case UPDATE_USER_FULFILLED:
            return {
                ...state,
                fetching: false,
                fetched: true,
                falied: false
            };
        case UPDATE_USER_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                falied: true
            };
        case GET_USER_FULFILLED:
            let currentUser = action.payload;
            return {
                ...state,
                user: { ...currentUser },
                fetching: false,
                fetched: true,
                failed: false,
            };
        case GET_USER_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        case CHECK_SSO_PENDING:
            return {
                ...state,
                checkingSSOPending: true,
                checkingSSOFulfilled: false,
                checkingSSOFailed: false,
            };
        case CHECK_SSO_FULFILLED:
            return {
                ...state,
                shouldUseSSO: action.payload,
                checkingSSOPending: false,
                checkingSSOFulfilled: true,
                checkingSSOFailed: false,
            };
        case CHECK_SSO_REJECTED:
            return {
                ...state,
                shouldUseSSO: action.payload,
                checkingSSOPending: false,
                checkingSSOFulfilled: false,
                checkingSSOFailed: true,
            };
        default:
            return state;
    }
};
