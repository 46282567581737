import {
    GET_EQUIPMENTS_PENDING,
    GET_EQUIPMENTS_FULFILLED,
    GET_EQUIPMENTS_REJECTED,
    ADD_EQUIPMENT_FULFILLED,
    ADD_EQUIPMENT_PENDING,
    ADD_EQUIPMENT_REJECTED,
    GET_EQUIPMENT_FULFILLED,
    GET_EQUIPMENT_PENDING,
    GET_EQUIPMENT_REJECTED,
    PUT_EQUIPMENT_FULFILLED,
    PUT_EQUIPMENT_PENDING,
    PUT_EQUIPMENT_REJECTED,
    DELETE_EQUIPMENT_FULFILLED,
    DELETE_EQUIPMENT_PENDING,
    DELETE_EQUIPMENT_REJECTED,
} from "../actions/EquipmentActions";

import { SAVE_PRODUCT_GROUP_PENDING, SAVE_PRODUCT_GROUP_FULFILLED, SAVE_PRODUCT_GROUP_REJECTED } from "../actions/ProductGroupActions";

import { CLEAR_REDUCERS, PREPARE_FOR_CONTRACT_SWITCH } from "../actions/GeneralActions";

// INITIALIZE STATE
const initialState = {
    equipment: {},
    equipments: {},
    fetching: false,
    fetched: false,
    failed: false,
    adding: false,
    added: false,
    addFailed: false,
    updating: false,
    updated: false,
    updateFailed: false,
    deleting: false,
    deleted: false,
    deleteFailed: false,
};

// REDUCER
export const EquipmentReducer = (state = initialState, action) => {
    let equipments = {};

    switch (action.type) {
        case CLEAR_REDUCERS:
            return initialState;
        case PREPARE_FOR_CONTRACT_SWITCH:
            return initialState;
        /* GET EQUIPMENTS */
        case GET_EQUIPMENTS_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_EQUIPMENTS_FULFILLED:
            return {
                ...state,
                equipments: action.payload,
                fetching: false,
                fetched: true,
                failed: false,
            };
        case GET_EQUIPMENTS_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        case ADD_EQUIPMENT_PENDING:
            return {
                ...state,
                adding: true,
                added: false,
                addFailed: false,
            };
        case ADD_EQUIPMENT_FULFILLED:
            return {
                ...state,
                adding: false,
                added: true,
                addFailed: false,
            };
        case ADD_EQUIPMENT_REJECTED:
            return {
                ...state,
                adding: false,
                added: false,
                addFailed: true,
            };
        case GET_EQUIPMENT_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_EQUIPMENT_FULFILLED:
            return {
                ...state,
                equipment: action.payload,
                fetching: false,
                fetched: true,
                failed: false,
            };
        case GET_EQUIPMENT_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        case PUT_EQUIPMENT_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
                updatedFailed: false,
            };
        case PUT_EQUIPMENT_FULFILLED:
            let newEquipment = action.payload;
            equipments = Object.assign({}, state.equipments);
            equipments[newEquipment.id] = newEquipment;

            return {
                ...state,
                equipment: newEquipment,
                equipments: equipments,
                updating: false,
                updated: true,
                updatedFailed: false,
            };
        case PUT_EQUIPMENT_REJECTED:
            return {
                ...state,
                updating: false,
                updated: false,
                updatedFailed: true,
            };
        case DELETE_EQUIPMENT_PENDING:
            return {
                ...state,
                deleting: true,
                delete: false,
                deleteFailed: false,
            };
        case DELETE_EQUIPMENT_FULFILLED:
            let deletedIds = action.payload;
            let equipment = Object.assign({}, state.equipment);
            equipments = Object.assign({}, state.equipments);

            deletedIds.forEach((id) => {
                delete equipments[id];
            });

            if (deletedIds.includes(state.equipment.id)) {
                equipment = {};
            }

            return {
                ...state,
                equipment: equipment,
                equipments: equipments,
                deleting: false,
                delete: true,
                deleteFailed: false,
            };
        case DELETE_EQUIPMENT_REJECTED:
            return {
                ...state,
                deleting: false,
                delete: false,
                deleteFailed: true,
            };
        default:
            return state;
    }
};
