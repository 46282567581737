import { GET_TEMPLATES_PENDING, GET_TEMPLATES_FULFILLED, GET_TEMPLATES_REJECTED, PUT_TEMPLATE_PENDING, PUT_TEMPLATE_FULFILLED, PUT_TEMPLATE_FAILED, DELETE_TEMPLATE_PENDING, DELETE_TEMPLATE_FULFILLED, DELETE_TEMPLATE_FAILED } from "../actions/TemplateActions";

const initialState = {
    templates: {},
    fetchingTemplates: false,
    fetchedTemplates: false,
    failedTemplates: false,
    addingTemplate: false,
    addedTemplate: false,
    addTemplateFailed: false,
    deletingTemplates: false,
    deletedTemplates: false,
    deleteFailed: false,
};

import { CLEAR_REDUCERS, PREPARE_FOR_CONTRACT_SWITCH } from "../actions/GeneralActions";

export const TemplateReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_REDUCERS:
            return initialState;
        case PREPARE_FOR_CONTRACT_SWITCH:
            return initialState;
        case GET_TEMPLATES_PENDING:
            return {
                ...state,
                fetchingTemplates: true,
                fetchedTemplates: false,
                failedTemplates: false,
            };
        case GET_TEMPLATES_FULFILLED:
            return {
                ...state,
                templates: action.payload,
                fetchingTemplates: false,
                fetchedTemplates: true,
                failedTemplates: false,
            };
        case GET_TEMPLATES_REJECTED:
            return {
                ...state,
                fetchingTemplates: false,
                fetchedTemplates: false,
                failedTemplates: true,
            };
        case PUT_TEMPLATE_PENDING:
            return {
                ...state,
                addingTemplate: true,
                addedTemplate: false,
                addTemplateFailed: false,
            };
        case PUT_TEMPLATE_FULFILLED:
            return {
                ...state,
                addingTemplate: false,
                addedTemplate: true,
                addTemplateFailed: false,
            };
        case PUT_TEMPLATE_FAILED:
            return {
                ...state,
                addingTemplate: false,
                addedTemplate: false,
                addTemplateFailed: true,
            };
        case DELETE_TEMPLATE_PENDING:
            return {
                ...state,
                deletingTemplates: true,
                deletedTemplates: false,
                deleteFailed: false,
            };
        case DELETE_TEMPLATE_FULFILLED:
            let templates = Object.assign({}, state.templates);
            console.log("Templates before...", state.templates);

            let deletedTemplates = action.payload;

            deletedTemplates.forEach((templateId) => {
                delete templates[templateId];
            });

            console.log("Templates now...", templates);

            return {
                ...state,
                templates,
                deletingTemplates: false,
                deletedTemplates: true,
                deleteFailed: false,
            };
        case DELETE_TEMPLATE_FAILED:
            return {
                ...state,
                deletingTemplates: false,
                deletedTemplates: false,
                deleteFailed: true,
            };
        default:
            return state;
    }
};
