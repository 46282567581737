// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";

// ACTIONS
import { getScenarioMaterialGroupDataPoints } from "../../../../state/actions/SummaryActions";
import { deleteProductGroups, setMaterialOrdering, resetMaterialOrdering, getProductGroup } from "../../../../state/actions/ProductGroupActions";
import { saveContractCustomConditions } from "../../../../state/actions/ContractActions";

// OTHER IMPORTS
import format from "../../../../../helpers/formatter";
import ErrorPopup from "../../../../containers/layout/ErrorPopup";
import { Popover } from "../../../../containers/layout/Popover";
import { HeaderAction } from "../../../../containers/table/HeaderAction";
import { Checkbox } from "../../../../containers/inputs/Checkbox";
import DataCell from "./DataCell";
import Modal from "../../../../containers/layout/Modalised";
import { Conditionaliser } from "../../../../../modules/conditionaliser/Conditionaliser";
import { Loading } from "../../../../containers/loading/Loading";
import FeatherIcon from "feather-icons-react";

// COMPONENT
import { ResizableBox } from "react-resizable";
import { isNullOrUndefined } from "util";

let shiftKeyDown = false;
let cellRefs = {};
let selectedRefs = [];
let columnActivationMap = [];

class DataTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rows: props.rows,
            // have to maintain second rows object called received rows to represent what has been passed down from the parent
            receivedRows: props.rows,
            groups: props.groups,
            editing: false,
            columnGroups: props.columnGroups,
            dataPoints: props.dataPoints === undefined ? {} : props.dataPoints,
            error: undefined,
            hoveredColumn: -1,
            conditionalPopoverShowing: false,
            editingCell: [],
            popoverPosition: { x: 0, y: 0 },
            activeRowIndex: 0,
            activeDataIdentifier: undefined,
            itemsToDelete: [],
            conditionalLoaderShowing: false,
            loading: false,
            headerActionVisible: false,
            handleHeaderAction: {},
            headerActionLabel: "",
        };

        this.handleSaveCustomConditions = this.handleSaveCustomConditions.bind(this);

        this.determineEditableColumns = this.determineEditableColumns.bind(this);
        this.getNextEditableColumn = this.getNextEditableColumn.bind(this);
        this.getPreviousEditableColumn = this.getPreviousEditableColumn.bind(this);
        this.editableColumns = this.determineEditableColumns();

        this.updateRow = this.updateRow.bind(this);
        this.updateTableData = this.updateTableData.bind(this);
        this.toggleClicked = this.toggleClicked.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);

        this.toggleTypePopover = this.toggleTypePopover.bind(this);
        this.handleToggleDelete = this.handleToggleDelete.bind(this);
        this.showConditionalPopover = this.showConditionalPopover.bind(this);

        this.getActiveDiscountTypeKey = this.getActiveDiscountTypeKey.bind(this);
        this.getActiveDiscountType = this.getActiveDiscountType.bind(this);

        this.saveCellConditions = this.saveCellConditions.bind(this);
        this.onDrag = this.onDrag.bind(this);
        this.conditionaliser = null;

        this.getDiscountType = this.getDiscountType.bind(this);
        this.getDiscountTypeCode = this.getDiscountTypeCode.bind(this);

        this.dragEnded = this.dragEnded.bind(this);

        this.handleHeaderClick = this.handleHeaderClick.bind(this);

        this.handleAutoAllocate = this.handleAutoAllocate.bind(this);
        this.handlePaymentFrequency = this.handlePaymentFrequency.bind(this);
        this.getUnitOfMeasurementOptions = this.getUnitOfMeasurementOptions.bind(this);
        this.handleUnitOfMeasurement = this.handleUnitOfMeasurement.bind(this);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.compareRows = this.compareRows.bind(this);
        this.sortRows = this.sortRows.bind(this);
        this.updateCells = this.updateCells.bind(this);
        this.updateRows = this.updateRows.bind(this);
        this.compareRows = this.compareRows.bind(this);

        this.sortAscending = false;
    }

    componentDidMount() {
        this.updateTableData(this.state.rows);
        this.props.onRef(this);
        this.updateRows();

        /*
            Generate a column map to determine which columns are able to be edited, and which ones aren't,
            to speed up the process of handling the arrow keys to move around the table.
        */
        columnActivationMap = this.props.groups.flatMap((group) => {
            return group.dataPoints.map((dataPoint) => {
                return dataPoint.disabled ? dataPoint.disabled : false;
            });
        });

        // Set global bindings...
        document.addEventListener("keydown", this.handleKeyDown, false);
        document.addEventListener("keyup", this.handleKeyUp, false);
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        // Remove global bindings...
        document.removeEventListener("keydown", this.handleKeyDown, false);
        document.removeEventListener("keyup", this.handleKeyUp, false);
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    /**
     * Inspects the DataTable groups to determine which columns are editable.
     *
     * Returns an array of integers representing DataTable columns
     */
    determineEditableColumns() {
        let columns = [];
        let columnIndex = 0;

        this.props.groups.forEach((group) => {
            group.dataPoints.forEach((dataPoint) => {
                if (dataPoint.editable) {
                    columns.push(columnIndex);
                }

                columnIndex++;
            });
        });

        return columns;
    }


    handleSaveCustomConditions(conditions) {
        this.setState({ messageActive: true, messageLoading: true, message: "Saving Custom Conditions" });
        this.props.saveContractCustomConditions(this.props.match.params.contractId, conditions, (success) => {
            this.setState({ messageActive: false, messageLoading: false });
        });
    }

    /**
     *
     * @param {number} currentColumn Current column selected
     * @returns {number} Next column to be selected, or -1 if there is no next column
     */
    getNextEditableColumn(currentColumn) {
        let nextColumn = -1;
        this.editableColumns.forEach((column, columnIndex) => {
            if (column === currentColumn) {
                if (columnIndex + 1 < this.editableColumns.length) {
                    nextColumn = this.editableColumns[columnIndex + 1];
                    return;
                } else {
                    return;
                }
            }
        });

        return nextColumn;
    }

    /**
     *
     * @param {number} currentColumn Current column selected
     * @returns {number} Next column to be selected, or -1 if there is no next column
     */
    getPreviousEditableColumn(currentColumn) {
        let nextColumn = -1;
        this.editableColumns.forEach((column, columnIndex) => {
            if (column === currentColumn) {
                if (columnIndex - 1 > -1) {
                    nextColumn = this.editableColumns[columnIndex - 1];
                    return;
                } else {
                    return;
                }
            }
        });

        return nextColumn;
    }

    handleKeyUp() {
        shiftKeyDown = false;
    }

    /**
     * Set the wrapper ref, so that we can detect when the table is clicked outside of, in order to de-select
     * the active cells.
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * De-select the active cell if clicked on outside of table.
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            Object.keys(selectedRefs).map((refKey) => {
                let cellRef = selectedRefs[refKey];

                if (cellRef.ref.current) {
                    let dataCell = cellRef.ref.current.parentNode.parentNode.parentNode;
                    dataCell.classList.remove("active");
                }
            });

            this.setNewActiveCell(this.state.activeCell);
            this.setState({ activeCell: undefined });
        }
    }

    /**
     * Determines if a position is within the bounds of the table
     *
     * @param {*} newRowIndex The index of the row the table is attempting to move to
     * @param {*} newColumnIndex The index of the column the table is attempting to move to
     */
    withinBounds(newRowIndex, newColumnIndex) {
        let result = false;
        let rowBound = this.state.rows.length;
        let validColumns = this.editableColumns;

        // Check the row is not beyond the rowBound nor less than 0
        if (newRowIndex < rowBound && newRowIndex >= 0) {
            // Check the column is one of the valid columns
            if (validColumns.includes(newColumnIndex)) {
                result = true;
            }
        }

        return result;
    }

    /**
     * Handle a keyDown event.
     *
     * This is used for performing switching of cells with the arrow keys, tabbing and entering to the next cells.
     *
     * @param event - the keyDown event.
     */
    handleKeyDown(event) {
        let activeCell = Object.assign({}, this.state.activeCell);

        if (this.state.editingCell.length > 0 || this.state.conditionalPopoverShowing) {
            // Don't override handle key press while a cell is being edited directly.
            return;
        }

        /* Escape Key Pressed */
        if (event.keyCode === 27) {
            this.setNewActiveCell();
            return;
        }

        let disabled = false;
        /* Directional Keys Pressed */
        if ([40, 39, 38, 37, 13, 9].includes(event.keyCode)) {
            let keyCode = event.keyCode;

            if (keyCode === 13) {
                if (shiftKeyDown) {
                    keyCode = 38;
                } else {
                    keyCode = 40;
                }
            } else if (keyCode === 9) {
                if (shiftKeyDown) {
                    keyCode = 37;
                } else {
                    keyCode = 39;
                }
            }

            event.preventDefault();

            // Revert to only using one cell...
            if ([40, 38].includes(keyCode)) {
                if (keyCode === 40) {
                    activeCell.r += 1;
                } else if (keyCode === 38) {
                    activeCell.r -= 1;
                }
            } else {
                if (keyCode === 39) {
                    // Shift left
                    let nextColumn = this.getNextEditableColumn(activeCell.c);
                    disabled = columnActivationMap[nextColumn];

                    if (!disabled) activeCell.c = nextColumn;
                } else {
                    // Shift right
                    let previousColumn = this.getPreviousEditableColumn(activeCell.c);
                    disabled = columnActivationMap[previousColumn];

                    if (!disabled) activeCell.c = previousColumn;
                }

                activeCell.dataIdentifier = this.getColumnDataIdentifier(activeCell.c);
            }

            // Check that the new active cell is within the bounds of the table.
            if (this.withinBounds(activeCell.r, activeCell.c)) {
                this.setNewActiveCell(activeCell);
            } else if (activeCell.r === this.state.rows.length) {
                // Next cell would go below the table, shift to the top of the next column if possible
                activeCell.r = 0;
                activeCell.c = this.getNextEditableColumn(activeCell.c);

                if (this.withinBounds(activeCell.r, activeCell.c)) {
                    this.setNewActiveCell(activeCell);
                }
            } else if (activeCell.r === -1) {
                // Next cell would go above the table, shift to the top of the previous column if possble
                activeCell.r = this.state.rows.length - 1;
                activeCell.c = this.getPreviousEditableColumn(activeCell.c);

                if (this.withinBounds(activeCell.r, activeCell.c)) {
                    this.setNewActiveCell(activeCell);
                }
            }
        }

        /* Shift Key Pressed */
        if (event.keyCode === 16) {
            shiftKeyDown = true;
        }

        if (this.state.activeCell) {
            let firstActiveCell = this.state.activeCell;
            let firstActiveCellIdentifier = `R${firstActiveCell.r}D${firstActiveCell.dataIdentifier}`;
            let activeCellRef = cellRefs[firstActiveCellIdentifier];

            let disableFocusChange = [40, 39, 38, 37, 13, 9];

            // If there is an input at this index.
            if (activeCellRef.ref.current && !this.state.conditionalPopoverShowing && !disableFocusChange.includes(event.keyCode)) {
                if (activeCellRef.ref.current !== document.activeElement) {
                    activeCellRef.cell.current.updateCellValue("");
                    activeCellRef.ref.current.focus();
                }
            }
        }
    }

    updateCellValue(dataIdentifier, rowIndex, data, conditionalValue) {
        console.log(conditionalValue);
        let { value, discountType } = data;
        let rows = Object.assign([], this.state.rows);
        let row = rows[rowIndex];

        if (this.isDiscount(dataIdentifier)) {
            if (!row.discounts.hasOwnProperty(dataIdentifier)) {
                row.discounts[dataIdentifier] = {};
            }

            row.discounts[dataIdentifier].fixedValue = value;
            row.discounts[dataIdentifier].numericValue = value;

            // If the cell that we are copying is not a discount itself, then we have no discount type to copy over.
            if (discountType) {
                row.discounts[dataIdentifier].type = discountType;
            }

            if (conditionalValue) {
                row.discounts[dataIdentifier].active = true;
                row.discounts[dataIdentifier].conditions = conditionalValue;
            }
        } else {
            row.dataPoints[dataIdentifier] = value;
        }

        return row;
    }

    /**
     * Fetch calculated data points for the table.
     * @param {Array} rows - the rows for which to retrieve calculated data points for.
     */
    updateTableData(rows) {
        let groups = Object.assign([], this.state.groups);
        let derivedDataPoints = groups.filter((group) => {
            return group.active;
        });

        let dataPointsPromise = new Promise((resolve, reject) => {
            this.props.getScenarioMaterialGroupDataPoints(this.props.match.params.contractId, this.props.match.params.scenarioId, rows, derivedDataPoints, resolve, reject);
        });

        Promise.all([dataPointsPromise])
            .then((data) => { })
            .catch((error) => {
                this.setState({ error: error });

                setTimeout(
                    function () {
                        this.setState({ error: undefined });
                    }.bind(this),
                    3000
                );
            });
    }

    componentDidUpdate() {
        if (JSON.stringify(this.props.rows) !== JSON.stringify(this.state.receivedRows)) {
            // By maintaining the receivedRows object separately to rows (which gets updated based on necessary ordering) we can monitor the changes in props.rows from the parent
            this.setState({ receivedRows: this.props.rows });
            // Need to make sure the rows are ordered correctly
            this.updateRows();
        }

        if (JSON.stringify(this.props.columns) !== JSON.stringify(this.state.columns)) {
            this.setState({ columns: this.props.columns });
        }

        if (JSON.stringify(this.props.columnGroups) !== JSON.stringify(this.state.columnGroups)) {
            this.setState({ columnGroups: this.props.columnGroups });
        }

        if (JSON.stringify(this.props.groups) !== JSON.stringify(this.state.groups)) {
            this.setState({ groups: this.props.groups });
        }

        if (JSON.stringify(this.props.dataPoints) !== JSON.stringify(this.state.dataPoints)) {
            this.setState({ dataPoints: this.props.dataPoints });
        }
    }

    updateRow(value, rowIndex, dataIdentifier, callback) {
        let rows = Object.assign([], this.state.rows);
        let row = rows[rowIndex];

        if (this.isDiscount(dataIdentifier)) {
            if (value < 0) value = 0;
            if (!row.discounts) {
                row.discounts = {};
            }

            if (!row.discounts[dataIdentifier]) {
                row.discounts[dataIdentifier] = {};
            }

            // If the row doesn't have any discount type attached yet.
            if (!row.discounts[dataIdentifier].hasOwnProperty("type")) {
                let discountType = this.getDiscountType(dataIdentifier, rowIndex);
                row.discounts[dataIdentifier].type = discountType;
            }

            row.discounts[dataIdentifier].fixedValue = value;
            row.discounts[dataIdentifier].numericValue = value;
        } else {
            row.dataPoints[dataIdentifier] = value;
        }

        this.setState({ rows, changed: true }, () => {
            if (callback) {
                callback(row);
            }
        });
    }

    isDiscount(dataIdentifier) {
        /* Determine if this cell is a discount cell... */
        if (["flatDiscount", "rebates", "paPerCase"].includes(dataIdentifier)) {
            return true;
        }

        return false;
    }

    toggleTypePopover(e, rowIndex, dataIdentifier) {
        this.setState({ typePopoverShowing: !this.state.typePopoverShowing, activeRowIndex: rowIndex, typePopoverDataIdentifier: dataIdentifier, typePopoverPosition: { x: e.clientX, y: e.clientY } });
    }

    range(start, end) {
        let temp = 0;
        if (start > end) {
            temp = start;
            start = end;
            end = temp;
        }

        return Array(end - start + 1)
            .fill()
            .map((_, idx) => start + idx);
    }

    getColumnDataIdentifier(column) {
        // Have to figure out the groupIndex and dataIdentifier at this position.
        let columnCount = 0;
        let dataPoint;

        for (let i = 0; i < this.state.groups.length; i++) {
            let group = this.state.groups[i];

            for (let j = 0; j < group.dataPoints.length; j++) {
                if (columnCount === column) {
                    dataPoint = group.dataPoints[j];
                    return dataPoint.dataIdentifier;
                }

                columnCount += 1;
            }
        }
    }

    toggleClicked(rowIndex, columnIndex, isActive, groupIndex, dataIdentifier) {
        if (this.state.conditionalPopoverShowing) {
            return;
        }

        if (!isActive) {
            this.setNewActiveCell({ r: rowIndex, c: columnIndex, dataIdentifier, groupIndex });
        }
    }

    savePreviousCell(previousCell) {
        if (previousCell) {
            if (Object.keys(previousCell).length > 0) {
                let cellRefKey = `R${previousCell.r}D${previousCell.dataIdentifier}`;
                let row = Object.assign({}, this.state.rows[previousCell.r]);
                let cell = cellRefs[cellRefKey].cell.current;

                if (!cell) {
                    console.log("ERROR: Couldn't save cell.");
                    return;
                }

                cell.save(() => {
                    this.props.handleSave([row], () => {
                        this.updateTableData([row]);
                        this.setState({ changed: false, typePopoverShowing: false });
                    });
                });
            }
        }
    }

    /**
     * Save the previously selected cells.
     *
     * This is commonly used when toggling away from a cell, and wanting to save the input to the previous cell.
     */
    setNewActiveCell(newActiveCell) {
        // Firstly save the previous cell if saving is required.
        this.savePreviousCell(this.state.activeCell);

        // Then update the active cell
        this.setState({ activeCell: newActiveCell });
    }

    /**
     * Set the type of the discount for the cell, retrived from the discount type popover.
     *
     * @param {String} type - the type of the discount (i.e. dollar, percentage, fixedPrice)
     * @param {String} dataIdentifier - the type of the cell (i.e. MG1, MG1Desc)
     */
    setDiscountType(type, dataIdentifier) {
        let rows = Object.assign([], this.state.rows);
        let row = rows[this.state.activeRowIndex];
        let discount = row.discounts[dataIdentifier];

        if (!discount) {
            discount = {};
        }

        discount.type = type;

        // Save the change in discount type...
        this.props.handleSave([row], () => {
            this.updateTableData([row]);
            this.setState({ changed: false, typePopoverShowing: false });
        });
    }

    showConditionalPopover(e, conditions, rowIndex, dataIdentifier) {
        e.preventDefault();

        if (conditions) {
            if (Object.keys(conditions).length === 0) {
                conditions = [];
            }
        } else {
            conditions = [];
        }

        this.setState({ activeRowIndex: rowIndex, activeDataIdentifier: dataIdentifier, conditionalPopoverShowing: !this.state.conditionalPopoverShowing, popoverPosition: { x: e.clientX, y: e.clientY }, conditions });
    }

    calculateColumnIndex(groupIndex, groupDataPointIndex) {
        let columnIndex = 0;
        this.state.groups.forEach((group, i) => {
            if (i < groupIndex) columnIndex += group.dataPoints.length;
        });

        columnIndex += groupDataPointIndex;
        return columnIndex;
    }

    generateValueRange(expressions) {
        let potentialValues = [0];
        if (expressions) {
            expressions.forEach((expression) => {
                expression.forEach((statement) => {
                    if (statement.hasOwnProperty("isResult")) {
                        if (statement.isResult) {
                            potentialValues.push(statement.components[2].value);
                        }
                    }
                });
            });
        }

        let maxValue = Math.max(...potentialValues);
        let label = maxValue;
        return { fixedValue: label, numericValue: maxValue };
    }

    getDiscountTypeCode(dataIdentifier, type) {
        if (dataIdentifier === "flatDiscount") {
            switch (type) {
                case "dollar": {
                    return "OP14";
                }
                case "fixedPrice": {
                    return "OP13";
                }
                case "percent": {
                    return "OP12";
                }
            }
        } else if (dataIdentifier === "rebates") {
            switch (type) {
                case "dollar": {
                    return "OP11";
                }
                case "percent": {
                    return "OP17";
                }
            }
        } else if (dataIdentifier === "paPerCase") {
            switch (type) {
                case "dollar": {
                    return "OP15";
                }
                case "percent": {
                    return "OP16";
                }
            }
        }
    }

    getDiscountType(dataIdentifier, rowIndex) {
        let discountType = dataIdentifier;

        if (this.isDiscount(dataIdentifier)) {
            discountType = this.getActiveDiscountType(rowIndex, dataIdentifier);
        }

        return discountType;
    }

    getConditionalValue(dataIdentifier, rowIndex) {
        let conditionalValue = null;
        let row = this.state.rows[rowIndex];
        let discounts = row.discounts;
        if (discounts[dataIdentifier].hasOwnProperty("conditions")) {
            return discounts[dataIdentifier].conditions;
        }
        return conditionalValue;
    }

    getActiveDiscountTypeKey(rowIndex, dataIdentifier) {
        let activeDiscountType = this.getActiveDiscountType(rowIndex, dataIdentifier);

        if (activeDiscountType === "percent") {
            return "p";
        } else if (activeDiscountType === "dollar") {
            return "d";
        } else if (activeDiscountType === "fixedPrice") {
            return "f";
        } else {
            // Default to percentage...
            return "p";
        }
    }

    getActiveDiscountType(rowIndex, dataIdentifier) {
        let row = this.state.rows[rowIndex];
        let discounts = row.discounts;

        if (discounts[dataIdentifier]) {
            if (discounts[dataIdentifier].hasOwnProperty("type")) {
                return discounts[dataIdentifier].type;
            } else {
                // Default to percentage.
                return "percent";
            }
        }
    }

    /**
     * Save the conditions of the cell.
     */
    saveCellConditions() {
        if (this.conditionaliser !== null) {
            let activeRowIndex = this.state.activeRowIndex;
            let activeDataIdentifier = this.state.activeDataIdentifier;

            let rows = Object.assign([], this.state.rows);
            let row = rows[activeRowIndex];

            let expressions = JSON.parse(this.conditionaliser.getExpressions());
            let valueRange = this.generateValueRange(expressions);

            if (this.isDiscount(activeDataIdentifier)) {
                let discountType = this.getDiscountType(activeDataIdentifier, this.state.activeRowIndex);

                if (!row.discounts.hasOwnProperty(discountType)) {
                    row.discounts[discountType] = {};
                }

                let discountCode = this.getDiscountTypeCode(activeDataIdentifier, discountType);

                row.discounts[activeDataIdentifier].active = true;
                row.discounts[activeDataIdentifier].conditions = expressions;
                row.discounts[activeDataIdentifier].type = discountType;
                row.discounts[activeDataIdentifier].code = discountCode;

                row.discounts[activeDataIdentifier].fixedValue = valueRange.label;
                row.discounts[activeDataIdentifier].numericValue = valueRange.numericValue;

                this.setState({ conditionalLoaderShowing: true });
                this.props.handleSave([row], (success) => {
                    this.setState({ changed: false, conditionalLoaderShowing: false, conditionalPopoverShowing: false });
                    this.updateTableData([row]);
                });
            }
        }
    }

    onLoad() {
        if (this.conditionaliser !== null) {
            let activeDataIdentifier = this.state.activeDataIdentifier;

            let rows = Object.assign([], this.state.rows);

            if (this.isDiscount(activeDataIdentifier)) {

                this.setState({ conditionalLoaderShowing: true });
                this.props.handleSave(rows, (success) => {
                    this.setState({ changed: false, conditionalLoaderShowing: false, conditionalPopoverShowing: false });
                    this.updateTableData(rows);
                });
            }
        }
    }

    /**
     * Handle the deletion of a cell's conditions.
     */
    deleteCellCondition() {
        if (this.conditionaliser !== null) {
            let activeRowIndex = this.state.activeRowIndex;
            let activeDataIdentifier = this.state.activeDataIdentifier;

            let rows = Object.assign([], this.state.rows);
            let row = rows[activeRowIndex];

            if (this.isDiscount(activeDataIdentifier)) {
                let discountType = this.getDiscountType(activeDataIdentifier, this.state.activeRowIndex);

                row.discounts[discountType] = {};

                let discountCode = this.getDiscountTypeCode(activeDataIdentifier, discountType);

                row.discounts[discountType].fixedValue = 0;
                row.discounts[discountType].numericValue = 0;
                row.discounts[discountType].type = discountCode;
                row.discounts[activeDataIdentifier].active = false;

                this.props.handleSave([row], (success) => {
                    this.setState({ changed: false, conditionalPopoverShowing: false });
                    this.updateTableData([row]);
                });
            }
        }
    }

    handleToggleDelete(rowIndex) {
        let itemsToDelete = Object.assign([], this.state.itemsToDelete);

        if (itemsToDelete.includes(rowIndex)) {
            // Remove from items to delete...
            var index = itemsToDelete.indexOf(rowIndex);
            if (index > -1) {
                itemsToDelete.splice(index, 1);
            }
        } else {
            // Add to items to delete...
            itemsToDelete.push(rowIndex);
        }

        this.handleRemoveButton(itemsToDelete);
        this.setState({ itemsToDelete });
    }

    handleRemoveButton(itemsToDelete) {
        // Enable the remove button if there are items to remove...
        if (itemsToDelete.length > 0) {
            this.props.enableRemove();
        } else {
            this.props.disableRemove();
        }
    }

    handleDelete() {
        // Get the IDs of the product groups to delete.
        let productGroupsToDelete = this.state.itemsToDelete.map((rowIndex) => {
            return this.state.rows[rowIndex].productGroupId;
        });

        // Empty the items to delete...
        this.setState({ itemsToDelete: [] });
        this.props.deleteProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, productGroupsToDelete, (success) => {
            this.props.disableRemove();
        });
    }

    selectAllToDelete() {
        if (this.state.itemsToDelete.length === this.state.rows.length) {
            this.handleRemoveButton([]);
            this.setState({ itemsToDelete: [] });
            return;
        }

        let allProductGroupIds = this.state.rows.map((row, index) => {
            return index;
        });
        this.handleRemoveButton(allProductGroupIds);
        this.setState({ itemsToDelete: allProductGroupIds });
    }

    /**
     * Updates the rows to reflect the ordering defined by props.materialOrdering
     */
    updateRows() {
        let orderedRows = [];

        // Use the current defined order for the materials to update rows into correct order
        if (this.props.materialOrdering.length !== 0) {
            this.props.materialOrdering.forEach((mg1, orderingIndex) => {
                // Get the row that has the matching MG1
                let row = this.props.rows.filter((row) => {
                    return row.dataPoints.MG1 === mg1;
                });

                orderedRows[orderingIndex] = row[0];
            });
        } else {
            orderedRows = this.props.rows;
        }

        this.setState(
            {
                rows: orderedRows,
            },
            () => {
                this.updateCells(orderedRows);
            }
        );
    }

    /**
     * Custom comparison function to compare a particular value of a row for sorting purposes
     *
     * @param { Object } a Left hand Row object for comparison
     * @param { Object } b Right hand Row object for comparison
     * @param { String } key A string representing the value to compare on for each row
     */
    compareRows(a, b) {
        let comparator = this.currentComparator;

        // try access the values from row.dataPoints (non-calculated values)
        let left = a.dataPoints[comparator];
        let right = b.dataPoints[comparator];

        // try access the values from props.dataPoints (calculated values)
        if (left === undefined && right === undefined) {
            left = this.props.dataPoints[a.dataPoints["MG1"]][comparator];
            right = this.props.dataPoints[b.dataPoints["MG1"]][comparator];
        }

        // try access the values from row.discounts
        if (left === undefined && right === undefined) {
            if (a.discounts.hasOwnProperty(comparator)) {
                left = a.discounts[comparator].numericValue;
            }

            if (b.discounts.hasOwnProperty(comparator)) {
                right = b.discounts[comparator].numericValue;
            }
        }

        if (typeof left === "boolean" || typeof right === "boolean") {
            left = left === true ? 1 : 0;
            right = right === true ? 1 : 0;
        }

        // If we could not get a value, set it to 0
        left = left === undefined ? 0 : left;
        right = right === undefined ? 0 : right;

        if (this.sortAscending) {
            let temp = left;
            left = right;
            right = temp;
        }

        if (left < right) {
            if (typeof left == "string") {
                return -1;
            } else if (typeof right == "number") {
                return 1;
            }
        }

        if (left > right) {
            if (typeof left == "string") {
                return 1;
            } else if (typeof right == "number") {
                return -1;
            }
        }

        return 0;
    }

    /**
     * Update all of the editable cells in each row to their correct values
     */
    updateCells(rows) {
        let dataPoints = this.state.groups.flatMap((group) => {
            return group.dataPoints.filter((dataPoint) => {
                return !dataPoint.hasOwnProperty("disabled");
            });
        });

        rows.forEach((row, rowIndex) => {
            dataPoints.forEach((dataPoint) => {
                let cellRef = cellRefs[`R${rowIndex}D${dataPoint.dataIdentifier}`];
                let value = row.dataPoints[dataPoint.dataIdentifier];

                // Could not pull value out of row.dataPoints. Try pull from row.discounts instead
                if (value === undefined) {
                    if (row.discounts[dataPoint.dataIdentifier]) {
                        value = row.discounts[dataPoint.dataIdentifier].numericValue;
                    }
                }

                // update the value for this cell...
                if (value) {
                    cellRef.cell.current.updateCellValue(value);
                } else {
                    cellRef.cell.current.updateCellValue("");
                }
            });
        });
    }

    /**
     * Sort the DataTable rows
     */
    sortRows(comparator) {
        // Sort the rows based on the column that was clicked (identified by groupDataPoint.dataIdentifier)
        // We need to set the identifier for the comparator (e.g. CasePrice, RRP, Profit) so we know what value to sort on
        this.currentComparator = comparator;
        this.sortAscending = !this.sortAscending;
        let sortedRows = this.state.rows.sort(this.compareRows);

        this.setState({ rows: sortedRows }, () => {
            // We need to manually update the editable cells into their new position
            this.updateCells(sortedRows);
        });

        // Update the ordering in the ProductGroupReducer to allow for persistence
        this.props.setMaterialOrdering(sortedRows);
    }

    handleHeaderClick(e, groupDataPoint) {
        if (groupDataPoint.selectable) {
            // The data point column is selectable...
            if (this.state.showHeaderMenu === groupDataPoint.name) {
                this.setState({ showHeaderMenu: undefined, headerMenuPosition: { x: e.clientX, y: e.clientY } });
            } else {
                this.setState({ showHeaderMenu: groupDataPoint.name, headerMenuPosition: { x: e.clientX, y: e.clientY } });
            }
        }

        if (groupDataPoint.orderable) {
            this.sortRows(groupDataPoint.dataIdentifier);
        }
    }

    /**
     * Handle the auto-allocation of payments across materials in the table.
     */
    handleAutoAllocate = () => {
        let rows = Object.assign([], this.state.rows);
        let rowCount = rows.length;
        let rowAllocation = 100 / rowCount;

        rows.forEach((row, rowIndex) => {
            let cellRef = cellRefs[`R${rowIndex}D${"paymentAllocation"}`];
            cellRef.cell.current.updateCellValue(rowAllocation);
            row.dataPoints["paymentAllocation"] = rowAllocation;
        });

        this.setState({ rows: rows }, () => {
            //  Save the change in discount type...
            this.props.handleSave(rows, () => {
                this.updateTableData(rows);
                this.setState({ changed: false });
            });
        });
    };

    handleWeightedAllocation = () => {
        let rows = Object.assign([], this.state.rows);
        let rowCount = rows.length;
        let rowAllocation = 100 / rowCount;

        let count = 0;
        rows.forEach((row, rowIndex) => {
            if (row.dataPoints.Portfolio && row.dataPoints.Portfolio === "Alcohol") {
                count += (row.dataPoints.physicalCases * row.dataPoints.UnitsPerCase) / 5.678;
            } else {
                count += row.dataPoints.physicalCases;
            }
        })

        rows.forEach((row, rowIndex) => {
            let cellRef = cellRefs[`R${rowIndex}D${'paymentAllocation'}`];
            let value = 0;
            if (row.dataPoints.Portfolio && row.dataPoints.Portfolio === "Alcohol") {
                value = (((row.dataPoints.physicalCases * row.dataPoints.UnitsPerCase) / 5.678) / count) * 100;
            } else {
                value = (row.dataPoints.physicalCases / count) * 100;
            }

            cellRef.cell.current.updateCellValue(value);
            row.dataPoints['paymentAllocation'] = value;
        });

        this.setState({ rows: rows }, () => {
            //  Save the change in discount type...
            this.props.handleSave(rows, () => {
                this.updateTableData(rows);
                this.setState({ changed: false });
            });
        });
    };

    getUnitOfMeasurementOptions = (rowNumber) => {
        let row = this.state.rows[rowNumber];
        if (row.dataPoints.BeverageCategory == "Beer and Cider Kegs") return ["Case", "Each", "Syrup Litres", "Hecto Litres"];
        else return ["Case", "Each", "Syrup Litres"];
    }

    handlePaymentFrequency = (frequency, rowIndex) => {
        let rows = Object.assign([], this.state.rows);
        let row = rows[rowIndex];
        let cellRef = cellRefs[`R${rowIndex}D${"paymentFrequency"}`];

        if (frequency === "None") {
            cellRef.cell.current.updateCellValue("");
            row.dataPoints['paymentFrequency'] = "";

        } else {
            cellRef.cell.current.updateCellValue(frequency);
            row.dataPoints['paymentFrequency'] = frequency;
        }

        this.setState({ rows: rows }, () => {
            //  Save the change in discount type...
            this.props.handleSave(rows, () => {
                this.updateTableData(rows);
                this.setState({ changed: false });
            });
        });
    };
    handleUnitOfMeasurement = (frequency, rowIndex) => {
        let rows = Object.assign([], this.state.rows);
        let row = rows[rowIndex];
        let cellRef = cellRefs[`R${rowIndex}D${"unitOfMeasurement"}`];

        if (frequency === "None") {
            cellRef.cell.current.updateCellValue("");
            row.dataPoints['unitOfMeasurement'] = "";

        } else {
            cellRef.cell.current.updateCellValue(frequency);
            row.dataPoints['unitOfMeasurement'] = frequency;
        }

        this.setState({ rows: rows }, () => {
            //  Save the change in discount type...
            this.props.handleSave(rows, () => {
                this.updateTableData(rows);
                this.setState({ changed: false });
            });
        });
    };

    renderHeaderMenuContent = (groupDataPoint) => {
        if (groupDataPoint.name === "Payment Allocation") {
            return (
                <div>
                    <Button onClick={() => this.handleAutoAllocate()}>Auto Allocate Evenly</Button>
                    <Button onClick={() => this.handleAutoAllocate()}>Allocate Based On Units</Button>
                </div>
            );
        }
    };

    handleMouseEnterHeader = (groupDataPoint) => {
        let elementId = groupDataPoint.dataIdentifier;
        let headerCellElement = document.getElementById(elementId);
        let headerActionElement = document.getElementById("headerActionContainerAutoAllocate");

        if (elementId === "paymentAllocation") {
            let headerCellRect = headerCellElement.getBoundingClientRect();

            headerActionElement.style.left = headerCellRect.x + "px";
            headerActionElement.style.top = headerCellRect.y + headerCellRect.height + "px";
            headerActionElement.style.width = headerCellRect.width + "px";

            this.setState({
                headerActionVisible: true,
                handleHeaderAction: [this.handleAutoAllocate, this.handleWeightedAllocation],
                headerActionLabel: ["Even", "Weighted"]
            });
        }
    };

    handleMouseLeaveHeader = (groupDataPoint) => {
        let elementId = groupDataPoint.dataIdentifier;

        if (elementId === "paymentAllocation") {
            setTimeout(() => {
                this.setState({ headerActionVisible: false });
            }, 50);
        }
    };

    renderHeader = (group, groupDataPoint, width, maxWidth) => {
        if (groupDataPoint.type === "delete") {
            return (
                <div style={{ backgroundColor: group.color }} className={"h-material-row delete"}>
                    <Checkbox
                        handleClick={() => {
                            this.selectAllToDelete();
                        }}
                    />
                </div>
            );
        }

        let header = (
            <div
                id={groupDataPoint.dataIdentifier}
                onMouseEnter={(e) => this.handleMouseEnterHeader(groupDataPoint)}
                onMouseLeave={(e) => this.handleMouseLeaveHeader(groupDataPoint)}
                onClick={(e) => this.handleHeaderClick(e, groupDataPoint)}
                className={`h-data-point-heading sub-heading ${groupDataPoint.selectable ? "selectable" : ""} ${groupDataPoint.orderable ? "orderable" : ""} ${group.separator ? "separator" : ""}`}
                style={{ maxWidth: maxWidth, justifyContent: groupDataPoint.align === "right" ? "flex-end" : "center", textAlign: groupDataPoint.align, backgroundColor: group.color }}
            >
                {groupDataPoint.hasDropdownAction && (
                    <Fragment>
                        <div style={{ paddingRight: "1em" }}>{groupDataPoint.name} </div>
                        <FeatherIcon style={{ width: "150%" }} icon="chevron-down" />
                    </Fragment>
                )}

                {!groupDataPoint.hasDropdownAction && <div>{groupDataPoint.name} </div>}
            </div>
        );

        if (this.props.isResizable) {
            return (
                <ResizableBox maxConstraints={[maxWidth, Infinity]} width={width}>
                    {header}
                </ResizableBox>
            );
        } else {
            return header;
        }
    };

    compareValues(comparator, value1, value2) {
        if (comparator === "less" && value1 <= value2) {
            return true;
        }

        if (comparator === "greater" && value1 >= value2) {
            return true;
        }

        return false;
    }

    isHighlighted(groupDataPoint, rowData, dataPoint) {
        let shouldHighlight = false;

        if (groupDataPoint.highlight) {
            if (groupDataPoint.highlight.hasOwnProperty("fixedValue")) {
                return this.compareValues(groupDataPoint.highlight.comparator, dataPoint, groupDataPoint.highlight.fixedValue);
            }

            let dependent = groupDataPoint.highlight.dependent;

            if (dependent && groupDataPoint.calculated) {
                if (this.state.dataPoints) {
                    if (this.state.dataPoints.hasOwnProperty(rowData.dataPoints.MG1)) {
                        let materialDataPoints = this.state.dataPoints[rowData.dataPoints.MG1];

                        if (materialDataPoints.hasOwnProperty(dependent)) {
                            let dependentDataPoint = materialDataPoints[dependent];
                            let comparator = groupDataPoint.highlight.comparator;
                            return this.compareValues(comparator, dataPoint, dependentDataPoint);
                        }
                    }
                }
            }
        }

        return shouldHighlight;
    }

    renderDataCell(row, column, groupData, value, calculatedField, isActive, isCorner, isNumber, lastColumnInGroup) {
        const { rowIndex, rowData, dataPoint } = row;
        const { columnIndex } = column;
        const { group, groupIndex, groupDataPoint, groupDataPointIndex } = groupData;
        return (
            <div
                //                onMouseEnter={(e) => this.handleMouseEnterCell(groupDataPoint, rowIndex)}
                //                onMouseLeave={(e) => this.handleMouseLeaveCell(groupDataPoint)}
                onClick={() => {
                    this.toggleClicked(rowIndex, columnIndex, isActive, groupIndex, groupDataPoint.dataIdentifier);
                }}
                className={
                    `data-table-cell ${this.isHighlighted(groupDataPoint, rowData, dataPoint) ? "highlight" : ""} ${isActive ? " active " : ""}  ${isNumber ? "number" : ""} ${groupDataPoint.bold ? " bold" : ""}` +
                    (group.separator && groupDataPointIndex === group.dataPoints.length - 1 ? "separator " : "") +
                    (groupDataPoint.disabled ? " disabled " : "") +
                    (isActive ? " active " : "") +
                    (this.state.hoveredColumn === columnIndex ? " hovered-column " : "") +
                    groupDataPoint.align +
                    (lastColumnInGroup ? " group-end" : "")
                }
            >
                <div className={groupDataPoint.align + " fullWidth"}>
                    <Fragment>
                        {(groupDataPoint.calculated === undefined && groupDataPoint.disabled === undefined ? false : groupDataPoint.calculated || groupDataPoint.disabled) ? (
                            <Fragment>
                                <div className="calculated-cell float-right">{groupDataPoint.calculated ? calculatedField : format(value, groupDataPoint.format)}</div>
                            </Fragment>
                        ) : (
                            <DataCell
                                isActive={isActive}
                                ref={cellRefs[`R${rowIndex}D${groupDataPoint.dataIdentifier}`].cell}
                                isDiscount={this.isDiscount}
                                showConditionalPopover={this.showConditionalPopover}
                                saveCellConditions={this.saveCellConditions}
                                showingConditionalPopover={this.showingConditionalPopover}
                                hidingConditionalPopover={this.hidingConditionalPopover}
                                handleSave={this.props.handleSave}
                                updateTableData={this.updateTableData}
                                updateRow={this.updateRow}
                                cellRefs={cellRefs}
                                groups={this.state.groups}
                                row={this.state.rows[rowIndex]}
                                rowIndex={rowIndex}
                                value={calculatedField}
                                columnIndex={columnIndex}
                                groupIndex={groupIndex}
                                groupDataPointIndex={groupDataPointIndex}
                                dataIdentifier={groupDataPoint.dataIdentifier}
                                getDiscountType={this.getDiscountType}
                                getDiscountTypeCode={this.getDiscountTypeCode}
                                getActiveDiscountType={this.getActiveDiscountType}
                                getActiveDiscountTypeKey={this.getActiveDiscountTypeKey}
                                toggleTypePopover={this.toggleTypePopover}
                                handlePaymentFrequency={this.handlePaymentFrequency}
                                getUnitOfMeasurementOptions={this.getUnitOfMeasurementOptions}
                                handleUnitOfMeasurement={this.handleUnitOfMeasurement}
                            />
                        )}
                    </Fragment>
                </div>
                {isActive && (
                    <div className="data-table-cell-action bottom">
                        <div draggable="true" onTouchEnd={this.dragEnded} onDragEnd={this.dragEnded} onDrag={this.onDrag} className="cell-extend" />
                    </div>
                )}
            </div>
        );
    }

    renderRow = (row, group) => {
        const { groupIndex, groupDataPoint, groupDataPointIndex } = group;
        const { rowData, rowIndex, isNumber, lastColumnInGroup } = row;

        // If the column is a delete
        if (groupDataPoint.type === "delete") {
            return (
                <div
                    key={rowIndex}
                    onClick={() => {
                        this.handleToggleDelete(rowIndex);
                    }}
                    className={"h-material-row delete bordered"}
                >
                    <Checkbox observe checked={this.state.itemsToDelete.includes(rowIndex)} />
                </div>
            );
        }

        let value = rowData.dataPoints[groupDataPoint.dataIdentifier];

        let columnIndex = this.calculateColumnIndex(groupIndex, groupDataPointIndex);

        // Determine if the cell is active.
        let isActive = this.state.activeCell ? this.state.activeCell.r === rowIndex && this.state.activeCell.c === columnIndex : false;

        /* Create a reference to the input */
        cellRefs[`R${rowIndex}D${groupDataPoint.dataIdentifier}`] = { ref: React.createRef(), cell: React.createRef(), position: { rowIndex, columnIndex, groupIndex, dataIdentifier: groupDataPoint.dataIdentifier } };

        let calculatedField = value;
        let dataPoint = {};

        if (groupDataPoint.calculated) {
            if (this.state.dataPoints) {
                if (this.state.dataPoints.hasOwnProperty(rowData.dataPoints.MG1)) {
                    let materialDataPoints = this.state.dataPoints[rowData.dataPoints.MG1];

                    if (materialDataPoints.hasOwnProperty(groupDataPoint.dataIdentifier)) {
                        dataPoint = materialDataPoints[groupDataPoint.dataIdentifier];
                        calculatedField = format(dataPoint, groupDataPoint.format);
                    }
                }
            }
        }

        return (
            <div>
                <div key={rowIndex} className={"h-material-row" + (rowIndex % 2 === 0 ? " even" : " odd")}>
                    {this.renderDataCell({ rowData, rowIndex, dataPoint }, { columnIndex }, group, value, calculatedField, isActive, isNumber, lastColumnInGroup)}
                </div>
            </div>
        );
    };

    renderDataGroup = (group, groupIndex) =>
        group.dataPoints.map((groupDataPoint, groupDataPointIndex) => {
            let width = groupDataPoint.width;
            let maxWidth = groupDataPoint.maxWidth;

            let isNumber = groupDataPoint.format !== undefined;
            let lastColumnInGroup = groupDataPointIndex >= group.dataPoints.length - 1;

            return (
                <div key={groupDataPointIndex} style={{ maxWidth: maxWidth, opacity: 0.9, backgroundColor: group.color }} className="data-table-column">
                    {this.renderHeader(group, groupDataPoint, width, maxWidth)}

                    {this.state.rows.map((rowData, rowIndex) => {
                        return this.renderRow(
                            { rowData, rowIndex, isNumber, lastColumnInGroup },
                            {
                                groupDataPointIndex,
                                groupDataPoint,
                                group,
                                groupIndex,
                            }
                        );
                    })}
                </div>
            );
        });

    renderTypePopover = () => (
        <Popover
            fixed
            hide={() => {
                this.setState({ typePopoverShowing: false });
            }}
            showing={this.state.typePopoverShowing}
            connectorPosition={this.state.typePopoverPosition}
        >
            <div onClick={() => this.setDiscountType("percent", this.state.typePopoverDataIdentifier)} className="full-width-popover-button">
                <div className="type-selector large p">P</div>
                Percent
            </div>
            <div onClick={() => this.setDiscountType("dollar", this.state.typePopoverDataIdentifier)} className="full-width-popover-button">
                <div className="type-selector large d">D</div>
                Dollar
            </div>
            {this.state.typePopoverDataIdentifier === "flatDiscount" && (
                <div onClick={() => this.setDiscountType("fixedPrice", this.state.typePopoverDataIdentifier)} className="full-width-popover-button">
                    <div className="type-selector large f">F</div>
                    Fixed Price
                </div>
            )}
        </Popover>
    );

    renderConditionalModal = () => (
        <Modal
            footer={
                <div className="conditionaliser-footer">
                    <div
                        className="rg-button destructive"
                        onClick={() => {
                            this.deleteCellCondition();
                        }}
                    >
                        Delete
                    </div>
                    <div
                        className="rg-button"
                        onClick={() => {
                            this.saveCellConditions();
                        }}
                    >
                        Save
                    </div>
                </div>
            }
            title={"Compliance"}
            action={
                <div
                    onClick={() => {
                        this.setState({ conditionalPopoverShowing: false });
                    }}
                    className="text-link"
                >
                    <FeatherIcon icon="x" />
                </div>
            }
            minWidth={800}
            minHeight={800}
            handleClose={() => {
                this.setState({ conditionalPopoverShowing: false });
            }}
            showing={this.state.conditionalPopoverShowing}
            position="bottom center"
        >
            {this.state.conditionalLoaderShowing ? (
                <Loading />
            ) : (
                <Conditionaliser
                    allowResults={true}
                    options={[this.getDiscountTypeCode(this.state.activeDataIdentifier, this.getDiscountType(this.state.activeDataIdentifier, this.state.activeRowIndex))]}
                    onRef={(ref) => (this.conditionaliser = ref)}
                    value={this.state.conditions !== undefined ? this.state.conditions : undefined}
                    customConditions={this.props.customConditions}
                    saveCustomConditions={this.handleSaveCustomConditions}
                    ref={(ref) => (this.conditionaliser = ref)}
                    setSaveButtonState={() => { }}
                    onChange={() => { }}
                    editing={true}
                />
            )}
        </Modal>
    );

    renderHeaderAction = () => <HeaderAction handleAction={this.state.handleHeaderAction} showing={this.state.headerActionVisible} label={this.state.headerActionLabel} id={"headerActionContainerAutoAllocate"} />;

    renderTable = () => (
        <div ref={this.setWrapperRef} className="h-data-point-headings">
            {this.state.groups.map((group, groupIndex) => {
                return (
                    <div key={groupIndex} className={"data-section"}>
                        <div style={{ backgroundColor: group.color }} className={`h-data-point-heading ${group.separator ? "separator" : ""}`}>
                            {group.groupName}{" "}
                        </div>
                        <div className="data-table-column-group">{this.renderDataGroup(group, groupIndex)}</div>
                    </div>
                );
            })}
        </div>
    );

    dragEnded(e) {
        this.onDrag(e);
        let rows = Object.assign([], this.state.rows);

        let activeCell = this.state.activeCell;
        let activeRow = rows[activeCell.r];
        let activeCellRef = cellRefs[`R${activeCell.r}D${activeCell.dataIdentifier}`];

        let value = activeCellRef.cell.current.getValue();
        let discountType = this.getDiscountType(activeCell.dataIdentifier, activeCell.r);
        // let conditionalValue = this.getConditionalValue(activeCell.dataIdentifier, activeCell.r);

        Object.keys(selectedRefs).map((refKey) => {
            let cellRef = selectedRefs[refKey];
            let position = cellRef.position;

            if (cellRef.ref.current) {
                let dataCell = cellRef.ref.current.parentNode.parentNode.parentNode;
                dataCell.classList.remove("active");
            }

            let updatedRow = null;
            updatedRow = this.updateCellValue(position.dataIdentifier, position.rowIndex, { value, discountType });
            cellRef.cell.current.updateCellValue(value);
            rows[position.rowIndex] = updatedRow;
        });

        this.setState({ rows });

        // And persist to the database...
        this.props.handleSave(rows, () => {
            this.updateTableData(rows);
            this.setState({ changed: false, typePopoverShowing: false });
        });
    }

    onDrag(e) {
        shiftKeyDown = true;

        let activeCell = this.state.activeCell;
        let activeCellIdentifier = `R${activeCell.r}D${activeCell.dataIdentifier}`;
        let activeCellRef = cellRefs[activeCellIdentifier].ref.current;
        let activePosition = activeCellRef.getBoundingClientRect();

        let mouseX = e.clientX;
        let mouseY = e.clientY;

        if (mouseX === 0 && mouseY === 0) {
            return;
        }

        let decrementingX = false;
        let decrementingY = false;

        if (mouseX < activePosition.left) {
            decrementingX = true;
        }

        if (mouseY < activePosition.top) {
            decrementingY = true;
        }

        selectedRefs = [];
        Object.keys(cellRefs).forEach((cellRefKey) => {
            let cellRef = cellRefs[cellRefKey];

            if (cellRef.ref.current) {
                const { ref, position } = cellRef;

                let cell = ref.current;
                let dataCell = cell.parentNode.parentNode.parentNode;

                const { left, top, bottom, width, height } = cell.getBoundingClientRect();

                let activeInXDirection = false;
                let activeInYDirection = false;

                if (decrementingX) {
                    if (mouseX <= left + width / 2 && left <= activePosition.left + activePosition.width) {
                        activeInXDirection = true;
                    }
                } else {
                    if (mouseX >= left && left >= activePosition.left - activePosition.width / 2) {
                        activeInXDirection = true;
                    }
                }

                if (decrementingY) {
                    if (mouseY <= top && top <= activePosition.top) {
                        activeInYDirection = true;
                    }
                } else {
                    if (mouseY >= top + height / 2 && top >= activePosition.top) {
                        activeInYDirection = true;
                    }
                }

                if (activeInXDirection && activeInYDirection) {
                    dataCell.classList.add("active");
                    selectedRefs.push(cellRef);
                } else {
                    dataCell.classList.remove("active");
                }
            }
        });

        shiftKeyDown = false;
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        return (
            <Fragment>
                <div className={"data-table-horizontal" + (this.props.mini ? " mini" : "")} ref={this.props.tableSizeCallback}>
                    <ErrorPopup showing={this.state.error !== undefined} errorText={this.state.error} />
                    {this.renderTable()}
                    {this.renderTypePopover()}
                    {this.renderConditionalModal()}
                    {this.renderHeaderAction()}
                </div>
            </Fragment>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { dataPoints } = state.summaryReducer;
    const { materialCount, materialOrdering } = state.productGroupReducer;
    return { dataPoints, materialCount, materialOrdering };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            deleteProductGroups,
            getScenarioMaterialGroupDataPoints,
            setMaterialOrdering,
            resetMaterialOrdering,
            saveContractCustomConditions,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(DataTable));

// EXPORT COMPONENT
export { hoc as DataTable };
