// IMPORT PACKAGE REFERENCES
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";
import meta from "../../config/meta.json";
import MyAccount from "./MyAccount.js";
import { Loading } from "../containers/loading/Loading.js";

import { WorkingIndicator } from "../containers/loading/WorkingIndicator";
import { getUser } from "../state/actions/UserActions";
import { SearchSuggestions } from "./SearchSuggestions.js";

// COMPONENT
class SiteSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            resultsShowing: false,
            searchText: "",
        };

        this.hideResults = this.hideResults.bind(this);

        // Refs
        this.searchSuggestions = null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.searchText !== this.state.searchText) {
            if (this.state.searchText.length > 0) {
                this.setState({ resultsShowing: true });
            } else {
                this.setState({ resultsShowing: false });
            }
        }
    }

    hideResults() {
        this.setState({ resultsShowing: false, searchText: "" });
    }

    _handleKeyDown = (e) => {
        if (e.key === "Enter") {
            this.searchSuggestions.handleEnter();
        }
    };

    render() {
        return (
            <div className="search-bar-container">
                <div className="search-input-container">
                    <FeatherIcon size={19} className="search-icon" icon="search" />
                    <input
                        onKeyDown={this._handleKeyDown}
                        value={this.state.searchText}
                        onChange={(e) => {
                            this.setState({ searchText: e.target.value });
                        }}
                        className="search-bar"
                        placeholder="Search contracts and pages"
                    ></input>
                </div>
                <div className={`search-content ${this.state.resultsShowing ? " active" : ""}`}>
                    <SearchSuggestions
                        onRef={(ref) => {
                            this.searchSuggestions = ref;
                        }}
                        hideResults={this.hideResults}
                        searchText={this.state.searchText}
                    />
                </div>
            </div>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { working } = state.generalReducer;
    const { user } = state.userReducer;
    return { working, user };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getUser,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteSearch));

// EXPORT COMPONENT

export { hoc as SiteSearch };
