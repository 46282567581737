// IMPORT PACKAGE REFERENCES
import React from "react";
import { Jumbotron, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ContextPage } from "../../containers/layout/ContextPage";
import { Card } from "../../containers/card/Card";
import { getGlobalDetails, saveGlobalDetails } from "../../state/actions/ConfigurationActions";
import { showError, hideError } from "../../state/actions/GeneralActions";
import { CardRow } from "../../containers/card/CardRow";
import { Formik } from "formik";
import { CCAInput } from "../../containers/inputs/CCAInput";
import { CCAInputTypes } from "../../enums/CCAInputTypes";
import { CCAError } from "../../enums/CCAError";

// COMPONENT
class AssumptionsPage extends React.Component {
    constructor(props) {
        super(props);

        this.valuesAtLastSave = {};
        this.getDetails = this.getDetails.bind(this);
    }

    componentDidMount() {
        this.getDetails();
    }

    getDetails() {
        this.props.getGlobalDetails((success) => {
            if (success) {
                this.valuesAtLastSave = this.props.globalDetails;
                this.props.hideError(CCAError.GET_CONTRACT_DETAILS_ERROR);
            } else {
                this.props.showError(CCAError.GET_CONTRACT_DETAILS_ERROR);
            }
        });
    }

    saveGlobalDetails(values) {
        this.props.saveGlobalDetails(values, (success) => {
            if (success) {
                this.props.hideError(CCAError.SAVE_CONTRACT_DETAILS_ERROR);
                this.valuesAtLastSave = values;
            } else {
                this.props.showError(CCAError.SAVE_CONTRACT_DETAILS_ERROR, () => {
                    this.saveGlobalDetails(values);
                });
            }
        });
    }

    render() {
        return (
            <ContextPage fetching={false} fetched={true} title={"Assumptions"}>
                <Jumbotron>
                    <h1 className="display-3">Global Assumptions</h1>
                    <p className="lead">As a user with admin rights you have the ability to change global assumptions. These are the system wide default assumptions and escalations that newly created agreements will automatically comply with.</p>
                    <hr className="my-2" />
                    <p className="lead secondary">Assumptions can be changed on a per-agreement basis by opening an agreement and navigating to the agreement's details section.</p>
                </Jumbotron>
                <Formik
                    enableReinitialize={true}
                    initialValues={this.props.globalDetails}
                    onSubmit={(values) => {
                        if (JSON.stringify(values) !== JSON.stringify(this.valuesAtLastSave)) {
                            this.saveGlobalDetails(values);
                        }
                    }}
                    render={({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldTouched, setFieldValue }) => (
                        <Card>
                            <CardRow label={"Volume"} top={true}>
                                <CCAInput
                                    type={CCAInputTypes.PERCENT}
                                    name="volume"
                                    value={values.volume}
                                    onChange={(value) => {
                                        setFieldValue("volume", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Selling Price"}>
                                <CCAInput
                                    type={CCAInputTypes.PERCENT}
                                    name="sellingPrice"
                                    value={values.sellingPrice}
                                    onChange={(value) => {
                                        setFieldValue("sellingPrice", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Inflation Rate (CPI)"}>
                                <CCAInput
                                    type={CCAInputTypes.PERCENT}
                                    name="inflationRate"
                                    value={values.inflationRate}
                                    onChange={(value) => {
                                        setFieldValue("inflationRate", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Cost of Sales"}>
                                <CCAInput
                                    type={CCAInputTypes.PERCENT}
                                    name="costOfSales"
                                    value={values.costOfSales}
                                    onChange={(value) => {
                                        setFieldValue("costOfSales", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Delivery Cost Escalator"}>
                                <CCAInput
                                    type={CCAInputTypes.PERCENT}
                                    name="deliveryCostEscalator"
                                    value={values.deliveryCostYear}
                                    onChange={(value) => {
                                        setFieldValue("deliveryCostEscalator", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Indirect Costs"}>
                                <CCAInput
                                    type={CCAInputTypes.PERCENT}
                                    name="indirectCosts"
                                    value={values.indirectCosts}
                                    onChange={(value) => {
                                        setFieldValue("indirectCosts", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Rent"} bottom={true}>
                                <CCAInput
                                    type={CCAInputTypes.PERCENT}
                                    name="rent"
                                    value={values.rent}
                                    onChange={(value) => {
                                        setFieldValue("rent", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>
                        </Card>
                    )}
                />
                <Formik
                    enableReinitialize
                    initialValues={this.props.globalDetails}
                    onSubmit={(values) => {
                        if (JSON.stringify(values) !== JSON.stringify(this.valuesAtLastSave)) {
                            this.saveGlobalDetails(values);
                        }
                    }}
                    render={({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldTouched, setFieldValue }) => (
                        <Card>
                            <CardRow label={"Hurdle Rate"} top={true}>
                                <CCAInput
                                    type={CCAInputTypes.PERCENT}
                                    name="hurdleRate"
                                    value={values.hurdleRate}
                                    onChange={(value) => {
                                        setFieldValue("hurdleRate", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Delivery Cost $ per PC"}>
                                <CCAInput
                                    type={CCAInputTypes.DOLLAR}
                                    name="deliveryCost"
                                    value={values.deliveryCost}
                                    onChange={(value) => {
                                        setFieldValue("deliveryCost", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Depreciation (Years) - Packaged"}>
                                <CCAInput
                                    type={CCAInputTypes.NUMBER}
                                    name="depreciationPackaged"
                                    value={values.depreciationPackaged}
                                    onChange={(value) => {
                                        setFieldValue("depreciationPackaged", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Depreciation (Years) - Postmix"}>
                                <CCAInput
                                    type={CCAInputTypes.NUMBER}
                                    name="depreciationPostmix"
                                    value={values.depreciationPostmix}
                                    onChange={(value) => {
                                        setFieldValue("depreciationPostmix", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Depreciation (Years) - Vending"}>
                                <CCAInput
                                    type={CCAInputTypes.NUMBER}
                                    name="depreciationVending"
                                    value={values.depreciationVending}
                                    onChange={(value) => {
                                        setFieldValue("depreciationVending", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Depreciation (Years) - Coffee"}>
                                <CCAInput
                                    type={CCAInputTypes.NUMBER}
                                    name="depreciationCoffee"
                                    value={values.depreciationCoffee}
                                    onChange={(value) => {
                                        setFieldValue("depreciationCoffee", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Indirects $ per Unit Case - Packaged"}>
                                <CCAInput
                                    type={CCAInputTypes.DOLLAR}
                                    name="indirectsPerUnitCasePackaged"
                                    value={values.indirectsPerUnitCasePackaged}
                                    onChange={(value) => {
                                        setFieldValue("indirectsPerUnitCasePackaged", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Indirects $ per Unit Case - Coffee"}>
                                <CCAInput
                                    type={CCAInputTypes.DOLLAR}
                                    name="indirectsPerUnitCaseCoffee"
                                    value={values.indirectsPerUnitCaseCoffee}
                                    onChange={(value) => {
                                        setFieldValue("indirectsPerUnitCaseCoffee", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Indirects $ per Unit Case - Postmix"}>
                                <CCAInput
                                    type={CCAInputTypes.DOLLAR}
                                    name="indirectsPerUnitCasePostmix"
                                    value={values.indirectsPerUnitCasePostmix}
                                    onChange={(value) => {
                                        setFieldValue("indirectsPerUnitCasePostmix", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Tax"}>
                                <CCAInput
                                    type={CCAInputTypes.PERCENT}
                                    name="tax"
                                    value={values.tax}
                                    onChange={(value) => {
                                        setFieldValue("tax", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"GST"} bottom={true}>
                                <CCAInput
                                    type={CCAInputTypes.PERCENT}
                                    name="gst"
                                    value={values.gst}
                                    onChange={(value) => {
                                        setFieldValue("gst", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Cup Size"} bottom={true}>
                                <CCAInput
                                    type={CCAInputTypes.NUMBER}
                                    name="cupsize"
                                    value={values.cupsize}
                                    onChange={(value) => {
                                        setFieldValue("cupsize", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>
                            <CardRow label={"Serve Size (Coffee)"} bottom={true}>
                                <CCAInput
                                    type={CCAInputTypes.NUMBER}
                                    name="servesize"
                                    value={values.servesize}
                                    onChange={(value) => {
                                        setFieldValue("servesize", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>
                        </Card>
                    )}
                />
            </ContextPage>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { fetching, fetched, failed, globalDetails } = state.configurationReducer;
    return { fetching, fetched, failed, globalDetails };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getGlobalDetails,
            saveGlobalDetails,
            showError,
            hideError,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(AssumptionsPage));
// EXPORT COMPONENT

export { hoc as AssumptionsPage };
