// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";

// ACTIONS
import { createContract } from "../../../state/actions/ContractActions";

// UI
import { ValidatedInput } from "../../../containers/validation/ValidatedInput";
import { Col, Row, Button, FormGroup, Label } from "reactstrap";
import { Loading } from "../../../containers/loading/Loading";
import { Message } from "../../../containers/layout/Message";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Template name required"),
});

// COMPONENT
class NewProgrammeModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            formInvalid: false,
            messageActive: false,
            messageError: false,
        };

        this.getContractIdFromUrl = this.getContractIdFromUrl.bind(this);
    }

    getContractIdFromUrl() {
        let components = this.props.location.pathname.split("/");
        return components[2];
    }

    render() {
        return (
            <Fragment>
                <Message active={this.state.messageActive} error={this.state.messageError} message="Error creating new programme" />
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        name: "",
                    }}
                    onSubmit={(values) => {
                        this.setState({ saving: true });
                        this.props.createContract(values, this.props.user, "PROGRAMME", this.getContractIdFromUrl(), (success) => {
                            this.setState({ saving: false });
                            if (success) {
                                this.props.hideModal();
                            } else {
                                this.setState({ messageActive: true, messageError: true });
                                setTimeout(() => {
                                    this.setState({ messageActive: false, messageError: false });
                                }, 2000);
                            }
                        });
                    }}
                    render={({ values, handleSubmit, setFieldValue, errors, touched }) => (
                        <Fragment>
                            <Row form>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="name">Name</Label>
                                        <ValidatedInput touched={touched.name} error={errors.name} field="name" setFieldValue={setFieldValue} name="name" value={values.name} type="text" id="name" placeholder="Programme name" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Button onClick={handleSubmit} color="secondary" size="lg" block>
                                {!this.state.saving && <div> Create Programme </div>}
                                {this.state.saving && <Loading small light />}
                            </Button>
                        </Fragment>
                    )}
                />
            </Fragment>
        );
    }
}

NewProgrammeModal.propTypes = {};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { fetching, fetched, failed } = state.contractReducer;
    const { user } = state.userReducer;
    return { fetching, fetched, failed, user };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ createContract }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(NewProgrammeModal));

// EXPORT COMPONENT

export { hoc as NewProgrammeModal };
