/*** @Imports ***/
import FeatherIcon from "feather-icons-react";

// React Import
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

/*** Component ***/
class ExpandableRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            expanded: props.expanded ? props.expanded : false,
        };

        this.toggleExpansion = this.toggleExpansion.bind(this);
    }

    toggleExpansion() {
        this.setState({ expanded: !this.state.expanded });
    }

    render() {
        return (
            <div>
                <div onClick={this.toggleExpansion} className="expandable-heading">
                    {this.props.label}
                    <div className="expandable-actions">
                        {this.props.button}
                        <FeatherIcon onClick={this.toggleExpansion} className={"expandable-icon" + (this.state.expanded ? " open" : " closed")} icon="chevron-down" />
                    </div>
                </div>
                <div className={"expandable-content" + (this.state.expanded ? " expanded" : "")}>{this.props.children}</div>
            </div>
        );
    }
}

const hoc = withRouter(ExpandableRow);

/*** Export ***/
export { hoc as ExpandableRow };
