// *** PACKAGE REFERENCES *** //
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Message } from "../../../containers/layout/Message";

// *** ACTIONS *** //
import { saveContractCustomConditions, saveContractCompliance } from "../../../state/actions/ContractActions";
import { saveContractDetails } from "../../../state/actions/ContractDetailsActions";

// *** UI *** //
import { ContextPage } from "../../../containers/layout/ContextPage";
import { Card } from "../../../containers/card/Card";
import { CardActions } from "../../../containers/card/CardActions";
import { Conditionaliser } from "../../../../modules/conditionaliser/Conditionaliser";

class ContractCompliancePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: false,
            contract: props.contract,
            contractDetails: {},
            message: "",
            messageActive: false,
            messageError: false,
            loading: false,
        };

        this.handleSaveCustomConditions = this.handleSaveCustomConditions.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.update = this.update.bind(this);

        this.complianceConditionaliser = null;
    }

    componentDidMount() {
        this.update();
    }

    componentDidUpdate() {
        this.update();
    }

    update() {
        if (JSON.stringify(this.props.contract) !== JSON.stringify(this.state.contract)) {
            this.setState({ contract: this.props.contract });
        }

        if (JSON.stringify(this.props.contractDetails) !== JSON.stringify(this.state.contractDetails)) {
            this.setState({ contractDetails: this.props.contractDetails });
        }
    }

    handleSaveCustomConditions(conditions) {
        this.setState({ messageActive: true, messageLoading: true, message: "Saving Custom Conditions" });
        this.props.saveContractCustomConditions(this.props.match.params.contractId, conditions, (success) => {
            this.setState({ messageActive: false, messageLoading: false });
        });
    }

    handleChange() { }

    handleSubmit() {
        if (this.conditionaliser !== null) {
            let expressions = JSON.parse(this.complianceConditionaliser.getExpressions());

            if (this.state.editing) {
                this.setState({ loading: true });
                this.props.saveContractDetails(this.props.match.params.contractId, expressions, "compliance", (success) => {
                    if (success) {
                        this.setState({ loading: false });
                    } else {
                        this.setState({ messageActive: true, messageError: true, message: "Error saving contract compliance" });
                        setTimeout(() => {
                            this.setState({ messageActive: false, messageError: false, loading: false });
                        }, 2000);
                    }
                });
            }
        }
    }

    render() {
        return (
            <ContextPage fetching={this.props.fetching} fetched={this.props.fetched} title={this.props.title}>
                <Message active={this.state.messageActive} message={this.state.message} error={this.state.messageError} />
                <Card
                    title={"Compliance"}
                    action={
                        <CardActions
                            handleClick={(e) => {
                                if (this.state.editing) {
                                    this.handleSubmit(e);
                                }

                                this.setState({ editing: !this.state.editing });
                            }}
                            editing={this.state.editing}
                            loading={this.state.loading}
                        />
                    }
                >
                    <Conditionaliser conditionTypes={["SQ124", "SQ125"]} customConditions={this.state.contract.customConditions} saveCustomConditions={this.handleSaveCustomConditions} value={this.state.contractDetails.compliance} allowResults={false} ref={(ref) => (this.complianceConditionaliser = ref)} setSaveButtonState={() => { }} onChange={this.handleChange} editing={this.state.editing} />
                </Card>
            </ContextPage>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { contractDetails, fetching, fetched, failed } = state.contractDetailsReducer;
    const { contract } = state.contractReducer;
    return { contractDetails, contract, fetching, fetched, failed };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ saveContractCustomConditions, saveContractCompliance, saveContractDetails }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractCompliancePage));

// EXPORT COMPONENT
export { hoc as ContractCompliancePage };
