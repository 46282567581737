import { CLEAR_REDUCERS, PREPARE_FOR_CONTRACT_SWITCH } from "../actions/GeneralActions";
import {
    GET_DOCUMENT_LIST_PENDING,
    GET_DOCUMENT_LIST_FULFILLED,
    GET_DOCUMENT_LIST_FAILED,
} from "../actions/DocumentActions";

const initialState = {
    documentList: [],
};

export const DocumentReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_REDUCERS:
            return initialState;
        case PREPARE_FOR_CONTRACT_SWITCH:
            return initialState;
        /* GET EQUIPMENTS */
        case GET_DOCUMENT_LIST_PENDING:
            return {
                ...state,
            };
        case GET_DOCUMENT_LIST_FULFILLED:
            return {
                ...state,
                documentList: action.payload,
            };
        case GET_DOCUMENT_LIST_FAILED:
            return {
                ...state,
            };
        default:
            return state;
    }
};
