// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
var { registerObserver } = require("react-perf-devtool");
import meta from "../../config/meta.json";

// IMPORT PROJECT REFERENCES
import { LoginPage } from "../pages/LoginPage";
import { PrivateRoute } from "./PrivateRoute";
import { PrivateContractsRoute } from "./PrivateContractsRoute";
import { Sidebar } from "../navigation/Sidebar";
import { ContractsPage } from "../pages/contract/ContractsPage";
import { ContractPage } from "../pages/contract/ContractPage";
import { ProductsPage } from "../pages/contract/products/ProductsPage";
import { FinancialIncentivesPage } from "../pages/contract/additionals/FinancialIncentivesPage";
import { ContractDetailsPage } from "../pages/contract/detailpages/ContractDetailsPage";
import { SettingsPage } from "../pages/SettingsPage";
import { SummaryPage } from "../pages/contract/SummaryPage";
import { EquipmentsPage } from "../pages/contract/additionals/EquipmentsPage";
import { ProgrammesPage } from "../pages/contract/ProgrammesPage";
import { CustomerPage } from "../pages/contract/CustomerPage";
import { UsersPage } from "../pages/contract/UsersPage";
import { TemplatesPage } from "../pages/contract/TemplatesPage";
import { ApprovalMatrixPage } from "../pages/contract/ApprovalMatrixPage";
import { RollingSalesPage } from "../pages/contract/RollingSalesPage";
import { SegmentDiscountPage } from "../pages/contract/SegmentDiscountPage";
import { FloorPriceMatrixPage } from "../pages/contract/FloorPriceMatrixPage";
import { MaterialsPage } from "../pages/contract/MaterialsPage";
import { EquipmentPage } from "../pages/contract/EquipmentPage";
import axios from "axios";
import { ScenarioDetailsPage } from "../pages/contract/detailpages/ScenarioDetailsPage";
import { CCARequestTypes } from "../enums/CCARequestTypes";
import { getCustomerTargets } from "../state/actions/CustomerTargetActions";
import { logout, setTestValue } from "../state/actions/UserActions";
import { NavigationBar } from "../navigation/NavigationBar";
import { Message } from "../containers/layout/Message";
import { startWorking, stopWorking, hideError } from "../state/actions/GeneralActions";
import { setVersion } from "../state/actions/UserActions";
import { CCAError } from "../enums/CCAError";
import { AssumptionsPage } from "../pages/contract/AssumptionsPage.js";
import { clearReducers } from "../state/actions/GeneralActions";
/*
    Manage the authorisation and routing of the user based on the provided URL parameters.
*/

const recalculateTargetRequests = [CCARequestTypes.PAYMENT_SAVE, CCARequestTypes.CONTRACT_REBATE_SAVE, CCARequestTypes.CONTRACT_DISCOUNT_SAVE];

class AppRouter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sidebarOpen: true,
            infoPanelOpen: false
        };
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.recalculatePins = this.recalculatePins.bind(this);
        this.getContractIdFromUrl = this.getContractIdFromUrl.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.checkVersion = this.checkVersion.bind(this);


        /* Intercept all requests... and perform some action */
        axios.interceptors.request.use(
            async (request) => {
                this.props.startWorking();

                let data = {};
                if (request.data !== undefined) {
                    data = request.data;
                }

                this.recalculatePins(data.requestId, request.url);

                // Attach the user ID to each request...
                if (this.props.user) {
                    if (Object.keys(this.props.user).length === 0) {
                        return request;
                    }

                    if (this.props.user) {
                        data.userId = this.props.user.uid;
                    }

                    let token = this.props.user.authToken;
                    request.headers.Authorization = `Bearer ${token}`;
                    return request;
                } else {
                    return request;
                }
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    handleKeyDown(event) {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if (event.ctrlKey && event.shiftKey && charCode === "z") {
            console.log("Ctrl + Shift + Z pressed");
        } else if (event.ctrlKey && charCode === "z") {
            console.log("Ctrl + Z pressed");
        }

        // For macOS we can use metaKey to detect cmd key
        if (event.metaKey && event.shiftKey && charCode === "z") {
            console.log("Cmd + Shift + Z pressed");
        } else if (event.metaKey && charCode === "z") {
            console.log("Cmd + Z pressed");
        }
    }

    /**
     * Inspects the url to find the contract id
     */
    getContractIdFromUrl(url) {
        let nextValueIsContractId = false;
        let contractId = null;
        let components = url.split("/");

        components.forEach((component) => {
            if (nextValueIsContractId) {
                contractId = component;
                nextValueIsContractId = false;
            }
            if (component === "contracts") {
                nextValueIsContractId = true;
            }
        });

        return contractId;
    }

    getScenarioIdFromUrl(url) {
        let components = url.split("/");
        return components[3];
    }

    /**
     * Check if we're on a contract route
     * */
    getContractDetailsFromUrl(){
        const path = window.location.pathname;
        const pathParts = path.split('/');
        if(pathParts[1] === 'contracts') {
            return {
                contractId: pathParts[2],
                scenarioId: pathParts[3],
            }
        }
        return false;
    }

    toggleSidebar() {
        this.setState({ sidebarOpen: !this.state.sidebarOpen });
    }

    /**
     * Checks if any of the pins require recalculation
     *
     * e.g. saving a rebate may change gross profit, so we need to recalculate the customer gross profit for the customer targets pin
     *
     * @param requestId
     */
    recalculatePins(requestId, url) {
        if (requestId) {
            if (recalculateTargetRequests.includes(requestId)) {
                this.props.getCustomerTargets(this.getContractIdFromUrl(url), this.getScenarioIdFromUrl(url));
            }
        }
    }

    /**
     * Checks the current version against what is in state.
     * If the version has changed, reset all values in state.
     *
     * @param currentBuildNumber
     */
    checkVersion(currentBuildNumber) {
        if (currentBuildNumber !== this.props.version) {
            this.props.clearReducers();
        }
    }

    /**
     * Remove the key down listener when navigating away from the page.
     */
    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown, false);
    }

    componentDidUpdate() {
        const contractDetails = this.getContractDetailsFromUrl(); // Refetch the details to check if the route has changed
        if(!contractDetails) {
            clearInterval(this.pollCalculation);
        }
    }

    componentDidMount() {

        document.addEventListener("keydown", this.handleKeyDown, false);

        this.checkVersion(meta.buildNumber);
        this.props.setVersion(meta.buildNumber);
        /*
           Ensure the working indicator and error message are not showing.
           If the page was refreshed while they were active, that state could have been maintained
        */
        this.props.stopWorking();
        this.props.hideError(CCAError.DISMISS_ERROR);

        registerObserver();

        /* Intercept all responses */
        axios.interceptors.response.use(
            (response) => {
                this.props.stopWorking();
                return response;
            },
            (error) => {
                this.props.stopWorking();
                return Promise.reject(error);
            }
        );


    }

    render() {
        return (
            <BrowserRouter>
                <Fragment>
                    {this.props.loggedIn && (
                        <Fragment>
                            <NavigationBar logout={this.props.logout} toggleSidebar={this.toggleSidebar} />
                            <div onKeyDown={this.handleKeyDown} className={"page-container" + (this.props.loggedIn ? " logged-in" : " logged-out")}>
                                <Fragment>
                                    <Message />
                                    <div className={"left-panel " + (this.state.sidebarOpen ? " open" : " closed") + (this.state.infoPanelOpen ? " info-showing" : "")}>
                                        <div className={"sidebar-content" + (this.state.sidebarOpen ? " open" : " closed")}>
                                            <Sidebar sidebarOpen={this.state.sidebarOpen} toggleSidebar={this.toggleSidebar} />
                                        </div>
                                    </div>

                                    <div className={"right-panel " + (this.state.sidebarOpen ? "open" : "")}>
                                        <Switch>
                                            <PrivateRoute loggedIn={this.props.loggedIn} path="/" component={ContractsPage} exact={true} />
                                            {/* <PrivateRoute loggedIn={this.props.loggedIn} path="/templates/" component={TemplatesPage} exact={true} /> */}
                                            {/* <PrivateRoute loggedIn={this.props.loggedIn} path="/programmes/" component={ProgrammesPage} exact={true} /> */}
                                            <PrivateRoute loggedIn={this.props.loggedIn} path="/users/" component={UsersPage} exact={true} />
                                            {/* <PrivateRoute loggedIn={this.props.loggedIn} path="/settings" component={SettingsPage} exact={true} /> */}
                                            <PrivateContractsRoute loggedIn={this.props.loggedIn} path="/contracts/:contractId/:scenarioId" component={ContractPage} exact={true} />

                                            {/* Products */}
                                            <PrivateContractsRoute loggedIn={this.props.loggedIn} path="/contracts/:contractId/:scenarioId/products" component={ProductsPage} exact={true} />

                                            <PrivateContractsRoute loggedIn={this.props.loggedIn} path="/contracts/:contractId/:scenarioId/products/materials" component={ProductsPage} exact={true} />
                                            <PrivateContractsRoute loggedIn={this.props.loggedIn} path="/contracts/:contractId/:scenarioId/products/productDetails" component={ProductsPage} exact={true} />
                                            <PrivateContractsRoute loggedIn={this.props.loggedIn} path="/contracts/:contractId/:scenarioId/products/compliance" component={ProductsPage} exact={true} />
                                            <PrivateContractsRoute loggedIn={this.props.loggedIn} path="/contracts/:contractId/:scenarioId/products/allocation" component={ProductsPage} exact={true} />

                                            <PrivateContractsRoute loggedIn={this.props.loggedIn} path="/contracts/:contractId/:scenarioId/products/:id" component={ProductsPage} exact={true} />

                                            {/* Contract Details */}
                                            <PrivateContractsRoute loggedIn={this.props.loggedIn} path="/contracts/:contractId/:scenarioId/details" component={ContractDetailsPage} />

                                            {/* Scenario Details */}
                                            <PrivateContractsRoute loggedIn={this.props.loggedIn} path="/contracts/:contractId/:scenarioId/scenario" component={ScenarioDetailsPage} />

                                            {/* Summary */}
                                            <PrivateContractsRoute loggedIn={this.props.loggedIn} path="/contracts/:contractId/:scenarioId/summary" component={SummaryPage} />

                                            {/* Customer */}
                                            <PrivateContractsRoute loggedIn={this.props.loggedIn} path="/contracts/:contractId/:scenarioId/customer" component={CustomerPage} />

                                            {/* Non-Financial Incentives */}
                                            <PrivateContractsRoute loggedIn={this.props.loggedIn} path="/contracts/:contractId/:scenarioId/equipment/" component={EquipmentsPage} exact={true} />
                                            <PrivateContractsRoute loggedIn={this.props.loggedIn} path="/contracts/:contractId/:scenarioId/equipment/:id" component={EquipmentsPage} />

                                            {/* Financial Incentives */}
                                            <PrivateContractsRoute loggedIn={this.props.loggedIn} path="/contracts/:contractId/:scenarioId/financial-incentives/" component={FinancialIncentivesPage} exact={true} />
                                            <PrivateContractsRoute loggedIn={this.props.loggedIn} path="/contracts/:contractId/:scenarioId/financial-incentives/:id" component={FinancialIncentivesPage} />

                                            {/* Approval Matrix */}
                                            {/* <PrivateRoute loggedIn={this.props.loggedIn} path="/approval-matrix" component={ApprovalMatrixPage} /> */}

                                            {/* Configuration  */}
                                            <PrivateRoute loggedIn={this.props.loggedIn} path="/assumptions" component={AssumptionsPage} />
                                            <PrivateRoute loggedIn={this.props.loggedIn} path="/rolling-sales" component={RollingSalesPage} />
                                            <PrivateRoute loggedIn={this.props.loggedIn} path="/segment-discount" component={SegmentDiscountPage} />
                                            <PrivateRoute loggedIn={this.props.loggedIn} path="/floor-price-matrix" component={FloorPriceMatrixPage} />
                                            <PrivateRoute loggedIn={this.props.loggedIn} path="/materials" component={MaterialsPage} />
                                            <PrivateRoute loggedIn={this.props.loggedIn} path="/equipment" component={EquipmentPage} />

                                            <Redirect to="/" />
                                        </Switch>
                                       
                                    </div>
                                </Fragment>
                            </div>
                        </Fragment>
                    )}
                    {!this.props.loggedIn && <LoginPage />}
                </Fragment>
            </BrowserRouter>
        );
    }
}

AppRouter.propTypes = {
    loggedIn: PropTypes.bool.isRequired,
};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { loggedIn, user, sidebarOpen, version } = state.userReducer;
    return { loggedIn, user, sidebarOpen, version };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getCustomerTargets,
            logout,
            stopWorking,
            startWorking,
            hideError,
            setVersion,
            clearReducers,
            setTestValue,
        },
        dispatch
    );

const hoc = connect(mapStateToProps, mapDispatchToProps)(AppRouter);

// EXPORT COMPONENT
export { hoc as AppRouter };
