// *** PACKAGE REFERENCES *** //
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// *** ACTIONS *** //
import { getContractDetails, saveContractDetails } from "../../../state/actions/ContractDetailsActions";
import { showError, hideError } from "../../../state/actions/GeneralActions";

// *** UI *** //
import { ContextPage } from "../../../containers/layout/ContextPage";
import { Card } from "../../../containers/card/Card";
import { CardRow } from "../../../containers/card/CardRow";
import { Formik } from "formik";
import { CCAInput } from "../../../containers/inputs/CCAInput";
import { CCAInputTypes } from "../../../enums/CCAInputTypes";
import { CCAError } from "../../../enums/CCAError";
import { AccessDenied } from "../../../shared/AccessDenied";

class OtherAssumptionsPage extends React.Component {
    constructor(props) {
        super(props);

        this.valuesAtLastSave = {};
        this.getDetails = this.getDetails.bind(this);
    }

    componentDidMount() {
        this.getDetails();
    }

    getDetails() {
        this.props.getContractDetails(this.props.match.params.contractId, (success) => {
            if (success) {
                this.valuesAtLastSave = this.props.contractDetails.goalsAndNeeds;
                this.props.hideError(CCAError.GET_CONTRACT_DETAILS_ERROR);
            } else {
                this.props.showError(CCAError.GET_CONTRACT_DETAILS_ERROR);
            }
        });
    }

    saveContractDetails(values) {
        this.props.saveContractDetails(this.props.match.params.contractId, values, "otherAssumptions", (success) => {
            if (success) {
                this.props.hideError(CCAError.SAVE_CONTRACT_DETAILS_ERROR);
                this.valuesAtLastSave = values;
            } else {
                this.props.showError(CCAError.SAVE_CONTRACT_DETAILS_ERROR, () => {
                    this.saveContractDetails(values);
                });
            }
        });
    }

    render() {
        if (this.props.locked) {
            return <AccessDenied />;
        }

        return (
            <ContextPage fetching={this.props.fetching} fetched={this.props.fetched} title={this.props.title}>
                <Formik
                    enableReinitialize
                    initialValues={this.props.contractDetails.otherAssumptions}
                    onSubmit={(values) => {
                        if (JSON.stringify(values) !== JSON.stringify(this.valuesAtLastSave)) {
                            this.saveContractDetails(values);
                        }
                    }}
                    render={({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldTouched, setFieldValue }) => (
                        <Card>
                            <CardRow label={"Hurdle Rate"} top={true}>
                                <CCAInput
                                    type={CCAInputTypes.PERCENT}
                                    name="hurdleRate"
                                    value={values.hurdleRate}
                                    onChange={(value) => {
                                        setFieldValue("hurdleRate", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Delivery Cost $ per PC"}>
                                <CCAInput
                                    type={CCAInputTypes.DOLLAR}
                                    name="deliveryCost"
                                    value={values.deliveryCost}
                                    onChange={(value) => {
                                        setFieldValue("deliveryCost", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Depreciation (Years) - Packaged"}>
                                <CCAInput
                                    type={CCAInputTypes.NUMBER}
                                    name="depreciationPackaged"
                                    value={values.depreciationPackaged}
                                    onChange={(value) => {
                                        setFieldValue("depreciationPackaged", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Depreciation (Years) - Postmix"}>
                                <CCAInput
                                    type={CCAInputTypes.NUMBER}
                                    name="depreciationPostmix"
                                    value={values.depreciationPostmix}
                                    onChange={(value) => {
                                        setFieldValue("depreciationPostmix", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Depreciation (Years) - Vending"}>
                                <CCAInput
                                    type={CCAInputTypes.NUMBER}
                                    name="depreciationVending"
                                    value={values.depreciationVending}
                                    onChange={(value) => {
                                        setFieldValue("depreciationVending", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Depreciation (Years) - Coffee"}>
                                <CCAInput
                                    type={CCAInputTypes.NUMBER}
                                    name="depreciationCoffee"
                                    value={values.depreciationCoffee}
                                    onChange={(value) => {
                                        setFieldValue("depreciationCoffee", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Indirects $ per Unit Case - Packaged"}>
                                <CCAInput
                                    type={CCAInputTypes.DOLLAR}
                                    name="indirectsPerUnitCasePackaged"
                                    value={values.indirectsPerUnitCasePackaged}
                                    onChange={(value) => {
                                        setFieldValue("indirectsPerUnitCasePackaged", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Indirects $ per Unit Case - Coffee"}>
                                <CCAInput
                                    type={CCAInputTypes.DOLLAR}
                                    name="indirectsPerUnitCaseCoffee"
                                    value={values.indirectsPerUnitCaseCoffee}
                                    onChange={(value) => {
                                        setFieldValue("indirectsPerUnitCaseCoffee", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Indirects $ per Unit Case - Postmix"}>
                                <CCAInput
                                    type={CCAInputTypes.DOLLAR}
                                    name="indirectsPerUnitCasePostmix"
                                    value={values.indirectsPerUnitCasePostmix}
                                    onChange={(value) => {
                                        setFieldValue("indirectsPerUnitCasePostmix", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Tax"}>
                                <CCAInput
                                    type={CCAInputTypes.PERCENT}
                                    name="tax"
                                    value={values.tax}
                                    onChange={(value) => {
                                        setFieldValue("tax", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"GST"} bottom={true}>
                                <CCAInput
                                    type={CCAInputTypes.PERCENT}
                                    name="gst"
                                    value={values.gst}
                                    onChange={(value) => {
                                        setFieldValue("gst", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Cup Size"} bottom={true}>
                                <CCAInput
                                    type={CCAInputTypes.NUMBER}
                                    name="cupsize"
                                    value={values.cupsize}
                                    onChange={(value) => {
                                        setFieldValue("cupsize", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>
                            <CardRow label={"Serve Size (Coffee)"} bottom={true}>
                                <CCAInput
                                    type={CCAInputTypes.NUMBER}
                                    name="servesize"
                                    value={values.servesize}
                                    onChange={(value) => {
                                        setFieldValue("servesize", value);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>
                        </Card>
                    )}
                />
            </ContextPage>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { fetching, fetched, failed, contractDetails } = state.contractDetailsReducer;
    const { working } = state.generalReducer;
    return { fetching, fetched, failed, contractDetails, working };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getContractDetails,
            saveContractDetails,
            showError,
            hideError,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(OtherAssumptionsPage));

// EXPORT COMPONENT
export { hoc as OtherAssumptionsPage };
