// IMPORT PACKAGE REFERENCES
import React from "react";

// COMPONENT
export class Card extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={"cca-card" + (this.props.noPadding ? " no-padding" : "") + ` ${this.props.size !== undefined ? this.props.size : ""}`}>
                {!this.props.noTitle && (
                    <div className={this.props.sticky ? "sticky" : ""}>
                        <div className={"" + (this.props.title || this.props.action ? "cca-card-header" : "") + (this.props.hideTitleBorder ? "hide-border" : "")}>
                            <div>{this.props.title}</div>
                            <div style={{ display: "inherit" }}>
                                <div style={{ paddingRight: "1em" }}>{this.props.secondaryAction}</div>
                                <div>{this.props.action}</div>
                            </div>
                        </div>

                        <div>
                            {this.props.rows
                                ? this.props.rows.map((row, rowIndex) => {
                                    return (
                                        <div className={"data-table-group" + (rowIndex % 2 === 0 ? " even" : " odd")}>
                                            <div className="data-table-cell bold wide">{row.name}</div>
                                            {row.rowData.map((rowItem, rowItemIndex) => {
                                                return (
                                                    <div style={{ width: (1 / row.rowData.length) * 0.7 * 100 + "%" }} className={`data-table-cell ${row.bold ? " bold" : ""}`}>
                                                        <div className="float-right">{rowItem}</div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })
                                : false}
                        </div>
                    </div>
                )}
                <div className={`cca-card-container${this.props.noPadding ? " no-padding" : ""}${this.props.smallFont ? " small-font" : ""}`}>{this.props.children}</div>
            </div>
        );
    }
}
