// IMPORT PACKAGE REFERENCES
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// IMPORT PROJECT REFERENCES
import { getProductGroups, saveProductGroupMaterials } from "../../../state/actions/ProductGroupActions";
import { getCustomerTargets } from "../../../state/actions/CustomerTargetActions";

// UI
import { ScenarioCustomerProfitStory } from "./ScenarioCustomerProfitStory";
import { Loading } from "../../../containers/loading/Loading";

export const FormContext = React.createContext();

// COMPONENT
class CustomerProfitStoryPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            groups: props.groups,
            materials: props.materials,
            customerProfitStoryGroups: [
                {
                    groupName: "Product",
                    hideGroupName: true,
                    active: true,
                    color: "#475364",
                    dataPoints: [
                        { editable: false, orderable: true, name: "Delete", bold: true, type: "delete", align: "left", dataIdentifier: "delete", width: 50, maxWidth: 50, disabled: false },
                        { editable: false, orderable: true, name: "Product Description", bold: true, align: "left", dataIdentifier: "MG1FriendlyDesc", width: 250, disabled: true, format: { type: "string" } },
                        { editable: false, orderable: true, name: "Product Category", align: "left", dataIdentifier: "BeverageCategory", width: 250, disabled: true, format: { type: "string" } },
                        { editable: true, orderable: true, name: "Selling Litres", align: "right", dataIdentifier: "sellingLitres", width: 50, total: true, format: { type: "number", decimalPlaces: 0 } },
                        { editable: true, orderable: true, name: "Physical Cases", align: "right", dataIdentifier: "physicalCases", width: 100, total: true, format: { type: "number", decimalPlaces: 0 } },
                        { editable: false, orderable: true, name: "12 Mths Volume", disabled: true, align: "right", dataIdentifier: "avg12MOVol", width: 100, total: true, format: { type: "number", decimalPlaces: 2 } },
                        { editable: true, orderable: true, name: "RRP (Inc)", align: "right", dataIdentifier: "RRP_INC", width: 100, total: true, format: { prefix: "$", type: "dollar", decimalPlaces: 2 } },
                        { editable: false, orderable: true, name: "RRP (Exc)", disabled: true, align: "right", dataIdentifier: "RRP_EXC", width: 100, total: true, format: { type: "dollar", decimalPlaces: 2 } },
                        { editable: false, orderable: true, name: "Cost Per Unit (Exc)", disabled: true, align: "right", dataIdentifier: "NSRPerUnitCase", width: 120, total: true, format: { type: "dollar", decimalPlaces: 2 } },
                        { editable: false, orderable: true, name: "Penny-Profit (Per Unit)", disabled: true, align: "right", dataIdentifier: "ProfitPerUnit", width: 120, total: true, format: { type: "dollar", decimalPlaces: 2 } },
                        { editable: false, orderable: true, name: "Total Profit", disabled: true, align: "right", dataIdentifier: "TotalProfit", width: 100, total: true, format: { type: "dollar", decimalPlaces: 2 } },
                        { editable: false, orderable: true, name: "% Margin", disabled: true, align: "right", dataIdentifier: "PercentMargin", width: 100, format: { type: "percentage", decimalPlaces: 1 } },
                    ],
                },
            ],
        };
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        return <ScenarioCustomerProfitStory bindRefresh={this.props.bindRefresh} getAlerts={this.props.getAlerts} onRef={this.props.onRef} setFloatingButtons={this.props.setFloatingButtons} disableRemove={this.props.disableRemove} enableRemove={this.props.enableRemove} groups={this.state.customerProfitStoryGroups} bindExport={this.props.bindExport} />;
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { productGroups, fetching } = state.productGroupReducer;
    return { productGroups, fetching };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getProductGroups, saveProductGroupMaterials, getCustomerTargets }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerProfitStoryPage));

// EXPORT COMPONENT
export { hoc as CustomerProfitStoryPage };
