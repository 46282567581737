// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { Formik, FastField } from "formik";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";

// Actions
import { addNonFinancial } from "../../../state/actions/NonFinancialActions";
import { getContractSummarySnapshot } from "../../../state/actions/SummaryActions";

// UI
import { Col, Row, Button, FormGroup, Label } from "reactstrap";
import { ValidatedInput } from "../../../containers/validation/ValidatedInput";
import { Loading } from "../../../containers/loading/Loading";
import { Message } from "../../../containers/layout/Message";

const validationSchema = Yup.object().shape({
    nonFinancialType: Yup.string().notOneOf(["None"], "Type required").required("Type required"),
    type: Yup.string().required("Name required"),
    value: Yup.number().moreThan(0, "Value must be greater than 0").required("Value required"),
    quantity: Yup.number().moreThan(0, "Quantity must be greater than 0").integer("Quantity must be a whole number").required("Quantity required"),
});

// COMPONENT
class NewNonFinancialPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalShowing: false,
            saving: false,
            messageActive: false,
            messageError: false,
        };
    }

    render() {
        return (
            <div className="modalised-content">
                <Message error={this.state.messageError} active={this.state.messageActive} message="Error adding incentive" />
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        nonFinancialType: "None",
                        type: "",
                        value: 0,
                        quantity: 1,
                        description: "",
                    }}
                    onSubmit={(values) => {
                        this.setState({ saving: true });
                        this.props.addNonFinancial(this.props.contractId, this.props.scenarioId, values, (success, newNonFinancialId) => {
                            this.setState({ saving: false });

                            if (success) {
                                this.props.onClose();
                                this.props.getContractSummarySnapshot(this.props.match.params.contractId, this.props.match.params.scenarioId);
                                this.props.history.push(`/contracts/${this.props.contractId}/${this.props.scenarioId}/equipment/${newNonFinancialId}?section=other`);
                            } else {
                                this.setState({ messageActive: true, messageError: true });
                                setTimeout(() => {
                                    this.setState({ messageActive: false, messageError: false });
                                }, 2000);
                            }
                        });
                    }}
                    render={({ values, handleSubmit, setFieldValue, errors, touched }) => (
                        <Fragment>
                            <Row form>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="type">Name</Label>
                                        <ValidatedInput touched={touched.type} error={errors.type} field="type" setFieldValue={setFieldValue} name="type" value={values.type} type="text" id="type" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="nonFinancialType">Type</Label>
                                        <ValidatedInput touched={touched.nonFinancialType} error={errors.nonFinancialType} field="nonFinancialType" setFieldValue={setFieldValue} name="nonFinancialType" value={values.nonFinancialType} type="select" id="nonFinancialType" inputFormat="select">
                                            <option disabled selected value="None">
                                                {" "}
                                                -- Non-Financial Type --{" "}
                                            </option>
                                            <option value="Rent">Rent</option>
                                            <option value="Other">Other</option>
                                        </ValidatedInput>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="value">Estimated Value</Label>
                                        <ValidatedInput touched={touched.value} error={errors.value} field="value" setFieldValue={setFieldValue} name="value" value={values.value} type="number" id="value" inputFormat="dollar" />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        {values.nonFinancialType !== "Other" ? (
                                            <Fragment />
                                        ) : (
                                                <Fragment>
                                                    <Label for="quantity">Quantity</Label>
                                                    <ValidatedInput touched={touched.quantity} error={errors.quantity} field="quantity" setFieldValue={setFieldValue} name="quantity" value={values.quantity} type="text" id="quantity" />
                                                </Fragment>
                                            )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label for="description">Description</Label>
                                <ValidatedInput touched={touched.description} error={errors.description} field="description" setFieldValue={setFieldValue} name="description" value={values.description} type="text" id="description" />
                            </FormGroup>
                            <Button block onClick={handleSubmit}>
                                {!this.state.saving && <div> Add Additional Incentive </div>}
                                {this.state.saving && <Loading small light />}
                            </Button>
                        </Fragment>
                    )}
                />
            </div>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { } = state.nonFinancialReducer;
    return {};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ addNonFinancial, getContractSummarySnapshot }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(NewNonFinancialPage));

// EXPORT COMPONENT

export { hoc as NewNonFinancialPage };
