// *** PACKAGE REFERENCES *** //
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// *** ACTIONS *** //
import { getContractDetails, saveContractDetails } from "../../../state/actions/ContractDetailsActions";
import { showError, hideError } from "../../../state/actions/GeneralActions";

// *** UI *** //
import { ContextPage } from "../../../containers/layout/ContextPage";
import { Card } from "../../../containers/card/Card";
import { CardRow } from "../../../containers/card/CardRow";
import { Formik } from "formik";
import { CCAInputTypes } from "../../../enums/CCAInputTypes";
import { CCAInput } from "../../../containers/inputs/CCAInput";
import { CCAError } from "../../../enums/CCAError";
import Select from 'react-select'

class KeyBusinessDriversPage extends React.Component {
    constructor(props) {
        super(props);

        this.valuesAtLastSave = {};
        this.firstPourCategories = [
            { value: "Bourbon", label: "Bourbon" },
            { value: "Whisky/ey", label: "Whisky/ey" },
            { value: "Gin", label: "Gin" },
            { value: "Vodka", label: "Vodka" },
            { value: "Tequila", label: "Tequila" },
            { value: "White Rum", label: "White Rum" },
            { value: "Gold/Dark Rum", label: "Gold/Dark Rum" },
            { value: "Brandy", label: "Brandy" },
            // { value: "Can/PMX CSD", label: "Can/PMX CSD" },
            // { value: "Glass/PMX CSD", label: "Glass/PMX CSD" },
            // { value: "PMX CSD", label: "PMX CSD" },
            // { value: "Juice & Cordial", label: "Juice & Cordial" },
            // { value: "Coffee", label: "Coffee" },
            // { value: "Tap Beer", label: "Tap Beer" },
            // { value: "Cider", label: "Cider" },
            // { value: "Spirits & RTDs", label: "Spirits & RTDs" },
            // { value: "Glass Water", label: "Glass Water" },
            // { value: "Cider & Spirits", label: "Cider & Spirits" },
            // { value: "Premium Mixers", label: "Premium Mixers" },
            // { value: "Cider & Coffee", label: "Cider & Coffee" },
            // { value: "Table Water", label: "Table Water" },
            // { value: "Spirits", label: "Spirits" },
            // { value: "Beer", label: "Beer" },
            // { value: "RTDs & Coffee", label: "RTDs & Coffee" }
        ]

        this.getDetails = this.getDetails.bind(this);
        this.getDefaultValueFirstPour = this.getDefaultValueFirstPour.bind(this);
    }

    componentDidMount() {
        this.getDetails();
    }

    getDetails() {
        this.props.getContractDetails(this.props.match.params.contractId, (success) => {
            if (success) {
                this.valuesAtLastSave = this.props.contractDetails.keyBusinessDrivers;
                this.props.hideError(CCAError.GET_CONTRACT_DETAILS_ERROR);
            } else {
                this.props.showError(CCAError.GET_CONTRACT_DETAILS_ERROR);
            }
        });
    }

    getDefaultValueFirstPour(prevValue, setValue, submit) {
        if (prevValue == null) {
            // Generate value from subtrade channel
            // var subtradeChannel = this.props.contractDetails.general.subtradeChannel;
            // if (subtradeChannel == null) return null;
            // let leadProducts = [];
            // switch (subtradeChannel) {
            //     case "TRADITIONALPUB":
            //         leadProducts = ["Can/PMX CSD", "Juice & Cordial", "Coffee", "Tap Beer", "Cider", "Spirits & RTDs"]
            //         break;
            //     case "SMARTSUBURBAN":
            //         leadProducts = ["Glass/PMX CSD", "Juice & Cordial", "Coffee", "Glass Water", "Tap Beer", "Cider & Spirits"]
            //         break;
            //     case "ACCOMMODATION":
            //         leadProducts = ["Glass/PMX CSD", "Premium Mixers", "Coffee", "Glass Water", "Spirits", "Beer", "Cider & Coffee"]
            //         break;
            //     case "SOCIALDINING":
            //         leadProducts = ["Glass/PMX CSD", "Premium Mixers", "Table Water", "Spirits", "Beer", "Cider & Coffee"]
            //         break;
            //     case "FAMILYDINING":
            //         leadProducts = ["Glass/PMX CSD", "Juice & Cordial", "Coffee", "Tap Beer", "Cider", "Spirits", "RTDs & Coffee"]
            //         break;
            //     case "MEMBEREDCLUB":
            //         leadProducts = ["Can/PMX CSD", "Juice & Cordial", "Coffee", "Tap Beer", "Cider", "Spirits", "RTDs & Coffee"]
            //         break;
            //     case "BIGNIGHTOUT":
            //         leadProducts = ["Can/PMX CSD", "Juice & Cordial", "Tap Beer", "Cider", "Spirits & RTDs"]
            //         break;
            //     case "CONTEMPORARYBAR":
            //         leadProducts = ["PMX CSD", "Premium Mixers", "Table Water", "Spirits", "Beer", "Cider & Coffee"]
            //         break;
            //     case "NICHEBAR":
            //         leadProducts = ["Glass CSD", "Premium Mixers", "Table Water", "Spirits", "Beer & Cider"]
            //         break;
            //     default:
            //         leadProducts = [];
            //         break;
            // }
            // let filteredBySubtradeChannel = this.firstPourCategories.filter((item) => leadProducts.includes(item.value));
            // setValue("Exclusive First Pour Spirit Categories", filteredBySubtradeChannel);
            // submit();
            // return filteredBySubtradeChannel;
            return [];
        } else {
            return prevValue;
        }
    }

    saveContractDetails(values) {
        this.props.saveContractDetails(this.props.match.params.contractId, values, "keyBusinessDrivers", (success) => {
            if (success) {
                this.props.hideError(CCAError.SAVE_CONTRACT_DETAILS_ERROR);
                this.valuesAtLastSave = values;
            } else {
                this.props.showError(CCAError.SAVE_CONTRACT_DETAILS_ERROR, () => {
                    this.saveContractDetails(values);
                });
            }
        });
    }

    render() {
        return (
            <ContextPage pinned={this.props.pinned} handlePin={() => this.props.handlePin("keyBusinessDrivers")} pin={true} fetching={this.props.fetching} fetched={this.props.fetched} title={this.props.title}>
                <Formik
                    enableReinitialize={true}
                    initialValues={this.props.contractDetails.keyBusinessDrivers}
                    onSubmit={(values) => {
                        if (JSON.stringify(values) !== JSON.stringify(this.valuesAtLastSave)) {
                            this.saveContractDetails(values);
                        }
                    }}
                    render={({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldTouched, setFieldValue }) => (
                        <div>
                            <Card>
                                <CardRow label={"Combos"} top={true}>
                                    <CCAInput
                                        type={CCAInputTypes.CHECKBOX}
                                        value={values.combos}
                                        name="combos"
                                        onChange={(name, event) => {
                                            setFieldValue(name, event);
                                        }}
                                        onSave={(e) => {
                                            handleSubmit(e);
                                        }}
                                    />
                                </CardRow>

                                <CardRow label={"Core Range"}>
                                    <CCAInput
                                        type={CCAInputTypes.CHECKBOX}
                                        value={values.coreRange}
                                        name="coreRange"
                                        onChange={(name, event) => {
                                            setFieldValue(name, event);
                                        }}
                                        onSave={(e) => {
                                            handleSubmit(e);
                                        }}
                                    />
                                </CardRow>

                                <CardRow label={"Displays"}>
                                    <CCAInput
                                        type={CCAInputTypes.CHECKBOX}
                                        value={values.displays}
                                        name="displays"
                                        onChange={(name, event) => {
                                            setFieldValue(name, event);
                                        }}
                                        onSave={(e) => {
                                            handleSubmit(e);
                                        }}
                                    />
                                </CardRow>

                                <CardRow label={"Fridge Relay"}>
                                    <CCAInput
                                        type={CCAInputTypes.CHECKBOX}
                                        value={values.fridgeRelay}
                                        name="fridgeRelay"
                                        onChange={(name, event) => {
                                            setFieldValue(name, event);
                                        }}
                                        onSave={(e) => {
                                            handleSubmit(e);
                                        }}
                                    />
                                </CardRow>

                                <CardRow label={"Hotspot"}>
                                    <CCAInput
                                        type={CCAInputTypes.CHECKBOX}
                                        value={values.hotspot}
                                        name="hotspot"
                                        onChange={(name, event) => {
                                            setFieldValue(name, event);
                                        }}
                                        onSave={(e) => {
                                            handleSubmit(e);
                                        }}
                                    />
                                </CardRow>

                                <CardRow label={"Impulse Cooler"}>
                                    <CCAInput
                                        type={CCAInputTypes.CHECKBOX}
                                        value={values.impulseCooler}
                                        name="impulseCooler"
                                        onChange={(name, event) => {
                                            setFieldValue(name, event);
                                        }}
                                        onSave={(e) => {
                                            handleSubmit(e);
                                        }}
                                    />
                                </CardRow>

                                <CardRow label={"Share of Space"}>
                                    <CCAInput
                                        type={CCAInputTypes.PERCENT}
                                        name="shareOfSpace"
                                        value={values.shareOfSpace}
                                        onChange={(value) => {
                                            setFieldValue("shareOfSpace", value);
                                        }}
                                        onSave={(e) => {
                                            handleSubmit(e);
                                        }}
                                    />
                                </CardRow>

                                <CardRow label={"Agreed Retails"}>
                                    <CCAInput
                                        type={CCAInputTypes.CHECKBOX}
                                        value={values.agreedRetails}
                                        name="agreedRetails"
                                        onChange={(name, event) => {
                                            setFieldValue(name, event);
                                        }}
                                        onSave={(e) => {
                                            handleSubmit(e);
                                        }}
                                    />
                                </CardRow>

                                <CardRow longform label={"Extras"} bottom={true}>
                                    <CCAInput
                                        type={CCAInputTypes.DESCRIPTION}
                                        name="extras"
                                        value={values.extras}
                                        onChange={handleChange}
                                        onSave={(e) => {
                                            handleSubmit(e);
                                        }}
                                    />
                                </CardRow>
                            </Card>
                            <Card>
                                <h3>Alcohol Specific Key Business Drivers</h3>
                                <CardRow label={"Soft Drinks"} top>
                                    <CCAInput
                                        type={CCAInputTypes.CHECKBOX}
                                        value={values.SoftDrinks}
                                        name="SoftDrinks"
                                        onChange={(name, event) => {
                                            setFieldValue(name, event);
                                        }}
                                        onSave={handleSubmit}
                                    />
                                </CardRow>
                                <CardRow label={"Mixers"}>
                                    <CCAInput
                                        type={CCAInputTypes.CHECKBOX}
                                        value={values.Mixers}
                                        name="Mixers"
                                        onChange={(name, event) => {
                                            setFieldValue(name, event);
                                        }}
                                        onSave={handleSubmit}
                                    />
                                </CardRow>
                                <CardRow label={"Premium Mixers"}>
                                    <CCAInput
                                        type={CCAInputTypes.CHECKBOX}
                                        value={values.PremiumMixers}
                                        name="PremiumMixers"
                                        onChange={(name, event) => {
                                            setFieldValue(name, event);
                                        }}
                                        onSave={handleSubmit}
                                    />
                                </CardRow>
                                <CardRow label={"Ginger Beer"}>
                                    <CCAInput
                                        type={CCAInputTypes.CHECKBOX}
                                        value={values.GingerBeer}
                                        name="GingerBeer"
                                        onChange={(name, event) => {
                                            setFieldValue(name, event);
                                        }}
                                        onSave={handleSubmit}
                                    />
                                </CardRow>
                                <CardRow label={"Energy"}>
                                    <CCAInput
                                        type={CCAInputTypes.CHECKBOX}
                                        value={values.Energy}
                                        name="Energy"
                                        onChange={(name, event) => {
                                            setFieldValue(name, event);
                                        }}
                                        onSave={handleSubmit}
                                    />
                                </CardRow>
                                <CardRow label={"Water"}>
                                    <CCAInput
                                        type={CCAInputTypes.CHECKBOX}
                                        value={values.Water}
                                        name="Water"
                                        onChange={(name, event) => {
                                            setFieldValue(name, event);
                                        }}
                                        onSave={handleSubmit}
                                    />
                                </CardRow>
                                <CardRow label={"Juice"}>
                                    <CCAInput
                                        type={CCAInputTypes.CHECKBOX}
                                        value={values.Juice}
                                        name="Juice"
                                        onChange={(name, event) => {
                                            setFieldValue(name, event);
                                        }}
                                        onSave={handleSubmit}
                                    />
                                </CardRow>
                                <CardRow label={"Cordial"}>
                                    <CCAInput
                                        type={CCAInputTypes.CHECKBOX}
                                        value={values.Cordial}
                                        name="Cordial"
                                        onChange={(name, event) => {
                                            setFieldValue(name, event);
                                        }}
                                        onSave={handleSubmit}
                                    />
                                </CardRow>
                                <CardRow label={"Coffee"}>
                                    <CCAInput
                                        type={CCAInputTypes.CHECKBOX}
                                        value={values.Coffee}
                                        name="Coffee"
                                        onChange={(name, event) => {
                                            setFieldValue(name, event);
                                        }}
                                        onSave={handleSubmit}
                                    />
                                </CardRow>
                                <CardRow label={"Share of Back Bar Spirits"}>
                                    <Select
                                        onBlur={(e) => { handleSubmit(e) }}
                                        defaultValue={{ value: values.ShareOfBackBar, label: values.ShareOfBackBar }}
                                        options={[
                                            { value: "None", label: "None" },
                                            { value: "<15%", label: "<15%" },
                                            { value: "15-25%", label: "15-25%" },
                                            { value: ">25%", label: ">25%" }
                                        ]}
                                        onChange={(event) => {
                                            setFieldValue("ShareOfBackBar", event.value);
                                            handleSubmit(values);
                                        }}
                                        onSave={handleSubmit}
                                    >
                                    </Select>
                                </CardRow>
                                <CardRow label={"Number Of Taps"}>
                                    <Select
                                        onBlur={(e) => { handleSubmit(e) }}
                                        defaultValue={{ value: values.NumberOfTaps, label: values.NumberOfTaps }}
                                        options={[
                                            { value: "1", label: "1" },
                                            { value: "2", label: "2" },
                                            { value: "3", label: "3" },
                                            { value: "4+", label: "4+" }
                                        ]}
                                        onChange={(event) => {
                                            setFieldValue("NumberOfTaps", event.value);
                                            handleSubmit(values);
                                        }}
                                        onSave={handleSubmit}
                                    >
                                    </Select>
                                </CardRow>
                                <CardRow label={"Number of Packaged ARTD SKU’s Ranged"}>
                                    <Select
                                        onBlur={(e) => { handleSubmit(e) }}
                                        defaultValue={{ value: values["Number of Packaged ARTD SKU’s Ranged"], label: values["Number of Packaged ARTD SKU’s Ranged"] }}
                                        options={[
                                            { value: "1", label: "1" },
                                            { value: "2", label: "2" },
                                            { value: "3", label: "3" },
                                            { value: "4", label: "4" },
                                            { value: "5", label: "5" },
                                            { value: "6", label: "6" },
                                            { value: "7+", label: "7+" }
                                        ]}
                                        onChange={(event) => {
                                            setFieldValue("Number of Packaged ARTD SKU’s Ranged", event.value);
                                            handleSubmit(values);
                                        }}
                                        onSave={handleSubmit}
                                    >
                                    </Select>
                                </CardRow>
                                <CardRow label={"Exclusive First Pour Spirit Categories"}>
                                    <Select
                                        onBlur={(e) => { handleSubmit(e) }}
                                        defaultValue={this.getDefaultValueFirstPour(values["Exclusive First Pour Spirit Categories"], setFieldValue, handleSubmit)}
                                        options={this.firstPourCategories}
                                        isMulti
                                        onChange={(event) => {
                                            setFieldValue("Exclusive First Pour Spirit Categories", event);
                                            handleSubmit(values);
                                        }}
                                        onSave={handleSubmit}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                </CardRow>
                                <CardRow label={"Menu Activation"}>
                                    <CCAInput
                                        type={CCAInputTypes.CHECKBOX}
                                        value={values.MenuActivation}
                                        name="MenuActivation"
                                        onChange={(name, event) => {
                                            setFieldValue(name, event);
                                        }}
                                        onSave={handleSubmit}
                                    />
                                </CardRow>
                                <CardRow label={"Perfect Serve"}>
                                    <CCAInput
                                        type={CCAInputTypes.CHECKBOX}
                                        value={values.PerfectServe}
                                        name="PerfectServe"
                                        onChange={(name, event) => {
                                            setFieldValue(name, event);
                                        }}
                                        onSave={handleSubmit}
                                    />
                                </CardRow>
                                <CardRow label={"Minimum Bar/Venue Activation"} bottom>
                                    <CCAInput
                                        type={CCAInputTypes.CHECKBOX}
                                        value={values.MinimumActivation}
                                        name="MinimumActivation"
                                        onChange={(name, event) => {
                                            setFieldValue(name, event);
                                        }}
                                        onSave={handleSubmit}
                                    />
                                </CardRow>
                                {/* For Licensed KBD's, I see that this list is there:
                        Can I get examples / Full lists for the ones with !! Beside them please
                        Exclusive Coffee
                        Exclusive First Pour Spirit Categories (Bourbon, Vodka etc) !!
                        Share of Back Bar Spirits (<15%, 15-25% or >25%)
                        Number of Taps (1, 2, 3 or 4+)
                        Number of Packaged ARTD SKU’s Ranged (1-7)
                        Menu Activation !! (Is it a true/ false or are there multiple options)
                        Perfect Serve !! same as above
                        Minimum Bar/Venue Activation !! Same as above */}
                            </Card>
                        </div>
                    )}
                />
            </ContextPage>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { fetching, fetched, failed, contractDetails } = state.contractDetailsReducer;
    return { fetching, fetched, failed, contractDetails };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getContractDetails,
            saveContractDetails,
            showError,
            hideError,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(KeyBusinessDriversPage));

// EXPORT COMPONENT
export { hoc as KeyBusinessDriversPage };
