// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// IMPORT PROJECT REFERENCES
import { PageTitle } from "../../containers/layout/PageTitle";

// COMPONENT
class ContractPage extends React.Component {
    render() {
        return (
            <Fragment>
                <PageTitle title={"Contract " + this.props.match.params.contractId} />
            </Fragment>
        );
    }
}

ContractPage.propTypes = {
    match: PropTypes.object.isRequired,
};

const hoc = withRouter(ContractPage);

// EXPORT COMPONENT
export { hoc as ContractPage };
