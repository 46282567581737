// IMPORT PACKAGE REFERENCES
import { createStore, applyMiddleware } from "redux";

// IMPORT MIDDLEWARE
import thunk from "redux-thunk";
import promiseMiddleware from "redux-promise-middleware";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native

// IMPORT REDUCERS
import { AppReducer } from "../reducers/AppReducer";

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, AppReducer);

// CONFIGURE STORE
export const createAppStore = () => {
    let store = createStore(persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(), applyMiddleware(thunk, promiseMiddleware()));
    let persistor = persistStore(store);
    return { store, persistor };
};
