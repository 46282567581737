import { PAGE_CHANGE_FULFILLED } from "../actions/NavigationActions";

import { CLEAR_REDUCERS } from "../actions/GeneralActions";

import { Page } from "../../enums/Page";

// INITIALIZE STATE
const initialState = {
    page: Page.HOME,
};

// REDUCER
export const NavigationReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_REDUCERS:
            return initialState;
        case PAGE_CHANGE_FULFILLED:
            return {
                ...state,
                page: action.payload,
            };
        default:
            return state;
    }
};
