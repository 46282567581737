// IMPORT PACKAGE REFERENCES
import React from "react";
import FeatherIcon from "feather-icons-react";

// COMPONENT
export class Checkbox extends React.Component {
    constructor(props) {
        super(props);

        let checked = props.checked === undefined ? false : props.checked;

        if (props.initialChecked) {
            checked = props.initialChecked;
        }

        this.state = {
            checked,
            editing: props.editing === undefined ? true : props.editing,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.observe) {
            if (this.state.checked !== this.props.checked) {
                this.setState({ checked: this.props.checked });
            }
        }

        if (this.props.editing !== undefined) {
            if (this.state.editing !== this.props.editing) {
                this.setState({ editing: this.props.editing });
            }
        }

        if (prevProps.checked !== this.props.checked) {
            this.setState({ checked: this.props.checked });
        }

        if (this.props.checked !== this.state.checked) {
            this.setState({ checked: this.props.checked });
        }
    }

    handleCheck() {
        if (this.props.hasOwnProperty("checkable")) {
            if (!this.props.checkable) {
                return;
            }
        }

        if (!this.props.observe) {
            this.setState({ checked: !this.state.checked });
            this.props.handleClick(!this.state.checked);
        }
    }

    render() {
        let checkbox = (
            <div
                tabIndex="0"
                onClick={() => {
                    this.handleCheck();
                }}
                className={"checkbox" + (this.state.checked ? " active" : "") + (this.state.editing ? " enabled" : " disabled")}
            >
                <FeatherIcon icon="check"></FeatherIcon>
            </div>
        );

        if (this.props.label) {
            return (
                <div onClick={() => this.handleCheck()} className="checkbox-container">
                    {checkbox}
                    <div className="checkbox-label">{this.props.label}</div>
                </div>
            );
        } else {
            return checkbox;
        }
    }
}
