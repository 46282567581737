import axios from "axios";
import { API_ROOT, APPROVALS_ROOT } from "../../../config/config";

export const GET_DOCUMENT_LIST_PENDING = "GET_DOCUMENT_LIST_PENDING";
export const GET_DOCUMENT_LIST_FULFILLED = "GET_DOCUMENT_LIST_FULFILLED";
export const GET_DOCUMENT_LIST_FAILED = "GET_DOCUMENT_LIST_FAILED";

export const DELETE_DOCUMENT_PENDING = "DELETE_DOCUMENT_PENDING";
export const DELETE_DOCUMENT_FULFILLED = "DELETE_DOCUMENT_FULFILLED";
export const DELETE_DOCUMENT_FAILED = "DELETE_DOCUMENT_FAILED";

const getDocumentList = (contractId, scenarioId, callback) => (dispatch) => {
    dispatch({
        type: GET_DOCUMENT_LIST_PENDING
    });

    axios.get(`${APPROVALS_ROOT}/GetDocumentList?ContractId=${contractId}&ScenarioId=${scenarioId}`)
        .then((res) => {
            dispatch({
                type: GET_DOCUMENT_LIST_FULFILLED,
                payload: res.data,
            });
            if(callback) callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting document list", error);
            dispatch({
                type: GET_DOCUMENT_LIST_FAILED,
            });

            if(callback) callback(false);
        });
}

const deleteDocument = (location, contractId, scenarioId, callback) => (dispatch) => {
    dispatch({
        type: DELETE_DOCUMENT_PENDING
    });

    axios.post(`${APPROVALS_ROOT}/DeleteDocument?Location=${location}&ContractId=${contractId}&ScenarioId=${scenarioId}`)
        .then((res) => {
            dispatch({
                type: DELETE_DOCUMENT_FULFILLED,
                payload: res.data,
            });
            if(callback) callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting document list", error);
            dispatch({
                type: DELETE_DOCUMENT_FAILED,
            });

            if(callback) callback(false);
        });
}

const sendContractToPnR = (contractId, scenarioId, callback) => (dispatch) => {
    dispatch({
        type: DELETE_DOCUMENT_PENDING
    });
    axios.post(`${APPROVALS_ROOT}/SubmitToPNR?ContractId=${contractId}&ScenarioId=${scenarioId}`)
        .then((res) => {
            dispatch({
                type: DELETE_DOCUMENT_FULFILLED,
                payload: res.data,
            });
            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting document list", error);
            dispatch({
                type: DELETE_DOCUMENT_FAILED,
            });

            callback(false);
        });
}

export { getDocumentList, deleteDocument, sendContractToPnR };