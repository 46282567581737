// IMPORT PACKAGE REFERENCES
import React from "react";
import ReactDOM from "react-dom";
import { Loading } from "../loading/Loading";

// COMPONENT
export default class InfoLoader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showing: false,
        };
    }

    componentDidUpdate() {
        if (this.state.showing !== this.props.showing) {
            this.setState({ showing: this.props.showing });
        }
    }

    render() {
        return ReactDOM.createPortal(
            <div className={"modalised-container" + (this.state.showing ? " active" : "") + (this.props.pageModal ? " pageModal" : "")}>
                <div className={"info-loader"}>
                    <Loading medium noPadding />
                    <div className="info-loader-text">{this.props.text}</div>
                </div>
            </div>,
            document.body
        );
    }
}
