// *** PACKAGE REFERENCES *** //
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button } from "reactstrap";

// *** ACTIONS *** //
import { getContractDetails, saveContractDetails } from "../../../state/actions/ContractDetailsActions";
import { getGlobalDetails } from "../../../state/actions/ConfigurationActions";
import { showError, hideError } from "../../../state/actions/GeneralActions";

// *** UI *** //
import { ContextPage } from "../../../containers/layout/ContextPage";
import { Card } from "../../../containers/card/Card";
import { CardRow } from "../../../containers/card/CardRow";
import { Formik } from "formik";
import { CCAInput } from "../../../containers/inputs/CCAInput";
import { CCAInputTypes } from "../../../enums/CCAInputTypes";
import { CCAError } from "../../../enums/CCAError";
import { AccessDenied } from "../../../shared/AccessDenied";

class EscalatorAssumptionsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            escalators: {},
        };

        this.valuesAtLastSave = {};
        this.getDetails = this.getDetails.bind(this);
    }

    componentDidMount() {
        if (JSON.stringify(this.state.escalators) !== JSON.stringify(this.props.contractDetails.escalators)) {
            this.setState({ escalators: this.props.contractDetails.escalators });
        }
    }

    getDetails() {
        this.props.getContractDetails(this.props.match.params.contractId, (success) => {
            if (success) {
                this.valuesAtLastSave = this.props.contractDetails.goalsAndNeeds;
                this.props.hideError(CCAError.GET_CONTRACT_DETAILS_ERROR);
            } else {
                this.props.showError(CCAError.GET_CONTRACT_DETAILS_ERROR);
            }
        });
    }

    saveContractDetails(values) {
        this.props.saveContractDetails(this.props.match.params.contractId, values, "escalators", (success) => {
            if (success) {
                this.props.hideError(CCAError.SAVE_CONTRACT_DETAILS_ERROR);
                this.valuesAtLastSave = values;
            } else {
                this.props.showError(CCAError.SAVE_CONTRACT_DETAILS_ERROR, () => {
                    this.saveContractDetails(values);
                });
            }
        });
    }

    resetToDefault() {
        this.props.getGlobalDetails((success, globalDetails) => {
            let newEscalators = Object.assign({}, this.state.escalators);

            if (success) {
                let attributesToDefault = ["volume", "sellingPrice", "inflationRate", "costOfSales", "deliveryCost", "indirectCosts", "rent"];

                attributesToDefault.map((attribute) => {
                    if (globalDetails.hasOwnProperty(attribute)) {
                        let defaultValue = globalDetails[attribute];
                        newEscalators[attribute] = defaultValue;
                    } else {
                        newEscalators[attribute] = 0;
                    }
                });

                this.setState({ escalators: newEscalators }, () => {
                    this.resetForm();
                });
            }

            // Save the new defaults...
            this.saveContractDetails(newEscalators);
        });
    }

    render() {
        if (this.props.locked) {
            return <AccessDenied />;
        }

        return (
            <ContextPage
                firstElement={
                    <Button
                        className="menu-action-button"
                        color="link"
                        onClick={() => {
                            this.resetToDefault();
                        }}
                    >
                        Reset to Default
                    </Button>
                }
                fetching={this.props.fetching}
                fetched={this.props.fetched}
                title={this.props.title}
            >
                <Formik
                    enableReinitialize={true}
                    initialValues={this.state.escalators}
                    onSubmit={(values) => {
                        if (JSON.stringify(values) !== JSON.stringify(this.valuesAtLastSave)) {
                            this.saveContractDetails(values);
                        }
                    }}
                    render={({ values, errors, touched, handleBlur, handleChange, resetForm, handleSubmit, setFieldTouched, setFieldValue }) => {
                        this.resetForm = resetForm;

                        return (
                            <Card>
                                <CardRow label={"Volume"} top={true}>
                                    <CCAInput
                                        type={CCAInputTypes.PERCENT}
                                        name="volume"
                                        value={values.volume}
                                        onChange={(value) => {
                                            setFieldValue("volume", value);
                                        }}
                                        onSave={handleSubmit}
                                    />
                                </CardRow>

                                <CardRow label={"Selling Price"}>
                                    <CCAInput
                                        type={CCAInputTypes.PERCENT}
                                        name="sellingPrice"
                                        value={values.sellingPrice}
                                        onChange={(value) => {
                                            setFieldValue("sellingPrice", value);
                                        }}
                                        onSave={handleSubmit}
                                    />
                                </CardRow>

                                <CardRow label={"Inflation Rate (CPI)"}>
                                    <CCAInput
                                        type={CCAInputTypes.PERCENT}
                                        name="inflationRate"
                                        value={values.inflationRate}
                                        onChange={(value) => {
                                            setFieldValue("inflationRate", value);
                                        }}
                                        onSave={handleSubmit}
                                    />
                                </CardRow>

                                <CardRow label={"Cost of Sales"}>
                                    <CCAInput
                                        type={CCAInputTypes.PERCENT}
                                        name="costOfSales"
                                        value={values.costOfSales}
                                        onChange={(value) => {
                                            setFieldValue("costOfSales", value);
                                        }}
                                        onSave={handleSubmit}
                                    />
                                </CardRow>

                                <CardRow label={"Delivery Cost"}>
                                    <CCAInput
                                        type={CCAInputTypes.PERCENT}
                                        name="deliveryCost"
                                        value={values.deliveryCost}
                                        onChange={(value) => {
                                            setFieldValue("deliveryCost", value);
                                        }}
                                        onSave={handleSubmit}
                                    />
                                </CardRow>

                                <CardRow label={"Indirect Costs"}>
                                    <CCAInput
                                        type={CCAInputTypes.PERCENT}
                                        name="indirectCosts"
                                        value={values.indirectCosts}
                                        onChange={(value) => {
                                            setFieldValue("indirectCosts", value);
                                        }}
                                        onSave={handleSubmit}
                                    />
                                </CardRow>

                                <CardRow label={"Rent"} bottom={true}>
                                    <CCAInput
                                        type={CCAInputTypes.PERCENT}
                                        name="rent"
                                        value={values.rent}
                                        onChange={(value) => {
                                            setFieldValue("rent", value);
                                        }}
                                        onSave={handleSubmit}
                                    />
                                </CardRow>
                            </Card>
                        );
                    }}
                />
            </ContextPage>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { fetching, fetched, failed, contractDetails } = state.contractDetailsReducer;
    const { globalDetails } = state.configurationReducer;
    return { fetching, fetched, failed, contractDetails };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getContractDetails,
            saveContractDetails,
            getGlobalDetails,
            showError,
            hideError,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(EscalatorAssumptionsPage));

// EXPORT COMPONENT
export { hoc as EscalatorAssumptionsPage };
