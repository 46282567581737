import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

/* 
    PrivateRouter component to automatically redirect the user to the login page if they are not logged in.
    Otherwise, load the requested resource if they are authenticated. 
*/

export class PrivateRoute extends React.Component {
    render() {
        if (!this.props.loggedIn) {
            return <Redirect to={{ pathname: "/login", state: { from: this.props.location } }} />;
        }

        return <Route {...this.props} />;
    }
}

PrivateRoute.propTypes = {
    loggedIn: PropTypes.bool.isRequired,
    location: PropTypes.object,
};