// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import FeatherIcon from "feather-icons-react";

const keyBusinessDriversLabelMap = {
    agreedRetails: "Agreed Retails",
    combos: "Combos",
    coreRange: "Core Range",
    displays: "Displays",
    extras: "Extras",
    fridgeRelay: "Fridge Relay",
    hotspot: "Hot Spot",
    impulseCooler: "Impulse Cooler",
    shareOfSpace: "Share of Space",
};

// COMPONENT
export class KeyBusinessDriversPin extends React.Component {
    render() {
        let businessDrivers = this.props.keyBusinessDrivers !== undefined ? this.props.keyBusinessDrivers : {};

        return (
            <Fragment>
                <div className="menu-subtitle">Key Business Drivers</div>
                <div className="sidebar-summary-section">
                    {Object.keys(businessDrivers).map((keyBusinessDriversKey, keyBusinessDriversIndex) => {
                        let isExclusive = businessDrivers[keyBusinessDriversKey];
                        let boolValue = typeof businessDrivers[keyBusinessDriversKey] === "boolean";
                        let isShareOfSpace = keyBusinessDriversKey === "shareOfSpace";
                        let isExtras = keyBusinessDriversKey === "extras";

                        return (
                            <div key={keyBusinessDriversIndex} className={"summary-row" + (keyBusinessDriversIndex % 2 === 0 ? " even" : " odd")}>
                                <div className="summary-row-item left">{keyBusinessDriversLabelMap[keyBusinessDriversKey]}</div>
                                {(boolValue || isExtras) && <div className="summary-row-item right">{isExclusive ? <FeatherIcon size="12" icon="check" /> : <FeatherIcon size="12" icon="x" />}</div>}
                                {isShareOfSpace && businessDrivers[keyBusinessDriversKey] !== "" && <div className="summary-row-item right">{businessDrivers[keyBusinessDriversKey]}%</div>}
                                {isShareOfSpace && businessDrivers[keyBusinessDriversKey] === "" && (
                                    <div className="summary-row-item right">
                                        {" "}
                                        <FeatherIcon size="12" icon="x" />{" "}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </Fragment>
        );
    }
}
