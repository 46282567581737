// IMPORT PACKAGE REFERENCES
import React from "react";

// UI
import { Button, Input, Row, Col } from "reactstrap";

// COMPONENT
export class AdditiveTable extends React.Component {
    constructor(props) {
        super(props);

        let rows = props.rows;

        if (rows === undefined) {
            rows = [];
        }

        this.state = {
            rows: rows,
            editing: false,
        };

        this.toggleEditing = this.toggleEditing.bind(this);
    }

    randomId() {
        return Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(2, 10);
    }

    getRows() {
        return this.state.rows;
    }

    addNewRow() {
        // Don't allow the insertion of more than one blank row.
        if (this.state.rows.length > 0) {
            if (this.state.rows[this.state.rows.length - 1].name.length < 1) {
                return;
            }
        }

        let newState = Object.assign({}, this.state);
        newState.rows.push({ name: "", value: false, id: this.randomId() });
        this.setState({ rows: newState.rows });
    }

    deleteRow(index) {
        if (confirm("Are you sure you want to delete?")) {
            let newState = Object.assign({}, this.state);
            newState.rows.splice(index, 1);
            this.setState({ rows: newState.rows });
        }
    }

    handleNameChange(event, index) {
        let newState = Object.assign({}, this.state);
        newState.rows[index].name = event.target.value;
        this.setState({ rows: newState.rows });
    }

    handleValueChange(event, index) {
        let newState = Object.assign({}, this.state);
        newState.rows[index].value = event.target.checked;
        this.setState({ rows: newState.rows });
    }

    toggleEditing() {
        let emptyRow = false;

        // Check that each option has some text associated...
        this.state.rows.forEach((row) => {
            if (row.name.length === 0) {
                emptyRow = true;
            }
        });

        if (emptyRow) {
            alert("All options must have associated text.");
        } else {
            if (this.state.editing) {
                // Save because we've finished editing now...
                this.props.save(this.state.rows, (success) => {
                    if (!success) {
                        alert("Failed to save condition");
                    } else {
                        console.log("Succeeded in saving conditions...", success);
                    }
                });
            }

            this.setState({ editing: !this.state.editing });
        }
    }

    render() {
        let activeCondition = this.props.activeCondition === undefined ? { id: -1 } : this.props.activeCondition;

        return (
            <div className="table-group">
                {this.state.rows.map((row, index) => {
                    return (
                        <div
                            onClick={(e) => {
                                if (!this.state.editing) {
                                    this.props.handleConditionSelected(e, row);
                                }
                            }}
                            className="material-group-condition-row"
                        >
                            <Input disabled={!this.state.editing} className={"additive-table-input " + (this.state.editing ? " " : "ineditable ") + (activeCondition.id === row.id ? "active" : "")} type="text" value={row.name} onChange={(event) => this.handleNameChange(event, index)} />

                            {this.state.editing && (
                                <Button style={{ marginLeft: "1em" }} onClick={() => this.deleteRow(index)}>
                                    Delete
                                </Button>
                            )}
                        </div>
                    );
                })}

                {this.state.editing && <Button onClick={() => this.addNewRow()}>+ New {this.props.type}</Button>}
                <Button style={{ float: "right", marginBottom: "1em" }} onClick={() => this.toggleEditing()}>
                    {this.state.editing ? "Save" : "Edit"}
                </Button>
            </div>
        );
    }
}
