export const PAGE_CHANGE_FULFILLED = "PAGE_CHANGE_FULFILLED";

// ACTION GENERATORS

/* 
    Changes the navigation store to the newly provided page.
    @parameters:
    - page: the newly selected page.
*/

const changePage = (page) => (dispatch) => {
    dispatch({
        type: PAGE_CHANGE_FULFILLED,
        payload: page,
    });
};

// EXPORT ACTIONS
export { changePage };
