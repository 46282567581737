// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PageTitle } from "../../../containers/layout/PageTitle";
import Modalised from "../../../containers/layout/Modalised";
import { NewPaymentModal } from "./NewPaymentModal";
import { ResponsiveList } from "../lists/ResponsiveList";
import { ContextualSidebar } from "../../../containers/layout/ContextualSidebar";
import { CELL_TYPES } from "../CellTypes";
import { MasterDetailRouter } from "../../../containers/layout/MasterDetailRouter";
import FeatherIcon from "feather-icons-react";

// IMPORT ACTIONS
import { getPayments, getNovelties, deletePayments } from "../../../state/actions/PaymentActions";
import { getContractSummarySnapshot } from "../../../state/actions/SummaryActions";

// UI
import { Button } from "reactstrap";
import { PaymentDetailsPage } from "./PaymentDetailsPage";
import { NoveltyDetailsPage } from "./NoveltyDetailsPage";
import { CELL_SUB_TYPES } from "../CellSubTypes";
import { Message } from "../../../containers/layout/Message";
import { NewNoveltyModal } from "./NewNoveltyModal";

// Component to add Financial Incentives to a contract.
// Financial Incentives are either upfront or annual payments, or rebates.
class FinancialIncentivesPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalShowing: false,
            modalType: "",
            messageActive: false,
            message: "",
            editingShowing: false,
        };

        this.sections = [];
        this.paymentData = [];
        this.noveltyData = [];

        this.handleClick = this.handleClick.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.toggleNewPaymentModal = this.toggleNewPaymentModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handlePaymentModalClose = this.handlePaymentModalClose.bind(this);
        this.handleNoveltyModalClose = this.handlePaymentModalClose.bind(this);
        this.reFetchData = this.reFetchData.bind(this);
        this.generateSections = this.generateSections.bind(this);
        this.toggleNewNoveltyModal = this.toggleNewNoveltyModal.bind(this);
    }

    componentDidMount() {
        this.reFetchData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.scenarioId !== this.props.match.params.scenarioId) {
            this.reFetchData(true);
        }
    }

    /**
     * Fetches the data needed to render the sections on the page (payments)
     */
    reFetchData(scenarioChanged = false) {
        let paymentsPromise = new Promise((resolve, reject) => {
            this.props.getPayments(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => {
                success ? resolve() : reject();
            });

            this.props.getNovelties(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => {
                success ? resolve() : reject();
            });
        });

        Promise.all([paymentsPromise]).then(() => {
            this.generateSections();

            if (scenarioChanged) {
                // Need to select the first item if there is one exists in any of the sections
                let found = false;

                if (this.sections.length > 0) {
                    this.sections.forEach((section) => {
                        if (section.items.length > 0) {
                            // Found a section with at least one item in it. Select it.
                            let item = section.items[0];

                            this.handleClick(item[section.identifier], section.sectionIdentifier);
                            found = true;
                        }
                    });
                }

                if (!found) {
                    this.handleClick(undefined, undefined);
                }
            }
        });
    }

    handleClick(id, sectionIdentifier) {
        this.props.history.push(id + `?section=${sectionIdentifier}`);
    }

    hideModal() {
        this.setState({ modalShowing: false });
    }

    toggleNewPaymentModal() {
        this.setState({ modalShowing: !this.state.modalShowing, modalType: "Payment" });
    }

    toggleNewNoveltyModal() {
        this.setState({ modalShowing: !this.state.modalShowing, modalType: "Novelty" });
    }

    handlePaymentModalClose() {
        this.toggleNewPaymentModal();
        // Need to re-fetch the payments and rebates
        this.props.getPayments(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
        this.props.getNovelties(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
        this.props.getContractSummarySnapshot(this.props.match.params.contractId, this.props.match.params.scenarioId);
    }

    handleNoveltyModalClose() {
        this.toggleNewNoveltyModal();
        // Need to re-fetch the payments and rebates
        this.props.getPayments(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
        this.props.getPayments(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
        this.props.getContractSummarySnapshot(this.props.match.params.contractId, this.props.match.params.scenarioId);
    }

    toggleEditing() {
        this.setState({ editingShowing: !this.state.editingShowing });
    }

    handleDelete(selectedItems) {
        if (confirm("Are you sure you want to delete these financial incentives?")) {
            let paymentsToDelete = [];
            let rebatesToDelete = [];

            selectedItems.forEach((item) => {
                if (item.section === 0) {
                    paymentsToDelete.push(item.id);
                } else {
                    rebatesToDelete.push(item.id);
                }
            });

            // Delete the payments...
            this.props.deletePayments(this.props.match.params.contractId, this.props.match.params.scenarioId, paymentsToDelete, (success) => {
                if (success) {
                    this.setState({ editingShowing: false });

                    // Update the summary values
                    this.props.getContractSummarySnapshot(this.props.params.match.contractId, this.props.params.match.scenarioId);
                } else {
                    this.setState({ messageActive: true });
                    setTimeout(() => {
                        this.setState({ messageActive: false });
                    }, 2000);
                }
            });
        }
    }

    generateSections() {
        this.paymentData = Object.keys(this.props.payments).map((key) => {
            return { paymentId: key, ...this.props.payments[key] };
        });
        this.noveltyData = Object.keys(this.props.novelties).map((key) => {
            return { paymentId: key, ...this.props.novelties[key] };
        });

        this.sections = [
            {
                items: this.paymentData,
                addLabel: "Add Payment",
                addAction: this.toggleNewPaymentModal,
                emptyLabel: "No payments",
                sectionLabel: "Payments",
                sectionIdentifier: "payments",
                identifier: "paymentId",
                page: <PaymentDetailsPage />,
                cell: {
                    type: CELL_TYPES.INDICATOR,
                    subType: CELL_SUB_TYPES.PAYMENT,
                    dataIdentifiers: { label: "type", subtitle: "description" },
                },
            },
            {
                items: this.noveltyData,
                addLabel: "Add Novelty",
                addAction: this.toggleNewNoveltyModal,
                emptyLabel: "No novelties",
                sectionLabel: "Novelties",
                sectionIdentifier: "novelties",
                identifier: "paymentId",
                page: <NoveltyDetailsPage />,
                cell: {
                    type: CELL_TYPES.INDICATOR,
                    subType: CELL_SUB_TYPES.PAYMENT,
                    dataIdentifiers: { label: "type", subtitle: "description" },
                },
            },
        ];
    }

    render() {
        this.generateSections();

        return (
            <Fragment>
                <Message error message="Error deleting financial incentives" active={this.state.messageActive} />
                <Fragment>
                    <Modalised
                        handleClose={this.hideModal}
                        title={"New " + this.state.modalType}
                        action={
                            <div
                                onClick={() => {
                                    this.hideModal();
                                }}
                                className="text-link"
                            >
                                <FeatherIcon icon="x" />
                            </div>
                        }
                        showing={this.state.modalShowing}
                    >
                        {this.state.modalType == "Payment" ? <NewPaymentModal onClose={this.handlePaymentModalClose} /> : <NewNoveltyModal onClose={this.handleNoveltyModalClose} />}
                    </Modalised>

                    <ContextualSidebar
                        pageTitle={
                            <PageTitle
                                firstElement={
                                    this.paymentData.length > 0 && (
                                        <Button color="link" onClick={() => this.toggleEditing()}>
                                            {this.state.editingShowing ? "Done" : "Edit"}
                                        </Button>
                                    )
                                }
                                title={"Financial Incentives"}
                            />
                        }
                        left={
                            <Fragment>
                                <ResponsiveList
                                    editingShowing={this.state.editingShowing}
                                    handleDelete={this.handleDelete}
                                    handleClick={this.handleClick}
                                    actions={["Delete"]}
                                    type={"item"}
                                    sections={this.sections} />
                            </Fragment>
                        }
                        right={<MasterDetailRouter sections={this.sections} />}
                    />
                </Fragment>
            </Fragment>
        );
    }
}

FinancialIncentivesPage.propTypes = {
    fetching: PropTypes.bool.isRequired,
    failed: PropTypes.bool.isRequired,
    fetched: PropTypes.bool.isRequired,
    payments: PropTypes.object.isRequired,
    novelties: PropTypes.object.isRequired,
    getPayments: PropTypes.func.isRequired,
    getNovelties: PropTypes.func.isRequired
};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { fetching, failed, fetched, payments, novelties } = state.paymentReducer;
    return { fetching, failed, fetched, payments, novelties };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getPayments, getNovelties, deletePayments, getContractSummarySnapshot }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(FinancialIncentivesPage));

// EXPORT COMPONENT

export { hoc as FinancialIncentivesPage };
