// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import FeatherIcon from "feather-icons-react";

import { EditableInputTypes } from "../../enums/EditableInputTypes";

// COMPONENT
export class EditableInput extends React.Component {
    constructor(props) {
        super(props);

        this.getBooleanIcon = this.getBooleanIcon.bind(this);
    }

    getBooleanIcon() {
        switch (this.props.readonlyLabel) {
            case true:
                return (
                    <div className="float-right">
                        {" "}
                        <img alt={"check"} src={require("../../../images/icons/check.svg")} />{" "}
                    </div>
                );
            case false:
                return (
                    <div className="float-right">
                        {" "}
                        <img alt={"x"} src={require("../../../images/icons/x.svg")} />{" "}
                    </div>
                );
        }
    }

    render() {
        return (
            <Fragment>
                {this.props.alertInput && !this.props.editable && <FeatherIcon className="editable-input-alert" icon="alert-circle" />}

                {this.props.type === EditableInputTypes.PERCENT && <div className="editable-input-small">{this.props.editable ? this.props.children : <div className="float-right"> {this.props.readonlyLabel}% </div>}</div>}

                {this.props.type === EditableInputTypes.DOLLAR && <div className="editable-input-small">{this.props.editable ? this.props.children : <div className="float-right"> ${this.props.readonlyLabel} </div>}</div>}

                {this.props.type === EditableInputTypes.YEARS && <div className="editable-input-small">{this.props.editable ? this.props.children : <div className="float-right"> {this.props.readonlyLabel} years </div>}</div>}

                {this.props.type === EditableInputTypes.BOOLEAN && (
                    <div className="editable-input-small">
                        <div> {this.props.editable ? this.props.children : this.getBooleanIcon()} </div>
                    </div>
                )}

                {this.props.type === EditableInputTypes.LONG_TEXT && <div className="editable-input-large">{this.props.editable ? this.props.children : this.props.readonlyLabel}</div>}

                {this.props.type === EditableInputTypes.STANDARD && (
                    <div className="editable-input-small">
                        { this.props.editable ? this.props.children : <div style={{ 'textAlign' : this.props.textAlign ? this.props.textAlign : 'right' }}> {this.props.readonlyLabel} </div> }
                    </div>  
                )}
            </Fragment>
        );
    }
}
