import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import { Route, Redirect } from "react-router-dom";
import { CalculationIndicator } from "../shared/calculationIndicator/CalculationIndicator";
import { getIsCalculationPending } from "../state/actions/GeneralActions";


/**
 * This is a wrapper for the PrivateRoute component for contract routes.
 * It adds a calculation indicator to the route and passes the the calculation status to the route.
 * The status is used to disable parts of the UI when a calculation is in progress.
 */

class PrivateContractsRoute extends React.Component {

    getRouteParams(){
        const pathParts = this.props.location.pathname.split("/");
        const contractId = pathParts[2];
        const scenarioId = pathParts[3];

        return {
            contractId,
            scenarioId
        }
    }

    initPolling = () => {
        return setInterval(()=>{
            if(!document.hidden && this.props.isCalculationPending) {
                this.props.getIsCalculationPending(this.getRouteParams().contractId, this.getRouteParams().scenarioId, (success)=>{
                    if(success) clearInterval(this.pollCalculation)
                })
            } else {
                if(document.hidden){
                    console.log('%c🙈 Window is hidden, not polling for calculation status', "color: #000088;", "");
                }
            }
        }, 5000)
    }

    componentDidMount() {
        this.props.getIsCalculationPending(this.getRouteParams().contractId, this.getRouteParams().scenarioId);
        this.pollCalculation = this.initPolling();
    }
    
    componentDidUpdate() {
        this.props.getIsCalculationPending(this.getRouteParams().contractId, this.getRouteParams().scenarioId);
        this.pollCalculation = this.initPolling();
    }

    componentWillUnmount() {
        clearInterval(this.pollCalculation);
    }

    render() {
        if (!this.props.loggedIn) {
            return <Redirect to={{ pathname: "/login", state: { from: this.props.location } }} />;
        }

        const Component = this.props.component;

        return(
            <Fragment>
                <Route loggedIn={this.props.loggedIn} exact={this.props.exact} path={this.props.path} render={(props) => (<Component {...props} isCalculationPending={this.props.isCalculationPending} />) }   />
                <CalculationIndicator isPending={this.props.isCalculationPending}/>
            </Fragment>
        )
    }
}


PrivateContractsRoute.propTypes = {
    loggedIn: PropTypes.bool,
    location: PropTypes.object,
};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { isCalculationPending } = state.generalReducer;
    return { isCalculationPending };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getIsCalculationPending,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateContractsRoute));


// EXPORT COMPONENT
export { hoc as PrivateContractsRoute };