// IMPORT PACKAGE REFERENCES
import React from "react";
import FeatherIcon from "feather-icons-react";
import { Tooltip } from "reactstrap";

// COMPONENT
export class SimpleCell extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false,
        };
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        });
    }

    render() {
        return (
            <div className="normal-row">
                {this.props.alerts[this.props.label] && (
                    <div className="alert-icon">
                        <span href="#" id="TooltipExample">
                            <FeatherIcon icon="alert-circle" />
                        </span>
                        <Tooltip placement="left" isOpen={this.state.tooltipOpen} target="TooltipExample" toggle={this.toggle}>
                            {Object.keys(this.props.alerts[this.props.label]).map((alertKey) => {
                                let alert = this.props.alerts[this.props.label][alertKey];
                                return <div>{alert.message}</div>;
                            })}
                        </Tooltip>
                    </div>
                )}
                <div className="normal-label">{this.props.label}</div>
            </div>
        );
    }
}
