/*** @Imports ***/

// React Import
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

/*** Component ***/
class MasterDetailRouter extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const query = queryString.parse(this.props.location.search);
        return (
            <Fragment>
                {query.section === undefined ? (
                    <div className="empty-view">
                        <img alt={"empty icon"} className="empty-icon" src={require("../../../images/empty-placeholder.png")} />
                        <h4>No item selected </h4>
                    </div>
                ) : (
                        this.props.sections.map((section, index) => {
                            if (query.section.startsWith(section.sectionIdentifier)) {
                                if (section.hasOwnProperty("page")) {
                                    return <Fragment key={index}>{section.page}</Fragment>;
                                } else {
                                    return <Fragment key={index}>{section.pages[query.sectionType]}</Fragment>;
                                }
                            }
                        })
                    )}
            </Fragment>
        );
    }
}

const hoc = withRouter(MasterDetailRouter);

/*** Export ***/
export { hoc as MasterDetailRouter };
