import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "moment/locale/en-nz";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Button } from "reactstrap";

class DateRangePicker extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.handleDayChange(this.props.dateValue[0], this.props.expressionIndex, this.props.statementIndex, this.props.componentIndex, this.props.dateIndex, this.props.vfKey);
    }

    render() {
        return (
            <DatePicker
                showYearDropdown
                locale="en-NZ"
                className={"date-input " + (this.props.conditionalInput ? "conditional" : "")}
                selected={this.props.dateValue[this.props.dateIndex]}
                selectsStart
                startDate={this.props.dateValue[0]}
                endDate={this.props.dateValue[1]}
                onChange={(date) => this.props.handleDayChange(date, this.props.expressionIndex, this.props.statementIndex, this.props.componentIndex, this.props.dateIndex, this.props.vfKey)}
            >
                {(this.props.contractStart || this.props.contractEnd) && (
                    <div>
                        <Button
                            onClick={() => {
                                this.props.handleDayChange(moment(this.props.contract.details.general.contractTerm[this.props.dateIndex], "DD/MM/YYYY"), this.props.expressionIndex, this.props.statementIndex, this.props.componentIndex, this.props.dateIndex, this.props.vfKey);
                            }}
                            style={{ width: "100%" }}
                        >
                            {this.props.buttonLabel}
                        </Button>
                    </div>
                )}
            </DatePicker>
        );
    }
}

DateRangePicker.propTypes = {
    contract: PropTypes.object.isRequired,
};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { contract } = state.contractReducer;
    return { contract };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(DateRangePicker));

// EXPORT COMPONENT

export { hoc as DateRangePicker };
