// IMPORT PACKAGE REFERENCES
import React from "react";

// UI
import { IndicatorRow } from "./IndicatorRow";

// COMPONENT
export class IndicatorCell extends React.Component {
    render() {
        let sublabel = this.props.data[this.props.identifiers.label];

        if (sublabel) {
            if (sublabel.length > 40) {
                sublabel = sublabel.substring(0, 40) + "...";
            }
        }

        return (
            <div className="normal-row">
                <div className="normal-label"> {this.props.label} </div>
                <div className="normal-label"> {sublabel} </div>
                {this.props.data[this.props.identifiers.subtitle] !== undefined && <div className="normal-subtitle"> {this.props.data[this.props.identifiers.subtitle]} </div>}
                <IndicatorRow cellSubType={this.props.cellSubType} data={this.props.data} />
            </div>
        );
    }
}
