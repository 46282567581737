// IMPORT PACKAGE REFERENCES
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";
import meta from "../../config/meta.json";
import MyAccount from "./MyAccount.js";

import { WorkingIndicator } from "../containers/loading/WorkingIndicator";
import { getUser } from "../state/actions/UserActions";
import { SiteSearch } from "./SiteSearch.js";
import { ENVIRONMENT } from "../../config/config.js";

// COMPONENT
class NavigationBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showingPopover: false,
            salesOffice: "Auckland",
            showingAccount: false,
            user: props.user,
        };

        this.escFunction = this.escFunction.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.setState({ showingPopover: false });
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ showingPopover: false });
        }
    }

    componentDidUpdate() {
        if (JSON.stringify(this.props.user) !== JSON.stringify(this.state.user)) {
            this.setState({ user: this.props.user });
        }
    }

    render() {
        let username = "User";
        let salesOffice = "Auckland";
        let role = "user";

        if (this.state.user) {
            if (this.state.user.name) {
                username = this.state.user.name;
            }

            role = this.state.user.role;
        }

        return (
            <div ref={this.setWrapperRef} className="navigation-bar">
                <div className="navigation-bar-pattern-container"></div>
                <div className="logo-sidebar">
                    <FeatherIcon
                        onClick={() => {
                            this.props.toggleSidebar();
                        }}
                        className="sidebar-hamburger"
                        size="30"
                        icon="menu"
                    />
                    <div style={{ display: "flex" }}>
                        <NavLink to="/?section=my-contracts">
                            <img alt={"logo"} className="logo" src={require("../../images/logo-white.png")} />
                        </NavLink>
                        <div className="environment-flag">{ENVIRONMENT}</div>
                        {this.props.working && <WorkingIndicator />}
                    </div>
                </div>
                <SiteSearch />
                <div>
                    <div
                        onClick={() => {
                            this.setState({ showingPopover: !this.state.showingPopover });
                        }}
                        className="profile-container"
                    >
                        <div className="profile-username">
                            {username} <FeatherIcon icon="chevron-down" />
                        </div>
                    </div>
                    <div className={"sidebar-popover" + (this.state.showingPopover ? "" : " hidden")}>
                        {/* <div className="sidebar-popover-button" onClick={() => { this.setState({ showingAccount: true, showingPopover: false })}}>
                            My Account 
                        </div> */}
                        <div className="sidebar-popover-button" onClick={this.props.logout}>
                            Logout
                        </div>
                        <div className={"version"}>
                            v{meta.versionNumber} - Build {meta.buildNumber}
                        </div>
                    </div>
                </div>
                <MyAccount
                    handleClose={() => {
                        this.setState({ showingAccount: false });
                    }}
                    showing={this.state.showingAccount}
                />
            </div>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { working } = state.generalReducer;
    const { user } = state.userReducer;
    return { working, user };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getUser,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationBar));

// EXPORT COMPONENT

export { hoc as NavigationBar };
