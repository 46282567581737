// IMPORT PACKAGE REFERENCES
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ContextPage } from "../../containers/layout/ContextPage";
import { getEquipments } from "../../state/actions/EquipmentActions";
import { Card } from "../../containers/card/Card";
import { CardRow } from "../../containers/card/CardRow";
import { Row, Col, FormGroup, Label, Input, FormText, Container } from "reactstrap";
import { API_ROOT } from "../../../config/config";
import moment from "moment";

import { uploadEquipment } from "../../state/actions/ConfigurationActions";

// COMPONENT
class EquipmentPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            equipments: {},
            fileToUpload: undefined,
            equipmentLastUpdated: undefined,
            uploading: false,
            progress: 0,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
    }

    componentDidMount() {
        this.props.getEquipments(this.props.match.params.contractId, this.props.match.params.scenarioId, () => { });
    }

    componentDidUpdate() {
        if (JSON.stringify(this.props.equipments["ccaEquipment"]) !== JSON.stringify(this.state.equipments)) {
            this.setState({ equipments: this.props.equipments["ccaEquipment"] });
            this.setState({ equipmentLastUpdated: this.props.equipments["lastUpdated"] });
        }
    }

    handleChange(event) {
        let files = event.target.files;

        if (files.length >= 1) {
            let file = files[0];

            this.setState({
                fileToUpload: file,
            });
        }
    }

    handleUpload() {
        this.setState({ uploading: true });
        this.props.uploadEquipment(
            this.state.fileToUpload,
            (progress) => {
                this.setState({ progress: progress.loaded });
            },
            (success) => {
                if (success) {
                    this.setState({ fileToUpload: undefined });
                    this.props.getEquipments(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
                }else{
                    alert("Upload failed, please check that your data is correct");
                }

                this.setState({ uploading: false });
            }
        );
    }

    round2dp(num) {
        return Math.round(num * 100) / 100;
    }

    render() {
        return (
            <ContextPage fetching={this.props.fetching} fetched={this.props.fetched} title={"Equipment"}>
                <Card
                    title="Import Equipment Data"
                    action={
                        <div onClick={(e) => this.handleUpload(e)} className={"text-link" + (this.state.fileToUpload === undefined ? " disabled" : "")}>
                            Upload
                        </div>
                    }
                >
                    <FormGroup>
                        <Label for="fileUpload">File</Label>
                        <Input accept=".csv" onChange={this.handleChange} type="file" name="file" id="fileUpload" />
                        <FormText color="muted">{"Please ensure that the file is formatted correctly (accepts .csv)."}</FormText>
                        <FormText color="muted">{"Column format (spelling must match): name, type, value"}</FormText>
                    </FormGroup>
                </Card>

                <Card title="Equipment Data">
                    <CardRow label={"Last Updated"} top>
                        {this.state.equipmentLastUpdated === undefined ? "No equipment data uploaded." : moment(this.state.equipmentLastUpdated).format("DD/MM/YYYY")}
                    </CardRow>

                    <CardRow label={"Download Current Equipment Data"} bottom>
                        {this.state.equipmentLastUpdated === undefined ? "No equipment data to download." : <a href={API_ROOT + "/equipment/Download"}>Download</a>}
                    </CardRow>
                </Card>
                <Card noTitle>
                    {this.props.fetching || this.state.equipments == null ? <h1></h1> : Object.keys(this.state.equipments).map((equipmentKey) => {
                        let equipment = this.state.equipments[equipmentKey];
                        return (
                            <CardRow label={equipment.name} minWidth={"70%"}>
                                <Row>
                                    <Col xs="6">{equipment.type === undefined ? "-" : `${equipment.type}`}</Col>
                                    <Col xs="6">{equipment.value === undefined ? "-" : `${equipment.value}`}</Col>
                                </Row>
                            </CardRow>
                        );
                    })}
                </Card>
            </ContextPage>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { fetching, fetched, failed, equipments, equipmentLastUpdated } = state.equipmentReducer;
    return { fetching, fetched, failed, equipments, equipmentLastUpdated };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getEquipments, uploadEquipment }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(EquipmentPage));

// EXPORT COMPONENT

export { hoc as EquipmentPage };
