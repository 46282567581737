import axios from "axios";
import { API_ROOT, APPROVALS_ROOT } from "../../../config/config";

// Import Base URL

// GET CONTRACTS
export const GET_CONTRACTS_PENDING = "GET_CONTRACTS_PENDING";
export const GET_CONTRACTS_FULFILLED = "GET_CONTRACTS_FULFILLED";
export const GET_CONTRACTS_REJECTED = "GET_CONTRACTS_REJECTED";

// GET CONTRACT
export const GET_CONTRACT_PENDING = "GET_CONTRACT_PENDING";
export const GET_CONTRACT_FULFILLED = "GET_CONTRACT_FULFILLED";
export const GET_CONTRACT_REJECTED = "GET_CONTRACT_REJECTED";

// POST CONTRACT
export const POST_CONTRACT_PENDING = "POST_CONTRACT_PENDING";
export const POST_CONTRACT_FULFILLED = "POST_CONTRACT_FULFILLED";
export const POST_CONTRACT_REJECTED = "POST_CONTRACT_REJECTED";

// POST SCENARIO
export const POST_SCENARIO_PENDING = "POST_SCENARIO_PENDING";
export const POST_SCENARIO_FULFILLED = "POST_SCENARIO_FULFILLED";
export const POST_SCENARIO_REJECTED = "POST_SCENARIO_REJECTED";

// DELETE SCENARIO
export const DELETE_SCENARIO_PENDING = "DELETE_SCENARIO_PENDING";
export const DELETE_SCENARIO_FULFILLED = "DELETE_SCENARIO_FULFILLED";
export const DELETE_SCENARIO_REJECTED = "DELETE_SCENARIO_REJECTED";

// PUT CONTRACT
export const PUT_CONTRACT_PENDING = "PUT_CONTRACT_PENDING";
export const PUT_CONTRACT_FULFILLED = "PUT_CONTRACT_FULFILLED";
export const PUT_CONTRACT_REJECTED = "PUT_CONTRACT_REJECTED";
export const PUT_CONTRACT_UPDATING = "PUT_CONTRACT_UPDATING";
export const PUT_CONTRACT_UPDATED = "PUT_CONTRACT_UPDATED";

// GET CONTRACT PROFIT SUMMARY
export const GET_CONTRACT_PROFIT_SUMMARY_PENDING = "GET_CONTRACT_PROFIT_SUMMARY_PENDING";
export const GET_CONTRACT_PROFIT_SUMMARY_FULFILLED = "GET_CONTRACT_PROFIT_SUMMARY_FULFILLED";
export const GET_CONTRACT_PROFIT_SUMMARY_REJECTED = "GET_CONTRACT_PROFIT_SUMMARY_REJECTED";

// GET CONTRACT SUMMARY
export const GET_CONTRACT_SUMMARY_FULFILLED = "GET_CONTRACT_SUMMARY_FULFILLED";
export const GET_CONTRACT_SUMMARY_PENDING = "GET_CONTRACT_SUMMARY_PENDING";
export const GET_CONTRACT_SUMMARY_REJECTED = "GET_CONTRACT_SUMMARY_REJECTED";

// UPDATE CONTRACT SUMMARY
export const UPDATE_CONTRACT_SUMMARY_FULFILLED = "UPDATE_CONTRACT_SUMMARY_FULFILLED";
export const UPDATE_CONTRACT_SUMMARY_PENDING = "UPDATE_CONTRACT_SUMMARY_PENDING";
export const UPDATE_CONTRACT_SUMMARY_REJECTED = "UPDATE_CONTRACT_SUMMARY_REJECTED";

// GET CONTRACT DISCOUNT VOLUME SPREAD
export const GET_CONTRACT_DISCOUNT_VOLUME_SPREAD_PENDING = "GET_CONTRACT_DISCOUNT_VOLUME_SPREAD_PENDING";
export const GET_CONTRACT_DISCOUNT_VOLUME_SPREAD_FULFILLED = "GET_CONTRACT_DISCOUNT_VOLUME_SPREAD_FULFILLED";
export const GET_CONTRACT_DISCOUNT_VOLUME_SPREAD_REJECTED = "GET_CONTRACT_DISCOUNT_VOLUME_SPREAD_REJECTED";

// DELETE CONTRACTS PENDING
export const DELETE_CONTRACTS_PENDING = "DELETE_CONTRACTS_PENDING";
export const DELETE_CONTRACTS_FULFILLED = "DELETE_CONTRACTS_FULFILLED";
export const DELETE_CONTRACTS_REJECTED = "DELETE_CONTRACTS_REJECTED";

// SAVE CONTRACTS CUSTOM CONDITIONS PENDING
export const SAVE_CONTRACT_CUSTOM_CONDITIONS_PENDING = "SAVE_CONTRACT_CUSTOM_CONDITIONS_PENDING";
export const SAVE_CONTRACT_CUSTOM_CONDITIONS_FULFILLED = "SAVE_CONTRACT_CUSTOM_CONDITIONS_FULFILLED";
export const SAVE_CONTRACT_CUSTOM_CONDITIONS_REJECTED = "SAVE_CONTRACT_CUSTOM_CONDITIONS_REJECTED";

// SAVE CONTRACTS COMPLIANCE
export const SAVE_CONTRACT_COMPLIANCE_PENDING = "SAVE_CONTRACT_COMPLIANCE_PENDING";
export const SAVE_CONTRACT_COMPLIANCE_FULFILLED = "SAVE_CONTRACT_COMPLIANCE_FULFILLED";
export const SAVE_CONTRACT_COMPLIANCE_REJECTED = "SAVE_CONTRACT_COMPLIANCE_REJECTED";

export const GET_CONTRACT_COMPLIANCE_PENDING = "GET_CONTRACT_COMPLIANCE_PENDING";
export const GET_CONTRACT_COMPLIANCE_FULFILLED = "GET_CONTRACT_COMPLIANCE_FULFILLED";
export const GET_CONTRACT_COMPLIANCE_REJECTED = "GET_CONTRACT_COMPLIANCE_REJECTED";

export const GET_CONTRACT_APPROVERS_PENDING = "GET_CONTRACT_APPROVERS_PENDING";
export const GET_CONTRACT_APPROVERS_FULFILLED = "GET_CONTRACT_APPROVERS_FULFILLED";
export const GET_CONTRACT_APPROVERS_REJECTED = "GET_CONTRACT_APPROVERS_REJECTED";
export const SAVE_CONTRACT_APPROVAL_PENDING = "GET_CONTRACT_APPROVAL_PENDING";
export const SAVE_CONTRACT_APPROVAL_FULFILLED = "GET_CONTRACT_APPROVAL_FULFILLED";
export const SAVE_CONTRACT_APPROVAL_REJECTED = "GET_CONTRACT_APPROVAL_REJECTED";

export const GET_WORKFLOW_USERS_PENDING = "GET_WORKFLOW_USERS_PENDING";
export const GET_WORKFLOW_USERS_FULFILLED = "GET_WORKFLOW_USERS_FULFILLED";
export const GET_WORKFLOW_USERS_REJECTED = "GET_WORKFLOW_USERS_REJECTED";

export const UPDATE_WORKFLOW_USERS_PENDING = "UPDATE_WORKFLOW_USERS_PENDING";
export const UPDATE_WORKFLOW_USERS_FULFILLED = "UPDATE_WORKFLOW_USERS_FULFILLED";
export const UPDATE_WORKFLOW_USERS_REJECTED = "UPDATE_WORKFLOW_USERS_REJECTED";
// ACTION GENERATORS

/*
    Retrieves all the contracts.

    @params:
    - contractType: whether the contract is custom or a programme agreement.
*/

const getContracts = (userId, contractType = "custom", personal = false) => (dispatch) => {
    dispatch({
        type: GET_CONTRACTS_PENDING,
    });

    axios
        .get(API_ROOT + `/contracts?type=${contractType}&userId=${userId}&personal=${personal}`)
        .then((res) => {
            const contracts = res.data;

            /* Fulfil the request for contracts, since the API succceded, pass the contracts data to the reducer. */

            dispatch({
                type: GET_CONTRACTS_FULFILLED,
                payload: contracts,
            });
        })
        .catch((error) => {
            console.log("An error occurred requesting contracts.", error);

            /* Reject the request for contracts, since the API failed. */

            dispatch({
                type: GET_CONTRACTS_REJECTED,
            });
        });
};

/*
    Retrieve the individual contract.
*/
const getContract = (contractId, callback) => (dispatch) => {
    dispatch({
        type: GET_CONTRACT_PENDING,
    });

    axios
        .get(API_ROOT + "/contracts/" + contractId)
        .then((res) => {
            const contract = res.data;

            /* Fulfil the request for contract, since the API succceded, pass the contract data to the reducer. */

            dispatch({
                type: GET_CONTRACT_FULFILLED,
                payload: contract,
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting contract.", error);

            /* Reject the request for contract, since the API failed. */

            dispatch({
                type: GET_CONTRACT_REJECTED,
            });

            callback(false);
        });
};

/*
    Retrieve the contract profit summary.
*/
const getContractProfitSummary = (contractId) => (dispatch) => {
    dispatch({
        type: GET_CONTRACT_PROFIT_SUMMARY_PENDING,
    });

    axios
        .get(API_ROOT + "/contracts/" + contractId + "/profitSummary")
        .then((res) => {
            const profitSummary = res.data;

            /* Fulfil the request for contract, since the API succceded, pass the contract data to the reducer. */

            dispatch({
                type: GET_CONTRACT_PROFIT_SUMMARY_FULFILLED,
                payload: profitSummary,
            });
        })
        .catch((error) => {
            console.log("An error occurred requesting contract.", error);

            /* Reject the request for contract, since the API failed. */

            dispatch({
                type: GET_CONTRACT_PROFIT_SUMMARY_REJECTED,
            });
        });
};

/*
    Retrieve the contract discount volume spread.
*/
const getContractDiscountVolumeSpread = (contractId, year) => (dispatch) => {
    dispatch({
        type: GET_CONTRACT_DISCOUNT_VOLUME_SPREAD_PENDING,
    });

    let url = API_ROOT + "/contracts/" + contractId + "/profitSummary/discounts";

    if (year !== undefined && year !== null && year !== -1) {
        url += "?year=" + year;
    }

    axios
        .get(url)
        .then((res) => {
            const discountVolumeSpread = res.data;

            /* Fulfil the request for contract, since the API succceded, pass the contract data to the reducer. */

            dispatch({
                type: GET_CONTRACT_DISCOUNT_VOLUME_SPREAD_FULFILLED,
                payload: discountVolumeSpread,
            });
        })
        .catch((error) => {
            console.log("An error occurred requesting contract.", error);

            /* Reject the request for contract, since the API failed. */

            dispatch({
                type: GET_CONTRACT_DISCOUNT_VOLUME_SPREAD_REJECTED,
            });
        });
};

/*
    Retrieve the contract summary.
*/
const getContractSummary = (contractId) => (dispatch) => {
    dispatch({
        type: GET_CONTRACT_SUMMARY_PENDING,
    });

    axios
        .get(API_ROOT + `/contracts/${contractId}/summary`)
        .then((res) => {
            const summary = res.data;

            /* Fulfil the request for contract, since the API succceded, pass the contract data to the reducer. */

            dispatch({
                type: GET_CONTRACT_SUMMARY_FULFILLED,
                payload: summary,
            });
        })
        .catch((error) => {
            console.log("An error occurred requesting contract.", error);

            /* Reject the request for contract, since the API failed. */

            dispatch({
                type: GET_CONTRACT_SUMMARY_REJECTED,
            });
        });
};

const getCustomCompliance = (contractId) => (dispatch) => {
    dispatch({
        type: GET_CONTRACT_COMPLIANCE_PENDING,
    });

    axios
        .get(API_ROOT + "/contracts/" + contractId + "/customConditions")
        .then((res) => {
            const contract = res.data;

            dispatch({
                type: GET_CONTRACT_COMPLIANCE_FULFILLED,
                payload: contract,
            });
        })
        .catch((error) => {
            console.log("An error occurred requesting custom conditions", error);
            dispatch({
                type: GET_CONTRACT_COMPLIANCE_REJECTED,
            });
        });
};

/*
    Retrieve the contract summary.
*/
const updateContractSummary = (contractId) => (dispatch) => {
    dispatch({
        type: UPDATE_CONTRACT_SUMMARY_PENDING,
    });

    axios
        .get(API_ROOT + "/contracts/" + contractId + "/summary")
        .then((res) => {
            const summary = res.data;

            /* Fulfil the request for contract, since the API succceded, pass the contract data to the reducer. */

            dispatch({
                type: UPDATE_CONTRACT_SUMMARY_FULFILLED,
                payload: summary,
            });
        })
        .catch((error) => {
            console.log("An error occurred requesting contract.", error);

            /* Reject the request for contract, since the API failed. */

            dispatch({
                type: UPDATE_CONTRACT_SUMMARY_REJECTED,
            });
        });
};

/*
    Generates a request to create a new contract.

    If a selectedId and type are provided, then the new contract created from either a template or an existing contract,
    depending on the type.
*/
const createContract = (values, user, type, selectedId, callback) => (dispatch) => {
    dispatch({
        type: POST_CONTRACT_PENDING,
    });

    axios
        .post(API_ROOT + "/contracts", { selectedId: selectedId, type, userId: user.uid, ...values })
        .then((res) => {
            /* Fulfil the request for contracts, since the API succceded, pass the contracts data to the reducer. */
            dispatch({
                type: POST_CONTRACT_FULFILLED,
                payload: res.data,
            });

            callback(true, res);
        })
        .catch((error) => {
            console.log("An error occurred requesting contracts.", error);

            /* Reject the request for contracts, since the API failed. */

            dispatch({
                type: POST_CONTRACT_REJECTED,
            });

            callback(false, null);
        });
};

/*
    Generates a request to create a new scenario for a contract.
*/
const createScenario = (values, contractId, scenarioId, callback) => (dispatch) => {
    dispatch({
        type: POST_SCENARIO_PENDING,
    });

    let postBody = { ...values, scenarioId };

    axios
        .post(API_ROOT + `/contracts/${contractId}/scenarios`, postBody)
        .then((res) => {
            /* Fulfil the request for contracts, since the API succceded, pass the contracts data to the reducer. */
            dispatch({
                type: POST_SCENARIO_FULFILLED,
                payload: res.data,
            });

            callback(true, res.data.scenarioId);
        })
        .catch((error) => {
            console.log("An error occurred creating scenarios.", error);

            /* Reject the request for contracts, since the API failed. */

            dispatch({
                type: POST_SCENARIO_REJECTED,
            });

            callback(false, null);
        });
};

/*
    Delete scenario from contract
*/
const deleteScenario = (scenarioId, contractId) => (dispatch) => {
    dispatch({
        type: DELETE_SCENARIO_PENDING,
    });

    axios
        .delete(API_ROOT + `/contracts/${contractId}/scenarios/${scenarioId}`)
        .then((res) => {
            /* Fulfil the request for contracts, since the API succceded, pass the contracts data to the reducer. */
            dispatch({
                type: DELETE_SCENARIO_FULFILLED,
                payload: scenarioId,
            });
        })
        .catch((error) => {
            console.log("An error occurred creating scenarios.", error);

            /* Reject the request for contracts, since the API failed. */

            dispatch({
                type: DELETE_SCENARIO_REJECTED,
            });
        });
};

/*
    Updates the current contract information
*/
const updateContract = (contractId, values, callback) => (dispatch) => {
    dispatch({
        type: PUT_CONTRACT_UPDATING,
    });

    axios
        .put(API_ROOT + "/contracts/" + contractId, values)
        .then((res) => {
            dispatch({
                type: PUT_CONTRACT_UPDATED,
                payload: values,
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred updating the contract", error);
            dispatch({
                type: PUT_CONTRACT_REJECTED,
            });

            callback(false);
        });
};

/*
    Delete contracts
*/
const deleteContracts = (contractsToDelete, callback) => (dispatch) => {
    dispatch({
        type: DELETE_CONTRACTS_PENDING,
    });

    axios
        .delete(API_ROOT + "/contracts/", { data: contractsToDelete })
        .then((res) => {
            dispatch({
                type: DELETE_CONTRACTS_FULFILLED,
                payload: contractsToDelete,
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred updating the contract", error);
            dispatch({
                type: DELETE_CONTRACTS_REJECTED,
            });

            callback(false);
        });
};

/*
    Saves custom conditions attached to a contract
*/
const saveContractCustomConditions = (contractId, conditions, callback) => (dispatch) => {
    dispatch({
        type: SAVE_CONTRACT_CUSTOM_CONDITIONS_PENDING,
    });

    axios
        .post(API_ROOT + "/contracts/" + contractId + "/customConditions", { data: conditions })
        .then((res) => {
            dispatch({
                type: SAVE_CONTRACT_CUSTOM_CONDITIONS_FULFILLED,
                payload: { contractId, conditions },
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred saving custom conditions.", error);
            dispatch({
                type: SAVE_CONTRACT_CUSTOM_CONDITIONS_REJECTED,
            });

            callback(false);
        });
};

/*
    Save contract compliance information
*/
const saveContractCompliance = (contractId, conditions, callback) => (dispatch) => {
    dispatch({
        type: SAVE_CONTRACT_COMPLIANCE_PENDING,
    });

    axios
        .post(API_ROOT + "/contracts/" + contractId + "/compliance", { data: conditions })
        .then((res) => {
            let data = res.data;
            dispatch({
                type: SAVE_CONTRACT_COMPLIANCE_FULFILLED,
                payload: data,
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred saving contract compliance.", error);
            dispatch({
                type: SAVE_CONTRACT_COMPLIANCE_REJECTED,
            });

            callback(false);
        });
};

const approveContract = (contractId, scenarioId, userEmail, isRejection = false, reason, isAdmin = false, callback) => (dispatch) => {
    var apiUrl = isRejection ? 'RejectScenario' : 'ApproveScenario';
    dispatch({ type: SAVE_CONTRACT_APPROVAL_PENDING })
    axios
        .post(APPROVALS_ROOT + `/${apiUrl}?ContractId=${contractId}&ScenarioId=${scenarioId}&UserEmail=${userEmail}&IsAdmin=${isAdmin}`,
            { Reason: reason }
        )
        .then((res) => {
            let data = res.data;
            dispatch({
                type: SAVE_CONTRACT_APPROVAL_FULFILLED,
                payload: data,
            });

            if(callback) callback(true);
        })
        .catch((error) => {
            console.log("An error occurred submitting the contract approval request", error);
            dispatch({
                type: SAVE_CONTRACT_APPROVAL_REJECTED,
            });

            if(callback) callback(false);
        });
}

const getContractApprovers = (contractId, scenarioId, userEmail, callback) => (dispatch) => {
    dispatch({ type: GET_CONTRACT_APPROVERS_PENDING })
    axios
        .get(APPROVALS_ROOT + `/GetApprovalData?ContractId=${contractId}&ScenarioId=${scenarioId}&UserEmail=${userEmail}/`)
        .then((res) => {
            let data = res.data;
            dispatch({
                type: GET_CONTRACT_APPROVERS_FULFILLED,
                payload: data,
            });
            if(callback) callback(true);
        })
        .catch((error) => {
            console.log("An error occurred getting contract approvers", error);
            dispatch({
                type: GET_CONTRACT_APPROVERS_REJECTED,
            });
            if(callback) callback(false);
        });
}

const getWorkflowOptions = (contractId, scenarioId, callback) => (dispatch) => {
    dispatch({ type: GET_WORKFLOW_USERS_PENDING })
    axios
        .get(APPROVALS_ROOT + `/GetWorkflowTree?ContractId=${contractId}&ScenarioId=${scenarioId}`)
        .then((res) => {
            let data = res.data;
            dispatch({
                type: GET_WORKFLOW_USERS_FULFILLED,
                payload: data,
            });
            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred getting workflow user options", error);
            dispatch({
                type: GET_WORKFLOW_USERS_REJECTED,
            });

            callback(false);
        });
}

const updateWorkflowUser = (email, displayName, role, contractId, scenarioId, editingUser, callback) => (dispatch) => {
    dispatch({ type: UPDATE_WORKFLOW_USERS_PENDING })
    axios
        .post(APPROVALS_ROOT + `/UpdateApprovalTreeUser?ContractId=${contractId}&ScenarioId=${scenarioId}&Role=${role}&DisplayName=${displayName}&Email=${email}&EditingUser=${editingUser}`)
        .then((res) => {
            let data = res.data;
            dispatch({
                type: UPDATE_WORKFLOW_USERS_FULFILLED,
                payload: data,
            });
            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred getting workflow user options", error);
            dispatch({
                type: UPDATE_WORKFLOW_USERS_REJECTED,
            });

            callback(false);
        });
}

// EXPORT ACTIONS
export {
    getContracts, getContract, createContract, updateContract, getContractProfitSummary, getContractSummary,
    createScenario, deleteScenario, getContractDiscountVolumeSpread, deleteContracts, saveContractCustomConditions,
    saveContractCompliance, getCustomCompliance, getContractApprovers, approveContract, getWorkflowOptions, updateWorkflowUser
};
