// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

// UI
import { Row, Col } from "reactstrap";
import { Checkbox } from "../../containers/inputs/Checkbox";

// ACTIONS //
import { getTemplates } from "../../state/actions/TemplateActions";

// COMPONENT
class SimpleContractTemplateList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            checked: undefined,
        };

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.props.getTemplates();
    }

    handleClick(checked, id) {
        if (checked) {
            this.setState({ checked: id }, () => {
                this.props.handleItemClicked(id);
            });
        }

        this.props.slide();
    }

    render() {
        return (
            <Row form>
                <Col md={12}>
                    {this.props.fetchedTemplates &&
                        Object.values(this.props.templates).map((template, templateIndex) => {
                            return (
                                <div key={templateIndex} className="material-list">
                                    <div className="material-list-checkbox">
                                        <Checkbox
                                            editing={true}
                                            checked={this.state.checked === template.contractId}
                                            handleClick={(checked) => {
                                                this.handleClick(checked, template.contractId);
                                            }}
                                        />
                                    </div>

                                    <div className="material-list-details">
                                        <div className="material-list-left"> {template.contractName} </div>
                                    </div>
                                </div>
                            );
                        })}
                </Col>
            </Row>
        );
    }
}

SimpleContractTemplateList.propTypes = {
    templates: PropTypes.object.isRequired,
    fetchingTemplates: PropTypes.bool.isRequired,
    fetchedTemplates: PropTypes.bool.isRequired,
    failedTemplates: PropTypes.bool.isRequired,
};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { fetchingTemplates, fetchedTemplates, failedTemplates, templates } = state.templateReducer;
    return { fetchingTemplates, fetchedTemplates, failedTemplates, templates };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getTemplates,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(SimpleContractTemplateList));

// EXPORT COMPONENT

export { hoc as SimpleContractTemplateList };
