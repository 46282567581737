import { GET_REBATES_PENDING, GET_REBATES_FULFILLED, GET_REBATES_REJECTED, SAVE_REBATES_FULFILLED, SAVE_REBATES_PENDING, SAVE_REBATES_REJECTED } from "../actions/RebateActions";

// INITIALIZE STATE
const initialState = {
    rebates: {},
    fetching: false,
    fetched: false,
    failed: false,
    updating: false,
    updated: false,
    updateFailed: false,
};

import { CLEAR_REDUCERS, PREPARE_FOR_CONTRACT_SWITCH } from "../actions/GeneralActions";

// REDUCER
export const RebateReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_REDUCERS:
            return initialState;
        case PREPARE_FOR_CONTRACT_SWITCH:
            return initialState;
        /* GET EQUIPMENTS */
        case GET_REBATES_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_REBATES_FULFILLED:
            return {
                ...state,
                rebates: action.payload,
                fetching: false,
                fetched: true,
                failed: false,
            };
        case GET_REBATES_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        case SAVE_REBATES_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
                updateFailed: false,
            };
        case SAVE_REBATES_FULFILLED:
            return {
                ...state,
                rebates: action.payload,
                updating: false,
                updated: false,
                updateFailed: true,
            };
        case SAVE_REBATES_REJECTED:
            return {
                ...state,
                updating: false,
                updated: false,
                updateFailed: true,
            };
        default:
            return state;
    }
};
