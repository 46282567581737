// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { PageTitle } from "../../../containers/layout/PageTitle";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

// UI
import Modalised from "../../../containers/layout/Modalised";
import { ResponsiveList } from "../lists/ResponsiveList";
import { NewEquipmentPage } from "./NewEquipmentPage";
import { ContextualSidebar } from "../../../containers/layout/ContextualSidebar";
import { Message } from "../../../containers/layout/Message";
import { Button } from "reactstrap";

/*** Actions ***/
import { getEquipments, deleteEquipments } from "../../../state/actions/EquipmentActions";
import { deleteNonFinancials } from "../../../state/actions/NonFinancialActions";
import { getNonFinancials } from "../../../state/actions/NonFinancialActions";
import { NewNonFinancialPage } from "./NewNonFinancialPage";
import { EquipmentDetailsPage } from "./EquipmentDetailsPage";
import { NonFinancialDetailsPage } from "./NonFinancialDetailsPage";
import { CELL_TYPES } from "../CellTypes";
import { CELL_SUB_TYPES } from "../CellSubTypes";
import { MasterDetailRouter } from "../../../containers/layout/MasterDetailRouter";

// COMPONENT
class EquipmentsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalType: "",
            modalShowing: false,
            id: undefined,
            messageActive: false,
            message: "",
            editingShowing: false,
        };

        this.sections = [];
        this.equipmentData = [];
        this.nonFinancialsData = [];

        /** Bindings **/
        this.handleNewNonFinancialModalClose = this.handleNewNonFinancialModalClose.bind(this);
        this.handleNewEquipmentModalClose = this.handleNewEquipmentModalClose.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.toggleNewIncentiveModal = this.toggleNewIncentiveModal.bind(this);
        this.toggleNewEquipmentModal = this.toggleNewEquipmentModal.bind(this);
        this.reFetchData = this.reFetchData.bind(this);
        this.generateSections = this.generateSections.bind(this);
    }

    componentDidMount() {
        this.reFetchData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.scenarioId !== this.props.match.params.scenarioId) {
            this.reFetchData(true);
        }
    }

    /**
     * Fetches the data needed to render the sections on the page (Equipment, Other Non Financials)
     */
    reFetchData(scenarioChanged = false) {
        let equipmentPromise = new Promise((resolve, reject) => {
            this.props.getEquipments(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => {
                success ? resolve() : reject();
            });
        });

        let nonFinancialsPromise = new Promise((resolve, reject) => {
            this.props.getNonFinancials(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => {
                success ? resolve() : reject();
            });
        });

        Promise.all([equipmentPromise, nonFinancialsPromise]).then(() => {
            this.generateSections();

            if (scenarioChanged) {
                // Need to select the first item if there is one exists in any of the sections
                let found = false;

                if (this.sections.length > 0) {
                    this.sections.forEach((section) => {
                        if (section.items.length > 0) {
                            // Found a section with at least one item in it. Select it.
                            let item = section.items[0];

                            this.handleClick(item[section.identifier], section.sectionIdentifier);
                            found = true;
                        }
                    });
                }

                if (!found) {
                    this.handleClick(undefined, undefined);
                }
            }
        });
    }

    handleClick(id, sectionIdentifier) {
        this.props.history.push(id + `?section=${sectionIdentifier}`);
    }

    toggleNewEquipmentModal() {
        this.setState({ modalShowing: !this.state.modalShowing, modalType: "equipment" });
    }

    toggleNewIncentiveModal() {
        this.setState({ modalShowing: !this.state.modalShowing, modalType: "non-financial" });
    }

    hideModal() {
        this.setState({ modalShowing: false });
    }

    handleNewEquipmentModalClose() {
        this.toggleNewEquipmentModal();

        // Refetch the new equipment.
        this.props.getEquipments(this.props.match.params.contractId, this.props.match.params.scenarioId, () => { });
    }

    handleNewNonFinancialModalClose() {
        this.toggleNewIncentiveModal();

        // Refetch the new equipment.
        this.props.getNonFinancials(this.props.match.params.contractId, this.props.match.params.scenarioId, () => { });
    }

    toggleEditing() {
        this.setState({ editingShowing: !this.state.editingShowing });
    }

    handleDelete(selectedItems) {
        if (confirm("Are you sure you want to delete this equipment?")) {
            let equipmentsToDelete = [];
            let nonFinancialsToDelete = [];

            selectedItems.forEach((item) => {
                if (item.section === 0) {
                    equipmentsToDelete.push(item.id);
                } else {
                    nonFinancialsToDelete.push(item.id);
                }
            });

            // Delete the equipments...
            this.props.deleteEquipments(this.props.match.params.contractId, this.props.match.params.scenarioId, equipmentsToDelete, (success) => {
                if (!success) {
                    this.setState({ messageActive: true, message: "Error deleting non-financial incentives" });
                    setTimeout(() => {
                        this.setState({ messageActive: false });
                    }, 2000);
                } else {
                    // No error deleting equipment, delete the other non-financials
                    this.props.deleteNonFinancials(this.props.match.params.contractId, this.props.match.params.scenarioId, nonFinancialsToDelete, (success) => {
                        if (!success) {
                            this.setState({ messageActive: true, message: "Error deleting non-financial incentives" });
                            setTimeout(() => {
                                this.setState({ messageActive: false });
                            }, 2000);
                        } else {
                            this.setState({ editingShowing: false });
                        }
                    });
                }
            });
        }
    }

    generateSections() {
        this.equipmentData = Object.keys(this.props.equipments).map((key) => {
            return { equipmentId: key, ...this.props.equipments[key] };
        });

        this.nonFinancialsData = Object.keys(this.props.nonFinancials).map((key) => {
            return { nonFinancialId: key, ...this.props.nonFinancials[key] };
        });

        this.sections = [
            {
                items: this.equipmentData,
                addLabel: "Add Equipment",
                addAction: this.toggleNewEquipmentModal,
                emptyLabel: "No equipment",
                sectionLabel: "Equipment",
                sectionIdentifier: "equipment",
                identifier: "equipmentId",
                page: <EquipmentDetailsPage />,
                cell: {
                    type: CELL_TYPES.INDICATOR,
                    subType: CELL_SUB_TYPES.EQUIPMENT,
                    dataIdentifiers: { label: "equipmentSelection", subtitle: "description" },
                },
            },
            {
                items: this.nonFinancialsData,
                addLabel: "Add Other Incentive",
                addAction: this.toggleNewIncentiveModal,
                emptyLabel: "No other non-financial incentives",
                sectionLabel: "Other",
                sectionIdentifier: "other",
                identifier: "nonFinancialId",
                page: <NonFinancialDetailsPage />,
                cell: {
                    type: CELL_TYPES.INDICATOR,
                    subType: CELL_SUB_TYPES.NON_FINANCIAL,
                    dataIdentifiers: { label: "type", subtitle: "description" },
                },
            },
        ];
    }

    render() {
        this.generateSections();

        return (
            <Fragment>
                <Modalised
                    title={this.state.modalType === "equipment" ? "Add Equipment" : "Add Other Incentive"}
                    action={
                        <div
                            onClick={() => {
                                this.hideModal();
                            }}
                            className="text-link"
                        >
                            <FeatherIcon icon="x" />
                        </div>
                    }
                    handleClose={() => {
                        this.hideModal();
                    }}
                    showing={this.state.modalShowing}
                >
                    {this.state.modalType === "equipment" ? <NewEquipmentPage onClose={this.handleNewEquipmentModalClose} contractId={this.props.match.params.contractId} scenarioId={this.props.match.params.scenarioId} /> : <NewNonFinancialPage onClose={this.handleNewNonFinancialModalClose} contractId={this.props.match.params.contractId} scenarioId={this.props.match.params.scenarioId} />}
                </Modalised>

                <ContextualSidebar
                    pageTitle={
                        <PageTitle
                            firstElement={
                                this.equipmentData.length > 0 && (
                                    <Button color="link" onClick={() => this.toggleEditing()}>
                                        {this.state.editingShowing ? "Done" : "Edit"}
                                    </Button>
                                )
                            }
                            title={"Additional Incentives"}
                        />
                    }
                    left={<ResponsiveList editingShowing={this.state.editingShowing} handleDelete={this.handleDelete} handleClick={this.handleClick} buttonText="Add Equipment" placeholderImage={require("../../../../images/refrigerator.png")} actions={["Delete"]} type={"item"} sections={this.sections} />}
                    right={<MasterDetailRouter sections={this.sections} />}
                />
            </Fragment>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { equipments } = state.equipmentReducer;
    const { nonFinancials } = state.nonFinancialReducer;
    return { equipments, nonFinancials };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getEquipments, deleteEquipments, getNonFinancials, deleteNonFinancials }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(EquipmentsPage));

// EXPORT COMPONENT

export { hoc as EquipmentsPage };
