// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";

// COMPONENT
export class GoalsAndNeedsPin extends React.Component {
    render() {
        let goalsAndNeeds = this.props.goalsAndNeeds !== undefined ? this.props.goalsAndNeeds : {};

        return (
            <Fragment>
                <div className="menu-subtitle">Goals and Needs</div>
                <div className="sidebar-summary-section padded">
                    <div className="sidebar-summary-section-title">Goals and Needs</div>
                    <div>{goalsAndNeeds.goalsAndNeeds}</div>
                    <br />
                    <div className="sidebar-summary-section-title">How CCA can help</div>
                    <div>{goalsAndNeeds.howCCAHelp}</div>
                </div>
            </Fragment>
        );
    }
}
