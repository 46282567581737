// IMPORT PACKAGE REFERENCES
import React, { Fragment, Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import Select from 'react-select'
// UI
import { Col, Row, Button, FormGroup, Label, Input } from "reactstrap";
import { ValidatedInput } from "../../../containers/validation/ValidatedInput";

// Actions
import { addEquipment } from "../../../state/actions/EquipmentActions";
import { getGeneralDetails } from "../../../state/actions/GeneralDetailsActions";
import { Loading } from "../../../containers/loading/Loading";
import { Message } from "../../../containers/layout/Message";
import { Note } from "../../../Note";
import { Checkbox } from "../../../containers/inputs/Checkbox";

const validationSchema = Yup.object().shape({
    equipmentSelection: Yup.string().required("Equipment type required"),
    equipmentType: Yup.string().required("Equipment name required"),
    capitalValue: Yup.number().moreThan(0, "Value must be greater than 0").required("Value required"),
    quantity: Yup.number().moreThan(0, "Quantity must be greater than 0").integer("Quantity must be a whole number").required("Quantity required"),
});

// COMPONENT
class NewEquipmentPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalShowing: false,
            saving: false,
            messageActive: false,
            messageError: false,
            equipment: [],
            equipmentCategories: [],
        };

        this.updateEquipment = this.updateEquipment.bind(this);
        this.generateEquipmentCategories = this.generateEquipmentCategories.bind(this);
    }

    componentDidMount() {
        this.props.getGeneralDetails(() => { });
        this.generateEquipmentCategories();
    }

    generateEquipmentCategories() {
        let niceListObjects = Object.keys(this.props.generalDetails.equipment).map((equipmentKey, index) => {
            let equipment = this.props.generalDetails.equipment[equipmentKey];
            return { value: equipment.name, label: equipment.name, type: equipment.type }
        });
        let equipmentCategories = [...new Set(niceListObjects.map((item) => item.type))]
            .map((item) => { return { categoryName: item, visible: true } })
            .filter(item => item != null && item.categoryName != null);
        this.setState({ equipmentCategories: equipmentCategories });
        this.updateEquipment(equipmentCategories);
    }

    updateEquipment(equipmentCategories) {
        let niceListObjects = Object.keys(this.props.generalDetails.equipment).map((equipmentKey, index) => {
            let equipment = this.props.generalDetails.equipment[equipmentKey];
            return { value: equipment.name, label: equipment.name, type: equipment.type }
        });

        let eq = [];

        var filteredEquipmentCategories = equipmentCategories.filter(topLevelItem => topLevelItem.visible && topLevelItem.categoryName != null);
        filteredEquipmentCategories.forEach(category => {
            let opts = niceListObjects.filter(s => s.type == category.categoryName);
            if (category.categoryName == null) {
                eq.push(opts);
            } else {
                eq.push({
                    label: category.categoryName,
                    options: opts
                })
            }
        });

        this.setState({ equipment: eq });
    }

    render() {
        return (
            <div className="modalised-content">
                <Message active={this.state.messageActive} error={this.state.messageError} message="Error adding new equipment" />
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        capitalValue: 0,
                        equipmentSelection: "",
                        equipmentType: "",
                        quantity: 1,
                        description: "",
                        rental: false
                    }}
                    onSubmit={(values) => {
                        this.setState({ saving: true });
                        this.props.addEquipment(this.props.contractId, this.props.scenarioId, values, (success, newEquipmentId) => {
                            this.setState({ saving: false });
                            if (success) {
                                this.props.onClose();
                                this.props.history.push(`/contracts/${this.props.contractId}/${this.props.scenarioId}/equipment/${newEquipmentId}?section=equipment`);
                            } else {
                                this.setState({ messageActive: true, messageError: true });
                                setTimeout(() => {
                                    this.setState({ messageActive: false, messageError: false });
                                }, 2000);
                            }
                        });
                    }}
                    render={({ values, handleSubmit, handleChange, handleBlur, setFieldValue, touched, errors }) => (
                        <Fragment>
                            <Row form>
                                <Col md={12}>
                                    <FormGroup>
                                        <Select
                                            defaultValue={this.state.equipment[0]}
                                            options={this.state.equipment}
                                            onChange={(event) => {
                                                let equipmentName = event.value;
                                                let equipment = this.props.generalDetails.equipment[equipmentName];

                                                setFieldValue("equipmentSelection", equipment.name);
                                                setFieldValue("equipmentType", equipment.type);
                                                setFieldValue("capitalValue", equipment.value.replace("$", "").replace(",", ""));
                                                setFieldValue("quantity", 1);
                                            }}>
                                        </Select>
                                        <div className="validation-error">{touched.equipmentSelection && <div>{errors.equipmentSelection}</div>}</div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={12}>
                                    {this.state.equipmentCategories && this.state.equipmentCategories.map((category, index) => {
                                        return (
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input"
                                                    type="checkbox"
                                                    id={`${category.categoryName}Checkbox`}
                                                    checked={category.visible}
                                                    onChange={(e) => {
                                                        var newstate = [...this.state.equipmentCategories];
                                                        newstate[index].visible = !newstate[index].visible;
                                                        this.setState({ equipmentCategories: newstate });
                                                        this.updateEquipment(newstate);
                                                    }}>
                                                </input>
                                                <label className="form-check-label" htmlFor={`${category.categoryName}Checkbox`}>{category.categoryName}</label>
                                            </div>
                                        );
                                    })}
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="equipmentType">Equipment Type</Label>
                                        <ValidatedInput disabled={true} touched={touched.equipmentType} error={errors.equipmentType} field="equipmentType" setFieldValue={setFieldValue} name="equipmentType" value={values.equipmentType} type="text" id="equipmentType" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="capitalValue">Capital Value</Label>
                                        <ValidatedInput touched={touched.capitalValue} error={errors.capitalValue} field="capitalValue" setFieldValue={setFieldValue} name="capitalValue" value={values.capitalValue} type="number" id="capitalValue" inputFormat="dollar" />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="quantity">Quantity</Label>
                                        <ValidatedInput touched={touched.quantity} error={errors.quantity} field="quantity" setFieldValue={setFieldValue} name="quantity" value={values.quantity} type="text" id="quantity" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label for="description">Equipment Notes</Label>
                                <ValidatedInput touched={touched.description} error={errors.description} field="description" setFieldValue={setFieldValue} name="description" value={values.description} type="text" id="description" />
                            </FormGroup>
                            <Button onClick={handleSubmit} color="secondary" size="lg" block>
                                {!this.state.saving && <div> Add Equipment </div>}
                                {this.state.saving && <Loading small light />}
                            </Button>
                        </Fragment >
                    )
                    }
                />
            </div >
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { generalDetails } = state.generalDetailsReducer;
    return { generalDetails };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ addEquipment, getGeneralDetails }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(NewEquipmentPage));

// EXPORT COMPONENT

export { hoc as NewEquipmentPage };
