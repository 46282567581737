// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";

// UI
import { Checkbox } from "../../../containers/inputs/Checkbox";

export class MaterialGroupCell extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: props.selected,
        };
    }

    render() {
        return (
            <Fragment>
                <div
                    onClick={() => {
                        let selected = !this.state.selected;
                        this.setState({ selected: selected });
                        this.props.handleClick(selected, this.props.material);
                    }}
                    className={"material-list" + (this.state.selected ? " selected" : "") + (this.props.materialIndex % 2 === 0 ? " even" : " odd")}
                >
                    <div key={this.props.materialIndex} className="material-list">
                        <div className="material-list-checkbox">
                            <Checkbox
                                observe
                                checked={this.state.selected}
                                handleClick={(checked) => {
                                    this.setState({ selected: !this.state.selected });
                                    this.props.handleClick(checked, this.props.material);
                                }}
                            />
                        </div>
                        <div className="material-list-details">
                            <div className="material-list-title">{this.props.material.MG1FriendlyDesc}</div>
                            {/* <div>{ this.props.material.</div> */}
                            <div className="material-list-secondary">
                                {this.props.material.RRP_INC && "$" + Number(this.props.material.RRP_INC).toLocaleString("en", { minimumFractionDigits: 2 }) + " RRP"}
                                {this.props.material.RRP_INC && <span>&middot;</span>}
                                {this.props.material.MG1}
                                &middot;
                                {this.props.material.UnitsPerCase}
                                Units Per Case
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
