// IMPORT PACKAGE REFERENCES
import React from "react";
import FeatherIcon from "feather-icons-react";
import { Tooltip } from "reactstrap";
import { PieChart, Pie, Sector, Cell } from "recharts";

const data = [
    { name: "Group A", value: 500 },
    { name: "Group B", value: 500 },
];
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

// COMPONENT
export class ResultCell extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tooltipOpen: false,
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        });
    }

    render() {
        return (
            <div className="result-row-container" onClick={this.props.onClick}>
                <div className="result-row">
                    {this.props.showChart && (
                        <PieChart className="result-chart" width={80} height={80} onMouseEnter={this.onPieEnter}>
                            <Pie dataKey="value" data={this.props.chartData} innerRadius={20} outerRadius={30} fill="#8884d8" paddingAngle={5}>
                                {data.map((entry, index) => (
                                    <Cell fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                        </PieChart>
                    )}
                    <div>
                        <div className="result-value">{this.props.value}</div>
                        <div className="result-subtitle">{this.props.label}</div>
                    </div>
                </div>
                <div className="result-guidance-container">
                    {this.props.guidance === "danger" ? (
                        <div>
                            <div id={"tooltip"}>
                                <FeatherIcon className="danger-icon" icon="alert-triangle" />
                            </div>
                            <Tooltip placement={"top"} isOpen={this.state.tooltipOpen} target={"tooltip"} toggle={this.toggle}>
                                {this.props.advice}
                            </Tooltip>
                        </div>
                    ) : (
                            ""
                        )}
                </div>
            </div>
        );
    }
}
