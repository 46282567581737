// IMPORT PACKAGE REFERENCES
import React from "react";
import ReactDOM from "react-dom";

// COMPONENT
export default class Modalised extends React.Component {
    constructor(props) {
        super(props);
        this.escFunction = this.escFunction.bind(this);

        this.state = {
            showing: false,
            contentShowing: false,
        };
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.props.handleClose();
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    componentDidUpdate() {
        if (this.state.showing !== this.props.showing) {
            this.setState({ showing: this.props.showing });

            // Reload the modal content, so that data is cleared from the previous state.
            if (!this.props.showing) {
                setTimeout(() => {
                    this.setState({ contentShowing: false });
                }, 350);
            } else {
                this.setState({ contentShowing: true });
            }
        }
    }

    render() {
        return ReactDOM.createPortal(
            <div className={"modalised-container" + (this.props.showing ? " active" : "") + (this.props.pageModal ? " pageModal" : "")}>
                <div style={{ minWidth: this.props.minWidth, minHeight: this.props.minHeight }} className={"modalised" + (this.props.showing ? " active" : "") + (this.props.relative ? " relative" : "")}>
                    {!this.props.hideHeader && (
                        <div className={"cca-card-header" + (this.props.floating ? " floating" : "")}>
                            <div className="modal-title">{this.props.title}</div>
                            <div>{this.props.action}</div>
                        </div>
                    )}
                    {this.state.contentShowing && <div className="cca-card-container no-padding">{this.props.children}</div>}
                    {this.props.footer && <div className={"cca-card-footer"}>{this.props.footer}</div>}
                </div>
                <div
                    onClick={(e) => {
                        e.preventDefault();
                        this.props.handleClose();
                    }}
                    className="modalised-closer"
                ></div>
            </div>,
            document.body
        );
    }
}
