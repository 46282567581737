import { GET_CUSTOMERS_PENDING, GET_CUSTOMERS_FULFILLED, GET_CUSTOMERS_REJECTED } from "../actions/CustomerActions";

import { CLEAR_REDUCERS } from "../actions/GeneralActions";

// INITIALIZE STATE
const initialState = {
    customers: [],
    fetching: false,
    fetched: false,
    failed: false,
};

// REDUCER
export const CustomerReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_REDUCERS:
            return initialState;
        case GET_CUSTOMERS_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_CUSTOMERS_FULFILLED:
            return {
                ...state,
                customers: action.payload,
                fetching: false,
                fetched: true,
                failed: false,
            };
        case GET_CUSTOMERS_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        default:
            return state;
    }
};
