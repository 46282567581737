import React, { Fragment } from "react";

// Date
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-day-picker/lib/style.css";
import "moment/locale/en-nz";
import moment from "moment";
import { translate, conditions } from "./ConditionaliserHelper";

const classNames = require("classnames");

// UI
import { SingleDatePicker } from "../../components/containers/inputs/SingleDatePicker";
import { DateRangePicker } from "../../components/containers/inputs/DateRangePicker";
import { Button, Input, UncontrolledTooltip } from "reactstrap";
import { CustomDropdown } from "../../components/containers/inputs/CustomDropdown";
import FeatherIcon from "feather-icons-react";

let backspace = 0;

const subtractLight = function (color, amount) {
    let cc = parseInt(color, 16) - amount;
    let c = cc < 0 ? 0 : cc;
    c = c.toString(16).length > 1 ? c.toString(16) : `0${c.toString(16)}`;
    return c;
};

const darken = (color, amount) => {
    color = color.indexOf("#") >= 0 ? color.substring(1, color.length) : color;
    amount = parseInt((255 * amount) / 100);
    color = `#${subtractLight(color.substring(0, 2), amount)}${subtractLight(color.substring(2, 4), amount)}${subtractLight(color.substring(4, 6), amount)}`;
    return color;
};

function randomId() {
    return Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(2, 10);
}

function combineIdsAndValues(keyArray) {
    return Object.keys(keyArray).map((key) => {
        if (key !== "next") {
            let component = keyArray[key];
            component["id"] = key;
            return component;
        }
        return false;
    });
}

let refs = {};

export class Conditionaliser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            suggestionsActive: false,
            suggestionIndex: 0,
            activeField: 0,
            expressions: this.loadSavedExpressions(props.value),
            rawExpressions: props.value, // Unparsed expressions.
            actions: [],
            inputFieldErrors: {},
            valueFormatRenderStack: [],
            calloutShowing: false,
            cleared: false,
            availableTypes: []
        };
        if (this.props.b2b != null && this.props.b2b) {
            this.state.availableTypes = ["SQ123", "SQ124", "SQ127"];
        } else {
            this.state.availableTypes = ["SQ123", "SQ124"];
        }
        this.props.conditionTypes ? (this.state.availableTypes = this.props.conditionTypes) : null;

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocusChange = this.handleFocusChange.bind(this);
        this.handleVolumeInputFieldLeft = this.handleVolumeInputFieldLeft.bind(this);
        this.handleSaveState = this.handleSaveState.bind(this);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    loadSavedExpressions(savedExpressions) {
        let expressions = [];

        if (savedExpressions !== undefined) {
            expressions = savedExpressions;

            /*
                Reassign refs so that we can change the cursor focus when deleting statements.
            */
            expressions.forEach((expression) => {
                expression.forEach((statement) => {
                    let id = randomId();
                    statement.ref = id;
                    refs[id] = React.createRef();
                });
            });
        }

        return expressions;
    }

    componentDidUpdate(prevProps) {
        if (this.props.b2b != null && this.props.b2b) {
            this.state.availableTypes = ["SQ123", "SQ124", "SQ125", "SQ127"];
        } else {
            this.state.availableTypes = ["SQ123", "SQ124", "SQ125"];
        }
        if (JSON.stringify(this.state.rawExpressions) !== JSON.stringify(this.props.value) && !this.state.cleared) {
            this.setState({ rawExpressions: this.props.value, expressions: this.loadSavedExpressions(this.props.value) });
        }
    }

    cancelled() {
        this.setState({ expression: this.loadSavedExpressions(this.state.rawExpressions) });
    }

    parseNext(component, next) {
        let elements = next.split("/");

        // Start at the root level
        let currentCondition = conditions;

        elements.forEach((element) => {
            if (element.startsWith(":")) {
                // We need to replace with an ID.
                currentCondition = currentCondition[component.id];
            } else {
                // Continue traversing.
                currentCondition = currentCondition[element];
            }
        });

        return currentCondition;
    }

    getSuggestionsForComponent(component, suggestions) {
        if (suggestions.hasOwnProperty("next")) {
            /* Function, because this will be handled by the server calls in future. */
            let nextSuggestions = this.parseNext(component, suggestions.next);
            let availableSuggestions = {};

            if (suggestions.hasOwnProperty("availableUnits")) {
                Object.keys(nextSuggestions).forEach((suggestionId) => {
                    if (suggestions.availableUnits.includes(suggestionId)) {
                        availableSuggestions[suggestionId] = nextSuggestions[suggestionId];
                    }
                });
            } else {
                availableSuggestions = nextSuggestions;
            }

            return availableSuggestions;
        }

        return {};
    }

    getDefaultSuggestions(index) {
        // If the index is 0, then don't show conjunctions.
        if (index === 0) {
            return combineIdsAndValues(conditions["types"]).filter((type) => this.state.availableTypes.includes(type.id));
        }

        return combineIdsAndValues(conditions["conjunctions"]);
    }

    getNextSuggestions(statement, expressionIndex, statementIndex) {
        let suggestions = [];

        let nonBracketedComponents = [];
        if (statement.components) {
            nonBracketedComponents = statement.components.filter((component) => !component.hasOwnProperty("isBracket"));
        }

        // If the statement has no existing components then, if its the first statement in the list then show the root suggestions.
        if (nonBracketedComponents.length <= 0) {
            suggestions = this.getDefaultSuggestions(statementIndex);
        } else {
            let lastComponent = nonBracketedComponents[nonBracketedComponents.length - 1];

            // If the last component in the components array has a further options to build the query then show these.
            if (lastComponent.hasOwnProperty("options")) {
                // If we have components existing, then show the last components options.
                suggestions = Object.keys(lastComponent.options)
                    .map((key) => {
                        if (lastComponent.id === "SQ126") {
                            if (this.props.options !== undefined) {
                                if (!this.props.options.includes(key)) {
                                    return;
                                }
                            }
                        }

                        let component = lastComponent.options[key];
                        component["id"] = key;
                        return component;
                    })
                    .filter((item) => {
                        return item !== undefined;
                    });

                // suggestions = combineIdsAndValues(lastComponent.options);
            } else if (!lastComponent.hasOwnProperty("options") && lastComponent.hasOwnProperty("isValueFormat")) {
                // If the last component doesn't have any options but it was a value formatter, check to see if the value formatter has any next.
                let valueFormat = conditions.valueFormats[lastComponent.valueFormat];

                if (valueFormat.hasOwnProperty("next")) {
                    suggestions = combineIdsAndValues(this.getSuggestionsForComponent(lastComponent, valueFormat));
                }
            } else if (!lastComponent.hasOwnProperty("options") && nonBracketedComponents.length <= 1) {
                // If the next branch doesn't have options, the previous must have been a conjunction.
                suggestions = combineIdsAndValues(conditions["types"]).filter((type) => this.state.availableTypes.includes(type.id));
            } else {
                let nextSuggestions = this.getSuggestionsForComponent(lastComponent, lastComponent);
                suggestions = combineIdsAndValues(nextSuggestions);
            }
        }

        // Only return statements that contain text that has been entered.
        suggestions = suggestions.filter((suggestion) => {
            if (suggestion.hasOwnProperty("name")) {
                return suggestion.name.toLowerCase().includes(statement.value.toLowerCase());
            }
            return false;
        });

        return suggestions;
    }

    handleKeyPress(event, expressionIndex, statementIndex, value, inputField = false) {
        let suggestionsActive = true;
        let statement = this.state.expressions[expressionIndex][statementIndex];
        let newState = Object.assign({}, this.state);

        let statementValue = value === undefined ? statement.value : value;

        /* When the enter key is pressed, determine which suggestion to apply to the statement. */
        if (event.key === "Enter" && !inputField) {
            this.props.onChange(this.state.expressions);

            if (statementValue === ")" || statementValue === "(") {
                newState.expressions[expressionIndex][statementIndex].components.push({ isBracket: true, name: statementValue });
                newState.expressions[expressionIndex][statementIndex].value = "";
            } else {
                event.preventDefault();

                let suggestion = {};
                let suggestions = this.getNextSuggestions(statement, expressionIndex, statementIndex);
                suggestion = suggestions[0];

                if (suggestions.length > 0) {
                    suggestion = suggestions[this.state.suggestionIndex];
                    newState.expressions[expressionIndex][statementIndex].components.push(suggestion);
                    newState.expressions[expressionIndex][statementIndex].value = "";
                } else {
                    this.addToStatement(expressionIndex, statementIndex + 1);
                    return;
                }

                if (suggestion.hasOwnProperty("valueFormat")) {
                    newState.expressions[expressionIndex][statementIndex].components.push({ isValueFormat: true, valueFormat: suggestion.valueFormat, valueId: suggestion.valueId });

                    // Provide default values for the addition of dates...
                    if (suggestion.valueFormat === "VF1") {
                        let componentIndex = newState.expressions[expressionIndex][statementIndex].components.length;
                        let dateIndex = 0;
                        let day = moment();
                        let vfKey = this.generateVFKey(expressionIndex, statementIndex, componentIndex);
                        this.handleDayChange(day, expressionIndex, statementIndex, componentIndex - 1, dateIndex, vfKey);
                    }

                    newState.expressions[expressionIndex][statementIndex].value = "";
                    suggestionsActive = false;
                }
            }

            this.setState({ expressions: newState.expressions, suggestionsActive, suggestionIndex: 0 });
        } else if (event.key === "Enter" && inputField) {
            this.props.onChange(this.state.expressions);

            // Focus on the field at the end of the statement line when pressing enter in an input field.
            let statement = this.state.expressions[expressionIndex][statementIndex];
            refs[statement.ref].current.focus();
        }
    }

    handleFocusChange(type, event, expressionIndex, statementIndex) {
        if (type === "focus") {
            this.setState({ suggestionsActive: true, calloutShowing: false, activeField: { statementIndex, expressionIndex } });
        } else {
            this.setState({ suggestionsActive: false, calloutShowing: false, activeField: { statementIndex, expressionIndex } });
        }
    }

    deleteComponentFromStatement(expressionIndex, statementIndex) {
        let newState = Object.assign({}, this.state);
        let statement = this.state.expressions[expressionIndex][statementIndex];
        let components = statement.components;

        let newStatement = newState.expressions[expressionIndex][statementIndex];
        let newComponents = newState.expressions[expressionIndex][statementIndex].components;

        // If the statement is a result statement, then on backspace remove the whole statement line.
        if (statement.hasOwnProperty("isResult") && components.length <= 1) {
            if (statement.isResult) {
                this.removeStatement(expressionIndex, statementIndex);
                return;
            }
        }

        // If we are showing a value formatter then we want to delete this and its associated component.
        if (components.length > 0) {
            let component = components[components.length - 1];

            // If the component is a value format then remove both the value component and its associated identifier.
            if (component.hasOwnProperty("isValueFormat")) {
                if (component.isValueFormat) {
                    let stack = this.state.valueFormatRenderStack;
                    let vfKey = stack.pop();
                    this.setState({ valueFormatRenderStack: stack });
                    let inputFieldErrorsCopy = JSON.parse(JSON.stringify(this.state.inputFieldErrors));
                    inputFieldErrorsCopy[vfKey] = undefined;
                    this.setState({ inputFieldErrors: inputFieldErrorsCopy }, () => {
                        this.handleSaveState();
                    });

                    components.pop();
                }
            }

            // If the components length is still greater than 0, check to see if the last component is a 'None' conjunction.
            if (components.length - 1 === 0) {
                component = components[components.length - 1];

                if (component.hasOwnProperty("isDeletable")) {
                    if (!component.isDeletable) {
                        // Remove the statement.
                        this.removeStatement(expressionIndex, statementIndex);
                    }
                } else {
                    components.pop();
                }
            } else {
                components.pop();
            }

            newComponents = components;
        } else {
            if (backspace > 0) {
                // If we've backspaced twice, then remove the statement.
                this.removeStatement(expressionIndex, statementIndex);
                backspace = 0;
            } else {
                let suggestions = conditions["conjunctions"];

                // If we have no statements in the sequence there is no need to start with a conjunction.
                if (newStatement.length <= 1 || !newState.hasOwnProperty("statements")) {
                    suggestions = conditions["types"];
                }

                newStatement.suggestions = suggestions;
                backspace += 1;
            }
        }

        this.setState({ expressions: newState.expressions, suggestionsActive: true });
    }

    pushNewExpression(newState, id) {
        newState.expressions.push([{ components: [{ id: "CON3", name: "None", isDeletable: false }], value: "", ref: id, id: id, isSubstatement: false }]);
        this.setState({ expressions: newState.expressions });
    }

    handleCtrlN(expressionIndex, statementIndex) {
        let newState = Object.assign({}, this.state);

        // If the previous statement was a result, then push a new expression, otherwise push a new statement onto the existing expression.
        let currentStatement = newState.expressions[expressionIndex][statementIndex];

        let id = randomId();
        refs[id] = React.createRef();

        if (currentStatement.hasOwnProperty("isResult")) {
            if (currentStatement.isResult) {
                // Push a new expression.
                this.pushNewExpression(newState, id);
                return;
            }
        } else {
            // If we don't allow results then new expressions can be pushed without a result row.
            if (!this.props.allowResults) {
                // Push a new expression.
                this.pushNewExpression(newState, id);
                return;
            }
        }

        // If not then proceed in adding a new statement to the existing expression.
        newState.expressions[expressionIndex].push({ components: [], value: "", ref: id, id: id, isSubstatement: false });
        this.setState({ expressions: newState.expressions });
    }

    handleKeyDown(event, expressionIndex, statementIndex, value) {
        if (!event.ctrlKey && event.key === "Backspace" && value === "") {
            this.deleteComponentFromStatement(expressionIndex, statementIndex);
        } else if (event.ctrlKey && event.key === "n") {
            event.preventDefault();
            this.handleCtrlN(expressionIndex, statementIndex);
        } else if (event.ctrlKey && event.key === "Backspace") {
            event.preventDefault();
            this.removeStatement(expressionIndex, statementIndex);
        } else if (event.ctrlKey && event.key === "r") {
            this.addResultRow(event, expressionIndex, statementIndex);
        } else if (event.key === "ArrowDown") {
            let statement = this.state.expressions[expressionIndex][statementIndex];
            let nextSuggestions = this.getNextSuggestions(statement, expressionIndex, statementIndex);

            if (this.state.suggestionIndex < nextSuggestions.length - 1) {
                this.setState({ suggestionIndex: (this.state.suggestionIndex += 1) });
            }
        } else if (event.key === "ArrowUp") {
            if (this.state.suggestionIndex > 0) {
                this.setState({ suggestionIndex: (this.state.suggestionIndex -= 1) });
            }
        }
    }

    addResultRow(event, expressionIndex, statementIndex) {
        if (this.props.allowResults) {
            if (event) {
                event.preventDefault();
            }

            let newState = Object.assign({}, this.state);

            let indexToInsert = statementIndex + 1;
            let id = randomId();
            refs[id] = React.createRef();
            // Insert a new result statement at the provided index.
            if (newState.expressions[expressionIndex][statementIndex].components.length === 0 || (statementIndex === 0 && newState.expressions[expressionIndex][statementIndex].components.length === 1)) {
                // If the statement currently at the index is empty of components, add the result line here.
                indexToInsert = statementIndex;
            }

            if (!newState.expressions[expressionIndex][statementIndex].isResult) {
                // If the index to insert is the same as the statment index then replace instead of splicing, because this is the only statement in the expression so far.
                if (indexToInsert === statementIndex) {
                    newState.expressions[expressionIndex].splice(indexToInsert, 1, { components: [conditions.types["SQ126"]], isResult: true, value: "", ref: id, id: id, isSubstatement: true });
                } else {
                    newState.expressions[expressionIndex].splice(indexToInsert, 0, { components: [conditions.types["SQ126"]], isResult: true, value: "", ref: id, id: id, isSubstatement: true });
                }
            }

            this.setState({ expressions: newState.expressions });
        }
    }

    handleChange(event, expressionIndex, statementIndex, statement) {
        let newState = Object.assign({}, this.state);
        let nextSuggestions = this.getNextSuggestions(statement, expressionIndex, statementIndex);
        let existingValue = newState.expressions[expressionIndex][statementIndex].value;

        if (nextSuggestions.length > 0 || existingValue.length > 0) {
            newState.expressions[expressionIndex][statementIndex].value = event.target.value;
            this.setState({ statements: newState.statements });
        }
    }

    removeStatement(expressionIndex, statementIndex) {
        let newState = Object.assign({}, this.state);
        let expressions = newState.expressions;
        let statements = expressions[expressionIndex];
        statements.splice(statementIndex, 1);

        if (statements.length <= 0) {
            newState.expressions.splice(expressionIndex, 1);
        }

        // Update the input focus so that the cursor is on the previous statement.
        if (statements.length > 0) {
            let previousStatement = statements[statements.length - 1];
            refs[previousStatement.ref].current.focus();
        } else {
            // If there is a previous expression, jump focus to that.
            if (newState.expressions.length > 0 && expressionIndex > 0) {
                let numberOfStatementsInPreviousExpression = newState.expressions[expressionIndex - 1].length;

                if (numberOfStatementsInPreviousExpression > 0) {
                    let previousStatements = newState.expressions[expressionIndex - 1];
                    let numberOfPreviousStatements = previousStatements.length;
                    let previousStatement = previousStatements[numberOfPreviousStatements - 1];
                    refs[previousStatement.ref].current.focus();
                }
            }
        }

        this.setState({ expressions: newState.expressions });
    }

    updateValueOfComponent(value, expressionIndex, statementIndex, componentIndex) {
        let newState = Object.assign({}, this.state);
        newState.expressions[expressionIndex][statementIndex].components[componentIndex].value = value;
        this.setState({ expressions: newState.expressions });
    }

    handleNumberChange(event, expressionIndex, statementIndex, componentIndex) {
        this.updateValueOfComponent(event.target.value, expressionIndex, statementIndex, componentIndex);
    }

    handleTextChange(event, expressionIndex, statementIndex, componentIndex) {
        this.updateValueOfComponent(event.target.value, expressionIndex, statementIndex, componentIndex);
    }

    handleOptionChange(option, expressionIndex, statementIndex, componentIndex) {
        let newState = Object.assign({}, this.state);
        newState.expressions[expressionIndex][statementIndex].components[componentIndex].value = option.id;
        this.setState({ expressions: newState.expressions });
    }

    handleDateChange(date, expressionIndex, statementIndex, componentIndex) {
        if (date !== null) {
            let newDateString = date.format("DD/MM/YYYY").toString();
            this.updateValueOfComponent(newDateString, expressionIndex, statementIndex, componentIndex);
        }
    }

    handleDayChange(day, expressionIndex, statementIndex, componentIndex, dateIndex, vfKey) {
        let newState = Object.assign({}, this.state);

        if (newState.expressions[expressionIndex][statementIndex].components[componentIndex].value === undefined) {
            newState.expressions[expressionIndex][statementIndex].components[componentIndex].value = [moment().format("DD/MM/YYYY").toString(), moment().format("DD/MM/YYYY").toString()];
        }

        newState.expressions[expressionIndex][statementIndex].components[componentIndex].value[dateIndex] = day.format("DD/MM/YYYY").toString();

        this.setState({ expressions: newState.expressions });
    }

    getVolumeErrorMessage(input) {
        let errorMessage = null;
        let isWhole = input % 1 === 0;

        if (!isWhole) {
            errorMessage = "Enter a whole number";
        }
        if (isNaN(input)) {
            errorMessage = "Enter a number";
        }
        if (input <= 0) {
            errorMessage = "Enter a number greater than 0";
        }

        return errorMessage;
    }

    getPercentErrorMessage(input) {
        let errorMessage = null;

        if (isNaN(input)) {
            errorMessage = "Enter a number";
        }
        if (input <= 0) {
            errorMessage = "Enter a number greater than 0 percent";
        } else if (input > 100) {
            errorMessage = "Enter a number less than 100 percent";
        }

        return errorMessage;
    }

    handleVolumeInputFieldLeft(event, key) {
        let input = parseFloat(event.target.value);
        let errorMessage = this.getVolumeErrorMessage(input);

        let inputFieldErrorsCopy = JSON.parse(JSON.stringify(this.state.inputFieldErrors));
        inputFieldErrorsCopy[key] = errorMessage;
        let stack = this.state.valueFormatRenderStack;

        var top = stack[stack.length - 1];

        if (top !== key) {
            stack.push(key);
        }

        this.setState({ inputFieldErrors: inputFieldErrorsCopy, valueFormatRenderStack: stack }, () => {
            this.handleSaveState();
        });
    }

    handlePercentInputFieldLeft(event, key) {
        let input = parseFloat(event.target.value);
        let errorMessage = this.getPercentErrorMessage(input);

        let inputFieldErrorsCopy = JSON.parse(JSON.stringify(this.state.inputFieldErrors));
        inputFieldErrorsCopy[key] = errorMessage;
        let stack = this.state.valueFormatRenderStack;
        var top = stack[stack.length - 1];

        if (top !== key) {
            stack.push(key);
        }

        this.setState({ inputFieldErrors: inputFieldErrorsCopy, valueFormatRenderStack: stack }, () => {
            this.handleSaveState();
        });
    }

    handleSaveState() {
        let allowSaving = true;

        Object.values(this.state.inputFieldErrors).map((message) => {
            if (message !== null && message !== undefined) {
                allowSaving = false;
            }
        });

        if (allowSaving) {
            this.props.setSaveButtonState(true);
        } else {
            this.props.setSaveButtonState(false);
        }
    }

    generateVFKey(expressionIndex, statementIndex, componentIndex) {
        return parseInt("" + expressionIndex + statementIndex + componentIndex);
    }

    renderValueFormat(valueFormat, expressionIndex, statementIndex, componentIndex, value) {
        let valueLength = value !== undefined ? value.length + 1 : 0;
        let vfKey = this.generateVFKey(expressionIndex, statementIndex, componentIndex);

        var inlineInputClass = classNames({
            "inline-input": true,
            error: this.state.inputFieldErrors[vfKey] !== null && this.state.inputFieldErrors[vfKey] !== undefined,
        });

        if (valueFormat !== undefined) {
            switch (valueFormat) {
                case "VF1":
                    if (value !== undefined) {
                        value = [moment(value[0], "DD/MM/YYYY"), moment(value[1], "DD/MM/YYYY")];
                    } else {
                        value = [moment(), moment()];
                    }

                    return (
                        <div key={vfKey} className="tag-field">
                            <div className="date-input-container">
                                <DateRangePicker conditionalInput dateIndex={0} contractStart={true} buttonLabel="Contract Start" dateValue={value} handleDayChange={this.handleDayChange} key={vfKey} expressionIndex={expressionIndex} statementIndex={statementIndex} componentIndex={componentIndex} />
                            </div>
                            <div className="tag and">and</div>
                            <div className="date-input-container">
                                <DateRangePicker conditionalInput dateIndex={1} contractEnd={true} buttonLabel="Contract End" dateValue={value} handleDayChange={this.handleDayChange} key={vfKey} expressionIndex={expressionIndex} statementIndex={statementIndex} componentIndex={componentIndex} />
                            </div>
                        </div>
                    );
                case "VF2":
                    if (value !== undefined) {
                        value = moment(value, "DD/MM/YYYY");
                    } else {
                        value = moment();
                    }

                    return (
                        <div className="date-input-container">
                            <SingleDatePicker conditionalInput dateIndex={1} contractEndButton={true} key={vfKey} dateValue={value} handleDateChange={this.handleDateChange} expressionIndex={expressionIndex} statementIndex={statementIndex} componentIndex={componentIndex} />
                        </div>
                    );
                case "VF3":
                    return (
                        <Input
                            type="number"
                            onKeyPress={(event) => this.handleKeyPress(event, expressionIndex, statementIndex, value, true)}
                            key={vfKey}
                            style={{ width: valueLength * 15 + "px" }}
                            className={inlineInputClass}
                            value={value}
                            autoFocus
                            onChange={(e) => this.handleNumberChange(e, expressionIndex, statementIndex, componentIndex)}
                            onBlur={(e) => this.handleVolumeInputFieldLeft(e, vfKey)}
                        />
                    );
                case "VF4":
                    return <Input type="number" onKeyPress={(event) => this.handleKeyPress(event, expressionIndex, statementIndex, value, true)} value={value} key={vfKey} style={{ width: valueLength * 15 + "px" }} className={inlineInputClass} autoFocus onChange={(e) => this.handleNumberChange(e, expressionIndex, statementIndex, componentIndex)} />;
                case "VF5":
                    return <CustomDropdown selectedId={value} onChange={(option) => this.handleOptionChange(option, expressionIndex, statementIndex, componentIndex)} save={this.props.saveCustomConditions} sections={[{ title: "Contract compliance", elements: this.props.customConditions }]} type={"Condition"} />;
                case "VF6":
                    return <Input type="number" onKeyPress={(event) => this.handleKeyPress(event, expressionIndex, statementIndex, value, true)} style={{ width: valueLength * 15 + "px" }} key={vfKey} className={inlineInputClass} value={value} autoFocus onChange={(e) => this.handleNumberChange(e, expressionIndex, statementIndex, componentIndex)} />;
                case "VF7":
                    return (
                        <Input
                            type="number"
                            onKeyPress={(event) => this.handleKeyPress(event, expressionIndex, statementIndex, value, true)}
                            style={{ width: valueLength * 15 + "px" }}
                            key={vfKey}
                            className={inlineInputClass}
                            value={value}
                            autoFocus
                            onChange={(e) => this.handleNumberChange(e, expressionIndex, statementIndex, componentIndex)}
                            onBlur={(e) => this.handlePercentInputFieldLeft(e, vfKey)}
                        />
                    );
                case "VF8":
                    return <Input type="number" onKeyPress={(event) => this.handleKeyPress(event, expressionIndex, statementIndex, value, true)} style={{ width: valueLength * 15 + "px" }} key={vfKey} className={inlineInputClass} value={value} autoFocus onChange={(e) => this.handleNumberChange(e, expressionIndex, statementIndex, componentIndex)} />;
                case "VF9":
                    return <Input type="number" onKeyPress={(event) => this.handleKeyPress(event, expressionIndex, statementIndex, value, true)} style={{ width: valueLength * 15 + "px" }} key={vfKey} className={inlineInputClass} value={value} autoFocus onChange={(e) => this.handleNumberChange(e, expressionIndex, statementIndex, componentIndex)} />;
                case "VF10":
                    if (value !== undefined) {
                        value = moment(value, "DD/MM/YYYY");
                    } else {
                        value = moment();
                    }

                    return (
                        <div className="date-input-container">
                            <SingleDatePicker conditionalInput dateIndex={0} contractStartButton={true} key={vfKey} dateValue={value} handleDateChange={this.handleDateChange} expressionIndex={expressionIndex} statementIndex={statementIndex} componentIndex={componentIndex} />
                        </div>
                    );
            }
        }
    }

    getExpressions() {
        // Filter out the ref which contains a circular reference.
        let expressions = Object.assign([], this.state.expressions);

        expressions = JSON.stringify(expressions, (key, value) => {
            if (key !== "ref" && key !== "color" && key !== "name" && key !== "options" && key !== "suggestions") {
                return value;
            }
        });

        return expressions;
    }

    clearExpressions() {
        this.setState({ expressions: [], cleared: true });
    }

    addNewStatement() {
        let newState = Object.assign({}, this.state);

        let statementId = randomId();
        refs[statementId] = React.createRef();

        newState.expressions = [[{ components: [{ id: "CON3", name: "None", isDeletable: false }], value: "", ref: statementId, id: statementId, isSubstatement: false }]];
        this.setState({ expressions: newState.expressions });
    }

    addToStatement(expressionIndex, statementIndex) {
        let newState = Object.assign({}, this.state);

        let id = randomId();
        refs[id] = React.createRef();

        if (statementIndex === 0) {
            // Add a empty conjunction.
            newState.expressions[expressionIndex].splice(statementIndex, 0, { components: [{ id: "CON3", name: "None", isDeletable: false }], value: "", ref: id, id: id, isSubstatement: false });
        } else {
            newState.expressions[expressionIndex].splice(statementIndex, 0, { components: [], value: "", ref: id, id: id, isSubstatement: false });
        }

        this.setState({ expressions: newState.expressions });
    }

    handleOptionSelected(event, expressionIndex, statementIndex, suggestion) {
        event.preventDefault();
        let suggestionsActive = true;
        let newState = Object.assign({}, this.state);
        newState.expressions[expressionIndex][statementIndex].components.push(suggestion);
        newState.expressions[expressionIndex][statementIndex].value = "";

        if (suggestion.hasOwnProperty("valueFormat")) {
            newState.expressions[expressionIndex][statementIndex].components.push({ isValueFormat: true, valueFormat: suggestion.valueFormat, valueId: suggestion.valueId });
            newState.expressions[expressionIndex][statementIndex].value = "";
            suggestionsActive = false;
        }

        this.setState({ expressions: newState.expressions, suggestionsActive: suggestionsActive });
    }

    shouldShowContinuationActions(expressionIndex) {
        let resultRow = false;
        let statements = this.state.expressions[expressionIndex];
        let lastStatement = statements[statements.length - 1];

        if (lastStatement.isResult) {
            resultRow = true;
        }

        let suggestions = this.getNextSuggestions(lastStatement, expressionIndex, statements.length - 1);

        return this.state.expressions.length - 1 === expressionIndex && !resultRow && suggestions.length === 0;
    }

    shouldShowResultContinuationAction(expressionIndex) {
        let resultRow = false;
        let statements = this.state.expressions[expressionIndex];
        let lastStatement = statements[statements.length - 1];

        if (lastStatement.isResult) {
            resultRow = true;
        }

        let suggestions = this.getNextSuggestions(lastStatement, expressionIndex, statements.length - 1);

        return this.state.expressions.length - 1 === expressionIndex && resultRow && suggestions.length === 0;
    }

    render() {
        return (
            <Fragment>
                {this.state.expressions.length === 0 ? (
                    <div>
                        {!this.props.editing ? (
                            <div className={"discount-empty" + (this.props.editing ? " active" : "")}>
                                <div className="discount-placeholder-text">No compliance requirements</div>
                            </div>
                        ) : (
                            <div className={"discount-empty" + (this.props.editing ? " active" : "")}>
                                <Button className="action" onClick={() => this.addNewStatement()}>
                                    + Create New Condition
                                </Button>
                            </div>
                        )}
                    </div>
                ) : this.props.editing ? (
                    <Fragment>
                        {this.state.expressions.map((expression, expressionIndex) => {
                            let sIndex;
                            return (
                                <Fragment>
                                    <div key={expressionIndex} className={"expression " + (this.props.hoveredExpressionIndex === expressionIndex ? "hovered" : "")}>
                                        <div className="expression-grouper" />
                                        {expression.map((statement, statementIndex) => {
                                            sIndex = statementIndex;
                                            let suggestions = this.getNextSuggestions(statement, expressionIndex, statementIndex);

                                            return (
                                                <Fragment key={statementIndex}>
                                                    {statementIndex === 0 && (
                                                        <div
                                                            onClick={() => {
                                                                this.addToStatement(expressionIndex, statementIndex - 1);
                                                            }}
                                                            className="add-statement-divider"
                                                        ></div>
                                                    )}
                                                    <div className={"statement " + (statement.isResult ? "indented" : "")}>
                                                        <div className="statement-delete-container">
                                                            <FeatherIcon onClick={() => this.removeStatement(expressionIndex, statementIndex)} className="statement-delete-icon" icon="x-circle" />
                                                        </div>
                                                        {statement.isResult && <div className="indent">The customer will receive a</div>}
                                                        <div className="conditional-input">
                                                            {statement.components !== undefined &&
                                                                statement.components.map((component, componentIndex) => {
                                                                    if (component.hasOwnProperty("isValueFormat")) {
                                                                        if (component.isValueFormat) {
                                                                            return this.renderValueFormat(component.valueFormat, expressionIndex, statementIndex, componentIndex, component.value);
                                                                        }
                                                                    }

                                                                    // If the component isn't a None conjunction then render the component's tag.
                                                                    if (component.id !== "CON3" && component.id !== "SQ126") {
                                                                        return (
                                                                            <div key={componentIndex} style={{ borderColor: component.color === undefined ? "orange" : darken(component.color, 15), backgroundColor: component.color === undefined ? "orange" : component.color }} className="tag">
                                                                                {component.name}
                                                                            </div>
                                                                        );
                                                                    }
                                                                })}

                                                            <div className="conditional-input-container">
                                                                <input
                                                                    ref={refs[statement.ref]}
                                                                    focus="true"
                                                                    autoFocus
                                                                    value={statement.value}
                                                                    onFocus={(e) => this.handleFocusChange("focus", e, expressionIndex, statementIndex)}
                                                                    onBlur={(e) => this.handleFocusChange("blur", e)}
                                                                    onChange={(e) => this.handleChange(e, expressionIndex, statementIndex, statement)}
                                                                    onKeyDown={(e) => this.handleKeyDown(e, expressionIndex, statementIndex, statement.value)}
                                                                    onKeyPress={(e) => this.handleKeyPress(e, expressionIndex, statementIndex)}
                                                                    className="conditional-input"
                                                                    contentEditable={true}
                                                                />

                                                                <div className={"conditional-suggestions " + (this.getNextSuggestions(statement, expressionIndex, statementIndex).length > 0 && statementIndex === this.state.activeField.statementIndex && expressionIndex === this.state.activeField.expressionIndex ? "active" : "")}>
                                                                    <div className="suggestion-option-group">Types</div>
                                                                    {suggestions.map((suggestion, suggestionIndex) => {
                                                                        if (!suggestion.hasOwnProperty("display")) {
                                                                            return (
                                                                                <div key={suggestionIndex} onMouseDown={(e) => this.handleOptionSelected(e, expressionIndex, statementIndex, suggestion)} className={"suggestion-option " + (this.state.suggestionIndex === suggestionIndex ? " active" : "")}>
                                                                                    {suggestion.name}
                                                                                </div>
                                                                            );
                                                                        }
                                                                    })}

                                                                    {this.state.actions.length > 0 && (
                                                                        <Fragment>
                                                                            <div className="suggestion-option-group">Actions</div>
                                                                            {this.state.actions.map((action) => {
                                                                                return (
                                                                                    <div key={suggestionIndex} className={"suggestion-option"}>
                                                                                        {action}
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </Fragment>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {statementIndex !== expression.length - 1 && (
                                                        <div
                                                            onClick={() => {
                                                                this.addToStatement(expressionIndex, statementIndex + 1);
                                                            }}
                                                            className="add-statement-divider"
                                                        ></div>
                                                    )}
                                                </Fragment>
                                            );
                                        })}
                                        {this.shouldShowContinuationActions(expressionIndex) && (
                                            <div className="continuation-container">
                                                <div onClick={() => this.addToStatement(expressionIndex, this.state.expressions[expressionIndex].length + 1)} className="add-tag">
                                                    {this.props.allowResults && <div> Add Row (CTRL+N) </div>}
                                                    {!this.props.allowResults && <div> Add Row (ENTER) </div>}
                                                </div>

                                                {!this.props.allowResults && (
                                                    <Fragment>
                                                        <div onClick={() => this.handleCtrlN(expressionIndex, sIndex)} className="add-tag">
                                                            Add Condition (CTRL+N)
                                                        </div>

                                                        {/* <div onClick={() => this.props.onSave()} className="add-tag">
                                                            Save Conditions
                                                        </div>  */}
                                                    </Fragment>
                                                )}

                                                {this.props.allowResults && (
                                                    <div onClick={(event) => this.addResultRow(event, expressionIndex, this.state.expressions[expressionIndex].length - 1)} className="add-tag">
                                                        Add Result (CTRL+R)
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        {this.shouldShowResultContinuationAction(expressionIndex) && (
                                            <div className="continuation-container">
                                                {/* <div className="add-tag" onClick={() => this.handleCtrlN(expressionIndex, sIndex)}> Add Condition (CTRL+N) </div> */}

                                                <Fragment>
                                                    <div onClick={() => this.handleCtrlN(expressionIndex, sIndex)} className="add-tag">
                                                        Add Condition (CTRL+N)
                                                    </div>

                                                    {/* <div onClick={() => this.props.onSave()} className="add-tag">
                                                        Save Conditions
                                                    </div>  */}
                                                </Fragment>
                                            </div>
                                        )}
                                    </div>
                                </Fragment>
                            );
                        })}
                    </Fragment>
                ) : (
                    <div className="translation"> {translate(this.state.expressions, this.props.allowResults, this.props.customConditions)} </div>
                )}
            </Fragment>
        );
    }
}
