// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Jumbotron } from "reactstrap";

// IMPORT PROJECT REFERENCES
import { getContracts, deleteContracts } from "../../state/actions/ContractActions";
import { prepareForContractSwitch } from "../../state/actions/GeneralActions";
import { ResponsiveList } from "./lists/ResponsiveList";
import { Loading } from "../../containers/loading/Loading";
import Modalised from "../../containers/layout/Modalised";
import { NewContractModal } from "./NewContractModal";
import FeatherIcon from "feather-icons-react";

// UI
import { Button } from "reactstrap";
import { CELL_TYPES } from "./CellTypes";
import { ContextPage } from "../../containers/layout/ContextPage";
import { Message } from "../../containers/layout/Message";
import { Card } from "../../containers/card/Card";
import { FloatingButton } from "../../containers/inputs/FloatingButton";
import { FloatingBar } from "../../containers/layout/FloatingBar";

// COMPONENT
class ContractsListPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            newContractModalShowing: false,
            editingShowing: false,
            message: "",
            messageActive: false,
            showDelete: false,
        };

        this.newCustomerPage = this.newCustomerPage.bind(this);
        this.handleNewContractClicked = this.handleNewContractClicked.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.toggleEditing = this.toggleEditing.bind(this);

        // Responsive List
        this.responsiveList = null;
    }

    componentDidMount() {
        this.props.prepareForContractSwitch();
        this.props.getContracts(this.props.user.uid, "custom", this.props.personal);
    }

    newCustomerPage() {
        this.props.history.push("/contracts/new");
    }

    handleNewContractClicked() {
        this.setState({ newContractModalShowing: true });
    }

    hideModal() {
        this.setState({ newContractModalShowing: false });
    }

    handleDelete(selectedContracts) {
        if (confirm("Are you sure you want to delete?")) {
            let contractsToDelete = [];

            selectedContracts.forEach((item) => {
                contractsToDelete.push(item.id);
            });

            this.props.deleteContracts(contractsToDelete, (success) => {
                if (success) {
                    this.setState({ editingShowing: false });
                } else {
                    this.setState({ messageActive: true });
                    setTimeout(() => {
                        this.setState({ messageActive: false });
                    }, 2000);
                }
            });
        }

        this.setState({ showDelete: false });
    }

    navigateToContract(contractId) {
        let contract = this.props.contracts[contractId];
        let scenarioId = contract.baseScenario;

        // Only transition to the contract if a scenario exists...
        if (scenarioId) {
            this.props.history.push(`/contracts/${contractId}/${scenarioId}/details`);
        } else {
            alert("No base scenario exists...");
        }
    }

    toggleEditing() {
        this.setState({
            editingShowing: !this.state.editingShowing,
            showDelete: false,
        });
    }

    render() {
        let sections = [
            {
                items: Object.values(this.props.contracts),
                emptyLabel: "No contracts",
                sectionLabel: "",
                sectionIdentifier: "contracts",
                identifier: "contractId",
                cell: {
                    type: CELL_TYPES.CONTRACT,
                    dataIdentifiers: { label: "contractName", subtitle: "createdBy", createdAt: "createdAt", accountStatus: "accountStatus" },
                },
            },
        ];

        return (
            <Fragment>
                <Message error message="Error deleting contracts" active={this.state.messageActive} />
                <Fragment>
                    <Modalised
                        title={"New contract"}
                        action={
                            <div
                                onClick={() => {
                                    this.hideModal();
                                }}
                                className="text-link"
                            >
                                <FeatherIcon icon="x" />
                            </div>
                        }
                        handleClose={() => {
                            this.hideModal();
                        }}
                        showing={this.state.newContractModalShowing}
                    >
                        <NewContractModal onClose={() => this.hideModal()} />
                    </Modalised>
                    <ContextPage
                        padded={false}
                        firstElement={
                            <div>
                                {this.props.user.role === "admin" ?
                                    <Button className="padded-right" className="menu-action-button" color="link" onClick={this.toggleEditing}>
                                        <FeatherIcon icon="edit-2" className="button-icon" />
                                        {this.state.editingShowing ? "Done" : "Edit"}
                                    </Button>
                                    : <div></div>
                                }
                            </div>
                        }
                        fetching={this.props.fetching}
                        fetched={this.props.fetched}
                        title={this.props.pageName}
                    >
                        {/* <Jumbotron>
                            <h1 className="display-3">Hello { this.props.user.name.split(" ")[0] }!</h1>
                            <p className="lead">
                                Welcome to SaMa, a tool for creating and analysing the return on investment of custom CCA agreements.
                            </p>
                        </Jumbotron> */}

                        {this.props.fetched && (
                            <Card>
                                <ResponsiveList
                                    hideSectionHeader
                                    onRef={(ref) => {
                                        this.responsiveList = ref;
                                    }}
                                    showDelete={() => {
                                        this.setState({ showDelete: true });
                                    }}
                                    hideDelete={() => {
                                        this.setState({ showDelete: false });
                                    }}
                                    placeholderTitle={"No Contracts"}
                                    showPlaceholder
                                    disableAutoRoute
                                    editingShowing={this.state.editingShowing}
                                    handleDelete={this.handleDelete}
                                    handleClick={(contractId) => {
                                        this.navigateToContract(contractId);
                                    }}
                                    actions={["Delete"]}
                                    sections={sections}
                                />
                            </Card>
                        )}

                        {this.props.fetching && <Loading />}
                        {this.props.failed && <div> {"Couldn't fetch contracts."} </div>}
                    </ContextPage>
                </Fragment>

                <FloatingBar>
                    {this.state.showDelete && (
                        <FloatingButton
                            classes="delete-button"
                            label={
                                <div>
                                    <FeatherIcon className="fab-button-icon" icon="minus-circle" />
                                    Delete
                                </div>
                            }
                            action={() => this.handleDelete(this.responsiveList.getSelectedItems())}
                        />
                    )}
                    <FloatingButton
                        label={
                            <div>
                                <FeatherIcon className="fab-button-icon" icon="plus-circle" />
                                New Contract
                            </div>
                        }
                        action={() => this.handleNewContractClicked()}
                    />
                </FloatingBar>
            </Fragment>
        );
    }
}

ContractsListPage.propTypes = {
    contracts: PropTypes.object.isRequired,
    fetching: PropTypes.bool.isRequired,
    fetched: PropTypes.bool.isRequired,
    failed: PropTypes.bool.isRequired,
    getContracts: PropTypes.func.isRequired,
    history: PropTypes.object,
};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { contracts, fetching, fetched, failed } = state.contractReducer;
    const { user } = state.userReducer;
    return { contracts, fetching, fetched, failed, user };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getContracts, prepareForContractSwitch, deleteContracts }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractsListPage));

// EXPORT COMPONENT

export { hoc as ContractsListPage };
