// IMPORT PACKAGE REFERENCES
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ContextPage } from "../../../containers/layout/ContextPage";
import moment from "moment";
import { Button } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import Select from 'react-select'

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import PrintProvider, { NoPrint, Print } from 'react-easy-print';
// API IMPORT
import { API_ROOT, APPROVALS_ROOT } from "../../../../config/config";

// IMPORT ACTIONS
import { getScenarioDetails, getContractDetails, setCurrentComparisonScenario } from "../../../state/actions/ContractDetailsActions";
import { getEquipments } from "../../../state/actions/EquipmentActions";
import { getProductGroups } from "../../../state/actions/ProductGroupActions";
import { getPayments, getNovelties } from "../../../state/actions/PaymentActions";
import { getNonFinancials } from "../../../state/actions/NonFinancialActions";
import { getMaterialGroupDataPoint, getAnnualInvestmentSummary, getAnnualInvestmentSummaries, getMaterialSummary, getApproval, getAdditionalComments, postAdditionalComments } from "../../../state/actions/SummaryActions";
import { getContract } from "../../../state/actions/ContractActions";
import { getDocumentList, deleteDocument, sendContractToPnR } from "../../../state/actions/DocumentActions";

// IMPORT UI
import { FlexibleDataTable } from "../../../containers/table/FlexibleDataTable";
import { eq, isNumber } from "lodash";

export const FormContext = React.createContext();

const customStyles = {
    menuList: () => ({
        // none of react-select's styles are passed to <Control />
        color: "#000000",
    }),
    pnrButton: () => ({
        color: "#28a745",
        backgroundColor: "#FFFFFF"
    })
}

// COMPONENT
class ContractReviewPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedPortfolio: 0,
            equipments: {},
            annualInvestmentSummary: props.annualInvestmentSummary,
            scenarios: [],
            fetching: false,
            fetched: false,
            approval: {},
            portfolioOptions: [
                { value: null, label: "All" },
                { value: "NARTD", label: "🥤 NARTD" },
                { value: "Coffee", label: "☕️ Coffee" },
                { value: "Alcohol", label: "🍻 Alcohol" }
            ],
            selectedComparisonScenarios: [],
        };

        this.scenarioId = props.match.params.scenarioId;
        this.contractId = props.match.params.contractId;

        this.getAnnualInvestmentSummary = this.getAnnualInvestmentSummary.bind(this);
        this.calculateAnnualInvestmentSummaries = this.calculateAnnualInvestmentSummaries.bind(this);
        this.calculateMaterialSummary = this.calculateMaterialSummary.bind(this);
        this.getSelectScenarioOptions = this.getSelectScenarioOptions.bind(this);
        this.generateDocumentList = this.generateDocumentList.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);
        this.submitToPnR = this.submitToPnR.bind(this);
    }

    componentDidMount() {
        this.getDetails();
        this.calculateAnnualInvestmentSummaries();
        this.calculateMaterialSummary();
        this.props.getDocumentList(this.contractId, this.scenarioId, _);
        this.props.getAdditionalComments(this.contractId, this.props.match.params.scenarioId);
        this.props.getApproval(this.contractId, this.props.match.params.scenarioId);

        this.props.getContract(this.contractId, (success) => {
            if (success) {
                let scenarios = Object.values(this.props.contract.scenarios).map((value) => {
                    return value.scenarioId;
                });

                this.setState({ scenarios: scenarios });
            }
        });
    }

    calculateMaterialSummary() {
        this.props.getMaterialSummary(this.contractId, this.scenarioId, this.props.annualInvestmentSummaryTimestamp, () => { });
    }

    calculateAnnualInvestmentSummaries(portfolioIndex = null) {
        this.getAnnualInvestmentSummary(this.contractId, this.scenarioId, this.props.annualInvestmentSummaryTimestamp, false, true, portfolioIndex);
        this.props.comparisonScenario ? this.getAnnualInvestmentSummary(this.contractId, this.props.comparisonScenario, this.props.annualInvestmentSummaryTimestamp, true, portfolioIndex) : null;
    }

    getDetails() {
        let scenarioDetailsPromise = new Promise((resolve, reject) =>
            this.props.getScenarioDetails(this.contractId, this.scenarioId, (success) => {
                resolve();
            })
        );
        let equipmentsPromise = new Promise((resolve, reject) =>
            this.props.getEquipments(this.contractId, this.scenarioId, (success) => {
                resolve();
            })
        );
        let productGroupsPromise = new Promise((resolve, reject) =>
            this.props.getProductGroups(this.contractId, this.scenarioId, (success) => {
                resolve();
            })
        );
        let paymentsPromise = new Promise((resolve, reject) =>
            this.props.getPayments(this.contractId, this.scenarioId, (success) => {
                resolve();
            })
        );
        let noveltiesPromise = new Promise((resolve, reject) =>
            this.props.getNovelties(this.contractId, this.scenarioId, (success) => {
                resolve();
            })
        );
        let nonFinancialsPromise = new Promise((resolve, reject) =>
            this.props.getNonFinancials(this.contractId, this.scenarioId, (success) => {
                resolve();
            })
        );
        let contractDetailsPromise = new Promise((resolve, reject) =>
            this.props.getContractDetails(this.contractId, (success) => {
                resolve();
            })
        );

        this.setState({
            fetching: true,
            fetched: false,
        });

        Promise.all([scenarioDetailsPromise, equipmentsPromise, productGroupsPromise, paymentsPromise, noveltiesPromise, nonFinancialsPromise, contractDetailsPromise]).then(() => {
            this.setState({
                fetching: false,
                fetched: true,
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.scenarioId !== this.props.match.params.scenarioId) {
            this.scenarioId = this.props.match.params.scenarioId;
            this.getDetails();
            this.calculateAnnualInvestmentSummaries();
            this.calculateMaterialSummary();
            this.props.getApproval(this.contractId, this.props.match.params.scenarioId);
            // Reset comparison scenarios
            this.setState({ selectedComparisonScenarios: [] });
        }

        if (JSON.stringify(this.props.equipments) !== JSON.stringify(this.state.equipments)) {
            this.setState({ equipments: this.props.equipments });
        }

        if (JSON.stringify(prevState.annualInvestmentSummary) !== JSON.stringify(this.props.annualInvestmentSummary)) {
            this.setState({ annualInvestmentSummary: this.props.annualInvestmentSummary });
        }

        /* Check if the currently selected scenario for comparison has updated and re-calculate summary if so */
        if (prevProps.comparisonScenario !== this.props.comparisonScenario) {
            this.getAnnualInvestmentSummary(this.contractId, this.props.comparisonScenario, this.props.annualInvestmentSummaryTimestamp, true, true);
        }

        if (JSON.stringify(prevState.annualInvestmentSummaryComparison) !== JSON.stringify(this.props.annualInvestmentSummaryComparison)) {
            this.setState({ annualInvestmentSummaryComparison: this.props.annualInvestmentSummaryComparison });
        }

        if (JSON.stringify(this.props.approval) !== JSON.stringify(this.state.approval)) {
            this.setState({ approval: this.props.approval });
        }

        if (this.state.selectedComparisonScenarios != prevState.selectedComparisonScenarios) {
            this.props.getAnnualInvestmentSummaries(this.contractId, this.state.selectedComparisonScenarios, this.state.portfolioOptions[this.state.selectedPortfolio].value, (success) => {
                return success;
            });
        }
    }

    getAnnualInvestmentSummary(contractId, scenarioId, lastCalculated, isComparison = false, forceRecalculate = false, portfolioIndex = null) {
        portfolioIndex ?
            this.props.getAnnualInvestmentSummary(contractId, scenarioId, isComparison, lastCalculated, forceRecalculate, this.state.portfolioOptions[portfolioIndex].value, (success) => {
                return success;
            })
            :
            this.props.getAnnualInvestmentSummary(contractId, scenarioId, isComparison, lastCalculated, forceRecalculate, this.state.portfolioOptions[this.state.selectedPortfolio].value, (success) => {
                return success;
            });
    }

    toSentenceCase(text) {
        var result = text.replace(/([A-Z]+)/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    calculateTotalPayments() {
        let annualPayments = 0;
        let upfrontPayments = 0;

        Object.values(this.props.payments).map((payment) => {
            if (payment.annuallyRecurring) {
                annualPayments += parseFloat(payment.amount);
            } else {
                upfrontPayments += parseFloat(payment.amount);
            }
        });

        return { annualPayments, upfrontPayments };
    }

    calculateTotalNovelties() {
        let annualNovelties = 0;
        let upfrontNovelties = 0;

        Object.values(this.props.novelties).map((novelty) => {
            if (novelty.annuallyRecurring) {
                annualNovelties += parseFloat(novelty.amount);
            } else {
                upfrontNovelties += parseFloat(novelty.amount);
            }
        });

        return { annualNovelties, upfrontNovelties };
    }

    calculateTotalRent() {
        let rent = 0;

        Object.values(this.props.nonFinancials).map((nonFinancial) => {
            if (nonFinancial.type === "Rent") {
                rent += parseFloat(nonFinancial.value) * nonFinancial.quantity;
            }
        });

        return rent;
    }

    calculateMaterialFinancials() {
        let totalDollarRebates = 0;
        let totalPercRebates = 0;
        let totalNumberOfRebates = 0;

        let totalDollarPa = 0;
        let totalPercPa = 0;
        let totalNumberOfPa = 0;

        Object.values(this.props.productGroups[this.scenarioId]).map((productGroup) => {
            if (productGroup.discounts) {
                if (productGroup.discounts.paPerCase) {
                    productGroup.discounts.paPerCase.type === "dollar" ?
                        totalDollarPa += productGroup.discounts.paPerCase.numericValue :
                        totalPercPa += productGroup.discounts.paPerCase.numericValue
                    totalNumberOfPa += 1;
                }

                if (productGroup.discounts.rebates) {
                    productGroup.discounts.rebates.type === "dollar" ?
                        totalDollarRebates += productGroup.discounts.rebates.numericValue :
                        totalPercRebates += productGroup.discounts.rebates.numericValue

                    totalNumberOfRebates += 1;
                }
            }
        });
        if (totalNumberOfRebates === 0) {
            totalNumberOfRebates = 1;
        }

        if (totalNumberOfPa === 0) {
            totalNumberOfPa = 1;
        }

        return {
            annualAvgDollarRebates: totalDollarRebates / this.props.scenarioDetails.contractDuration,
            annualAvgPercRebates: totalPercRebates / this.props.scenarioDetails.contractDuration,
            dollarRebates: totalDollarRebates / totalNumberOfRebates,
            percentRebates: totalPercRebates / totalNumberOfRebates,

            annualAvgDollarPa: totalDollarPa / this.props.scenarioDetails.contractDuration,
            annualAvgPercPa: totalPercPa / this.props.scenarioDetails.contractDuration,
            dollarPaPerCase: totalDollarPa / totalNumberOfPa,
            percentPaPerCase: totalPercPa / totalNumberOfPa
        };
    }

    getSelectScenarioOptions() {
        return this.state.scenarios.map(item => {
            return { label: this.getScenarioName(item), value: item }
        }).filter((item) => item.value != this.scenarioId);
    }

    generateAnnualInvestmentSummaryRowsMultiple(data) {
        let currentScenarioSummmary = this.state.annualInvestmentSummary;
        let dataPoints = [
            { label: "Unit Cases", identifier: "Unit Cases", dataType: "number" },
            { label: "Physical Cases", identifier: "Physical Cases", dataType: "number" },
            // { label: "Selling Litres", identifier: "Selling Litres", dataType: "number" },
            { label: "Discounts", identifier: "Discounts and Allowances", dataType: "dollar" },
            { label: "Net Sales Revenue", identifier: "Net Sales Revenue", dataType: "dollar" },
            { label: "NSR/UC", identifier: "NSR per UC", dataType: "dollar" },
            { label: "Net Contribution", identifier: "Net Contribution", dataType: "dollar" },
            { label: "NC/UC", identifier: "NC per UC", dataType: "dollar" },
            { label: "EBIT", identifier: "EBIT", dataType: "dollar" },
            { label: "Capital Investment", identifier: "Capital Expenditure", dataType: "dollar", negated: true },
            { label: "IRR", identifier: "Internal Rate of Return", dataType: "percent" },
            { label: "EBIT Margin", identifier: "EBIT Margin", dataType: "percent" },
            { label: "Discount % GSR", identifier: "Discount percent GSR", dataType: "percent" },
        ];

        
        let rows = dataPoints.map((dataPoint) => {
            let currentScenarioValue = currentScenarioSummmary ? currentScenarioSummmary[dataPoint.identifier] : "-";
            if (dataPoint.negated) {
                currentScenarioValue *= -1;
            }

            // Define base columns
            let columns = [
                { cellType: "text", data: dataPoint.label },
                { calculated: true, cellType: dataPoint.dataType, data: currentScenarioValue },
            ];
            if (this.state.selectedComparisonScenarios.length == 0) return { columns: columns };
            
            debugger;
           
            data.forEach((item) => {
                const scenarioName = this.getScenarioName(item.ScenarioId);
                let variance = "-";
                let comparisonScenarioValue = item[dataPoint.identifier] ? item[dataPoint.identifier] : "-";
                if (dataPoint.negated) { 
                    comparisonScenarioValue = typeof comparisonScenarioValue === "number" ? comparisonScenarioValue * -1 : comparisonScenarioValue;
                }

                if (typeof currentScenarioValue === "number" && typeof comparisonScenarioValue === "number") {
                    if(scenarioName === "Base Scenario"){
                        // if this is the base scenario, use that as the basis for comparing variance
                        variance = currentScenarioValue - comparisonScenarioValue;
                    } else {
                        variance = comparisonScenarioValue - currentScenarioValue;
                    }
                }

                columns.push({ calculated: true, cellType: dataPoint.dataType, data: comparisonScenarioValue });
                columns.push({ calculated: true, cellType: dataPoint.dataType, data: variance });
            });
            return { columns: columns };
        });
        return rows;
    }

    generateMaterialSummaryRows() {
        let rows = [];

        Object.values(this.props.productGroups[this.scenarioId]).map((productGroup) => {
            let conditions = [];
            let discounts = productGroup.discounts;

            let flatDiscount = 0;
            let flatDiscountPercentage = 1;
            let flatDiscountType = "percent";
            let flatDiscountConditionalised = false;

            let nsr = 0;

            /* Summation of rebates, PAs, payments */
            /* If any of rebates, PAs, payments are conditionalised then this will be set to True */
            let rebatePaPaymentPerCase = 0;
            let rebatePaPaymentPerCaseConditionalised = false;

            // Assuming there is only one material per product group.
            let material = Object.values(productGroup.materials)[0];
            nsr = material.NSRPerUnitCase * material.UnitsPerCase;
            if (discounts) {
                if (discounts.hasOwnProperty("flatDiscount")) {
                    flatDiscount = discounts.flatDiscount.numericValue;
                    flatDiscountType = discounts.flatDiscount.type;
                    flatDiscountConditionalised = discounts.flatDiscount.active ? true : false;

                    if (flatDiscountConditionalised) {
                        conditions.push(discounts.flatDiscount.conditions);
                    }

                    let newValue = 0;
                    if (flatDiscountType !== "percent") {
                        if (flatDiscountType === "dollar") {
                            // Dollar off
                            newValue = material.UnitPriceEX - flatDiscount;
                            flatDiscountPercentage = ((newValue - material.UnitPriceEX) / newValue) * -1;
                        } else {
                            // Fixed price
                            newValue = flatDiscount;
                            flatDiscountPercentage = (newValue / material.CasePriceEX) * -1;
                            flatDiscount = material.CasePriceEX - flatDiscount;
                        }
                    }
                }

                /* Add any rebates to rebatePaPaymentPerCase */
                if (discounts.rebates.hasOwnProperty("fixedValue") || discounts.rebates.hasOwnProperty("numericValue")) {
                    let rebateValue = parseFloat(discounts.rebates.numericValue);


                    if (rebateValue) {
                        if (discounts.rebates.type === "percent") {
                            // Convert to dollar...
                            rebateValue = material.UnitPriceEX * (rebateValue / 100);
                        }

                        rebatePaPaymentPerCase += rebateValue;
                    }

                    if (discounts.rebates.active) {
                        rebatePaPaymentPerCaseConditionalised = true;
                        conditions.push(discounts.rebates.conditions);
                    }
                }

                /* add any PAs to rebatePaPaymentPerCase */
                if (discounts.hasOwnProperty("paPerCase")) {
                    let paValue = parseFloat(discounts.paPerCase.numericValue);

                    if (paValue) {
                        if (discounts.paPerCase.type === "percent") {
                            paValue = material.UnitPriceEX * (paValue / 100);
                        }

                        rebatePaPaymentPerCase += paValue;
                    }

                    if (discounts.paPerCase.active) {
                        rebatePaPaymentPerCaseConditionalised = true;
                        conditions.push(discounts.paPerCase.conditions);
                    }
                }
            }

            if (productGroup.materials) {
                Object.values(productGroup.materials).map((material) => {
                    let totalDiscount = 0;
                    let discountPerCase = 0;

                    try {
                        totalDiscount = this.props.materialSummary[material.MG1].summary.TotalDiscount;
                        discountPerCase = this.props.materialSummary[material.MG1].summary["Sale Price per PC"] * (totalDiscount / 100)
                        rebatePaPaymentPerCase += this.props.materialSummary[material.MG1].summary.Payments;
                    } catch (error) { }

                    rebatePaPaymentPerCase = rebatePaPaymentPerCaseConditionalised ? `Up to $${rebatePaPaymentPerCase}` : `$${rebatePaPaymentPerCase}`;
                    rows.push({
                        columns: [
                            { cellType: 'text', data: material.ProductGroup == null || material.ProductGroup === "" ? material.BeverageCategory : material.ProductGroup },
                            { cellType: 'text', data: material.MG1FriendlyDesc == null ? material.MG1Desc : material.MG1FriendlyDesc },
                            { cellType: 'text', data: material.physicalCases },
                            { cellType: flatDiscountType === 'percent' ? 'percent' : 'dollar', conditionalised: flatDiscountConditionalised, data: flatDiscount },
                            { cellType: 'dollar', conditionalised: flatDiscountConditionalised, data: discountPerCase },
                            { cellType: 'dollar', calculated: true, conditionalised: rebatePaPaymentPerCaseConditionalised, data: rebatePaPaymentPerCase },
                            { cellType: 'percent', calculated: true, data: totalDiscount }
                        ]
                    });

                    if (flatDiscountConditionalised || rebatePaPaymentPerCaseConditionalised) {
                        rows.push({
                            rowType: "conditions",
                            data: conditions,
                        });
                    }
                });
            }
        });
        return rows;
    }

    generateIncentiveRows() {
        let rows = [];

        Object.values(this.props.novelties).map((novelty) => {
            let conditions = [];
            rows.push({
                columns: [
                    { cellType: 'text', data: novelty.name },
                    { cellType: 'text', data: "Novelty" },
                    { cellType: 'text', data: novelty.type },
                    { cellType: 'number', data: novelty.quantity },
                    { cellType: 'dollar', data: novelty.amount },
                    { cellType: 'dollar', data: novelty.quantity * novelty.amount },
                ]
            });

            if (novelty.conditions) {
                conditions.push(novelty.conditions);
                rows.push({
                    rowType: "conditions",
                    data: conditions,
                });
            };
        })

        Object.values(this.props.nonFinancials).map((financial) => {
            let conditions = [];
            rows.push({
                columns: [
                    { cellType: 'text', data: financial.type },
                    { cellType: 'text', data: "Non Financial" },
                    { cellType: 'text', data: financial.nonFinancialType },
                    { cellType: 'number', data: financial.quantity },
                    { cellType: 'dollar', data: financial.value },
                    { cellType: 'dollar', data: financial.quantity * financial.value },
                ]
            });

            if (financial.conditions) {
                conditions.push(financial.conditions);
                rows.push({
                    rowType: "conditions",
                    data: conditions,
                });
            };
        })

        Object.values(this.props.payments).map((payment) => {
            let conditions = [];
            rows.push({
                columns: [
                    { cellType: 'text', data: payment.type },
                    { cellType: 'text', data: "Payment" },
                    { cellType: 'text', data: payment.description },
                    { cellType: 'number', data: 1 },
                    { cellType: 'dollar', data: payment.amount },
                    { cellType: 'dollar', data: 1 * payment.amount },
                ]
            });

            if (payment.conditions) {
                conditions.push(payment.conditions);
                rows.push({
                    rowType: "conditions",
                    data: conditions,
                });
            };
        })
        return rows;
    }

    deleteDocument(location) {
        this.props.deleteDocument(location, this.contractId, this.scenarioId, (success) => {
            this.props.getDocumentList(this.contractId, this.scenarioId);
        });
    }

    generateDocumentList() {
        const list = this.props.documentList.map(item => {
            return (
                <li>
                    <a href={`${APPROVALS_ROOT}/GetDocumentByLocation?Location=${item}`}>
                        {item.split("/")[2]}
                    </a>
                    <Button style={{ outline: 'none', border: '0px' }} onClick={event => { this.deleteDocument(item) }}>
                        <FeatherIcon icon="x-circle" />
                    </Button>
                </li>)
        })
        return list;
    }

    submitToPnR() {
        this.props.sendContractToPnR(this.contractId, this.scenarioId, (success) => {
            success ? alert("Sent to PnR Team") : alert("Sending to PnR team failed");
        });
    }

    generateEquipmentRows() {
        let rows = [];

        Object.values(this.state.equipments).map((equipment) => {
            let conditions = [];
            let rental = null;
            if (equipment.rentalDetails) {
                rental = equipment.rentalDetails.stringValue
            }

            rows.push({
                columns: [
                    { cellType: 'text', data: equipment.equipmentSelection },
                    { cellType: 'text', data: equipment.equipmentType },
                    { cellType: 'number', data: equipment.quantity },
                    { cellType: 'dollar', data: equipment.capitalValue },
                    { cellType: 'dollar', data: equipment.capitalValue * equipment.quantity },
                ]
            });
            if (equipment.conditions || equipment.rentalDetails) {
                conditions.push(equipment.conditions);
                rows.push({
                    rowType: "conditions",
                    data: conditions,
                    rental: rental
                });
            };

        });
        return rows;
    }

    getSignOffLevel(approval) {
        return approval ? approval.approver : "...";
    }

    getScenarioName(scenarioId) {
        let scenarioName = "";

        this.props.contract.scenarios.forEach((scenario) => {
            if (scenarioId === scenario.scenarioId) {
                scenarioName = scenario.scenarioName;
            }
        });

        return scenarioName;
    }

    render() {
        let exclusivity = ["NARTD", "CSD", "water", "energy", "sports", "lifestyle", "juice", "coffee", "exceptions"];
        let keyBusinessDrivers = ["agreedRetails", "combos", "coreRange", "displays", "extras", "fridgeRelay", "hotspot", "impulseCooler", "shareOfSpace"];
        let alcKeyBusinessDrivers = ["SoftDrinks", "Mixers", "PremiumMixers", "GingerBeer", "Energy", "Water", "Juice", "Cordial", "Coffee", "NumberOfTaps", "Exclusive First Pour Spirit Categories", "Number of Packaged ARTD SKU’s Ranged", "MenuActivation", "PerfectServe", "MinimumActivation"]

        let payments;
        let novelties;
        let totalRent;
        let materialFinancials;
        let annualAvgDollarRebates;
        let annualAvgPercRebates;
        let annualAvgPercPa;
        let annualAvgDollarPa;
        let avgDollarRebates;
        let avgPercRebates;
        let avgDollarPa;
        let avgPercPa;

        if (this.props.productGroups[this.scenarioId]) {
            payments = this.calculateTotalPayments();
            novelties = this.calculateTotalNovelties();
            totalRent = this.calculateTotalRent();
            materialFinancials = this.calculateMaterialFinancials();

            annualAvgDollarRebates = materialFinancials.annualAvgDollarRebates;
            annualAvgPercRebates = materialFinancials.annualAvgPercRebates;
            annualAvgPercPa = materialFinancials.annualAvgPercPa;
            annualAvgDollarPa = materialFinancials.annualAvgDollarPa;

            avgDollarRebates = materialFinancials.dollarRebates;
            avgPercRebates = materialFinancials.percentRebates;
            avgDollarPa = materialFinancials.dollarPaPerCase;
            avgPercPa = materialFinancials.percentPaPerCase;
        }

        let outletNumber = "-";
        let contractName = "-";
        let subtradeChannel = "-";
        let fullAddress = "-";
        let salesOffice = "-";
        let accountStatus = "-";
        let contractStartDate = "-";
        let priceRise = "-";
        let preparedBy = "-";

        let custDetails = this.props.contractDetails.customer;

        // customer details
        if (this.props.contractDetails.customer) {
            outletNumber = this.props.contractDetails.customer["outletNumber"];
            contractName = this.props.contractDetails.customer["tradeName"];
            fullAddress = this.props.contractDetails.customer["fullAddress"];
            preparedBy = this.props.contractDetails.customer["preparedBy"];
        }

        if (this.props.contractDetails.general) {
            subtradeChannel = this.props.contractDetails.general["subtradeChannel"];
            salesOffice = this.props.contractDetails.general["salesOffice"];
            accountStatus = this.props.contractDetails.general["accountStatus"];
            contractStartDate = this.props.scenarioDetails["contractStartDate"];
            priceRise = this.props.contractDetails.general["priceRise"];
        }

        let annualInvestmentSummaryColumnHeaders = [];

        if (this.state.selectedComparisonScenarios.length == 0) {
            annualInvestmentSummaryColumnHeaders = ["", this.props.contract.scenarios ? this.getScenarioName(this.scenarioId) : "..."];
        } else {
            annualInvestmentSummaryColumnHeaders = ["", this.props.contract.scenarios ? this.getScenarioName(this.scenarioId) : "..."];
            this.props.annualInvestmentSummariesComparison.map((item) => {
                annualInvestmentSummaryColumnHeaders.push(this.getScenarioName(item.ScenarioId));
                annualInvestmentSummaryColumnHeaders.push("Variance (" + this.getScenarioName(item.ScenarioId) + ")");
            })
            // annualInvestmentSummaryColumnHeaders = ["", this.props.contract.scenarios ? this.getScenarioName(this.scenarioId) : "..."];
        }

        return (
            <PrintProvider>

                <ContextPage

                    firstElement={
                        <Button
                            className="menu-action-button"
                            color="link"
                            onClick={() => {
                                alert("Please use the system dialog to print: CTRL+P for Windows, CMD+P for MacOS");
                            }}
                        >
                            <FeatherIcon className="button-icon" icon="printer" /> Print
                        </Button>
                    }
                    secondElement={
                        this.props.user.role == "admin" ?
                            <Button
                                className="menu-action-button"
                                color="link"
                                onClick={() => {
                                    axios
                                        .get(`${API_ROOT}/contracts/${this.props.match.params.contractId}/${this.props.match.params.scenarioId}/calculate/BlobExport`)
                                        .then((response) => {
                                            if (response.status == 200) {
                                                alert("Uploaded Data to blob storage");
                                            }
                                        });
                                }}
                            >
                                <FeatherIcon className="button-icon" icon="archive" /> Blob Export
                            </Button>
                            : null
                    }
                    fetching={this.state.fetching}
                    fetched={this.state.fetched}
                    title={`Review ${this.state.portfolioOptions[this.state.selectedPortfolio].label} Portfolio${this.state.selectedPortfolio === "All" ? "s" : ""}`}
                >
                    <Print name="Details">

                        < div className="flexible-container" >
                            <FlexibleDataTable
                                simpleTable
                                header="Outlet Details"
                                headerColour="blue"
                                rows={[
                                    { label: "Customer/BO/TN No.", value: outletNumber },
                                    { label: "Customer/BO/TN Name", value: custDetails ? custDetails.tradingName : "" },
                                    { label: "Sub-Trade Channel", value: subtradeChannel },
                                    { label: "Customer Full Address", value: fullAddress },
                                    { label: "Sales Office", value: salesOffice },
                                    { label: "Status", value: accountStatus },
                                    { label: "Contract Start Date", value: contractStartDate },
                                    { label: "Contract End Date", value: moment(contractStartDate, "DD/MM/YYYY").add(this.props.scenarioDetails.contractDuration, "year").format("DD/MM/YYYY") },
                                    { label: "Price Rise", value: priceRise },
                                    { label: "Prepared By", value: preparedBy },
                                ]}
                            />

                            <FlexibleDataTable
                                simpleTable
                                header="Contract Details"
                                headerColour="blue"
                                rows={[
                                    { label: "Contract Term", value: this.props.scenarioDetails.contractDuration ? this.props.scenarioDetails.contractDuration : "-" },
                                    { label: "Cash Upfront", value: payments ? payments.upfrontPayments : "-", format: { type: "dollar", decimalPlaces: 2 } },
                                    { label: "Cash Annual", value: payments ? payments.annualPayments : "-", format: { type: "dollar", decimalPlaces: 2 } },
                                    { label: "Novelties Upfront", value: novelties ? novelties.upfrontNovelties : "-", format: { type: "dollar", decimalPlaces: 2 } },
                                    { label: "Novelties Annual", value: novelties ? novelties.annualNovelties : "-", format: { type: "dollar", decimalPlaces: 2 } },
                                    { label: "Rental $ P.A", value: totalRent ? totalRent : "-", format: { type: "dollar", decimalPlaces: 2 } },
                                    { label: "Average Rebate $ P.A", value: avgDollarRebates ? avgDollarRebates : "-", format: { type: "dollar", decimalPlaces: 2 } },
                                    { label: "Average Rebate % P.A", value: avgPercRebates ? avgPercRebates : "-", format: { type: "percent", decimalPlaces: 2 } },
                                    { label: "Average PA $ P.A", value: avgDollarPa ? avgDollarPa : "-", format: { type: "dollar", decimalPlaces: 2 } },
                                    { label: "Average PA % P.A", value: avgPercPa ? avgPercPa : "-", format: { type: "percent", decimalPlaces: 2 } },
                                ]}
                            />
                        </ div>
                    </Print>
                    <Print name="Incentives">
                        <div className="flexible-container">
                            <FlexibleDataTable
                                columnTable
                                header="Incentives"
                                headerColour="pink"
                                columnHeaders={["Incentive Name", "Incentive Type", "Sub Type", "Quantity", "Value", "Total Value"]}
                                rows={this.generateIncentiveRows()}
                            />
                        </div>
                    </Print>
                    <div className="page-break" />
                    <Print name="Equipment">
                        <div className="flexible-container">
                            <FlexibleDataTable
                                columnTable
                                header="Equipment"
                                headerColour="pink"
                                columnHeaders={["Equipment Name", "Equipment Type", "Quantity", "Capital Value", "Total Value"]}
                                rows={this.generateEquipmentRows()}
                            />
                        </div>
                    </Print>
                    <Print name="Materials">
                        <div className="flexible-container">
                            <FlexibleDataTable
                                columnTable
                                header="Materials"
                                headerColour="green"
                                valuesLoading={this.props.fetchingMaterialSummary}
                                columnHeaders={["Product Group", "Product Description", "Physical Cases", "Discount on Invoice", "Total Discount Per Case", "Rebate/PA/Payments per Case", "Total Average Discount"]}
                                rows={this.props.productGroups[this.scenarioId] ? this.generateMaterialSummaryRows() : []}
                            />
                        </div>
                    </Print>
                    <NoPrint>
                        <Select
                            label="Select what portfolio you're wanting to view"
                            defaultValue={this.state.portfolioOptions[this.state.selectedPortfolio]}
                            placeholder="Select aaa"
                            options={this.state.portfolioOptions}
                            onChange={(e) => {
                                var index = this.state.portfolioOptions.findIndex((item) => item.label == e.label);
                                this.setState({ selectedPortfolio: index });
                                this.calculateAnnualInvestmentSummaries(index);
                            }}
                        >
                            Select what portfolio you're wanting to view
                        </Select>
                    </NoPrint>
                    <Print name="Summary">
                        <div className="flexible-container">
                            <FlexibleDataTable
                                columnTable
                                header="Annual Investment Summary"
                                headerColour="orange"
                                columnHeaders={annualInvestmentSummaryColumnHeaders}
                                headerElement={
                                    <Select
                                        label="Comparison Scenarios"
                                        placeholder="Select Scenarios For Comparison"
                                        options={this.getSelectScenarioOptions()}
                                        isMulti
                                        onChange={(event) => {
                                            this.setState({ selectedComparisonScenarios: event });
                                        }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        styles={customStyles}
                                    />}
                                valuesLoading={this.props.fetchingAnnualInvestmentSummary || this.props.fetchingAnnualInvestmentSummaryComparison}
                                rows={this.generateAnnualInvestmentSummaryRowsMultiple(this.props.annualInvestmentSummariesComparison)}
                            />
                        </div>
                    </Print>
                    <div className="page-break" />
                    <Print name="Exclusivity">
                        <div className="flexible-container">
                            <FlexibleDataTable
                                simpleTable
                                header="Exclusivity"
                                headerColour="red"
                                rows={exclusivity.map((value) => {
                                    let isExclusive = false;

                                    if (this.props.scenarioDetails) {
                                        if (this.props.scenarioDetails.exclusivity) {
                                            if (this.props.scenarioDetails.exclusivity.hasOwnProperty(value)) {
                                                isExclusive = this.props.scenarioDetails.exclusivity[value];
                                            }
                                        }
                                    }
                                    return { label: value, value: isExclusive ? "Yes" : "-" };
                                })}
                            />
                            {this.state.portfolioOptions[this.state.selectedPortfolio].value !== "Alcohol" &&
                                <FlexibleDataTable
                                    simpleTable
                                    header="Key Business Drivers"
                                    headerColour="red"
                                    rows={keyBusinessDrivers.map((driver) => {
                                        let value = "...";
                                        value = this.props.contractDetails.keyBusinessDrivers ? this.props.contractDetails.keyBusinessDrivers[driver] : "...";
                                        if (value === true) value = "Yes";
                                        else if (isNumber(value)) value = `${value}%`;
                                        return { label: this.toSentenceCase(driver), value: value ? value : "-" };
                                    })}
                                />
                            }
                            <FlexibleDataTable
                                simpleTable
                                header="Key Business Drivers (ALC)"
                                headerColour="red"
                                rows={alcKeyBusinessDrivers.map((driver) => {
                                    let value = "...";
                                    value = this.props.contractDetails.keyBusinessDrivers ? this.props.contractDetails.keyBusinessDrivers[driver] : "...";
                                    if (Array.isArray(value)) value = value.map(item => item.value).join(", ");
                                    if (value === true) { value = "Yes"; }
                                    return { label: this.toSentenceCase(driver), value: value ? value : "-" };
                                })}
                            />
                        </div>
                    </Print>
                    <Print name="MoreInfo">
                        <div className="flexible-container">
                            <div className="flexible-item">
                                <div className="flexible-header purple">Further Information</div>
                                <textarea
                                    placeholder="Any additional information..."
                                    className="further-information-field"
                                    rows="4"
                                    cols="50"
                                    defaultValue={this.props.additionalComments}
                                    onBlur={(event) => { this.props.postAdditionalComments(this.contractId, this.props.match.params.scenarioId, event.target.value) }}
                                />
                            </div>
                        </div>
                    </Print>

                    <Print name="Signoff">
                        <div className="flexible-container">
                            <div className="flexible-item">
                                <div className="flexible-header purple">Sign Off</div>
                                <div className="flexible-row">
                                    <div className="flexible-element left"> Required by </div>
                                    <div className="flexible-element right"> {this.getSignOffLevel(this.state.approval)} </div>
                                </div>
                                <div className="flexible-row signoff">
                                    <div className="signoff label"> Name </div>
                                    <div className="signoff box"></div>
                                </div>
                                <div className="flexible-row signoff">
                                    <div className="signoff label"> Signature </div>
                                    <div className="signoff box"></div>
                                </div>
                            </div>
                        </div>
                    </Print>
                </ContextPage >
            </PrintProvider >
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { contract, fetched } = state.contractReducer;
    const { scenarioDetails, contractDetails, comparisonScenario } = state.contractDetailsReducer;
    const { productGroups } = state.productGroupReducer;
    const { equipments } = state.equipmentReducer;
    const { nonFinancials } = state.nonFinancialReducer;
    const { user } = state.userReducer;
    const { payments, novelties } = state.paymentReducer;
    const { additionalComments, annualInvestmentSummariesComparison, annualInvestmentSummary, annualInvestmentSummaryTimestamp, annualInvestmentSummaryComparison, fetchingAnnualInvestmentSummary, materialSummary, fetchingMaterialSummary, fetchingAnnualInvestmentSummaryComparison, approval, fetchingApproval } = state.summaryReducer;
    const { documentList } = state.documentReducer;
    return {
        user,
        contract,
        fetched,
        contractDetails,
        scenarioDetails,
        comparisonScenario,
        equipments,
        productGroups,
        payments,
        novelties,
        nonFinancials,
        annualInvestmentSummaryTimestamp,
        annualInvestmentSummary,
        annualInvestmentSummaryComparison,
        fetchingAnnualInvestmentSummary,
        fetchingAnnualInvestmentSummaryComparison,
        annualInvestmentSummariesComparison,
        materialSummary,
        fetchingMaterialSummary,
        approval,
        fetchingApproval,
        additionalComments,
        documentList
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getScenarioDetails,
            getEquipments,
            getProductGroups,
            getPayments,
            getNovelties,
            getNonFinancials,
            getMaterialGroupDataPoint,
            getAnnualInvestmentSummary,
            getContract,
            getContractDetails,
            setCurrentComparisonScenario,
            getMaterialSummary,
            getApproval,
            getAdditionalComments,
            postAdditionalComments,
            getAnnualInvestmentSummaries,
            getDocumentList,
            deleteDocument,
            sendContractToPnR
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractReviewPage));

// EXPORT COMPONENT
export { hoc as ContractReviewPage };
