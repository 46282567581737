// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import * as Yup from "yup";
import FeatherIcon from "feather-icons-react";

// ACTIONS
import { addPayment } from "../../../state/actions/PaymentActions";
import { getContractSummarySnapshot } from "../../../state/actions/SummaryActions";

// UI
import { Col, Row, Button, FormGroup, Label } from "reactstrap";
import { FormCheckbox } from "../../../containers/inputs/FormCheckbox";
import { ValidatedInput } from "../../../containers/validation/ValidatedInput";
import { Message } from "../../../containers/layout/Message";
import { Loading } from "../../../containers/loading/Loading";

const validationSchema = Yup.object().shape({
    type: Yup.string().required("Payment type required"),
    amount: Yup.number().moreThan(0, "Provide a payment amount greater than 0").required("Payment amount required"),
});

// COMPONENT
class NewPaymentModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            messageError: false,
            messageActive: false,
        };
    }

    render() {
        return (
            <div className="modalised-content">
                <Message active={this.state.messageActive} error={this.state.messageError} message="Error adding new payment" />
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        type: "",
                        amount: 0,
                        annuallyRecurring: false,
                        description: "",
                        isNovelty: false
                    }}
                    onSubmit={(values) => {
                        this.setState({ saving: true });
                        this.props.addPayment(this.props.match.params.contractId, this.props.match.params.scenarioId, values, (success, newPaymentId) => {
                            this.setState({ saving: false });
                            if (success) {
                                this.props.onClose();
                                this.props.getContractSummarySnapshot(this.props.match.params.contractId, this.props.match.params.scenarioId);
                                console.log("Pushing to ", `/contracts/${this.props.match.params.contractId}/${this.props.match.params.scenarioId}/financial-incentives/${newPaymentId}?section=payments`);
                                this.props.history.push(`/contracts/${this.props.match.params.contractId}/${this.props.match.params.scenarioId}/financial-incentives/${newPaymentId}?section=payments`);
                            } else {
                                this.setState({ messageActive: true, messageError: true });
                                setTimeout(() => {
                                    this.setState({ messageActive: false, messageError: false });
                                }, 2000);
                            }
                        });
                    }}
                    render={({ values, handleSubmit, handleChange, handleBlur, setFieldValue, errors, touched }) => (
                        <Fragment>
                            <Row form>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="type">Type</Label>
                                        <ValidatedInput touched={touched.type} error={errors.type} field="type" setFieldValue={setFieldValue} name="type" value={values.type} type="text" id="type" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={8}>
                                    <FormGroup>
                                        <Label for="amount">Amount</Label>
                                        <ValidatedInput touched={touched.amount} error={errors.amount} field="amount" setFieldValue={setFieldValue} name="amount" value={values.amount} type="number" id="amount" inputFormat="dollar" />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label style={{ paddingBottom: "0.35em" }} for="annuallyRecurring">
                                            Annually Recurring
                                        </Label>
                                        <FormCheckbox
                                            checked={values.annuallyRecurring}
                                            value={values.annuallyRecurring}
                                            name="annuallyRecurring"
                                            setFieldValue={setFieldValue}
                                            onChange={(name, event) => {
                                                setFieldValue(name, event);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label for="description">Payment Notes</Label>
                                <ValidatedInput touched={touched.description} error={errors.description} field="description" setFieldValue={setFieldValue} name="description" value={values.description} type="text" id="description" />
                            </FormGroup>
                            <Button onClick={handleSubmit} color="secondary" size="lg" block>
                                {!this.state.saving && <div> Add Payment </div>}
                                {this.state.saving && <Loading small light />}
                            </Button>
                        </Fragment>
                    )}
                />
            </div>
        );
    }
}

NewPaymentModal.propTypes = {
    adding: PropTypes.bool.isRequired,
    added: PropTypes.bool.isRequired,
    addFailed: PropTypes.bool.isRequired,
    addPayment: PropTypes.func.isRequired,
};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { adding, added, addFailed } = state.paymentReducer;
    return { adding, added, addFailed };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ addPayment, getContractSummarySnapshot }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(NewPaymentModal));

// EXPORT COMPONENT

export { hoc as NewPaymentModal };
