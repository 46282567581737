import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const RejectionModal = (props) => {
    const [rejectionReason, setRejectionReason] = useState("");

    return (
        <div>
            <Modal toggle={props.toggle} isOpen={props.isOpen}>
                <ModalHeader toggle={props.toggle}>Rejection Reason</ModalHeader>
                <ModalBody>
                    Please enter your rejection reason:
                    <textarea
                        placeholder="Rejection Reason"
                        className="further-information-field"
                        rows="4"
                        cols="50"
                        defaultValue={rejectionReason}
                        onBlur={(event) => { setRejectionReason(event.target.value) }}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={(event) => { props.doRejection(rejectionReason) }}>Reject</Button>{' '}
                    <Button color="secondary" onClick={props.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default RejectionModal;