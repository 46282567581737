// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import * as Yup from "yup";
import FeatherIcon from "feather-icons-react";

import { addPayment, getAllNovelties } from "../../../state/actions/PaymentActions";
import { getContractSummarySnapshot } from "../../../state/actions/SummaryActions";

// UI
import { Col, Row, Button, FormGroup, Label, Input } from "reactstrap";
import { FormCheckbox } from "../../../containers/inputs/FormCheckbox";
import { ValidatedInput } from "../../../containers/validation/ValidatedInput";
import { Message } from "../../../containers/layout/Message";
import { Loading } from "../../../containers/loading/Loading";
import { CCAError } from "../../../enums/CCAError";
import { CCAInput } from "../../../containers/inputs/CCAInput";
import { CCAInputTypes } from "../../../enums/CCAInputTypes";

const validationSchema = Yup.object().shape({
    type: Yup.string().required("Payment type required"),
    amount: Yup.number().moreThan(0, "Provide a payment amount greater than 0").required("Payment amount required"),
});

// COMPONENT
class NewNoveltyModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            messageError: false,
            messageActive: false
        };
    }

    componentDidMount() {
        this.props.getAllNovelties(() => { });
    }

    render() {
        return (
            <div className="modalised-content">
                <Message active={this.state.messageActive} error={this.state.messageError} message="Error adding new payment" />
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        type: "",
                        amount: 0,
                        annuallyRecurring: true,
                        description: "",
                        quantity: 0,
                        isNovelty: true
                    }}
                    onSubmit={(values) => {
                        this.setState({ saving: true });
                        this.props.addPayment(this.props.match.params.contractId, this.props.match.params.scenarioId, values, (success, newPaymentId) => {
                            this.setState({ saving: false });
                            if (success) {
                                this.props.onClose();
                                this.props.getContractSummarySnapshot(this.props.match.params.contractId, this.props.match.params.scenarioId);
                                console.log("Pushing to ", `/contracts/${this.props.match.params.contractId}/${this.props.match.params.scenarioId}/financial-incentives/${newPaymentId}?section=novelties`);
                                this.props.history.push(`/contracts/${this.props.match.params.contractId}/${this.props.match.params.scenarioId}/financial-incentives/${newPaymentId}?section=novelties`);
                            } else {
                                this.setState({ messageActive: true, messageError: true });
                                setTimeout(() => {
                                    this.setState({ messageActive: false, messageError: false });
                                }, 2000);
                            }
                        });
                    }}
                    render={({ values, handleSubmit, handleChange, handleBlur, setFieldValue, errors, touched }) => (
                        <Fragment>
                            <Row form>
                                <Col md={12}>
                                    <FormGroup>
                                        <Input
                                            onChange={(event) => {
                                                let equipmentId = event.target.value;
                                                let novelty = this.props.noveltyList[equipmentId];
                                                setFieldValue("name", novelty.Name);
                                                setFieldValue("type", novelty.Type);
                                                setFieldValue("amount", novelty.Value.replace("$", "").replace(",", ""));
                                                setFieldValue("quantity", 1);
                                            }}
                                            type="select"
                                            name="select"
                                            id="chooseExisting"
                                        >
                                            {this.props.noveltyList !== undefined && (
                                                <Fragment>
                                                    <option disabled selected value="None">
                                                        {" "}-- Select Novelty --{" "}
                                                    </option>

                                                    {Object.keys(this.props.noveltyList).map((noveltyKey, index) => {
                                                        let novelty = this.props.noveltyList[noveltyKey];
                                                        return (
                                                            <option key={index} value={noveltyKey}>
                                                                {" "}{novelty.Name}{" "}
                                                            </option>
                                                        );
                                                    })}
                                                </Fragment>
                                            )}
                                        </Input>

                                        <div className="validation-error">{touched.equipmentSelection && <div>{errors.equipmentSelection}</div>}</div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="amount">Amount</Label>
                                        <ValidatedInput touched={touched.amount} error={errors.amount} field="amount" setFieldValue={setFieldValue} name="amount" value={values.amount} type="number" id="amount" inputFormat="dollar" />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label style={{ paddingBottom: "0.35em" }} for="annuallyRecurring">
                                            Annually Recurring
                                        </Label>
                                        <br></br>
                                        <FormCheckbox
                                            checked={values.annuallyRecurring}
                                            value={values.annuallyRecurring}
                                            name="annuallyRecurring"
                                            setFieldValue={setFieldValue}
                                            onChange={(name, event) => {
                                                setFieldValue(name, event);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label style={{ paddingBottom: "0.35em" }} for="quantity">
                                            Quantity
                                        </Label>
                                        <CCAInput
                                            type={CCAInputTypes.NUMBER}
                                            name="quantity"
                                            value={values.quantity}
                                            onChange={(value) => {
                                                setFieldValue("quantity", value);
                                            }}
                                            onSave={() => { }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label for="description">Novelty Notes</Label>
                                <ValidatedInput touched={touched.description} error={errors.description} field="description" setFieldValue={setFieldValue} name="description" value={values.description} type="text" id="description" />
                            </FormGroup>
                            <Button onClick={handleSubmit} color="secondary" size="lg" block>
                                {!this.state.saving && <div> Add Novelty </div>}
                                {this.state.saving && <Loading small light />}
                            </Button>
                        </Fragment>
                    )}
                />
            </div>
        );
    }
}

NewNoveltyModal.propTypes = {
    adding: PropTypes.bool.isRequired,
    added: PropTypes.bool.isRequired,
    addFailed: PropTypes.bool.isRequired,
    addPayment: PropTypes.func.isRequired,
};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { adding, added, addFailed, noveltyList } = state.paymentReducer;
    return { adding, added, addFailed, noveltyList };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ addPayment, getContractSummarySnapshot, getAllNovelties }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(NewNoveltyModal));

// EXPORT COMPONENT

export { hoc as NewNoveltyModal };
