// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// IMPORT PROJECT REFERENCES
import { getTemplates, deleteTemplates } from "../../state/actions/TemplateActions";
import { ResponsiveList } from "./lists/ResponsiveList";
import { Loading } from "../../containers/loading/Loading";
import Modalised from "../../containers/layout/Modalised";
import { NewContractModal } from ".//NewContractModal";

// UI
import { Button } from "reactstrap";
import { CELL_TYPES } from "./CellTypes";
import { ContextPage } from "../../containers/layout/ContextPage";
import { Message } from "../../containers/layout/Message";
import { Card } from "../../containers/card/Card";

// COMPONENT
class TemplateDetailsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            newContractModalShowing: false,
            editingShowing: false,
            message: "",
            messageActive: false,
        };

        this.newCustomerPage = this.newCustomerPage.bind(this);
        this.handleNewContractClicked = this.handleNewContractClicked.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.toggleEditing = this.toggleEditing.bind(this);

        // Responsive List
        this.responsiveList = null;
    }

    componentDidMount() {
        this.props.getTemplates();
    }

    newCustomerPage() {
        this.props.history.push("/contracts/new");
    }

    handleNewContractClicked() {
        this.setState({ newContractModalShowing: true });
    }

    hideModal() {
        this.setState({ newContractModalShowing: false });
    }

    handleDelete(selectedTemplates) {
        if (confirm("Are you sure you want to delete?")) {
            let templatesToDelete = [];

            selectedTemplates.forEach((item) => {
                templatesToDelete.push(item.id);
            });

            this.props.deleteTemplates(templatesToDelete, (success) => {
                if (success) {
                    this.setState({ editingShowing: false });
                } else {
                    this.setState({ messageActive: true });
                    setTimeout(() => {
                        this.setState({ messageActive: false });
                    }, 2000);
                }
            });
        }
    }

    toggleEditing() {
        this.setState({ editingShowing: !this.state.editingShowing });
    }

    render() {
        let sections = [
            {
                items: Object.values(this.props.templates),
                emptyLabel: "No Templates",
                sectionLabel: "",
                sectionIdentifier: "templates",
                identifier: "contractId",
                cell: {
                    type: CELL_TYPES.TEMPLATE,
                    dataIdentifiers: { label: "contractName", subtitle: "contractId", createdAt: "createdAt", associatedContractName: "associatedContractName" },
                },
            },
        ];

        return (
            <Fragment>
                <Message error message="Error deleting contracts" active={this.state.messageActive} />
                <Fragment>
                    <Modalised
                        floating
                        title={"Create a contract"}
                        action={
                            <div
                                onClick={() => {
                                    this.hideModal();
                                }}
                                className="text-link"
                            >
                                Close
                            </div>
                        }
                        handleClose={() => {
                            this.hideModal();
                        }}
                        showing={this.state.newContractModalShowing}
                    >
                        <NewContractModal onClose={() => this.hideModal()} />
                    </Modalised>
                    <ContextPage
                        padded={false}
                        firstElement={
                            <div>
                                <Button className="padded-right" onClick={this.toggleEditing}>
                                    {this.state.editingShowing ? "Done" : "Edit"}
                                </Button>
                            </div>
                        }
                        fetching={this.props.fetchingTemplates}
                        fetched={this.props.fetchedTemplates}
                        title={"My Templates"}
                    >
                        {this.props.fetchedTemplates && (
                            <Card>
                                <ResponsiveList
                                    hideSectionHeader
                                    onRef={(ref) => {
                                        this.responsiveList = ref;
                                    }}
                                    showPlaceholder
                                    placeholderTitle={"No templates"}
                                    disableAutoRoute
                                    editingShowing={this.state.editingShowing}
                                    handleDelete={this.handleDelete}
                                    handleClick={(templateId) => this.props.history.push("/contracts/" + templateId + "/details")}
                                    actions={["Delete"]}
                                    sections={sections}
                                />
                            </Card>
                        )}

                        {this.props.fetching && <Loading />}
                        {this.props.failed && <div> {"Couldn't fetch contracts."} </div>}
                    </ContextPage>
                </Fragment>
            </Fragment>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { templates, fetchingTemplates, fetchedTemplates, failedTemplates } = state.templateReducer;
    return { templates, fetchingTemplates, fetchedTemplates, failedTemplates };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getTemplates, deleteTemplates }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(TemplateDetailsPage));

// EXPORT COMPONENT
export { hoc as TemplateDetailsPage };
