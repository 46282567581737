// IMPORT PACKAGE REFERENCES
import React from "react";
import ReactDOM from "react-dom";

// COMPONENT
export class FloatingButton extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div onClick={this.props.action} className={"floating-button" + ` ${this.props.classes}`}>
                {this.props.label}
            </div>
        );
    }
}
