import axios from "axios";
import { API_ROOT } from "../../../config/config";

/* CREATE PRODUCT GROUP */
export const CREATE_PRODUCT_GROUP_PENDING = "CREATE_PRODUCT_GROUP_PENDING";
export const CREATE_PRODUCT_GROUP_FULFILLED = "CREATE_PRODUCT_GROUP_FULFILLED";
export const CREATE_PRODUCT_GROUP_REJECTED = "CREATE_PRODUCT_GROUP_REJECTED";

/* GET PRODUCT GROUPS */
export const GET_PRODUCT_GROUPS_PENDING = "GET_PRODUCT_GROUPS_PENDING";
export const GET_PRODUCT_GROUPS_FULFILLED = "GET_PRODUCT_GROUPS_FULFILLED";
export const GET_PRODUCT_GROUPS_REJECTED = "GET_PRODUCT_GROUPS_REJECTED";

/* GET PRODUCT GROUP */
export const GET_PRODUCT_GROUP_PENDING = "GET_PRODUCT_GROUP_PENDING";
export const GET_PRODUCT_GROUP_FULFILLED = "GET_PRODUCT_GROUP_FULFILLED";
export const GET_PRODUCT_GROUP_REJECTED = "GET_PRODUCT_GROUP_REJECTED";

/* DELETE PRODUCT GROUPS */
export const DELETE_PRODUCT_GROUPS_PENDING = "DELETE_PRODUCT_GROUPS_PENDING";
export const DELETE_PRODUCT_GROUPS_FULFILLED = "DELETE_PRODUCT_GROUPS_FULFILLED";
export const DELETE_PRODUCT_GROUPS_REJECTED = "DELETE_PRODUCT_GROUPS_REJECTED";

/* SAVE PRODUCT GROUP */
export const SAVE_PRODUCT_GROUP_PENDING = "SAVE_PRODUCT_GROUP_PENDING";
export const SAVE_PRODUCT_GROUP_FULFILLED = "SAVE_PRODUCT_GROUP_FULFILLED";
export const SAVE_PRODUCT_GROUP_REJECTED = "SAVE_PRODUCT_GROUP_REJECTED";

/* SAVE PRODUCT GROUP CONDIITONS */
export const SAVE_PRODUCT_GROUP_CONDITIONS_PENDING = "SAVE_PRODUCT_GROUP_CONDITIONS_PENDING";
export const SAVE_PRODUCT_GROUP_CONDITIONS_FULFILLED = "SAVE_PRODUCT_GROUP_CONDITIONS_FULFILLED";
export const SAVE_PRODUCT_GROUP_CONDITIONS_REJECTED = "SAVE_PRODUCT_GROUP_CONDITIONS_REJECTED";

/* SAVE PRODUCT GROUP COMPLIANCE */
export const SAVE_PRODUCT_GROUP_COMPLIANCE_PENDING = "SAVE_PRODUCT_GROUP_COMPLIANCE_PENDING";
export const SAVE_PRODUCT_GROUP_COMPLIANCE_FULFILLED = "SAVE_PRODUCT_GROUP_COMPLIANCE_FULFILLED";
export const SAVE_PRODUCT_GROUP_COMPLIANCE_REJECTED = "SAVE_PRODUCT_GROUP_COMPLIANCE_REJECTED";

/* DELETE PRODUCT GROUP MATERIALS */
export const DELETE_PRODUCT_GROUP_MATERIALS_PENDING = "DELETE_PRODUCT_GROUP_MATERIALS_PENDING";
export const DELETE_PRODUCT_GROUP_MATERIALS_FULFILLED = "DELETE_PRODUCT_GROUP_MATERIALS_FULFILLED";
export const DELETE_PRODUCT_GROUP_MATERIALS_REJECTED = "DELETE_PRODUCT_GROUP_MATERIALS_REJECTED";

/* ADD PRODUCT GROUP MATERIALS */
export const ADD_PRODUCT_GROUP_MATERIALS_PENDING = "ADD_PRODUCT_GROUP_MATERIALS_PENDING";
export const ADD_PRODUCT_GROUP_MATERIALS_FULFILLED = "ADD_PRODUCT_GROUP_MATERIALS_FULFILLED";
export const ADD_PRODUCT_GROUP_MATERIALS_REJECTED = "ADD_PRODUCT_GROUP_MATERIALS_REJECTED";

/* UPDATE PRODUCT GROUP MATERIALS */
export const UPDATE_PRODUCT_GROUP_MATERIALS_PENDING = "UPDATE_PRODUCT_GROUP_MATERIALS_PENDING";
export const UPDATE_PRODUCT_GROUP_MATERIALS_FULFILLED = "UPDATE_PRODUCT_GROUP_MATERIALS_FULFILLED";
export const UPDATE_PRODUCT_GROUP_MATERIALS_REJECTED = "UPDATE_PRODUCT_GROUP_MATERIALS_REJECTED";

/* REASSIGN PRODUCT GROUP MATERIALS */
export const REASSIGN_PRODUCT_GROUP_MATERIALS_PENDING = "REASSIGN_PRODUCT_GROUP_MATERIALS_PENDING";
export const REASSIGN_PRODUCT_GROUP_MATERIALS_FULFILLED = "REASSIGN_PRODUCT_GROUP_MATERIALS_FULFILLED";
export const REASSIGN_PRODUCT_GROUP_MATERIALS_REJECTED = "REASSIGN_PRODUCT_GROUP_MATERIALS_REJECTED";

/* SAVE PRODUCT GROUP CUSTOM CONDIITONS */
export const SAVE_PRODUCT_GROUP_CUSTOM_CONDITIONS_PENDING = "SAVE_PRODUCT_GROUP_CUSTOM_CONDITIONS_PENDING";
export const SAVE_PRODUCT_GROUP_CUSTOM_CONDITIONS_FULFILLED = "SAVE_PRODUCT_GROUP_CUSTOM_CONDITIONS_FULFILLED";
export const SAVE_PRODUCT_GROUP_CUSTOM_CONDITIONS_REJECTED = "SAVE_PRODUCT_GROUP_CUSTOM_CONDITIONS_REJECTED";

/* GET PRODUCT GROUP SUMMARY */
export const GET_PRODUCT_GROUPS_SUMMARY_PENDING = "GET_PRODUCT_GROUPS_SUMMARY_PENDING";
export const GET_PRODUCT_GROUPS_SUMMARY_FULFILLED = "GET_PRODUCT_GROUPS_SUMMARY_FULFILLED";
export const GET_PRODUCT_GROUPS_SUMMARY_REJECTED = "GET_PRODUCT_GROUPS_SUMMARY_REJECTED";

/* SAVE MATERIAL GROUP ALLOCATION */
export const SAVE_MATERIAL_GROUP_ALLOCATION_PENDING = "SAVE_MATERIAL_GROUP_ALLOCATION_PENDING";
export const SAVE_MATERIAL_GROUP_ALLOCATION_FULFILLED = "SAVE_MATERIAL_GROUP_ALLOCATION_FULFILLED";
export const SAVE_MATERIAL_GROUP_ALLOCATION_REJECTED = "SAVE_MATERIAL_GROUP_ALLOCATION_REJECTED";

/* SAVE SCENARIO MATERIALS */
export const UPDATE_SCENARIO_MATERIALS_PENDING = "UPDATE_SCENARIO_MATERIALS_PENDING";
export const UPDATE_SCENARIO_MATERIALS_FULFILLED = "UPDATE_SCENARIO_MATERIALS_FULFILLED";
export const UPDATE_SCENARIO_MATERIALS_REJECTED = "UPDATE_SCENARIO_MATERIALS_REJECTED";

export const SET_MATERIAL_ORDERING = "SET_MATERIAL_ORDERING";
export const RESET_MATERIAL_ORDERING = "RESET_MATERIAL_ORDERING";

/*
    Create a product group.
*/
const createProductGroup = (contractId, data, callback) => (dispatch) => {
    dispatch({
        type: CREATE_PRODUCT_GROUP_PENDING,
    });

    /*
        We want to get all the materials, plus all the materials that are currently assigned to the contract, and the suggested materials for this customer.
    */
    axios
        .post(API_ROOT + "/contracts/" + contractId + "/productGroups", data)
        .then((response) => {
            /* Fulfil the request to create the product group */
            dispatch({
                type: CREATE_PRODUCT_GROUP_FULFILLED,
                payload: data,
            });

            callback(true, response.data);
        })
        .catch((error) => {
            console.log("An error occurred creating the product group.", error);

            /* Reject the request to create a product group, since the API failed. */
            dispatch({
                type: CREATE_PRODUCT_GROUP_REJECTED,
            });

            callback(false);
        });
};

/*
    Get product groups.
*/
const getProductGroups = (contractId, scenarioId, callback) => (dispatch) => {
    dispatch({
        type: GET_PRODUCT_GROUPS_PENDING,
    });

    /*
        We want to get all the product groups.
    */
    axios
        .get(API_ROOT + "/contracts/" + contractId + "/" + scenarioId + "/productGroups")
        .then((res) => {
            const productGroups = res.data;
            /*
            Hijacking the request to add the *Proper* NSR calculation into the returned request body
            */
            // This is a Hardcoded bit of JSON for the backend to know what it's grabbing for us
            let derivedDataPoints = [
                {
                    "dataPoints": [
                        {
                            "dataIdentifier": "NSRPerUnitCase",
                        },
                        {
                            "dataIdentifier": "avg12MOVol"
                        }
                    ]
                }
            ]
            // For every product group in the returned body, create a new 'row' to send back to the backend again

            let rows = []
            for (var key in productGroups) {
                var MG1 = Object.values(productGroups[key].materials)[0].MG1;
                var data = { "dataPoints": { "MG1": MG1 }, "productGroupId": key }
                rows.push(data);
            }

            // Send the hijacked request
            axios.post(API_ROOT + `/contracts/${contractId}/${scenarioId}/calculate/dataPoints`, { data: rows, derivedDataPoints })
                .then(response => {
                    const nsrData = response.data;
                    // Append the data onto the original request body
                    for (var key in productGroups) {
                        var data = Object.values(productGroups[key].materials)[0]
                        var MG1 = data.MG1;
                        // data = Object.assign(data, nsrData[MG1]);
                        data.NSRPerUnitCase = nsrData[MG1].NSRPerUnitCase;
                        data.avg12MOVol = nsrData[MG1].avg12MOVol;
                    }

                    // Send off the response
                    dispatch({
                        type: GET_PRODUCT_GROUPS_FULFILLED,
                        payload: { ...productGroups, scenarioId }
                    });

                    callback(true);
                })
                .catch(error => {
                    console.log('An error has occured while intercepting product groups.', error);
                    dispatch({
                        type: GET_PRODUCT_GROUPS_REJECTED
                    });

                    callback(false);
                });

        })
        .catch(error => {
            console.log('An error occurred requesting product groups.', error);

            dispatch({
                type: GET_PRODUCT_GROUPS_REJECTED,
            });

            callback(false);
        });
};

/*
    Get product group.
*/
const getProductGroup = (contractId, productGroupId, callback) => (dispatch) => {
    dispatch({
        type: GET_PRODUCT_GROUP_PENDING,
    });

    /*
        We want to get a singular product group.
    */
    axios
        .get(API_ROOT + "/contracts/" + contractId + "/productGroups/" + productGroupId)
        .then((res) => {
            const productGroup = res.data;

            /* Fulfil the request for materials, since the API succceded, pass the product group data to the reducer. */
            dispatch({
                type: GET_PRODUCT_GROUP_FULFILLED,
                payload: { id: productGroupId, data: productGroup },
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting product group.", error);

            /* Reject the request for materials, since the API failed. */
            dispatch({
                type: GET_PRODUCT_GROUP_REJECTED,
            });

            callback(false);
        });
};

/*
    Delete product groups.
*/
const deleteProductGroups = (contractId, scenarioId, productGroupsToDelete, callback) => (dispatch) => {
    dispatch({
        type: DELETE_PRODUCT_GROUPS_PENDING,
    });

    /*
        We want to get a singular product group.
    */
    axios
        .delete(API_ROOT + "/contracts/" + contractId + "/" + scenarioId + "/productGroups", { data: productGroupsToDelete })
        .then(() => {
            /* Fulfil the request for materials, since the API succceded, pass the product group data to the reducer. */
            dispatch({
                type: DELETE_PRODUCT_GROUPS_FULFILLED,
                payload: { itemsToDelete: productGroupsToDelete, scenarioId },
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting product group.", error);

            /* Reject the request for materials, since the API failed. */
            dispatch({
                type: DELETE_PRODUCT_GROUPS_REJECTED,
            });

            callback(false);
        });
};

/*
    Create a product group.
*/
const saveProductGroupConditions = (contractId, productGroupId, data, callback) => (dispatch) => {
    dispatch({
        type: SAVE_PRODUCT_GROUP_CONDITIONS_PENDING,
    });

    /*
        We want to update the product group conditions.

        The entity is the type of variable we want to conditionalise, such as rebates or discounts.
    */
    axios
        .put(API_ROOT + "/contracts/" + contractId + "/productGroups/" + productGroupId + "/conditions", { data })
        .then((res) => {
            let response = res.data;

            /* Fulfil the request to update the product group expressions */
            dispatch({
                type: SAVE_PRODUCT_GROUP_CONDITIONS_FULFILLED,
                payload: response,
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred creating the product group.", error);

            /* Reject the request to update the product group conditions, since the API failed. */
            dispatch({
                type: SAVE_PRODUCT_GROUP_CONDITIONS_REJECTED,
            });

            callback(false);
        });
};

/*
    Save product group details
*/
const saveProductGroupDetails = (contractId, productGroupId, data, callback) => (dispatch) => {
    dispatch({
        type: SAVE_PRODUCT_GROUP_PENDING,
    });

    /*
        We want to update the product group conditions.

        The entity is the type of variable we want to conditionalise, such as rebates or discounts.
    */
    axios
        .put(API_ROOT + "/contracts/" + contractId + "/productGroups/" + productGroupId, { data })
        .then(() => {
            /* Fulfil the request to update the product group expressions */
            dispatch({
                type: SAVE_PRODUCT_GROUP_FULFILLED,
                payload: { id: productGroupId, data },
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred creating the product group.", error);

            /* Reject the request to update the product group conditions, since the API failed. */
            dispatch({
                type: SAVE_PRODUCT_GROUP_REJECTED,
            });

            callback(false);
        });
};

/*
    Save product group details
*/
const saveProductGroupCompliance = (contractId, productGroupId, complianceData, callback) => (dispatch) => {
    dispatch({
        type: SAVE_PRODUCT_GROUP_COMPLIANCE_PENDING,
    });

    /*
        We want to update the product group compliance.
    */
    axios
        .put(API_ROOT + "/contracts/" + contractId + "/productGroups/" + productGroupId + "/compliance", { data: complianceData })
        .then((res) => {
            let complianceExpressions = res.data;

            /* Fulfil the request to update the product group expressions */
            dispatch({
                type: SAVE_PRODUCT_GROUP_COMPLIANCE_FULFILLED,
                payload: { id: productGroupId, complianceExpressions },
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred creating the product group.", error);

            /* Reject the request to update the product group compliance, since the API failed. */
            dispatch({
                type: SAVE_PRODUCT_GROUP_COMPLIANCE_REJECTED,
            });

            callback(false);
        });
};

/*
    Delete material groups from product groups.
*/
const removeMaterialsFromProductGroup = (contractId, productGroupId, materialGroupIdsToDelete, callback) => (dispatch) => {
    dispatch({
        type: DELETE_PRODUCT_GROUP_MATERIALS_PENDING,
    });

    /*
        We want to get a singular product group.
    */
    axios
        .delete(API_ROOT + "/contracts/" + contractId + "/productGroups/" + productGroupId + "/materials", { data: materialGroupIdsToDelete })
        .then(() => {
            /* Fulfil the request for materials, since the API succceded, pass the product group data to the reducer. */
            dispatch({
                type: DELETE_PRODUCT_GROUP_MATERIALS_FULFILLED,
                payload: { id: productGroupId, data: materialGroupIdsToDelete }
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting product group.", error);

            /* Reject the request for materials, since the API failed. */
            dispatch({
                type: DELETE_PRODUCT_GROUP_MATERIALS_REJECTED,
            });

            callback(false);
        });
};

/*
    Add material groups to product groups.
*/
const addMaterialGroups = (contractId, scenarioId, materialsToAdd, callback) => (dispatch) => {
    dispatch({
        type: ADD_PRODUCT_GROUP_MATERIALS_PENDING,
    });

    /*
        We want to get a singular product group.
    */
    axios
        .post(API_ROOT + "/contracts/" + contractId + "/" + scenarioId + "/productGroups/materials", { data: materialsToAdd })
        .then((res) => {
            console.log("Got response...", res.data);

            /* Fulfil the request for materials, since the API succceded, pass the product group data to the reducer. */
            dispatch({
                type: ADD_PRODUCT_GROUP_MATERIALS_FULFILLED,
                payload: { scenarioId, data: res.data },
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting product group.", error);

            /* Reject the request for materials, since the API failed. */
            dispatch({
                type: ADD_PRODUCT_GROUP_MATERIALS_REJECTED,
            });

            callback(false);
        });
};

/*
    Save material groups changes.
*/
const saveProductGroupMaterials = (contractId, scenarioId, productGroupId, materialGroupUpdate, resolve, reject) => (dispatch) => {
    dispatch({
        type: UPDATE_PRODUCT_GROUP_MATERIALS_PENDING,
    });

    /*
        We want to get a singular product group.
    */
    axios
        .put(API_ROOT + '/contracts/' + contractId + '/' + scenarioId + '/productGroups/' + productGroupId + '/materials', { data: materialGroupUpdate })
        .then((res) => {
            /* Fulfil the request for materials, since the API succceded, pass the product group data to the reducer. */
            dispatch({
                type: UPDATE_PRODUCT_GROUP_MATERIALS_FULFILLED,
                payload: { id: productGroupId, scenarioId, data: res.data },
            });

            resolve(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting product group.", error);

            /* Reject the request for materials, since the API failed. */
            dispatch({
                type: UPDATE_PRODUCT_GROUP_MATERIALS_REJECTED,
            });

            reject(false);
        });
};

/*
    Save material groups changes.
*/
const saveScenarioMaterialData = (contractId, scenarioId, rows, resolve, reject) => (dispatch) => {
    dispatch({
        type: UPDATE_SCENARIO_MATERIALS_PENDING,
    });
    /*
        We want to get a singular product group.
    */
    axios
        .put(`${API_ROOT}/contracts/${contractId}/${scenarioId}/rows`, { data: rows })
        .then((res) => {
            /* Fulfil the request for materials, since the API succceded, pass the product group data to the reducer. */
            dispatch({
                type: UPDATE_SCENARIO_MATERIALS_FULFILLED,
                payload: { scenarioId, data: rows },
            });

            resolve(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting product group.", error);

            /* Reject the request for materials, since the API failed. */
            dispatch({
                type: UPDATE_SCENARIO_MATERIALS_REJECTED,
            });

            reject(false);
        });
};

/*
    Save material groups changes.
*/
const reassignMaterialGroup = (contractId, existingProductGroupId, newProductGroupId, materialGroupId, isNew = false, options, callback) => (dispatch) => {
    dispatch({
        type: REASSIGN_PRODUCT_GROUP_MATERIALS_PENDING,
    });

    /*
        We want to get a singular product group.
    */
    axios
        .put(API_ROOT + "/contracts/" + contractId + "/productGroups/" + existingProductGroupId + "/reassign", { data: { isNew, options, existingProductGroupId, newProductGroupId, materialGroupId } })
        .then((res) => {
            let newProductGroup = res.data.newProductGroup;
            let materialData = {};

            if (isNew) {
                newProductGroupId = res.data.newProductGroupId;
            } else {
                materialData = res.data.materialData;
            }

            /* Fulfil the request for materials, since the API succceded, pass the product group data to the reducer. */
            dispatch({
                type: REASSIGN_PRODUCT_GROUP_MATERIALS_FULFILLED,
                payload: { data: { isNew, existingProductGroupId, newProductGroupId, materialGroupId, options, newProductGroup, materialData } },
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting product group.", error);

            /* Reject the request for materials, since the API failed. */
            dispatch({
                type: REASSIGN_PRODUCT_GROUP_MATERIALS_REJECTED,
            });

            callback(false);
        });
};

/*
    Save product groups custom conditions.
*/
const getProductGroupsSummary = (contractId, callback) => (dispatch) => {
    dispatch({
        type: GET_PRODUCT_GROUPS_SUMMARY_PENDING,
    });

    /*
        We want to get the product group summary.
    */
    axios
        .get(API_ROOT + "/contracts/" + contractId + "/productGroups/summary")
        .then((res) => {
            /* Fulfil the request for materials, since the API succceded, pass the product group summary data to the reducer. */
            dispatch({
                type: GET_PRODUCT_GROUPS_SUMMARY_FULFILLED,
                payload: res.data,
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting product group summary.", error);

            /* Reject the request for the product group summary, since the API failed. */
            dispatch({
                type: GET_PRODUCT_GROUPS_SUMMARY_REJECTED,
            });

            callback(false);
        });
};

/*
    Save material group payment allocation.
*/
const saveMaterialGroupPaymentAllocation = (contractId, materialGroups, callback) => (dispatch) => {
    dispatch({
        type: SAVE_MATERIAL_GROUP_ALLOCATION_PENDING,
    });

    /*
        We want to save the material group payment allocation.
    */
    axios
        .post(API_ROOT + '/contracts/' + contractId + '/productGroups/paymentAllocation', { 'materials': materialGroups })
        .then((res) => {
            /* Fulfil the request to save the material group payment allocation. */
            dispatch({
                type: SAVE_MATERIAL_GROUP_ALLOCATION_FULFILLED,
                payload: res.data,
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting product group summary.", error);

            /* Reject the request to save the material group payment allocation. */
            dispatch({
                type: SAVE_MATERIAL_GROUP_ALLOCATION_REJECTED,
            });

            callback(false);
        });
};

/**
 * Sets the ordering of materials as a list of MG1 ids
 *
 * @param { Array } materials The rows of materials
 */
const setMaterialOrdering = (materials) => (dispatch) => {
    let ordering = [];

    materials.forEach((material) => {
        ordering.push(material.dataPoints["MG1"]);
    });

    dispatch({
        type: SET_MATERIAL_ORDERING,
        payload: ordering,
    });
};

const resetMaterialOrdering = () => (dispatch) => {
    dispatch({
        type: RESET_MATERIAL_ORDERING,
    });
};

// EXPORT ACTIONS
export {
    createProductGroup,
    getProductGroups,
    getProductGroup,
    deleteProductGroups,
    saveProductGroupConditions,
    saveProductGroupDetails,
    saveProductGroupCompliance,
    removeMaterialsFromProductGroup,
    addMaterialGroups,
    saveProductGroupMaterials,
    saveScenarioMaterialData,
    reassignMaterialGroup,
    getProductGroupsSummary,
    saveMaterialGroupPaymentAllocation,
    setMaterialOrdering,
    resetMaterialOrdering
};
