// IMPORT PACKAGE REFERENCES
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// IMPORT PROJECT REFERENCES
import { getProductGroups, saveScenarioMaterialData } from "../../../state/actions/ProductGroupActions";
import { getContractSummarySnapshot } from "../../../state/actions/SummaryActions";
import { getCustomerTargets } from "../../../state/actions/CustomerTargetActions";

// UI
import { DataTable } from "./productGroup/DataTable";
import { Loading } from "../../../containers/loading/Loading";
import Placeholder from "../../../containers/layout/Placeholder";

export const FormContext = React.createContext();

// COMPONENT
class RevenueAndCostsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            addMaterialGroupModalShowing: false,
            splitMaterialGroupModalShowing: false,
            showingId: undefined,
            activeMaterialId: "",
            messageActive: false,
            messageLoading: false,
            message: "",
            loading: true,
            productGroup: {
                materials: {},
            },
            tableDirection: "horizontal",
            showingPopover: false,
            columnsPopoverShowing: false,
            position: {},
            tableSize: {},
            columnsPopoverPosition: {},
            groups: [
                {
                    groupName: "Sales",
                    active: true,
                    color: "#475364",
                    dataPoints: [
                        { editable: false, orderable: false, name: "Delete", bold: true, type: "delete", align: "left", dataIdentifier: "delete", width: 50, maxWidth: 50, disabled: false },
                        { editable: false, orderable: true, name: "Material", bold: true, align: "left", dataIdentifier: "MG1FriendlyDesc", width: 300, disabled: true },
                        { editable: false, orderable: true, name: "Revenue", align: "right", dataIdentifier: "Revenue", calculated: true, width: 1, disabled: true, format: { type: "dollar", decimalPlaces: 0, forceNoDecimals: true } },
                    ],
                },
                {
                    groupName: "Net Summary",
                    color: "#FCA559",
                    active: true,
                    dataPoints: [
                        { editable: false, orderable: true, name: "Unit Cases", align: "right", dataIdentifier: "UnitCases", calculated: true, width: 1.1, disabled: true, format: { type: "number", decimalPlaces: 0 } },
                        { editable: false, orderable: true, name: "Net Sales Revenue", align: "right", dataIdentifier: "NetSalesRevenue", calculated: true, width: 1.1, disabled: true, format: { type: "dollar", decimalPlaces: 2 } },
                        { editable: false, orderable: true, name: "COGs", align: "right", dataIdentifier: "COGS", calculated: true, width: 1, disabled: true, format: { type: "dollar", decimalPlaces: 2, displayAsNegative: true } },
                        { editable: false, orderable: true, name: "Delivery", align: "right", dataIdentifier: "DeliveryCost", calculated: true, width: 1.1, disabled: true, format: { type: "dollar", decimalPlaces: 2, displayAsNegative: true } },
                        { editable: false, orderable: true, name: "Net Contribution", align: "right", dataIdentifier: "NetContribution", calculated: true, width: 1, disabled: true, format: { type: "dollar", decimalPlaces: 2 } },
                        { editable: false, orderable: true, name: "Rate (NSR/UC)", align: "right", dataIdentifier: "NetSummaryRate", calculated: true, width: 0.8, disabled: true, format: { type: "dollar", decimalPlaces: 2 } },
                        { editable: false, orderable: true, name: "NC/UC", align: "right", dataIdentifier: "NetContributionPerUnitCase", calculated: true, width: 0.8, disabled: true, format: { type: "dollar", decimalPlaces: 2 } },
                    ],
                },
            ],
        };

        this.handleProductGroupClicked = this.handleProductGroupClicked.bind(this);
        this.toggleNewProductGroupModal = this.toggleNewProductGroupModal.bind(this);
        this.handleNewProductGroupModalClose = this.handleNewProductGroupModalClose.bind(this);
        this.handleDataTableSave = this.handleDataTableSave.bind(this);
        this.recalculate = this.recalculate.bind(this);
    }

    handleNewProductGroupModalClose() {
        this.hideModal();

        // Refetch the new product groups.
        this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => {
            console.log("Got product groups?", success);
        });
    }

    toggleNewProductGroupModal() {
        this.setState({ modalShowing: !this.state.modalShowing });
    }

    hideModal() {
        this.setState({ modalShowing: false });
    }

    toggleAllMaterialGroups() {
        this.setState({ allMaterialGroupsVisible: !this.state.allMaterialGroupsVisible });
    }

    handleProductGroupClicked(id, section, sectionType) {
        this.props.history.push(id + `?section=${section}`);
    }

    componentDidMount() {
        // Fetch the product groups to show on the page.
        this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });

        setTimeout(() => {
            this.setState({ loading: false });
        }, 100);

        this.props.setFloatingButtons([{ type: "recalculate" }]);
        this.props.bindRefresh(this.recalculate);
    }

    handleDelete() {
        this.materialDataTable.handleDelete();
    }

    recalculate() {
        this.setState({ loading: true });

        let promises = [];

        let promise = new Promise((resolve, reject) => {
            this.props.getContractSummarySnapshot(this.props.match.params.contractId, this.props.match.params.scenarioId);
        });
        promises.push(promise);

        Promise.all(promises)
            .then((data) => {
                this.props.getContractSummarySnapshot(this.props.match.params.contractId, this.props.match.params.scenarioId);

                // When all the materials have been updated, recall the customer targets and topline summary.
                this.props.getCustomerTargets(this.props.match.params.contractId, this.props.match.params.scenarioId);
                // When all the materials have been updated, recall the customer targets and topline summary.
                // this.props.getCustomerTargets(this.props.match.params.contractId, this.props.match.params.scenarioId)
                this.props.getScenarioProfitStorySummary(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });

                // Update the contract summary...
                this.props.getContractSummarySnapshot(this.props.match.params.contractId, this.props.match.params.scenarioId);

                this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
                this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
                this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
                this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
                this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
                this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
                this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
                this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
                this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
                this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
                this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
                this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
                this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
                this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
                this.render();
            })
            .catch((error) => {
                console.log(error);
            });
        this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 200);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.scenarioId !== this.props.match.params.scenarioId) {
            this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
        }
    }

    handleDataTableSave(rows, completion) {
        let promises = [];

        let promise = new Promise((resolve, reject) => {
            this.props.saveScenarioMaterialData(this.props.match.params.contractId, this.props.match.params.scenarioId, rows, resolve, reject);
            this.props.getContractSummarySnapshot(this.props.match.params.contractId, this.props.match.params.scenarioId);
        });
        promises.push(promise);

        Promise.all(promises)
            .then((data) => {
                // When all the materials have been updated, recall the customer targets and topline summary.
                this.props.getCustomerTargets(this.props.match.params.contractId, this.props.match.params.scenarioId);
                completion(true);
            })
            .catch((error) => {
                completion(false);
            });
    }

    render() {
        let activeScenarioId = this.props.match.params.scenarioId;

        let materials = [];
        if (this.props.productGroups) {
            let productGroups = this.props.productGroups[activeScenarioId];

            if (productGroups) {
                materials = Object.keys(productGroups).map((productGroupKey) => {
                    let rowItem = {};
                    rowItem["discounts"] = productGroups[productGroupKey].discounts === undefined ? {} : productGroups[productGroupKey].discounts;
                    rowItem["dataPoints"] = Object.values(productGroups[productGroupKey].materials)[0];
                    rowItem["productGroupId"] = productGroupKey;
                    return rowItem;
                });
            }
        }

        if (this.state.loading) {
            return <Loading />;
        }

        return (
            <div>
                {materials.length === 0 ? (
                    <Placeholder type="no_materials" />
                ) : (
                    <div className="material-data-table-container">
                        {this.props.fetching ? (
                            <Loading />
                        ) : (
                            <DataTable
                                enableRemove={this.props.enableRemove}
                                disableRemove={this.props.disableRemove}
                                sizeListener={this.tableSizeCallback}
                                direction={this.state.tableDirection}
                                onRef={this.props.onRef}
                                hideTitleBorder
                                handleSplit={(row) => {
                                    this.handleMaterialSplit(row);
                                }}
                                handleSave={this.handleDataTableSave}
                                groups={this.state.groups.filter((group) => {
                                    return group.active;
                                })}
                                rows={materials}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { productGroups, fetching } = state.productGroupReducer;
    return { productGroups, fetching };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getProductGroups,
            saveScenarioMaterialData,
            getCustomerTargets,
            getContractSummarySnapshot,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(RevenueAndCostsPage));

// EXPORT COMPONENT
export { hoc as RevenueAndCostsPage };
