// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getDiscounts } from "../../../state/actions/DiscountActions";

// UI
import { IndicatorTile } from "./IndicatorTile";

import { EditableInputTypes } from "../../../enums/EditableInputTypes";

// COMPONENT
class DiscountIndicatorRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            indicators: [],
        };

        this.generateIndicators = this.generateIndicators.bind(this);
    }

    generateIndicators() {
        let percentDiscount = "0%";

        if (this.props.discounts.hasOwnProperty("percentDiscount")) {
            if (this.props.discounts.percentDiscount.hasOwnProperty("fixedValue")) {
                percentDiscount = this.props.discounts.percentDiscount.fixedValue + "%";
            }
        }

        let indicators = [];
        indicators.push({ title: "Percent Discount", value: percentDiscount });
        this.setState({ indicators: indicators });
    }

    componentDidMount() {
        // get the discounts, then generate indicators
        this.generateIndicators();

        this.props.getDiscounts(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => {
            if (success) {
                this.generateIndicators();
            }
        });
    }

    componentDidUpdate(prevProps) {
        // generate indicators if prev discounts dont match current
        if (JSON.stringify(this.props.discounts) !== JSON.stringify(prevProps.discounts)) {
            this.generateIndicators();
        }
    }

    render() {
        return (
            <div className="indicator-row">
                {this.state.indicators.map((data, index) => {
                    return <IndicatorTile key={index} title={data.title} value={data.value} type={data.type} />;
                })}
            </div>
        );
    }
}

DiscountIndicatorRow.propTypes = {
    rebates: PropTypes.object.isRequired,
};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { discounts } = state.discountReducer;
    return { discounts };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getDiscounts }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(DiscountIndicatorRow));

// EXPORT COMPONENT

export { hoc as DiscountIndicatorRow };
