// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ExclusivityPage } from "./ExclusivityPage";
import { pinToSidebar, unpinFromSidebar } from "../../../state/actions/UserActions";

import { PageTitle } from "../../../containers/layout/PageTitle";
import { SimpleList } from "../lists/SimpleList";
import { ContextualSidebar } from "../../../containers/layout/ContextualSidebar";
import { CELL_TYPES } from "../CellTypes";
import { MasterDetailRouter } from "../../../containers/layout/MasterDetailRouter";
import { getContract } from "../../../state/actions/ContractActions";
import { GeneralScenarioDetailsPage } from "./GeneralScenarioDetailsPage";

// Component to add Financial Incentives to a contract.
// Financial Incentives are either upfront or annual payments, or rebates.
class ScenarioDetailsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            messageActive: false,
            message: "",
            collapsedSections: {
                escalatorAssumptions: true,
            },
        };

        this.handleClick = this.handleClick.bind(this);
        this.handlePin = this.handlePin.bind(this);
        this.handleCollapse = this.handleCollapse.bind(this);
    }

    componentDidMount() {
        this.props.getContract(this.props.match.params.contractId, (success) => { });
    }

    handleClick(section) {
        this.props.history.push(`?section=${section}`);
    }

    handlePin(pageIdentifier) {
        if (this.props.pinned.hasOwnProperty(pageIdentifier)) {
            this.props.unpinFromSidebar(pageIdentifier);
        } else {
            this.props.pinToSidebar(pageIdentifier);
        }
    }

    handleCollapse(section) {
        let sections = Object.assign(this.state.collapsedSections);
        sections[section] = !sections[section];

        this.setState({
            collapsedSections: sections,
        });
    }

    render() {
        let sections = [
            {
                sectionHeader: "Details",
                page: <GeneralScenarioDetailsPage title="General Scenario Details" />,
                sectionIdentifier: "generalDetails",
                cell: {
                    type: CELL_TYPES.NORMAL,
                    identifiers: { label: "General Scenario Details" },
                },
            },
            {
                page: <ExclusivityPage pinned={this.props.pinned.hasOwnProperty("exclusivity")} handlePin={this.handlePin} title="Exclusivity" />,
                sectionIdentifier: "exclusivity",
                cell: {
                    type: CELL_TYPES.NORMAL,
                    identifiers: { label: "Exclusivity" },
                },
            },
        ];

        return (
            <Fragment>
                <Fragment>
                    <ContextualSidebar pageTitle={<PageTitle title={"Scenario Details"} />} left={<SimpleList collapsed={this.state.collapsedSections} onCollapse={this.handleCollapse} handleClick={this.handleClick} sections={sections} />} right={<MasterDetailRouter sections={sections} />} />
                </Fragment>
            </Fragment>
        );
    }
}

ScenarioDetailsPage.propTypes = {};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { pinned } = state.userReducer;
    return { pinned };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getContract, pinToSidebar, unpinFromSidebar }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(ScenarioDetailsPage));

// EXPORT COMPONENT

export { hoc as ScenarioDetailsPage };
