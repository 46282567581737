// *** PACKAGE REFERENCES *** //
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// *** ACTIONS *** //
import { getContractDetails, saveContractDetails } from "../../../state/actions/ContractDetailsActions";
import { getContract } from "../../../state/actions/ContractActions";
import { showError, hideError } from "../../../state/actions/GeneralActions";

// *** UI *** //
import { ContextPage } from "../../../containers/layout/ContextPage";
import { Card } from "../../../containers/card/Card";
import { CardRow } from "../../../containers/card/CardRow";
import { Formik } from "formik";
import "moment/locale/en-nz";
import "react-datepicker/dist/react-datepicker.css";
import { CCAInput } from "../../../containers/inputs/CCAInput";
import { CCAInputTypes } from "../../../enums/CCAInputTypes";
import { CCAError } from "../../../enums/CCAError";

class CustomerDetailsPage extends React.Component {
    constructor(props) {
        super(props);

        this.valuesAtLastSave = {};
        this.getDetails = this.getDetails.bind(this);
    }

    componentDidMount() {
        this.getDetails();
    }

    getDetails() {
        this.props.getContractDetails(this.props.match.params.contractId, (success) => {
            if (success) {
                this.valuesAtLastSave = this.props.contractDetails.customer;
                this.props.hideError(CCAError.GET_CONTRACT_DETAILS_ERROR);
            } else {
                this.props.showError(CCAError.GET_CONTRACT_DETAILS_ERROR);
            }
        });
    }

    saveContractDetails(values) {
        this.props.saveContractDetails(this.props.match.params.contractId, values, "customer", (success) => {
            if (success) {
                this.props.hideError(CCAError.SAVE_CONTRACT_DETAILS_ERROR);
                this.valuesAtLastSave = values;
            } else {
                this.props.showError(CCAError.SAVE_CONTRACT_DETAILS_ERROR, () => {
                    this.saveContractDetails(values);
                });
            }
        });
    }

    render() {
        return (
            <ContextPage fetching={this.props.fetching} fetched={this.props.fetched} title={"Customer Details"}>
                {true && (
                    <Fragment>
                        <Formik
                            enableReinitialize={true}
                            initialValues={this.props.contractDetails.customer}
                            onSubmit={(values) => {
                                if (JSON.stringify(values) !== JSON.stringify(this.valuesAtLastSave)) {
                                    this.saveContractDetails(values);
                                }
                            }}
                            render={({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => {
                                return (
                                    <Fragment>
                                        <Card>
                                            <CardRow top label={"Prepared By"}>
                                                <CCAInput placeholder={"Prepared By"} type={CCAInputTypes.FREE_TEXT} name="preparedBy" value={values.preparedBy} onChange={handleChange} onSave={handleSubmit} />
                                            </CardRow>

                                            <CardRow top label={"Company Name (Legal Name)"}>
                                                <CCAInput placeholder={"Company Name"} type={CCAInputTypes.FREE_TEXT} name="companyName" value={values.companyName} onChange={handleChange} onSave={handleSubmit} />
                                            </CardRow>

                                            <CardRow top label={"Company Name (Trading Name)"}>
                                                <CCAInput placeholder={"Trading Name"} type={CCAInputTypes.FREE_TEXT} name="tradingName" value={values.tradingName} onChange={handleChange} onSave={handleSubmit} />
                                            </CardRow>

                                            <CardRow label={"Customer Number"}>
                                                <CCAInput placeholder={"Customer Number"} type={CCAInputTypes.FREE_TEXT} name="outletNumber" value={values.outletNumber} onChange={handleChange} onSave={handleSubmit} />
                                            </CardRow>

                                            <CardRow label={"Customer Business Number"}>
                                                <CCAInput placeholder={"Customer Business Number"} type={CCAInputTypes.FREE_TEXT} name="customerNumber" value={values.customerNumber} onChange={handleChange} onSave={handleSubmit} />
                                            </CardRow>

                                            <CardRow longform label={"Customer Full Address"}>
                                                <CCAInput placeholder={"Customer Full Address"} type={CCAInputTypes.DESCRIPTION} name="fullAddress" value={values.fullAddress} onChange={handleChange} onSave={handleSubmit} />
                                            </CardRow>

                                            <CardRow bottom label={"Company Owner/Director"}>
                                                <CCAInput placeholder={"Company Owner/Director"} type={CCAInputTypes.FREE_TEXT} name="businessOwner" value={values.businessOwner} onChange={handleChange} onSave={handleSubmit} />
                                            </CardRow>

                                            <CardRow bottom label={"Customer Contact Email"}>
                                                <CCAInput placeholder={"Customer Contact Email"} type={CCAInputTypes.FREE_TEXT} name="contactEmail" value={values.contactEmail} onChange={handleChange} onSave={handleSubmit} />
                                            </CardRow>

                                            <CardRow bottom label={"Customer Contact Number"}>
                                                <CCAInput placeholder={"Customer Contact Number"} type={CCAInputTypes.FREE_TEXT} name="contactPhone" value={values.contactPhone} onChange={handleChange} onSave={handleSubmit} />
                                            </CardRow>
                                        </Card>
                                    </Fragment>
                                );
                            }}
                        />
                    </Fragment>
                )}
            </ContextPage>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { fetching, fetched, failed, contractDetails } = state.contractDetailsReducer;
    return { fetching, fetched, failed, contractDetails };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getContractDetails,
            getContract,
            saveContractDetails,
            showError,
            hideError,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerDetailsPage));

// EXPORT COMPONENT
export { hoc as CustomerDetailsPage };
