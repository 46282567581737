// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { Loading } from "../loading/Loading";

// COMPONENT
export class CardActions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: false,
            loading: false,
        };
    }

    componentDidUpdate() {
        if (this.state.editing !== this.props.editing) {
            this.setState({ editing: this.props.editing });
        }

        if (this.state.loading !== this.props.loading) {
            this.setState({ loading: this.props.loading });
        }
    }

    render() {
        return (
            <Fragment>
                <div onClick={(e) => this.props.handleClick(e)} className="text-link">
                    {this.state.loading ? <Loading small /> : this.state.editing ? "Save" : "Edit"}
                </div>
                {this.props.children}
            </Fragment>
        );
    }
}
