// IMPORT PACKAGE REFERENCES
import React from "react";
import ReactDOM from "react-dom";

// COMPONENT
export class FloatingBar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return ReactDOM.createPortal(
            <div onClick={this.props.action} className={"floating-bar"}>
                {this.props.children}
            </div>,
            document.body
        );
    }
}
