// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

import { getAlerts } from "../../../state/actions/AlertActions";
import { getContractApprovers, approveContract } from "../../../state/actions/ContractActions";
import { getApproval } from "../../../state/actions/SummaryActions";
import { getDocumentList, deleteDocument, sendContractToPnR } from "../../../state/actions/DocumentActions";
import { API_ROOT, APPROVALS_ROOT } from "../../../../config/config";

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
// UI
import { Col, Row, Button, Container } from "reactstrap";
import { Loading } from "../../../containers/loading/Loading";
import { ContextPage } from "../../../containers/layout/ContextPage";
import { FlexibleDataTable } from "../../../containers/table/FlexibleDataTable";
import RejectionModal from "./RejectionModal";

// COMPONENT
class ApprovalPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            submitting: false,
            showRejectionModal: false,
            isAdmin: false,
            alerts: {}
        };

        this.getContractIdFromUrl = this.getContractIdFromUrl.bind(this);
        this.getScenarioIdFromUrl = this.getScenarioIdFromUrl.bind(this);
        this.getApprovers = this.getApprovers.bind(this);
        this.approveContract = this.approveContract.bind(this);
        this.rejectContract = this.rejectContract.bind(this);
        this.triggerRejectionModal = this.triggerRejectionModal.bind(this);
        this.generateDocumentList = this.generateDocumentList.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);
        this.submitToPnR = this.submitToPnR.bind(this);
        this.isCurrentUserNextToApprove = this.isCurrentUserNextToApprove.bind(this);
    }

    getContractIdFromUrl() {
        let components = this.props.location.pathname.split("/");
        return components[2];
    }

    getScenarioIdFromUrl() {
        let components = this.props.location.pathname.split("/");
        return components[3];
    }

    getApprovers() {
        this.setState({ loading: true });
        this.props.getContractApprovers(this.getContractIdFromUrl(), this.getScenarioIdFromUrl(), this.props.user.email, (success) => {
            this.setState({ loading: false });
        });
    }

    renderAlerts() {
        if(!this.state.alerts || !('Products' in this.state.alerts)) return;
        // Make sure we're only showing product alerts in this view
        const alerts = Object.values(this.state.alerts.Products);
        if(!alerts.length) return;

        return alerts.map(alert => {
            return (
                <div className="payment-allocation-warning" key={alert.key}>
                    <FeatherIcon icon="alert-circle" />
                    <span>{alert.message}</span>
                </div>
            )
        });
    }

    getAlerts() {
        console.log('getting alerts', this.state);
        const contractId = this.getContractIdFromUrl();
        const scenarioId = this.getScenarioIdFromUrl();  
                
        this.props.getAlerts(contractId, scenarioId, (alertSuccess) => {
            if (!alertSuccess) console.log("Failed to retrieve contract alerts");
        });
    }

    isCurrentUserNextToApprove() {
        let userEmail = this.props.user.email;
        let userTreeUser = this.props.approvalTree.filter(item => item.Email == userEmail);
        if (userTreeUser.length >= 1 && userTreeUser[0].Status == "InProgress") {
            return true;
        }

        return this.props.user.role == "admin";
    }

    approveContract() {
        this.setState({ loading: true });
        this.props.approveContract(this.getContractIdFromUrl(), this.getScenarioIdFromUrl(), this.props.user.email, false, "", this.state.isAdmin, (success) => {
            this.props.getContractApprovers(this.getContractIdFromUrl(), this.getScenarioIdFromUrl(), this.props.user.email, (success) => {
                this.setState({ loading: false });
            });
        });
    }

    rejectContract(reason) {
        this.triggerRejectionModal();
        this.props.approveContract(this.getContractIdFromUrl(), this.getScenarioIdFromUrl(), this.props.user.email, true, reason, this.state.isAdmin, (success) => {
            this.props.getContractApprovers(this.getContractIdFromUrl(), this.getScenarioIdFromUrl(), this.props.user.email, (success) => {
                this.setState({ loading: false });
            });
        });
    }

    triggerRejectionModal() {
        this.setState({ showRejectionModal: !this.state.showRejectionModal })
    }

    componentDidMount() {
        this.setState({ ...this.state, isAdmin: this.props.user.role == "admin" })
        this.getApprovers();
        this.props.getApproval(this.getContractIdFromUrl(), this.getScenarioIdFromUrl());
        this.props.getDocumentList(this.getContractIdFromUrl(), this.getScenarioIdFromUrl());
        this.getAlerts();
        this.setState({ alerts: this.props.alerts }, () => {
            // console.log(this.state.alerts)
        });

    }

    deleteDocument(location) {
        this.props.deleteDocument(location, this.getContractIdFromUrl(), this.getScenarioIdFromUrl(), (success) => {
            this.props.getDocumentList(this.getContractIdFromUrl(), this.getScenarioIdFromUrl());
        });
    }

    generateDocumentList() {
        const list = this.props.documentList.map(item => {
            return (
                <li className="dropzoneDocumentListItem" key={item}>
                    <a href={`${APPROVALS_ROOT}/GetDocumentByLocation?Location=${item}`}>
                        {item.split("/")[2]}
                    </a>
                    <Button style={{ outline: 'none', border: '0px' }} onClick={event => { this.deleteDocument(item) }}>
                        <FeatherIcon icon="x-circle" />
                    </Button>
                </li>)
        })
        return list;
    }

    submitToPnR() {
        this.props.sendContractToPnR(this.getContractIdFromUrl(), this.getScenarioIdFromUrl(), (success) => {
            success ? alert("Sent to PnR Team") : alert("Sending to PnR team failed");
        });
    }

    renderApprovalTree(approvalTree) {
        let cols = ["Name", "Role", "Status", "Rejection Reason"];
        let rows = approvalTree.map(item => {
            return ({
                columns: [
                    { cellType: 'text', data: item.DisplayName },
                    { cellType: 'text', data: item.Role },
                    { cellType: 'text', data: item.Status },
                    { cellType: 'text', data: item.RejectionReason },
                ]
            })
        });
        return (
            <FlexibleDataTable
                columnTable
                header="Approval Tree"
                headerColour="pink"
                columnHeaders={cols}
                rows={rows}
            />
        )
    }

    render() {
        console.log('Approval Page', this.props)
        return (
            <ContextPage
                title="Approval Page"
                fetching={false}
                fetched={true}
                secondElement={
                    this.props.user.role == "admin" ?
                        <Button
                            className="menu-action-button"
                            color="link"
                            onClick={() => {
                                location.href = `${API_ROOT}/contracts/${this.props.match.params.contractId}/${this.props.match.params.scenarioId}/calculate/PAFund`;
                            }}
                        >
                            <FeatherIcon className="button-icon" icon="gift" /> PA Fund
                        </Button>
                        : null
                }
                thirdElement={
                    this.props.user.role == "admin" ?
                        <Button
                            className="menu-action-button"
                            color="link"
                            onClick={() => {
                                location.href = `${API_ROOT}/contracts/${this.props.match.params.contractId}/${this.props.match.params.scenarioId}/calculate/ROISheet`;
                            }}
                        >
                            <FeatherIcon className="button-icon" icon="shopping-cart" /> ROI Export
                        </Button>
                        : null
                }
                fourthElement={
                    this.props.user.role == "admin" ?
                        <Button
                            className="menu-action-button"
                            color="link"
                            onClick={() => {
                                location.href = `${API_ROOT}/contracts/${this.props.match.params.contractId}/${this.props.match.params.scenarioId}/calculate/RebateSheet`;
                            }}
                        >
                            <FeatherIcon className="button-icon" icon="bar-chart" /> Rebate Sheet
                        </Button>
                        : null
                }
            >
                {this.renderAlerts()}
                <RejectionModal isOpen={this.state.showRejectionModal} doRejection={this.rejectContract} toggle={this.triggerRejectionModal} />
                {
                    this.state.loading ?
                        <Loading />
                        :
                        <Fragment>
                            <div className="flexible-container">
                                <div className="flexible-item">
                                    <div className="flexible-row">
                                        <div className="flexible-element right">Required by {this.props.approvalReason.approver} </div>
                                    </div>
                                    <div className="flexible-row">
                                        <div className="flexible-element left">Reason: <br></br>{this.props.approvalReason.reason} </div>
                                    </div>
                                </div>
                            </div>
                            <div>{this.renderApprovalTree(this.props.approvalTree)}</div>
                            {this.isCurrentUserNextToApprove() &&
                                <Container>
                                    <Row>
                                        <Col xs="6"><Button size="lg" block color="secondary" disabled={Object.entries(this.state.alerts).length != 0 || this.props.isCalculationPending } onClick={this.approveContract}>Approve / Request Approval</Button></Col>
                                        <Col xs="6"><Button size="lg" block color="secondary" disabled={this.props.isCalculationPending } onClick={this.triggerRejectionModal}>Reject</Button></Col>
                                    </Row>
                                </Container>
                            }
                            <div className="flexible-container">
                                <div className="flexible-item">
                                    <div className="flexible-header green">Signed Document Upload
                                        {this.props.documentList.length >= 1 &&
                                            <Button
                                                className="btn btn-light"
                                                style={{ color: "#28a745", backgroundColor: "#FFFFFF" }}
                                                onClick={this.submitToPnR}>
                                                Submit to PnR Team
                                            </Button>}
                                    </div>
                                    <ul className="dropzoneDocumentList">
                                        {this.generateDocumentList()}
                                    </ul>
                                    <Dropzone
                                        getUploadParams={() => ({ url: `${APPROVALS_ROOT}/UploadDocument?ContractId=${this.getContractIdFromUrl()}&ScenarioId=${this.getScenarioIdFromUrl()}` })}
                                        onChangeStatus={({ remove }, status) => {
                                            if (status === 'headers_received') {
                                                remove();
                                                this.props.getDocumentList(this.getContractIdFromUrl(), this.getScenarioIdFromUrl());
                                            }
                                        }}
                                        accept="*"
                                    />
                                </div>
                            </div>
                        </Fragment>

                }
            </ContextPage >
        );
    }
}

ApprovalPage.propTypes = {};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { approvalTree, approvalReason } = state.contractReducer;
    const { user } = state.userReducer;
    const { documentList } = state.documentReducer;
    const { alerts } = state.alertReducer;

    return { approvalTree, user, approvalReason, documentList, alerts };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getAlerts,
            getContractApprovers,
            approveContract,
            getDocumentList,
            deleteDocument,
            sendContractToPnR,
            getApproval
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(ApprovalPage));

// EXPORT COMPONENT

export { hoc as ApprovalPage };
