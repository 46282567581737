// IMPORT PACKAGE REFERENCES
import React, { Suspense } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FeatherIcon from "feather-icons-react";
import { getUser } from "../state/actions/UserActions";
import getSearchSuggestions from "../routers/Routes.js";
import { Loading } from "../containers/loading/Loading";

class SearchResultSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
        };
    }

    componentDidUpdate() {
        if (JSON.stringify(this.state.data) !== JSON.stringify(this.props.data)) {
            this.setState({ data: this.props.data });
        }
    }

    render() {
        return this.state.data.map((item) => {
            return (
                <div
                    onClick={() => {
                        this.props.navigate(item.route);
                    }}
                    className="search-item"
                >
                    {item.name}
                    <FeatherIcon className="disclosure-indicator" icon="chevron-right" />
                </div>
            );
        });
    }
}

// COMPONENT
class SearchSuggestions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            suggestions: {},
        };

        this.handleEnter = this.handleEnter.bind(this);
        this.navigate = this.navigate.bind(this);
    }

    getContractIdFromUrl(providedProps) {
        let props = this.props;

        if (providedProps) {
            props = providedProps;
        }

        let components = props.location.pathname.split("/");
        return components[2];
    }

    getScenarioIdFromUrl(providedProps, newScenarioId = null) {
        if (newScenarioId) {
            return newScenarioId;
        }

        let props = this.props;

        if (providedProps) {
            props = providedProps;
        }

        let components = props.location.pathname.split("/");
        return components[3];
    }

    componentDidUpdate(prevProps) {
        if (prevProps.searchText !== this.props.searchText) {
            let searchSuggestions = getSearchSuggestions(this.props.searchText, { contractId: this.getContractIdFromUrl(), scenarioId: this.getScenarioIdFromUrl() });

            searchSuggestions.map((suggestion) => {
                suggestion.items.then((data) => {
                    let suggestions = Object.assign({}, this.state.suggestions);
                    suggestions[suggestion.name] = data;
                    this.setState({ suggestions });
                });
            });
        }
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    handleEnter() {
        let items = this.state.suggestions.flatMap((suggestion) => {
            return suggestion.items;
        });

        if (items.length === 1) {
            this.navigate(items[0].route);
        }
    }

    navigate(route) {
        this.props.history.push(route);
        this.props.hideResults();
    }

    render() {
        let suggestions = this.state.suggestions;

        return (
            <div>
                {Object.keys(suggestions).map((suggestionKey) => {
                    let results = suggestions[suggestionKey];

                    if (results.length === 0) {
                        return;
                    }

                    return (
                        <div>
                            <div className="search-section">{suggestionKey}</div>
                            <SearchResultSection navigate={this.navigate} data={results} />
                        </div>
                    );
                })}
            </div>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { working } = state.generalReducer;
    const { user } = state.userReducer;
    return { working, user };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getUser,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchSuggestions));

// EXPORT COMPONENT

export { hoc as SearchSuggestions };
