// IMPORT PACKAGE REFERENCES
import React from "react";
import { Loading } from "../../loading/Loading";

import { EditableInputTypes } from "../../../enums/EditableInputTypes";

// COMPONENT
export class IndicatorTile extends React.Component {
    constructor(props) {
        super(props);
        this.renderValue = this.renderValue.bind(this);
    }

    renderValue() {
        switch (this.props.type) {
            case EditableInputTypes.DOLLAR:
                return <div className="tile-value">${this.props.value}</div>;
            case EditableInputTypes.PERCENT:
                return <div className="tile-value">{this.props.value}%</div>;
            default:
                return <div className="tile-value">{this.props.value}</div>;
        }
    }

    render() {
        return (
            <div className="cell-indicator-container-outer">
                <div className="cell-indicator-container-inner">
                    {!this.props.loadingSpinner && <div> {this.renderValue()} </div>}
                    {this.props.loadingSpinner && <Loading small />}
                    <div className="tile-label">{this.props.title}</div>
                </div>
            </div>
        );
    }
}
