import { ALERT_POST_FULFILLED, ALERT_CLEAR_FULFILLED, GET_ALERTS_FULFILLED, GET_ALERTS_REJECTED, GET_ALERTS_PENDING } from "../actions/AlertActions";

import { ClEAR_REDUCERS, CLEAR_REDUCERS } from "../actions/GeneralActions";

// INITIALIZE STATE
const initialState = {
    alerts: {},
    fetching: false,
    fetched: false,
    failed: false,
};

function UUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

// REDUCER
export const AlertReducer = (state = initialState, action) => {
    let alerts = Object.assign({}, state.alerts);

    switch (action.type) {
        case CLEAR_REDUCERS:
            return initialState;
        case ALERT_POST_FULFILLED:
            let message = action.payload.message;

            if (!alerts.hasOwnProperty(action.payload.pageId)) {
                alerts[action.payload.pageId] = [];
            }

            let key = UUID();

            alerts[action.payload.pageId][key] = { messageId: key, message: message };

            return {
                ...state,
                alerts,
            };
        case ALERT_CLEAR_FULFILLED:
            let pageId = action.payload.pageId;
            let messageId = action.payload.messageId;

            if (!messageId) {
                delete alerts[pageId];
            } else {
                delete alerts[pageId][messageId];
            }

            return {
                ...state,
                alerts,
            };
        case GET_ALERTS_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_ALERTS_FULFILLED:
            let serverAlerts = action.payload;
            alerts = {};

            serverAlerts.forEach((alert) => {
                alert.pages.forEach((page) => {
                    if (!alerts.hasOwnProperty(page)) {
                        alerts[page] = {};
                    }

                    alerts[page][alert.key] = alert;
                });
            });

            return {
                ...state,
                alerts: alerts,
                fetching: false,
                fetched: true,
                failed: false,
            };
        case GET_ALERTS_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        default:
            return state;
    }
};
