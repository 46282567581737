import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FeatherIcon from "feather-icons-react";
var classNames = require("classnames");

import { getAlerts } from "../../../state/actions/AlertActions";

import { CELL_TYPES } from "../CellTypes";
import { SimpleCell } from "../../../containers/table/cells/SimpleCell";
import { IndicatorCell } from "../../../containers/table/cells/IndicatorCell";

class SimpleList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeSection: null,
            alerts: {},
            collapsed: {},
        };
    }

    handleSectionChange(sectionIdentifier) {
        this.setState({ activeSection: sectionIdentifier });
    }

    componentDidMount() {
        const sectionIdentifier = queryString.parse(this.props.location.search).section;

        if (this.props.disableAutoRoute) {
            // Don't auto select a section
        } else {
            // Auto select the section if no specific section in query, otherwise select section identified by sectionIdentifier
            if (sectionIdentifier === undefined) {
                let sections = this.props.sections;
                if (sections.length > 0) {
                    this.props.handleClick(sections[0].sectionIdentifier);
                }
            } else {
                this.handleSectionChange(sectionIdentifier);
            }

            // If the contract is defined, fetch its alerts.
            if (this.props.match.params.contractId) {
                this.props.getAlerts(this.props.match.params.contractId, this.props.match.params.scenarioId);
            }
        }
    }

    componentDidUpdate() {
        if (JSON.stringify(this.props.alerts) !== JSON.stringify(this.state.alerts)) {
            this.setState({ alerts: this.props.alerts });
        }

        if (JSON.stringify(this.state.collapsed) !== JSON.stringify(this.props.collapsed)) {
            this.setState({ collapsed: this.props.collapsed });
        }
    }

    render() {
        return (
            <Fragment>
                {this.props.sections.map((section, sectionIndex) => {
                    // Work out if the cell is collapsed or not.
                    let isCollapsed = false;

                    if (this.state.collapsed) {
                        if (this.state.collapsed.hasOwnProperty(section.sectionIdentifier)) {
                            isCollapsed = this.state.collapsed[section.sectionIdentifier];
                        } else if (this.state.collapsed.hasOwnProperty(section.linkedTo)) {
                            isCollapsed = this.state.collapsed[section.linkedTo];
                        }
                    }

                    /* 
                        If the route is restricted access and the user isn't an admin, 
                        don't allow them to see the page.
                    */
                    if (section.restricted === "admin" && this.props.user.role !== "admin") {
                        return <Fragment key={sectionIndex} />;
                    }

                    let cellClasses = classNames("rl-cell-container", { "rl-active-item": section.sectionIdentifier === queryString.parse(this.props.location.search).section });

                    return (
                        <Fragment key={sectionIndex}>
                            {section.sectionHeader !== undefined && (
                                <div
                                    onClick={() => {
                                        section.collapse && this.props.onCollapse(section.sectionIdentifier);
                                    }}
                                    className={"rl-section-label" + (section.collapse ? " collapseable" : "")}
                                >
                                    {" "}
                                    {section.sectionHeader}{" "}
                                    {section.collapse ? (
                                        <FeatherIcon
                                            onClick={() => {
                                                this.props.onCollapse(section.sectionIdentifier);
                                            }}
                                            className={"rl-item-collapse-icon" + (isCollapsed ? " collapsed" : "")}
                                            size={17}
                                            icon="arrow-up"
                                        />
                                    ) : (
                                            ""
                                        )}
                                </div>
                            )}

                            <div
                                className={cellClasses}
                                key={sectionIndex}
                                onClick={() => {
                                    this.props.handleClick(section.sectionIdentifier);
                                    this.handleSectionChange(section.sectionIdentifier);
                                }}
                            >
                                <div key={"rl-cell-indicator" + sectionIndex} className={"rl-cell-indicator" + (section.sectionIdentifier === queryString.parse(this.props.location.search).section ? " active" : "")}></div>
                                <div key={"rl-cell-content" + sectionIndex} className={"rl-cell-content" + (isCollapsed ? " collapsed" : "")}>
                                    {section.cell.type === CELL_TYPES.NORMAL && <SimpleCell key={"SimpleCell" + sectionIndex} alerts={this.state.alerts} label={section.cell.identifiers.label} />}
                                    {section.cell.type === CELL_TYPES.INDICATOR && <IndicatorCell key={"IndicatorCell" + sectionIndex} label={section.cell.identifiers.label} identifiers={this.props} data={{}} cellSubType={section.cell.subType} />}
                                </div>
                            </div>
                        </Fragment>
                    );
                })}
            </Fragment>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { alerts } = state.alertReducer;
    const { user } = state.userReducer;
    return { alerts, user };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getAlerts,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(SimpleList));

export { hoc as SimpleList };
