// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import FeatherIcon from "feather-icons-react";
import { EditableInput } from "../inputs/EditableInput";
import format from "../../../helpers/formatter";
import { Loading } from "../loading/Loading";
import ErrorPopup from "../layout/ErrorPopup";
import { EditableInputTypes } from "../../enums/EditableInputTypes";

// COMPONENT
export class AsyncDataTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sections: props.sections,
            columns: props.columns,
            editing: false,
            columnGroups: props.columnGroups,
            dataPoints: props.dataPoints === undefined ? {} : props.dataPoints,
            loading: false,
            error: undefined,
        };

        this.updateRow = this.updateRow.bind(this);
        this.updateTableData = this.updateTableData.bind(this);
    }

    updateRow(event, rowIndex, dataIdentifier, type = "text") {
        let value = "";

        if (type === "checkbox") {
            value = event;
        } else {
            value = event.target.value;
            value = value.replace("$", "").replace(",", "").replace(".", "");
        }

        let rows = Object.assign([], this.state.rows);
        rows[rowIndex][dataIdentifier] = value;
        this.setState({ rows });
    }

    componentDidMount() {
        this.updateTableData();
        this.props.onRef(this);
    }

    // updateTableData() {
    //     let dataPointPromises = [];

    //     this.setState({ loading: true });
    //     this.props.sections.forEach((section, rowIndex) => {
    //         let dataPoints = section.rows
    //             .map(row => {
    //                 return row["Measure"];
    //             })
    //             .join();

    //         dataPointPromises.push( new Promise((resolve, reject) => {
    //             this.props.getDataPoints(dataPoints, rowIndex, 0, true, this.props.scenarioId, resolve, reject);
    //         }) )
    //     });

    //     Promise.all(dataPointPromises)
    //         .then(data => {
    //             this.setState({ loading: false });
    //         })
    //         .catch(error => {
    //             console.log('An error occurred loading the data table:', error);
    //             this.setState({ error: error.response.data, loading: false });

    //             setTimeout(
    //                 function() {
    //                     this.setState({ error: undefined });
    //                 }.bind(this),
    //                 3000
    //             );
    //         });
    // }

    updateTableData() {
        let dataPointPromises = [];

        this.setState({ loading: true });
        this.props.sections.forEach((section, rowIndex) => {
            this.props.columns.forEach((column) => {
                if (column.calculated) {
                    let dataPoints = section.rows
                        .map((row) => {
                            return row[column.dataIdentifier];
                        })
                        .join();

                    let dataPointPromise = new Promise((resolve, reject) => {
                        this.props.getDataPoints(dataPoints, rowIndex, column.index, column.incremental, column.scenarioId, resolve, reject);
                    });

                    dataPointPromises.push(dataPointPromise);
                }
            });
        });

        Promise.all(dataPointPromises)
            .then((data) => {
                this.setState({ loading: false });
            })
            .catch((error) => {
                console.log("An error occurred loading the data table:", error);
                this.setState({ error: error.response.data, loading: false });

                setTimeout(
                    function () {
                        this.setState({ error: undefined });
                    }.bind(this),
                    3000
                );
            });
    }

    componentDidUpdate() {
        if (JSON.stringify(this.props.sections) !== JSON.stringify(this.state.sections)) {
            this.setState({ sections: this.props.sections });
        }

        if (JSON.stringify(this.props.columns) !== JSON.stringify(this.state.columns)) {
            this.setState({ columns: this.props.columns });
            this.updateTableData();
        }

        if (JSON.stringify(this.props.columnGroups) !== JSON.stringify(this.state.columnGroups)) {
            this.setState({ columnGroups: this.props.columnGroups });
        }

        if (JSON.stringify(this.props.dataPoints) !== JSON.stringify(this.state.dataPoints)) {
            this.setState({ dataPoints: this.props.dataPoints });
        }
    }

    render() {
        let totalColumnActive =
            this.state.columns.filter((column) => {
                return column.total;
            }).length > 0;
        let columnTotals = {};

        return (
            <div className="data-table-container">
                <ErrorPopup showing={this.state.error !== undefined} errorText={this.state.error} />
                <div className="data-table">
                    <div className="data-table-grouping">
                        {this.state.hasOwnProperty("columnGroups") &&
                            this.state.columnGroups !== undefined &&
                            this.state.columnGroups.map((columnGroup, index) => {
                                let startColumn = columnGroup.column.start;
                                let endColumn = columnGroup.column.end;

                                let widthOfColumns = 0;
                                for (let columnIndex = startColumn; columnIndex < endColumn; columnIndex++) {
                                    widthOfColumns += this.state.columns[columnIndex].width;
                                }

                                return (
                                    <div className={"data-table-section-group"} key={index} style={{ minWidth: widthOfColumns * 100 + "px" }}>
                                        {columnGroup.name}
                                    </div>
                                );
                            })}
                    </div>
                    <div className="data-table-header">
                        {this.state.columns.map((column, index) => {
                            return (
                                <div key={index} className={"data-table-header-title" + (index === 0 ? " pad-left" : "") + (column.align === "center" ? " center" : column.align === "left" ? " left" : " right")} key={index} style={{ minWidth: column.width * 100 + "px" }}>
                                    {column.name}
                                </div>
                            );
                        })}
                    </div>
                    {this.state.sections.map((section, sectionIndex) => {
                        return (
                            <div key={sectionIndex} className="table-section-container">
                                <div className="table-section-header">
                                    {section.name}{" "}
                                    {section.hasOwnProperty("collapsed") && (
                                        <div className="collapse-icon" onClick={() => this.props.toggleCollapse()}>
                                            {section.collapsed ? <FeatherIcon size={25} icon="maximize" /> : <FeatherIcon size={25} icon="minimize" />}
                                        </div>
                                    )}
                                </div>
                                {this.state.loading ? (
                                    <div className="data-table-loader">
                                        <Loading small />
                                        <div className="data-table-loader-text">Loading {section.name.toLowerCase()} </div>
                                    </div>
                                ) : (
                                        section.rows.map((row, rowIndex) => {
                                            return (
                                                <div key={rowIndex}>
                                                    {row.hidden ? null : (
                                                        <div key={rowIndex} className={"data-table-row-container" + (row.rowType !== undefined ? ` ${row.rowType}` : "") + (section.collapsed ? " collapsed" : "")}>
                                                            <div className="data-table-row">
                                                                {this.state.columns.map((column, columnIndex) => {
                                                                    let value = row[column.dataIdentifier];
                                                                    let calculationStatus = {
                                                                        fetching: true,
                                                                        fetched: false,
                                                                        failed: false,
                                                                    };

                                                                    if (column.total) {
                                                                        if (!columnTotals.hasOwnProperty(columnIndex)) {
                                                                            columnTotals[columnIndex] = 0;
                                                                        }

                                                                        if (value !== undefined) {
                                                                            let newTotal = columnTotals[columnIndex] + parseFloat(value);
                                                                            if (!isNaN(newTotal)) {
                                                                                columnTotals[columnIndex] = newTotal;
                                                                            }
                                                                        }
                                                                    }

                                                                    if (column.calculated) {
                                                                        if (this.state.dataPoints) {
                                                                            if (this.state.dataPoints.hasOwnProperty(row[this.state.columns[0].dataIdentifier])) {
                                                                                let dataPointIdentifier = row[this.state.columns[0].dataIdentifier];
                                                                                let dataPoint = this.state.dataPoints[dataPointIdentifier][column.identifier];

                                                                                // If the value is defined.
                                                                                if (dataPoint !== undefined) {
                                                                                    dataPoint = dataPoint[column.scenarioId];

                                                                                    if (dataPoint !== undefined) {
                                                                                        if (row.hasOwnProperty("format") && column.name !== "Escalators") {
                                                                                            if (!dataPoint.data) {
                                                                                                value = format(0, { type: row.format.type, decimalPlaces: row.format.decimalPlaces });
                                                                                            } else {
                                                                                                value = format(dataPoint.data, { type: row.format.type, decimalPlaces: row.format.decimalPlaces });
                                                                                            }
                                                                                        } else if (column.name !== "Escalators") {
                                                                                            value = dataPoint.data;
                                                                                        } else {
                                                                                            value = <span className="escalator-text">{dataPoint.data}</span>;
                                                                                        }

                                                                                        // If the status indicators are present.
                                                                                        if (dataPoint.hasOwnProperty("fetched") && dataPoint.hasOwnProperty("failed") && dataPoint.hasOwnProperty("fetching")) {
                                                                                            calculationStatus = dataPoint;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    let calculatedField = calculationStatus.fetched ? value : calculationStatus.fetching ? <Loading small /> : calculationStatus.failed ? <div>Failed</div> : "";

                                                                    /* Change the odd-even colouring, when we are only showing the uncollapsed rows. */
                                                                    let totalRows = 0;
                                                                    if (section.collapsed) {
                                                                        totalRows = section.rows.filter((row) => {
                                                                            return row.rowType === "total";
                                                                        });
                                                                        rowIndex = totalRows.indexOf(row);
                                                                    }

                                                                    let active = false;
                                                                    if (typeof this.props.graphedDataPoints === "object") {
                                                                        active = this.props.graphedDataPoints.hasOwnProperty(row[column.dataIdentifier]);
                                                                    }

                                                                    var val = 50;
                                                                    if (column.align === "left") {
                                                                        val = 300;
                                                                    }
                                                                    return (
                                                                        <div key={columnIndex} style={{ minWidth: column.width * val + "px" }} className={"input-cell" + (rowIndex % 2 === 0 ? " odd" : " even") + (columnIndex === 0 ? " left" : "")}>
                                                                            <div className={"cash-flow-data-table-cell" + (column.align === "center" ? " center" : column.align === "left" ? " left" : " right")}>
                                                                                <Fragment>
                                                                                    <div className="cell-grab-handle" />

                                                                                    {columnIndex === 0 && section.graphable && (
                                                                                        <div onClick={() => this.props.toggleDataPoint(row[column.dataIdentifier], row)} className={"pin-to-chart" + (active ? " active" : "")}>
                                                                                            <FeatherIcon icon="bar-chart-2" />
                                                                                        </div>
                                                                                    )}

                                                                                    <EditableInput type={EditableInputTypes.STANDARD} editable={this.state.editing} readonlyLabel={column.calculated ? calculatedField : row[column.dataIdentifier]} textAlign={column.calculated ? "right" : "left"}>
                                                                                        {value}
                                                                                    </EditableInput>
                                                                                </Fragment>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })
                                    )}
                            </div>
                        );
                    })}
                    {totalColumnActive && (
                        <div className="data-table-total-row">
                            {this.state.columns.map((column, index) => {
                                let max = 0;
                                let min = 0;

                                if (column.guidance) {
                                    if (column.guidance.max) {
                                        max = column.guidance.max;
                                    }

                                    if (column.guidance.min) {
                                        min = column.guidance.min;
                                    }
                                }

                                return (
                                    <div className={"data-table-total-cell" + (column.guidance ? (columnTotals[index] <= max && columnTotals[index] >= min ? "" : " danger") : "") + (column.align === "center" ? " center" : column.align === "left" ? " left" : " right")} key={index} style={{ minWidth: column.width * 100 + "px" }}>
                                        {columnTotals[index]}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
