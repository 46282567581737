// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import queryString from "query-string";
import { Button } from "reactstrap";

// UI
import { CELL_TYPES } from "../CellTypes";
import { NormalCell } from "../../../containers/table/cells/NormalCell";
import { Checkbox } from "../../../containers/inputs/Checkbox";
import { IndicatorCell } from "../../../containers/table/cells/IndicatorCell";
import { DetailCell } from "../../../containers/table/cells/DetailCell";
import { NonFinancialIncentivesSummary } from "../products/NonFinancialIncentivesSummary";
import { ProductGroupsSummary } from "../products/productGroup/ProductGroupsSummary";
import { ContractCell } from "../../../containers/table/cells/ContractCell";
import { TemplateCell } from "../../../containers/table/cells/TemplateCell";

// COMPONENT
class ResponsiveList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: false,
            editingShowing: props.editingShowing,
            selectedItems: {},
        };

        this.handleFirstItemSelection = this.handleFirstItemSelection.bind(this);
    }

    getCell(type, subType, data, identifiers, isLastCell) {
        switch (type) {
            case CELL_TYPES.NORMAL:
                return <NormalCell isLastCell={isLastCell} key={identifiers} identifiers={identifiers} data={data} />;
            case CELL_TYPES.INDICATOR:
                return <IndicatorCell key={identifiers} identifiers={identifiers} data={data} cellSubType={subType} />;
            case CELL_TYPES.PRODUCT_SUMMARY:
                return <ProductGroupsSummary key={identifiers} />;
            case CELL_TYPES.NON_FINANCIAL_SUMMARY:
                return <NonFinancialIncentivesSummary key={identifiers} />;
            case CELL_TYPES.DETAIL:
                return <DetailCell key={identifiers} identifiers={identifiers} data={data} />;
            case CELL_TYPES.CONTRACT:
                return <ContractCell isLastCell={isLastCell} key={identifiers} identifiers={identifiers} data={data} />;
            case CELL_TYPES.TEMPLATE:
                return <TemplateCell key={identifiers} identifiers={identifiers} data={data} />;
        }
    }

    selectItem(element, section, sectionIndex, event) {
        // On Change
        let selectedItems = Object.assign({}, this.state.selectedItems);

        if (selectedItems[element[section.identifier]]) {
            if (selectedItems[element[section.identifier]].checked) {
                selectedItems[element[section.identifier]] = { checked: false, section: sectionIndex };
            } else {
                selectedItems[element[section.identifier]] = { checked: true, section: sectionIndex };
            }
        } else {
            selectedItems[element[section.identifier]] = { checked: true, section: sectionIndex };
        }

        // If we are using the floating action buttons to delete...
        if (this.props.showDelete && this.props.hideDelete) {
            if (
                Object.values(selectedItems).filter((item) => {
                    return item.checked;
                }).length > 0
            ) {
                this.props.showDelete();
            } else {
                this.props.hideDelete();
            }
        }

        this.setState({ selectedItems });
    }

    componentDidUpdate(prevProps) {
        if (this.state.editingShowing !== this.props.editingShowing) {
            if (!this.props.editingShowing) {
                // Empty the selected items when editing ends...
                this.setState({ selectedItems: {} });
            }

            this.setState({ editingShowing: this.props.editingShowing });
        }

        // Once the data in the sections has loaded, select the first item of the first section
        if (prevProps.sections[0].items.length === 0 && this.props.sections[0].items.length !== 0) {
            this.handleFirstItemSelection();
        }
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }

        this.handleFirstItemSelection();
    }

    /**
     * Selects the first item in the ResponsiveList by manually triggering its handle click method
     */
    handleFirstItemSelection() {
        const query = queryString.parse(this.props.location.search);

        /* Don't auto-route if explicitly told not to, or if there is already a section attached. */
        // Auto-select the first item...
        if (!this.props.disableAutoRoute || this.props.disableAutoRoute === undefined) {
            let alreadyOnSection = false;

            if (query) {
                if (query.section) {
                    alreadyOnSection = true;
                }
            }

            if (this.props.sections && !alreadyOnSection) {
                if (this.props.sections.length > 0) {
                    if (this.props.sections[0].items) {
                        if (this.props.sections[0].items.length > 0) {
                            let section = this.props.sections[0];
                            let item = this.props.sections[0].items[0];

                            // If this is the products page, we don't want to auto select the first item
                            // if one was already selected (already exists in the url)
                            if (this.props.match.url.includes("contracts") && this.props.match.url.includes("products")) {
                                if (!this.props.match.params.id) {
                                    this.props.handleClick(item[section.identifier], section.sectionIdentifier);
                                }
                            } else {
                                this.props.handleClick(item[section.identifier], section.sectionIdentifier);
                            }
                        }
                    }
                }
            }
        }
    }

    getSelectedItems() {
        return Object.keys(this.state.selectedItems)
            .map((key) => {
                return { id: key, ...this.state.selectedItems[key] };
            })
            .filter((item) => {
                let checked = item.checked;
                if (checked) {
                    return true;
                }
                return false;
            });
    }

    render() {
        let activeId = this.props.match.params.id;
        let activeSection = queryString.parse(this.props.location.search).section;

        let activeIndex = { sectionIndex: -1, itemIndex: -1 };
        this.props.sections.forEach((section, si) => {
            if (!section.isSummary) {
                section.items.forEach((item, ii) => {
                    if (item[section.identifier] === activeId) {
                        activeIndex = { sectionIndex: si, itemIndex: ii };
                    }
                });
            }
        });

        let selectedItems = this.getSelectedItems();

        return (
            <div>
                <Fragment>
                    {!this.props.hideSectionHeader && (
                        <div className={"section-header underlined light " + (this.state.editingShowing && selectedItems.length > 0 ? "" : "hidden")}>
                            {this.props.actions.length > 0 && (
                                <div className={"rl-action-bar"}>
                                    {this.state.editingShowing && selectedItems.length > 0 && this.props.actions.includes("Delete") && (
                                        <Fragment>
                                            <div>
                                                {selectedItems.length} {`item${selectedItems.length > 1 ? "s" : ""}`} selected
                                            </div>
                                            <Button
                                                onClick={() => {
                                                    this.props.handleDelete(selectedItems);
                                                    this.setState({ selectedItems: {}, editing: false });
                                                }}
                                                color="link"
                                            >
                                                Delete
                                            </Button>
                                        </Fragment>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                    {this.props.sections.map((section, sectionIndex) => {
                        if (section.isSummary) {
                            return this.getCell(section.cell.type, "", [], {});
                        } else {
                            return (
                                <Fragment key={sectionIndex}>
                                    <div>
                                        {!this.props.hideSectionHeader && <div className="rl-section-label">{section.sectionLabel}</div>}
                                        {section.items.length !== 0 ? (
                                            <div>
                                                {section.items.map((element, itemIndex) => {
                                                    return (
                                                        <div
                                                            key={itemIndex}
                                                            onClick={() => {
                                                                let clickable = this.props.clickable !== undefined ? this.props.clickable : true;
                                                                if (!this.state.editingShowing && clickable) {
                                                                    this.props.handleClick(element[section.identifier], section.sectionIdentifier);
                                                                }

                                                                if (this.state.editingShowing && clickable) {
                                                                    this.selectItem(element, section, sectionIndex, event);
                                                                }
                                                            }}
                                                            className={"rl-cell-container" + (activeSection === section.sectionIdentifier && activeIndex.sectionIndex === sectionIndex && activeIndex.itemIndex === itemIndex && !this.state.editingShowing ? " rl-active-item" : "")}
                                                        >
                                                            <div className={"rl-cell-indicator" + (activeSection === section.sectionIdentifier && activeIndex.sectionIndex === sectionIndex && activeIndex.itemIndex === itemIndex && !this.state.editingShowing ? " active" : "")}></div>
                                                            <div className={"rl-feature" + (this.state.editingShowing ? " active" : "")}>
                                                                {this.state.editingShowing && (
                                                                    <Checkbox
                                                                        observe
                                                                        checked={
                                                                            selectedItems.findIndex((item) => {
                                                                                return item.id === element[section.identifier];
                                                                            }) >= 0
                                                                        }
                                                                        className="rl-check"
                                                                    />
                                                                )}
                                                            </div>
                                                            {this.getCell(section.cell.type, section.cell.subType, element, section.cell.dataIdentifiers, itemIndex >= section.items.length - 1)}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ) : (
                                                this.props.showPlaceholder && (
                                                    <div className="empty-view">
                                                        <img alt={"empty"} className="empty-icon" src={require("../../../../images/empty-placeholder.png")} />
                                                        <h4>{this.props.placeholderTitle !== undefined ? this.props.placeholderTitle : "No data"}</h4>
                                                    </div>
                                                )
                                            )}
                                    </div>
                                    {section.addAction !== undefined && (
                                        <div onClick={section.addAction} className={"rl-row button"}>
                                            <Button block>{section.addLabel}</Button>
                                        </div>
                                    )}
                                </Fragment>
                            );
                        }
                    })}
                </Fragment>
            </div>
        );
    }
}

ResponsiveList.propTypes = {
    history: PropTypes.object.isRequired,
};

const hoc = withRouter(ResponsiveList);

// EXPORT COMPONENT
export { hoc as ResponsiveList };
