// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FeatherIcon from "feather-icons-react";

// IMPORT PROJECT REFERENCES
import { getContract } from "../../../state/actions/ContractActions";

// UI
import { Loading } from "../../../containers/loading/Loading";
import Modalised from "../../../containers/layout/Modalised";
import { Checkbox } from "../../../containers/inputs/Checkbox";

export const FormContext = React.createContext();

// COMPONENT
class ScenarioChooser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            contract: {},
            scenarios: [],
            showing: false,
        };
    }

    componentWillMount() {
        this.setState({ loading: true });
    }

    componentDidMount() {
        this.props.getContract(this.props.match.params.contractId, (_) => {
            this.setState({ loading: false });
        });
    }

    componentDidUpdate() {
        if (JSON.stringify(this.state.contract) !== JSON.stringify(this.props.contract)) {
            this.setState({ contract: this.props.contract });
        }

        if (this.state.showing !== this.props.showing) {
            this.setState({ showing: this.props.showing });
        }

        if (this.state.scenarios !== this.props.scenarios) {
            this.setState({ scenarios: this.props.scenarios });
        }
    }

    render() {
        let activeScenarioId = this.props.match.params.scenarioId;

        /* Scenario Options */
        let scenarios = [];
        if (this.state.contract.scenarios) {
            scenarios = this.state.contract.scenarios.filter((scenario) => {
                return activeScenarioId !== scenario.scenarioId;
            });
        }

        return (
            <Modalised
                title={"Choose a Scenario"}
                handleClose={() => {
                    this.props.handleClose();
                }}
                showing={this.state.showing}
                action={
                    <div
                        onClick={() => {
                            this.props.handleClose();
                        }}
                        className="text-link"
                    >
                        <FeatherIcon icon="x" />
                    </div>
                }
            >
                {this.state.loading ? (
                    <Loading />
                ) : (
                        <div>
                            {scenarios.length === 0 ? (
                                <div className="empty-view" style={{ margin: "1em 0.5em" }}>
                                    <img alt={"empty"} className="empty-icon" src={require("../../../../images/empty-placeholder.png")} />
                                    <h5> No other scenarios created </h5>
                                </div>
                            ) : (
                                    <div>
                                        {this.props.firstOptionNone && (
                                            <div onClick={() => this.props.handleSelection(null)} className={"material-list top"}>
                                                <div className="material-list-checkbox">
                                                    <Checkbox checked={this.state.scenarios.includes(null)} handleClick={() => { }} />
                                                </div>

                                                {"None"}
                                            </div>
                                        )}

                                        {scenarios.map((scenario, index) => {
                                            let position = this.props.firstOptionNone ? (index === scenarios.length - 1 ? "bottom" : "") : scenarios.length === 1 ? "top bottom" : index === 0 ? "top" : index === scenarios.length - 1 ? "bottom" : "";

                                            return (
                                                <div key={index} onClick={() => this.props.handleSelection(scenario.scenarioId)} className={`material-list ${position}`}>
                                                    <div className="material-list-checkbox">
                                                        <Checkbox checked={this.state.scenarios.includes(scenario.scenarioId)} handleClick={() => { }} />
                                                    </div>

                                                    {scenario.scenarioName}
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                        </div>
                    )}
            </Modalised>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { contract } = state.contractReducer;
    return { contract };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getContract }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(ScenarioChooser));

// EXPORT COMPONENT
export { hoc as ScenarioChooser };
