// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import * as Yup from "yup";

// ACTIONS
import { createContract } from "../../../components/state/actions/ContractActions";
import { getGeneralDetails } from "../../state/actions/GeneralDetailsActions";

// UI
import { Col, Row, FormGroup, Label } from "reactstrap";
import { Checkbox } from "../../containers/inputs/Checkbox";
import { Slideable } from "../../containers/carousel/Slideable";
import { SimpleContractTemplateList } from "./SimpleContractTemplateList";
import { SimpleContractCopyList } from "./SimpleContractCopyList";
import { ValidatedInput } from "../../containers/validation/ValidatedInput";
import { Message } from "../../containers/layout/Message";
import { CCAInput } from "../../containers/inputs/CCAInput";
import { CCAInputTypes } from "../../enums/CCAInputTypes";
import NumberFormat from "react-number-format";
import moment from "moment";

const contractTypes = [
    { name: "New Blank contract", id: "NEW" },
    /* { name: "New from Template", id: "TEMPLATE" }, */ { name: "Copy Existing", id: "COPY" },
];

const validationSchema = Yup.object().shape({
    contractName: Yup.string().required("Contract Name required"),
    salesOffice: Yup.string().required("Sales Office required"),
    subtradeChannel: Yup.string().required("Sub-trade Channel required"),
    contractStartDate: Yup.string()
        .required("Contract Start Date required")
        .matches(/^[0-3]{1}[0-9]{1}\/[0-1]{1}[0-9]{1}\/20[0-9]{2}$/, "Enter a valid date"),
    contractDuration: Yup.string().required("Contract Duration required"),
});

// COMPONENT
class NewContractModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            variablePages: undefined,
            checked: "",
            selectedId: undefined,
            currentPage: undefined,
            slide: false,
            messageActive: false,
            messageError: false,
            saving: false,
        };

        this.handleItemClicked = this.handleItemClicked.bind(this);
        this.slide = this.slide.bind(this);
        this.getGeneralDetails = this.getGeneralDetails.bind(this);

        this.templateList = <SimpleContractTemplateList handleItemClicked={this.handleItemClicked} slide={this.slide} />;
        this.existingList = <SimpleContractCopyList handleItemClicked={this.handleItemClicked} slide={this.slide} />;
    }

    componentDidMount() {
        this.getGeneralDetails();
    }

    getGeneralDetails() {
        this.props.getGeneralDetails((success) => { });
    }

    handleTypeClicked(type) {
        let variablePages;

        if (type === "TEMPLATE") {
            variablePages = [this.templateList];
        } else if (type === "COPY") {
            variablePages = [this.existingList];
        }

        this.setState({
            checked: type,
            variablePages,
        });

        this.slide();
    }

    slide() {
        this.setState({
            slide: !this.state.slide,
        });
    }

    handleItemClicked(id) {
        this.setState({
            selectedId: id,
            slide: !this.state.slide,
        });
    }

    render() {
        return (
            <div className="modalised-content">
                <Message active={this.state.messageActive} message="Error creating new contract" error={this.state.messageError} />
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        contractName: "",
                        contractStartDate: moment().format("DD/MM/YYYY"),
                        contractDuration: 1,
                        salesOffice: undefined,
                        subtradeChannel: undefined,
                    }}
                    onSubmit={(values) => {
                        if (!this.state.saving) {
                            this.setState({ saving: true });
                            this.props.createContract(values, this.props.user, this.state.checked, this.state.selectedId, (success, response) => {
                                this.setState({ saving: false });
                                if (success) {
                                    // Get the contract ID from the contract.
                                    let contractId = response.data.contractId;
                                    let scenarioId = response.data.baseScenario;

                                    this.props.onClose();
                                    this.backToStart();
                                    this.props.history.push(`/contracts/${contractId}/${scenarioId}/details?section=generalDetails`);
                                } else {
                                    this.setState({ messageError: true, messageActive: true });
                                    setTimeout(() => {
                                        this.setState({ messageError: false, messageActive: false });
                                    }, 2000);
                                }
                            });
                        }
                    }}
                    render={({ values, handleSubmit, handleChange, handleBlur, setFieldValue, errors, touched }) => (
                        <Fragment>
                            <Slideable
                                backToStart={(click) => (this.backToStart = click)}
                                slide={this.state.slide}
                                handleSubmit={handleSubmit}
                                submitLoading={this.state.saving}
                                submitText="Create Contract"
                                pages={[
                                    <Row form>
                                        <Col md={12}>
                                            {contractTypes.map((type, index) => {
                                                return (
                                                    <div onClick={() => this.handleTypeClicked(type.id)} key={index} className="material-list">
                                                        <div className="material-list-checkbox">
                                                            <Checkbox
                                                                editing
                                                                observe
                                                                checked={this.state.checked === type.id}
                                                                handleClick={(checked) => {
                                                                    this.handleTypeClicked(checked, type.id);
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="material-list-details">
                                                            <div className="material-list-left">{type.name}</div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Col>
                                    </Row>,
                                    this.state.variablePages,
                                    <Row form>
                                        <Col md={12}>
                                            {this.props.fetchedGeneralDetails && (
                                                <Fragment>
                                                    <FormGroup>
                                                        <Label for="contractName">Contract Name</Label>

                                                        <ValidatedInput large touched={touched.contractName} error={errors.contractName} field="contractName" setFieldValue={setFieldValue} name="contractName" value={values.contractName} type="text" id="contractName" placeholder="e.g. Fred's Takeaways" />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <div>
                                                            <Label for="contractName">Contract Start Date</Label>
                                                        </div>
                                                        <div>
                                                            <NumberFormat
                                                                class="bordered-input"
                                                                format="##/##/####"
                                                                placeholder="DD/MM/YYYY"
                                                                mask={["D", "D", "M", "M", "Y", "Y", "Y", "Y"]}
                                                                onValueChange={(value) => {
                                                                    setFieldValue("contractStartDate", value.formattedValue);
                                                                }}
                                                                value={values.contractStartDate}
                                                            />
                                                        </div>

                                                        <div className="validation-error">{touched.contractStartDate && <div>{errors.contractStartDate}</div>}</div>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <div>
                                                            <Label for="contractName">Duration</Label>
                                                        </div>
                                                        <div>
                                                            <NumberFormat
                                                                class="bordered-input"
                                                                placeholder="Contract Length"
                                                                suffix={" year" + (values.contractDuration > 1 || values.contractDuration === 0 || values.contractDuration === undefined ? "s" : "")}
                                                                decimalScale={0}
                                                                allowNegative={false}
                                                                defaultValue={0}
                                                                onValueChange={(value) => {
                                                                    setFieldValue("contractDuration", value.floatValue);
                                                                }}
                                                                value={values.contractDuration}
                                                            />

                                                            <div className="validation-error">{touched.contractDuration && <div>{errors.contractDuration}</div>}</div>
                                                        </div>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <div>
                                                            <Label for="subtradeChannel"> Sub-trade Channel </Label>
                                                        </div>

                                                        <CCAInput
                                                            firstItemNone
                                                            type={CCAInputTypes.SELECT}
                                                            name="select"
                                                            value={values.subtradeChannel}
                                                            onChange={(e) => {
                                                                setFieldValue("subtradeChannel", e.target.value);
                                                            }}
                                                            options={Object.keys(this.props.generalDetails.subTrade).map((key) => {
                                                                return { saveChoice: key, displayChoice: this.props.generalDetails.subTrade[key].name };
                                                            })}
                                                            optionsAreObjects
                                                            css={""}
                                                        />

                                                        <div className="validation-error">{touched.subtradeChannel && <div>{errors.subtradeChannel}</div>}</div>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <div>
                                                            <Label for="salesOffice"> Sales Office </Label>
                                                        </div>

                                                        <CCAInput
                                                            firstItemNone
                                                            type={CCAInputTypes.SELECT}
                                                            name="select"
                                                            value={values.salesOffice}
                                                            onChange={(e) => {
                                                                setFieldValue("salesOffice", e.target.value);
                                                            }}
                                                            options={Object.values(this.props.generalDetails.salesOffice).map((value) => {
                                                                return value;
                                                            })}
                                                            css={""}
                                                        />

                                                        <div className="validation-error">{touched.salesOffice && <div>{errors.salesOffice}</div>}</div>
                                                    </FormGroup>
                                                </Fragment>
                                            )}
                                        </Col>
                                    </Row>,
                                ]}
                            />
                        </Fragment>
                    )}
                />
            </div>
        );
    }
}

NewContractModal.propTypes = {
    createContract: PropTypes.func.isRequired,
    fetching: PropTypes.bool.isRequired,
    fetched: PropTypes.bool.isRequired,
    failed: PropTypes.bool.isRequired,
};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { fetching, fetched, failed } = state.contractReducer;
    const { user } = state.userReducer;
    const { fetchingGeneralDetails, fetchedGeneralDetails, failedGeneralDetails, generalDetails } = state.generalDetailsReducer;

    return {
        fetching,
        fetched,
        failed,
        user,
        fetchingGeneralDetails,
        fetchedGeneralDetails,
        failedGeneralDetails,
        generalDetails,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            createContract,
            getGeneralDetails,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(NewContractModal));

// EXPORT COMPONENT

export { hoc as NewContractModal };
