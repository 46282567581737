// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// IMPORT PROJECT REFERENCES
import { getTemplates } from "../../state/actions/TemplateActions";

// UI
import { CELL_TYPES } from "./CellTypes";
import { ContextualSidebar } from "../../containers/layout/ContextualSidebar";
import { MasterDetailRouter } from "../../containers/layout/MasterDetailRouter";
import { PageTitle } from "../../containers/layout/PageTitle";
import { SimpleList } from "./lists/SimpleList";
import { ProgrammeDetailsPage } from "./ProgrammeDetailsPage";

// COMPONENT
class ProgrammesPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleTypeClick = this.handleTypeClick.bind(this);
    }

    handleTypeClick(sectionIdentifier) {
        this.props.history.push(`?section=${sectionIdentifier}`);
    }

    render() {
        let sections = [
            {
                page: <ProgrammeDetailsPage />,
                sectionIdentifier: "all-programmes",
                sectionHeader: "",
                cell: {
                    type: CELL_TYPES.NORMAL,
                    identifiers: { label: "All Programmes" },
                },
            },
        ];

        return (
            <Fragment>
                <ContextualSidebar pageTitle={<PageTitle title={"Programmes"} />} left={<SimpleList handleClick={this.handleTypeClick} sections={sections} />} right={<MasterDetailRouter sections={sections} />} />
            </Fragment>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { templates, fetchingTemplates, fetchedTemplates, failedTemplates } = state.templateReducer;
    return { templates, fetchingTemplates, fetchedTemplates, failedTemplates };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getTemplates }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(ProgrammesPage));

// EXPORT COMPONENT
export { hoc as ProgrammesPage };
