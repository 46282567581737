// IMPORT PACKAGE REFERENCES
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { EditableInputTypes } from "../../../enums/EditableInputTypes";

// UI
import { IndicatorTile } from "./IndicatorTile";

// COMPONENT
class PaymentIndicatorRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            indicators: [],
        };

        this.generateIndicators = this.generateIndicators.bind(this);
    }

    generateIndicators() {
        let indicators = [];
        let paymentId = this.props.data.paymentId;
        indicators.push({ title: "Amount", value: this.props.payments[paymentId]["amount"], type: EditableInputTypes.DOLLAR });
        indicators.push({ title: "Annual", value: this.props.payments[paymentId]["annuallyRecurring"] ? "YES" : "NO", type: EditableInputTypes.BOOLEAN });
        this.setState({ indicators: indicators });
    }

    componentDidMount() {
        this.generateIndicators();
    }

    componentDidUpdate(prevProps) {
        let paymentId = this.props.data.paymentId;
        if (JSON.stringify(this.props.payments[paymentId]) !== JSON.stringify(prevProps.payments[paymentId])) {
            this.generateIndicators();
        }
    }

    render() {
        return (
            <div className="indicator-row">
                {this.state.indicators.map((data, index) => {
                    return <IndicatorTile key={index} title={data.title} value={data.value} type={data.type} />;
                })}
            </div>
        );
    }
}

PaymentIndicatorRow.propTypes = {
    payments: PropTypes.object.isRequired,
};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { payments, novelties } = state.paymentReducer;
    const combined = { ...payments, ...novelties };
    return { payments: combined };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentIndicatorRow));

// EXPORT COMPONENT

export { hoc as PaymentIndicatorRow };
