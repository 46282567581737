import axios from "axios";

// Import Base URL
import { API_ROOT } from "../../../config/config";

// SAVE CUSTOMER TARGETS
export const GET_CUSTOMER_TARGETS_PENDING = "GET_CUSTOMER_TARGETS_PENDING";
export const GET_CUSTOMER_TARGETS_FULFILLED = "GET_CUSTOMER_TARGETS_FULFILLED";
export const GET_CUSTOMER_TARGETS_FAILED = "GET_CUSTOMER_TARGETS_FAILED";

// ACTION GENERATORS
const getCustomerTargets = (contractId, scenarioId) => (dispatch) => {
    dispatch({
        type: GET_CUSTOMER_TARGETS_PENDING,
    });

    axios
        .get(`${API_ROOT}/contracts/${contractId}/${scenarioId}/customerTargets`)
        .then((response) => {
            dispatch({
                type: GET_CUSTOMER_TARGETS_FULFILLED,
                payload: response.data,
            });
        })
        .catch((error) => {
            console.log("An error occurred requesting customer targets", error);
            dispatch({
                type: GET_CUSTOMER_TARGETS_FAILED,
            });
        });
};

// EXPORT ACTIONS
export { getCustomerTargets };
