// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// UI
import { IndicatorTile } from "./IndicatorTile";
import { EditableInputTypes } from "../../../enums/EditableInputTypes";

// COMPONENT
class ProductIndicatorRow extends React.Component {
    constructor(props) {
        super(props);
    }

    camelCaseToSentenceCase(string) {
        var result = string.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    generateIndicatorTiles() {
        let discounts = this.props.productGroups[this.props.data.productGroupId].discounts;

        if (discounts) {
            return Object.keys(discounts)
                .filter((discountKey) => {
                    let discount = discounts[discountKey];
                    return discount.hasOwnProperty("fixedValue");
                })
                .map((discountKey, index) => {
                    let discount = discounts[discountKey];
                    let type = "";

                    if (discount.hasOwnProperty("fixedValue")) {
                        if (discount.fixedValue.length > 0) {
                            if (!discount.active) {
                                if (discount.type === "OP11" || discount.type === "OP14" || discount.type === "OP13" || discount.type === "OP15") {
                                    type = EditableInputTypes.DOLLAR;
                                } else {
                                    type = EditableInputTypes.PERCENT;
                                }
                            }

                            let discountName = "";

                            switch (discount.type) {
                                case "OP11": {
                                    discountName = "Rebate";
                                    break;
                                }
                                case "OP12": {
                                    discountName = "Percent";
                                    break;
                                }
                                case "OP18":
                                case "OP13": {
                                    discountName = "Fixed Price";
                                    break;
                                }
                                case "OP14": {
                                    discountName = "Dollar Off";
                                    break;
                                }
                                case "OP15": {
                                    discountName = "PA Per Case";
                                    break;
                                }
                            }

                            return <IndicatorTile key={index} title={discountName} value={discount.fixedValue} type={type} />;
                        }
                    }
                });
        }

        return [];
    }

    render() {
        let indicatorTiles = this.generateIndicatorTiles();

        if (indicatorTiles.length > 0) {
            return (
                <div>
                    <div className="indicator-row">
                        {indicatorTiles.map((tile) => {
                            return tile;
                        })}
                    </div>
                </div>
            );
        } else {
            return <Fragment />;
        }
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { productGroups, productGroup } = state.productGroupReducer;
    return { productGroups, productGroup };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductIndicatorRow));

// EXPORT COMPONENT

export { hoc as ProductIndicatorRow };
