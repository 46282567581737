// IMPORT PACKAGE REFERENCES
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ContextPage } from "../../containers/layout/ContextPage";
import { ApprovalMatrixTable } from "./ApprovalMatrixTable";

// COMPONENT
class ApprovalMatrixPage extends React.Component {
    render() {
        return (
            <ContextPage fetching={false} fetched={true} title={"Approval Matrix"}>
                <ApprovalMatrixTable />
            </ContextPage>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { } = state.templateReducer;
    return {};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(ApprovalMatrixPage));

// EXPORT COMPONENT

export { hoc as ApprovalMatrixPage };
