// IMPORT PACKAGE REFERENCES
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { ResultCell } from "../../../../containers/table/cells/ResultCell";
import { getProductGroupsSummary } from "../../../../state/actions/ProductGroupActions";
import { getPayments } from "../../../../state/actions/PaymentActions";

// COMPONENT
class ProductGroupsSummary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            productGroups: props.productGroups,
            productGroupsSummary: {
                grossProfit: {
                    value: 0,
                    guidance: "normal",
                    advice: "",
                },
                totalPhysicalCases: {
                    value: 0,
                    guidance: "normal",
                    advice: "",
                },
                totalPaymentAllocation: {
                    value: 0,
                    guidance: "normal",
                    advice: "Total payment allocation must not exceed 100%",
                },
            },
        };
    }

    handleSummaryClicked(section, sectionType) {
        this.props.history.push(`?section=${section}&sectionType=${sectionType}`);
    }

    componentDidMount() {
        this.props.getProductGroupsSummary(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });

        /* 
            Retrieve payments to determine if payment allocation is required.

            Payment allocation is only required if there are 1 or more payments that need to
            be distributed across material groups being added to the contract.
        */
        this.props.getPayments(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.productGroupsSummary) !== JSON.stringify(this.state.productGroupsSummary)) {
            this.setState({ productGroupsSummary: this.props.productGroupsSummary });
        }

        let fetchSummary = false;

        if (JSON.stringify(this.state.productGroups) !== JSON.stringify(this.props.productGroups)) {
            fetchSummary = true;
            this.setState({ productGroups: this.props.productGroups });
        }

        if (fetchSummary) {
            this.props.getProductGroupsSummary(this.props.match.params.contractId, (success) => { });
        }
    }

    round2dp(num) {
        return Math.round((num + 0.00001) * 100) / 100;
    }

    render() {
        const query = queryString.parse(this.props.location.search);

        let hasProductGroups = Object.keys(this.props.productGroups).length > 0;

        let hasPaymentsToAllocation = false;
        if (typeof this.props.productGroups === "object") {
            hasPaymentsToAllocation = Object.keys(this.props.payments).length > 0;
        }

        if (hasProductGroups) {
            return (
                <div>
                    <div className="rl-section-label">Product Ranges Summary</div>

                    {hasPaymentsToAllocation && (
                        <div className={"rl-cell-container clickable " + (query.sectionType === "allocation" ? "rl-active-item" : "")}>
                            <ResultCell
                                showChart
                                chartData={[
                                    { name: "Allocated", value: this.state.productGroupsSummary.totalPaymentAllocation.value },
                                    { name: "Unallocated", value: 100 - this.state.productGroupsSummary.totalPaymentAllocation.value },
                                ]}
                                onClick={() => {
                                    this.handleSummaryClicked("productGroupSummary", "allocation");
                                }}
                                advice={this.state.productGroupsSummary.totalPaymentAllocation.advice}
                                guidance={this.state.productGroupsSummary.totalPaymentAllocation.guidance}
                                label="Payment Allocation"
                                value={this.round2dp(this.state.productGroupsSummary.totalPaymentAllocation.value) + "%"}
                            />
                        </div>
                    )}
                    <div className={"rl-cell-container"}>
                        <ResultCell guidance={this.state.productGroupsSummary.totalPhysicalCases.guidance} label="Total Physical Cases" value={this.state.productGroupsSummary.totalPhysicalCases.value} />
                    </div>
                </div>
            );
        } else {
            return <div></div>;
        }
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { productGroups, productGroupsSummary } = state.productGroupReducer;
    const { payments } = state.paymentReducer;
    return { productGroups, productGroupsSummary, payments };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getProductGroupsSummary, getPayments }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductGroupsSummary));

// EXPORT COMPONENT

export { hoc as ProductGroupsSummary };
