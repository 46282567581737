// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";

// IMPORT UI
import { AdditiveTable } from "../table/AdditiveTable";
import FeatherIcon from "feather-icons-react";

const ESCAPE_KEY = 27;

// COMPONENT
export class CustomDropdown extends React.Component {
    handleKeyDown = (event) => {
        switch (event.keyCode) {
            case ESCAPE_KEY:
                this.setState({ calloutShowing: false });
                break;
            default:
                break;
        }
    };

    constructor(props) {
        super(props);
        let activeCondition = { name: "Select a Condition" };

        if (props.selectedId !== undefined) {
            props.sections.forEach((section) => {
                activeCondition = section.elements.filter((element) => {
                    return element.id === props.selectedId;
                });

                if (activeCondition.length > 0) {
                    activeCondition = activeCondition[0];
                }
            });
        }

        this.state = {
            activeCondition: activeCondition,
            calloutShowing: props.calloutShowing,
        };

        this.toggleCallout = this.toggleCallout.bind(this);
        this.handleConditionSelected = this.handleConditionSelected.bind(this);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        document.addEventListener("click", this._handleDocumentClick, false);
        document.addEventListener("keydown", this.handleKeyDown.bind(this));
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
        document.removeEventListener("click", this._handleDocumentClick, false);
        document.removeEventListener("keydown", this.handleKeyDown.bind(this));
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ calloutShowing: false });
        }
    }

    toggleCallout() {
        this.setState({ calloutShowing: !this.state.calloutShowing });
    }

    handleConditionSelected(e, row) {
        this.props.onChange(row);
        this.setState({ activeCondition: row, calloutShowing: false });
    }

    render() {
        return (
            <div ref={this.setWrapperRef} className="dropdown-container">
                <div onClick={this.toggleCallout} className="inline-input dropdown">
                    <div className="dropdown-content">
                        {this.state.activeCondition.name}
                        <span className="dropdown-icon">
                            {" "}
                            <FeatherIcon size="18" icon="chevron-down" />
                        </span>
                    </div>
                </div>
                <div className={"dropdown-callout " + (this.state.calloutShowing ? "dropdown-active" : "")}>
                    {this.props.sections.map((section, index) => {
                        return (
                            <Fragment>
                                <div className="section-title">{section.title}</div>
                                <AdditiveTable save={this.props.save} handleConditionSelected={this.handleConditionSelected} activeCondition={this.state.activeCondition} rows={section.elements} type={section.title + " Condition"} />
                            </Fragment>
                        );
                    })}
                </div>
            </div>
        );
    }
}
