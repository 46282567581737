import React, { Fragment } from "react";
import ReactDOM from "react-dom";

export class HeaderAction extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hovered: false,
        };

        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }

    onMouseEnter() {
        this.setState({ hovered: true });
    }

    onMouseLeave() {
        setTimeout(() => {
            this.setState({ hovered: false });
        }, 50);
    }

    render() {
        let containerClasses = "header-action-container ";
        let headerActionId = this.props.id;
        if (this.props.showing || this.state.hovered) {
            containerClasses += " showing";
        }

        let container;
        let label;
        if (Array.isArray(this.props.label)) {
            if(Array.isArray(this.props.handleAction)){
                label = this.props.label.map((l, index) => {
                    return (
                        <div onClick={(e) => this.props.handleAction[index](l)} style={{backgroundColor: "#FE9B57"}}>
                            { l }
                        </div>
                    );
                });
            }else{
                label = this.props.label.map((l, index) => {
                    return (
                        <div onClick={(e) => this.props.handleAction(l)} style={{backgroundColor: "#FE9B57"}}>
                            { l }
                        </div>
                    );
                });
            }
            

            container = 
                <div 
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                    id={headerActionId}
                    className={containerClasses}
                    // style={{ height: '8em' }}
                >
                    { label }
                </div>
            ;
        } else {
            container = (
                <div onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} id={headerActionId} onClick={(e) => this.props.handleAction(this.props.label)} className={containerClasses}>
                    <div className="header-action-label">{this.props.label}</div>
                </div>
            );
        }

        return ReactDOM.createPortal(<Fragment>{container}</Fragment>, document.body);
    }
}
