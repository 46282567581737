// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// IMPORT PROJECT REFERENCES
import { getContracts, deleteContracts } from "../../state/actions/ContractActions";
import { prepareForContractSwitch } from "../../state/actions/GeneralActions";

// UI
import { CELL_TYPES } from "./CellTypes";
import { ContextualSidebar } from "../../containers/layout/ContextualSidebar";
import { MasterDetailRouter } from "../../containers/layout/MasterDetailRouter";
import { PageTitle } from "../../containers/layout/PageTitle";
import { SimpleList } from "./lists/SimpleList";
import { ContractsListPage } from "./ContractsListPage";

// COMPONENT
class ContractsPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleTypeClick = this.handleTypeClick.bind(this);
    }

    handleTypeClick(sectionIdentifier) {
        this.props.history.push(`?section=${sectionIdentifier}`);
    }

    render() {
        let sections = [
            {
                page: <ContractsListPage pageName={"My Contracts"} personal={true} />,
                sectionIdentifier: "my-contracts",
                sectionHeader: "Contracts",
                cell: {
                    type: CELL_TYPES.NORMAL,
                    identifiers: { label: "My Contracts" },
                },
            },
            {
                page: <ContractsListPage pageName={"Other Contracts"} personal={false} />,
                sectionIdentifier: "other-contracts",
                cell: {
                    type: CELL_TYPES.NORMAL,
                    identifiers: { label: "Other Contracts" },
                },
            },
        ];

        return (
            <Fragment>
                <ContextualSidebar pageTitle={<PageTitle title={"Contracts"} />} left={<SimpleList handleClick={this.handleTypeClick} sections={sections} />} right={<MasterDetailRouter sections={sections} />} />
            </Fragment>
        );
    }
}

ContractsPage.propTypes = {
    contracts: PropTypes.object.isRequired,
    fetching: PropTypes.bool.isRequired,
    fetched: PropTypes.bool.isRequired,
    failed: PropTypes.bool.isRequired,
    getContracts: PropTypes.func.isRequired,
    history: PropTypes.object,
};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { contracts, fetching, fetched, failed } = state.contractReducer;
    return { contracts, fetching, fetched, failed };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getContracts, prepareForContractSwitch, deleteContracts }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractsPage));

// EXPORT COMPONENT

export { hoc as ContractsPage };
