// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// UI
import { PageTitle } from "../../containers/layout/PageTitle";
import { ContextualSidebar } from "../../containers/layout/ContextualSidebar";
import { MasterDetailRouter } from "../../containers/layout/MasterDetailRouter";
import { SimpleList } from "./lists/SimpleList";
import { CELL_TYPES } from "./CellTypes";
import { ContractReviewPage } from "./summary/ContractReviewPage";
import { ConsolidatedCashFlowPage } from "./summary/ConsolidatedCashFlowPage";
import { CustomerReviewPage } from "./summary/CustomerReviewPage";
import { ApprovalPage } from "./Approvals/ApprovalPage";
import { AuditLogPage } from "./Approvals/AuditLogPage";
import { WorkflowAssignmentPage } from "./Approvals/WorkflowAssignmentPage";

export const FormContext = React.createContext();

// COMPONENT
class SummaryPage extends React.Component {
    constructor(props) {
        super(props);
        
        this.handleSummaryClicked = this.handleSummaryClicked.bind(this);
        this.componentRef = null;
    }

    handleSummaryClicked(sectionIdentifier) {
        this.props.history.push(`?section=${sectionIdentifier}`);
    }

    render() {
        console.log('SP', this.props)
        let sections = [
            {
                page: <CustomerReviewPage isCalculationPending={this.props.isCalculationPending} />,
                sectionIdentifier: "review",
                sectionHeader: "Customer Review",
                cell: {
                    type: CELL_TYPES.NORMAL,
                    identifiers: { label: "Customer Review" },
                },
            },
            {
                page: <ContractReviewPage isCalculationPending={this.props.isCalculationPending} />,
                sectionIdentifier: "internal",
                sectionHeader: "CCA Internal",
                cell: {
                    type: CELL_TYPES.NORMAL,
                    identifiers: { label: "ROI Review" },
                },
            },
            {
                page: <ConsolidatedCashFlowPage isCalculationPending={this.props.isCalculationPending} />,
                sectionIdentifier: "cash-flow",
                cell: {
                    type: CELL_TYPES.NORMAL,
                    identifiers: { label: "Cash Flow" },
                },
            },
            {
                page: <ApprovalPage isCalculationPending={this.props.isCalculationPending} />,
                sectionIdentifier: "approvalpage",
                sectionHeader: "Approval Workflow",
                cell: {
                    type: CELL_TYPES.NORMAL,
                    identifiers: { label: "Approval Page" },
                },
            },
            {
                page: <AuditLogPage isCalculationPending={this.props.isCalculationPending} />,
                sectionIdentifier: "approval-audit",
                cell: {
                    type: CELL_TYPES.NORMAL,
                    identifiers: { label: "Approval Audit Log" },
                },
            },
            {
                page: <WorkflowAssignmentPage isCalculationPending={this.props.isCalculationPending} />,
                sectionIdentifier: "workflow-assignment",
                cell: {
                    type: CELL_TYPES.NORMAL,
                    identifiers: { label: "Workflow Assignment" },
                },
            },
        ];

        return (
            <Fragment>
                <ContextualSidebar pageTitle={<PageTitle title={"Summary"} />} left={<SimpleList handleClick={this.handleSummaryClicked} sections={sections} />} right={<MasterDetailRouter sections={sections} />} />
            </Fragment>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { } = state.productGroupReducer;
    return {};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(SummaryPage));

// EXPORT COMPONENT
export { hoc as SummaryPage };
