// *** PACKAGE REFERENCES *** //
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FeatherIcon from "feather-icons-react";

//** ACTIONS **//
import { hideError } from "../../state/actions/GeneralActions";
import { CCAError } from "../../enums/CCAError";

// COMPONENT
class Message extends React.Component {
    render() {
        return (
            <Fragment>
                {this.props.errorShowing && (
                    <div className="error-message">
                        <div className="error-message-content">
                            <div className="error-message-text"> {this.props.errorMessage} </div>
                            <div
                                className="error-message-action"
                                onClick={() => {
                                    this.props.retryHandler();
                                }}
                            >
                                {" "}
                                Retry{" "}
                            </div>
                        </div>

                        <div
                            className="error-message-dismiss"
                            onClick={() => {
                                this.props.hideError(CCAError.DISMISS_ERROR);
                            }}
                        >
                            <FeatherIcon icon="x" />
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { errorShowing, errorMessage, retryHandler } = state.generalReducer;
    return { errorShowing, errorMessage, retryHandler };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            hideError,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(Message));

// EXPORT COMPONENT
export { hoc as Message };
