// IMPORT PACKAGE REFERENCES
import React from "react";

// COMPONENT
export class DetailCell extends React.Component {
    render() {
        return (
            <div className="normal-row">
                <div className="normal-label">{this.props.data[this.props.identifiers.label]}</div>
                <div className="normal-subtitle">{this.props.data[this.props.identifiers.subtitle]}</div>
            </div>
        );
    }
}
