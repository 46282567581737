import axios from "axios";
import { API_ROOT } from "../../../config/config";

export const GET_EQUIPMENTS_PENDING = "GET_EQUIPMENTS_PENDING";
export const GET_EQUIPMENTS_FULFILLED = "GET_EQUIPMENTS_FULFILLED";
export const GET_EQUIPMENTS_REJECTED = "GET_EQUIPMENTS_REJECTED";

export const ADD_EQUIPMENT_PENDING = "ADD_EQUIPMENT_PENDING";
export const ADD_EQUIPMENT_FULFILLED = "ADD_EQUIPMENT_FULFILLED";
export const ADD_EQUIPMENT_REJECTED = "ADD_EQUIPMENT_REJECTED";

export const GET_EQUIPMENT_PENDING = "GET_EQUIPMENT_PENDING";
export const GET_EQUIPMENT_FULFILLED = "GET_EQUIPMENT_FULFILLED";
export const GET_EQUIPMENT_REJECTED = "GET_EQUIPMENT_REJECTED";

export const PUT_EQUIPMENT_PENDING = "PUT_EQUIPMENT_PENDING";
export const PUT_EQUIPMENT_FULFILLED = "PUT_EQUIPMENT_FULFILLED";
export const PUT_EQUIPMENT_REJECTED = "PUT_EQUIPMENT_REJECTED";

export const DELETE_EQUIPMENT_PENDING = "DELETE_EQUIPMENT_PENDING";
export const DELETE_EQUIPMENT_FULFILLED = "DELETE_EQUIPMENT_FULFILLED";
export const DELETE_EQUIPMENT_REJECTED = "DELETE_EQUIPMENT_REJECTED";

export const SAVE_EQUIPMENT_CONDITIONS_PENDING = "SAVE_EQUIPMENT_CONDITIONS_PENDING";
export const SAVE_EQUIPMENT_CONDITIONS_FULFILLED = "SAVE_EQUIPMENT_CONDITIONS_FULFILLED";
export const SAVE_EQUIPMENT_CONDITIONS_REJECTED = "SAVE_EQUIPMENT_CONDITIONS_REJECTED";

// ACTION GENERATORS

/*
    Retrieves all the equipment.
*/

const getEquipments = (contractId, scenarioId, callback) => (dispatch) => {
    dispatch({
        type: GET_EQUIPMENTS_PENDING,
    });

    let url = API_ROOT + "/equipment";

    if (contractId && scenarioId) {
        url = `${API_ROOT}/contracts/${contractId}/${scenarioId}/equipment`;
    }

    /*
        We want to get all the equipment.
    */
    axios
        .get(url)
        .then((res) => {
            const equipments = res.data;

            /* Fulfil the request for equipment, since the API succceded, pass the equipment data to the reducer. */
            dispatch({
                type: GET_EQUIPMENTS_FULFILLED,
                payload: equipments,
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting equipment.", error);

            /* Reject the request for equipment, since the API failed. */
            dispatch({
                type: GET_EQUIPMENTS_REJECTED,
            });

            callback(false);
        });
};

/*
    Retrieves a singular piece of equipment.
*/

const getEquipment = (contractId, scenarioId, equipmentId, callback) => (dispatch) => {
    dispatch({
        type: GET_EQUIPMENT_PENDING,
    });

    /*
        We want to get the equipment.
    */
    axios
        .get(`${API_ROOT}/contracts/${contractId}/${scenarioId}/equipment/${equipmentId}`)
        .then((res) => {
            const equipment = { id: equipmentId, ...res.data };

            /* Fulfil the request for equipment, since the API succceded, pass the equipment data to the reducer. */
            dispatch({
                type: GET_EQUIPMENT_FULFILLED,
                payload: equipment,
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting equipment.", error);

            /* Reject the request for equipment, since the API failed. */
            dispatch({
                type: GET_EQUIPMENT_REJECTED,
            });

            callback(false);
        });
};

/*
    Saves a singular piece of equipment.
*/

const saveEquipment = (contractId, scenarioId, equipmentId, values, callback) => (dispatch) => {
    dispatch({
        type: PUT_EQUIPMENT_PENDING,
    });

    /*
        We want to save the equipment.
    */
    axios
        .put(`${API_ROOT}/contracts/${contractId}/${scenarioId}/equipment/${equipmentId}`, values)
        .then((res) => {
            /* Fulfil the request for equipment, since the API succceded */
            dispatch({
                type: PUT_EQUIPMENT_FULFILLED,
                payload: { id: equipmentId, ...values },
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting equipment.", error);

            /* Reject the request for saving the equipment, since the API failed. */
            dispatch({
                type: PUT_EQUIPMENT_REJECTED,
            });

            callback(false);
        });
};

/*
    Saves a singular piece of equipment.
*/

const deleteEquipments = (contractId, scenarioId, values, callback) => (dispatch) => {
    dispatch({
        type: DELETE_EQUIPMENT_PENDING,
    });

    /*
        We want to save the equipment.
    */
    axios
        .delete(`${API_ROOT}/contracts/${contractId}/${scenarioId}/equipment`, { data: values })
        .then((res) => {
            /* Fulfil the request for equipment, since the API succceded */
            dispatch({
                type: DELETE_EQUIPMENT_FULFILLED,
                payload: values,
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting equipment.", error);

            /* Reject the request for saving the equipment, since the API failed. */
            dispatch({
                type: DELETE_EQUIPMENT_REJECTED,
            });

            callback(false);
        });
};

/*
    Add equipment to the contract.
*/

const addEquipment = (contractId, scenarioId, data, callback) => (dispatch) => {
    dispatch({
        type: ADD_EQUIPMENT_PENDING,
    });

    /*
        We want to add equipment.
    */
    axios
        .post(`${API_ROOT}/contracts/${contractId}/${scenarioId}/equipment`, data)
        .then((response) => {
            dispatch({
                type: ADD_EQUIPMENT_FULFILLED,
            });

            callback(true, response.data);
        })
        .catch((error) => {
            console.log("An error occurred requesting equipment.", error);

            /* Reject the request for equipment, since the API failed. */
            dispatch({
                type: ADD_EQUIPMENT_REJECTED,
            });

            callback(false);
        });
};

/*
    Save equipment conditions.
*/

const saveEquipmentConditions = (contractId, scenarioId, equipmentId, data, callback) => (dispatch) => {
    dispatch({
        type: SAVE_EQUIPMENT_CONDITIONS_PENDING,
    });

    /*
        We want to add equipment.
    */
    axios
        .post(API_ROOT + "/contracts/" + contractId + "/" + scenarioId + "/equipment/" + equipmentId + "/conditions", { data: data })
        .then(() => {
            dispatch({
                type: SAVE_EQUIPMENT_CONDITIONS_FULFILLED,
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting equipment.", error);

            /* Reject the request for equipment, since the API failed. */
            dispatch({
                type: SAVE_EQUIPMENT_CONDITIONS_REJECTED,
            });

            callback(false);
        });
};

// EXPORT ACTIONS
export { getEquipments, addEquipment, getEquipment, saveEquipment, deleteEquipments, saveEquipmentConditions };
