import React, { useEffect, useState } from 'react';
import { useSelector, connect, useDispatch } from 'react-redux';
import { withRouter } from "react-router-dom";
import { ContextPage } from '../../../containers/layout/ContextPage';
import { FlexibleDataTable } from '../../../containers/table/FlexibleDataTable';
import { getContractApprovers, getWorkflowOptions, updateWorkflowUser } from "../../../state/actions/ContractActions";
import moment from "moment";
import Select from 'react-select'
import { Loading } from '../../../containers/loading/Loading';
import { getApproval } from "../../../state/actions/SummaryActions";

const WorkflowAssignmentPage = (props) => {
    const [cols, setCols] = useState(["Role", "User", "State"]);
    const [rows, setRows] = useState([]);
    const [current, setCurrent] = useState({});
    const [loading, setLoading] = useState(false);
    const [usersListsLoading, setUsersListsLoading] = useState(false);
    const [approvalDataLoading, setApprovalDataLoading] = useState(false);
    const dispatch = useDispatch();

    const usersByRole = useSelector((state) => state.contractReducer.usersByRole);
    const user = useSelector((state) => state.userReducer.user);
    const approvalTree = useSelector((state) => state.contractReducer.approvalTree);

    useEffect(() => {
        dispatch(getApproval(getContractIdFromUrl(), getScenarioIdFromUrl()));
        refreshPage();
    }, []);

    useEffect(() => {
        setRows([...usersByRole]);
    }, [usersByRole]);

    useEffect(() => {
        getCurrentValues();
    }, [approvalTree]);

    useEffect(() => {
        if (!usersListsLoading && !approvalDataLoading) {
            setLoading(false);
        }
    }, [usersListsLoading, approvalDataLoading])

    const refreshPage = () => {
        setLoading(true);
        setUsersListsLoading(true);
        setApprovalDataLoading(true);
        dispatch(getWorkflowOptions(getContractIdFromUrl(), getScenarioIdFromUrl(), (success) => {
            setUsersListsLoading(false);
        }))
        dispatch(getContractApprovers(getContractIdFromUrl(), getScenarioIdFromUrl(), user.Email, (success) => {
            setApprovalDataLoading(false);
        }))
    }

    const getContractIdFromUrl = () => {
        let components = props.location.pathname.split("/");
        return components[2];
    }

    const getScenarioIdFromUrl = () => {
        let components = props.location.pathname.split("/");
        return components[3];
    }

    const getCurrentValues = () => {
        var currentContractValues = {};
        approvalTree.forEach(item => {
            currentContractValues[item.Role] = { value: item.Email, label: item.DisplayName, Status: item.Status }
        });

        setCurrent(currentContractValues);
    }

    const updateUserByRole = (email, displayname, role) => {
        dispatch(updateWorkflowUser(email, displayname, role, getContractIdFromUrl(), getScenarioIdFromUrl(), user.Email, (success) => {
            refreshPage();
        }));
    }

    const getRows = () => {
        let tableRows = [];
        rows.forEach(item => {
            let currStatus = current[item.Role] != null ? current[item.Role].Status : "";
            let disabled = currStatus == "Approved" || currStatus == "AutomaticallyApproved";
            tableRows.push({
                columns: [
                    { cellType: 'text', data: item.Role },
                    {
                        cellType: 'text', data:
                            <Select
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                menuPortalTarget={document.body}
                                defaultValue={current[item.Role]}
                                selected
                                isDisabled={disabled}
                                options={item.Users.map(s => { return { value: s.Mail, label: s.DisplayName } })}
                                onChange={(event) => {
                                    updateUserByRole(event.value, event.label, item.Role);
                                }}
                            />
                    },
                    { cellType: 'text', data: current[item.Role] != null ? current[item.Role].Status : "Unknown" },
                ]
            })
        });
        return tableRows;
    }

    return (

        <ContextPage
            title="Workflow Management Page"
            fetching={!loading}
            fetched={true}
        >
            {loading && <Loading></Loading>}
            <FlexibleDataTable
                columnTable
                header="Workflow Assignment"
                headerColour="purple"
                columnHeaders={cols}
                rows={getRows()}
            />
        </ContextPage>
    );
}

const hoc = withRouter(connect()(WorkflowAssignmentPage));

// EXPORT COMPONENT
export { hoc as WorkflowAssignmentPage };