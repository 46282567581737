// IMPORT PACKAGE REFERENCES
import React from "react";
import ReactDOM from "react-dom";
import Modalised from "../containers/layout/Modalised";

// COMPONENT
export default class MyAccount extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showing: false,
        };
    }

    componentDidUpdate() {
        if (this.state.showing !== this.props.showing) {
            this.setState({ showing: this.props.showing });
        }
    }

    render() {
        return (
            <Modalised handleClose={this.props.handleClose} title={"My Account"} showing={this.state.showing}>
                <div>Content</div>
            </Modalised>
        );
    }
}
