// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";

// UI
import { Input } from "reactstrap";
import NumberFormat from "react-number-format";

// COMPONENT
export class ValidatedInput extends React.Component {
    constructor(props) {
        super(props);

        this.renderStandardInput = this.renderStandardInput.bind(this);
        this.renderSelectInput = this.renderSelectInput.bind(this);
        this.renderValidationErrorMessage = this.renderValidationErrorMessage.bind(this);
        this.renderStandardFormat = this.renderStandardFormat.bind(this);
        this.renderDollarFormat = this.renderDollarFormat.bind(this);

        this.state = {
            showErrors: false,
        };
    }

    renderStandardInput() {
        return (
            <Input
                bsSize={this.props.large ? "lg" : ""}
                onChange={(e) => {
                    this.props.setFieldValue(this.props.field, e.target.value);
                }}
                name={this.props.name}
                value={this.props.value}
                type={this.props.type}
                id={this.props.id}
                placeholder={this.props.placeholder}
                disabled={this.props.disabled == null ? false : this.props.disabled}
            />
        );
    }

    renderDateInput() {
        return (
            <NumberFormat
                type="text"
                className={"form-control"}
                format="##/##/####"
                placeholder="DD/MM/YYYY"
                mask={["D", "D", "M", "M", "Y", "Y", "Y", "Y"]}
                onValueChange={(value) => {
                    this.props.setFieldValue(this.props.field, value.formattedValue);
                }}
                value={this.props.value}
            />
        );
    }

    renderSelectInput() {
        return (
            <Input
                onChange={(e) => {
                    this.props.setFieldValue(this.props.field, e.target.value);
                }}
                name={this.props.name}
                value={this.props.value}
                type={this.props.type}
                id={this.props.id}
                placeholder={this.props.placeholder}
            >
                {this.props.children}
            </Input>
        );
    }

    renderValidationErrorMessage() {
        return <div className="validation-error">{this.props.error}</div>;
    }

    renderDollarFormat() {
        return (
            <Fragment>
                <NumberFormat
                    value={this.props.value}
                    prefix="$"
                    fixedDecimalScale={true}
                    decimalScale={2}
                    className="form-control"
                    onValueChange={(value) => {
                        this.props.setFieldValue(this.props.field, value.floatValue);
                    }}
                />
                {this.props.touched && this.renderValidationErrorMessage()}
            </Fragment>
        );
    }

    renderSelectFormat() {
        return (
            <Fragment>
                {this.renderSelectInput()}
                {this.props.touched && this.renderValidationErrorMessage()}
            </Fragment>
        );
    }

    renderDateFormat() {
        return (
            <Fragment>
                {this.renderDateInput()}
                {this.props.touched && this.renderValidationErrorMessage()}
            </Fragment>
        );
    }

    renderStandardFormat() {
        return (
            <Fragment>
                {this.renderStandardInput()}
                {this.props.touched && this.renderValidationErrorMessage()}
            </Fragment>
        );
    }

    render() {
        switch (this.props.inputFormat) {
            case "dollar":
                return this.renderDollarFormat();
            case "select":
                return this.renderSelectFormat();
            case "date":
                return this.renderDateFormat();
            default:
                return this.renderStandardFormat();
        }
    }
}
