import React, { useEffect, useState } from 'react';
import { useSelector, connect, useDispatch } from 'react-redux';
import { withRouter } from "react-router-dom";
import { ContextPage } from '../../../containers/layout/ContextPage';
import { FlexibleDataTable } from '../../../containers/table/FlexibleDataTable';
import { getContractApprovers } from "../../../state/actions/ContractActions";
import { getApproval } from "../../../state/actions/SummaryActions";

import moment from "moment";


const AuditLogPage = (props) => {
    const [cols, setCols] = useState(["User", "Action", "Date & Time"]);
    const [rows, setRows] = useState([]);
    const dispatch = useDispatch();

    const approvalItem = useSelector((state) => state.contractReducer.approvalItem);
    const user = useSelector((state) => state.userReducer.user);

    useEffect(() => {
        dispatch(getApproval(getContractIdFromUrl(), getScenarioIdFromUrl()));
        dispatch(getContractApprovers(getContractIdFromUrl(), getScenarioIdFromUrl(), user.email, (success) => {
            console.log(success);
        }));
    }, []);

    useEffect(() => {
        if (approvalItem != null && approvalItem.AuditLog != null) {
            var rows = approvalItem.AuditLog.map(item => {
                let ActionTime = moment(item.ActionTime);
                return ({
                    columns: [
                        { cellType: 'text', data: item.AuditUser },
                        { cellType: 'text', data: item.AuditAction },
                        { cellType: 'text', data: ActionTime.format("DD-MM-YYYY HH:mm:ss") },
                    ]
                })
            });
            setRows(rows);
        }
    }, [approvalItem])

    const getContractIdFromUrl = () => {
        let components = props.location.pathname.split("/");
        return components[2];
    }

    const getScenarioIdFromUrl = () => {
        let components = props.location.pathname.split("/");
        return components[3];
    }

    return (
        <ContextPage
            title="Audit Log Page"
            fetching={false}
            fetched={true}
        >
            <FlexibleDataTable
                columnTable
                header="Audit Log"
                headerColour="green"
                columnHeaders={cols}
                rows={rows}
            />
        </ContextPage>
    );
}

const hoc = withRouter(connect()(AuditLogPage));

// EXPORT COMPONENT
export { hoc as AuditLogPage };