// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";

// ACTIONS
import { createScenario, getContract } from "../../../state/actions/ContractActions";

// UI
import { ValidatedInput } from "../../../containers/validation/ValidatedInput";
import { Col, Row, Button, FormGroup, Label } from "reactstrap";
import { Loading } from "../../../containers/loading/Loading";
import { Message } from "../../../containers/layout/Message";
import { Checkbox } from "../../../containers/inputs/Checkbox";
import { CCAInput } from "../../../containers/inputs/CCAInput";
import { CCAInputTypes } from "../../../enums/CCAInputTypes";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("A scenario name is required."),
    copyScenario: Yup.string().when("shouldCopyScenario", {
        is: true,
        then: Yup.string().required("Please choose a scenario to copy"),
    }),
});

// COMPONENT
class NewScenarioModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            formInvalid: false,
            messageActive: false,
            messageError: false,
            loading: true,
            shouldCopyExistingScenario: false,
            submitted: true,
        };

        this.getContractIdFromUrl = this.getContractIdFromUrl.bind(this);
    }

    componentDidMount() {
        this.props.getContract(this.getContractIdFromUrl(), (contractSuccess) => {
            this.setState({ loading: false });
        });
    }

    getContractIdFromUrl() {
        let components = this.props.location.pathname.split("/");
        return components[2];
    }

    getScenarioIfFromUrl() {
        let components = this.props.location.pathname.split("/");
        return components[3];
    }

    getScenarios() {
        let scenarios = this.props.contract.scenarios;
        if (!scenarios) {
            scenarios = [];
        }

        return scenarios;
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        let scenarios = this.getScenarios();

        return (
            <div className="modalised-content">
                <Message active={this.state.messageActive} error={this.state.messageError} message="Error creating new scenario" />
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        name: "",
                    }}
                    onSubmit={(values) => {
                        this.setState({ saving: true, submitted: true });
                        // If the 'None' option is selected, make sure we don't copy an existing scenario
                        if (values.copyScenario === "None") {
                            values.copyScenario = undefined;
                            values.shouldCopyScenario = undefined;
                        }

                        this.props.createScenario(values, this.getContractIdFromUrl(), this.getScenarioIfFromUrl(), (success, newScenarioId) => {
                            this.setState({ saving: false });
                            if (success) {
                                this.props.hideModal();
                                this.props.history.push(`/contracts/${this.getContractIdFromUrl()}/${newScenarioId}/scenario?section=generalDetails`);
                            } else {
                                this.setState({ messageActive: true, messageError: true });
                                setTimeout(() => {
                                    this.setState({ messageActive: false, messageError: false });
                                }, 2000);
                            }
                        });
                    }}
                    render={({ values, handleSubmit, setFieldValue, errors, touched }) => {
                        return (
                            <Fragment>
                                {[{ rowName: "Scenario", rowDataIdentifier: "name", placeholder: "Scenario name", inputFormat: "text" }].map((row) => {
                                    return (
                                        <Row form>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="name">{row.rowName}</Label>
                                                    <ValidatedInput touched={touched.name} error={errors[row.rowDataIdentifier]} field={row.rowDataIdentifier} setFieldValue={setFieldValue} name={row.rowDataIdentifier} value={values[row.rowDataIdentifier]} inputFormat={row.inputFormat} type="text" id={row.rowDataIdentifier} placeholder={row.placeholder !== undefined && row.placeholder} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    );
                                })}

                                <Row form>
                                    <Col md={12}>
                                        <Checkbox
                                            label={"Copy existing scenario data?"}
                                            handleClick={() => {
                                                let shouldCopyScenario = !this.state.shouldCopyExistingScenario;
                                                this.setState({ shouldCopyExistingScenario: shouldCopyScenario });
                                                setFieldValue("shouldCopyScenario", shouldCopyScenario);
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={12} className={this.state.shouldCopyExistingScenario ? "content-visible" : "content-hidden"}>
                                        <FormGroup>
                                            <Label for="exampleSelect">Scenario to Copy</Label>
                                            <CCAInput
                                                css={""}
                                                firstItemNone
                                                type={CCAInputTypes.SELECT}
                                                name="select"
                                                value={values.copyScenario}
                                                onChange={(e) => {
                                                    setFieldValue("copyScenario", e.target.value);
                                                }}
                                                onSave={(e) => {
                                                    handleSubmit(e);
                                                }}
                                                options={scenarios.map((scenario) => {
                                                    return { saveChoice: scenario.scenarioId, displayChoice: scenario.scenarioName };
                                                })}
                                                optionsAreObjects
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row form>
                                    <Button onClick={handleSubmit} color="secondary" size="lg" block>
                                        {!this.state.saving && <div> New Scenario </div>}
                                        {this.state.saving && <Loading small light />}
                                    </Button>
                                </Row>
                            </Fragment>
                        );
                    }}
                />
            </div>
        );
    }
}

NewScenarioModal.propTypes = {};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { contract, fetching, fetched, failed } = state.contractReducer;
    const { user } = state.userReducer;
    return { fetching, fetched, failed, user, contract };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ createScenario, getContract }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(NewScenarioModal));

// EXPORT COMPONENT

export { hoc as NewScenarioModal };
