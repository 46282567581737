import React from "react";
import { Label } from "recharts";

export class Note extends React.Component {
  constructor(props) {
    super(props);
    let tag = props.Tag ? props.Tag : "div";
    this.state = {
      tag,
    };
  }

  render() {
    return (
      <div
        css={{
          color: 'hsl(0, 0%, 40%)',
          display: 'inline-block',
          fontSize: 12,
          fontStyle: 'italic',
          marginTop: '1em',
        }}
      />
    )
  };
}