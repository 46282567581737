export var CCAError = Object.freeze({
    DISMISS_ERROR: {
        code: -1,
    },
    SAVE_CONTRACT_DETAILS_ERROR: {
        code: 0,
        message: "Error Saving Contract Details",
    },
    GET_CONTRACT_DETAILS_ERROR: {
        code: 1,
        message: "Error Retrieving Contract Details",
    },
    GET_GENERAL_DETAILS_ERROR: {
        code: 2,
        message: "Error Retrieving General Details",
    },
    SAVE_EQUIPMENT_ERROR: {
        code: 3,
        message: "Error Saving Equipment Details",
    },
    ADD_EQUIPMENT_ERROR: {
        code: 3,
        message: "Error Adding New Equipment",
    },
    GET_NON_FINANCIAL_SUMMARY_ERROR: {
        code: 4,
        message: "Error Requesting Non Financial Summary",
    },
    SAVE_NON_FINANCIAL_ERROR: {
        code: 5,
        message: "Error Saving Non Financial",
    },
    SAVE_SCENARIO_DETAILS_ERROR: {
        code: 6,
        message: "Error Saving Scenario Details",
    },
    GET_SCENARIO_DETAILS_ERROR: {
        code: 7,
        message: "Error Requesting Scenario Details",
    },
});
