import React from "react";
import ReactDOM from "react-dom";
import { App } from "./components/App";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/app.scss";
import 'react-dropzone-uploader/dist/styles.css'

ReactDOM.render(<App />, document.getElementById("app"));
if (module.hot) {
    module.hot.accept()
}