// *** PACKAGE REFERENCES *** //
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// *** ACTIONS *** //
import { getContractDetails, saveContractDetails, getScenarioDetails, saveScenarioDetails } from "../../../state/actions/ContractDetailsActions";
import { getContract } from "../../../state/actions/ContractActions";
import { showError, hideError } from "../../../state/actions/GeneralActions";

// *** UI *** //
import { ContextPage } from "../../../containers/layout/ContextPage";
import { Card } from "../../../containers/card/Card";
import { CardRow } from "../../../containers/card/CardRow";
import { Formik } from "formik";
import { CCAInput } from "../../../containers/inputs/CCAInput";
import { CCAInputTypes } from "../../../enums/CCAInputTypes";
import { CCAError } from "../../../enums/CCAError";

class ExclusivityPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: false,
        };

        this.valuesAtLastSave = {};
        this.getDetails = this.getDetails.bind(this);
    }

    componentDidMount() {
        this.getDetails();
    }

    getDetails() {
        this.props.getScenarioDetails(this.props.match.params.contractId, this.props.match.params.scenarioId, () => {
            this.valuesAtLastSave = this.props.scenarioDetails;
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.scenarioId !== this.props.match.params.scenarioId) {
            this.getDetails();
        }
    }

    saveScenarioDetails(values) {
        this.props.saveScenarioDetails(this.props.match.params.contractId, this.props.match.params.scenarioId, { exclusivity: values }, (success) => {
            if (success) {
                this.valuesAtLastSave = values;
                this.props.hideError(CCAError.SAVE_SCENARIO_DETAILS_ERROR);
                // Go and re-fetch the contract so that the term, name, etc will be updated on the sidebar...
                this.props.getContract(this.props.match.params.contractId, (success) => { });
            } else {
                this.props.showError(CCAError.SAVE_SCENARIO_DETAILS_ERROR, () => {
                    this.saveScenarioDetails(values);
                });
            }
        });
    }

    render() {
        return (
            <ContextPage pin={true} pinned={this.props.pinned} handlePin={() => this.props.handlePin("exclusivity")} fetching={this.props.fetching} fetched={this.props.fetched} title={this.props.title}>
                <Formik
                    enableReinitialize={true}
                    initialValues={this.props.scenarioDetails.exclusivity ? this.props.scenarioDetails.exclusivity : {}}
                    onSubmit={(values) => {
                        // Don't bother sending a request if no change to inputs
                        if (JSON.stringify(values) !== JSON.stringify(this.valuesAtLastSave)) {
                            this.saveScenarioDetails(values);
                        }
                    }}
                    render={({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldTouched, setFieldValue }) => (
                        <Card>
                            <CardRow label={"NARTD"} top>
                                <CCAInput
                                    type={CCAInputTypes.CHECKBOX}
                                    value={values.NARTD}
                                    name="NARTD"
                                    onChange={(name, event) => {
                                        setFieldValue(name, event);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"CSD"}>
                                <CCAInput
                                    type={CCAInputTypes.CHECKBOX}
                                    value={values.CSD}
                                    name="CSD"
                                    onChange={(name, event) => {
                                        setFieldValue(name, event);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Water"}>
                                <CCAInput
                                    type={CCAInputTypes.CHECKBOX}
                                    value={values.water}
                                    name="water"
                                    onChange={(name, event) => {
                                        setFieldValue(name, event);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Energy"}>
                                <CCAInput
                                    type={CCAInputTypes.CHECKBOX}
                                    value={values.energy}
                                    name="energy"
                                    onChange={(name, event) => {
                                        setFieldValue(name, event);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Sports"}>
                                <CCAInput
                                    type={CCAInputTypes.CHECKBOX}
                                    value={values.sports}
                                    name="sports"
                                    onChange={(name, event) => {
                                        setFieldValue(name, event);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Lifestyle"}>
                                <CCAInput
                                    type={CCAInputTypes.CHECKBOX}
                                    value={values.lifestyle}
                                    name="lifestyle"
                                    onChange={(name, event) => {
                                        setFieldValue(name, event);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Juice"}>
                                <CCAInput
                                    type={CCAInputTypes.CHECKBOX}
                                    value={values.juice}
                                    name="juice"
                                    onChange={(name, event) => {
                                        setFieldValue(name, event);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Coffee"}>
                                <CCAInput
                                    type={CCAInputTypes.CHECKBOX}
                                    value={values.coffee}
                                    name="coffee"
                                    onChange={(name, event) => {
                                        setFieldValue(name, event);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow label={"Flavoured Milk"}>
                                <CCAInput
                                    type={CCAInputTypes.CHECKBOX}
                                    value={values.flavouredMilk}
                                    name="flavouredMilk"
                                    onChange={(name, event) => {
                                        setFieldValue(name, event);
                                    }}
                                    onSave={handleSubmit}
                                />
                            </CardRow>

                            <CardRow longform label={"Exceptions"} bottom={true}>
                                <CCAInput type={CCAInputTypes.DESCRIPTION} name="exceptions" value={values.exceptions} onChange={handleChange} onSave={handleSubmit} />
                            </CardRow>
                        </Card>
                    )}
                />
            </ContextPage>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { fetching, fetched, failed, scenarioDetails } = state.contractDetailsReducer;
    return { fetching, fetched, failed, scenarioDetails };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getContractDetails,
            getScenarioDetails,
            saveContractDetails,
            saveScenarioDetails,
            getContract,
            showError,
            hideError,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(ExclusivityPage));

// EXPORT COMPONENT
export { hoc as ExclusivityPage };
