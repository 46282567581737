// IMPORT PACKAGE REFERENCES
import React, { Fragment } from 'react';
import FeatherIcon from 'feather-icons-react';
import NumberFormat from 'react-number-format';
import { Input } from 'reactstrap';

import { Checkbox } from '../../../../containers/inputs/Checkbox';
import { HeaderAction } from '../../../../containers/table/HeaderAction';
import { MultiPurchasePromotionCell } from './MultiPurchasePromotionCell';

export default class DataCell extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            groups: props.groups,
            rows: props.rows,
            showingConditionalPopover: false,
            changed: false,
            value: '',
            isActive: props.isActive,
            cellActionVisible: false,
            handleCellAction: {},
            cellActionLabel: "",
        };

        this.updateCellValue = this.updateCellValue.bind(this);
    }

    getDiscountValue(discounts, dataIdentifier) {
        // Default the discount value to 0.
        let value = '';
        let type = '';
        let conditions = {};
        let conditionalised = false;

        if (dataIdentifier === 'rebates' && discounts.hasOwnProperty('rebates')) {
            // If the discount is a rebate...
            let rebates = discounts['rebates'];

            // Check to see if rebates are active, this means that it has a condition attached...
            if (rebates.active) {
                value = rebates.numericValue;
                conditionalised = true;
                conditions = rebates.conditions;
                type = rebates.type;
            }

            // Otherwise just get the fixed value as there is no discount attached.
            if (rebates.fixedValue) {
                value = rebates.fixedValue;
            }
        } else if (dataIdentifier === 'paPerCase' && discounts.hasOwnProperty('paPerCase')) {
            let paPerCase = discounts['paPerCase'];

            // Check to see if paPerCase is active, this means that it has a condition attached...
            if (paPerCase.active) {
                value = paPerCase.numericValue;
                conditionalised = true;
                conditions = paPerCase.conditions;
                type = paPerCase.type;
            }

            // Otherwise just get the fixed value as there is no discount attached.
            if (paPerCase.fixedValue) {
                value = paPerCase.fixedValue;
            }
        } else if (dataIdentifier === 'flatDiscount' && discounts.hasOwnProperty('flatDiscount')) {
            let flatDiscount = discounts['flatDiscount'];

            // Check to see if flatDiscount is active, this means that it has a condition attached...
            if (flatDiscount.active) {
                value = flatDiscount.numericValue;
                conditionalised = true;
                conditions = flatDiscount.conditions;
                type = flatDiscount.type;
            }

            // Otherwise just get the fixed value as there is no discount attached.
            if (flatDiscount.fixedValue) {
                value = flatDiscount.fixedValue;
            }
        }

        return { value, conditionalised, conditions, type };
    }

    renderCellAction = (headerActionContainerId) => (
        <HeaderAction
            handleAction={this.state.handleCellAction}
            showing={this.state.cellActionVisible}
            label={this.state.cellActionLabel}
            id={headerActionContainerId}
        />
    )

    isConditionalised(row, dataIdentifier) {
        if (this.props.isDiscount(dataIdentifier)) {
            let result = this.getDiscountValue(row.discounts, dataIdentifier);
            return { conditionalised: result.conditionalised, conditions: result.conditions };
        }

        return { conditionalised: false, conditions: {} };
    }

    getCellValue(dataIdentifier, row, conditional = false) {
        if (this.isDiscount(dataIdentifier)) {
            let discountType = this.props.getDiscountType(dataIdentifier, rowIndex);

            if (conditional) {
                return { discountType, value: row.discounts[dataIdentifier].conditions };
            } else {
                return { discountType, value: row.discounts[dataIdentifier].numericValue };
            }
        } else if (row.dataPoints[dataIdentifier]) {
            return { discountType: undefined, value: row.dataPoints[dataIdentifier] };
        }
    }

    getValue() {
        return this.state.value;
    }

    componentDidMount() {
        if (JSON.stringify(this.props.groups) !== JSON.stringify(this.state.groups)) {
            this.setState({ groups: this.props.groups });
        }

        let cellValue = this.getCellValue(this.props.row, this.props.rowIndex, this.props.dataIdentifier, false);

        if (cellValue) {
            this.setState({ value: cellValue.value });
        }

        if (JSON.stringify(this.props.rows) !== JSON.stringify(this.state.rows)) {
            this.setState({ rows: this.props.rows });
        }
    }

    componentDidUpdate() {
        if (this.props.isActive !== this.state.isActive) {
            this.setState({ isActive: this.props.isActive });
        }
    }

    updateCellValue(value) {
        // Reset edits to 0 when editing manually.
        this.setState({ changed: false, value });
    }

    hasChanged() {
        return this.state.changed;
    }

    save(callback) {
        if (this.hasChanged()) {
            let value = this.state.value;

            if (typeof this.state.value === 'string') {
                value = parseFloat(this.state.value);
            }

            this.props.updateRow(value, this.props.rowIndex, this.props.dataIdentifier, () => {
                callback();
            });

            this.setState({ changed: false });
        }
    }

    showingConditionalPopover() {
        this.setState({ showingConditionalPopover: true });
        this.props.showingConditionalPopover();
    }

    hidingConditionalPopover() {
        this.setState({ showingConditionalPopover: false });
        this.props.hidingConditionalPopover();
    }

    getCellValue(row, rowIndex, dataIdentifier, conditional = false) {
        if (this.props.isDiscount(dataIdentifier)) {
            let discountType = this.props.getDiscountType(dataIdentifier, rowIndex);

            if (conditional) {
                return { discountType, value: row.discounts[dataIdentifier].conditions };
            } else if (row.discounts[dataIdentifier]) {
                if (row.discounts[dataIdentifier].hasOwnProperty('numericValue')) {
                    return { discountType, value: row.discounts[dataIdentifier].numericValue };
                }
            }

            return { discountType, value: 0 };
        } else if (row.dataPoints[dataIdentifier] !== undefined) {
            return { discountType: undefined, value: row.dataPoints[dataIdentifier] };
        }
    }

    handleMouseEnter = (dataItem, cellId, headerActionContainerId, rowIndex) => {
        let elementId = dataItem.dataIdentifier;
        let cellElement = document.getElementById(cellId);
        let headerActionElement = document.getElementById(headerActionContainerId);

        if (elementId === "paymentFrequency") {
            let cellRect = cellElement.getBoundingClientRect()
            headerActionElement.style.left = (cellRect.x) + 'px'
            headerActionElement.style.top = ((cellRect.y) + cellRect.height) + 'px'
            headerActionElement.style.width = (cellRect.width) + 'px'

            this.setState({
                cellActionVisible: true,
                handleCellAction: (frequency) => { this.props.handlePaymentFrequency(frequency, rowIndex) },
                cellActionLabel: ["Blank", "Monthly", "Quarterly", "Half Yearly", "Yearly"]
            })
        }
        if (elementId === "unitOfMeasurement") {
            let cellRect = cellElement.getBoundingClientRect()
            headerActionElement.style.left = (cellRect.x) + 'px'
            headerActionElement.style.top = ((cellRect.y) + cellRect.height) + 'px'
            headerActionElement.style.width = (cellRect.width) + 'px'

            this.setState({
                cellActionVisible: true,
                handleCellAction: (frequency) => { this.props.handleUnitOfMeasurement(frequency, rowIndex) },
                cellActionLabel: this.props.getUnitOfMeasurementOptions(rowIndex)
            })
        }
    }

    handleMouseLeave = (dataItem, cellId, headerActionContainerId) => {
        let elementId = dataItem.dataIdentifier;

        if (elementId === "paymentFrequency" || elementId === "unitOfMeasurement") {
            setTimeout(() => {
                this.setState({ cellActionVisible: false });
            }, 50);
        }
    }

    render() {
        let { value, row, rowIndex, groupIndex, groupDataPointIndex, dataIdentifier } = this.props;

        let dataItem = this.state.groups[groupIndex].dataPoints[groupDataPointIndex];

        let discounts = row.discounts;
        let conditionalised = false;

        let isDiscount = this.props.isDiscount(dataIdentifier);

        let prefix = '';
        let suffix = '';
        if (dataItem.format) {
            if (dataItem.format.prefix) {
                prefix = dataItem.format.prefix;
            }

            if (dataItem.format.suffix) {
                suffix = dataItem.format.suffix;
            }

            if (dataItem.format.type === 'boolean') {
                return (
                    <Checkbox
                        checked={this.state.value}
                        handleClick={checked => {
                            this.setState({ changed: true, value: checked });
                        }}
                    />
                );
            } else if (dataItem.format.type === 'multiPurchasePromotion') {
                return (
                    <MultiPurchasePromotionCell
                        uid={`R${rowIndex}D${dataIdentifier}`}
                        rowIndex={rowIndex}
                        dataIdentifier={dataIdentifier}
                        values={value}
                        active={this.state.isActive}
                        onValueChange={value => {
                            // Only want to to save the values if they are both numbers
                            if (typeof value[0] === 'number' && typeof value[1] === 'number') {
                                this.setState({
                                    changed: true,
                                    value: value
                                });
                            }
                        }}
                    />
                );
            } else if (dataItem.format.type === 'string') {
                return (
                    <Fragment>
                        {value}
                    </Fragment>
                );
            } else if (dataItem.format.type === 'dropdown') {
                const cellId = dataItem.dataIdentifier + '--' + rowIndex;
                const headerActionContainerId = "headerActionContainer" + cellId[0].toUpperCase() + cellId.slice(1);
                return (
                    <div>
                        <div
                            id={cellId}
                            ref={this.props.cellRefs[`R${rowIndex}D${dataIdentifier}`].ref}
                            onMouseEnter={(e) => this.handleMouseEnter(dataItem, cellId, headerActionContainerId, rowIndex)}
                            onMouseLeave={(e) => this.handleMouseLeave(dataItem, cellId, headerActionContainerId)}
                        >
                            <Fragment>
                                <div style={{ "paddingRight": "1em", "textAlign": "right" }}>
                                    {value}{' '}
                                </div>
                                <FeatherIcon style={{ "width": "150%" }} icon="chevron-down" />
                            </Fragment>
                        </div>
                        {this.renderCellAction(headerActionContainerId)}
                    </div>
                );
            }
        }

        let discountKey = 'p';

        /* Determine if this cell is a discount cell... */
        if (isDiscount) {
            discountKey = this.props.getActiveDiscountTypeKey(rowIndex, dataIdentifier);

            if (discountKey === 'p') {
                suffix = '%';
                prefix = '';
            }

            // If it is we need to get the appropriate value.
            let result = this.getDiscountValue(discounts, dataIdentifier);
            value = result.value;
            conditionalised = result.conditionalised;
        }

        let conditonaliser = this.isConditionalised(row, dataIdentifier);

        return (
            <div ref={this.props.selectableRef} className="context-container">
                <div onClick={e => this.props.showConditionalPopover(e, conditonaliser.conditions, rowIndex, dataIdentifier)} className={'conditionalised-flag ' + (!isDiscount ? 'disabled' : '') + (conditonaliser.conditionalised ? 'active' : '')} />
                {isDiscount && (
                    <Fragment>
                        <div
                            onClick={e => {
                                this.props.toggleTypePopover(e, rowIndex, dataIdentifier);
                            }}
                            className={'type-selector ' + discountKey}
                        >
                            {discountKey.toUpperCase()}
                        </div>
                    </Fragment>
                )}

                <NumberFormat
                    decimalScale={dataItem.format.decimalPlaces}
                    fixedDecimalScale={true}
                    // getInputRef={this.props.cellRefs[`R${rowIndex}D${dataIdentifier}`].ref}
                    disabled={!this.state.isActive}
                    className={'data-table-input right table-form-control'}
                    onValueChange={inputValue => {
                        if (this.state.isActive) {
                            if (isDiscount && inputValue.floatValue < 0) inputValue.floatValue = 0;
                            this.setState({ changed: true, value: inputValue.floatValue });
                        }
                    }}
                    value={this.state.value}
                    thousandSeparator={true}
                    prefix={prefix}
                    suffix={suffix}
                />
            </div>
        );
    }
}
