import axios from "axios";
import { API_ROOT } from "../../../config/config";
import Papa from "papaparse";

// Import Base URL

// GET CONTRACTS
export const GET_EQUIPMENT_PENDING = "GET_EQUIPMENT_PENDING";
export const GET_EQUIPMENT_FULFILLED = "GET_EQUIPMENT_FULFILLED";
export const GET_EQUIPMENT_REJECTED = "GET_EQUIPMENT_REJECTED";

// UPLOAD EQUIPMENT DATA
export const UPLOAD_EQUIPMENT_PENDING = "UPLOAD_EQUIPMENT_PENDING";
export const UPLOAD_EQUIPMENT_FULFILLED = "UPLOAD_EQUIPMENT_FULFILLED";
export const UPLOAD_EQUIPMENT_REJECTED = "UPLOAD_EQUIPMENT_REJECTED";

// UPLOAD ROLLING SALES DATA LIST
export const GET_ROLLING_SALES_DATA_PENDING = "GET_ROLLING_SALES_DATA_PENDING";
export const GET_ROLLING_SALES_DATA_FULFILLED = "GET_ROLLING_SALES_DATA_FULFILLED";
export const GET_ROLLING_SALES_DATA_REJECTED = "GET_ROLLING_SALES_DATA_REJECTED";

// GET ROLLING SALES DATA
export const GET_ROLLING_SALES_META_DATA_PENDING = "GET_ROLLING_SALES_META_DATA_PENDING";
export const GET_ROLLING_SALES_META_DATA_FULFILLED = "GET_ROLLING_SALES_META_DATA_FULFILLED";
export const GET_ROLLING_SALES_META_DATA_REJECTED = "GET_ROLLING_SALES_META_DATA_REJECTED";

// UPLOAD ROLLING SALES DATA LIST
export const UPLOAD_ROLLING_SALES_DATA_PENDING = "UPLOAD_ROLLING_SALES_DATA_PENDING";
export const UPLOAD_ROLLING_SALES_DATA_FULFILLED = "UPLOAD_ROLLING_SALES_DATA_FULFILLED";
export const UPLOAD_ROLLING_SALES_DATA_REJECTED = "UPLOAD_ROLLING_SALES_DATA_REJECTED";

// GET FLOOR PRICE MATRIX META DATA
export const GET_FLOOR_PRICE_MATRIX_META_DATA_PENDING = "GET_FLOOR_PRICE_MATRIX_META_DATA_PENDING";
export const GET_FLOOR_PRICE_MATRIX_META_DATA_FULFILLED = "GET_FLOOR_PRICE_MATRIX_META_DATA_FULFILLED";
export const GET_FLOOR_PRICE_MATRIX_META_DATA_REJECTED = "GET_FLOOR_PRICE_MATRIX_META_DATA_REJECTED";

// UPLOAD FLOOR PRICE MATRIX DATA
export const UPLOAD_FLOOR_PRICE_MATRIX_PENDING = "UPLOAD_FLOOR_PRICE_MATRIX_PENDING";
export const UPLOAD_FLOOR_PRICE_MATRIX_FULFILLED = "UPLOAD_FLOOR_PRICE_MATRIX_FULFILLED";
export const UPLOAD_FLOOR_PRICE_MATRIX_REJECTED = "UPLOAD_FLOOR_PRICE_MATRIX_REJECTED";

// GLOBAL DETAILS
export const SAVE_GLOBAL_DETAILS_PENDING = "SAVE_GLOBAL_DETAILS_PENDING";
export const SAVE_GLOBAL_DETAILS_FULFILLED = "SAVE_GLOBAL_DETAILS_FULFILLED";
export const SAVE_GLOBAL_DETAILS_REJECTED = "SAVE_GLOBAL_DETAILS_REJECTED";
export const GET_GLOBAL_DETAILS_PENDING = "GET_GLOBAL_DETAILS_PENDING";
export const GET_GLOBAL_DETAILS_FULFILLED = "GET_GLOBAL_DETAILS_FULFILLED";
export const GET_GLOBAL_DETAILS_REJECTED = "GET_GLOBAL_DETAILS_REJECTED";

// UPLOAD MATERIAL DATA
export const UPLOAD_MATERIALS_PENDING = "UPLOAD_MATERIALS_PENDING";
export const UPLOAD_MATERIALS_FULFILLED = "UPLOAD_MATERIALS_FULFILLED";
export const UPLOAD_MATERIALS_FAILED = "UPLOAD_MATERIALS_FAILED";

//UPLOAD SEGMENT DISCOUNT DATA
export const UPLOAD_SEGMENT_PENDING = "UPLOAD_SEGMENT_PENDING";
export const UPLOAD_SEGMENT_FULFILLED = "UPLOAD_SEGMENT_FULFILLED";
export const UPLOAD_SEGMENT_FAILED = "UPLOAD_SEGMENT_FAILED";

//GET SEGMENT DISCOUNT DATA
export const GET_SEGMENT_PENDING = "GET_SEGMENT_PENDING";
export const GET_SEGMENT_FULFILLED = "GET_SEGMENT_FULFILLED";
export const GET_SEGMENT_FAILED = "GET_SEGMENT_FAILED";

// ACTION GENERATORS
/*
    Retrieves all the equipment.
*/

const getEquipment = () => (dispatch) => {
    dispatch({
        type: GET_EQUIPMENT_PENDING,
    });

    axios
        .get(API_ROOT + `/contracts?type=${contractType}`)
        .then((res) => {
            const contracts = res.data;

            /* Fulfil the request for contracts, since the API succceded, pass the contracts data to the reducer. */

            dispatch({
                type: GET_EQUIPMENT_FULFILLED,
                payload: contracts,
            });
        })
        .catch((error) => {
            console.log("An error occurred requesting equipment.", error);

            /* Reject the request for contracts, since the API failed. */

            dispatch({
                type: GET_EQUIPMENT_REJECTED,
            });
        });
};

/*
    Retrieves all the rolling sales data list.
*/
const getRollingSalesMetaData = () => (dispatch) => {
    dispatch({
        type: GET_ROLLING_SALES_META_DATA_PENDING,
    });

    axios
        .get(API_ROOT + "/rollingSales/meta")
        .then((res) => {
            const salesData = res.data;

            dispatch({
                type: GET_ROLLING_SALES_META_DATA_FULFILLED,
                payload: salesData,
            });
        })
        .catch((error) => {
            console.log("An error occurred requesting sales data.", error);
            dispatch({
                type: GET_ROLLING_SALES_META_DATA_REJECTED,
            });
        });
};

/*
    Retrieves all the rolling sales data list.
*/
const getSegmentDiscountMetaData = () => (dispatch) => {
    dispatch({
        type: GET_SEGMENT_PENDING,
    });

    axios
        .get(API_ROOT + "/segmentDiscount/meta")
        .then((res) => {
            const data = res.data;
            dispatch({
                type: GET_SEGMENT_FULFILLED,
                payload: data,
            });
        })
        .catch((error) => {
            console.log("An error occurred requesting segment data.", error);
            dispatch({
                type: GET_SEGMENT_FAILED,
            });
        });
};

/*
    Retrieves all the floor price matrix meta data.
*/
const getFloorPriceMatrixMetaData = () => (dispatch) => {
    dispatch({
        type: GET_FLOOR_PRICE_MATRIX_META_DATA_PENDING,
    });

    axios
        .get(API_ROOT + "/floorPriceMatrix/meta")
        .then((res) => {
            dispatch({
                type: GET_FLOOR_PRICE_MATRIX_META_DATA_FULFILLED,
                payload: res.data,
            });
        })
        .catch((error) => {
            console.log("An error occurred requesting sales data.", error);
            dispatch({
                type: GET_FLOOR_PRICE_MATRIX_META_DATA_REJECTED,
            });
        });
};

/*
    Retrieves all the rolling sales data list.
*/

const getRollingSalesData = (callback) => (dispatch) => {
    axios
        .get(API_ROOT + "/rollingSales/data")
        .then((res) => {
            const salesData = res.data;

            // Don't store in the reducers because this data is so large.
            callback(true, salesData);
        })
        .catch((error) => {
            console.log("An error occurred requesting sales data.", error);
            callback(false, null);
        });
};

/*
    Upload the sales data.
*/
const uploadSalesData = (file, progress, callback) => (dispatch) => {
    dispatch({
        type: UPLOAD_ROLLING_SALES_DATA_PENDING,
    });

    Papa.parse(file, {
        header: true,
        complete: function (results) {
            let data = results.data;

            axios
                .post(API_ROOT + "/rollingSales", data, {
                    onUploadProgress: (ProgressEvent) => {
                        progress({
                            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
                        });
                    },
                })
                .then((_) => {
                    /* Fulfil the request for sales data, since the API succceded, pass the sales data list to the reducer. */
                    dispatch({
                        type: UPLOAD_ROLLING_SALES_DATA_FULFILLED,
                    });

                    callback(true);
                })
                .catch((error) => {
                    console.log("An error occurred uploading sales data.", error);

                    /* Reject the request for sales data, since the API failed. */

                    dispatch({
                        type: UPLOAD_ROLLING_SALES_DATA_REJECTED,
                    });
                    callback(false);
                });
        },
    });
};

/*
    Upload the sales data.
*/
const uploadSegmentData = (file, progress, complete) => (dispatch) => {
    dispatch({
        type: UPLOAD_SEGMENT_PENDING,
    });
    Papa.parse(file, {
        header: true,
        complete: function (results) {
            let data = results.data;

            axios
                .post(API_ROOT + "/segmentDiscount/upload", data, {
                    onUploadProgress: (ProgressEvent) => {
                        progress({
                            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
                        });
                    },
                })
                .then((_) => {
                    dispatch({
                        type: UPLOAD_SEGMENT_FULFILLED,
                    });
                    complete(true);

                })
                .catch((error) => {
                    console.log("An error occurred uploading sales data.", error);
                    dispatch({
                        type: UPLOAD_SEGMENT_FAILED,
                    });
                    complete(false);

                });
        },
    });
};

/*
    Upload the floor price matrix data.
*/
const uploadFloorPriceMatrix = (file, progress, completion) => (dispatch) => {
    dispatch({
        type: UPLOAD_FLOOR_PRICE_MATRIX_PENDING,
    });

    Papa.parse(file, {
        header: true,
        complete: function (results) {
            let data = results.data;

            axios
                .post(API_ROOT + "/floorPriceMatrix", data, {
                    onUploadProgress: (ProgressEvent) => {
                        progress({
                            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
                        });
                    },
                })
                .then((_) => {
                    /* Fulfil the request for sales data, since the API succceded, pass the sales data list to the reducer. */
                    dispatch({
                        type: UPLOAD_FLOOR_PRICE_MATRIX_FULFILLED,
                    });

                    completion(true);
                })
                .catch((error) => {
                    console.log("An error occurred uploading sales data.", error);
                    /* Reject the request for sales data, since the API failed. */
                    dispatch({
                        type: UPLOAD_FLOOR_PRICE_MATRIX_REJECTED,
                    });

                    completion(false);
                });
        },
    });
};

/*
    Upload the material data.
*/
const uploadMaterials = (file, progress, completion) => (dispatch) => {
    dispatch({
        type: UPLOAD_MATERIALS_PENDING,
    });

    Papa.parse(file, {
        header: true,
        complete: function (results) {
            let data = results.data;

            axios
                .post(API_ROOT + "/materials", data, {
                    onUploadProgress: (ProgressEvent) => {
                        progress({
                            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
                        });
                    },
                })
                .then((_) => {
                    /* Fulfil the request for materials, since the API succceded, pass the materials list to the reducer. */
                    dispatch({
                        type: UPLOAD_MATERIALS_FULFILLED,
                    });

                    completion(true);
                })
                .catch((error) => {
                    console.log("An error occurred uploading materials.", error);

                    /* Reject the request for materials, since the API failed. */
                    dispatch({
                        type: UPLOAD_MATERIALS_FAILED,
                    });
                    completion(false, error.response.data);
                });
        },
    });
};

/*
    Upload the equipment data.
*/
const uploadEquipment = (file, progress, completion) => (dispatch) => {
    dispatch({
        type: UPLOAD_EQUIPMENT_PENDING,
    });

    Papa.parse(file, {
        header: true,
        complete: function (results) {
            let data = results.data;

            axios
                .post(API_ROOT + "/equipment", data, {
                    onUploadProgress: (ProgressEvent) => {
                        progress({
                            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
                        });
                    },
                })
                .then((_) => {
                    /* Fulfil the request for equipment, since the API succceded, pass the equipment list to the reducer. */
                    dispatch({
                        type: UPLOAD_EQUIPMENT_FULFILLED,
                    });

                    completion(true);
                })
                .catch((error) => {
                    console.log("An error occurred uploading equipment.", error);

                    /* Reject the request for equipment, since the API failed. */

                    dispatch({
                        type: UPLOAD_EQUIPMENT_REJECTED,
                    });

                    completion(false);
                });
        },
    });
};

/*
    Get the global app details.
*/
const getGlobalDetails = (callback) => (dispatch) => {
    dispatch({
        type: GET_GLOBAL_DETAILS_PENDING,
    });

    axios
        .get(API_ROOT + "/details")
        .then((res) => {
            dispatch({
                type: GET_GLOBAL_DETAILS_FULFILLED,
                payload: res.data,
            });

            callback(true, res.data);
        })
        .catch((error) => {
            console.log("An error occurred requesting global details.", error);
            dispatch({
                type: GET_GLOBAL_DETAILS_REJECTED,
            });

            callback(false, {});
        });
};

/*
    Save the global app details.
*/
const saveGlobalDetails = (details) => (dispatch) => {
    dispatch({
        type: SAVE_GLOBAL_DETAILS_PENDING,
    });

    axios
        .post(API_ROOT + "/details", { data: details })
        .then((_) => {
            dispatch({
                type: SAVE_GLOBAL_DETAILS_FULFILLED,
                payload: details,
            });
        })
        .catch((error) => {
            console.log("An error occurred saving global details.", error);
            dispatch({
                type: SAVE_GLOBAL_DETAILS_REJECTED,
            });
        });
};

// EXPORT ACTIONS
export { getEquipment, getRollingSalesData, getRollingSalesMetaData, getSegmentDiscountMetaData, uploadSalesData, getFloorPriceMatrixMetaData, uploadFloorPriceMatrix, saveGlobalDetails, getGlobalDetails, uploadMaterials, uploadEquipment, uploadSegmentData };
