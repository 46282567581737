import {
    CREATE_PRODUCT_GROUP_PENDING,
    CREATE_PRODUCT_GROUP_FULFILLED,
    CREATE_PRODUCT_GROUP_REJECTED,
    GET_PRODUCT_GROUPS_PENDING,
    GET_PRODUCT_GROUPS_FULFILLED,
    GET_PRODUCT_GROUPS_REJECTED,
    GET_PRODUCT_GROUP_PENDING,
    GET_PRODUCT_GROUP_FULFILLED,
    GET_PRODUCT_GROUP_REJECTED,
    DELETE_PRODUCT_GROUPS_PENDING,
    DELETE_PRODUCT_GROUPS_FULFILLED,
    DELETE_PRODUCT_GROUPS_REJECTED,
    SAVE_PRODUCT_GROUP_PENDING,
    SAVE_PRODUCT_GROUP_FULFILLED,
    SAVE_PRODUCT_GROUP_REJECTED,
    SAVE_PRODUCT_GROUP_COMPLIANCE_PENDING,
    SAVE_PRODUCT_GROUP_COMPLIANCE_FULFILLED,
    SAVE_PRODUCT_GROUP_COMPLIANCE_REJECTED,
    ADD_PRODUCT_GROUP_MATERIALS_PENDING,
    ADD_PRODUCT_GROUP_MATERIALS_FULFILLED,
    ADD_PRODUCT_GROUP_MATERIALS_REJECTED,
    DELETE_PRODUCT_GROUP_MATERIALS_PENDING,
    DELETE_PRODUCT_GROUP_MATERIALS_FULFILLED,
    DELETE_PRODUCT_GROUP_MATERIALS_REJECTED,
    REASSIGN_PRODUCT_GROUP_MATERIALS_PENDING,
    REASSIGN_PRODUCT_GROUP_MATERIALS_FULFILLED,
    REASSIGN_PRODUCT_GROUP_MATERIALS_REJECTED,
    SAVE_PRODUCT_GROUP_CONDITIONS_PENDING,
    SAVE_PRODUCT_GROUP_CONDITIONS_FULFILLED,
    SAVE_PRODUCT_GROUP_CONDITIONS_REJECTED,
    GET_PRODUCT_GROUPS_SUMMARY_PENDING,
    GET_PRODUCT_GROUPS_SUMMARY_FULFILLED,
    GET_PRODUCT_GROUPS_SUMMARY_REJECTED,
    SAVE_MATERIAL_GROUP_ALLOCATION_PENDING,
    SAVE_MATERIAL_GROUP_ALLOCATION_FULFILLED,
    SAVE_MATERIAL_GROUP_ALLOCATION_REJECTED,
    UPDATE_PRODUCT_GROUP_MATERIALS_PENDING,
    UPDATE_PRODUCT_GROUP_MATERIALS_FULFILLED,
    UPDATE_PRODUCT_GROUP_MATERIALS_REJECTED,
    UPDATE_SCENARIO_MATERIALS_PENDING,
    UPDATE_SCENARIO_MATERIALS_FULFILLED,
    UPDATE_SCENARIO_MATERIALS_REJECTED,
    SET_MATERIAL_ORDERING,
    RESET_MATERIAL_ORDERING,
} from "../actions/ProductGroupActions";

import { CLEAR_REDUCERS, PREPARE_FOR_CONTRACT_SWITCH } from "../actions/GeneralActions";
import { isDate } from "util";

// INITIALIZE STATE
const initialState = {
    productGroups: {},
    productGroup: {
        title: "",
        materials: {},
    },
    productGroupsSummary: {
        grossProfit: {
            value: 0,
            guidance: "normal",
        },
        totalPhysicalCases: {
            value: 0,
            guidance: "normal",
        },
        totalPaymentAllocation: {
            value: 0,
            guidance: "normal",
        },
    },
    fetching: false,
    fetched: false,
    failed: false,
    deleting: false,
    deleted: false,
    deleteFailed: false,
    adding: false,
    added: false,
    addFailed: false,
    updating: false,
    updated: false,
    updateFailed: false,
    materialCount: 0,
    materialOrdering: [],
};

// REDUCER
export const ProductGroupReducer = (state = initialState, action) => {
    let productGroups = Object.assign({}, state.productGroups);
    let productGroup = Object.assign({}, state.productGroup);
    let id;
    let ordering = [...state.materialOrdering];

    switch (action.type) {
        case CLEAR_REDUCERS:
            return initialState;
        case PREPARE_FOR_CONTRACT_SWITCH:
            return initialState;
        case CREATE_PRODUCT_GROUP_PENDING:
            return {
                ...state,
                adding: true,
                added: false,
                addFailed: false,
            };
        case CREATE_PRODUCT_GROUP_FULFILLED:
            return {
                ...state,
                adding: false,
                added: true,
                addFailed: false,
            };
        case CREATE_PRODUCT_GROUP_REJECTED:
            return {
                ...state,
                adding: false,
                added: false,
                addFailed: true,
            };
        case GET_PRODUCT_GROUPS_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_PRODUCT_GROUPS_FULFILLED:
            data = action.payload;
            let scenarioId = data.scenarioId;
            // Remove the scenarioId from the object, as this is no longer required.
            delete data["scenarioId"];
            productGroups[scenarioId] = data;

            return {
                ...state,
                productGroups,
                fetching: false,
                fetched: true,
                failed: false,
            };
        case GET_PRODUCT_GROUPS_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        case GET_PRODUCT_GROUP_PENDING:
            return {
                ...state,
                fetching: true,
                fetched: false,
                failed: false,
            };
        case GET_PRODUCT_GROUP_FULFILLED:
            return {
                ...state,
                productGroup: { id: action.payload.id, ...action.payload.data },
                fetching: false,
                fetched: true,
                failed: false,
            };
        case GET_PRODUCT_GROUP_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched: false,
                failed: true,
            };
        case DELETE_PRODUCT_GROUPS_PENDING:
            return {
                ...state,
                deleting: true,
                deleted: false,
                deleteFailed: false,
            };
        case DELETE_PRODUCT_GROUPS_FULFILLED:
            let deletedIds = action.payload.itemsToDelete;
            let mg1sToRemoveFromOrdering = [];

            // Remove the product group from the product groups object.
            deletedIds.forEach((id) => {
                // Need to remove MG1 from ordering if it matches the MG1 here
                let materialsInGroup = productGroups[action.payload.scenarioId][id].materials;
                mg1sToRemoveFromOrdering = mg1sToRemoveFromOrdering.concat(Object.keys(materialsInGroup));

                delete productGroups[action.payload.scenarioId][id];
            });

            ordering = ordering.filter((mg1) => {
                return mg1sToRemoveFromOrdering.includes(mg1) ? false : true;
            });

            // And remove it, if it is the current product group being shown.
            if (deletedIds.includes(productGroup.id)) {
                productGroup = {};
            }

            let productGroupKeys = Object.keys(productGroups);
            if (productGroupKeys.length === 1) {
                if (Object.keys(productGroups[productGroupKeys[0]]).length === 0) {
                    productGroups = {};
                }
            }

            return {
                ...state,
                productGroups,
                productGroup,
                deleting: false,
                deleted: true,
                deleteFailed: false,
                materialOrdering: ordering,
            };
        case DELETE_PRODUCT_GROUPS_REJECTED:
            return {
                ...state,
                deleting: false,
                deleted: false,
                deleteFailed: true,
            };
        case SAVE_PRODUCT_GROUP_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
                updateFailed: false,
            };
        case SAVE_PRODUCT_GROUP_FULFILLED:
            id = action.payload.id;
            data = action.payload.data;

            // Update the product group's general details.
            productGroups[id] = data;

            return {
                ...state,
                productGroup: data,
                productGroups,
                updating: false,
                updated: true,
                updateFailed: false,
            };
        case SAVE_PRODUCT_GROUP_REJECTED:
            return {
                ...state,
                updating: false,
                updated: false,
                updateFailed: true,
            };
        case SAVE_PRODUCT_GROUP_COMPLIANCE_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
                updateFailed: false,
            };
        case SAVE_PRODUCT_GROUP_COMPLIANCE_FULFILLED:
            id = action.payload.id;
            data = action.payload.complianceExpressions;

            // Update the product group's compliance data.
            productGroup.compliance = data;
            productGroups[id] = productGroup;

            return {
                ...state,
                productGroup,
                productGroups,
                updating: false,
                updated: true,
                updateFailed: false,
            };
        case SAVE_PRODUCT_GROUP_COMPLIANCE_REJECTED:
            return {
                ...state,
                updating: false,
                updated: false,
                updateFailed: true,
            };
        case DELETE_PRODUCT_GROUP_MATERIALS_PENDING:
            return {
                ...state,
                deleting: true,
                deleted: false,
                deleteFailed: false,
            };
        case DELETE_PRODUCT_GROUP_MATERIALS_FULFILLED:
            action.payload.data.forEach((deletedMaterialId) => {
                delete productGroup.materials[deletedMaterialId];
            });

            productGroups[action.payload.id] = productGroup;

            return {
                ...state,
                productGroups,
                productGroup,
                deleting: false,
                deleted: true,
                deleteFailed: false,
            };
        case DELETE_PRODUCT_GROUP_MATERIALS_REJECTED:
            return {
                ...state,
                deleting: false,
                deleted: false,
                deleteFailed: true,
            };
        case ADD_PRODUCT_GROUP_MATERIALS_PENDING:
            return {
                ...state,
                adding: true,
                added: false,
                addFailed: false,
            };
        case ADD_PRODUCT_GROUP_MATERIALS_FULFILLED:
            Object.keys(action.payload.data).forEach((productGroupId) => {
                let newProductGroup = action.payload.data[productGroupId];

                if (!productGroups.hasOwnProperty(action.payload.scenarioId)) {
                    productGroups[action.payload.scenarioId] = {};
                }

                productGroups[action.payload.scenarioId][productGroupId] = newProductGroup;

                if (ordering.length > 0) {
                    // Need to push all new MG1 ids to ordering array
                    ordering = ordering.concat(Object.keys(newProductGroup.materials));
                }
            });

            return {
                ...state,
                productGroups,
                productGroup,
                adding: false,
                added: true,
                addFailed: false,
                materialOrdering: ordering,
            };
        case ADD_PRODUCT_GROUP_MATERIALS_REJECTED:
            return {
                ...state,
                adding: false,
                added: false,
                addFailed: true,
            };
        case REASSIGN_PRODUCT_GROUP_MATERIALS_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
                updateFailed: false,
            };
        case REASSIGN_PRODUCT_GROUP_MATERIALS_FULFILLED:
            let data = action.payload.data;

            // Remove from the existing product group.
            delete productGroup.materials[data.materialGroupId];
            productGroups[data.existingProductGroupId] = productGroup;

            if (data.isNew) {
                productGroups[data.newProductGroupId] = {};
                productGroups[data.newProductGroupId] = data.newProductGroup;
            } else {
                productGroups[data.newProductGroupId].materials[data.materialGroupId] = data.materialData;
            }

            return {
                ...state,
                productGroups,
                productGroup,
                updating: false,
                updated: true,
                updateFailed: false,
            };
        case REASSIGN_PRODUCT_GROUP_MATERIALS_REJECTED:
            return {
                ...state,
                updating: false,
                updated: false,
                updateFailed: true,
            };
        case SAVE_PRODUCT_GROUP_CONDITIONS_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
                updateFailed: false,
            };
        case SAVE_PRODUCT_GROUP_CONDITIONS_FULFILLED:
            if (!productGroup.hasOwnProperty("discounts")) {
                productGroup.discounts = {};
            }

            let discountChanges = action.payload;

            Object.keys(discountChanges).map((discountChangeKeys) => {
                let discountChange = discountChanges[discountChangeKeys];
                productGroup.discounts[discountChangeKeys] = discountChange;
            });

            productGroups[productGroup.id] = productGroup;

            return {
                ...state,
                productGroup,
                productGroups,
                updating: false,
                updated: true,
                updateFailed: false,
            };
        case SAVE_PRODUCT_GROUP_CONDITIONS_REJECTED:
            return {
                ...state,
                updating: false,
                updated: false,
                updateFailed: true,
            };
        case GET_PRODUCT_GROUPS_SUMMARY_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
                updateFailed: false,
            };
        case GET_PRODUCT_GROUPS_SUMMARY_FULFILLED:
            return {
                ...state,
                updating: false,
                updated: true,
                updateFailed: false,
                productGroupsSummary: action.payload,
            };
        case GET_PRODUCT_GROUPS_SUMMARY_REJECTED:
            return {
                ...state,
                updating: false,
                updated: false,
                updateFailed: true,
            };
        case SAVE_MATERIAL_GROUP_ALLOCATION_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
                updateFailed: false,
            };
        case SAVE_MATERIAL_GROUP_ALLOCATION_FULFILLED:
            return {
                ...state,
                productGroups: action.payload,
                updating: false,
                updated: true,
                updateFailed: false,
            };
        case SAVE_MATERIAL_GROUP_ALLOCATION_REJECTED:
            return {
                ...state,
                updating: false,
                updated: false,
                updateFailed: true,
            };
        case UPDATE_PRODUCT_GROUP_MATERIALS_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
                updateFailed: false,
            };
        case UPDATE_PRODUCT_GROUP_MATERIALS_FULFILLED:
        // productGroups = Object.assign({}, state.productGroups);

        // productGroup = Object.assign({}, state.productGroup);
        // productGroup.materials = action.payload.data.materials;
        // productGroup.discounts = action.payload.data.discounts;

        // if (!productGroups[action.payload.scenarioId]) {
        //     productGroups[action.payload.scenarioId] = {};
        // }

        // productGroups[action.payload.scenarioId][action.payload.id] = productGroup;

        // return {
        //     ...state,
        //     productGroup,
        //     productGroups,
        //     updating: false,
        //     updated: true,
        //     updateFailed: false,
        // }
        case UPDATE_PRODUCT_GROUP_MATERIALS_REJECTED:
            return {
                ...state,
                updating: false,
                updated: false,
                updateFailed: false,
            };
        case UPDATE_SCENARIO_MATERIALS_PENDING:
            return {
                ...state,
                updating: true,
                updated: false,
                updateFailed: false,
            };
        case UPDATE_SCENARIO_MATERIALS_FULFILLED:
            action.payload.data.map((groupData) => {
                groupData.materials = {};
                groupData.materials[`${groupData.dataPoints.MG1}`] = groupData.dataPoints;
                productGroups[action.payload.scenarioId][groupData.productGroupId] = groupData;
            });

            return {
                ...state,
                productGroups,
                updating: false,
                updated: true,
                updateFailed: false,
            };
        case UPDATE_SCENARIO_MATERIALS_REJECTED:
            return {
                ...state,
                updating: false,
                updated: false,
                updateFailed: true,
            };
        case SET_MATERIAL_ORDERING:
            return {
                ...state,
                materialOrdering: action.payload,
            };
        case RESET_MATERIAL_ORDERING:
            return {
                ...state,
                materialOrdering: [],
            };
        default:
            return state;
    }
};
