import axios from "axios";
import { API_ROOT } from "../../../config/config";

// GET CONTRACT DETAILS
export const GET_SUBTRADE_CHANNEL_PENDING = "GET_SUBTRADE_CHANNEL_PENDING";
export const GET_SUBTRADE_CHANNEL_FULFILLED = "GET_SUBTRADE_CHANNEL_FULFILLED";
export const GET_SUBTRADE_CHANNEL_REJECTED = "GET_SUBTRADE_CHANNEL_REJECTED";

/*
    Retrieve all of the contract details
*/
const getGeneralDetails = (callback) => (dispatch) => {
    dispatch({
        type: GET_SUBTRADE_CHANNEL_PENDING,
    });

    axios
        .get(`${API_ROOT}/generalDetails`)
        .then((response) => {
            dispatch({
                type: GET_SUBTRADE_CHANNEL_FULFILLED,
                payload: response.data,
            });
            callback(true);
        })
        .catch((error) => {
            dispatch({
                type: GET_SUBTRADE_CHANNEL_REJECTED,
            });
            console.log("An error occurred retrieving contract details.", error);
            callback(false);
        });
};

export { getGeneralDetails };
