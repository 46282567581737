let backendHost;
let environment;
let approvalsHost;
const hostname = window && window.location && window.location.hostname;

if (process.env.env === "prod") {
    environment = "PROD";
    if (hostname === "localhost") {
        backendHost = "http://localhost:7071/api";
    } else {
        backendHost = "https://ccanzauesprdrsg016api.azurewebsites.net/api";
    }
    approvalsHost = "https://ccanzauesprdrsg016api2.azurewebsites.net/api";

} else if (process.env.env === "dev") {
    environment = "DEV";
    if (hostname === "localhost") {
        backendHost = "http://localhost:7071/api";
    } else {
        backendHost = "https://ccaauauesdevrsg006api.azurewebsites.net/api";
    }
    approvalsHost = "http://localhost:9005/api"

} else if (process.env.env === "uat") {
    environment = "UAT";

    if (hostname === "localhost") {
        backendHost = "http://localhost:7071/api";
    } else {
        backendHost = "https://ccaauauesdevrsg006api.azurewebsites.net/api";
    }
    approvalsHost = "https://ccaauauesdevrsg006api2.azurewebsites.net/api";
}

export const API_ROOT = `${backendHost}`;
export const APPROVALS_ROOT = `${approvalsHost}`
export const ENVIRONMENT = environment;
