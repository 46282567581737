// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ContextPage } from "../../containers/layout/ContextPage";
import { Card } from "../../containers/card/Card";
import { getUsers, createNewUser } from "../../state/actions/UserActions";
import { CardRow } from "../../containers/card/CardRow";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import Modalised from "../../containers/layout/Modalised";
import { FloatingBar } from "../../containers/layout/FloatingBar";
import { FloatingButton } from "../../containers/inputs/FloatingButton";
import FeatherIcon from "feather-icons-react";
import { Checkbox } from "../../containers/inputs/Checkbox";
import { updateUser } from "../../state/actions/UserActions";

// COMPONENT
class UsersPage extends React.Component {
    constructor() {
        super();

        this.state = {
            users: [],
            showNewUserModal: false,
            name: "",
            password: "",
            email: "",
        };

        this.createNewUser = this.createNewUser.bind(this);
    }

    componentDidMount() {
        this.props.getUsers();
    }

    componentDidUpdate() {
        if (JSON.stringify(this.state.users) !== JSON.stringify(this.props.users)) {
            this.setState({ users: this.props.users });
        }
    }

    handleNewUser() {
        this.setState({ showNewUserModal: !this.state.showNewUserModal });
    }

    createNewUser() {
        this.props.createNewUser(this.state.name, this.state.password, this.state.email, (success) => {
            if (success) {
                this.setState({ showNewUserModal: false });

                // Fetch users...
                this.props.getUsers();
            }
        });
    }

    render() {
        return (
            <Fragment>
                <Modalised
                    title={"New User"}
                    showing={this.state.showNewUserModal}
                    handleClose={() => {
                        this.setState({ showNewUserModal: false });
                    }}
                    action={
                        <div
                            onClick={() => {
                                this.setState({ showNewUserModal: false });
                            }}
                            className="text-link"
                        >
                            Close
                        </div>
                    }
                >
                    <div className="modalised-content">
                        <Form>
                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Input
                                    onChange={(e) => {
                                        this.setState({ name: e.target.value });
                                    }}
                                    value={this.state.name}
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="e.g. Jo Doe"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input
                                    onChange={(e) => {
                                        this.setState({ email: e.target.value });
                                    }}
                                    value={this.state.email}
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="e.g. jo@test.com"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="password">Password</Label>
                                <Input
                                    onChange={(e) => {
                                        this.setState({ password: e.target.value });
                                    }}
                                    value={this.state.password}
                                    type="password"
                                    name="password"
                                    id="password"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Button
                                    block
                                    onClick={() => {
                                        this.createNewUser();
                                    }}
                                >
                                    Create New User
                                </Button>
                            </FormGroup>
                        </Form>
                    </div>
                </Modalised>
                <ContextPage fetching={false} fetched={true} title={"Users"}>
                    <Card noTitle>
                        <CardRow label="User's Name">Current Role, Admin Assignment</CardRow>
                        {this.state.users.map((user, index) => {
                            return (
                                <CardRow label={user.name} bottom={index === this.state.users.length - 1}>
                                    <Checkbox
                                        name={index}
                                        key={index}
                                        label={user.role}
                                        checked={user.role === "admin"}
                                        handleClick={checked => {
                                            let prevUsers = [...this.state.users];
                                            prevUsers[index].role = checked ? "admin" : "user";
                                            this.props.updateUser(prevUsers[index]._id, prevUsers[index].role);
                                            this.setState({ users: prevUsers })
                                        }}
                                    />
                                </CardRow>
                            );
                        })}
                    </Card>
                    {/* <FloatingBar>
                        <FloatingButton
                            action={() => this.handleNewUser()}
                            label={
                                <div>
                                    <FeatherIcon className="fab-button-icon" icon="plus-circle" />
                                    New User
                                </div>
                            }
                        />
                    </FloatingBar> */}
                </ContextPage>
            </Fragment>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { users, fetching, fetched, failed } = state.userReducer;
    return { users, fetching, fetched, failed };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getUsers, createNewUser, updateUser }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersPage));

// EXPORT COMPONENT

export { hoc as UsersPage };
