export const GET_CUSTOMERS_PENDING = "GET_CUSTOMERS_PENDING";
export const GET_CUSTOMERS_FULFILLED = "GET_CUSTOMERS_FULFILLED";
export const GET_CUSTOMERS_REJECTED = "GET_CUSTOMERS_REJECTED";

// ACTION GENERATORS

/*
    Retrieves all the customers with existing contracts.
*/

const getCustomers = () => (dispatch) => {
    dispatch({
        type: GET_CUSTOMERS_PENDING,
    });

    dispatch({
        type: GET_CUSTOMERS_FULFILLED,
        payload: [
            {
                customerName: "Springs Cafe",
                customerId: "123",
            },
            {
                customerName: "Jims Cafe",
                customerId: "124",
            },
        ],
    });
};

// EXPORT ACTIONS
export { getCustomers };
