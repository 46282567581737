import axios from "axios";
import { API_ROOT } from "../../../config/config";
import jwt_decode from "jwt-decode";

export const LOGIN_USER_PENDING = "LOGIN_USER_PENDING";
export const LOGIN_USER_FULFILLED = "LOGIN_USER_FULFILLED";
export const LOGIN_USER_REJECTED = "LOGIN_USER_REJECTED";

export const LOGOUT_USER_PENDING = "LOGOUT_USER_PENDING";
export const LOGOUT_USER_FULFILLED = "LOGOUT_USER_FULFILLED";
export const LOGOUT_USER_REJECTED = "LOGOUT_USER_REJECTED";

export const CHECK_SSO_PENDING = "CHECK_SSO_PENDING";
export const CHECK_SSO_FULFILLED = "CHECK_SSO_FULFILLED";
export const CHECK_SSO_REJECTED = "CHECK_SSO_REJECTED";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

export const PIN_TO_SIDEBAR = "PIN_TO_SIDEBAR";
export const UNPIN_FROM_SIDEBAR = "UNPIN_FROM_SIDEBAR";

export const TOGGLE_LEFT_DETAIL = "TOGGLE_LEFT_DETAIL";

export const ADD_DATA_POINT_TO_GRAPH = "ADD_DATA_POINT_TO_GRAPH";
export const REMOVE_DATA_POINT_FROM_GRAPH = "REMOVE_DATA_POINT_FROM_GRAPH";

// Users
export const GET_USERS_PENDING = "GET_USERS_PENDING";
export const GET_USERS_FULFILLED = "GET_USERS_FULFILLED";
export const GET_USERS_REJECTED = "GET_USERS_REJECTED";

// Update user
export const UPDATE_USER_PENDING = "UPDATE_USER_PENDING";
export const UPDATE_USER_FULFILLED = "UPDATE_USER_FULFILLED";
export const UPDATE_USER_REJECTED = "UPDATE_USER_REJECTED";

// User
export const GET_USER_PENDING = "GET_USER_PENDING";
export const GET_USER_FULFILLED = "GET_USER_FULFILLED";
export const GET_USER_REJECTED = "GET_USER_REJECTED";

// Create User
export const CREATE_USER_PENDING = "CREATE_USER_PENDING";
export const CREATE_USER_FULFILLED = "CREATE_USER_FULFILLED";
export const CREATE_USER_REJECTED = "CREATE_USER_REJECTED";

// Set App Version
export const SET_VERSION = "SET_VERSION";

export const INCREASE_SUMMARY_YEAR = "INCREASE_SUMMARY_YEAR";
export const DECREASE_SUMMARY_YEAR = "DECREASE_SUMMARY_YEAR";

import Cookies from "universal-cookie";
import { constants } from "crypto";
const cookies = new Cookies();

// ACTION GENERATORS

/*
    Set the version of the app, so we can keep track of whether migration is needed for future updates.
*/

const setVersion = (versionNumber) => (dispatch) => {
    dispatch({
        type: SET_VERSION,
        payload: versionNumber,
    });
};

/*
    Get the product range summary...
*/
const increaseSummaryYear = () => (dispatch) => {
    dispatch({
        type: INCREASE_SUMMARY_YEAR,
    });
};

/*
    Get the product range summary...
*/
const decreaseSummaryYear = () => (dispatch) => {
    dispatch({
        type: DECREASE_SUMMARY_YEAR,
    });
};

const loginWithToken = (token) => (dispatch) => {
    dispatch({
        type: LOGIN_USER_PENDING,
    });

    var decoded_token = jwt_decode(token);

    let config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    axios
        .post(`${API_ROOT}/user/details/${decoded_token.oid}`, {}, config)
        .then((response) => {
            const companyID = response.companyID;
            const companyName = response.companyName;
            delete response.companyID;
            delete response.companyName;

            let mergedUserData = { ...response.data, uid: response.data.id, authToken: token };
            dispatch({
                type: GET_USER_FULFILLED,
                payload: mergedUserData,
            });

            dispatch({
                type: LOGIN_USER_FULFILLED,
                payload: {
                    user: response.data,
                    companyID: companyID,
                    companyName: companyName,
                    userEmail: response.email,
                },
            });
        })
        .catch((error) => {
            setUpNewLogin(dispatch, token);
        });
};

const setUpNewLogin = (dispatch, token) => {
    axios
        .post(`${API_ROOT}/user`, {}, {})
        .then((response) => {
            const companyID = response.companyID;
            const companyName = response.companyName;
            delete response.companyID;
            delete response.companyName;

            let mergedUserData = { ...response.data, uid: response.data.id, authToken: token };
            dispatch({
                type: GET_USER_FULFILLED,
                payload: mergedUserData,
            });

            dispatch({
                type: LOGIN_USER_FULFILLED,
                payload: {
                    user: response,
                    companyID: companyID,
                    companyName: companyName,
                    userEmail: response.email,
                },
            });
        })
        .catch((error) => {
            console.log("Error retrieving user data:", error);
            dispatch({ type: GET_USER_REJECTED, payload: error });
            callback(false);
        });
};

const logoutAction = () => (dispatch) => {
    dispatch({
        type: LOGOUT_USER_PENDING,
    });

    cookies.remove("authToken");

    dispatch({
        type: LOGOUT_USER_FULFILLED,
    });
};

const checkForOpenId = (email) => (dispatch) => {
    dispatch({
        type: CHECK_SSO_PENDING,
    });
    axios
        .get(API_ROOT + "/requiresOpenId?email=" + email)
        .then((res) => {
            const shouldUseSSO = res.data;
            dispatch({
                type: CHECK_SSO_FULFILLED,
                payload: shouldUseSSO,
            });
        })
        .catch((error) => {
            dispatch({
                type: CHECK_SSO_REJECTED,
                payload: false,
            });
        });
};

/*
    Add a data point to the graph.
*/

const addDataPointToGraph = (dataPoint, format) => (dispatch) => {
    dispatch({
        type: ADD_DATA_POINT_TO_GRAPH,
        payload: { dataPoint, format },
    });
};

/*
    Remove a data point from the graph.
*/

const removeDataPointFromGraph = (dataPoint) => (dispatch) => {
    dispatch({
        type: REMOVE_DATA_POINT_FROM_GRAPH,
        payload: dataPoint,
    });
};

const toggleSidebarAction = () => (dispatch) => {
    dispatch({
        type: TOGGLE_SIDEBAR,
    });
};

/*
    Pin a sidebar snippet.
*/

const pinToSidebar = (pageIdentifier) => (dispatch) => {
    dispatch({
        type: PIN_TO_SIDEBAR,
        payload: pageIdentifier,
    });
};

/*
    Unpin a sidebar snippet.
*/

const unpinFromSidebar = (pageIdentifier) => (dispatch) => {
    dispatch({
        type: UNPIN_FROM_SIDEBAR,
        payload: pageIdentifier,
    });
};

/*
    Unpin a sidebar snippet.
*/

const toggleLeftDetail = () => (dispatch) => {
    dispatch({
        type: TOGGLE_LEFT_DETAIL,
    });
};

/*
    Get a list of users
*/

const getUsers = () => (dispatch) => {
    dispatch({
        type: GET_USERS_PENDING,
    });

    axios
        .get(API_ROOT + "/users")
        .then((res) => {
            /* Fulfil the request for users. */
            dispatch({
                type: GET_USERS_FULFILLED,
                payload: res.data,
            });
        })
        .catch((error) => {
            console.log("An error occurred requesting contracts.", error);

            /* Reject the request for users, since the API failed. */
            dispatch({
                type: GET_USERS_REJECTED,
            });
        });
};

const updateUser = (id, role, callback) => (dispatch) => {
    dispatch({
        type: UPDATE_USER_PENDING,
    });
    axios
        .post(API_ROOT + `/users/update?id=${id}&role=${role}`)
        .then((res) => {
            dispatch({
                type: UPDATE_USER_FULFILLED
            });
            callback(true);
        }).catch((error) => {
            console.log("An error occurred updating the user.", error);

            /* Reject the request for users, since the API failed. */
            dispatch({
                type: UPDATE_USER_REJECTED,
            });

            callback(false);
        });
};

/*
    Create a new of user
*/

const createNewUser = (name, password, email, callback) => (dispatch) => {
    dispatch({
        type: CREATE_USER_PENDING,
    });

    axios
        .post(API_ROOT + `/users?name=${name}&password=${password}&email=${email}`)
        .then((res) => {
            /* Fulfil the request for users. */
            dispatch({
                type: CREATE_USER_FULFILLED,
                payload: res.data,
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred creating the user.", error);

            /* Reject the request for users, since the API failed. */
            dispatch({
                type: CREATE_USER_REJECTED,
            });

            callback(false);
        });
};

// EXPORT ACTIONS
export { updateUser, loginWithToken, logoutAction as logout, checkForOpenId, toggleSidebarAction as toggleSidebar, pinToSidebar, unpinFromSidebar, toggleLeftDetail, addDataPointToGraph, removeDataPointFromGraph, getUsers, createNewUser, setVersion, increaseSummaryYear, decreaseSummaryYear };
