// IMPORT PACKAGE REFERENCES
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// UI
import { IndicatorTile } from "./IndicatorTile";

import { EditableInputTypes } from "../../../enums/EditableInputTypes";

// COMPONENT
class NonFinancialIndicatorRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            indicators: [],
            nonFinancialId: null,
        };

        this.generateIndicators = this.generateIndicators.bind(this);
    }

    generateIndicators() {
        let indicators = [];
        let nonFinancialId = this.props.data.nonFinancialId;
        indicators.push({ title: "Capital Value", value: this.props.nonFinancials[nonFinancialId]["value"], type: EditableInputTypes.DOLLAR });
        indicators.push({ title: "Quantity", value: this.props.nonFinancials[nonFinancialId]["quantity"] });

        this.setState({ indicators: indicators });
    }

    componentDidMount() {
        this.generateIndicators();
    }

    componentDidUpdate(prevProps) {
        let nonFinancialId = this.props.data.nonFinancialId;
        if (JSON.stringify(this.props.nonFinancials[nonFinancialId]) !== JSON.stringify(prevProps.nonFinancials[nonFinancialId])) {
            this.generateIndicators();
        }
    }

    render() {
        return (
            <div className="indicator-row">
                {this.state.indicators.map((data, index) => {
                    return <IndicatorTile key={index} title={data.title} value={data.value} type={data.type} />;
                })}
            </div>
        );
    }
}

NonFinancialIndicatorRow.propTypes = {
    nonFinancials: PropTypes.object.isRequired,
};

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { nonFinancials } = state.nonFinancialReducer;
    return { nonFinancials };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(NonFinancialIndicatorRow));

// EXPORT COMPONENT

export { hoc as NonFinancialIndicatorRow };
