import axios from "axios";
import { API_ROOT } from "../../../config/config";
import moment from "moment";
import "moment/locale/en-nz";
import { redux } from "../../App";

// GET PRODUCT RANGE SUMMARY
export const GET_PRODUCT_RANGE_SUMMARY_PENDING = "GET_PRODUCT_RANGE_SUMMARY_PENDING";
export const GET_PRODUCT_RANGE_SUMMARY_FULFILLED = "GET_PRODUCT_RANGE_SUMMARY_FULFILLED";
export const GET_PRODUCT_RANGE_SUMMARY_REJECTED = "GET_PRODUCT_RANGE_SUMMARY_REJECTED";

// GET MATERIAL GROUP DATA POINT SUMMARY
export const GET_MATERIAL_GROUP_DATA_POINT_SUMMARY_PENDING = "GET_MATERIAL_GROUP_DATA_POINT_SUMMARY_PENDING";
export const GET_MATERIAL_GROUP_DATA_POINT_SUMMARY_FULFILLED = "GET_MATERIAL_GROUP_DATA_POINT_SUMMARY_FULFILLED";
export const GET_MATERIAL_GROUP_DATA_POINT_SUMMARY_REJECTED = "GET_MATERIAL_GROUP_DATA_POINT_SUMMARY_REJECTED";

// GET CONTRACT DATA POINT SUMMARY
export const GET_CONTRACT_DATA_POINT_SUMMARY_PENDING = "GET_CONTRACT_DATA_POINT_SUMMARY_PENDING";
export const GET_CONTRACT_DATA_POINT_SUMMARY_FULFILLED = "GET_CONTRACT_DATA_POINT_SUMMARY_FULFILLED";
export const GET_CONTRACT_DATA_POINT_SUMMARY_REJECTED = "GET_CONTRACT_DATA_POINT_SUMMARY_REJECTED";

// GET CONTRACT SUMMARY
export const GET_CONTRACT_SUMMARY_SNAPSHOT_PENDING = "GET_CONTRACT_SUMMARY_SNAPSHOT_PENDING";
export const GET_CONTRACT_SUMMARY_SNAPSHOT_FULFILLED = "GET_CONTRACT_SUMMARY_SNAPSHOT_FULFILLED";
export const GET_CONTRACT_SUMMARY_SNAPSHOT_REJECTED = "GET_CONTRACT_SUMMARY_SNAPSHOT_REJECTED";

// GET APPROVAL REQUIREMENT
export const GET_APPROVAL_PENDING = "GET_APPROVAL_PENDING";
export const GET_APPROVAL_FULFILLED = "GET_APPROVAL_FULFILLED";
export const GET_APPROVAL_FAILED = "GET_APPROVAL_FAILED";

// GET ANNUAL INVESTMENT SUMMARY
export const GET_ANNUAL_INVESTMENT_SUMMARY_FULFILLED = "GET_ANNUAL_INVESTMENT_SUMMARY_FULFILLED";
export const GET_ANNUAL_INVESTMENT_SUMMARY_PENDING = "GET_ANNUAL_INVESTMENT_SUMMARY_PENDING";
export const GET_ANNUAL_INVESTMENT_SUMMARY_FAILED = "GET_ANNUAL_INVESTMENT_SUMMARY_FAILED";

export const GET_ANNUAL_INVESTMENT_SUMMARY_COMPARISON_FULFILLED = "GET_ANNUAL_INVESTMENT_SUMMARY_COMPARISON_FULFILLED";
export const GET_ANNUAL_INVESTMENT_SUMMARY_COMPARISON_PENDING = "GET_ANNUAL_INVESTMENT_SUMMARY_COMPARISON_PENDING";
export const GET_ANNUAL_INVESTMENT_SUMMARY_COMPARISON_FAILED = "GET_ANNUAL_INVESTMENT_SUMMARY_COMPARISON_FAILED";

export const GET_ANNUAL_INVESTMENT_SUMMARIES_COMPARISON_FULFILLED = "GET_ANNUAL_INVESTMENT_SUMMARIES_COMPARISON_FULFILLED";
export const GET_ANNUAL_INVESTMENT_SUMMARIES_COMPARISON_PENDING = "GET_ANNUAL_INVESTMENT_SUMMARIES_COMPARISON_PENDING";
export const GET_ANNUAL_INVESTMENT_SUMMARIES_COMPARISON_FAILED = "GET_ANNUAL_INVESTMENT_SUMMARIES_COMPARISON_FAILED";

// GET MATERIAL SUMMARY
export const GET_MATERIAL_SUMMARY_PENDING = "GET_MATERIAL_SUMMARY_PENDING";
export const GET_MATERIAL_SUMMARY_FAILED = "GET_MATERIAL_SUMMARY_FAILED";
export const GET_MATERIAL_SUMMARY_FULFILLED = "GET_MATERIAL_SUMMARY_FULFILLED";

// GET SCENARIO PROFIT STORY SUMMARY
export const GET_SCENARIO_PROFIT_STORY_SUMMARY_PENDING = "GET_SCENARIO_PROFIT_STORY_SUMMARY_PENDING";
export const GET_SCENARIO_PROFIT_STORY_SUMMARY_FULFILLED = "GET_SCENARIO_PROFIT_STORY_SUMMARY_FULFILLED";
export const GET_SCENARIO_PROFIT_STORY_SUMMARY_FAILED = "GET_SCENARIO_PROFIT_STORY_SUMMARY_FAILED";

// GET SCENARIO PROFIT STORY SUMMARY
export const GET_SCENARIO_MATERIAL_DATA_POINTS_PENDING = "GET_SCENARIO_MATERIAL_DATA_POINTS_PENDING";
export const GET_SCENARIO_MATERIAL_DATA_POINTS_FULFILLED = "GET_SCENARIO_MATERIAL_DATA_POINTS_FULFILLED";
export const GET_SCENARIO_MATERIAL_DATA_POINTS_FAILED = "GET_SCENARIO_MATERIAL_DATA_POINTS_FAILED";

export const CLEAR_CONTRACT_SUMMARY_SNAPSHOT = "CLEAR_CONTRACT_SUMMARY_SNAPSHOT";

export const ADDITIONAL_COMMENTS_PENDING = "ADDITIONAL_COMMENTS_PENDING";
export const ADDITIONAL_COMMENTS_FULFILLED = "ADDITIONAL_COMMENTS_FULFILLED";
export const ADDITIONAL_COMMENTS_REJECTED = "ADDITIONAL_COMMENTS_REJECTED";
// ACTION GENERATORS

/*
    Get the product range summary...
*/
const getProductRangeSummary = (contractId, scenarioId, productRangeId, callback) => (dispatch) => {
    dispatch({
        type: GET_PRODUCT_RANGE_SUMMARY_PENDING,
    });

    axios
        .get(API_ROOT + `/contracts/${contractId}/${scenarioId}/summary?productRange=${productRangeId}`)
        .then((res) => {
            const summary = res.data;

            /* Fulfil the request for the product range summary. */
            dispatch({
                type: GET_PRODUCT_RANGE_SUMMARY_FULFILLED,
                payload: summary,
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred requesting contracts.", error);

            /* Reject the request for the product range summary, since the API failed. */
            dispatch({
                type: GET_PRODUCT_RANGE_SUMMARY_REJECTED,
            });

            callback(true);
        });
};

/*
    Get the product range summary...
*/
const clearCurrentContractSummarySnapshot = () => (dispatch) => {
    dispatch({
        type: CLEAR_CONTRACT_SUMMARY_SNAPSHOT,
    });
};

const getAdditionalComments = (contractId, scenarioId) => (dispatch) => {
    dispatch({
        type: ADDITIONAL_COMMENTS_PENDING,
    });

    axios.get(API_ROOT + `/contracts/${contractId}/${scenarioId}/summary/additionalDetails`)
        .then((res) => {
            dispatch({
                type: ADDITIONAL_COMMENTS_FULFILLED,
                payload: res.data,
            });
        })
        .catch((error) => {
            console.log("An error occurred getting extra contract data", error);
            dispatch({
                type: ADDITIONAL_COMMENTS_REJECTED,
            });
        });
}

const postAdditionalComments = (contractId, scenarioId, data) => (dispatch) => {
    dispatch({
        type: ADDITIONAL_COMMENTS_PENDING,
    });
    axios.post(API_ROOT + `/contracts/${contractId}/${scenarioId}/summary/additionalDetails`, { data: data })
        .then((res) => {
            dispatch({
                type: ADDITIONAL_COMMENTS_FULFILLED,
                payload: res.data,
            });
        })
        .catch((error) => {
            console.log("An error occurred getting extra contract data", error);
            dispatch({
                type: ADDITIONAL_COMMENTS_REJECTED,
            });
        });
}

/*
    Get the product range summary...
*/
const getContractSummarySnapshot = (contractId, scenarioId) => (dispatch) => {
    let userReducer = redux.store.getState().userReducer;
    let year = userReducer.summaryYear;
    let dataPoints = Object.keys(userReducer.dataPointsToGraph);

    dispatch({
        type: GET_CONTRACT_SUMMARY_SNAPSHOT_PENDING,
    });

    axios
        .post(API_ROOT + `/contracts/${contractId}/${scenarioId}/summarySnapshot`, { data: { year, dataPoints } })
        .then((res) => {
            const summary = res.data;

            /* Fulfil the request for the product range summary. */
            dispatch({
                type: GET_CONTRACT_SUMMARY_SNAPSHOT_FULFILLED,
                payload: summary,
            });
        })
        .catch((error) => {
            console.log("An error occurred requesting contracts.", error);

            /* Reject the request for the product range summary, since the API failed. */
            dispatch({
                type: GET_CONTRACT_SUMMARY_SNAPSHOT_REJECTED,
            });
        });
};

/*
    Get the material group data point...
*/
const getMaterialGroupDataPoint = (contractId, scenarioId, productRangeId, materialGroupId, dataPoints, resolve, reject) => (dispatch) => {
    let dataPointString = dataPoints.join();

    dispatch({
        type: GET_MATERIAL_GROUP_DATA_POINT_SUMMARY_PENDING,
        payload: { dataPoints, materialGroupId: materialGroupId },
    });

    return axios
        .get(API_ROOT + `/contracts/${contractId}/${scenarioId}/productGroups/${productRangeId}/materials/${materialGroupId}/calculate?dataPoints=${dataPointString}`)
        .then((res) => {
            const data = { dataPoints, materialGroupId: materialGroupId, data: res.data };

            /* Fulfil the request for the material group data point, since the API succceded. */
            dispatch({
                type: GET_MATERIAL_GROUP_DATA_POINT_SUMMARY_FULFILLED,
                payload: data,
            });

            resolve(true);
        })
        .catch((error) => {
            console.log("An error occurred fetching material group data point.", error);

            /* Reject the request for the material group data point, since the API failed. */
            dispatch({
                type: GET_MATERIAL_GROUP_DATA_POINT_SUMMARY_REJECTED,
                payload: { dataPoints, materialGroupId: materialGroupId },
            });

            reject(error.response.data);
        });
};

/*
    Get the contract data point...
*/
// const getContractDataPoint = (contractId, scenarioId, dataPoints, year, columnName, incremental, resolve, reject) => dispatch => {
//     let dataPointArray = dataPoints.split(',');

//     dispatch({
//         type: GET_CONTRACT_DATA_POINT_SUMMARY_PENDING,
//         payload: { dataPoints: dataPointArray, scenarioId }
//     });

//     return axios.get(API_ROOT + `/contracts/${contractId}/${scenarioId}/summary?dataPoints=${dataPoints}&year=${year}&incremental=${incremental}`)
//         .then(res => {
//             const data = { dataPoints: dataPointArray, data: res.data };

//             /* Fulfil the request for the material group data point, since the API succceded. */
//             dispatch({
//                 type: GET_CONTRACT_DATA_POINT_SUMMARY_FULFILLED,
//                 payload: { ...data, scenarioId }
//             });

//             resolve(true);
//         })
//         .catch(error => {
//             console.log('An error occurred fetching material group data point.', error);

//             /* Reject the request for the material group data point, since the API failed. */
//             dispatch({
//                 type: GET_CONTRACT_DATA_POINT_SUMMARY_REJECTED,
//                 payload: { dataPoints: dataPointArray, scenarioId }
//             });

//             reject(error);
//         });
// };
const getContractDataPoint = (contractId, scenarioId, dataPoints, year, columnName, incremental, portfolio, resolve, reject) => (dispatch) => {
    let dataPointArray = dataPoints.split(",");

    dispatch({
        type: GET_CONTRACT_DATA_POINT_SUMMARY_PENDING,
        payload: { dataPoints: dataPointArray, year: columnName, scenarioId },
    });
    return axios
        .post(API_ROOT + `/contracts/${contractId}/${scenarioId}/summary?year=${year}&incremental=${incremental}&portfolio=${portfolio}`,
            dataPointArray,
            {
                headers: {
                    'authorization': 'Basic xxxxxxxxxxxxxxxxxxx',
                }
            })
        .then((res) => {
            const data = { dataPoints: dataPointArray, data: res.data, year: columnName };

            /* Fulfil the request for the material group data point, since the API succceded. */
            dispatch({
                type: GET_CONTRACT_DATA_POINT_SUMMARY_FULFILLED,
                payload: { ...data, scenarioId, year: columnName },
            });

            resolve(true);
        })
        .catch((error) => {
            console.log("An error occurred fetching material group data point.", error);

            /* Reject the request for the material group data point, since the API failed. */
            dispatch({
                type: GET_CONTRACT_DATA_POINT_SUMMARY_REJECTED,
                payload: { dataPoints: dataPointArray, scenarioId },
            });

            reject(error);
        });
};

/**
 *  Fetches the approval requirement (DSM, GM, etc) for the contract
 */
const getApproval = (contractId, scenarioId) => (dispatch) => {
    dispatch({
        type: GET_APPROVAL_PENDING,
    });

    return axios
        .get(API_ROOT + `/contracts/${contractId}/${scenarioId}/approval`)
        .then((response) => {
            const data = response.data;

            dispatch({
                type: GET_APPROVAL_FULFILLED,
                payload: data,
            });
        })
        .catch((error) => {
            console.log("An error occurred fetching approval");

            dispatch({
                type: GET_APPROVAL_FAILED,
            });
        });
};

/**
 * Returns a promise which will resolve with True if lastCalculated is after the last edited timestamp against the contract.
 * Will always resolve true if the forceRecalculate flag is set
 *
 * @param {String} contractId
 * @param {String} lastCalculated
 * @param {Boolean} forceRecalculate
 */
const createShouldRecalculatePromise = function (contractId, lastCalculated, forceRecalculate = false) {
    return new Promise((resolve, reject) => {
        if (forceRecalculate) {
            resolve(true);
        } else {
            /* Don't want to force re-calculation, so fetch last edited timestamp and then decide whether to re-calculate */
            axios
                .get(`${API_ROOT}/contracts/${contractId}/lastEdited`)
                .then((response) => {
                    let lastEdited = response.data;
                    resolve(moment(lastEdited).isAfter(lastCalculated));
                })
                .catch((error) => {
                    reject(error);
                });
        }
    });
};

const getMaterialSummary = (contractId, scenarioId, lastCalculated, callback) => (dispatch) => {
    let shouldRecalculatePromise = createShouldRecalculatePromise(contractId, lastCalculated);

    shouldRecalculatePromise
        .then((needToRecalculate) => {
            if (false) {
                callback(true);
            } else {
                dispatch({ type: GET_MATERIAL_SUMMARY_PENDING });
                axios
                    .get(`${API_ROOT}/contracts/${contractId}/${scenarioId}/materialSummary`)
                    .then((response) => {
                        dispatch({
                            type: GET_MATERIAL_SUMMARY_FULFILLED,
                            payload: response.data,
                        });
                        callback(true);
                    })
                    .catch((error) => {
                        dispatch({ type: GET_MATERIAL_SUMMARY_FAILED });
                        console.log(error);
                        callback(false);
                    });
            }
        })
        .catch((error) => {
            console.log(error);
            callback(false);
        });
};

const getAnnualInvestmentSummaries = (contractId, scenarioIds, portfolio, callback) => (dispatch) => {
    dispatch({ type: GET_ANNUAL_INVESTMENT_SUMMARIES_COMPARISON_PENDING });
    /* These data points are first year calculations */
    let nonCollatedDataPoints = ["Unit Cases", "Physical Cases", "Selling Litres", "Gross Sales Revenue", "EBIT", "Discounts and Allowances", "Net Sales Revenue", "Net Contribution", "NSR per UC", "NC per UC", "EBIT Margin"];
    let collatedDataPoints = ["Internal Rate of Return", "Discount percent GSR", "Capital Expenditure"];

    let promiseList = [];
    scenarioIds.forEach(element => {
        promiseList.push(
            new Promise((resolve, reject) => {
                let scenarioId = element.value;
                let data = {};
                axios
                    .post(API_ROOT + `/contracts/${contractId}/${scenarioId}/summary?year=${0}&incremental=${false}&portfolio=${portfolio}`, nonCollatedDataPoints)
                    .then((nonCollatedResponse) => {
                        data = { ...data, ...nonCollatedResponse.data };
                        axios
                            .post(API_ROOT + `/contracts/${contractId}/${scenarioId}/summary?year=${-1}&incremental=${true}&portfolio=${portfolio}`, collatedDataPoints)
                            .then((collatedResponse) => {
                                data = { ScenarioId: scenarioId, ...data, ...collatedResponse.data };
                                resolve(data);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
        );
    });
    Promise.all(promiseList).then((values) => {
        dispatch({
            type: GET_ANNUAL_INVESTMENT_SUMMARIES_COMPARISON_FULFILLED,
            payload: [...values],
        });
    }).catch((error) => {
        console.log(error);
        dispatch({ type: GET_ANNUAL_INVESTMENT_SUMMARIES_COMPARISON_FAILED })
    });
}

/**
 * Calculates the datapoints in the Annual Investment summary
 *
 * A timestamp is recorded each time the calculation is performed.
 * If the contract has been edited since the most recent calculation, the datapoints are re-calulated.
 *
 * The timestamp comparison is ignored if the forceRecalculate flag is set.
 *
 * @param {String} contractId
 * @param {String} scenarioId
 * @param {Boolean} isComparison
 * @param {String} lastCalculated
 * @param {Function} callback
 */
const getAnnualInvestmentSummary = (contractId, scenarioId, isComparison, lastCalculated, forceRecalculate, portfolio, callback) => (dispatch) => {
    let shouldRecalculatePromise = createShouldRecalculatePromise(contractId, lastCalculated, forceRecalculate);

    shouldRecalculatePromise
        .then((needToRecalculate) => {
            // if (!needToRecalculate) {
            if (false) {
                if(callback) callback(true);
            } else {
                /* The contract has been edited since the last calculation, need to re-calculate */
                !isComparison ? dispatch({ type: GET_ANNUAL_INVESTMENT_SUMMARY_PENDING }) : dispatch({ type: GET_ANNUAL_INVESTMENT_SUMMARY_COMPARISON_PENDING });

                /* These data points are first year calculations */
                let nonCollatedDataPoints = ["Unit Cases", "Physical Cases", "Selling Litres", "Gross Sales Revenue", "EBIT", "Discounts and Allowances", "Net Sales Revenue", "Net Contribution", "NSR per UC", "NC per UC", "EBIT Margin"];

                /* Calculate the datapoints which are non collated (calculated across a single year, the first year in this case) */
                let nonCollatedPromise = new Promise((resolve, reject) => {
                    return axios
                        .post(API_ROOT + `/contracts/${contractId}/${scenarioId}/summary?year=${0}&incremental=${false}&portfolio=${portfolio}`, nonCollatedDataPoints)
                        .then((response) => {
                            resolve(response.data);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });

                let collatedDataPoints = ["Internal Rate of Return", "Discount percent GSR", "Capital Expenditure"];

                /* Calculate the datapoints which are collated (calculated across the duration of the contract) */
                let collatedPromise = new Promise((resolve, reject) => {
                    return axios
                        .post(API_ROOT + `/contracts/${contractId}/${scenarioId}/summary?year=${-1}&incremental=${true}&portfolio=${portfolio}`, collatedDataPoints)
                        .then((response) => {
                            resolve(response.data);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });

                Promise.all([collatedPromise, nonCollatedPromise])
                    .then((data) => {
                        !isComparison
                            ? dispatch({
                                type: GET_ANNUAL_INVESTMENT_SUMMARY_FULFILLED,
                                payload: { data: { ...data[0], ...data[1] } },
                            })
                            : dispatch({
                                type: GET_ANNUAL_INVESTMENT_SUMMARY_COMPARISON_FULFILLED,
                                payload: { data: { ...data[0], ...data[1] } },
                            });
                        if(callback) callback(true);
                    })
                    .catch((error) => {
                        console.log(error);
                        !isComparison ? dispatch({ type: GET_ANNUAL_INVESTMENT_SUMMARY_FAILED }) : dispatch({ type: GET_ANNUAL_INVESTMENT_SUMMARY_COMPARISON_FAILED });
                        if(callback) callback(false);
                    });
            }
        })
        .catch((error) => {
            console.log(error);
            if(callback) callback(false);
        });
};

const getScenarioMaterialGroupDataPoints = (contractId, scenarioId, rows, derivedDataPoints, resolve, reject) => (dispatch) => {
    dispatch({
        type: GET_SCENARIO_MATERIAL_DATA_POINTS_PENDING,
    });

    return axios
        .post(API_ROOT + `/contracts/${contractId}/${scenarioId}/calculate/dataPoints`, { data: rows, derivedDataPoints })
        .then((response) => {
            dispatch({
                type: GET_SCENARIO_MATERIAL_DATA_POINTS_FULFILLED,
                payload: { scenarioId, data: response.data },
            });

            resolve(true);
        })
        .catch((error) => {
            console.log("An error occurred fetching top-line summary data.", error);
            dispatch({
                type: GET_SCENARIO_MATERIAL_DATA_POINTS_FAILED,
            });

            reject(false);
        });
};

/**
 * Get the summary statistics such as total profit, average margin etc. for a specific scenario's customer profit story...
 */
const getScenarioProfitStorySummary = (contractId, scenarioId, callback) => (dispatch) => {
    dispatch({
        type: GET_SCENARIO_PROFIT_STORY_SUMMARY_PENDING,
    });

    return axios
        .get(API_ROOT + `/contracts/${contractId}/${scenarioId}/customerProfitStorySummary`)
        .then((response) => {
            dispatch({
                type: GET_SCENARIO_PROFIT_STORY_SUMMARY_FULFILLED,
                payload: { scenarioId, data: response.data },
            });

            callback(true);
        })
        .catch((error) => {
            console.log("An error occurred fetching top-line summary data.", error);
            dispatch({
                type: GET_SCENARIO_PROFIT_STORY_SUMMARY_FAILED,
            });

            callback(false);
        });
};

// EXPORT ACTIONS
export { getAnnualInvestmentSummaries, getAdditionalComments, postAdditionalComments, getProductRangeSummary, getMaterialGroupDataPoint, getContractDataPoint, getContractSummarySnapshot, getApproval, getAnnualInvestmentSummary, getScenarioProfitStorySummary, getScenarioMaterialGroupDataPoints, getMaterialSummary, clearCurrentContractSummarySnapshot };
