// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// IMPORT PROJECT REFERENCES
import { getProductGroups, saveScenarioMaterialData } from "../../../state/actions/ProductGroupActions";
import { getContractSummarySnapshot } from "../../../state/actions/SummaryActions";
import { getScenarioProfitStorySummary } from "../../../state/actions/SummaryActions";
import { getCustomerTargets } from "../../../state/actions/CustomerTargetActions";
import { getContract } from "../../../state/actions/ContractActions";

import { API_ROOT } from "../../../../config/config";

// UI
import { DataTable } from "./productGroup/DataTable";
import { ExpandableRow } from "../../../containers/table/ExpandableRow";
import format from "../../../../helpers/formatter";
import Placeholder from "../../../containers/layout/Placeholder";
import { Loading } from "../../../containers/loading/Loading";
import FeatherIcon from "feather-icons-react";
import { FloatingButton } from "../../../containers/inputs/FloatingButton";
import XLSX from 'xlsx';

export const FormContext = React.createContext();

// COMPONENT
class ScenarioCustomerProfitStory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            productGroups: props.productGroups,
            rows: [],
            groups: [],
            scenarios: [],
            scenarioProfitStorySummary: {},
            contract: {},
        };

        this.handleDataTableSave = this.handleDataTableSave.bind(this);
        this.fetchProductGroupData = this.fetchProductGroupData.bind(this);
        this.handleScenarioIdSelected = this.handleScenarioIdSelected.bind(this);
        this.recalculate = this.recalculate.bind(this);
        this.export = this.export.bind(this);
    }

    export() {
        var beeeeeeeeeemovie = this.state.groups[0].dataPoints;
        let pGroups = beeeeeeeeeemovie.map(item => {
            return item.dataIdentifier;
        });
        location.href = `${API_ROOT}/contracts/${this.props.match.params.contractId}/${this.props.match.params.scenarioId}/materialXLSX?DataPoints=${pGroups}`;
    }

    componentWillMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 200);
    }

    componentDidMount() {
        this.props.getContract(this.props.match.params.contractId, (_) => {
            this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
            this.props.getScenarioProfitStorySummary(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
            this.fetchProductGroupData(this.props.contract.scenarios);
            this.props.setFloatingButtons([{ type: "recalculate" }, { type: "xlsx_export" }, { type: "add_materials" }, { type: "remove_materials" }]);
            this.setState({ loading: false });
        });

        this.props.bindRefresh(this.recalculate);
        this.props.bindExport(this.export);
    }

    /*
        Fetch all the Product Groups for the relevant scenarios.
    */
    fetchProductGroupData(scenarios) {
        // Fire of all the relevant requests for product group data...
        this.props.getProductGroups(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
        // Fetch the profit story summary for the active scenario too...
        this.props.getScenarioProfitStorySummary(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.scenarioId !== this.props.match.params.scenarioId) {
            this.fetchProductGroupData(this.state.scenarios);
        }

        if (JSON.stringify(this.props.groups) !== JSON.stringify(this.state.groups)) {
            this.setState({ groups: this.props.groups });
        }

        if (JSON.stringify(this.state.productGroups) !== JSON.stringify(this.props.productGroups)) {
            this.setState({ productGroups: this.props.productGroups });
        }

        if (JSON.stringify(this.state.scenarioProfitStorySummary) !== JSON.stringify(this.props.scenarioProfitStorySummary)) {
            this.setState({ scenarioProfitStorySummary: this.props.scenarioProfitStorySummary });
        }

        if (JSON.stringify(this.state.contract) !== JSON.stringify(this.props.contract)) {
            this.setState({ contract: this.props.contract });
        }
    }

    handleDataTableSave(rows, completion) {
        let promises = [];
        let promise = new Promise((resolve, reject) => {
            this.props.saveScenarioMaterialData(this.props.match.params.contractId, this.props.match.params.scenarioId, rows, resolve, reject);
        });
        promises.push(promise);

        Promise.all(promises)
            .then((data) => {
                // When all the materials have been updated, recall the customer targets and topline summary.
                // this.props.getCustomerTargets(this.props.match.params.contractId, this.props.match.params.scenarioId)
                this.props.getScenarioProfitStorySummary(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
                this.props.getAlerts();
                // Update the contract summary...
                this.props.getContractSummarySnapshot(this.props.match.params.contractId, this.props.match.params.scenarioId);
                completion(true);
            })
            .catch((error) => {
                console.log("Error...", error);
                completion(false);
            });
    }

    handleScenarioIdSelected(scenarioId) {
        if (this.state.scenarios.includes(scenarioId)) {
            let index = this.state.scenarios.indexOf(scenarioId);
            this.setState({ scenarios: [...this.state.scenarios.slice(0, index), ...this.state.scenarios.slice(index + 1)] });
        } else {
            this.setState({ scenarios: [...this.state.scenarios, scenarioId] });

            // When we add a new scenario, we want to make sure we have the relevant data...
            this.fetchProductGroupData([scenarioId]);
        }
    }

    /**
     *
     * @param {String} text - convert the provided camelCase text into (Camel Case) sentence case text.
     */
    toSentenceCase(text) {
        var result = text.replace(/([A-Z])/g, " $1");
        var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult;
    }

    recalculate() {
        let materials = this.retrieveMaterials(this.props.match.params.scenarioId);
        let promises = [];

        let promise = new Promise((resolve, reject) => {
            this.props.saveScenarioMaterialData(this.props.match.params.contractId, this.props.match.params.scenarioId, materials, resolve, reject);
        });
        promises.push(promise);

        Promise.all(promises)
            .then((data) => {
                // When all the materials have been updated, recall the customer targets and topline summary.
                // this.props.getCustomerTargets(this.props.match.params.contractId, this.props.match.params.scenarioId)
                this.props.getScenarioProfitStorySummary(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });

                // Update the contract summary...
                this.props.getContractSummarySnapshot(this.props.match.params.contractId, this.props.match.params.scenarioId);

                this.fetchProductGroupData(); // -> Force refresh the page
                this.fetchProductGroupData();
                this.fetchProductGroupData();
                this.fetchProductGroupData();
                this.fetchProductGroupData();
            });
    }

    /**
     * Render the scenario profit story content (volume/pricing data table and running totals)
     *
     * Render a new expandable row for each scenario.
     *
     * @param {String} summaryId - the scenario to render.
     * @param {Object} summaryData - the summary data to consider.
     */
    renderProfitStoryContent(activeScenarioId, scenarioId, summaryData) {
        let materials = this.retrieveMaterials(scenarioId);

        if (materials.length <= 0) {
            return <Placeholder type="no_materials" />;
        } else {
            return (
                <div className="material-data-table-container">
                    <div className="profit-stories-container">
                        <Fragment>
                            <div className={"profit-story-content" + (scenarioId === activeScenarioId ? " active" : " inactive") + (this.state.loading ? " transition-hidden" : " transition-showing")}>
                                <DataTable
                                    mini
                                    allowDeletion={scenarioId === activeScenarioId}
                                    enableRemove={this.props.enableRemove}
                                    disableRemove={this.props.disableRemove}
                                    sizeListener={this.tableSizeCallback}
                                    direction={this.state.tableDirection}
                                    onRef={this.props.onRef}
                                    hideTitleBorder
                                    handleSave={this.handleDataTableSave}
                                    groups={this.state.groups.filter((group) => {
                                        return group.active;
                                    })}
                                    rows={materials}
                                />
                            </div>

                            <div style={{ transitionDelay: "200ms" }} className={"scenario-totals" + (this.state.loading ? " transition-hidden" : " transition-showing")}>
                                <div className="scenario-total-heading">Total</div>
                                <div className="scenario-total-subheading">
                                    <div className="scenario-table-left">Type</div>
                                    <div className="scenario-table-right">Value</div>
                                </div>
                                {Object.keys(summaryData).map((summaryKey, summaryIndex) => {
                                    let value = summaryData[summaryKey];

                                    let formatting = { type: "dollar" };

                                    if (summaryKey === "totalVolume") {
                                        formatting["type"] = "number";
                                    }

                                    if (summaryKey === "averageMargin") {
                                        formatting["type"] = "percentage";
                                    }

                                    return (
                                        <div className="scenario-total-row" key={summaryIndex}>
                                            <div className="scenario-table-left">{this.toSentenceCase(summaryKey)}</div>
                                            <div className="scenario-table-right">{format(value, formatting)}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </Fragment>
                    </div>
                </div>
            );
        }
    }

    /**
     * Retrieve the materials that are related to the provided scenarioId.
     * @param {String} scenarioId - the scenario for which to retrieve materials from.
     */
    retrieveMaterials(scenarioId) {
        let materials = [];
        if (this.state.productGroups) {
            let productGroupsForScenario = this.state.productGroups[scenarioId];

            if (productGroupsForScenario) {
                materials = Object.keys(productGroupsForScenario).map((productGroupKey) => {
                    let rowItem = {};
                    rowItem["discounts"] = productGroupsForScenario[productGroupKey].discounts === undefined ? {} : productGroupsForScenario[productGroupKey].discounts;
                    rowItem["dataPoints"] = Object.values(productGroupsForScenario[productGroupKey].materials)[0];
                    rowItem["productGroupId"] = productGroupKey;
                    return rowItem;
                });
            }
        }

        return materials;
    }

    /**
     * Retrieve summary data for the provided scenarioId.
     * @param {String} scenarioId
     */
    retrieveSummaryData(scenarioId) {
        let summaryData = {};
        if (this.state.scenarioProfitStorySummary[scenarioId]) {
            summaryData = this.state.scenarioProfitStorySummary[scenarioId];
        }

        let scenarioName = "";
        if (this.state.contract) {
            if (this.state.contract.scenarios) {
                let indexOfScenario = this.state.contract.scenarios.findIndex((scenario) => {
                    return scenario.scenarioId === scenarioId;
                });

                if (indexOfScenario > -1) {
                    scenarioName = this.state.contract.scenarios[indexOfScenario].scenarioName;
                }
            }
        }

        return { summaryData, scenarioName };
    }

    /**
     * Render the customer Profit story as a series of expandable rows for each scenario.
     */
    render() {
        let activeScenarioId = this.props.match.params.scenarioId;
        let { summaryData } = this.retrieveSummaryData(activeScenarioId);

        if (this.state.loading || this.props.fetching) {
            return <Loading />;
        }

        return <div> {this.renderProfitStoryContent(activeScenarioId, activeScenarioId, summaryData)} </div>;
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { contract } = state.contractReducer;
    const { productGroups, fetching } = state.productGroupReducer;
    const { scenarioProfitStorySummary } = state.summaryReducer;
    return { productGroups, fetching, contract, scenarioProfitStorySummary };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getProductGroups,
            saveScenarioMaterialData,
            getCustomerTargets,
            getContract,
            getScenarioProfitStorySummary,
            getContractSummarySnapshot,
        },
        dispatch
    );

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(ScenarioCustomerProfitStory));

// EXPORT COMPONENT
export { hoc as ScenarioCustomerProfitStory };
