// IMPORT PACKAGE REFERENCES
import React from "react";
import { ContextHeader } from "./ContextHeader";
import { Loading } from "../loading/Loading";

// COMPONENT
export class ContextPage extends React.Component {
    render() {
        let padded = this.props.padded === undefined ? false : this.props.padded;

        return (
            <div>
                <ContextHeader {...this.props} />
                {this.props.fetching && <Loading large className={this.props.fetching ? "" : "view-hidden"} />}
                {/* { this.props.fetched && */}
                <div className={"contextual-detail content-guide " + (padded ? " padded" : "") + (this.props.fetched ? " view-visible" : " view-hidden")}>{this.props.children}</div>
                {/* } */}
            </div>
        );
    }
}
