// IMPORT PACKAGE REFERENCES
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// IMPORT UI
import { ConditionalInput } from "../inputs/ConditionalInput";
import { Conditionaliser } from "../../../modules/conditionaliser/Conditionaliser";
import { CardRow } from "../card/CardRow";
import { Checkbox } from "../inputs/Checkbox";

// IMPORT ACTIONS
import { saveContractCustomConditions } from "../../state/actions/ContractActions";

// COMPONENT
class ConditionalisationSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            editing: this.props.editing,
            fixedValue: "",
            conditions: [],
            id: undefined,
            customConditions: {},
            numericValue: undefined,
            messageActive: false,
            messageLoading: false,
            message: "",
        };

        this.initialValue = {};
        this.conditionaliser = null;

        this.toggleActive = this.toggleActive.bind(this);
        this.handleTextEntry = this.handleTextEntry.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSaveCustomConditions = this.handleSaveCustomConditions.bind(this);
    }

    toggleActive() {
        this.setState({ active: !this.state.active });
    }

    generateValueRange(expressions) {
        let potentialValues = [0];
        if (expressions) {
            expressions.forEach((expression) => {
                expression.forEach((statement) => {
                    if (statement.hasOwnProperty("isResult")) {
                        if (statement.isResult) {
                            potentialValues.push(statement.components[2].value);
                        }
                    }
                });
            });
        }

        let maxValue = Math.max(...potentialValues);
        let label = `Up to ${this.props.units.position === "before" ? this.props.units.type : ""}${maxValue}${this.props.units.position === "after" ? this.props.units.type : ""}`;
        this.setState({ fixedValue: label, numericValue: maxValue });
        return { fixedValue: label, numericValue: maxValue };
    }

    getData() {
        if (this.conditionaliser !== null) {
            let expressions = JSON.parse(this.conditionaliser.getExpressions());
            let valueRange = this.generateValueRange(expressions);

            if (expressions.length > 0) {
                return { conditions: expressions, fixedValue: valueRange.label, active: this.state.active, type: this.props.type, numericValue: valueRange.numericValue };
            }
        } else {
            return { conditions: [], fixedValue: this.state.fixedValue, active: this.state.active, type: this.props.type, numericValue: this.state.numericValue };
        }
    }

    componentDidUpdate() {
        if (this.state.fixedValue !== this.props.fixedValue) {
            this.setState({ fixedValue: this.props.fixedValue });
        }

        if (this.state.numericValue !== this.props.numericValue) {
            this.setState({ numericValue: this.props.numericValue });
        }

        if (this.state.conditions !== this.props.conditions) {
            this.setState({ conditions: this.props.conditions });
        }

        /*
            Enable editing.
        */
        if (this.state.editing !== this.props.editing) {
            this.setState({ editing: this.props.editing });
        }

        /*
            We need to make sure that we update the data when switching between different ids. 
            The component isn't necessarily unmounted, so a full remount won't be performed.
        */
        if (this.props.id !== this.state.id) {
            this.setState({ id: this.props.id });

            // When the conditions change...
            if (JSON.stringify(this.state.conditions) !== JSON.stringify(this.props.value.conditions)) {
                this.setState({ conditions: this.props.value.conditions });

                if (this.props.value.conditions !== undefined) {
                    this.generateValueRange(this.props.value.conditions);
                }
            }

            if (this.state.active !== this.props.value.active) {
                this.setState({ active: this.props.value.active });
            }

            if (!this.props.value.active) {
                if (this.state.fixedValue !== this.props.value.fixedValue) {
                    let valueToSet = this.props.value.fixedValue === undefined ? "" : this.props.value.fixedValue;
                    this.setState({ fixedValue: valueToSet, numericValue: valueToSet });
                }
            } else {
                this.generateValueRange(this.props.value.conditions);
            }
        }

        if (JSON.stringify(this.props.customConditions) !== JSON.stringify(this.state.customConditions)) {
            this.setState({ customConditions: this.props.customConditions });
        }

        if (this.state.cancelled !== this.props.cancelled && this.conditionaliser !== null) {
            this.conditionaliser.cancelled();
            this.props.cancelCompleted();
        }
    }

    handleTextEntry(value) {
        if (!this.state.active) {
            if (this.conditionaliser !== null) {
                if (JSON.parse(this.conditionaliser.getExpressions()).length > 0) {
                    var r = confirm("Clear conditional statements?");
                    if (r == true) {
                        this.conditionaliser.clearExpressions();
                        this.setState({ fixedValue: "", numericValue: undefined });
                    }
                }
            } else {
                this.setState({ fixedValue: value, numericValue: parseFloat(value) });
            }
        }
    }

    handleChange() { }

    handleSaveCustomConditions(conditions) {
        this.setState({ messageActive: true, messageLoading: true, message: "Saving Custom Conditions" });
        this.props.saveContractCustomConditions(this.props.match.params.contractId, conditions, (success) => {
            this.setState({ messageActive: false, messageLoading: false });
        });
    }

    componentDidMount() {
        this.setState({ fixedValue: this.props.value.fixedValue, numericValue: this.props.value.fixedValue });
        this.props.onRef(this);
    }

    render() {
        return (
            <Fragment>
                <div className={"conditional-row" + (this.props.stacked ? " stacked" : "")}>
                    {this.props.selectable && (
                        <div className="discount-checkbox">
                            <Checkbox editing={this.props.editing} observe handleClick={this.props.handleClick} checked={this.props.checked} />
                        </div>
                    )}

                    <CardRow stacked label={this.props.label} top={this.props.top} bottom={this.props.bottom}>
                        <ConditionalInput
                            active={this.state.active}
                            editing={this.state.editing}
                            value={this.state.fixedValue}
                            units={this.props.units}
                            handleToggle={() => {
                                if (this.state.active) {
                                    this.conditionaliser.clearExpressions();
                                    this.setState({ fixedValue: "", numericValue: undefined });
                                }
                                this.setState({ active: !this.state.active });
                            }}
                            handleChange={this.handleTextEntry}
                            onSave={() => {
                                this.props.onSave();
                            }}
                        />
                    </CardRow>
                </div>

                {this.state.active && (
                    <Conditionaliser
                        onSave={() => {
                            this.props.onSave();
                        }}
                        customConditions={this.props.contract.customConditions}
                        saveCustomConditions={this.handleSaveCustomConditions}
                        allowResults={this.props.allowResults === undefined ? true : this.props.allowResults}
                        value={this.state.conditions}
                        ref={(ref) => (this.conditionaliser = ref)}
                        options={this.props.resultOptions}
                        setSaveButtonState={() => { }}
                        onChange={this.handleChange}
                        editing={this.state.editing}
                    />
                )}

                {this.props.stacked && !this.props.bottom && <div className="cca-card-divider" />}
            </Fragment>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { contract } = state.contractReducer;
    return { contract };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ saveContractCustomConditions }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConditionalisationSection));

// EXPORT COMPONENT
export { hoc as ConditionalisationSection };
