// IMPORT PACKAGE REFERENCES
import React from "react";
import { ResultCell } from "../../../containers/table/cells/ResultCell";
import { getNonFinancialSummary } from "../../../state/actions/NonFinancialActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

// COMPONENT
class NonFinancialIncentivesSummary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nonFinancialSummary: {
                totalCapitalValue: {
                    value: 0,
                    guidance: "normal",
                    advice: "",
                },
                totalQuantity: {
                    value: 0,
                    guidance: "normal",
                    advice: "",
                },
                equipmentOtherDistribution: {
                    ratio: "1:1",
                    guidance: "normal",
                    advice: "",
                },
            },
        };
    }

    componentDidMount() {
        this.props.getNonFinancialSummary(this.props.match.params.contractId, this.props.match.params.scenarioId, (success) => { });
    }

    componentDidUpdate() {
        if (JSON.stringify(this.props.nonFinancialSummary) !== JSON.stringify(this.state.nonFinancialSummary)) {
            this.setState({ nonFinancialSummary: this.props.nonFinancialSummary });
        }
    }

    render() {
        return (
            <div>
                <div className="rl-section-label">Additional Incentives Summary</div>
                <div className={"rl-cell-container"}>
                    <ResultCell advice={this.state.nonFinancialSummary.totalCapitalValue.advice} guidance={this.state.nonFinancialSummary.totalCapitalValue.guidance} label="Total Capital Value" value={"$" + this.state.nonFinancialSummary.totalCapitalValue.value} />
                </div>
                <div className={"rl-cell-container"}>
                    <ResultCell guidance={this.state.nonFinancialSummary.totalQuantity.guidance} label="Total Equipment Quantity" value={this.state.nonFinancialSummary.totalQuantity.value} />
                </div>
            </div>
        );
    }
}

// CONFIGURE REACT REDUX
const mapStateToProps = (state) => {
    const { nonFinancialSummary } = state.nonFinancialReducer;
    return { nonFinancialSummary };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getNonFinancialSummary }, dispatch);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(NonFinancialIncentivesSummary));

// EXPORT COMPONENT

export { hoc as NonFinancialIncentivesSummary };
