import axios from "axios";
import { API_ROOT } from "../../../config/config";
import { yupToFormErrors } from "formik";

// Import Base URL

// GET CONTRACTS
export const ALERT_POST_FULFILLED = "ALERT_POST_FULFILLED";
export const ALERT_CLEAR_FULFILLED = "ALERT_CLEAR_FULFILLED";
export const GET_ALERTS_PENDING = "GET_ALERTS_PENDING";
export const GET_ALERTS_FULFILLED = "GET_ALERTS_FULFILLED";
export const GET_ALERTS_REJECTED = "GET_ALERTS_REJECTED";

// ACTION GENERATORS

/* 
    Get alerts that have been identified by the server.

    @params:
    - contractId: the id of the contract to get alerts for.
*/
const getAlerts = (contractId, scenarioId) => (dispatch) => {
    dispatch({
        type: GET_ALERTS_PENDING,
    });

    axios
        .get(API_ROOT + `/contracts/${contractId}/${scenarioId}/alerts`)
        .then((res) => {
            const alerts = res.data;
            dispatch({
                type: GET_ALERTS_FULFILLED,
                payload: alerts,
            });
        })
        .catch((error) => {
            console.log("An error occurred requesting alerts.", error);

            dispatch({
                type: GET_ALERTS_REJECTED,
            });
        });
};

/* 
    Post a page alert.

    @params:
    - pageId: the page id of where the alert comes from.
    - message: the alert message.
*/
const postAlert = (pageId, message) => (dispatch) => {
    dispatch({
        type: ALERT_POST_FULFILLED,
        payload: { pageId, message },
    });
};

/* 
    Clear a page alert.

    @params:
    - pageId: the page id of where the alert comes from.
    - messageId: the id of the message to clear.
    
    By default all messages for a page are cleared if no message id is provided.
*/
const clearAlert = (pageId, messageId = null) => (dispatch) => {
    dispatch({
        type: ALERT_CLEAR_FULFILLED,
        payload: { pageId, messageId },
    });
};

// EXPORT ACTIONS
export { postAlert, clearAlert, getAlerts };
